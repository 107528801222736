export default {
  "p-support-chat-welcome": "欢迎来到我们的支持聊天！",
  "p-support-chat-intro":
    "我们在这里为您提供任何问题或疑虑的帮助。我们的目标是为您提供最佳的支持体验。以下是您可以开始的步骤：",
  "p-support-chat-enter-details-prefix": "输入您的详细信息：",
  "p-support-chat-enter-details-suffix":
    "问题的标题。这些信息将帮助我们更好地为您提供帮助，并更有效地解决您的问题。",
  "p-support-chat-open-chat": '打开聊天：点击"打开支持聊天"按钮开始。',
  "p-support-chat-start-conversation":
    "开始对话：输入您的详细信息并提交表单后，页面上会出现一个聊天框。我们的版主会很快加入聊天，为您提供帮助。",
  "p-support-chat-appreciation": "我们感谢您的耐心，并期待为您提供帮助！",
  "p-support-chat-your-name": "您的姓名",
  "p-support-chat-your-email": "您的电子邮件",
  "p-support-chat-your-question": "您的问题",
  "p-support-chat-open-chat-button": "打开支持聊天",
  "p-support-chat-wait-moderator": "等待版主加入",
  "p-support-chat-contact-alternative":
    "如果您在使用聊天时遇到任何问题或更喜欢其他联系方式，请通过我们的",
  "p-support-chat-contact-page": "联系页面",
  "p-support-chat-or-email": "或发送电子邮件至",
  "p-support-chat-thank-you": "感谢您选择我们的服务！",
  "p-support-chat-ask-question": "提问",
  "p-support-chat-name-required": "姓名是必填项",
  "p-support-chat-email-required": "电子邮件是必填项",
  "p-support-chat-valid-email": "请输入有效的电子邮件",
  "p-support-chat-chat-started": "支持聊天已开始！",
  "p-support-chat-name-email": "姓名，电子邮件,",
};
