export default {
  "p-trade-action-buy-title": "购买加密货币 | Solid Swap 上的安全加密货币购买",
  "p-trade-action-sell-title": "出售加密货币 | Solid Swap 上的安全加密货币出售",
  "p-trade-action-buy-description":
    "在 Solid Swap 上安全购买加密货币。享受快速交易和具有竞争力的数字资产购买价格",
  "p-trade-action-sell-description":
    "在 Solid Swap 上安全出售您的加密货币。享受快速交易和最佳数字资产价格",
  "p-trade-action-buy-keywords":
    "购买加密货币，加密货币购买，安全资产购买，数字货币交易",
  "p-trade-action-sell-keywords":
    "出售加密货币，加密货币出售，安全资产销售，数字货币交易",
  "p-trade-action-nothing-found": "未找到，请尝试其他选项",
  "p-trade-action-clear-filters": "清除所有过滤器",
};
