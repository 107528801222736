export default {
  "c-exchange-steps-verifying-card": "Verifying user's card",
  "c-exchange-steps-waiting-alm": "Waiting for ALM results",
  "c-exchange-steps-preparing-crypto": "Preparing platform address",
  "c-exchange-steps-moderator-verifying": "Moderator is verifying data",
  "c-exchange-steps-preparing-fiat": "Preparing fiat requisites",
  "c-exchange-steps-waiting-payment": "Waiting for user's payment",
  "c-exchange-steps-confirming-payment": "Confirming payment",
  "c-exchange-steps-sending-crypto": "Sending crypto",
  "c-exchange-steps-sending-fiat": "Sending fiat",
  "c-exchange-steps-request-completed": "Request completed",
};
