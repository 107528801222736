export default {
  "p-confirm-email-title": "Confirmation de l'email",
  "p-confirm-email-wait-message":
    "Veuillez patienter, nous essayons de confirmer votre adresse email...",
  "p-confirm-email-success-message":
    "Votre adresse email a été confirmée avec succès !",
  "p-confirm-email-success-elmessage":
    "Votre adresse email a été confirmée avec succès ! Vous serez redirigé vers la page de connexion dans 30 secondes.",
  "p-confirm-email-info-elmessage":
    "Vous pouvez maintenant vous connecter à votre compte en tant que : {email}",
  "p-confirm-email-error-message":
    "Quelque chose a mal tourné, veuillez réessayer plus tard.",
  "p-confirm-email-proceed-button": "Procéder à la connexion",
  "p-confirm-email-welcome-title":
    "Bienvenue dans une nouvelle ère de trading !",
  "p-confirm-email-welcome-message":
    "Connectez-vous en toute sécurité à un monde d'actifs numériques. Commencez à trader en toute confiance et commodité sur notre plateforme P2P de confiance. Votre passerelle vers des transactions crypto fluides vous attend.",
};
