export default {
  "t-email-logoAltText": "SolidSwap Լոգո",
  "t-email-greeting": "Բարև,",
  "t-email-failedLoginAttemptMessage":
    "Դուք ունեք չհաջողված մուտք SolidSwap-ի ձեր հաշվում: Ձեր մուտքը արգելափակված է 1 ժամով:",
  "t-email-lastFailedLoginAttemptMessage":
    "Վերջին չհաջողված մուտքը եղել է այս սարքից և այս վայրից:",
  "t-email-serverLabel": "Սերվեր:",
  "t-email-ipAddressLabel": "IP հասցե:",
  "t-email-countryDistrictLabel": "Երկիր/Շրջան:",
  "t-email-timestampLabel": "Ժամանակ:",
  "t-email-compromisedAccountMessage":
    "Եթե կարծում եք, որ ձեր հաշիվը կոտրվել է, անմիջապես կապ հաստատեք մեզ հետ էլեկտրոնային փոստով",
  "t-email-registeredEmailMessage":
    "օգտագործելով ձեր գրանցված էլ. հասցեն, կամ սեղմեք ներքևի կոճակը՝ ելքերը արգելափակելու համար:",
  "t-email-blockWithdrawalsButtonText": "Արգելափակել ելքերը",
  "t-email-blockWithdrawalsButtonLabel": "Արգելափակել ելքերը",
  "t-email-thankYouMessage": "Շնորհակալություն,",
  "t-email-teamMessage": "SolidSwap թիմը",
  "t-email-loginFromNewDeviceMessage":
    "Դուք մուտք եք գործել ձեր SolidSwap հաշիվ նոր սարքից կամ վայրից:",
  "t-email-newExchangeRequestSubject": "SolidSwap: Նոր փոխանակման հարցում",
  "t-email-fromCurrencyLabel": "Արտարժույթից:",
  "t-email-fromAmountLabel": "Գումար:",
  "t-email-toCurrencyLabel": "Արտարժույթին:",
  "t-email-toAmountLabel": "Գումար:",
  "t-email-statusLabel": "Կարգավիճակ:",
  "t-email-dateTimeLabel": "Ամսաթիվ/Ժամանակ:",
  "t-email-requestIdLabel": "Հարցման ID:",
  "t-email-goToRequestPageButtonLabel": "Գնալ փոխանակման հարցման էջ",
  "t-email-noReplyMessage": "Խնդրում ենք չպատասխանել այս նամակին:",
  "t-email-supportRequestNotification":
    "SolidSwap Նոր աջակցման հարցման ծանուցում",
  "t-email-userNameLabel": "Օգտագործողի անուն:",
  "t-email-userEmailLabel": "Օգտագործողի էլ. հասցե:",
  "t-email-helpMessageLabel": "Օգնության հաղորդագրություն:",
  "t-email-checkSupportChatsMessage":
    "Խնդրում ենք ստուգել աջակցության զրույցների ներդիրը:",
  "t-email-orderCreationNotification": "SolidSwap Պատվերի ստեղծման ծանուցում",
  "t-email-currencyLabel": "Արտարժույթ:",
  "t-email-quantityLabel": "Քանակ:",
  "t-email-goToOrderPageButtonLabel": "Գնալ պատվերի էջ",
  "t-email-passwordResetRequestMessage":
    "Դուք խնդրել եք գաղտնաբառի վերականգնում:",
  "t-email-passwordResetInstructionMessage":
    "Խնդրում ենք շարունակել վերականգնել ձեր SolidSwap հաշվի գաղտնաբառը՝ սեղմելով ներքևի կոճակը:",
  "t-email-resetPasswordButtonLabel": "Վերականգնել գաղտնաբառը",
  "t-email-linkValidityMessage": "Հղումը վավեր է 15 րոպե:",
  "t-email-errorNotificationMessage":
    "Եթե կարծում եք, որ այս նամակը սխալմամբ է ուղարկվել, անմիջապես տեղեկացրեք SolidSwap-ի գրանցման թիմին",
  "t-email-alternateLinkInstruction":
    "Եթե վերոհիշյալ հղումը չի գործում, խնդրում ենք պատճենել ստորև նշված հղումը և տեղադրել այն ձեր վեբ դիտարկչի հասցեագծում՝ սեղմելով մուտք:",
  "t-email-confirmAccountMessage":
    "Խնդրում ենք հաստատել ձեր SolidSwap հաշիվը՝ սեղմելով ներքևի կոճակը:",
  "t-email-confirmAccountButtonLabel": "Հաստատել հաշիվը",
  "t-email-memberNameMessage": "Ձեր SolidSwap անդամի անունն է:",
  "t-email-accessSiteMessage": "Դուք կարող եք մուտք գործել կայք՝ անցնելով",
  "t-email-activate2FAInstructions":
    "Առևտուր սկսելու կամ առաջին ավանդը կատարելու համար անհրաժեշտ է ակտիվացնել Երկու-փուլային վավերացումը: Դուք կարող եք դա անել ձեր պրոֆիլի Կարգավորումներում կամ սեղմել",
  "t-email-hereLabel": "այստեղ",
  "t-email-beginnersGuideMessage":
    "Մենք խորհուրդ ենք տալիս սկսել մեր Սկսնակների ուղեցույցը",
  "t-email-communityWelcomeMessage":
    "Մենք ուրախ ենք տեսնել ձեզ SolidSwap համայնքում:",
  "t-email-exchangeStatusChangeNotification":
    "SolidSwap Փոխանակման հարցում: Կարգավիճակի փոփոխման ծանուցում",
  "t-email-withdrawalNotification": "SolidSwap Ելքի ծանուցում",
  "t-email-withdrawToLabel": "Ելք դեպի:",
  "t-email-ipLabel": "IP:",
  "t-email-locationLabel": "Տեղանք:",
  "t-email-unauthorizedActivityMessage":
    "Եթե չեք ճանաչում այս գործողությունը, խնդրում ենք սեղմել ներքևի կոճակը՝ գործարքը չեղարկելու և ձեր հաշվի ելքերը արգելափակելու համար: Հղումը վավեր է միայն 10 րոպե:",
  "t-email-cancelTransactionButtonLabel": "Չեղարկել գործարքը",
};
