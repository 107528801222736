export default {
  "c-exchange-steps-verifying-card": "Ստուգում է օգտատիրոջ քարտը",
  "c-exchange-steps-waiting-alm": "Սպասում է ALM արդյունքների",
  "c-exchange-steps-preparing-crypto": "Պլատֆորմի հասցեն պատրաստվում է",
  "c-exchange-steps-moderator-verifying": "Մոդերատորը ստուգում է տվյալները",
  "c-exchange-steps-preparing-fiat": "Ֆիատի տվյալների պատրաստում",
  "c-exchange-steps-waiting-payment": "Սպասում է օգտատիրոջ վճարմանը",
  "c-exchange-steps-confirming-payment": "Վճարման հաստատում",
  "c-exchange-steps-sending-crypto": "Կրիպտոյի ուղարկում",
  "c-exchange-steps-sending-fiat": "Ֆիատի ուղարկում",
  "c-exchange-steps-request-completed": "Հարցումը ավարտված է",
};
