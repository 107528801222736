export default {
  "c-footer-menu-trade": "comercio p2p",
  "c-footer-menu-news": "noticias",
  "c-footer-menu-blog": "blog",
  "c-footer-menu-faq": "FAQ",
  "c-footer-menu-beginnersGuide": "guía para principiantes",
  "c-footer-menu-about": "acerca de",
  "c-footer-menu-manageCookies": "gestionar cookies",
  "c-footer-menu-adminPanel": "panel de administración",

  "c-footer-mobilemenu-home": "Inicio",
  "c-footer-mobilemenu-trade": "Comercio",
  "c-footer-mobilemenu-orders": "Pedidos",
  "c-footer-mobilemenu-wallet": "Cartera",
};
