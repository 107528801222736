export default {
  "c-exchange-reqItem-cancelling-request":
    "Cancelando su solicitud de intercambio",
  "c-exchange-reqItem-cancel-request-confirm":
    "¿Está seguro de que desea cancelar la solicitud?",
  "c-exchange-reqItem-confirming-documents": "Confirmando sus documentos",
  "c-exchange-reqItem-upload-documents-instructions":
    "Asegúrese de que las imágenes de su documento tengan alta resolución y que todos los dígitos sean claramente visibles",
  "c-exchange-reqItem-confirm-documents-confirmation":
    "¿Desea continuar con la confirmación de los documentos?",
  "c-exchange-reqItem-select-payment-option": "Seleccionar opción de pago",
  "c-exchange-reqItem-proceed-with-payment-option":
    "¿Desea continuar con la opción de pago seleccionada?",
  "c-exchange-reqItem-confirming-payment": "Confirmando el pago",
  "c-exchange-reqItem-confirm-payment":
    "¿Desea continuar con la confirmación de su pago?",
  "c-exchange-reqItem-completing-request": "Completando la solicitud",
  "c-exchange-reqItem-provide-txid":
    "Para completar la solicitud, debe proporcionar el ID de transacción de su transferencia",
  "c-exchange-reqItem-enter-txid-error": "Por favor ingrese el txId aquí",
  "c-exchange-reqItem-enter-txid-placeholder": "Ingrese txId aquí",
  "c-exchange-reqItem-verifying-card": "Verificando la tarjeta del usuario",
  "c-exchange-reqItem-moderator-verifying":
    "El moderador está verificando los datos",
  "c-exchange-reqItem-waiting-alm": "Esperando resultados de ALM",
  "c-exchange-reqItem-preparing-crypto":
    "Preparando la dirección de la plataforma",
  "c-exchange-reqItem-preparing-fiat": "Preparando los datos del fiat",
  "c-exchange-reqItem-sending-crypto": "Enviando cripto",
  "c-exchange-reqItem-sending-fiat": "Enviando fiat",
  "c-exchange-reqItem-waiting-payment": "Esperando el pago del usuario",
  "c-exchange-reqItem-completed": "Completado",
  "c-exchange-reqItem-failed": "Fallido",
  "c-exchange-reqItem-cancelled": "Cancelado",
  "c-exchange-reqItem-cancel-exchange-request":
    "Cancelar la solicitud de intercambio",
  "c-exchange-reqItem-confirm-documents": "Confirmar documentos",
  "c-exchange-reqItem-documents-confirmed": "Documentos confirmados",
  "c-exchange-reqItem-payment-sent": "El pago ha sido enviado",
  "c-exchange-reqItem-confirm-user-payment": "Confirmar el pago del usuario",
  "c-exchange-reqItem-complete-request": "Completar solicitud",
  "c-exchange-reqItem-id": "ID",
  "c-exchange-reqItem-exchanging": "Intercambiando",
  "c-exchange-reqItem-crypto-address": "Dirección de cripto del usuario",
  "c-exchange-reqItem-fiat-card-number": "Número de tarjeta fiat del usuario",
  "c-exchange-reqItem-request-failed": "La solicitud ha fallado",
  "c-exchange-reqItem-alm-link": "Enlace a los resultados de ALM",
  "c-exchange-reqItem-check-here": "verificar aquí",
  "c-exchange-reqItem-request-cancelled": "Solicitud cancelada",
  "c-exchange-reqItem-request-fulfilled": "¡Su solicitud ha sido completada!",
  "c-exchange-reqItem-thank-you":
    "Gracias por elegirnos como su servicio de intercambio preferido. Apreciamos su confianza en nosotros y esperamos poder atenderle nuevamente.",
  "c-exchange-reqItem-address-preparation":
    "Aquí estará la dirección para su transferencia.",
  "c-exchange-reqItem-security-check":
    "Espere hasta que se completen todas las preparaciones y verificaciones de seguridad.",
  "c-exchange-reqItem-waiting-for-transaction":
    "La plataforma está esperando su transacción.",
  "c-exchange-reqItem-transfer-here": "Transfiera los fondos aquí",
  "c-exchange-reqItem-wallet": "billetera",
  "c-exchange-reqItem-payment-option": "Opción de pago",
  "c-exchange-reqItem-card-number": "Número de tarjeta",
  "c-exchange-reqItem-description": "Descripción",
  "c-exchange-reqItem-balance": "saldo",
  "c-exchange-reqItem-payment-instructions":
    "Después de realizar el pago, por favor comparta una imagen de la factura (o captura de pantalla) de su pago en el chat",
  "c-exchange-reqItem-verification-required":
    "Verificación de identidad requerida",
  "c-exchange-reqItem-upload-photo-instructions":
    "Para continuar con su solicitud de intercambio, necesitamos que cargue una foto de usted sosteniendo la tarjeta de crédito que planea usar. Asegúrese de lo siguiente:",
  "c-exchange-reqItem-photo-instruction-cardholder":
    "El nombre del titular de la tarjeta y todos los dígitos del número de tarjeta son claramente visibles.",
  "c-exchange-reqItem-photo-instruction-face":
    "Su rostro es claramente visible en la foto.",
  "c-exchange-reqItem-click-confirm-documents":
    "Una vez que haya subido la foto en el chat, haga clic en el botón 'Confirmar documentos' de arriba y espere a que nuestro moderador verifique su información.",
  "c-exchange-reqItem-verification-in-progress": "Verificación en progreso",
  "c-exchange-reqItem-moderator-review-in-progress":
    "Gracias por subir su foto. Espere mientras nuestro moderador revisa y verifica su información. Este proceso puede tardar unos minutos. Agradecemos su paciencia.",
  "c-exchange-reqItem-select-payment-option-description":
    "Elija una opción de pago de la lista a continuación. La opción seleccionada se mostrará al cliente como el método de pago para transferir fondos.",
  "c-exchange-reqItem-proceed-with-selected-option":
    "Continuar con la opción seleccionada",
  "c-exchange-reqItem-alm-check":
    "Estamos realizando una verificación ALM en su billetera",
  "c-exchange-reqItem-preparing-address":
    "SolidSwap está preparando la dirección para su transferencia de criptomonedas, por favor espere.",
  "c-exchange-reqItem-transfer-funds":
    "Transfiera los fondos a la billetera proporcionada a continuación y haga clic en el botón 'El pago fue enviado'",
  "c-exchange-reqItem-confirming-payment-text":
    "Por favor, espere mientras SolidSwap confirma su pago.",
  "c-exchange-reqItem-transfer-confirmed":
    "Su transferencia está confirmada, espere a que SolidSwap envíe sus fondos a",
  "c-exchange-reqItem-identity-verification":
    "Se requiere verificación de identidad",
  "c-exchange-reqItem-upload-photo":
    "Suba una foto del frente de la tarjeta con la transacción en el fondo. Puede ocultar los datos de la tarjeta, excepto los dígitos del número de la tarjeta.",
  "c-exchange-reqItem-virtual-card-intro": "Si tiene una tarjeta virtual:",
  "c-exchange-reqItem-first-way": "Primera manera:",
  "c-exchange-reqItem-first-way-desc":
    "Suba una captura de pantalla con dos ventanas abiertas al mismo tiempo: la primera es la página de la transacción con su número de pedido, la segunda es la ventana con los detalles de la tarjeta en la página de su banco.",
  "c-exchange-reqItem-second-way": "Segunda manera:",
  "c-exchange-reqItem-second-way-desc":
    "Suba una foto o captura de pantalla de su tarjeta virtual en la aplicación móvil o en la página web de su banco con la orden actual de fondo. Puede ocultar los datos de la tarjeta, excepto los dígitos del número de la tarjeta.",
  "c-exchange-reqItem-waiting-moderator":
    "Espere a que el moderador revise los documentos cargados",
  "c-exchange-reqItem-transfer-to-card":
    "Transfiera los fondos a la tarjeta proporcionada a continuación. Una vez que haya realizado el pago, comparta una imagen de la factura (o captura de pantalla) de su pago en el chat y haga clic en el botón 'El pago fue enviado'",
  "c-exchange-reqItem-crypto-transfer-confirmed":
    "Su transferencia está confirmada, por favor espere a que SolidSwap envíe sus fondos",
  "c-exchange-reqItem-faq-promotion": "Preguntas frecuentes sobre la promoción",
  "c-exchange-reqItem-solid-swap-top-service":
    "Cuando elige Solid Swap para intercambiar sus activos digitales y fiduciarios, no solo obtiene un servicio de primera calidad, sino también la oportunidad de agregar",
  "c-exchange-reqItem-add-to-account-monthly": "a su cuenta cada mes.",
  "c-exchange-reqItem-how-can-you-do-it": "¿Cómo puedes hacerlo?",
  "c-exchange-reqItem-register-and-exchange":
    "Regístrese y realice un intercambio en SolidSwap, cada intercambio cuenta como una oportunidad de ganar.",
  "c-exchange-reqItem-leave-review-on":
    "Deje su opinión honesta en uno de los siguientes recursos",
  "c-exchange-reqItem-send-email-screenshot":
    "Envíe su correo electrónico y una captura de pantalla de su opinión a",
  "c-exchange-reqItem-manager-telegram": "nuestro gerente en Telegram",
  "c-exchange-reqItem-or-email-to": "o envíelo por correo electrónico a",
  "c-exchange-reqItem-enter-giveaway":
    "para participar en el sorteo de 300 USDT.",
  "c-exchange-reqItem-promotion-period":
    "La promoción se extiende desde el 1 de octubre hasta el 31 de diciembre de 2024.",
  "c-exchange-reqItem-winners-selected-randomly":
    "Los ganadores se seleccionan al azar entre los participantes que cumplan con los requisitos cada mes durante el período de la promoción. Los resultados se anunciarán en",
  "c-exchange-reqItem-participate-again":
    "Para volver a participar el mes siguiente, debe realizar otro intercambio y dejar una opinión adicional.",
};
