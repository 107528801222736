export default {
  "p-exchange-exchange": "Փոխանակում",
  "p-exchange-for": "համար",
  "p-exchange-fixed-rate":
    "Փոխանակման կուրսը ֆիքսվելու է հայտի ստեղծումից հետո",
  "p-exchange-current-rate": "Ընթացիկ փոխանակման կուրսը այս զույգի համար է՝",
  "p-exchange-send": "Ուղարկել",
  "p-exchange-amount-to-send": "Ուղարկվող գումարը",
  "p-exchange-crypto": "Կրիպտո",
  "p-exchange-fiat": "Ֆիատ",
  "p-exchange-card-number-verification":
    "Ձեր քարտի համարը՝ (անհրաժեշտ է ստուգման համար)",
  "p-exchange-card-tooltip":
    "Նախքան ձեր գումարը ընդունելը, մենք պետք է հաստատենք ձեր ինքնությունը և ստուգենք, որ քարտը սև ցուցակում չէ",
  "p-exchange-learn-more": "(ավելին իմանալու համար?)",
  "p-exchange-wallet-verification":
    "Ձեր {currency} դրամապանակը՝ (անհրաժեշտ է ALM ստուգման համար)",
  "p-exchange-wallet-tooltip":
    "ALM ստուգումը պահանջում է կրիպտոարժույթի ստուգում, SolidSwap-ը երաշխավորում է, որ բոլոր կրիպտո առաքումները և ստացումները «մաքուր» են",
  "p-exchange-enter-wallet": "Մուտքագրեք դրամապանակը այստեղ",
  "p-exchange-receive": "Ստանալ",
  "p-exchange-on": "{name} վրա",
  "p-exchange-amount-to-receive": "Ստացվող գումարը",
  "p-exchange-card-number": "Քարտի համարը",
  "p-exchange-wallet-receive": "Դրամապանակ ստանալու համար",
  "p-exchange-email-required": "Էլ-փոստ (պարտադիր)",
  "p-exchange-terms-message": "Ես կարդացել եմ և ընդունում եմ",
  "p-exchange-terms": "Օգտագործման պայմանները",
  "p-exchange-and": "և",
  "p-exchange-privacy": "Գաղտնիության քաղաքականություն",
  "p-exchange-alm-message": "Ես կարդացել եմ և ընդունում եմ",
  "p-exchange-captcha": "Կապչա",
  "p-exchange-not-allowed": "Թույլատրված չէ",
  "p-exchange-not-allowed-message":
    "SolidSwap-ի ադմիններն ու մոդերատորները թույլատրված չեն փոխանակման հայտեր ստեղծելու համար",
  "p-exchange-proceed": "Շարունակել փոխանակումը",
  "p-exchange-menu-create-request": "Ստեղծեք փոխանակման հայտ",
  "p-exchange-menu-requests": "Հայտեր",
  "p-exchange-accept-terms": "Խնդրում ենք ընդունել օգտագործման պայմանները",
  "p-exchange-accept-alm": "Խնդրում ենք կարդալ և ընդունել AML/CTF և KYC",
  "p-exchange-enter-captcha": "Խնդրում ենք մուտքագրել captcha-ն",
  "p-exchange-send-amount-min":
    "Ուղարկվող գումարը չպետք է լինի պակաս {amount} {currency}-ից",
  "p-exchange-recieve-amount-min":
    "Ստացվող գումարը չպետք է լինի պակաս {amount} {currency}-ից",
  "p-exchange-send-amount-max":
    "Ուղարկվող գումարը չպետք է գերազանցի {amount} {currency}",
  "p-exchange-recieve-amount-max":
    "Ստացվող գումարը չպետք է գերազանցի {amount} {currency}",
  "p-exchange-send-amount-required": "Ուղարկվող գումարը պարտադիր է",
  "p-exchange-recieve-amount-required": "Ստացվող գումարը պարտադիր է",
  "p-exchange-public-address-required": "Ձեր դրամապանակի հասցեն պարտադիր է",
  "p-exchange-card-number-required": "Ձեր քարտի համարը պարտադիր է",
  "p-exchange-rates-updated-now": "Դրամաչափերը կթարմացվեն հիմա!",
  "p-exchange-rates-updated-in":
    "Դրամաչափերը կթարմացվեն {minutes}:{seconds}-ից",
  "p-exchange-procees-to-search": "Շարունակել ստեղծել փոխանակում",
};
