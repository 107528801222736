export default {
  "p-login-head-title": "Connexion | Accédez à votre compte Solid Swap",
  "p-login-head-description":
    "Connectez-vous à votre compte Solid Swap pour commencer à trader des cryptomonnaies en toute sécurité et efficacement",
  "p-login-head-keywords":
    "connexion, compte Solid Swap, accès au trading de cryptomonnaies",
  "p-login-rules-email": "Veuillez entrer votre email",
  "p-login-rules-password": "Veuillez entrer votre mot de passe",
  "p-login-error-default":
    "Oups, quelque chose a mal tourné pendant la connexion.",
  "p-login-error-2fa-wrong": "Code 2FA incorrect ! Veuillez réessayer.",
  "p-login-error-user-not-found": "Utilisateur non trouvé",
  "p-login-error-email-verification":
    "Votre email doit d'abord être vérifié. Veuillez vérifier votre email.",
  "p-login-error-suspended":
    "Votre compte a été suspendu. Veuillez vérifier votre email pour plus de détails.",
  "p-login-error-locked-title": "Temporairement verrouillé",
  "p-login-error-locked-message":
    "Vous avez fait {failedLockCount} tentatives de connexion infructueuses ou plus. Pour des raisons de sécurité, veuillez attendre 60 minutes avant d'essayer à nouveau. Si vous avez besoin d'aide immédiate ou si vous avez oublié votre mot de passe, utilisez l'option 'Mot de passe oublié' pour réinitialiser votre mot de passe.",
  "p-login-error-wrong-password-title": "Mot de passe incorrect",
  "p-login-error-wrong-password-message":
    "Vous avez fait {failedAttemptsCount} tentative(s) de connexion infructueuse(s). Veuillez faire attention : après {failedLockCount} tentatives infructueuses, votre compte sera temporairement verrouillé pendant 60 minutes.",
  "p-login-password-reset-success": "Succès !",
  "p-login-password-reset-error":
    "Quelque chose a mal tourné pendant la réinitialisation du mot de passe, veuillez réessayer plus tard",
  "p-login-title": "Connexion",
  "p-login-label-email": "E-mail",
  "p-login-label-password": "Mot de passe",
  "p-login-email-placeholder": "Entrez votre email",
  "p-login-password-placeholder": "Entrez votre mot de passe",
  "p-login-forgot-password": "Mot de passe oublié ?",
  "p-login-submit-button": "Connexion",
  "p-login-2fa-prompt": "Entrez le code 2FA depuis votre application :",
  "p-login-2fa-placeholder": "Entrez le code...",
  "p-login-2fa-button": "Continuer",
  "p-login-password-reset-success-title": "Email envoyé !",
  "p-login-password-reset-success-message":
    "Nous avons envoyé un lien de réinitialisation du mot de passe à votre adresse email. Veuillez vérifier votre boîte de réception et suivre les instructions pour réinitialiser votre mot de passe.",
  "p-login-password-reset-alert":
    "Si vous ne voyez pas l'email, veuillez vérifier votre dossier spam ou courrier indésirable. Si vous ne l'avez toujours pas reçu, essayez de soumettre à nouveau votre adresse email ou contactez notre équipe de support pour obtenir de l'aide.",
  "p-login-back-to-login": "Retour à la connexion",
  "p-login-forgot-password-title": "Mot de passe oublié ?",
  "p-login-forgot-password-message":
    "Veuillez entrer l'adresse email associée à votre compte et cliquer sur 'Continuer'.",
  "p-login-password-reset-instructions":
    "Nous vous enverrons un email avec des instructions pour réinitialiser votre mot de passe. Si vous ne recevez pas l'email dans quelques minutes, veuillez vérifier votre dossier spam ou réessayer.",
  "p-login-enter-email": "Entrez votre email",
  "p-login-cancel": "Annuler",
  "p-login-proceed": "Continuer",
  "p-login-go-to-registration":
    "Vous n'avez pas de compte ? Aller à l'inscription",
  "p-login-welcome-title": "Bienvenue dans une nouvelle ère de trading !",
  "p-login-welcome-message-1":
    "Connectez-vous en toute sécurité à un monde d'actifs numériques.",
  "p-login-welcome-message-2":
    "Commencez à trader en toute confiance et commodité sur notre plateforme P2P de confiance.",
  "p-login-welcome-message-3":
    "Votre passerelle vers des transactions crypto fluides vous attend.",
};
