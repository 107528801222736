export default {
  "p-support-chat-welcome": "¡Bienvenido a nuestro chat de soporte!",
  "p-support-chat-intro":
    "Estamos aquí para ayudarle con cualquier pregunta o inquietud que pueda tener. Nuestro objetivo es brindarle la mejor experiencia de soporte posible. Así es como puede comenzar:",
  "p-support-chat-enter-details-prefix": "Ingrese sus datos: ",
  "p-support-chat-enter-details-suffix":
    "título de su pregunta. Esta información nos ayudará a brindarle una mejor asistencia y resolver sus inquietudes de manera más eficiente.",
  "p-support-chat-open-chat":
    'Abra el chat: haga clic en el botón "Abrir chat de soporte" para comenzar.',
  "p-support-chat-start-conversation":
    "Inicie la conversación: una vez que haya ingresado sus datos y enviado el formulario, aparecerá un cuadro de chat en la página. Uno de nuestros moderadores se unirá al chat en breve para ayudarle.",
  "p-support-chat-appreciation":
    "¡Agradecemos su paciencia y esperamos poder ayudarle!",
  "p-support-chat-your-name": "Su nombre",
  "p-support-chat-your-email": "Su correo electrónico",
  "p-support-chat-your-question": "Su pregunta",
  "p-support-chat-open-chat-button": "Abrir chat de soporte",
  "p-support-chat-wait-moderator": "Espere a que el moderador se una",
  "p-support-chat-contact-alternative":
    "Si tiene problemas para usar el chat o prefiere otras formas de contactarnos, no dude en comunicarse a través de nuestra",
  "p-support-chat-contact-page": "página de contacto",
  "p-support-chat-or-email": "o envíenos un correo electrónico a",
  "p-support-chat-thank-you": "¡Gracias por elegir nuestros servicios!",
  "p-support-chat-ask-question": "Haga una pregunta",
  "p-support-chat-name-required": "El nombre es obligatorio",
  "p-support-chat-email-required": "El correo electrónico es obligatorio",
  "p-support-chat-valid-email": "Ingrese un correo electrónico válido",
  "p-support-chat-chat-started": "¡Chat de soporte iniciado!",
  "p-support-chat-name-email": "nombre, correo electrónico,",
};
