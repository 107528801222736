export default {
  "c-exchange-steps-verifying-card": "Vérification de la carte utilisateur",
  "c-exchange-steps-waiting-alm": "En attente des résultats ALM",
  "c-exchange-steps-preparing-crypto":
    "Préparation de l'adresse de la plateforme",
  "c-exchange-steps-moderator-verifying": "Le modérateur vérifie les données",
  "c-exchange-steps-preparing-fiat": "Préparation des coordonnées bancaires",
  "c-exchange-steps-waiting-payment": "En attente du paiement de l'utilisateur",
  "c-exchange-steps-confirming-payment": "Confirmation du paiement",
  "c-exchange-steps-sending-crypto": "Envoi de crypto",
  "c-exchange-steps-sending-fiat": "Envoi de fiat",
  "c-exchange-steps-request-completed": "Demande terminée",
};
