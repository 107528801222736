export default {
  "p-registration-label-email": "E-mail",
  "p-registration-label-username": "Nom d'utilisateur",
  "p-registration-label-password": "Mot de passe",
  "p-registration-label-repeat-password": "Répéter le mot de passe",
  "p-registration-head-title": "Inscription | Créez votre compte Solid Swap",
  "p-registration-head-description":
    "Inscrivez-vous pour un compte Solid Swap pour commencer à trader des cryptomonnaies en toute sécurité et efficacement",
  "p-registration-head-keywords":
    "inscription, créer un compte, inscription Solid Swap, inscription au trading de crypto",
  "p-registration-rules-username": "Veuillez entrer votre nom d'utilisateur",
  "p-registration-rules-username-length":
    "La longueur du nom d'utilisateur doit être comprise entre 5 et 15 caractères",
  "p-registration-rules-email": "Veuillez entrer votre email",
  "p-registration-rules-password": "Veuillez entrer votre mot de passe",
  "p-registration-rules-password-length":
    "La longueur du mot de passe doit être comprise entre 6 et 21 caractères",
  "p-registration-rules-password-confirm":
    "Veuillez confirmer votre mot de passe",
  "p-registration-rules-password-match":
    "Les mots de passe ne correspondent pas",
  "p-registration-rules-terms":
    "Veuillez accepter les conditions d'utilisation",
  "p-registration-success-message":
    "Inscription réussie. Veuillez vérifier votre email pour un lien de confirmation.",
  "p-registration-error-default":
    "Oups, quelque chose a mal tourné pendant l'inscription.",
  "p-registration-error-data":
    "Quelque chose ne va pas avec les données que vous avez entrées",
  "p-registration-error-account-exists":
    "Il semble que vous ayez déjà un compte.",
  "p-registration-login-link": "Veuillez vous connecter",
  "p-registration-title": "Inscription",
  "p-registration-email-placeholder": "Entrez votre email",
  "p-registration-username-placeholder": "Entrez votre nom d'utilisateur",
  "p-registration-password-placeholder": "Entrez votre mot de passe",
  "p-registration-password-confirm-placeholder": "Confirmez votre mot de passe",
  "p-registration-terms-message": "J'accepte les",
  "p-registration-terms": "Conditions d'utilisation",
  "p-registration-and": "et",
  "p-registration-privacy": "Politique de confidentialité",
  "p-registration-continue": "Continuer",
  "p-registration-success-title": "Succès !",
  "p-registration-welcome-title":
    "Rejoignez notre communauté vibrante d'enthousiastes de la crypto !",
  "p-registration-welcome-message-1":
    "Inscrivez-vous maintenant pour libérer tout le potentiel du trading peer-to-peer.",
  "p-registration-welcome-message-2":
    "Expérimentez la liberté, l'équité et l'avenir de la finance.",
  "p-registration-welcome-message-3":
    "Plongez dans le marché diversifié des devises numériques où opportunité et innovation se rencontrent.",
};
