export default {
  "p-tos-title": "Terms of Use | Solid Swap",
  "p-tos-meta-description":
    "Read the terms of use for Solid Swap to understand the rules and guidelines for using our cryptocurrency trading platform",
  "p-tos-meta-keywords":
    "terms of use, user agreement, Solid Swap terms, trading rules",
  "p-tos-header-title": "Terms of Use",
  "p-tos-download-link": "download as file",
  "p-tos-alert":
    "This Customer Agreement may be published in different languages. In case of any discrepancy, please refer to the English version",
  "p-tos-agreement-part-1":
    'This agreement is between you (hereinafter referred to as "you" or "your\'\') and SOLIDSWAP. By accessing, using or clicking “I agree” to any of the Services made available by SOLIDSWAP or affiliates through the website',
  "p-tos-agreement-part-2":
    "), our mobile applications, or any other related Services provided by SOLIDSWAP or its affiliates as further described in Section 4 below (hereinafter “the Service” or collectively, “the Services”) you agree that you have read, understood and accepted all of the terms and conditions contained in this agreement (“the Terms” or “these Terms”), as well as our",
  "p-tos-privacy-policy": "Privacy Policy",
  "p-tos-agreement-part-3":
    ". In addition, when using additional functions of this Service, you may also need to comply with the additional terms and conditions applicable to these functions.",
  "p-tos-warning-part-1":
    "PLEASE READ THESE TERMS CAREFULLY AS THEY GOVERN YOUR USE OF THE SERVICES. THESE TERMS CONTAIN IMPORTANT PROVISIONS INCLUDING AN ARBITRATION PROVISION THAT REQUIRES ALL CLAIMS TO BE RESOLVED BY WAY OF BINDING ARBITRATION. THE TERMS OF THE ARBITRATION PROVISION ARE SET FORTH IN SECTION 15 BELOW TITLED “RESOLVING DISPUTES: FORUM, ARBITRATION, CLASS ACTION WAIVER.” AS WITH ANY ASSET, THE VALUE OF DIGITAL CURRENCIES CAN GO UP OR DOWN AND THERE CAN BE A SUBSTANTIAL RISK THAT YOU MAY LOSE MONEY BUYING, SELLING, HOLDING, OR INVESTING IN DIGITAL CURRENCIES. BY USING THE SERVICES YOU ACKNOWLEDGE AND AGREE THAT:",
  "p-tos-warning-part-2":
    "YOU ARE AWARE OF THE RISKS ASSOCIATED WITH TRANSACTING IN DIGITAL CURRENCIES;",
  "p-tos-warning-part-3":
    "YOU ASSUME ALL RISKS WITH RESPECT TO YOUR USE OF THE SERVICES AND TRADING IN DIGITAL CURRENCIES; SOLIDSWAP IS NOT RESPONSIBLE OR LIABLE FOR ANY SUCH RISKS OR ADVERSE OUTCOMES. BY ACCESSING, USING OR ATTEMPTING TO USE THE SERVICES IN ANY CAPACITY, YOU ACKNOWLEDGE THAT YOU ACCEPT AND AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE, DO NOT ACCESS OR USE THE SERVICES.",
  "p-tos-risk-awareness-1":
    "YOU ARE AWARE OF THE RISKS ASSOCIATED WITH TRANSACTING IN DIGITAL CURRENCIES;",
  "p-tos-risk-awareness-2":
    "YOU ASSUME ALL RISKS WITH RESPECT TO YOUR USE OF THE SERVICES AND TRADING IN DIGITAL CURRENCIES;",
  "p-tos-risk-awareness-3":
    "SOLIDSWAP IS NOT RESPONSIBLE OR LIABLE FOR ANY SUCH RISKS OR ADVERSE OUTCOMES.",
  "p-tos-risk-awareness-4":
    "BY ACCESSING, USING OR ATTEMPTING TO USE THE SERVICES IN ANY CAPACITY, YOU ACKNOWLEDGE THAT YOU ACCEPT AND AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE, DO NOT ACCESS OR USE THE SERVICES.",
  "p-tos-agreement-conditions-title": "Agreement Conditions",
  "p-tos-agreement-conditions-text":
    "SOLIDSWAP reserves the right to modify or change the Terms at any time and at its sole discretion. SOLIDSWAP will provide notice of these changes by updating the revised Terms on the webpage and changing the “[Last revised:]” date on this page. Any and all modifications or changes to these Terms will be effective immediately upon being announced on the website. As such, your continued use of SOLIDSWAP’s Services acts as acceptance of the amended agreement and rules. If you do not agree to any modification to these Terms, you must stop using the Services. SOLIDSWAP encourages you to frequently review the Terms to ensure you understand the terms and conditions that apply to your access to, and use of, the Services.",
  "p-tos-eligibility-title": "Eligibility",
  "p-tos-eligibility-text-1":
    "By registering to use a SOLIDSWAP Account (as defined in Section 5 below), you represent and warrant that you:",
  "p-tos-eligibility-text-2":
    "(a) are of legal age to form a binding contract under applicable law;",
  "p-tos-eligibility-text-3":
    "(b) are an individual, legal person or other organization with full legal capacity and authority to enter into these Terms;",
  "p-tos-eligibility-text-4":
    "(c) have not previously been suspended or removed from using our Services;",
  "p-tos-eligibility-text-5":
    "(d) do not currently have an existing SOLIDSWAP Account;",
  "p-tos-eligibility-text-6": "(e) are not a U.S. or Canada citizen.",
  "p-tos-eligibility-text-7":
    "If you are entering into these Terms on behalf of a legal entity of which you are an employee or an agent, you represent and warrant that you have all necessary rights and authority to bind such legal entity.",
  "p-tos-prohibition-title": "Prohibition of Use",
  "p-tos-prohibition-text-1":
    "By accessing and using the Services, you represent and warrant that you are not on any trade or economic sanctions lists, such as the UN Security Council Sanctions list, designated as a “Specially Designated National” by OFAC (Office of Foreign Assets Control of the U.S. Treasury Department) or placed on the U.S. Commerce Department’s “Denied Persons List”. SOLIDSWAP maintains the right to select its markets and jurisdictions to operate and may restrict or deny the Services in certain countries at its discretion.",
  "p-tos-prohibition-text-2":
    'SOLIDSWAP has the right to block the user\'s funds on the basis of an official request from a government agency, a decision received through the "Dispute" section, due to a violation of the rules or in connection with fraudulent actions against SOLIDSWAP.',
  "p-tos-description-title": "Description of Services",
  "p-tos-description-text-1":
    "Trading Service: SOLIDSWAP provides an online digital asset trading platform (crypto to crypto) for products commonly known as cryptographic tokens, digital tokens or cryptographic currency (collectively, “Digital Currency”). SOLIDSWAP does not provide fiat trading capabilities as part of the Services. SOLIDSWAP functions as a trading platform provider and not as a buyer or seller in trades made between traders. Users must register and open an account with SOLIDSWAP as further set forth in Section 5 below and deposit digital assets prior to commencement of trading. Traders may request the withdrawal of their digital assets, subject to the limitations as stated in these Terms.",
  "p-tos-description-text-2":
    "SOLIDSWAP is not a participant in the deal, its task is to block the user's funds (crypto) until the deal is completed.",
  "p-tos-description-text-3":
    "If the user mistakenly sends money, SOLIDSWAP is not responsible for the user's actions. The transfer of funds is the responsibility of the user.",
  "p-tos-community-title": "Community Service",
  "p-tos-community-text-1":
    "SOLIDSWAP provides you with community communication Services. You can copy the users you are interested in, express your views and opinions in the community, and participate in interactions such as Likes. SOLIDSWAP strives to maintain the accuracy of information posted on the Services however it cannot and does not guarantee the accuracy, suitability, reliability, completeness, performance or fitness for any purpose of the content made available through the Services, and will not be liable for any loss or damage that may arise directly or indirectly from your use of such content. Information on the Services can be subjected to change without notice and is provided for the primary purpose of facilitating users to arrive at independent decisions.",
  "p-tos-community-text-2":
    "SOLIDSWAP does not provide investment or advisory advice and will have no liability for the use or interpretation of information as stated on the Services or other communication mediums. All users of the Services must understand that there are risks involved in trading in Digital Currencies. SOLIDSWAP encourages all users to exercise prudence and trade responsibly within their own means.",
  "p-tos-community-text-3":
    'If there is a conflict between users and users cannot resolve the conflict among themselves, each of them can contact the "Dispute" section and use the Services of a moderator. When accessing the specified section within 1 hour, the moderator requests information about the details of the conflict and, within 24 hours of receiving the requested documents, makes a decision in favor of one of the users.',
  "p-tos-account-title": "SOLIDSWAP Account Registration & Requirements",
  "p-tos-registration-title": "5.1 Registration",
  "p-tos-registration-text-1":
    "All users of the Services (each, a “User”) must register at",
  "p-tos-registration-text-2":
    "for a SOLIDSWAP account (an “Account”) before using the Services. To register for an Account, you must provide your real name, email address and password, as well as accept the Terms of Use and Privacy Policy. SOLIDSWAP may, at its sole discretion, refuse to open an account for you. You agree to provide complete and accurate information when opening an Account and agree to promptly update any information you provide to SOLIDSWAP so that such information is complete and accurate at all times. Each registration is for a single user only and each User (including with respect to any User that is a business or legal entity) may only maintain one active Account with SOLIDSWAP.",
  "p-tos-verification-title": "5.2 User Identity Verification",
  "p-tos-verification-text-1":
    "With registration of an account on SOLIDSWAP, you agree to share personal information requested for the purposes of identity verification. This information is used specifically for the detection of money laundering, terrorist financing, fraud and other financial crimes on the SOLIDSWAP platform. We will collect, use and share this information in accordance with our posted Privacy Policy. In addition to providing this information, to facilitate compliance with global industry standards for data retention, you agree to permit us to keep a record of such information for the lifetime of your account plus 5 years beyond account closing. You also authorize us to make inquiries, either directly or through third parties, that are deemed necessary to verify your identity or to protect you and/or us against financial crimes such as fraud.",
  "p-tos-verification-text-2":
    "The identity verification information we request may include, but is not limited to, your: Name, Email Address, Contact Information, Telephone Number, Username, Government Issued ID, Date of Birth and other information collected at the time of account registration. In providing this required information, you confirm that it is accurate and authentic, and that the documents are a true reflection of your primary physical country of residence. In this regard, SOLIDSWAP reserves the right at its absolute discretion to reject any applicant who provides ID documents that evidence, indicate or confirm that he/she is using some form of digital and/or virtual government residency/identification from any country that allows the issuance of such residency/identification. SOLIDSWAP further reserves the right to keep confidential the list of such countries and amend or alter such list as and when it deems fit without requiring permission or approval from any third parties, nor shall it be required to divulge this list to any party, applicant or user unless lawfully compelled to do so by a competent authority. Post-registration, you must guarantee that the information is truthful, complete and updated in a timely manner with any changes. If there is any reasonable doubt that any information provided by you is wrong, untruthful, outdated or incomplete, SOLIDSWAP shall have the right to send you a notice to demand corrections, remove relevant information directly and, as the case may be, terminate all or part of the Services to you. You shall be solely and fully responsible for any loss or expenses incurred during the use of SOLIDSWAP Service if you cannot be reached through the contact information provided. You hereby acknowledge and agree that you have the obligation to keep all information provided up to date if there are any changes.",
  "p-tos-verification-text-3":
    "BY SIGNING UP FOR ACCOUNT YOU HEREBY AUTHORIZE SOLIDSWAP TO MAKE INQUIRIES, WHETHER DIRECTLY OR THROUGH THIRD PARTIES, THAT SOLIDSWAP CONSIDERS NECESSARY TO VERIFY YOUR IDENTITY OR PROTECT YOU AND/OR SOLIDSWAP AGAINST FRAUD OR OTHER FINANCIAL CRIMES, AND TO TAKE ACTION SOLIDSWAP REASONABLY DEEMS NECESSARY BASED ON THE RESULTS OF SUCH INQUIRIES. YOU ALSO ACKNOWLEDGE AND AGREE THAT YOUR PERSONAL INFORMATION MAY BE DISCLOSED TO CREDIT REFERENCE AND FRAUD PREVENTION OR FINANCIAL CRIME AGENCIES, AND THAT THESE AGENCIES MAY RESPOND TO OUR INQUIRIES IN FULL.",
  "p-tos-guidelines-title": "Guidelines for Usage of the Services",
  "p-tos-guidelines-subtitle": "6.1 License",
  "p-tos-guidelines-text-1":
    "Subject to your continued compliance with the express terms and conditions of these Terms, SOLIDSWAP provides to you a revocable, limited, royalty-free, non-exclusive, non-transferable, and non-sublicensable license to access and use the Services on your computer or other internet compatible device for your personal, internal use only. You are not permitted to use the Services for any resale or commercial use including to place trades on behalf of another person or entity. All such use is expressly prohibited and shall constitute a material violation of these Terms. The content layout, formatting, features of and access privileges for the Services shall be as specified by SOLIDSWAP at its sole discretion. All rights not expressly granted under these Terms are hereby reserved. Accordingly, you are hereby prohibited from using the Services in any manner that is not expressly and unambiguously authorized by these Terms.",
  "p-tos-guidelines-text-2":
    "These Terms provide only a limited license to access and use the Services. Accordingly, you hereby agree that SOLIDSWAP transfers no ownership or intellectual property interest or title in and to the Services or any SOLIDSWAP intellectual property to you or anyone else in connection with your use of the Services. All text, graphics, user interfaces, visual interfaces, photographs, sounds, artwork, computer code (including html code), programs, software, products, information, and documentation as well as the design, structure, selection, coordination, expression, “look and feel,” and arrangement of any content contained on or available through the Services are exclusively owned, controlled, and/or licensed by SOLIDSWAP or its members, parent(s), licensors, or affiliates.",
  "p-tos-guidelines-text-3":
    "SOLIDSWAP will own any feedback, suggestions, ideas, or other information or materials regarding SOLIDSWAP or the Services that you provide, whether by email, through the Services or otherwise (“Feedback”). You hereby assign to SOLIDSWAP all right, title and interest to Feedback together with all associated intellectual property rights. You will not be entitled to, and hereby waive any claim for, acknowledgment or compensation based on any Feedback or any modifications made based on any Feedback.",
  "p-tos-restrictions-title": "6.2 Restrictions",
  "p-tos-restrictions-text-1":
    "When you use the Services you agree and covenant to observe the following:",
  "p-tos-restrictions-text-2":
    "(a) All the activities that you carry out during the use of the Services will be in compliance with the requirements of applicable laws, regulations, as well as the various guidelines of SOLIDSWAP;",
  "p-tos-restrictions-text-3":
    "(b) Your use of the Services will not be in violation of public interests, public ethics or other’s legitimate interests including taking any action that would interfere with, disrupt, negatively affect, or inhibit other Users from using the Services;",
  "p-tos-restrictions-text-4":
    "(c) You agree not to use the Services for engaging in market manipulation (such as pump and dump schemes, wash trading, self-trading, front running, quote stuffing, and spoofing or layering regardless of whether prohibited by law).",
  "p-tos-restrictions-text-5":
    "SOLIDSWAP DOES NOT VERIFY THE SOURCE OF FUNDS IN USERS' WALLETS AND IS NOT RESPONSIBLE FOR THE ORIGIN OF FUNDS.",
  "p-tos-restrictions-text-6":
    "SOLIDSWAP PROHIBITS THE USE OF FUNDS RECEIVED FROM THE TRADING OF ILLEGAL SUBSTANCES, SCAM, OTHER ORIGIN ASSOCIATED WITH HARM TO LIFE AND HEALTH.",
  "p-tos-restrictions-text-7":
    "The following commercial uses of SOLIDSWAP data is prohibited unless written consent from SOLIDSWAP is granted:",
  "p-tos-restrictions-text-8":
    "(a) Exchange services that use quotes or order book information from SOLIDSWAP;",
  "p-tos-restrictions-text-9":
    "(b) Data feed or data stream services that make use of any market data from SOLIDSWAP;",
  "p-tos-restrictions-text-10":
    "(c) Any other websites/apps/services that charge for, or otherwise commercially monetize (including through advertising or referral fees), market data obtained from SOLIDSWAP.",
  "p-tos-restrictions-text-11":
    "You may not modify, reproduce, duplicate, copy, download, store, further transmit, disseminate, transfer, disassemble, broadcast, publish, remove or alter any proprietary notices or labels, license, sublicense, sell, mirror, frame, rent, lease, private label, grant a security interest in, create derivative works of, or otherwise exploit the Properties, or any portion of the Properties without SOLIDSWAP’s prior written consent.",
  "p-tos-orders-title": "Orders and Service Fees",
  "p-tos-orders-subtitle": "7.1 Orders",
  "p-tos-orders-text-1":
    "Upon placing an instruction to effect a trade using the Services (an “Order”), your Account will be updated to reflect the open Order and your Order will be included in SOLIDSWAP’s order book for matching with Orders from other Users. If all or a portion of your Order is matched with another User, the Services will execute an exchange (a “Trade”). Upon execution of a Trade, your Account will be updated to reflect that the Order has either been closed due to having been fully executed, or updated to reflect any partial fulfillment of the Order. Orders will remain open until fully executed or cancelled in accordance with subsection (7.2) below. For purposes of effectuating a Trade, you authorize SOLIDSWAP to take temporary control of the Digital Currency that you are disposing of in the Trade.",
  "p-tos-orders-text-2":
    "The minimum amount of a peer to peer transaction is 15 euros, the maximum is 100 000 000 euros.",
  "p-tos-orders-text-3":
    "The minimum amount of deposit and withdrawal from the wallet is 50 euros, if you deposit less than 50 euros; this amount is lost and cannot be refunded.",
  "p-tos-orders-text-4":
    "The commission for peer to peer transactions is paid by the cryptocurrency seller in the amount of 0.2% of the transaction.",
  "p-tos-orders-text-5":
    "The commission for withdrawal from the wallet is dynamic; it depends on the network load of the exported currency, except for USDT. In this case the withdrawal cost is 1 USDT.",
  "p-tos-cancellations-title": "7.2 Cancellations",
  "p-tos-cancellations-text-1":
    "You may only cancel an order initiated via the Services if such cancellation occurs before your Order has been matched with an Order from another user. Once your Order has been matched with an Order from another user, you may not change, withdraw, or cancel your authorization for SOLIDSWAP to complete such Order. If any order has been partially matched, you may cancel the unmatched portion of the Order unless and until the unmatched portion has been matched. SOLIDSWAP reserves the right to refuse any cancellation request associated with an Order after you have submitted such Order. If you have an insufficient amount of Digital Currency in your Account to fulfill an Order, SOLIDSWAP may cancel the entire Order or may fulfill a partial Order that can be covered by the Digital Currency in your Account (in each case after deducting any fees payable to SOLIDSWAP in connection with the Trade as described in subsection (7.3) below).",
  "p-tos-fees-title": "7.3 Fees",
  "p-tos-fees-text-1": "You agree to pay SOLIDSWAP the fees set forth at",
  "p-tos-fees-text-2":
    "which may be updated from time to time in SOLIDSWAP’s sole discretion. Any such updated fees will apply prospectively to any trades or other transactions that take place following the effective date of such updated fees. You authorize SOLIDSWAP to remove any amounts from your Account for any applicable fees owed by you under these Terms. Should there be insufficient balance in your Account to deduct any fees due and owing to SOLIDSWAP under this provision, you agree that you shall forthwith deposit such amount into your Account as soon as possible (and in no event later than three (3) working days after such fees become payable) as to enable SOLIDSWAP to deduct the appropriate fees. Failure to do so will entitle SOLIDSWAP at its own absolute discretion to suspend, close or cancel the Account and treat the unpaid fees as a debt owing by you to SOLIDSWAP and thereby an amount recoverable from you by any legal means available to SOLIDSWAP. Any interest on unpaid fees, as well as any and all costs and charges arising from the recovery actions of unpaid fees, shall be solely borne by you and payable to SOLIDSWAP on an indemnity basis.",
  "p-tos-futures-title": "Futures Trading",
  "p-tos-futures-subtitle": "8.1 Risks",
  "p-tos-futures-text-1":
    "Futures Trading are highly risky. As a futures trader, you acknowledge and agree that you shall access and use the margin trading and borrowing service at your own risks.",
  "p-tos-enter-futures-title": "8.2 To enter into Futures Trading",
  "p-tos-enter-futures-text-1":
    "You represent and warrant that you are not a citizen of the U.S., nor on any trade or economic sanctions lists, such as designated as a “Specially Designated National” by OFAC (Office of Foreign Assets Control of the U.S. Treasury Department).",
  "p-tos-enter-futures-text-2":
    "You should fully understand the risks associated with the futures trading and lending and are solely responsible and liable for any and all trading and non-trading activity on your SOLIDSWAP account. Do not enter into a transaction or invest in funds that are above your financial abilities. You are solely responsible and liable for knowing the true status of any position, even if presented incorrectly by SOLIDSWAP at any time.",
  "p-tos-enter-futures-text-3":
    "You agree to maintain in your SOLIDSWAP futures account a sufficient amount of blockchain assets required by SOLIDSWAP for users to engage in futures trading and return your loan in full timely. Failure to maintain a sufficient amount of assets or fail to return outstanding loan duly can result in the forced-liquidation of assets in your futures account.",
  "p-tos-enter-futures-text-4":
    "SOLIDSWAP cannot guarantee to stop losses even with the ability to force-liquidate any of your positions. If, after your positions are liquidated, your assets are insufficient to cover the outstanding loan, you are still responsible for any additional assets shortfall.",
  "p-tos-enter-futures-text-5":
    "SOLIDSWAP may, in its sole discretion, perform measures to mitigate potential losses to you on your behalf, including, but not limited to, transferring balances from your contract to your exchange account and/or from your exchange to your futures account.",
  "p-tos-enter-futures-text-6":
    "During SOLIDSWAP system maintenance, you agree that you are solely responsible and liable for managing your futures account under risk, including but not limited to, keep or close your position and repay your loan.",
  "p-tos-enter-futures-text-7":
    "You agree that you conduct all trading, futures trading and/or borrowing on your own account and claim full responsibility for your activities. SOLIDSWAP does not take any responsibility for any loss or damage incurred as a result of your use of any Services or your failure to understand the risks associated with assets use generally or your use of our Services.",
  "p-tos-enter-futures-text-8":
    "You are aware that on liquidation, all open orders are immediately cancelled. You may lose all your positions and all the funds in your contract account. If SOLIDSWAP cannot close all the positions on liquidation, the insurance fund and/or counterparty-liquidation will take effect. SOLIDSWAP takes every possible step to avoid counterparty liquidation to minimize the potential impact of any counterparty liquidation when it does occur. Unfortunately, due to the volatility in the Crypto markets, and the high leverage offered to clients, it is not possible to fully avoid this possibility. In order to provide the best possible client experience, SOLIDSWAP are striving to keep counterparty liquidations to an absolute minimum.",
  "p-tos-enter-futures-text-9":
    "Due to network delay, computer system failures and other force majeure, which may lead to delay, suspension or deviation of SOLIDSWAP service execution, SOLIDSWAP will use commercially reasonable effort to ensure but not promise that SOLIDSWAP service execution system run stably and effectively. SOLIDSWAP does not take any responsibility if the final execution doesn’t match your expectations due to the above factors.",
  "p-tos-prohibited-title": "8.3 Prohibited Trading Matters",
  "p-tos-prohibited-text-1":
    "Users are forbidden to use multiple accounts and devices for illegal arbitrage (including but not limited to earning trading fees, commissions, bonuses, hedging, etc.) SOLIDSWAP will take corresponding measures for users who have been detected to be illegally arbitraging in the system. Measures include (but not limited to) restricting accounts, freezing assets, restricting logins, requesting KYC, and more.",
  "p-tos-prohibited-text-2":
    "To ensure the normal trading experience of ordinary users, SOLIDSWAP reserves the right to exercise the following measures for users who are detected by the system to use ultra-short-term trading (ultra-short-term frequent opening and closing positions):",
  "p-tos-prohibited-text-3":
    "Immediately terminate your account and access to our servers, and deduct illegal income.",
  "p-tos-prohibited-text-4":
    "Invalidate all trading that constitute ultra-short-term trading activities (i.e. process trading as if it never occurred).",
  "p-tos-prohibited-text-5":
    "According to our current market price, close all trading that constitutes ultra-short-term trading activities.",
  "p-tos-prohibited-text-6":
    "Increase your position time (that is, you can only close the position after a period of time).",
  "p-tos-prohibited-text-7":
    "SOLIDSWAP strictly prohibits unfair trading behaviors. If you use SOLIDSWAP with the following behaviors, SOLIDSWAP reserves the right to exercise execute control over your account at its own discretion:",
  "p-tos-prohibited-text-8":
    "Participate in price manipulation or any other malicious market behavior.",
  "p-tos-prohibited-text-9":
    "Damage other users or SOLIDSWAP by exploiting vulnerabilities of this service or other unreasonable means.",
  "p-tos-prohibited-text-10":
    "Participate in any other activities that SOLIDSWAP deems harmful to the market.",
  "p-tos-liability-title": "Liability",
  "p-tos-liability-subtitle": "9.1. Disclaimer of Warranties",
  "p-tos-liability-text-1":
    "TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, THE SERVICES, THE SOLIDSWAP MATERIALS AND ANY PRODUCT, SERVICE OR OTHER ITEM PROVIDED BY OR ON BEHALF OF SOLIDSWAP ARE PROVIDED ON AN “AS IS'' AND “AS AVAILABLE” BASIS AND SOLIDSWAP EXPRESSLY DISCLAIMS, AND YOU WAIVE, ANY AND ALL OTHER WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE OR NON- INFRINGEMENT OR WARRANTIES ARISING FROM COURSE OF PERFORMANCE, COURSE OF DEALING OR USAGE IN TRADE. WITHOUT LIMITING THE FOREGOING, SOLIDSWAP DOES NOT REPRESENT OR WARRANT THAT THE SITE, THE SERVICES OR SOLIDSWAP MATERIALS ARE ACCURATE, COMPLETE, RELIABLE, CURRENT, ERROR-FREE, OR FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. SOLIDSWAP DOES NOT GUARANTEE THAT ANY ORDER WILL BE EXECUTED, ACCEPTED, RECORDED OR REMAIN OPEN. EXCEPT FOR THE EXPRESS STATEMENTS SET FORTH IN THIS AGREEMENT, YOU HEREBY ACKNOWLEDGE AND AGREE THAT YOU HAVE NOT RELIED UPON ANY OTHER STATEMENT OR UNDERSTANDING, WHETHER WRITTEN OR ORAL, WITH RESPECT TO YOUR USE AND ACCESS OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, YOU HEREBY UNDERSTAND AND AGREE THAT SOLIDSWAP WILL NOT BE LIABLE FOR ANY LOSSES OR DAMAGES ARISING OUT OF OR RELATING TO: (A) ANY INACCURACY, DEFECT OR OMISSION OF DIGITAL CURRENCY PRICE DATA, (B) ANY ERROR OR DELAY IN THE TRANSMISSION OF SUCH DATA, (C) INTERRUPTION IN ANY SUCH DATA AND (D) ANY DAMAGES INCURRED BY ANOTHER USER’S ACTIONS, OMISSIONS OR VIOLATION OF THIS AGREEMENT.",
  "p-tos-liability-text-2":
    "THE DISCLAIMER OF IMPLIED WARRANTIES CONTAINED HEREIN MAY NOT APPLY IF AND TO THE EXTENT IT IS PROHIBITED BY APPLICABLE LAW OF THE JURISDICTION IN WHICH YOU RESIDE.",
  "p-tos-liability-subtitle-2":
    "9.2 Disclaimer of Damages and Limitation of Liability",
  "p-tos-liability-text-3":
    "TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL SOLIDSWAP, ITS AFFILIATES AND THEIR RESPECTIVE SHAREHOLDERS, MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES, ATTORNEYS, AGENTS, REPRESENTATIVES, SUPPLIERS OR CONTRACTORS BE LIABLE FOR ANY INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE, CONSEQUENTIAL OR SIMILAR DAMAGES OR LIABILITIES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF DATA, INFORMATION, REVENUE, PROFITS OR OTHER BUSINESS OR FINANCIAL BENEFIT) ARISING OUT OF OR IN CONNECTION WITH THE SERVICES, ANY PERFORMANCE OR NON-PERFORMANCE OF THE SERVICES, OR ANY OTHER PRODUCT, SERVICE OR OTHER ITEM PROVIDED BY OR ON BEHALF OF SOLIDSWAP AND ITS AFFILIATES, WHETHER UNDER CONTRACT, STATUTE, STRICT LIABILITY OR OTHER THEORY EVEN IF SOLIDSWAP HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES EXCEPT TO THE EXTENT OF A FINAL JUDICIAL DETERMINATION THAT SUCH DAMAGES WERE A RESULT OF SOLIDSWAP’S GROSS NEGLIGENCE, FRAUD, WILLFUL MISCONDUCT OR INTENTIONAL VIOLATION OF LAW. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.",
  "p-tos-liability-text-4":
    "NOTWITHSTANDING THE FOREGOING, IN NO EVENT WILL THE LIABILITY OF SOLIDSWAP, ITS AFFILIATES AND THEIR RESPECTIVE SHAREHOLDERS, MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES, ATTORNEYS, AGENTS, REPRESENTATIVES, SUPPLIERS OR CONTRACTORS ARISING OUT OF OR IN CONNECTION THE SERVICES, ANY PERFORMANCE OR NON-PERFORMANCE OF THE SERVICES, OR ANY OTHER PRODUCT, SERVICE OR OTHER ITEM PROVIDED BY OR ON BEHALF OF SOLIDSWAP OR ITS AFFILIATES WHETHER UNDER CONTRACT, STATUTE, STRICT LIABILITY OR OTHER THEORY, EXCEED THE AMOUNT OF THE FEES PAID BY YOU TO SOLIDSWAP UNDER THIS AGREEMENT IN THE TWELVE-MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE CLAIM FOR LIABILITY.",
  "p-tos-liability-subtitle-3": "9.3 Indemnification",
  "p-tos-liability-text-5":
    "You agree to indemnify and hold harmless SOLIDSWAP, its affiliates, contractors, licensors, and their respective directors, officers, employees and agents from and against any claims, actions, proceedings, investigations, demands, suits, costs, expenses and damages (including attorneys’ fees, fines or penalties imposed by any regulatory authority) arising out of or related to (i) your use of, or conduct in connection with, the Services, (ii) your breach or our enforcement of these Terms, or (iii) your violation of any applicable law, regulation, or rights of any third party during your use of the Service. If you are obligated to indemnify SOLIDSWAP, its affiliates, contractors, licensors, and their respective directors, officers, employees or agents pursuant to this clause, SOLIDSWAP will have the right, in its sole discretion, to control any action or proceeding and to determine whether SOLIDSWAP wishes to settle, and if so, on what terms.",
  "p-tos-announcements-title": "Announcements",
  "p-tos-announcements-text-1":
    "Please be aware that all official announcements, news, promotions, competitions and airdrops will be listed on SOLIDSWAP official website where we urge all users to refer to regularly. SOLIDSWAP will not be held liable or responsible in any manner of compensation should users incur personal losses arising from ignorance or negligence of the announcements.",
  "p-tos-termination-title": "Termination of Agreement",
  "p-tos-termination-text-1":
    "You agree that SOLIDSWAP shall have the right to immediately suspend your account (and any accounts beneficially owned by related entities or affiliates), freeze or lock the funds in all such accounts, and suspend your access to SOLIDSWAP for any reason including if it suspects any such accounts to be in violation of these Terms, our Privacy Policy, or any applicable laws and regulations. You agree that SOLIDSWAP shall not be liable to you for any permanent or temporary modification, suspension or termination of your Account or access to all or any portion of the Services. SOLIDSWAP shall have the right to keep and use the transaction data or other information related to such accounts.The above account controls may also be applied in the following cases:",
  "p-tos-termination-text-2":
    "The account is subject to a governmental proceeding, criminal investigation or other pending litigation.",
  "p-tos-termination-text-3": "We detect unusual activity in the account.",
  "p-tos-termination-text-4": "We detect unauthorized access to the account.",
  "p-tos-termination-text-5":
    "We are required to do so by a court order or command by a regulatory/government authority.",
  "p-tos-termination-text-6":
    "In case of any of the following events, SOLIDSWAP shall have the right to directly terminate this agreement by cancelling your Account, and shall have the right to permanently freeze (cancel) the authorizations of your account on SOLIDSWAP and withdraw the corresponding SOLIDSWAP Account thereof:",
  "p-tos-termination-text-7": "after SOLIDSWAP terminates Services to you;",
  "p-tos-termination-text-8":
    "you allegedly register or register in any other person’s name as SOLIDSWAP user again, directly or indirectly;",
  "p-tos-termination-text-9":
    "the information that you have provided is untruthful, inaccurate, outdated or incomplete;",
  "p-tos-termination-text-10":
    "when these Terms are amended, you expressly state and notify SOLIDSWAP of your unwillingness to accept the amended Terms;",
  "p-tos-termination-text-11":
    "you request that the Services be terminated; and any other circumstances where SOLIDSWAP deems it should terminate the Services.",
  "p-tos-termination-text-12":
    "Should your Account be terminated, the Account and transactional information required for meeting data retention standards will be securely stored for 5 years. In addition, if a transaction is unfinished during the Account termination process, SOLIDSWAP shall have the right to notify your counterparty of the situation at that time. You acknowledge that a user initiated account exit (right to erasure under GDPR or other equivalent regulations) will also be subjected to the termination protocol stated above.",
  "p-tos-termination-text-13":
    "If SOLIDSWAP receives notice that any funds held in your Account are alleged to have been stolen or otherwise are not lawfully possessed by you, SOLIDSWAP may, but has no obligation to, place an administrative hold on the affected funds and your Account. If SOLIDSWAP does place an administrative hold on some or all of your funds or Account, SOLIDSWAP may continue such hold until such time as the dispute has been resolved and evidence of the resolution acceptable to SOLIDSWAP has been provided to SOLIDSWAP in a form acceptable to SOLIDSWAP. SOLIDSWAP will not involve itself in any such dispute or the resolution of the dispute. You agree that SOLIDSWAP will have no liability or responsibility for any such hold, or for your inability to withdraw funds or execute trades during the period of any such hold.",
  "p-tos-funds-title": "a. Remaining funds after Account termination",
  "p-tos-funds-text-1":
    "Except as set forth in subsection (b) below, once the Account is closed/withdrawn, all remaining balance (which includes charges and liabilities owed to SOLIDSWAP) on the account will be payable immediately to SOLIDSWAP. Upon payment of all outstanding charges to SOLIDSWAP (if any), the User will have 5 business days to withdraw the original amount(s) deposited into the Account (which, for the sake of avoiding any doubt, is net of any trading losses incurred prior to the termination of the account, whether or not reflected in the Account balance at the time of termination). The User shall not be entitled to any net trading profits which shall be forfeited by SOLIDSWAP accordingly. Should the balance in the Account upon termination be less than the original deposited amount, due but not limited to trading losses or earlier withdrawals, the User shall only be entitled to withdraw the lesser existing amount and shall have no recourse whatsoever against SOLIDSWAP for the balance under any circumstances.",
  "p-tos-funds-title-2":
    "b. Remaining funds after Account termination due to fraud, violation of law, or violation of these terms",
  "p-tos-funds-text-2":
    "SOLIDSWAP maintains full custody of the funds and User data/information which may be turned over to governmental authorities in the event of Account suspension/closure arising from fraud investigations, violation of law investigations or violation of these Terms.",
  "p-tos-financial-title": "No Financial Advice",
  "p-tos-financial-text-1":
    "SOLIDSWAP is not your broker, intermediary, agent, or advisor and has no fiduciary relationship or obligation to you in connection with any trades or other decisions or activities affected by you using the Services. No communication or information provided to you by SOLIDSWAP is intended as, or shall be considered or construed as, investment advice, financial advice, trading advice, or any other sort of advice. All trades are executed automatically, based on the parameters of your order instructions and in accordance with posted trade execution procedures, and you are solely responsible for determining whether any investment, investment",
  "p-tos-financial-text-2":
    "strategy or related transaction is appropriate for you based on your personal investment objectives, financial circumstances and risk tolerance. You should consult your legal or tax professional regarding your specific situation. SOLIDSWAP does not recommend that any Digital Currency should be bought, earned, sold, or held by you. Before making the decision to buy, sell or hold any Digital Currency, you should conduct your own due diligence and consult your financial advisors before making any investment decision. SOLIDSWAP will not be held responsible for the decisions you make to buy, sell, or hold Digital Currency based on the information provided by SOLIDSWAP.",
  "p-tos-local-laws-title": "Compliance with Local Laws",
  "p-tos-local-laws-text-1":
    "It is the responsibility of the User to abide by local laws in relation to the legal usage of SOLIDSWAP in their local jurisdiction. Users must also factor, to the extent of their local law, all aspects of taxation, the withholding, collection, reporting and remittance to their appropriate tax authorities. All Users of SOLIDSWAP and any of its Services acknowledge and declare that the source of their funds come from a legitimate manner and are not derived from illegal activities. SOLIDSWAP maintains a stance of cooperation with law enforcement authorities globally and will not hesitate to seize, freeze, terminate the account and funds of Users which are flagged out or investigated by legal mandate.",
  "p-tos-privacy-title": "Privacy Policy",
  "p-tos-privacy-text-1":
    "Access to the Services will require the submission of certain personally identifiable information. Please review SOLIDSWAP’s Privacy Policy found",
  "p-tos-privacy-text-2":
    "for a summary of SOLIDSWAP’s practices related to the collection and use of personally identifiable information.",
  "p-tos-disputes-title":
    "RESOLVING DISPUTES: FORUM, ARBITRATION, CLASS ACTION WAIVER",
  "p-tos-disputes-text-1":
    "PLEASE READ THIS SECTION CAREFULLY, AS IT INVOLVES A WAIVER OF CERTAIN RIGHTS TO BRING LEGAL PROCEEDINGS.",
  "p-tos-disputes-subtitle-1": "Notification of Dispute.",
  "p-tos-disputes-text-2":
    "Please contact SOLIDSWAP first! SOLIDSWAP wants to address your concerns without resorting to formal legal proceedings. Before filing a claim, you agree to try to resolve the dispute informally by contacting SOLIDSWAP first through SOLIDSWAP official website.",
  "p-tos-disputes-subtitle-2": "Agreement to Arbitrate.",
  "p-tos-disputes-text-3":
    "You and SOLIDSWAP agree to resolve any claims relating to this Agreement (including any question regarding its existence, validity, termination, or any Services or products provided and any representations made by us) through final and binding arbitration, except as set forth under Exceptions to Agreement to Arbitrate below. You agree to first give us an opportunity to resolve any claims by contacting us as set forth in subsection (a) above. If we",
  "p-tos-disputes-text-4":
    "are not able to resolve your claims within 60 days of receiving the notice, you may seek relief through arbitration or in the Republic of Armenia Appeals Court (“RAAC”), as set forth below.",
  "p-tos-disputes-subtitle-3": "Arbitration Procedure",
  "p-tos-disputes-text-5":
    "Either you or SOLIDSWAP may submit a dispute (after having made good faith efforts to resolve such dispute in accordance with subsections (a) and (b) above) for final, binding resolution by arbitration under the arbitration rules of the RAAC, which are deemed to be incorporated by reference. The arbitration tribunal shall consist of a sole arbitrator to be appointed by the President of RAAC. The language of the arbitration hearings shall be English and the seat, or legal place, of arbitration shall be Armenia. Judgment on any arbitral award may be entered in any court having jurisdiction over the party (or the assets of the party) due and owing such award.",
  "p-tos-disputes-subtitle-4": "Exceptions",
  "p-tos-disputes-text-6":
    "Either party may instead choose to assert the claims in the RAAC if the claims fall within the jurisdiction of the RAAC, and either party may seek injunctive relief or other urgent equitable relief in a court of competent jurisdiction. However, for the avoidance of doubt, where the claims fall outside of the jurisdiction of the RAAC, the claims will be referred to and finally resolved by RAAC arbitration.",
  "p-tos-disputes-subtitle-5": "Notice",
  "p-tos-disputes-text-7":
    "To begin an arbitration proceeding, you must send a letter requesting arbitration and describing your claims to",
  "p-tos-disputes-text-8":
    "If we request arbitration against you, we will give you notice at the email address or street address you have provided.",
  "p-tos-miscellaneous-title": "Miscellaneous",
  "p-tos-miscellaneous-text-1":
    "a. Independent Parties. SOLIDSWAP is an independent contractor and not an agent of you in the performance of these Terms. These Terms not to be interpreted as evidence of an association, joint venture, partnership, or franchise between the parties.",
  "p-tos-miscellaneous-text-2":
    "b. Entire Agreement. These Terms constitute the entire agreement between the parties regarding use of the Services and will supersede all prior agreements between the parties whether, written or oral. No usage of trade or other regular practice or method of dealing between the parties will be used to modify, interpret, supplement, or alter the terms of these Terms.",
  "p-tos-miscellaneous-text-3":
    "c. Force Majeure. SOLIDSWAP will not be liable for any delay or failure to perform as required by these Terms because of any cause or condition beyond SOLIDSWAP’s reasonable control.",
  "p-tos-miscellaneous-text-4":
    "d. Severability. If any portion of these Terms are held invalid or unenforceable, such invalidity or enforceability will not affect the other provisions of these Terms, which will remain in full force and effect, and the invalid or unenforceable portion will be given effect to the greatest extent possible.",
  "p-tos-miscellaneous-text-5":
    "e. Assignment. You may not assign or transfer any right to use the Services or any of your rights or obligations under these Terms without prior written consent from us, including by operation of law or in connection with any change of control. SOLIDSWAP may assign or transfer any or",
  "p-tos-miscellaneous-text-6":
    "all of its rights or obligations under these Terms, in whole or in part, without notice or obtaining your consent or approval.",
  "p-tos-miscellaneous-text-7":
    "f. Waiver. The failure of a party to require performance of any provision will not affect that party’s right to require performance at any time thereafter, nor will a waiver of any breach or default of these Terms or any provision of these Terms constitute a waiver of any subsequent breach or default or a waiver of the provision itself.",
  "p-tos-miscellaneous-text-8":
    "g. Third-Party Website Disclaimer. Any links to third-party websites from the Services does not imply endorsement by SOLIDSWAP of any products, Services or information presented therein, nor does SOLIDSWAP guarantee the accuracy of the information contained on them. In addition, since SOLIDSWAP has no control over the terms of use or privacy practices of third- party websites, you should read and understand those policies carefully.",
  "p-tos-miscellaneous-text-9":
    "h. Contact Information. For more information on SOLIDSWAP, you can refer to the company and license information found on the website. If you have questions regarding this agreement, please feel free to contact SOLIDSWAP for clarification via our Customer Support team at",
  "p-tos-language-title": "Language of Use",
  "p-tos-language-text-1":
    "This Customer Agreement may be published in different languages. In case of any discrepancy, please refer to the English version.",
  "p-tos-community-text-4":
    "If there is a conflict between users and users cannot resolve the conflict among themselves, each of them can contact the 'Dispute' section and use the Services of a moderator. When accessing the specified section within 1 hour, the moderator requests information about the details of the conflict and, within 24 hours of receiving the requested documents, makes a decision in favor of one of the users.",
  "p-tos-verification-text-4":
    "You hereby acknowledge and agree that you have the obligation to keep all information provided up to date if there are any changes.",
  "p-tos-verification-text-5":
    "BY SIGNING UP FOR ACCOUNT YOU HEREBY AUTHORIZE SOLIDSWAP TO MAKE INQUIRIES, WHETHER DIRECTLY OR THROUGH THIRD PARTIES, THAT SOLIDSWAP CONSIDERS NECESSARY TO VERIFY YOUR IDENTITY OR PROTECT YOU AND/OR SOLIDSWAP AGAINST FRAUD OR OTHER FINANCIAL CRIMES, AND TO TAKE ACTION SOLIDSWAP REASONABLY DEEMS NECESSARY BASED ON THE RESULTS OF SUCH INQUIRIES. YOU ALSO ACKNOWLEDGE AND AGREE THAT YOUR PERSONAL INFORMATION MAY BE DISCLOSED TO CREDIT REFERENCE AND FRAUD PREVENTION OR FINANCIAL CRIME AGENCIES, AND THAT THESE AGENCIES MAY RESPOND TO OUR INQUIRIES IN FULL.",
  "p-tos-guidelines-text-4":
    "These Terms provide only a limited license to access and use the Services. Accordingly, you hereby agree that SOLIDSWAP transfers no ownership or intellectual property interest or title in and to the Services or any SOLIDSWAP intellectual property to you or anyone else in connection with your use of the Services. All text, graphics, user interfaces, visual interfaces, photographs, sounds, artwork, computer code (including html code), programs, software, products, information, and documentation as well as the design, structure, selection, coordination, expression, 'look and feel,' and arrangement of any content contained on or available through the Services are exclusively owned, controlled, and/or licensed by SOLIDSWAP or its members, parent(s), licensors, or affiliates.",
  "p-tos-guidelines-text-5":
    "SOLIDSWAP will own any feedback, suggestions, ideas, or other information or materials regarding SOLIDSWAP or the Services that you provide, whether by email, through the Services or otherwise ('Feedback'). You hereby assign to SOLIDSWAP all right, title and interest to Feedback together with all associated intellectual property rights. You will not be entitled to, and",
  "p-tos-guidelines-text-6":
    "hereby waive any claim for, acknowledgment or compensation based on any Feedback or any modifications made based on any Feedback.",
  "p-tos-cancellations-text-2":
    "If you have an insufficient amount of Digital Currency in your Account to fulfill an Order, SOLIDSWAP may cancel the entire Order or may fulfill a partial Order that can be covered by the Digital Currency in your Account (in each case after deducting any fees payable to SOLIDSWAP in connection with the Trade as described in subsection (7.3) below).",
  "p-tos-futures-text-2":
    "Trading markets are extremely volatile and shift quickly in terms of liquidity, market depth, and trading dynamics. The use of leverage can work against you as well as for you and can lead to large losses as well as gains.",
  "p-tos-futures-text-3":
    "Under certain market conditions, you may find it difficult or impossible to liquidate a position. This can occur, for example, there is insufficient liquidity in the market or due to technical issues on SOLIDSWAP.",
  "p-tos-futures-text-4":
    "Placing contingent orders will not necessarily limit your losses to the intended amounts, since market conditions may make it impossible to execute such orders.",
  "p-tos-futures-text-5":
    "There is no guaranteed measure against losses on futures trading. As a borrower, you may sustain a total loss beyond what you have deposited to your contract account.",
  "p-tos-prohibited-text-11":
    "By accessing the Service, you agree that SOLIDSWAP shall have the right to investigate any violation of these Terms, unilaterally determine whether you have violated these Terms, and take actions to apply relevant rules without receiving your consent or giving prior notice to you. Examples of such actions include, but are not limited to:",
  "p-tos-termination-text-14": "We detect unusual activity in the account.",
  "p-tos-termination-text-15": "We detect unauthorized access to the account.",
  "p-tos-termination-text-16":
    "We are required to do so by a court order or command by a regulatory/government authority.",
  "p-tos-termination-text-17": "after SOLIDSWAP terminates Services to you;",
  "p-tos-termination-text-18":
    "you allegedly register or register in any other person’s name as SOLIDSWAP user again, directly or indirectly;",
  "p-tos-termination-text-19":
    "the information that you have provided is untruthful, inaccurate, outdated or incomplete;",
  "p-tos-termination-text-20":
    "when these Terms are amended, you expressly state and notify SOLIDSWAP of your unwillingness to accept the amended Terms;",
  "p-tos-compliance-title": "Compliance with Local Laws",
  "p-tos-compliance-text-1":
    "It is the responsibility of the User to abide by local laws in relation to the legal usage of SOLIDSWAP in their local jurisdiction. Users must also factor, to the extent of their local law, all aspects of taxation, the withholding, collection, reporting and remittance to their appropriate tax authorities. All Users of SOLIDSWAP and any of its Services acknowledge and declare that the source of their funds come from a legitimate manner and are not derived from illegal activities. SOLIDSWAP maintains a stance of cooperation with law enforcement authorities globally and will not hesitate to seize, freeze, terminate the account and funds of Users which are flagged out or investigated by legal mandate.",
  "p-tos-resolving-title":
    "RESOLVING DISPUTES: FORUM, ARBITRATION, CLASS ACTION WAIVER",
  "p-tos-resolving-text-1":
    "PLEASE READ THIS SECTION CAREFULLY, AS IT INVOLVES A WAIVER OF CERTAIN RIGHTS TO BRING LEGAL PROCEEDINGS.",
  "p-tos-resolving-text-2":
    "Please contact SOLIDSWAP first! SOLIDSWAP wants to address your concerns without resorting to formal legal proceedings. Before filing a claim, you agree to try to resolve the dispute informally by contacting SOLIDSWAP first through SOLIDSWAP official website.",
  "p-tos-resolving-text-3":
    "You and SOLIDSWAP agree to resolve any claims relating to this Agreement (including any question regarding its existence, validity, termination, or any Services or products provided and any representations made by us) through final and binding arbitration, except as set forth under Exceptions to Agreement to Arbitrate below. You agree to first give us an opportunity to resolve any claims by contacting us as set forth in subsection (a) above. If we",
  "p-tos-resolving-text-4":
    "are not able to resolve your claims within 60 days of receiving the notice, you may seek relief through arbitration or in the Republic of Armenia Appeals Court (“RAAC”), as set forth below.",
  "p-tos-resolving-text-5":
    "Either you or SOLIDSWAP may submit a dispute (after having made good faith efforts to resolve such dispute in accordance with subsections (a) and (b) above) for final, binding resolution by arbitration under the arbitration rules of the RAAC, which are deemed to be incorporated by reference. The arbitration tribunal shall consist of a sole arbitrator to be appointed by the President of RAAC. The language of the arbitration hearings shall be English and the seat, or legal place, of arbitration shall be Armenia. Judgment on any arbitral award may be entered in any court having jurisdiction over the party (or the assets of the party) due and owing such award.",
  "p-tos-resolving-text-6":
    "Either party may instead choose to assert the claims in the RAAC if the claims fall within the jurisdiction of the RAAC, and either party may seek injunctive relief or other urgent equitable relief in a court of competent jurisdiction. However, for the avoidance of doubt, where the claims fall outside of the jurisdiction of the RAAC, the claims will be referred to and finally resolved by RAAC arbitration.",
  "p-tos-resolving-text-7":
    "To begin an arbitration proceeding, you must send a letter requesting arbitration and describing your claims to",
};
