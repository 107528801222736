export default {
  "p-about-head-title":
    "About Solid Swap | Meet Our Cryptocurrency Exchange Team",
  "p-about-head-description":
    "Learn more about Solid Swap, the team behind our secure cryptocurrency trading platform, and our mission to provide efficient digital asset swaps",
  "p-about-head-keywords":
    "cryptocurrency exchange, secure trading, digital assets, crypto trading, Solid Swap, solidswap",
  "p-about-contact-title": "Contact Us",
  "p-about-address": "Address:",
  "p-about-index": "ZIP Code:",
  "p-about-tax-id": "Tax ID:",
  "p-about-email": "Email:",
  "p-about-support-email": "Support Email:",
  "p-about-duns": "Data Universal Numbering System (D-U-N-S):",
  "p-about-duns-check": "Check D-U-N-S",
  "p-about-traders-count": ">200 traders are already with us",
  "p-about-foundation-title": "The Foundation of Your Financial Independence",
  "p-about-description":
    "Solid Swap is a new service for fast cryptocurrency and fiat exchange on a P2P platform",
  "p-about-start-trading": "Start Trading",
  "p-about-fee-tag": "0% fee",
  "p-about-beneficial-terms": "Beneficial Terms",
  "p-about-low-fees": "Low fees, promotions, giveaways, and no fine print",
  "p-about-fast-transactions": "Fast Transactions",
  "p-about-secure-optimizations":
    "Constantly improving code without sacrificing security",
  "p-about-online": "Online",
  "p-about-24-support": "24/7 Support",
  "p-about-support-anytime": "Assisting with any issue, anytime, anywhere",
  "p-about-contact-support": "Contact Support",
  "p-about-platform-mission":
    "Building a reliable platform, following dreams and principles",
  "p-about-join-us":
    "Many exchanges exist, but we stand out — join us and follow the development progress",
  "p-about-freedom": "Freedom and Anonymity",
  "p-about-data-privacy":
    "We don’t want to become a bank; we value data privacy",
  "p-about-registered-armenia": "Registered in Armenia",
  "p-about-legal-compliance":
    "Operating within the law while preserving independence",
  "p-about-access-devices": "Access from Any Device",
  "p-about-cross-device-experience":
    "Creating a seamless experience across all devices, for any situation",
  "p-about-services-question": "What services does Solid Swap provide?",
  "p-about-buy-bitcoin-question": "How to buy Bitcoin on Solid Swap?",
  "p-about-crypto-question": "What is cryptocurrency?",
  "p-about-russians-trade-question": "Can Russians trade cryptocurrency?",
  "p-about-pay-with-crypto-question": "Can you pay with cryptocurrency?",
  "p-about-login-trade": "Log in to start trading",
  "p-about-login": "Log In",
  "p-about-register": "Register",
};
