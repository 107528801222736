export default {
  "p-trade-orders-title":
    "Handelsaufträge | Verwalten Sie Ihre Krypto-Aufträge auf Solid Swap",
  "p-trade-orders-description":
    "Verwalten Sie Ihre Kryptowährungsaufträge effizient auf Solid Swap. Verfolgen und steuern Sie Ihre Handelsaktivitäten nahtlos",
  "p-trade-orders-keywords":
    "krypto-aufträge verwalten, handelsaufträge, kryptowährungsauftrag-verfolgung, handelsmanagement",

  "p-trade-orders-order-fetch-error":
    "Fehler beim Laden des Auftrags, bitte laden Sie die Seite neu",
  "p-trade-orders-order-not-found": 'Auftrag "{orderId}" nicht gefunden',
  "p-trade-orders-payment-was-sent": "Zahlung wurde gesendet",
  "p-trade-orders-payment-was-sent-confirm":
    "Bestätigen Sie, dass die Zahlung von Ihrer Seite gesendet wurde? Tun Sie dies nur nach Bestätigung von der Bank. Fortfahren?",
  "p-trade-orders-payment-received": "Zahlung erhalten",
  "p-trade-orders-payment-received-confirm":
    "Bestätigen Sie, dass die Zahlung eingegangen ist? Tun Sie dies nur nach Bestätigung von Ihrer Bank. Wenn Sie fortfahren möchten, geben Sie unten Ihren 2FA-Code aus Ihrer App ein.",
  "p-trade-orders-open-dispute": "Streitfall eröffnen",
  "p-trade-orders-open-dispute-confirm":
    "Sie möchten einen Streitfall für diesen Auftrag eröffnen. Fortfahren?",
  "p-trade-orders-change-status": "Status ändern",
  "p-trade-orders-change-status-confirm":
    "Sind Sie sicher, dass Sie den Status ändern möchten?",
  "p-trade-orders-continue": "Fortfahren",
  "p-trade-orders-cancel": "Abbrechen",
  "p-trade-orders-invalid-2fa": "Ungültiger 2FA",
  "p-trade-orders-status-changed": "Auftragsstatus geändert",
  "p-trade-orders-fetch-order-error": "Fehler beim Abrufen der Auftragsdaten",
  "p-trade-orders-go-to-all-orders": "Zu allen Aufträgen gehen",
  "p-trade-orders-status": "Status",
  "p-trade-orders-waiting-for-payment": "Warten auf Zahlung des Käufers",
  "p-trade-orders-payment-sent": "Käufer hat die Zahlung gesendet",
  "p-trade-orders-order-completed": "Auftrag abgeschlossen",
  "p-trade-orders-order-cancelled": "Auftrag storniert",
  "p-trade-orders-dispute": "STREITFALL",
  "p-trade-orders-auto-cancel-after": "Auftrag automatisch stornieren nach",
  "p-trade-orders-open-dispute-after": "Streitfall kann eröffnet werden nach",
  "p-trade-orders-order-created": "Auftrag erstellt",
  "p-trade-orders-summary": "Zusammenfassung",
  "p-trade-orders-sell": "VERKAUFEN",
  "p-trade-orders-buy": "KAUFEN",
  "p-trade-orders-for": "für",
  "p-trade-orders-fee": "Gebühr",
  "p-trade-orders-included-in-transaction": "in der Transaktion enthalten",
  "p-trade-orders-selling": "Verkaufen",
  "p-trade-orders-buying": "Kaufen",
  "p-trade-orders-price": "Preis",
  "p-trade-orders-offer-creator": "Angebotsersteller",
  "p-trade-orders-trader-info": "Vom Händler bereitgestellte Informationen",
  "p-trade-orders-no-description": "Keine Beschreibung vom Händler angegeben.",
  "p-trade-orders-no-description-additional-info":
    "Sie erhalten nach der Auftragserstellung zusätzliche Informationen.",
  "p-trade-orders-trading-experience": "Wie war Ihr Handelserlebnis?",
  "p-trade-orders-positive": "Positiv",
  "p-trade-orders-negative": "Negativ",
  "p-trade-orders-your-feedback": "Ihr Feedback",

  "p-trade-ordersIndex-all": "Alle",
  "p-trade-ordersIndex-active": "Aktiv",
  "p-trade-ordersIndex-completed": "Abgeschlossen",
  "p-trade-ordersIndex-cancelled": "Storniert",
  "p-trade-ordersIndex-dispute": "Streitfall",
  "p-trade-ordersIndex-coin": "Münze",
  "p-trade-ordersIndex-order-type": "Auftragstyp",
  "p-trade-ordersIndex-dates": "Daten",
  "p-trade-ordersIndex-reset-filters": "Filter zurücksetzen",
  "p-trade-ordersIndex-loading-error":
    "Etwas ist beim Laden des Auftrags schiefgelaufen :(",
  "p-trade-ordersIndex-orders": "Aufträge",
  "p-trade-ordersIndex-id-type": "ID / Typ",
  "p-trade-ordersIndex-operation-info": "Betriebsinfo",
  "p-trade-ordersIndex-status-date": "Status / Datum",
  "p-trade-ordersIndex-id": "ID",
  "p-trade-ordersIndex-sell": "VERKAUFEN",
  "p-trade-ordersIndex-buy": "KAUFEN",
  "p-trade-ordersIndex-go-to-details": "Zu Details gehen",
  "p-trade-ordersIndex-summary": "Zusammenfassung",
  "p-trade-ordersIndex-fee": "Gebühr",
  "p-trade-ordersIndex-selling": "Verkaufen",
  "p-trade-ordersIndex-buying": "Kaufen",
  "p-trade-ordersIndex-for": "Für",
  "p-trade-ordersIndex-price": "Preis",
  "p-trade-ordersIndex-offer-creator": "Angebotsersteller",
  "p-trade-ordersIndex-order-created": "Auftrag erstellt",
  "p-trade-ordersIndex-status": "Status",
  "p-trade-ordersIndex-waiting-for-buyer": "Warten auf Zahlung des Käufers",
  "p-trade-ordersIndex-buyer-sent-payment": "Käufer hat die Zahlung gesendet",
  "p-trade-ordersIndex-order-completed": "Auftrag abgeschlossen",
  "p-trade-ordersIndex-order-cancelled": "Auftrag storniert",
  "p-trade-ordersIndex-last7days": "Letzte 7 Tage",
  "p-trade-ordersIndex-lastMonth": "Letzter Monat",
  "p-trade-ordersIndex-last3month": "Letzte 3 Monate",
  "p-trade-ordersIndex-dispute-caps": "STREITFALL",
};
