export default {
  "p-support-chat-welcome": "Willkommen zu unserem Support-Chat!",
  "p-support-chat-intro":
    "Wir sind hier, um Ihnen bei Fragen oder Bedenken zu helfen. Unser Ziel ist es, Ihnen die bestmögliche Unterstützung zu bieten. So können Sie starten:",
  "p-support-chat-enter-details-prefix": "Geben Sie Ihre Daten ein: ",
  "p-support-chat-enter-details-suffix":
    "Titel Ihrer Frage. Diese Informationen helfen uns, Ihnen besser zu helfen und Ihre Anliegen effizienter zu bearbeiten.",
  "p-support-chat-open-chat":
    'Öffnen Sie den Chat: Klicken Sie auf die Schaltfläche "Support-Chat öffnen", um zu beginnen.',
  "p-support-chat-start-conversation":
    "Starten Sie das Gespräch: Sobald Sie Ihre Daten eingegeben und das Formular abgeschickt haben, erscheint ein Chat-Fenster auf der Seite. Einer unserer Moderatoren wird dem Chat bald beitreten, um Ihnen zu helfen.",
  "p-support-chat-appreciation":
    "Wir schätzen Ihre Geduld und freuen uns darauf, Ihnen zu helfen!",
  "p-support-chat-your-name": "Ihr Name",
  "p-support-chat-your-email": "Ihre E-Mail",
  "p-support-chat-your-question": "Ihre Frage",
  "p-support-chat-open-chat-button": "Support-Chat öffnen",
  "p-support-chat-wait-moderator": "Warten Sie, bis der Moderator beitritt",
  "p-support-chat-contact-alternative":
    "Wenn Sie Probleme beim Chatten haben oder andere Kontaktmöglichkeiten bevorzugen, wenden Sie sich bitte über unsere",
  "p-support-chat-contact-page": "Kontaktseite",
  "p-support-chat-or-email": "oder senden Sie eine E-Mail an",
  "p-support-chat-thank-you":
    "Vielen Dank, dass Sie sich für unseren Service entschieden haben!",
  "p-support-chat-ask-question": "Stellen Sie eine Frage",
  "p-support-chat-name-required": "Name ist erforderlich",
  "p-support-chat-email-required": "E-Mail ist erforderlich",
  "p-support-chat-valid-email": "Geben Sie eine gültige E-Mail-Adresse ein",
  "p-support-chat-chat-started": "Support-Chat gestartet!",
  "p-support-chat-name-email": "Name, E-Mail,",
};
