export default {
  "c-home-banners-getToKnowUs": "了解我们",
  "c-home-banners-dirtyCryptocurrency": "收到“脏”加密货币该怎么办",
  "c-home-banners-beginnersGuide": "开始阅读我们的新手指南",

  "c-home-historywidgets-24hours": "24 小时",
  "c-home-historywidgets-1week": "1 周",
  "c-home-historywidgets-1month": "1 个月",
  "c-home-historywidgets-usdRatesHistory": "美元汇率历史",
  "c-home-historywidgets-change": "(更改？)",
};
