export default {
  "c-feedback-create-fastTransaction": "Schnelle Transaktion",
  "c-feedback-create-politeFriendly": "Höflich und freundlich",
  "c-feedback-create-patient": "Geduldig",
  "c-feedback-create-safeTrustworthy": "Sicher und vertrauenswürdig",
  "c-feedback-create-goodPrice": "Guter Preis",
  "c-feedback-create-successMessage": "Danke für Ihr Feedback!",
  "c-feedback-create-errorMessage":
    "Ein Fehler ist beim Senden des Feedbacks aufgetreten",
  "c-feedback-create-feedbackTitle": "Feedback",
  "c-feedback-create-experienceQuestion": "Wie war Ihre Erfahrung?",
  "c-feedback-create-positive": "Positiv",
  "c-feedback-create-negative": "Negativ",
  "c-feedback-create-feedbackPlaceholder": "Bitte geben Sie Ihr Feedback ein",
  "c-feedback-create-anonymous": "Anonym",
  "c-feedback-create-anonymousHint":
    "senden Sie Ihr Feedback, ohne Ihren Namen anzuzeigen",
  "c-feedback-create-cancel": "Abbrechen",
  "c-feedback-create-submit": "Feedback senden",

  "c-feedback-item-anonymously": "(anonym)",
  "c-feedback-item-positive": "Positiv",
  "c-feedback-item-negative": "Negativ",
};
