export default {
  "p-about-head-title": "关于 Solid Swap | 了解我们的加密货币交易团队",
  "p-about-head-description":
    "了解 Solid Swap、我们的安全加密货币交易平台背后的团队，以及我们提供高效数字资产交换的使命",
  "p-about-head-keywords":
    "加密货币交易所，安全交易，数字资产，加密交易，Solid Swap，solidswap",
  "p-about-contact-title": "联系我们",
  "p-about-address": "地址：",
  "p-about-index": "邮政编码：",
  "p-about-tax-id": "税号：",
  "p-about-email": "电子邮箱：",
  "p-about-support-email": "支持邮箱：",
  "p-about-duns": "数据通用编号系统 (D-U-N-S)：",
  "p-about-duns-check": "检查 D-U-N-S",
  "p-about-traders-count": ">200 名交易员已加入我们",
  "p-about-foundation-title": "你财务独立的基石",
  "p-about-description":
    "Solid Swap 是一个用于快速交换加密货币和法币的 P2P 平台的新服务",
  "p-about-start-trading": "开始交易",
  "p-about-fee-tag": "0% 手续费",
  "p-about-beneficial-terms": "有利条件",
  "p-about-low-fees": "低手续费、活动、抽奖且没有小字隐藏条款",
  "p-about-fast-transactions": "快速交易",
  "p-about-secure-optimizations": "持续优化代码，但不牺牲安全性",
  "p-about-online": "在线",
  "p-about-24-support": "全天候支持",
  "p-about-support-anytime": "任何时区的任何问题都可以帮助解决",
  "p-about-contact-support": "联系客服",
  "p-about-platform-mission": "我们通过追随梦想和原则建立可靠的平台",
  "p-about-join-us":
    "有很多交易所和兑换平台，但我们有自己的独特之处——加入我们，跟随开发进度",
  "p-about-freedom": "自由与匿名",
  "p-about-data-privacy": "我们不想成为银行，重视个人数据隐私",
  "p-about-registered-armenia": "注册地在亚美尼亚",
  "p-about-legal-compliance": "在遵守法律的前提下保持独立运作",
  "p-about-access-devices": "适用于任何设备",
  "p-about-cross-device-experience": "提供适用于任何设备和场景的优化体验",
  "p-about-services-question": "Solid Swap 提供哪些服务？",
  "p-about-buy-bitcoin-question": "如何在 Solid Swap 上购买比特币？",
  "p-about-crypto-question": "什么是加密货币？",
  "p-about-russians-trade-question": "俄罗斯人可以进行加密货币交易吗？",
  "p-about-pay-with-crypto-question": "可以用加密货币支付吗？",
  "p-about-login-trade": "登录以开始交易",
  "p-about-login": "登录",
  "p-about-register": "注册",
};
