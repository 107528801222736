export default {
  "p-login-head-title": "Inicio de sesión | Accede a tu cuenta de Solid Swap",
  "p-login-head-description":
    "Inicia sesión en tu cuenta de Solid Swap para comenzar a comerciar criptomonedas de manera segura y eficiente",
  "p-login-head-keywords":
    "inicio de sesión, cuenta de Solid Swap, acceso a comercio de criptomonedas",
  "p-login-rules-email": "Por favor, ingresa tu correo electrónico",
  "p-login-rules-password": "Por favor, ingresa tu contraseña",
  "p-login-error-default": "Ups, algo salió mal durante el inicio de sesión.",
  "p-login-error-2fa-wrong":
    "¡Código 2FA incorrecto! Por favor intenta de nuevo.",
  "p-login-error-user-not-found": "Usuario no encontrado",
  "p-login-error-email-verification":
    "Primero debes verificar tu correo electrónico. Por favor, revisa tu correo electrónico.",
  "p-login-error-suspended":
    "Tu cuenta ha sido suspendida. Por favor, revisa tu correo electrónico para más detalles.",
  "p-login-error-locked-title": "Temporalmente bloqueado",
  "p-login-error-locked-message":
    "Has realizado {failedLockCount} o más intentos fallidos de inicio de sesión. Por razones de seguridad, por favor espera 60 minutos antes de intentar nuevamente. Si necesitas asistencia inmediata o has olvidado tu contraseña, utiliza la opción 'Olvidé mi contraseña' para restablecerla.",
  "p-login-error-wrong-password-title": "Contraseña incorrecta",
  "p-login-error-wrong-password-message":
    "Has realizado {failedAttemptsCount} intento(s) fallido(s) de inicio de sesión. Por favor, ten cuidado: después de {failedLockCount} intentos fallidos, tu cuenta será bloqueada temporalmente por 60 minutos.",
  "p-login-password-reset-success": "¡Éxito!",
  "p-login-password-reset-error":
    "Algo salió mal durante el restablecimiento de la contraseña, por favor intenta más tarde",
  "p-login-title": "Iniciar sesión",
  "p-login-label-email": "Correo electrónico",
  "p-login-label-password": "Contraseña",
  "p-login-email-placeholder": "Ingresa tu correo electrónico",
  "p-login-password-placeholder": "Ingresa tu contraseña",
  "p-login-forgot-password": "¿Olvidaste tu contraseña?",
  "p-login-submit-button": "Iniciar sesión",
  "p-login-2fa-prompt": "Ingresa el código 2FA de tu aplicación:",
  "p-login-2fa-placeholder": "Ingresa el código...",
  "p-login-2fa-button": "Continuar",
  "p-login-password-reset-success-title": "¡Correo Enviado!",
  "p-login-password-reset-success-message":
    "Hemos enviado un enlace para restablecer la contraseña a tu dirección de correo electrónico. Por favor, revisa tu bandeja de entrada y sigue las instrucciones para restablecer tu contraseña.",
  "p-login-password-reset-alert":
    "Si no ves el correo electrónico, por favor revisa tu carpeta de spam o correo no deseado. Si aún no lo has recibido, intenta reenviar tu dirección de correo electrónico o contacta a nuestro equipo de soporte para obtener ayuda.",
  "p-login-back-to-login": "Volver a iniciar sesión",
  "p-login-forgot-password-title": "¿Olvidaste tu contraseña?",
  "p-login-forgot-password-message":
    "Por favor ingresa la dirección de correo electrónico asociada con tu cuenta y haz clic en 'Continuar'.",
  "p-login-password-reset-instructions":
    "Te enviaremos un correo electrónico con instrucciones sobre cómo restablecer tu contraseña. Si no recibes el correo electrónico en unos minutos, por favor revisa tu carpeta de spam o intenta nuevamente.",
  "p-login-enter-email": "Ingresa tu correo electrónico",
  "p-login-cancel": "Cancelar",
  "p-login-proceed": "Continuar",
  "p-login-go-to-registration": "¿No tienes una cuenta? Ir a registro",
  "p-login-welcome-title": "¡Bienvenido a una nueva era de comercio!",
  "p-login-welcome-message-1":
    "Conéctate de forma segura a un mundo de activos digitales.",
  "p-login-welcome-message-2":
    "Comienza a comerciar con confianza y conveniencia en nuestra plataforma de confianza P2P.",
  "p-login-welcome-message-3":
    "Tu puerta de entrada a transacciones cripto sin interrupciones te espera.",
};
