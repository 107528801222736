export default {
  "c-notifications-indexclient-connectError": "Ծանուցումներին միացման սխալ",
  "c-notifications-indexclient-hideAllMessages":
    "Թաքցնել բոլոր հաղորդագրությունները",
  "c-notifications-indexclient-goToSettings":
    "գնալ ծանուցումների կարգավորումներ",
  "c-notifications-indexclient-nothingNew": "նոր բան չկա",
  "c-notifications-indexclient-viewOlder": "Դիտել հիները",
  "c-notifications-indexclient-viewAll": "Դիտել բոլորը",
};
