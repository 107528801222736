export default {
  "c-exchange-reqItem-cancelling-request": "Отмена вашего запроса на обмен",
  "c-exchange-reqItem-cancel-request-confirm":
    "Вы уверены, что хотите отменить запрос?",
  "c-exchange-reqItem-confirming-documents": "Подтверждение ваших документов",
  "c-exchange-reqItem-upload-documents-instructions":
    "Убедитесь, что изображения вашего документа имеют высокое разрешение, и все цифры четко видны",
  "c-exchange-reqItem-confirm-documents-confirmation":
    "Вы хотите продолжить подтверждение документов?",
  "c-exchange-reqItem-select-payment-option": "Выберите способ оплаты",
  "c-exchange-reqItem-proceed-with-payment-option":
    "Вы хотите продолжить с выбранным способом оплаты?",
  "c-exchange-reqItem-confirming-payment": "Подтверждение оплаты",
  "c-exchange-reqItem-confirm-payment": "Вы хотите подтвердить свою оплату?",
  "c-exchange-reqItem-completing-request": "Завершение запроса",
  "c-exchange-reqItem-provide-txid":
    "Для завершения запроса вам необходимо предоставить идентификатор транзакции вашего перевода",
  "c-exchange-reqItem-enter-txid-error": "Пожалуйста, введите txId здесь",
  "c-exchange-reqItem-enter-txid-placeholder": "Введите txId здесь",
  "c-exchange-reqItem-verifying-card": "Проверка карты пользователя",
  "c-exchange-reqItem-moderator-verifying": "Модератор проверяет данные",
  "c-exchange-reqItem-waiting-alm": "Ожидание результатов ALM",
  "c-exchange-reqItem-preparing-crypto": "Подготовка адреса платформы",
  "c-exchange-reqItem-preparing-fiat": "Подготовка реквизитов для фиата",
  "c-exchange-reqItem-sending-crypto": "Отправка криптовалюты",
  "c-exchange-reqItem-sending-fiat": "Отправка фиата",
  "c-exchange-reqItem-waiting-payment": "Ожидание оплаты от пользователя",
  "c-exchange-reqItem-completed": "Завершено",
  "c-exchange-reqItem-failed": "Неудачно",
  "c-exchange-reqItem-cancelled": "Отменено",
  "c-exchange-reqItem-cancel-exchange-request": "Отменить запрос на обмен",
  "c-exchange-reqItem-confirm-documents": "Подтвердить документы",
  "c-exchange-reqItem-documents-confirmed": "Документы подтверждены",
  "c-exchange-reqItem-payment-sent": "Оплата отправлена",
  "c-exchange-reqItem-confirm-user-payment": "Подтвердить оплату пользователя",
  "c-exchange-reqItem-complete-request": "Завершить запрос",
  "c-exchange-reqItem-id": "ID",
  "c-exchange-reqItem-exchanging": "Обмен",
  "c-exchange-reqItem-crypto-address": "Криптовалютный адрес пользователя",
  "c-exchange-reqItem-fiat-card-number": "Номер фиатной карты пользователя",
  "c-exchange-reqItem-request-failed": "Запрос не выполнен",
  "c-exchange-reqItem-alm-link": "Ссылка на результаты ALM",
  "c-exchange-reqItem-check-here": "проверить здесь",
  "c-exchange-reqItem-request-cancelled": "Запрос отменен",
  "c-exchange-reqItem-request-fulfilled": "Ваш запрос выполнен!",
  "c-exchange-reqItem-thank-you":
    "Спасибо, что выбрали нас в качестве предпочтительного обменного сервиса. Мы очень ценим ваше доверие к нам и с нетерпением ждем возможности снова вам служить!",
  "c-exchange-reqItem-address-preparation":
    "Здесь будет адрес для вашего перевода.",
  "c-exchange-reqItem-security-check":
    "Пожалуйста, подождите, пока все приготовления и проверки безопасности будут завершены.",
  "c-exchange-reqItem-waiting-for-transaction":
    "Платформа ожидает вашу транзакцию.",
  "c-exchange-reqItem-transfer-here": "Переведите средства сюда",
  "c-exchange-reqItem-wallet": "кошелек",
  "c-exchange-reqItem-payment-option": "Способ оплаты",
  "c-exchange-reqItem-card-number": "Номер карты",
  "c-exchange-reqItem-description": "Описание",
  "c-exchange-reqItem-balance": "баланс",
  "c-exchange-reqItem-payment-instructions":
    "После совершения оплаты, пожалуйста, поделитесь изображением квитанции (или снимком экрана) вашего платежа в чате",
  "c-exchange-reqItem-verification-required": "Требуется проверка личности",
  "c-exchange-reqItem-upload-photo-instructions":
    "Чтобы продолжить ваш запрос на обмен, нам нужно, чтобы вы загрузили фотографию, на которой вы держите кредитную карту, которую планируете использовать. Пожалуйста, убедитесь, что:",
  "c-exchange-reqItem-photo-instruction-cardholder":
    "Имя владельца карты и все цифры номера карты четко видны.",
  "c-exchange-reqItem-photo-instruction-face":
    "Ваше лицо на фотографии четко видно.",
  "c-exchange-reqItem-click-confirm-documents":
    "После того как вы загрузите фото в чат, нажмите на кнопку 'Подтвердить документы' выше и дождитесь проверки информации нашим модератором.",
  "c-exchange-reqItem-verification-in-progress": "Проверка в процессе",
  "c-exchange-reqItem-moderator-review-in-progress":
    "Спасибо за загрузку фотографии. Пожалуйста, подождите, пока наш модератор проверит и подтвердит вашу информацию. Этот процесс может занять несколько минут. Спасибо за ваше терпение.",
  "c-exchange-reqItem-select-payment-option-description":
    "Выберите способ оплаты из списка ниже. Выбранный вариант будет отображаться клиенту как способ оплаты для перевода средств.",
  "c-exchange-reqItem-proceed-with-selected-option":
    "Продолжить с выбранным вариантом",
  "c-exchange-reqItem-alm-check": "Мы проводим проверку ALM вашего кошелька",
  "c-exchange-reqItem-preparing-address":
    "SolidSwap подготавливает адрес для вашего криптовалютного перевода, пожалуйста, подождите.",
  "c-exchange-reqItem-transfer-funds":
    "Переведите средства на указанный ниже кошелек и нажмите кнопку «Платеж был отправлен»",
  "c-exchange-reqItem-confirming-payment-text":
    "Пожалуйста, подождите, пока SolidSwap подтверждает ваш платеж.",
  "c-exchange-reqItem-transfer-confirmed":
    "Ваш перевод подтвержден, пожалуйста, подождите, пока SolidSwap отправит ваши средства",
  "c-exchange-reqItem-identity-verification": "Требуется проверка личности",
  "c-exchange-reqItem-upload-photo":
    "Используйте чат для загрузки фотографии передней части карты на фоне сделки. Вы можете скрыть данные карты, кроме цифр номера карты.",
  "c-exchange-reqItem-virtual-card-intro": "Если у вас виртуальная карта:",
  "c-exchange-reqItem-first-way": "Первый способ:",
  "c-exchange-reqItem-first-way-desc":
    "Загрузите скриншот экрана с двумя одновременно открытыми окнами: первое — это страница транзакции с вашим номером заказа, второе — окно с данными карты на странице вашего банка.",
  "c-exchange-reqItem-second-way": "Второй способ:",
  "c-exchange-reqItem-second-way-desc":
    "Загрузите фотографию или скриншот вашей виртуальной карты в мобильном приложении или на сайте вашего банка на фоне вашего текущего заказа. Вы можете скрыть данные карты, кроме цифр номера карты.",
  "c-exchange-reqItem-waiting-moderator":
    "Ожидайте проверки загруженных документов модератором",
  "c-exchange-reqItem-transfer-to-card":
    "Переведите средства на указанную ниже карту. После оплаты поделитесь изображением квитанции (или скриншотом) вашего платежа в чате и нажмите кнопку «Платеж был отправлен»",
  "c-exchange-reqItem-crypto-transfer-confirmed":
    "Ваш перевод подтвержден, пожалуйста, подождите, пока SolidSwap отправит ваши средства",
  "c-exchange-reqItem-faq-promotion": "FAQ по акции",
  "c-exchange-reqItem-solid-swap-top-service":
    "Когда вы выбираете Solid Swap для обмена вашими цифровыми и фиатными активами, вы не только получаете первоклассное обслуживание, но и шанс добавить",
  "c-exchange-reqItem-add-to-account-monthly": "на ваш счет каждый месяц.",
  "c-exchange-reqItem-how-can-you-do-it": "Как это сделать?",
  "c-exchange-reqItem-register-and-exchange":
    "Зарегистрируйтесь и проведите обмен на SolidSwap, каждый обмен считается участием в розыгрыше.",
  "c-exchange-reqItem-leave-review-on":
    "Оставьте честный отзыв на одном из следующих ресурсов",
  "c-exchange-reqItem-send-email-screenshot":
    "Отправьте ваш email и скриншот вашего отзыва",
  "c-exchange-reqItem-manager-telegram": "нашему менеджеру в Telegram",
  "c-exchange-reqItem-or-email-to": "или на почту",
  "c-exchange-reqItem-enter-giveaway":
    "чтобы принять участие в розыгрыше 300 USDT.",
  "c-exchange-reqItem-promotion-period":
    "Акция проходит с 1 октября по 31 декабря 2024 года.",
  "c-exchange-reqItem-winners-selected-randomly":
    "Победители случайным образом отбираются среди участников, выполнивших требования в течение каждого месяца акции. Результаты будут объявлены в",
  "c-exchange-reqItem-participate-again":
    "Чтобы снова участвовать в следующем месяце, вам нужно провести еще один обмен и оставить дополнительный отзыв.",
};
