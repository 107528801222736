export default {
  "p-kyc-aml-policy-title":
    "Anti-Money Laundering Policy (Anti-Money Laundering Policy).",
  "p-kyc-warning":
    "SolidSwap warns users against attempts to use the service to legalize criminally obtained funds, terrorist financing, illegal actions of any kind, as well as from using the service to purchase prohibited goods and services.",
  "p-kyc-illegal-funds-description":
    "The legalization of funds obtained by criminal means is the concealment of an illegal source of funds by transferring them into funds or investments that look legitimate.",
  "p-kyc-general-provisions-title": "General provisions",
  "p-kyc-prevent-illegal-transactions":
    "To prevent illegal transactions, the Service sets certain requirements for all Applications created by the User:",
  "p-kyc-sender-recipient-same":
    "The sender and the recipient of the Payment on the Application must be the same person. Transfers in favor of third parties are strictly prohibited using the services of the Service.",
  "p-kyc-contact-info-requirements":
    "All contact information entered by the User in the Application, as well as other personal data transmitted by the User to the Service, must be up-to-date and completely reliable.",
  "p-kyc-no-anonymous-connections":
    "It is strictly prohibited for a User to create Applications using anonymous proxy servers or any other anonymous Internet connections.",
  "p-kyc-policy-description":
    'This Anti-Money Laundering Policy (hereinafter referred to as the "AML Policy") describes the procedures and mechanisms used by SolidSwap to counter money laundering. SolidSwap adheres to the following rules:',
  "p-kyc-no-business-with-criminals":
    "not to enter into business relations with known criminals and/or terrorists;",
  "p-kyc-no-criminal-transactions":
    "not to process transactions that are the result of known criminal and/or terrorist activities;",
  "p-kyc-no-facilitating-criminal-activity":
    "not to facilitate any transactions related to known criminal and/or terrorist activities;",
  "p-kyc-right-to-restrict-services":
    "has the right to restrict or prohibit the use of all or part of the services for persons subject to restrictions...",
  "p-kyc-verification-procedures-title": "Verification procedures",
  "p-kyc-kyc-policy-description":
    'SolidSwap service establishes its own verification procedures within the framework of anti-money laundering standards - the "Know Your Customer" (KYC) policy.',
  "p-kyc-verification-steps":
    "SolidSwap Service users undergo the verification procedure (provide a government identification document: passport or identification card). SolidSwap reserves the right to collect User identification information for the purposes of implementing the AML Policy.",
  "p-kyc-request-second-document":
    "SolidSwap Service can also request a second document to identify the User: a bank statement or utility bill no older than 3 months...",
  "p-kyc-verification-authenticity":
    "SolidSwap service verifies the authenticity of documents and information provided by Users and reserves the right to receive additional information...",
  "p-kyc-request-updated-documents":
    "If the User's identification information has been changed or his activity has seemed suspicious, the SolidSwap Service has the right to request updated documents from the User...",
  "p-kyc-aml-compliance-title":
    "Responsible for compliance with the AML Policy",
  "p-kyc-aml-compliance-description":
    "The person responsible for compliance with the AML Policy is a SolidSwap employee whose responsibilities include ensuring compliance with the AML Policy, namely:",
  "p-kyc-collect-user-info":
    "collecting User identification information and transferring it to the responsible personal data processing agent;",
  "p-kyc-create-update-policies":
    "Creation and updating of internal policies and procedures for writing, reviewing, submitting and storing all reports required...",
  "p-kyc-monitor-transactions":
    "transaction monitoring and analysis of any significant deviations from normal User activity;",
  "p-kyc-record-management":
    "implementation of a records management system for storing and searching documents, files, forms and journals;",
  "p-kyc-regular-risk-update": "regular updating of the risk assessment.",
  "p-kyc-interaction-with-authorities":
    "The person responsible for compliance with the AML Policy has the right to interact with law enforcement agencies that are engaged in preventing money laundering...",
  "p-kyc-transaction-monitoring-title": "Transaction monitoring",
  "p-kyc-monitoring-description":
    "Monitoring User transactions and analyzing the received data is also a tool for risk assessment and detection of suspicious transactions...",
  "p-kyc-report-suspicious-transactions":
    "report suspicious transactions to the relevant law enforcement agencies;",
  "p-kyc-request-additional-info":
    "ask the User to provide any additional information and documents;",
  "p-kyc-suspend-terminate-account": "suspend or terminate the User account;",
  "p-kyc-freeze-assets":
    "suspend the exchange and freeze assets until the circumstances are clarified;",
  "p-kyc-return-funds":
    "return the funds to the user by canceling the exchange procedure, according to the user agreement;",
  "p-kyc-risk-assessment": "risk assessment.",
  "p-kyc-additional-monitoring":
    "The above list is not exhaustive. The AML Policy Officer monitors User transactions on a daily basis to determine whether...",
};
