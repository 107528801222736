export default {
  "c-footer-menu-trade": "p2p trade",
  "c-footer-menu-news": "news",
  "c-footer-menu-blog": "blog",
  "c-footer-menu-faq": "FAQ",
  "c-footer-menu-beginnersGuide": "beginners guide",
  "c-footer-menu-about": "about",
  "c-footer-menu-manageCookies": "manage cookies",
  "c-footer-menu-adminPanel": "Admin panel",

  "c-footer-mobilemenu-home": "Home",
  "c-footer-mobilemenu-trade": "Trade",
  "c-footer-mobilemenu-orders": "Orders",
  "c-footer-mobilemenu-wallet": "Wallet",
};
