export default {
  "c-blog-editingform-pictureFormatError":
    "¡La imagen debe estar en formato jpg/png!",
  "c-blog-editingform-pictureSizeError":
    "¡El tamaño de la imagen no puede superar los 2MB!",
  "c-blog-editingform-uploadError": "¡Error al subir la imagen!",
  "c-blog-editingform-titleRequired": "Se requiere título",
  "c-blog-editingform-idCodeRequired": "Se requiere el código URL",
  "c-blog-editingform-descriptionRequired": "Se requiere descripción",
  "c-blog-editingform-previewImageRequired":
    "Se requiere imagen de vista previa",
  "c-blog-editingform-tagsRequired": "Se requieren etiquetas",
  "c-blog-editingform-bodyRequired": "Se requiere el texto de la publicación",
  "c-blog-editingform-metaDescriptionRequired": "Se requiere meta descripción",
  "c-blog-editingform-creatingNewPost": "Creando nueva publicación",
  "c-blog-editingform-title": "Título",
  "c-blog-editingform-active": "Activo",
  "c-blog-editingform-idCode": "Código URL",
  "c-blog-editingform-idCodeHint":
    "El código debe ser único para cada publicación del blog, en minúsculas y con '-' en lugar de espacios",
  "c-blog-editingform-description": "Descripción",
  "c-blog-editingform-previewImage": "Imagen de vista previa",
  "c-blog-editingform-uploadHint1": "Suelta el archivo aquí o",
  "c-blog-editingform-uploadHint2": "haz clic para subir",
  "c-blog-editingform-uploadHint3":
    "archivos jpg/png con un tamaño menor a 2MB",
  "c-blog-editingform-tags": "Etiquetas",
  "c-blog-editingform-tagsPlaceholder": "Elige etiquetas para tu artículo",
  "c-blog-editingform-tagsHint":
    "Puedes elegir entre etiquetas existentes o crear nuevas escribiendo en el campo y presionando Enter",
  "c-blog-editingform-postText": "Texto de la publicación",
  "c-blog-editingform-metaDescription": "Meta descripción",
  "c-blog-editingform-cancel": "Cancelar",
  "c-blog-editingform-save": "Guardar",

  "c-blog-item-readMore": "Leer más",
};
