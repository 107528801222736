export default {
  "c-footer-menu-trade": "p2p 交易",
  "c-footer-menu-news": "新闻",
  "c-footer-menu-blog": "博客",
  "c-footer-menu-faq": "常见问题",
  "c-footer-menu-beginnersGuide": "新手指南",
  "c-footer-menu-about": "关于",
  "c-footer-menu-manageCookies": "管理 Cookie",
  "c-footer-menu-adminPanel": "管理面板",

  "c-footer-mobilemenu-home": "主页",
  "c-footer-mobilemenu-trade": "交易",
  "c-footer-mobilemenu-orders": "订单",
  "c-footer-mobilemenu-wallet": "钱包",
};
