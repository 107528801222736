export default {
  "p-trade-action-buy-title":
    "Գնել Կրիպտոարժույթ | Ապահով Կրիպտո Գնում Solid Swap-ում",
  "p-trade-action-sell-title":
    "Վաճառել Կրիպտոարժույթ | Ապահով Կրիպտո Վաճառք Solid Swap-ում",
  "p-trade-action-buy-description":
    "Գնեք կրիպտոարժույթ Solid Swap-ում: Վայելեք արագ գործարքներ և մրցունակ փոխարժեքներ թվային ակտիվների գնելու համար",
  "p-trade-action-sell-description":
    "Վաճառեք ձեր կրիպտոարժույթը Solid Swap-ում: Վայելեք արագ գործարքներ և օպտիմալ փոխարժեքներ ձեր թվային ակտիվների համար",
  "p-trade-action-buy-keywords":
    "գնեք կրիպտոարժույթ, կրիպտո գնում, ապահով ակտիվ գնում, թվային արժույթի փոխանակում",
  "p-trade-action-sell-keywords":
    "վաճառեք կրիպտոարժույթ, կրիպտո վաճառք, ապահով ակտիվ վաճառք, թվային արժույթի փոխանակում",
  "p-trade-action-nothing-found":
    "ոչինչ չի գտնվել, փորձեք գտնել այլ տարբերակներ",
  "p-trade-action-clear-filters": "Մաքրել բոլոր ֆիլտրերը",
};
