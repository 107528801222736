export default {
  "s-useNotifications-order": "Bestellung Nr. {orderId}",
  "s-useNotifications-system-message": "Systemnachricht",
  "s-useNotifications-new-order-created": "Neuer Auftrag erstellt!",
  "s-useNotifications-order-status-changed":
    'Auftragsstatus geändert zu "{status}"',
  "s-useNotifications-order-created": "Auftrag erstellt",
  "s-useNotifications-order-deleted": "Auftrag gelöscht",
  "s-useNotifications-moderator-joined": "Moderator beigetreten",
  "s-useNotifications-attached-file": "[angehängte Datei]",
};
