export default {
  "c-blog-editingform-pictureFormatError":
    "Изображение должно быть в формате jpg/png!",
  "c-blog-editingform-pictureSizeError":
    "Размер изображения не может превышать 2 МБ!",
  "c-blog-editingform-uploadError": "Не удалось загрузить изображение!",
  "c-blog-editingform-titleRequired": "Требуется заголовок",
  "c-blog-editingform-idCodeRequired": "Требуется URL-код",
  "c-blog-editingform-descriptionRequired": "Требуется описание",
  "c-blog-editingform-previewImageRequired": "Требуется превью изображения",
  "c-blog-editingform-tagsRequired": "Требуются теги",
  "c-blog-editingform-bodyRequired": "Требуется текст поста",
  "c-blog-editingform-metaDescriptionRequired": "Требуется мета-описание",
  "c-blog-editingform-creatingNewPost": "Создание нового поста",
  "c-blog-editingform-title": "Заголовок",
  "c-blog-editingform-active": "Активный",
  "c-blog-editingform-idCode": "URL-код",
  "c-blog-editingform-idCodeHint":
    "Код должен быть уникальным для каждого поста в блоге, в нижнем регистре и с '-' вместо пробелов",
  "c-blog-editingform-description": "Описание",
  "c-blog-editingform-previewImage": "Превью изображения",
  "c-blog-editingform-uploadHint1": "Перетащите файл сюда или",
  "c-blog-editingform-uploadHint2": "нажмите, чтобы загрузить",
  "c-blog-editingform-uploadHint3": "jpg/png файлы размером менее 2 МБ",
  "c-blog-editingform-tags": "Теги",
  "c-blog-editingform-tagsPlaceholder": "Выберите теги для вашей статьи",
  "c-blog-editingform-tagsHint":
    "Вы можете выбрать из существующих тегов или создать новые, введя их в поле и нажав Enter",
  "c-blog-editingform-postText": "Текст поста",
  "c-blog-editingform-metaDescription": "Мета-описание",
  "c-blog-editingform-cancel": "Отмена",
  "c-blog-editingform-save": "Сохранить",

  "c-blog-item-readMore": "Читать далее",
};
