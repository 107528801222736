export default {
  "c-widget-news-moreNews": "más noticias",

  "c-widget-Search-crypto": "Cripto:",
  "c-widget-Search-fiat": "Fiat:",
  "c-widget-Search-amount": "cantidad a",
  "c-widget-Search-send": "enviar",
  "c-widget-Search-recieve": "recibir",
  "c-widget-Search-buy": "comprar",
  "c-widget-Search-sell": "vender",
  "c-widget-Search-find": "Encontrar",
  "c-widget-Search-selling-offers": "Ofertas de venta",
  "c-widget-Search-selling": "vendiendo",
  "c-widget-Search-buying-offers": "Ofertas de compra",
  "c-widget-Search-buying": "comprando",
  "c-widget-Search-price": "precio",
  "c-widget-Search-available": "disponible",
  "c-widget-Search-nothing-found":
    "no se encontró nada, intenta encontrar diferentes opciones",
  "c-widget-Search-close": "cerrar",
  "c-widget-Search-show-more": "mostrar más",

  "c-widget-Stats-total-users": "Usuarios totales",
  "c-widget-Stats-av-reg-per-day": "prom. reg. por día",
  "c-widget-Stats-daily-active-users": "Usuarios activos diarios",
  "c-widget-Stats-daily-active-users-tip":
    "Número de usuarios que iniciaron sesión en el producto en un día",
  "c-widget-Stats-than-yesterday": "que ayer",
  "c-widget-Stats-total-orders": "Pedidos totales",
  "c-widget-Stats-av-orders-per-day": "prom. pedidos por día",
  "c-widget-Stats-total-offers": "Ofertas totales",
  "c-widget-Stats-new-offers-24h": "nuevas ofertas en 24h",

  "c-widget-TipTap-client-toggle-fullscreen": "Alternar pantalla completa",
  "c-widget-TipTap-client-upload-image": "Subir imagen",
  "c-widget-TipTap-client-drop-file-here": "Suelta el archivo aquí o",
  "c-widget-TipTap-client-click-to-upload": "haz clic para subir",
  "c-widget-TipTap-client-jpg-png-files":
    "archivos jpg/png con un tamaño menor a 2MB",
  "c-widget-TipTap-client-heading-1": "Encabezado 1",
  "c-widget-TipTap-client-heading-2": "Encabezado 2",
  "c-widget-TipTap-client-heading-3": "Encabezado 3",
  "c-widget-TipTap-client-paragraph": "Párrafo",
  "c-widget-TipTap-client-image": "Imagen",
  "c-widget-TipTap-client-divider": "divisor",
  "c-widget-TipTap-client-break": "salto",
  "c-widget-TipTap-client-clear-marks": "limpiar marcas",
  "c-widget-TipTap-client-please-input-link":
    "Por favor, ingresa el enlace para pegar",
  "c-widget-TipTap-client-tip": "Consejo",
  "c-widget-TipTap-client-ok": "OK",
  "c-widget-TipTap-client-cancel": "Cancelar",
  "c-widget-TipTap-client-input-canceled": "Entrada cancelada",
  "c-widget-TipTap-client-picture-format":
    "¡La imagen debe estar en formato jpg/png!",
  "c-widget-TipTap-client-picture-size":
    "¡El tamaño de la imagen no puede superar los 2MB!",
};
