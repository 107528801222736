export default {
  "c-header-menu-dark": "oscuro",
  "c-header-menu-light": "claro",
  "c-header-menu-trade": "Comercio P2P",
  "c-header-menu-news": "Noticias",
  "c-header-menu-support": "Soporte",
  "c-header-menu-chat": "Chat de soporte",
  "c-header-menu-blog": "Blog",
  "c-header-menu-faq": "FAQ",
  "c-header-menu-beginnersGuide": "Guía para principiantes",
  "c-header-menu-help": "Ayuda",
  "c-header-menu-about": "Acerca de",
  "c-header-menu-menu": "Menú",
  "c-header-menu-admin": "admin",
  "c-header-menu-moder": "moder",
  "c-header-menu-profile": "Mi perfil",
  "c-header-menu-orders": "Pedidos",
  "c-header-menu-paymentOptions": "Opciones de pago",
  "c-header-menu-wallet": "Cartera",
  "c-header-menu-settings": "Configuraciones",
  "c-header-menu-logout": "cerrar sesión",
  "c-header-menu-login": "Iniciar sesión",
  "c-header-menu-registration": "Registro",
  "c-header-menu-notifications": "Notificaciones",
};
