export default {
  "c-transaction-cancel-confirm":
    "Вы уверены, что хотите отменить эту транзакцию?",
  "c-transaction-cancel-withdrawal": "Отмена вывода",
  "c-transaction-back": "Назад",
  "c-transaction-cancel-error": "Что-то пошло не так при отмене транзакции!",
  "c-transaction-status": "Статус",
  "c-transaction-amount": "Сумма",
  "c-transaction-fee": "Комиссия",
  "c-transaction-type": "Тип",
  "c-transaction-comment": "Комментарий",
  "c-transaction-address": "Адрес",
};
