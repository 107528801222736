export default {
  "p-trade-action-buy-title":
    "Купить криптовалюту | Безопасная покупка криптовалюты на Solid Swap",
  "p-trade-action-sell-title":
    "Продать криптовалюту | Безопасная продажа криптовалюты на Solid Swap",
  "p-trade-action-buy-description":
    "Безопасно покупайте криптовалюту на Solid Swap. Воспользуйтесь быстрыми транзакциями и конкурентными ставками для покупки цифровых активов",
  "p-trade-action-sell-description":
    "Безопасно продавайте свою криптовалюту на Solid Swap. Наслаждайтесь быстрыми транзакциями и оптимальными ставками для ваших цифровых активов",
  "p-trade-action-buy-keywords":
    "купить криптовалюту, покупка крипто, безопасная покупка активов, торговля цифровыми валютами",
  "p-trade-action-sell-keywords":
    "продать криптовалюту, продажа крипто, безопасная продажа активов, торговля цифровыми валютами",
  "p-trade-action-nothing-found":
    "ничего не найдено, попробуйте найти другие варианты",
  "p-trade-action-clear-filters": "Очистить все фильтры",
};
