export default {
  "c-widget-news-moreNews": "plus de nouvelles",

  "c-widget-Search-crypto": "Crypto :",
  "c-widget-Search-fiat": "Fiat :",
  "c-widget-Search-amount": "montant à",
  "c-widget-Search-send": "envoyer",
  "c-widget-Search-recieve": "recevoir",
  "c-widget-Search-buy": "acheter",
  "c-widget-Search-sell": "vendre",
  "c-widget-Search-find": "Trouver",
  "c-widget-Search-selling-offers": "Offres de vente",
  "c-widget-Search-selling": "vente",
  "c-widget-Search-buying-offers": "Offres d'achat",
  "c-widget-Search-buying": "achat",
  "c-widget-Search-price": "prix",
  "c-widget-Search-available": "disponible",
  "c-widget-Search-nothing-found":
    "rien trouvé, essayez de trouver d'autres options",
  "c-widget-Search-close": "fermer",
  "c-widget-Search-show-more": "voir plus",

  "c-widget-Stats-total-users": "Total des utilisateurs",
  "c-widget-Stats-av-reg-per-day": "inscriptions moyennes par jour",
  "c-widget-Stats-daily-active-users": "Utilisateurs actifs quotidiens",
  "c-widget-Stats-daily-active-users-tip":
    "Nombre d'utilisateurs ayant ouvert une session dans le produit en une journée",
  "c-widget-Stats-than-yesterday": "que hier",
  "c-widget-Stats-total-orders": "Total des commandes",
  "c-widget-Stats-av-orders-per-day": "commandes moyennes par jour",
  "c-widget-Stats-total-offers": "Total des offres",
  "c-widget-Stats-new-offers-24h": "nouvelles offres en 24h",

  "c-widget-TipTap-client-toggle-fullscreen": "Basculer en plein écran",
  "c-widget-TipTap-client-upload-image": "Télécharger une image",
  "c-widget-TipTap-client-drop-file-here": "Déposer le fichier ici ou",
  "c-widget-TipTap-client-click-to-upload": "cliquez pour télécharger",
  "c-widget-TipTap-client-jpg-png-files": "fichiers jpg/png de moins de 2MB",
  "c-widget-TipTap-client-heading-1": "Titre 1",
  "c-widget-TipTap-client-heading-2": "Titre 2",
  "c-widget-TipTap-client-heading-3": "Titre 3",
  "c-widget-TipTap-client-paragraph": "Paragraphe",
  "c-widget-TipTap-client-image": "Image",
  "c-widget-TipTap-client-divider": "séparateur",
  "c-widget-TipTap-client-break": "pause",
  "c-widget-TipTap-client-clear-marks": "effacer les marques",
  "c-widget-TipTap-client-please-input-link":
    "Veuillez saisir le lien à coller",
  "c-widget-TipTap-client-tip": "Astuce",
  "c-widget-TipTap-client-ok": "OK",
  "c-widget-TipTap-client-cancel": "Annuler",
  "c-widget-TipTap-client-input-canceled": "Saisie annulée",
  "c-widget-TipTap-client-picture-format":
    "L'image doit être au format jpg/png !",
  "c-widget-TipTap-client-picture-size":
    "La taille de l'image ne doit pas dépasser 2MB !",
};
