export default {
  "p-trade-head-title":
    "Առևտրել կրիպտոարժույթ | Ապահով թվային ակտիվների առևտուր Solid Swap-ում",
  "p-trade-head-description":
    "Սկսեք առևտուր կատարել կրիպտոարժույթով ապահով Solid Swap-ի հետ: Վայելեք արագ և արդյունավետ թվային ակտիվների փոխանակումները մեր հարմարավետ հարթակում",
  "p-trade-head-keywords":
    "կրիպտոարժույթի առևտուր, անվտանգ առևտուր, թվային ակտիվների փոխանակումներ, կրիպտո առևտրի հարթակ",
  "p-trade-needLogin": "Պետք է մուտք գործել՝ ստեղծելու առաջարկ",
  "p-trade-menu-buy": "Գնել",
  "p-trade-menu-sell": "Վաճառել",
  "p-trade-menu-orders": "Պատվերներ",
  "p-trade-menu-myOffers": "Իմ առաջարկները",
  "p-trade-menu-paymentOptions": "Վճարման մեթոդներ",
  "p-trade-buying": "Գնում",
  "p-trade-selling": "Վաճառք",
  "p-trade-placeholder-type": "ընտրեք տիպը...",
  "p-trade-for": "համար",
  "p-trade-placeholder-allOptions": "բոլոր վճարման մեթոդները",
  "p-trade-amountIn": "քանակ",
  "p-trade-placeholder-anyAmount": "ցանկացած քանակ",
  "p-trade-minimumLimit": "նվազագույն առևտրի սահմանափակում",
  "p-trade-equivalent": "համարժեք {MIN_OFFER_AMOUNT_USD} ԱՄՆ դոլարի",
  "p-trade-ALM": "Ցույց տալ միայն ALM վավերացված առևտրականներին",
  "p-trade-postNewOffer": "Տեղադրել նոր առաջարկ",
};
