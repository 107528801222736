export default {
  "p-beginners-guide-head-title":
    "Руководство для начинающих | Начните торговать криптовалютой с Solid Swap",
  "p-beginners-guide-head-description":
    "Новичок в торговле криптовалютой? Наше руководство для начинающих поможет вам начать с легкими для понимания шагами и ценными советами по торговле цифровыми активами",
  "p-beginners-guide-head-keywords":
    "руководство для начинающих, торговля криптовалютой, начало работы, советы по торговле крипто, цифровые активы",
  "p-beginners-guide-account-preparation-title":
    "Подготовка аккаунта для P2P торговли",
  "p-beginners-guide-register-title": "Регистрация на Solid Swap",
  "p-beginners-guide-two-factor-title": "Включите двухфакторную аутентификацию",
  "p-beginners-guide-payment-method-title": "Добавьте способ оплаты",
  "p-beginners-guide-finding-offers-title-1":
    "Поиск предложений и создание предложений",
  "p-beginners-guide-select-currency-title-1": "Выберите валютную пару",
  "p-beginners-guide-search-offer-title-1": "Найдите подходящее предложение",
  "p-beginners-guide-create-offer-title-1": "Создайте предложение обмена",
  "p-beginners-guide-commissions-title-1": "Комиссии",
  "p-beginners-guide-completing-transaction-title-1": "Завершение транзакции",
  "p-beginners-guide-intro-1":
    "Здравствуйте! Это руководство поможет вам совершить ваш первый обмен на Solid Swap. Оно опишет все ключевые шаги, следуя которым вы сможете легко и безопасно завершить транзакцию. Мы сделали этот процесс максимально понятным и доступным, поэтому не беспокойтесь, если вы новичок в мире криптовалют — мы будем с вами на каждом шагу.",
  "p-beginners-guide-intro-2":
    "Давайте начнем с основ: что такое Solid Swap и почему это идеальный выбор для обмена криптовалют. Solid Swap - это платформа для быстрой торговли криптовалютами и фиатными деньгами, которая в настоящее время предлагает услуги peer-to-peer (P2P). Мы предоставляем безопасный и выгодный обмен с конкурентными ставками, низкими комиссиями и высоким уровнем безопасности.",
  "p-beginners-guide-steps-title":
    "Ключевые шаги для успешной торговли на Solid Swap:",
  "p-beginners-guide-follow-steps":
    "Следуя нашим инструкциям, вы сможете быстро, безопасно и уверенно совершить свою первую сделку на Solid Swap.",
  "p-beginners-guide-step1-title": "Шаг 1: Регистрация",
  "p-beginners-guide-register-instructions": "Для регистрации на Solid Swap:",
  "p-beginners-guide-register-step1":
    "Нажмите кнопку “Регистрация” в правом верхнем углу экрана.",
  "p-beginners-guide-register-step2":
    "Заполните необходимые данные и нажмите “Продолжить”.",
  "p-beginners-guide-register-step3":
    "Проверьте электронную почту, которую вы указали при регистрации, и следуйте ссылке в письме, чтобы подтвердить ваш адрес электронной почты.",
  "p-beginners-guide-register-step4": "Войдите в ваш аккаунт.",
  "p-beginners-guide-step2-title":
    "Шаг 2: Включение двухфакторной аутентификации",
  "p-beginners-guide-two-factor-instructions":
    "Чтобы совершать сделки, необходимо включить двухфакторную аутентификацию. Двухфакторная аутентификация особенно важна для защиты ваших активов и аккаунта. Для этого:",
  "p-beginners-guide-two-factor-step1":
    "Нажмите на значок вашего профиля в правом верхнем углу экрана и перейдите в настройки, нажав кнопку “Настройки”.",
  "p-beginners-guide-two-factor-step2":
    "Нажмите на красную кнопку “Включить 2FA”.",
  "p-beginners-guide-two-factor-step3":
    "Отсканируйте QR-код, отображаемый на экране, чтобы связать его с вашим аккаунтом на Solid Swap.",
  "p-beginners-guide-two-factor-note":
    "Если у вас нет приложения для двухфакторной аутентификации, не волнуйтесь. Вы можете скачать одно из следующих приложений на ваш смартфон:",
  "p-beginners-guide-two-factor-app1":
    "Google Authenticator (для Android и iOS)",
  "p-beginners-guide-two-factor-app2": "Authy (для Android и iOS)",
  "p-beginners-guide-two-factor-app3":
    "Microsoft Authenticator (для Android и iOS)",
  "p-beginners-guide-faq":
    "Для получения более подробной информации о подключении 2FA, а также ответов на другие часто задаваемые вопросы, посетите наш [FAQ](link to FAQ).",
  "p-beginners-guide-step3-title": "Шаг 3: Добавление способа оплаты",
  "p-beginners-guide-payment-method-instructions":
    "Чтобы совершить любой обмен, необходимо добавить способ оплаты. Для этого:",
  "p-beginners-guide-payment-method-step1":
    "В настройках вашего аккаунта перейдите на вкладку “Варианты оплаты”.",
  "p-beginners-guide-payment-method-step2":
    "Нажмите кнопку “+ Добавить новый вариант оплаты”.",
  "p-beginners-guide-payment-method-step3":
    "Выберите удобный способ оплаты из выпадающего списка.",
  "p-beginners-guide-payment-method-step4":
    "В поле “Напишите описание” вы можете указать дополнительную информацию. Это будет отображаться, когда вы являетесь продавцом. Например, номер банковской карты или криптокошелька, на который вы принимаете валюту.",
  "p-beginners-guide-finding-offers-title":
    "Поиск предложений и выбор продавца",
  "p-beginners-guide-select-currency-title": "Шаг 1: Выбор валютной пары",
  "p-beginners-guide-select-currency-step1":
    "Перейдите на вкладку P2P торговли, расположенную на панели в верхней части страницы.",
  "p-beginners-guide-select-currency-step2":
    "Выберите действие, которое вы планируете выполнить: покупка или продажа.",
  "p-beginners-guide-select-currency-step3":
    "Выберите способ оплаты, который будет использоваться в транзакции.",
  "p-beginners-guide-select-currency-step4":
    "Введите сумму валюты, которую вы планируете обменять, чтобы увидеть все подходящие предложения.",
  "p-beginners-guide-select-currency-note":
    "Валютную пару также можно выбрать на главной странице сайта.",
  "p-beginners-guide-search-offer-title":
    "Шаг 2: Поиск подходящего предложения",
  "p-beginners-guide-search-offer-description":
    "Выберите наиболее подходящее для вас предложение обмена.",
  "p-beginners-guide-search-offer-note":
    "При выборе продавца обратите внимание на следующие показатели:",
  "p-beginners-guide-search-offer-seller-rating":
    "Рейтинг продавца: У каждого продавца есть рейтинг, основанный на отзывах предыдущих покупателей. Выбирайте продавцов с высокими рейтингами для большей уверенности.",
  "p-beginners-guide-search-offer-transactions":
    "Количество завершенных транзакций: Обратите внимание на количество успешных транзакций, проведенных продавцом. Продавцы с большим количеством транзакций, как правило, более надежны.",
  "p-beginners-guide-search-offer-reviews":
    "Отзывы: Читайте отзывы других пользователей о продавце. Это поможет вам оценить их репутацию и качество предоставляемых услуг. Вы можете сделать это в профиле продавца.",
  "p-beginners-guide-search-offer-limits":
    "Лимиты суммы транзакции: Проверьте минимальные и максимальные суммы, которые продавец готов обменять. Убедитесь, что они соответствуют вашим требованиям.",
  "p-beginners-guide-create-offer-title": "Шаг 3: Выбор предложения",
  "p-beginners-guide-create-offer-step1":
    "Когда найдете подходящее предложение, нажмите зеленую кнопку “Купить” рядом с ним, чтобы просмотреть детали.",
  "p-beginners-guide-create-offer-step2":
    "Просмотрите условия сделки, включая обменный курс, минимальные и максимальные суммы, а также способы оплаты.",
  "p-beginners-guide-create-offer-step3":
    "Если вы согласны с условиями, можете продолжить открытие транзакции. Для этого в открывшемся окне заполните пустые поля и выберите способ оплаты. Нажмите кнопку “Купить”.",
  "p-beginners-guide-create-offer-step4":
    "Если ни одно из представленных предложений вам не подходит, вы можете создать свое собственное. Для этого:",
  "p-beginners-guide-create-offer-note1":
    "Нажмите кнопку “+ Разместить новое предложение”.",
  "p-beginners-guide-create-offer-note2":
    "Заполните поля необходимой информацией.",
  "p-beginners-guide-create-offer-note3":
    "В описании можно указать дополнительную информацию о сделке.",
  "p-beginners-guide-create-offer-note4": "Нажмите кнопку “Подтвердить”.",
  "p-beginners-guide-create-offer-note5":
    "После этого вы окажетесь в разделе “Мои предложения”. Здесь вы найдете все созданные вами предложения. Чтобы сделать предложение видимым для других пользователей сайта, измените его статус. Вы можете сделать это, нажав соответствующий переключатель справа от предложения.",
  "p-beginners-guide-commissions-title": "Пожалуйста, обратите внимание!",
  "p-beginners-guide-commissions-description":
    "Мы стремимся к прозрачности во всех аспектах нашей работы, включая комиссии. Торговая комиссия составляет всего 0.2% от суммы сделки, которую оплачивает продавец. Это позволяет вам легко планировать свои сделки, зная, что не будет неожиданных сборов.",
  "p-beginners-guide-completing-transaction-title": "Завершение сделки",
  "p-beginners-guide-completing-transaction-description1":
    "После того, как вы нашли подходящее предложение обмена и нажали кнопку “Купить”, вы окажетесь на странице сделки. Здесь указана вся информация о обмене. Мы настоятельно рекомендуем проверить ее, чтобы убедиться в точности и избежать ошибочных сделок!",
  "p-beginners-guide-completing-transaction-description2":
    "На странице сделки вы также найдете чат с продавцом. В нем вы можете уточнить условия обмена или другую интересующую вас информацию.",
  "p-beginners-guide-completing-transaction-description3":
    "В окне активной сделки:",
  "p-beginners-guide-completing-transaction-step1":
    "Проверьте условия и детали обмена.",
  "p-beginners-guide-completing-transaction-step2":
    "Сделайте перевод на указанный продавцом способ оплаты.",
  "p-beginners-guide-completing-transaction-step3":
    "Подтвердите перевод, нажав кнопку “Платеж был отправлен”.",
  "p-beginners-guide-completing-transaction-step4":
    "Ожидайте подтверждения оплаты продавцом и перевода средств на ваш счет.",
  "p-beginners-guide-completing-transaction-step5":
    "Оставьте отзыв о сделке: отзывы помогают пользователям понять, с кем сотрудничать, а кого избегать. Мы ценим ваши усилия по созданию благоприятной атмосферы на нашей платформе!",
  "p-beginners-guide-active-transactions-note1":
    "Вы можете найти свои активные сделки в разделе P2P торговли, во вкладке “заказы”.",
  "p-beginners-guide-active-transactions-note2":
    "В случае возникновения спора вы можете открыть спор через 30 минут после подтверждения перевода оплаты. Кнопка для этого появится на странице сделки после истечения таймера.",
  "p-beginners-guide-active-transactions-note3":
    "Если вы решили продать валюту и создали предложение о продаже, дождитесь отклика. Когда это произойдет, вы получите уведомление.",
  "p-beginners-guide-active-transactions-note4":
    "После нахождения покупателя продолжите обмен.",
  "p-beginners-guide-active-transactions-note5":
    "Проверьте условия и детали обмена.",
  "p-beginners-guide-active-transactions-note6":
    "Убедитесь, что оплата была произведена покупателем.",
  "p-beginners-guide-active-transactions-note7":
    "Подтвердите получение средств, нажав кнопку “Платеж получен”.",
  "p-beginners-guide-active-transactions-note8":
    "Отправьте согласованную сумму на указанный покупателем способ получения.",
  "p-beginners-guide-ending-note1":
    "Готово! Вы успешно завершили свою первую сделку на Solid Swap. Мы надеемся, что это руководство помогло вам пройти все шаги без труда.",
  "p-beginners-guide-ending-note2":
    "Всегда помните о безопасности при обмене криптовалют. Всегда проверяйте условия сделки и репутацию продавца, используйте двухфакторную аутентификацию и будьте осторожны при переводе средств. Если у вас возникли вопросы или проблемы, наша служба поддержки всегда готова помочь вам.",
  "p-beginners-guide-ending-note3":
    "Спасибо, что выбрали Solid Swap. Желаем вам успешных и безопасных обменов!",
  "p-beginners-guide-ending-note4":
    "Если у вас возникли вопросы или проблемы, наша служба поддержки всегда готова помочь вам.",
  "p-beginners-guide-ending-note5":
    "Спасибо, что выбрали Solid Swap. Желаем вам успешных и безопасных обменов!",
};
