export default {
  "s-useNotifications-order": "Պատվեր №{orderId}",
  "s-useNotifications-system-message": "Համակարգային հաղորդագրություն",
  "s-useNotifications-new-order-created": "Նոր պատվեր ստեղծված է!",
  "s-useNotifications-order-status-changed":
    'Պատվերի կարգավիճակը փոխվել է "{status}"',
  "s-useNotifications-order-created": "Պատվերը ստեղծված է",
  "s-useNotifications-order-deleted": "Պատվերը ջնջված է",
  "s-useNotifications-moderator-joined": "Մոդերատորը միացել է",
  "s-useNotifications-attached-file": "[կցված ֆայլ]",
};
