export default {
  "p-trade-orders-title":
    "Торговые заказы | Управляйте вашими заказами криптовалют на Solid Swap",
  "p-trade-orders-description":
    "Эффективно управляйте вашими заказами криптовалют на Solid Swap. Отслеживайте и контролируйте ваши торговые действия легко",
  "p-trade-orders-keywords":
    "управление заказами крипто, торговые заказы, отслеживание заказов криптовалют, управление торговлей",

  "p-trade-orders-order-fetch-error":
    "Произошла ошибка при загрузке заказа, пожалуйста, перезагрузите страницу",
  "p-trade-orders-order-not-found": 'Заказ "{orderId}" не найден',
  "p-trade-orders-payment-was-sent": "Платеж был отправлен",
  "p-trade-orders-payment-was-sent-confirm":
    "Вы подтверждаете, что платеж был отправлен с вашей стороны? Делайте это только после подтверждения от банка. Продолжить?",
  "p-trade-orders-payment-received": "Платеж получен",
  "p-trade-orders-payment-received-confirm":
    "Вы подтверждаете, что платеж был получен? Делайте это только после подтверждения от вашего банка. Если вы хотите продолжить, введите 2FA код из вашего приложения ниже",
  "p-trade-orders-open-dispute": "Открыть спор",
  "p-trade-orders-open-dispute-confirm":
    "Вы хотите открыть спор по этому заказу. Продолжить?",
  "p-trade-orders-change-status": "Изменить статус",
  "p-trade-orders-change-status-confirm":
    "Вы уверены, что хотите изменить статус?",
  "p-trade-orders-continue": "Продолжить",
  "p-trade-orders-cancel": "Отмена",
  "p-trade-orders-invalid-2fa": "Неверный 2FA",
  "p-trade-orders-status-changed": "Статус заказа изменен",
  "p-trade-orders-fetch-order-error":
    "Произошла ошибка при получении данных заказа",
  "p-trade-orders-go-to-all-orders": "Перейти ко всем заказам",
  "p-trade-orders-status": "Статус",
  "p-trade-orders-waiting-for-payment": "Ожидание оплаты покупателем",
  "p-trade-orders-payment-sent": "Покупатель отправил платеж",
  "p-trade-orders-order-completed": "Заказ завершен",
  "p-trade-orders-order-cancelled": "Заказ отменен",
  "p-trade-orders-dispute": "СПОР",
  "p-trade-orders-auto-cancel-after": "Автоотмена заказа после",
  "p-trade-orders-open-dispute-after": "Спор можно открыть после",
  "p-trade-orders-order-created": "Заказ создан",
  "p-trade-orders-summary": "Резюме",
  "p-trade-orders-sell": "ПРОДАТЬ",
  "p-trade-orders-buy": "КУПИТЬ",
  "p-trade-orders-for": "за",
  "p-trade-orders-fee": "Комиссия",
  "p-trade-orders-included-in-transaction": "включено в транзакцию",
  "p-trade-orders-selling": "Продажа",
  "p-trade-orders-buying": "Покупка",
  "p-trade-orders-price": "Цена",
  "p-trade-orders-offer-creator": "Создатель предложения",
  "p-trade-orders-trader-info": "Информация, предоставленная трейдером",
  "p-trade-orders-no-description": "Трейдер не указал описание.",
  "p-trade-orders-no-description-additional-info":
    "Вы получите дополнительную информацию после создания заказа.",
  "p-trade-orders-trading-experience": "Каков ваш опыт торговли?",
  "p-trade-orders-positive": "Положительный",
  "p-trade-orders-negative": "Отрицательный",
  "p-trade-orders-your-feedback": "Ваш отзыв",

  "p-trade-ordersIndex-all": "Все",
  "p-trade-ordersIndex-active": "Активные",
  "p-trade-ordersIndex-completed": "Завершенные",
  "p-trade-ordersIndex-cancelled": "Отмененные",
  "p-trade-ordersIndex-dispute": "Спор",
  "p-trade-ordersIndex-coin": "Монета",
  "p-trade-ordersIndex-order-type": "Тип заказа",
  "p-trade-ordersIndex-dates": "Даты",
  "p-trade-ordersIndex-reset-filters": "сбросить фильтры",
  "p-trade-ordersIndex-loading-error":
    "Что-то пошло не так при загрузке заказа :(",
  "p-trade-ordersIndex-orders": "заказы",
  "p-trade-ordersIndex-id-type": "ID / Тип",
  "p-trade-ordersIndex-operation-info": "Информация о операции",
  "p-trade-ordersIndex-status-date": "Статус / Дата",
  "p-trade-ordersIndex-id": "ID",
  "p-trade-ordersIndex-sell": "ПРОДАТЬ",
  "p-trade-ordersIndex-buy": "КУПИТЬ",
  "p-trade-ordersIndex-go-to-details": "перейти к деталям",
  "p-trade-ordersIndex-summary": "Резюме",
  "p-trade-ordersIndex-fee": "Комиссия",
  "p-trade-ordersIndex-selling": "Продажа",
  "p-trade-ordersIndex-buying": "Покупка",
  "p-trade-ordersIndex-for": "За",
  "p-trade-ordersIndex-price": "Цена",
  "p-trade-ordersIndex-offer-creator": "Создатель предложения",
  "p-trade-ordersIndex-order-created": "Заказ создан",
  "p-trade-ordersIndex-status": "Статус",
  "p-trade-ordersIndex-waiting-for-buyer": "Ожидание оплаты покупателем",
  "p-trade-ordersIndex-buyer-sent-payment": "Покупатель отправил платеж",
  "p-trade-ordersIndex-order-completed": "Заказ завершен",
  "p-trade-ordersIndex-order-cancelled": "Заказ отменен",
  "p-trade-ordersIndex-last7days": "Последние 7 дней",
  "p-trade-ordersIndex-lastMonth": "Прошлый месяц",
  "p-trade-ordersIndex-last3month": "Последние 3 месяца",
  "p-trade-ordersIndex-dispute-caps": "СПОР",
};
