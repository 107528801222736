export default {
  "p-profile-userId-error": "Fehler: Benutzer nicht gefunden",
  "p-profile-userId-online": "Online",
  "p-profile-userId-last-online": "war online {date}",
  "p-profile-userId-registration-date": "Registrierungsdatum",
  "p-profile-userId-total-orders": "Gesamtaufträge",
  "p-profile-userId-buy": "KAUFEN",
  "p-profile-userId-sell": "VERKAUFEN",
  "p-profile-userId-last-month-orders": "Aufträge des letzten Monats",
  "p-profile-userId-average-confirm-time":
    "Durchschnittliche Zahlungsbestätigungszeit",
  "p-profile-userId-average-send-time": "Durchschnittliche Zahlungsversandzeit",
  "p-profile-userId-feedback": "Feedback",
  "p-profile-userId-positive": "positiv",
  "p-profile-userId-negative": "negativ",
  "p-profile-userId-offers-tab": "Angebote",
  "p-profile-userId-feedback-tab": "Feedback",
  "p-profile-userId-no-offers": "Keine Angebote",
  "p-profile-userId-buy-orders": "Kaufaufträge",
  "p-profile-userId-sell-orders": "Verkaufsaufträge",
  "p-profile-userId-no-feedback": "Kein Feedback",
};
