export default {
  "p-trade-my-offers-title":
    "Мои предложения | Управляйте вашими предложениями криптовалют на Solid Swap",
  "p-trade-my-offers-description":
    "Просматривайте и управляйте вашими предложениями криптовалют на Solid Swap. Обеспечьте оптимальные торговые стратегии с нашим удобным интерфейсом",
  "p-trade-my-offers-keywords":
    "управление предложениями крипто, предложения криптовалют, торговые предложения, предложения цифровых активов",
  "p-trade-my-offers-delete-confirm":
    "Вы уверены, что хотите удалить это предложение?",
  "p-trade-my-offers-warning": "Предупреждение",
  "p-trade-my-offers-ok": "ОК",
  "p-trade-my-offers-cancel": "Отмена",
  "p-trade-my-offers-post-new-offer": "Разместить новое предложение",
  "p-trade-my-offers-no-active-offers": "Активные предложения не найдены",
  "p-trade-my-offers-your-selling-offers": "Ваши предложения о продаже",
  "p-trade-my-offers-your-buying-offers": "Ваши предложения о покупке",
};
