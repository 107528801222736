export default {
  "c-user-avatarChange-avatar-must-be-jpg": "Ավատարը պետք է լինի JPG ֆորմատով:",
  "c-user-avatarChange-avatar-size-exceed":
    "Ավատարի չափը չպետք է գերազանցի 2MB:",
  "c-user-avatarChange-avatar-upload-error": "Նկարի վերբեռնման սխալ:",
  "c-user-avatarChange-change-avatar": "Փոխել ավատար",
  "c-user-avatarChange-avatar-file-restriction":
    "jpg/png ֆայլեր՝ չափը 5 MB-ից քիչ:",

  "c-user-cookiealert-cookie-preference": "Թխվածքների նախապատվություններ",
  "c-user-cookiealert-essential-cookies":
    "Մենք օգտագործում ենք թխվածքներ և նման տեխնոլոգիաներ, որոնք անհրաժեշտ են մեր կայքերի գործարկման համար (անհրաժեշտ թխվածքներ):",
  "c-user-cookiealert-additional-cookies":
    "Մենք օգտագործում ենք նաև վերլուծության, ֆունկցիոնալության և թիրախային թխվածքներ՝ մեր կայքերի երթևեկությունը վերլուծելու, ձեր փորձը օպտիմալացնելու, բովանդակությունը անհատականացնելու և նպատակային գովազդներ մատուցելու համար:",
  "c-user-cookiealert-manage-cookies":
    "Դուք կարող եք անջատել թխվածքները ցանկացած պահի՝ այցելելով թխվածքների կառավարման տարբերակը էջի ներքևում:",
  "c-user-cookiealert-reject-cookies": "Մերժել թխվածքները",
  "c-user-cookiealert-accept-continue": "Ընդունել և շարունակել",

  "c-Enable2FA-enabled": "Երկու-ֆակտորային նույնականացումը (2FA) միացված է!",
  "c-Enable2FA-disable": "Անջատել 2FA",
  "c-Enable2FA-not-recommended": "(չի առաջարկվում)",
  "c-Enable2FA-enable": "Միացնել 2FA",
  "c-Enable2FA-highly-recommended": "(բարձր առաջարկվում է)",
  "c-Enable2FA-enable-2fa": "Միացնել 2FA",
  "c-Enable2FA-scan-image":
    "Սքանավորեք ստորև նկարն՝ օգտագործելով ձեր 2FA հավելվածը՝ երկու-ֆակտորային նույնականացումը միացնելու համար",
  "c-Enable2FA-enter-address": "Կամ մուտքագրեք այս հասցեն ձեր հավելվածում",
  "c-Enable2FA-enter-code": "Մուտքագրեք 2FA կոդը",
  "c-Enable2FA-cancel": "Չեղարկել",
  "c-Enable2FA-error-getting-image": "Սխալ՝ ստանալով 2FA նկարը!",
  "c-Enable2FA-success-enable": "2FA հաջողությամբ միացվել է!",
  "c-Enable2FA-error-enable": "2FA միացնելիս սխալ է տեղի ունեցել!",
  "c-Enable2FA-disable-prompt":
    "Դուք պատրաստվում եք անջատել Երկու-ֆակտորային նույնականացումը (2FA) ձեր հաշվին: Խնդրում ենք նկատի ունենալ, որ սա կիջեցնի ձեր հաշվի անվտանգության մակարդակը: 2FA անջատելուց հետո դուք չեք կարողանա գործարկել կամ դուրս բերել միջոցներ 24 ժամվա ընթացքում: Եթե ցանկանում եք շարունակել, խնդրում ենք մուտքագրել ձեր 2FA կոդը ստորև և սեղմել 'Անջատել 2FA':",
  "c-Enable2FA-warning": "Զգուշացում",
  "c-Enable2FA-input-error": "Մուտքագրեք ձեր 2FA կոդը",
  "c-Enable2FA-success-disable": "2FA չեղարկվել է",
  "c-Enable2FA-error-disable": "2FA չեղարկման սխալ!",

  "c-UserMenu-orders": "Պատվերներ",
  "c-UserMenu-payment-options": "Վճարման տարբերակներ",
  "c-UserMenu-wallet": "Դրամապանակ",
  "c-UserMenu-notifications": "Ծանուցումներ",
  "c-UserMenu-settings": "Կարգավորումներ",
  "c-UserMenu-partner-dashboard": "Գործընկեր",

  "c-NotificationSettings-title": "Ծանուցումների կարգավորումներ",
  "c-NotificationSettings-alert":
    "Խնդրում ենք նկատի ունենալ, որ ծանուցումների կարգավորումները սարքավորման հատուկ են: Դուք ունեք տարբեր կարգավորումներ յուրաքանչյուր սարքի համար:",
  "c-NotificationSettings-check-all": "Նշեք բոլորը",
  "c-NotificationSettings-website-notifications": "Կայքի ծանուցումներ:",
  "c-NotificationSettings-order-notifications": "Պատվերի ծանուցումներ",
  "c-NotificationSettings-chat-messages": "Չատ հաղորդագրություններ",
  "c-NotificationSettings-system-notifications": "Համակարգային ծանուցումներ:",
  "c-NotificationSettings-system-alert":
    "Համակարգային ծանուցումները սարքավորման հատուկ են: Այս ծանուցումները կաշխատեն միայն եթե դուք թույլատրել եք ձեր դիտարկչին ցուցադրել ծանուցումներ.",

  "c-PasswordChange-change-password": "Փոխել գաղտնաբառը",
  "c-PasswordChange-dialog-title": "Փոխեք ձեր գաղտնաբառը",
  "c-PasswordChange-description":
    "Խնդրում ենք մուտքագրել ձեր ընթացիկ գաղտնաբառը և ընտրել նոր մեկը: Ձեր նոր գաղտնաբառը պետք է համապատասխանի հետևյալ պահանջներին:",
  "c-PasswordChange-current-password": "Ընթացիկ գաղտնաբառ",
  "c-PasswordChange-new-password": "Նոր գաղտնաբառ",
  "c-PasswordChange-confirm-new-password": "Հաստատեք նոր գաղտնաբառը",
  "c-PasswordChange-min-characters": "Նվազագույնը 6 նիշ",
  "c-PasswordChange-max-characters": "Արավելագույնը 20 նիշ",
  "c-PasswordChange-2fa-code": "2FA կոդ",
  "c-PasswordChange-case-sensitive":
    "Գաղտնաբառերը case sensitive են: Ընտրեք ուժեղ, յուրահատուկ գաղտնաբառ՝ ձեր հաշվի անվտանգությունն ապահովելու համար:",
  "c-PasswordChange-cancel": "Չեղարկել",
  "c-PasswordChange-wait-loading": "Խնդրում ենք սպասել բեռնմանը...",
  "c-PasswordChange-enter-current-password":
    "Խնդրում ենք մուտքագրել ձեր ընթացիկ գաղտնաբառը",
  "c-PasswordChange-enter-new-password":
    "Խնդրում ենք մուտքագրել ձեր նոր գաղտնաբառը",
  "c-PasswordChange-please-confirm-new-password":
    "Խնդրում ենք հաստատել ձեր նոր գաղտնաբառը",
  "c-PasswordChange-passwords-not-match":
    "Երկու գաղտնաբառերը չեն համապատասխանում",
  "c-PasswordChange-enter-2fa-code": "Խնդրում ենք մուտքագրել ձեր 2FA կոդը",
  "c-PasswordChange-password-changed-success":
    "Գաղտնաբառը հաջողությամբ փոխվել է!",
  "c-PasswordChange-error-changing-password": "Գաղտնաբառի փոփոխման սխալ!",

  "c-TFAAlert-2fa-disabled-title":
    "Ձեր երկու-ֆակտորային նույնականացումը անջատված է",
  "c-TFAAlert-attention": "Ուշադրություն!",
  "c-TFAAlert-message-1":
    "Թվում է, որ դուք դեռ չեք միացրել Երկու-ֆակտորային նույնականացումը (2FA): Ձեր անվտանգության համար որոշ գործողություններ, ինչպիսիք են պատվերի, առաջարկների ստեղծումը և միջոցների դուրս բերումը սահմանափակված են մինչև 2FA-ի ակտիվացումը:",
  "c-TFAAlert-message-2":
    "Դուք կարող եք շարունակել օգտագործել կայքը, բայց խնդրում ենք հաշվի առնել 2FA-ի ակտիվացումը՝ ամբողջական ֆունկցիոնալությունը բացելու և ձեր հաշվի անվտանգությունն ապահովելու համար:",
  "c-TFAAlert-remind-me-later": "Հիշեցնել ավելի ուշ",
  "c-TFAAlert-go-to-setup": "Գնալ 2FA կարգավորումներ",

  "c-partner-bonuses-list": "Բոնուսների ցուցակ",
  "c-partner-bonuses-description":
    "Այստեղ կարող եք տեսնել ձեր կողմից ստացված բոնուսների ցուցակը այն հաճախորդներից, ովքեր օգտագործել են ձեր ռեֆերալ հղումը:",
  "c-partner-bonus-id": "ID",
  "c-partner-bonus-amount": "Բոնուսի գումարը",
  "c-partner-bonus-date": "Ամսաթիվ",

  "c-partner-withdrawals-list": "Կանխավճարների ցուցակ",
};
