export default {
  "p-trade-profile-title":
    "Варианты оплаты | Безопасные методы оплаты криптовалюты на Solid Swap",
  "p-trade-profile-description":
    "Откройте для себя различные безопасные варианты оплаты, доступные на Solid Swap для торговли криптовалютами. Выберите лучший метод для ваших нужд",
  "p-trade-profile-keywords":
    "варианты оплаты крипто, безопасные методы оплаты, оплаты цифровых валют, транзакции криптовалют",
  "p-trade-profile-option-delete-confirm":
    "Это действие удалит вашу опцию оплаты. Продолжить?",
  "p-trade-profile-warning": "Предупреждение",
  "p-trade-profile-delete": "Удалить",
  "p-trade-profile-cancel": "Отмена",
  "p-trade-profile-option-delete-error":
    "Произошла ошибка при удалении опции оплаты",
  "p-trade-profile-my-payment-options": "Мои варианты оплаты",
  "p-trade-profile-payment-options-info-1":
    "Это ваши варианты оплаты, необходимые для всех транзакций между пользователями.",
  "p-trade-profile-payment-options-info-2":
    "Если вы продаете криптовалюту, варианты оплаты будут информировать контрагента, куда перевести средства для покупки.",
  "p-trade-profile-payment-options-info-3":
    "Если вы покупаете криптовалюту, эти опции будут использоваться для выбора типа оплаты при создании заказа, указывая продавцу ваш предпочтительный метод оплаты для текущего заказа.",
  "p-trade-profile-no-options": "У вас пока нет опций",
  "p-trade-profile-edit": "Редактировать",
  "p-trade-profile-add-new-payment-option": "Добавить новую опцию оплаты",
};
