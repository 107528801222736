export default {
  "p-registration-label-email": "E-Mail",
  "p-registration-label-username": "Benutzername",
  "p-registration-label-password": "Passwort",
  "p-registration-label-repeat-password": "Passwort wiederholen",
  "p-registration-head-title":
    "Registrierung | Erstellen Sie Ihr Solid Swap-Konto",
  "p-registration-head-description":
    "Melden Sie sich für ein Solid Swap-Konto an, um sicher und effizient mit Kryptowährungen zu handeln",
  "p-registration-head-keywords":
    "registrierung, konto erstellen, Solid Swap anmelden, krypto-handelsregistrierung",
  "p-registration-rules-username": "Bitte geben Sie Ihren Benutzernamen ein",
  "p-registration-rules-username-length":
    "Die Länge des Benutzernamens muss zwischen 5 und 15 Zeichen liegen",
  "p-registration-rules-email": "Bitte geben Sie Ihre E-Mail ein",
  "p-registration-rules-password": "Bitte geben Sie Ihr Passwort ein",
  "p-registration-rules-password-length":
    "Die Länge des Passworts muss zwischen 6 und 21 Zeichen liegen",
  "p-registration-rules-password-confirm": "Bitte bestätigen Sie Ihr Passwort",
  "p-registration-rules-password-match": "Passwörter stimmen nicht überein",
  "p-registration-rules-terms": "Bitte akzeptieren Sie die Nutzungsbedingungen",
  "p-registration-success-message":
    "Registrierung erfolgreich. Bitte überprüfen Sie Ihre E-Mail auf einen Bestätigungslink.",
  "p-registration-error-default":
    "Hoppla, etwas ist bei der Registrierung schiefgelaufen.",
  "p-registration-error-data": "Etwas stimmt nicht mit den eingegebenen Daten",
  "p-registration-error-account-exists":
    "Es scheint, dass Sie bereits ein Konto haben.",
  "p-registration-login-link": "Bitte melden Sie sich an",
  "p-registration-title": "Registrieren",
  "p-registration-email-placeholder": "Geben Sie Ihre E-Mail ein",
  "p-registration-username-placeholder": "Geben Sie Ihren Benutzernamen ein",
  "p-registration-password-placeholder": "Geben Sie Ihr Passwort ein",
  "p-registration-password-confirm-placeholder": "Bestätigen Sie Ihr Passwort",
  "p-registration-terms-message": "Ich stimme den",
  "p-registration-terms": "Nutzungsbedingungen",
  "p-registration-and": "und",
  "p-registration-privacy": "Datenschutzbestimmungen",
  "p-registration-continue": "Fortfahren",
  "p-registration-success-title": "Erfolg!",
  "p-registration-welcome-title":
    "Treten Sie unserer lebendigen Gemeinschaft von Krypto-Enthusiasten bei!",
  "p-registration-welcome-message-1":
    "Registrieren Sie sich jetzt, um das volle Potenzial des Peer-to-Peer-Handels freizuschalten.",
  "p-registration-welcome-message-2":
    "Erleben Sie Freiheit, Fairness und die Zukunft der Finanzen.",
  "p-registration-welcome-message-3":
    "Tauchen Sie ein in den vielfältigen Marktplatz für digitale Währungen, wo sich Chancen und Innovation treffen.",
};
