export default {
  "c-header-menu-dark": "темный",
  "c-header-menu-light": "светлый",
  "c-header-menu-trade": "P2P торговля",
  "c-header-menu-news": "Новости",
  "c-header-menu-support": "Поддержка",
  "c-header-menu-chat": "Чат поддержки",
  "c-header-menu-blog": "Блог",
  "c-header-menu-faq": "FAQ",
  "c-header-menu-beginnersGuide": "Руководство для начинающих",
  "c-header-menu-help": "Помощь",
  "c-header-menu-about": "О нас",
  "c-header-menu-menu": "Меню",
  "c-header-menu-admin": "админ",
  "c-header-menu-moder": "модер",
  "c-header-menu-profile": "Мой профиль",
  "c-header-menu-orders": "Заказы",
  "c-header-menu-paymentOptions": "Варианты оплаты",
  "c-header-menu-wallet": "Кошелек",
  "c-header-menu-settings": "Настройки",
  "c-header-menu-logout": "выйти",
  "c-header-menu-login": "Вход",
  "c-header-menu-registration": "Регистрация",
  "c-header-menu-notifications": "Уведомления",
};
