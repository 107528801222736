export default {
  "c-blog-editingform-pictureFormatError":
    "Նկարը պետք է լինի jpg/png ֆորմատով:",
  "c-blog-editingform-pictureSizeError": "Նկարի չափը չպետք է գերազանցի 2MB:",
  "c-blog-editingform-uploadError": "Նկարի վերբեռնման սխալ:",
  "c-blog-editingform-titleRequired": "Վերնագիրը պարտադիր է:",
  "c-blog-editingform-idCodeRequired": "URL կոդը պարտադիր է:",
  "c-blog-editingform-descriptionRequired": "Նկարագրությունը պարտադիր է:",
  "c-blog-editingform-previewImageRequired": "Նախադիտման նկարը պարտադիր է:",
  "c-blog-editingform-tagsRequired": "Պիտակները պարտադիր են:",
  "c-blog-editingform-bodyRequired": "Հրապարակման տեքստը պարտադիր է:",
  "c-blog-editingform-metaDescriptionRequired":
    "Մետա նկարագրությունը պարտադիր է:",
  "c-blog-editingform-creatingNewPost": "Ստեղծում է նոր հրապարակում:",
  "c-blog-editingform-title": "Վերնագիր",
  "c-blog-editingform-active": "Ակտիվ",
  "c-blog-editingform-idCode": "URL կոդ",
  "c-blog-editingform-idCodeHint":
    "Կոդը պետք է լինի յուրահատուկ յուրաքանչյուր բլոգի համար, փոքրատառերով և '-' նշանով բացատների փոխարեն:",
  "c-blog-editingform-description": "Նկարագրություն",
  "c-blog-editingform-previewImage": "Նախադիտման նկար",
  "c-blog-editingform-uploadHint1": "Քաշեք ֆայլը այստեղ կամ",
  "c-blog-editingform-uploadHint2": "սեղմեք վերբեռնելու համար",
  "c-blog-editingform-uploadHint3": "jpg/png ֆայլեր՝ չափը 2MB-ից քիչ:",
  "c-blog-editingform-tags": "Պիտակներ",
  "c-blog-editingform-tagsPlaceholder": "Ընտրեք պիտակներ ձեր հոդվածի համար",
  "c-blog-editingform-tagsHint":
    "Կարող եք ընտրել գոյություն ունեցող պիտակներից կամ ստեղծել նորերը՝ մուտքագրելով դաշտում և սեղմելով Enter:",
  "c-blog-editingform-postText": "Հրապարակման տեքստ",
  "c-blog-editingform-metaDescription": "Մետա նկարագրություն",
  "c-blog-editingform-cancel": "Չեղարկել",
  "c-blog-editingform-save": "Պահպանել",

  "c-blog-item-readMore": "Կարդալ ավելին",
};
