export default {
  "p-about-head-title":
    "Մասին Solid Swap | Ծանոթացեք մեր կրիպտոարժույթի փոխանակման թիմի հետ",
  "p-about-head-description":
    "Իմացեք ավելին Solid Swap-ի մասին, մեր ապահով կրիպտոարժույթի փոխանակման հարթակի թիմի և մեր առաքելության մասին, որը նպատակաուղղված է ապահովելու արդյունավետ թվային ակտիվների փոխանակում",
  "p-about-head-keywords":
    "կրիպտոարժույթի փոխանակում, անվտանգ առևտուր, թվային ակտիվներ, կրիպտո առևտուր, Solid Swap, solidswap",
  "p-about-contact-title": "Կապվեք մեզ հետ",
  "p-about-address": "Հասցե:",
  "p-about-index": "Ինդեքս:",
  "p-about-tax-id": "ՀՎՀՀ:",
  "p-about-email": "Էլ. հասցե:",
  "p-about-support-email": "Աջակցության էլ. հասցե:",
  "p-about-duns": "Համաշխարհային համարակալման համակարգ (D-U-N-S):",
  "p-about-duns-check": "Ստուգել D-U-N-S",
  "p-about-traders-count": ">200 առևտրական արդեն մեզ հետ են",
  "p-about-foundation-title": "Քո ֆինանսական անկախության հիմնասյունը",
  "p-about-description":
    "Solid Swap - նոր ծառայություն կրիպտոարժույթի և ֆիատի արագ փոխանակման համար P2P հարթակում",
  "p-about-start-trading": "Սկսել առևտուրը",
  "p-about-fee-tag": "0% վճար",
  "p-about-beneficial-terms": "Օգտակար պայմաններ",
  "p-about-low-fees":
    "Ցածր վճարներ, ակցիաներ, խաղարկություններ և ոչ մի գաղտնի պայման",
  "p-about-fast-transactions": "Արագ գործարքներ",
  "p-about-secure-optimizations":
    "Համեմատաբար բարելավում ենք կոդը, բայց չենք զոհում անվտանգությունը",
  "p-about-online": "Առցանց",
  "p-about-24-support": "Աջակցություն շուրջօրյա",
  "p-about-support-anytime":
    "Կօգնենք ցանկացած խնդիր լուծել ցանկացած ժամանակահատվածում",
  "p-about-contact-support": "Կապվել աջակցությանը",
  "p-about-platform-mission":
    "Ստեղծում ենք հուսալի հարթակ՝ հետևելով երազանքներին և սկզբունքներին",
  "p-about-join-us":
    "Շատ բորսաներ և փոխանակման հարթակներ կան, բայց մենք ունենք մեր տարբերությունը՝ միացեք մեզ և հետևեք զարգացման ընթացքին",
  "p-about-freedom": "Ազատություն և անանունություն",
  "p-about-data-privacy":
    "Չենք ցանկանում դառնալ բանկ, գնահատում ենք անձնական տվյալների անպտառելիությունը",
  "p-about-registered-armenia": "Գրանցում Հայաստանում",
  "p-about-legal-compliance":
    "Իրավական ոլորտի պահանջներին համապատասխան աշխատելու համար՝ պահպանելով անկախությունը",
  "p-about-access-devices": "Մուտք ցանկացած սարքից",
  "p-about-cross-device-experience":
    "Ստեղծում ենք հարմար փորձառություն բոլոր սարքերի համար, ցանկացած իրավիճակում",
  "p-about-services-question":
    "Ի՞նչ ծառայություններ է տրամադրում Solid Swap-ը:",
  "p-about-buy-bitcoin-question": "Ինչպես գնել Bitcoin Solid Swap-ում?",
  "p-about-crypto-question": "Ինչ է կրիպտոարժույթը?",
  "p-about-russians-trade-question":
    "Կարո՞ղ են ռուսաստանցիները առևտուր անել կրիպտոարժույթով:",
  "p-about-pay-with-crypto-question":
    "Կարո՞ղ է վճարումը լինել կրիպտոարժույթով:",
  "p-about-login-trade": "Մուտք գործեք՝ առևտուր սկսելու համար",
  "p-about-login": "Մուտք",
  "p-about-register": "Գրանցվել",
};
