export default {
  "c-offers-table-loginWarning":
    "Necesitas iniciar sesión para crear un pedido",
  "c-offers-table-toggleError": "Algo salió mal al alternar la oferta",
  "c-offers-table-insufficientBalance":
    "No tienes suficiente saldo para activar esta oferta",
  "c-offers-table-goToWallet": "ir a la cartera",
  "c-offers-table-seller": "Vendedor",
  "c-offers-table-buyer": "Comprador",
  "c-offers-table-price": "Precio",
  "c-offers-table-availableLimit": "Límite disponible",
  "c-offers-table-available": "Disponible",
  "c-offers-table-offerLimits": "Límites de la oferta",
  "c-offers-amount-unlimited": "la cantidad de la oferta es ilimitada",
  "c-offers-table-paymentOptions": "Opciones de pago",
  "c-offers-table-more": "más",
  "c-offers-table-active": "activo",
  "c-offers-table-disabled": "deshabilitado",
  "c-offers-table-edit": "editar",
  "c-offers-table-delete": "eliminar",
  "c-offers-table-buy": "Comprar",
  "c-offers-table-sell": "Vender",
};
