export default {
  "p-profile-userId-error": "Ошибка: пользователь не найден",
  "p-profile-userId-online": "Онлайн",
  "p-profile-userId-last-online": "был онлайн {date}",
  "p-profile-userId-registration-date": "Дата регистрации",
  "p-profile-userId-total-orders": "Всего заказов",
  "p-profile-userId-buy": "КУПИТЬ",
  "p-profile-userId-sell": "ПРОДАТЬ",
  "p-profile-userId-last-month-orders": "Заказы за последний месяц",
  "p-profile-userId-average-confirm-time": "Среднее время подтверждения оплаты",
  "p-profile-userId-average-send-time": "Среднее время отправки оплаты",
  "p-profile-userId-feedback": "Отзыв",
  "p-profile-userId-positive": "положительный",
  "p-profile-userId-negative": "отрицательный",
  "p-profile-userId-offers-tab": "Предложения",
  "p-profile-userId-feedback-tab": "Отзывы",
  "p-profile-userId-no-offers": "Нет предложений",
  "p-profile-userId-buy-orders": "Заказы на покупку",
  "p-profile-userId-sell-orders": "Заказы на продажу",
  "p-profile-userId-no-feedback": "Нет отзывов",
};
