export default {
  "p-exchange-tos-agreement-intro":
    "Այս Օգտագործողի Համաձայնագիրը (այսուհետ՝ Համաձայնագիր) էլեկտրոնային համաձայնագիր է Օգտագործողի և SolidSwap ծառայության միջև և պարունակում է SolidSwap կայքում աշխատանքի կանոնները",
  "p-exchange-tos-agreement-intro-continued":
    "(այսուհետ՝ Կայք) և Կայքի միջոցով մատուցվող ծառայությունների հասանելիության պայմանները։ Կայքի հետ աշխատանքը սկսելուց առաջ Օգտագործողը (այսուհետ՝ Օգտագործող) պետք է ուշադիր ընթերցի նկարագրված պայմանները և ընդունի այս Համաձայնագիրը։ Եթե Օգտագործողը չի ընդունում Համաձայնագիրը և չի համաձայնվում հետևել այս Համաձայնագրի բոլոր պայմաններին, նա պետք է անմիջապես դադարեցնի Կայքի օգտագործումը։",
  "p-exchange-tos-agreement-updates":
    "Կայքի Վարչակազմը իրավունք է վերապահում իր հայեցողությամբ թարմացնել և փոփոխել Համաձայնագրի տեքստը՝ առանց Օգտագործողներին նախազգուշացնելու։ Համաձայնագրի նոր տարբերակը ուժի մեջ է մտնում անմիջապես դրա հրապարակումից հետո։ Օգտագործողը պարտավոր է ինքնուրույն հետևել Համաձայնագրի նոր տարբերակներին։ Կայքն օգտագործելու շարունակումը՝ փոփոխությունների ուժի մեջ մտնելուց հետո, նշանակում է, որ Օգտագործողը ավտոմատ կերպով ընդունում է Համաձայնագրի նոր տարբերակը։ Եթե Օգտագործողը համաձայն չէ նոր տարբերակի պայմանների հետ, նա պետք է անհապաղ դադարեցնի Կայքի ծառայությունների օգտագործումը։",
  "p-exchange-tos-terms-definitions": "1. Պայմաններ և սահմանումներ",
  "p-exchange-tos-solid-swap-service":
    "1.1. SolidSwap ծառայությունը հարթակ է, որը նախատեսված է վիրտուալ թվային ակտիվների առքուվաճառքի գործարքներ կատարելու համար։",
  "p-exchange-tos-user-definition":
    "1.2. Օգտագործողը անհատ է, որը գրանցվել է Պլատֆորմում և համաձայնել է այս Համաձայնագրի պայմաններին։ Օգտագործողը հաստատում է, որ հասել է 18 տարեկանին, նրա բնակության երկրի օրենքները չեն արգելում այս Ծառայության օգտագործումը, ինչպես նաև Օգտագործողը չի նախատեսում որևէ անօրինական գործողություն կատարել SolidSwap ծառայությունների միջոցով։",
  "p-exchange-tos-title-sign":
    "1.3. Վճարային նշանը վճարային համակարգերի պայմանագրին համապատասխանող պայմանական միավոր է՝ որը համապատասխանեցված է էլեկտրոնային համակարգերի հաշվարկներին։",
  "p-exchange-tos-payment-system":
    "1.4. Վճարային համակարգը առցանց ծառայություն է, որը իրականացնում է ապրանքների և ծառայությունների համար վճարումներ՝ էլեկտրոնային փոխանցումների միջոցով։",
  "p-exchange-tos-service-operations":
    "1.5. Ծառայության ծառայությունները՝ էլեկտրոնային արժույթների մուտքագրում և դուրս բերում, ինչպես նաև այլ ծառայություններ, որոնց մասին տեղեկությունները տեղադրված են Ծառայության կայքում։",
  "p-exchange-tos-payment-definition":
    "1.6. Վճարումը՝ փողի փոխանցումն է, որն իրականացվում է վճարային համակարգի միջոցով։",
  "p-exchange-tos-otc-platform":
    "1.7. OTC պլատֆորմը առքուվաճառքի հարթակ է, որտեղ գտնվում են գործարքների մասնակիցները և իրականացվում են արտամշակման գործարքներ։",
  "p-exchange-tos-personal-data":
    "1.8. Անձնական տվյալներ՝ անձին նույնացնող տվյալներ, ինչպիսիք են՝ անունը, հասցեն, էլ. փոստի հասցեն, բջջային հեռախոսի համարը, մատակարարման տվյալներ և բանկային մանրամասներ։ 'Անձնական Տվյալներ' հասկացությունը չի ներառում անանուն կամ համախմբված տվյալներ, որոնք չեն կարողանում նույնացնել որևէ կոնկրետ Օգտագործող։",
  "p-exchange-tos-agreement-definition":
    "1.9. Համաձայնագիր՝ այս Օգտագործողի Համաձայնագիրը և նրա անբաժանելի մասերը, հաշվի առնելով բոլոր հավելումներն ու փոփոխությունները, որոնք կարող են կատարվել՝ համաձայն դրա պայմանների։",
  "p-exchange-tos-website-definition":
    "1.10. Կայք՝ տեղեկատվության, տեքստերի, գրաֆիկական տարրերի, դիզայնի, պատկերների, լուսանկարների, տեսանյութերի և այլ ինտելեկտուալ գործունեության արդյունքների հավաքածու, ինչպես նաև համակարգչային ծրագրերի համալիր, որոնք ապահովում են այդ տեղեկատվության հասանելիությունը",
  "p-exchange-tos-website-continued":
    ", ներառյալ ցանկացած ենթադոմեյններ, և եթե որևէ պատճառով փոխվի դոմեյնը կամ Կայքի նյութը, դա ներառում է նաև նորացված Կայքը։",
  "p-exchange-tos-intellectual-property":
    "1.11. Ինտելեկտուալ սեփականություն՝ Կայքում հրապարակված բոլոր նյութերն ու Ծառայության կողմից մատուցված բոլոր Ծառայությունները հանդիսանում են Ծառայության սեփականությունը և չեն ենթարկվում երրորդ կողմի օգտագործմանը՝ հրապարակում, տարածում, կրկնօրինակում։",
  "p-exchange-tos-application-definition":
    "1.12. Հայց՝ Օգտագործողը առաջարկում է գնել կամ վաճառել վիրտուալ թվային ակտիվներ՝ օգտագործելով Ծառայության կողմից մատուցված ծառայությունները։",
  "p-exchange-tos-electronic-document":
    "1.13. Էլեկտրոնային փաստաթուղթ՝ տեղեկատվություն էլեկտրոնային և թվային ձևով։",
  "p-exchange-tos-suspicious-traffic":
    "1.14. Կասկածելի տրաֆիկ՝ գործողությունների, գործարքների, ակտիվների հավաքածու, որոնք կարող են հնարավոր կասկածել AML/CTF և KYC քաղաքականությունները խախտելու մեջ։",
  "p-exchange-tos-verification-procedures":
    "1.15. Հաճախորդի (KYC) ստուգման գործընթացներ՝ փողերի լվացման կանխարգելման ստանդարտների շրջանակներում իրականացվող ստուգումների գործընթացներ։",
  "p-exchange-tos-persons-subject-restrictions":
    "1.16. Սահմանափակումների ենթակա անձինք՝ յուրաքանչյուր անձ ԱՄՆ-ում և/կամ ցանկացած անհատ, որը ներառված է ԱՄՆ Գանձապետարանի 'հատուկ կատեգորիաների քաղաքացիական և արգելված անձանց ցուցակում', ԵՄ Ֆինանսական պատժամիջոցների համախմբված ցուցակում կամ Մեծ Բրիտանիայի պատժամիջոցների ցուցակում, ինչպես նաև քաղաքացիները և բնակիչները սահմանափակված իրավասություններում։",
  "p-exchange-tos-limited-jurisdiction":
    "1.17. Սահմանափակված իրավասություններ՝ սահմանափակված իրավասությունների մեջ են մտնում՝ ԱՄՆ, Աֆղանստան, Բոսնիա և Հերցեգովինա, Հյուսիսային Կորեա, Կոնգոյի Դեմոկրատական Հանրապետություն, Էրիտրեա, Եթովպիա, Գայանա, Իրան, Իրաք, Լաոս, Լիբիա, Սոմալի, Շրի Լանկա, Սուդան, Սիրիա, Տրինիդադ և Տոբագո, Թունիս, Ուգանդա, Վանուատու, Ուկրաինա, Մեծ Բրիտանիա, Մայրական Չինաստան, Սինգապուր, Հոնկոնգ, Կանադա, Կուբա, Ուզբեկստան, Ղրիմ, Ֆրանսիա, Սևաստոպոլ, Դոնեցկ և Լուգանսկի շրջաններ։",
  "p-exchange-tos-introduction": "2. Ներածություն",
  "p-exchange-tos-introduction-text":
    "2.1. Այս համաձայնագիրը կարգավորում է Օգտագործողի և Ծառայության միջև հարաբերությունները՝ Ծառայության կողմից Օգտագործողին մատուցվող ծառայությունների շրջանակներում, և չեղարկում է Ծառայության և Օգտագործողի միջև նախորդ համաձայնագրերը։",
  "p-exchange-tos-subject-agreement": "3. Համաձայնագրի առարկա",
  "p-exchange-tos-subject-agreement-services":
    "3.1. Այս Համաձայնագրի առարկան այն ծառայություններն են, որոնք տրամադրվում են Ծառայության կողմից և նկարագրված են Ծառայության կայքում։",
  "p-exchange-tos-subject-agreement-restrictions":
    "3.2. Ծառայությունը իր ծառայությունները մատուցում է բոլոր շահագրգիռ Օգտագործողներին, բացառությամբ սահմանափակումների ենթակա անձանց։",
  "p-exchange-tos-payment-systems-responsibility":
    "3.3. Վճարային համակարգերը և/կամ ֆինանսական հաստատությունները բացառապես պատասխանատու են Օգտագործողի կողմից իրենց վստահված միջոցների համար։ Ծառայությունը չի հանդիսանում Օգտագործողի և վճարային համակարգի և/կամ ֆինանսական հաստատության միջև կնքված համաձայնագրի կողմ։",
  "p-exchange-tos-operation-cannot-cancel":
    "3.4. Օգտագործողի միջոցները փոխանակելու համար նախաձեռնված ցանկացած գործողություն չի կարող չեղարկվել Օգտագործողի կողմից։",
  "p-exchange-tos-rights-obligations":
    "4. Կողմերի իրավունքներ և պարտականություններ",
  "p-exchange-tos-suspend-operation-authorities":
    "4.1. Ծառայությունը իրավունք ունի կասեցնել կամ չեղարկել ընթացիկ գործարքը, եթե ստացված է տեղեկատվություն Օգտագործողի միջոցների օրինականության մասին։",
  "p-exchange-tos-suspend-refund-operation":
    "4.2. Ծառայությունը իրավունք ունի կասեցնել, մերժել կամ չեղարկել ընթացիկ գործարքը։",
  "p-exchange-tos-cancel-operation-title-return":
    "4.3. Ծառայությունը իրավունք ունի չեղարկել գործարքը և վերադարձնել Օգտագործողի կողմից մուտքագրված վարկանիշային նշանները՝ առանց պատճառաբանելու։",
  "p-exchange-tos-prevent-illegal-activities":
    "4.4. Ծառայությունը պարտավորվում է կանխել անօրինական առևտրի, ֆինանսական խաբեությունների և փողերի լվացման փորձերը՝ Ծառայության ծառայությունները օգտագործելու նպատակով։",
  "p-exchange-tos-suspicious-traffic-rights":
    "4.5. Եթե Օգտագործողի կողմից կասկածելի տրաֆիկ հայտնաբերվի, Ծառայությունը իրավունք ունի փոխանցել տվյալները իրավապահ մարմիններին։",
  "p-exchange-tos-accept-agreement":
    "4.6. Օգտագործելով Կայքի ծառայությունները՝ Օգտագործողը հաստատում է, որ համաձայն է այս Կանոններին և Ծառայության AML/CTF և KYC քաղաքականությանը։",
  "p-exchange-tos-user-guarantee":
    "4.7. Օգտագործելով Կայքի ծառայությունները՝ Օգտագործողը երաշխավորում է, որ տրամադրել է ամբողջական, ճշգրիտ և արդիական տեղեկատվություն իր մասին։",
  "p-exchange-tos-user-confidentiality":
    "4.8. Օգտագործողը երաշխավորում է իր հաշվից մուտքի և գաղտնի տվյալների գաղտնիությունը։",
  "p-exchange-tos-report-security-breach":
    "4.9. Օգտագործողը պարտավորվում է անհապաղ տեղեկացնել իր հաշվի անվտանգության խախտման մասին։",
  "p-exchange-tos-block-account":
    "4.10. Ծառայությունը իրավունք ունի արգելափակել Օգտագործողի հաշիվը՝ կասկածելի գործողությունների, հաշվի կոտրման կամ Ծառայության կանոնների խախտման դեպքում։",
  "p-exchange-tos-rightful-owner":
    "4.11. Ընդունելով այս Համաձայնագրի պայմանները՝ Օգտագործողը երաշխավորում է, որ նա օրինական սեփականատերն է միջոցների/կրիպտոարժույթների։",
  "p-exchange-tos-understand-service-risks":
    "4.12. Ընդունելով այս Օգտագործողի Համաձայնագիրը՝ Օգտագործողը հաստատում է, որ նա հասկանում է Ծառայության սկզբունքները և իր վրա է վերցնում բոլոր ռիսկերը, որոնք կապված են Ծառայության օգտագործման հետ։",
  "p-exchange-tos-consent-data-processing":
    "4.13. Օգտագործողը տալիս է իր համաձայնությունը Ծառայությանը իր անձնական տվյալների մշակման համար, որոնք տրամադրվել են կայքում գրանցման, ստուգման կամ այլ գործընթացների ժամանակ։",
  "p-exchange-tos-personal-data-includes":
    "4.14. Անձնական տվյալները ներառում են՝",
  "p-exchange-tos-name": "Ազգանուն, Անուն, Հայրանուն",
  "p-exchange-tos-photo-passport": "լուսանկար/անձնագրի սկան",
  "p-exchange-tos-other-id-documents":
    "այլ նույնացնող փաստաթղթեր (յուրաքանչյուր դեպքում առանձին)",
  "p-exchange-tos-video-selfie": "վիդեո սելֆի անձնագրով",
  "p-exchange-tos-email": "էլ. փոստի հասցե",
  "p-exchange-tos-phone-number": "բջջային հեռախոսի համար",
  "p-exchange-tos-wallet-details": "օգտագործվող դրամապանակների տվյալներ",
  "p-exchange-tos-card-number": "քարտի համար և/կամ բանկային հաշիվ",
  "p-exchange-tos-transactions-wallets": "գործարքներ և դրամապանակների հասցեներ",
  "p-exchange-tos-processing-personal-data":
    "4.15. Անձնական տվյալների մշակումն իրականացվում է՝ ըստ Համաձայնագրի կողմերի պարտականությունների կատարման, կայքում գրանցման, տեխնիկական աջակցության, հայտերի ուղարկման և ծառայությունների մատուցման։",
  "p-exchange-tos-processing-purpose":
    "4.16. Անձնական տվյալների մշակումը նպատակ ունի՝ կատարելու կողմերի պարտականությունները՝ ըստ Համաձայնագրի, գրանցել Օգտագործողին կայքում և ապահովել նրա համար տեխնիկական աջակցություն։",
  "p-exchange-tos-fincen-rule":
    "4.17. Ֆինանսական հանցագործությունների հետաքննության ցանցի (FinCEN) վերջնական կանոնների համաձայն՝ SolidSwap-ը չի տրամադրում էլեկտրոնային արժույթի փոխանակման ծառայություններ՝ ԱՄՆ քաղաքացիներին և ԱՄՆ բնակիչներին։",
  "p-exchange-tos-legal-prohibited":
    "4.18. SolidSwap-ի ծառայությունները կարող են իրավաբանորեն արգելված լինել որոշ իրավասություններում։ Ընդունելով այս ծառայության պայմանները՝ դուք հաստատում եք, որ չեք հանդիսանում նման իրավասության բնակիչ։",
  "p-exchange-tos-aml-kyc-checks":
    "4.19. Ծառայությունը իրավունք ունի իրականացնել AML և KYC ստուգումներ՝ փողերի լվացման դեմ պայքարի հինգերորդ օրենքի (5AMLD) համաձայն։",
  "p-exchange-tos-kyt-aml-violation":
    "4.19.1. Եթե KYT/AML ստուգումների արդյունքում հայտնաբերվեն խախտումներ, միջոցները կբլոկավորվեն և կհրավիրվի օգտատիրոջ լրացուցիչ ստուգում (KYC)։",
  "p-exchange-tos-kyt-aml-non-return":
    "4.19.2. Եթե KYT/AML ստուգումների արդյունքում բացահայտվեն խախտումներ, միջոցները չեն ենթարկվում փոխանակման և վերադարձի։",
  "p-exchange-tos-services-service": "5. Ծառայության ծառայություններ",
  "p-exchange-tos-order-services":
    "5.1. Ծառայության ծառայությունները պատվիրելը և գործարքների ընթացքի վերաբերյալ տեղեկատվություն ստանալը իրականացվում է՝ Ծառայության ներկայացուցչի միջոցով։",
  "p-exchange-tos-service-internal-schedule":
    "5.2. Ծառայությունը իր ծառայությունները մատուցում է՝ ըստ իր սահմանված ներքին ժամանակացույցի։",
  "p-exchange-tos-questions-complaints":
    "5.3. Հայցերի վերաբերյալ հարցերը և բողոքները ընդունվում են՝ 24 ժամվա ընթացքում։",
  "p-exchange-tos-suspicious-actions":
    "5.4. Կասկածելի գործողությունների դեպքում՝ Ծառայությունը կարող է պահանջել հավելյալ անձը հաստատող փաստաթղթեր։",
  "p-exchange-tos-right-to-refuse":
    "5.5. Ծառայությունը իրավունք ունի միակողմանիորեն մերժել փոխանակումը։",
  "p-exchange-tos-restricted-citizens":
    "5.6. SolidSwap-ի կողմից մատուցվող ծառայությունները արգելված են սահմանափակումների ենթակա անձանց համար։",
  "p-exchange-tos-cost-services": "6. Ծառայությունների արժեքը",
  "p-exchange-tos-tariffs":
    "6.1. Սակագները սահմանվում են՝ Ծառայության կողմից և հրապարակվում են կայքում։",
  "p-exchange-tos-expectations-tariffs":
    "6.2. Ծառայությունը պատասխանատվություն չի կրում հաճախորդի սպասելիքների համար՝ սակագների փոփոխությունների վերաբերյալ։",
  "p-exchange-tos-guarantees-responsibilities":
    "7. Կողմերի երաշխիքները և պատասխանատվությունը",
  "p-exchange-tos-guarantee-fulfillment":
    "7.1. Ծառայությունը երաշխավորում է իր պարտավորությունների կատարումը՝ ըստ սահմանված գումարների։",
  "p-exchange-tos-confidentiality-guarantee":
    "7.2. Ծառայությունը երաշխավորում է օգտատիրոջ և նրա գործարքների գաղտնիությունը։",
  "p-exchange-tos-efforts-technical-issues":
    "7.3. Ծառայությունը հնարավոր ամեն ինչ անում է՝ ծառայության անխափան աշխատանքի համար, բայց չի երաշխավորում տեխնիկական խնդիրների բացակայությունը։",
  "p-exchange-tos-damage-responsibility":
    "7.4. Ծառայությունը պատասխանատվություն չի կրում օգտատիրոջ վնասների համար։",
  "p-exchange-tos-no-responsibility-losses":
    "7.5. Ծառայությունը պատասխանատվություն չի կրում օգտատիրոջ վնասների կամ կորուստների համար՝ թյուր սպասումների պատճառով։",
  "p-exchange-tos-aml-risk-score":
    "7.6. Ծառայությունը պատասխանատվություն չի կրում հաճախորդի դրամապանակի AML Risk Score-ի փոփոխությունների համար։",
  "p-exchange-tos-compensation":
    "7.7. Օգտագործողը երաշխավորում է փոխհատուցել ծառայության կորուստները՝ կապված իրավական հարցերի հետ։",
  "p-exchange-tos-not-responsible-communication":
    "7.8. Օգտագործողը համաձայն է, որ Ծառայությունը պատասխանատվություն չի կրում կապի խափանումների դեպքում։",
  "p-exchange-tos-guarantee-owner-funds":
    "7.9. Օգտագործողը երաշխավորում է, որ իր սեփական միջոցներն են օգտագործվում գործարքների համար։",
  "p-exchange-tos-no-falsify-communication":
    "7.10. Օգտագործողը պարտավորվում է չխաբել Ծառայության հետ կապը։",
  "p-exchange-tos-force-majeure": "8. Ֆորս-մաժոր",
  "p-exchange-tos-force-majeure-definition":
    "8.1. Կողմերը պատասխանատվությունից ազատվում են՝ ֆորս-մաժորի դեպքում։",
  "p-exchange-tos-error-during-payment":
    "8.2. Փոխանակման վճարման սխալի դեպքում՝ օգտատիրոջը կուղարկվի ծանուցում։",
  "p-exchange-tos-payment-after-deadline":
    "8.3. Եթե վճարումը կատարվել է ժամկետից ուշ, ծառայությունը կարող է վերականգնել կամ վերադարձնել վճարումը։",
  "p-exchange-tos-not-responsible-assets-fiat":
    "8.3.1. Ծառայությունը պատասխանատվություն չի կրում ակտիվների համար, որոնք փոխանցվել են ժամկետանց։",
  "p-exchange-tos-not-responsible-assets-anonymous":
    "8.3.2. Ծառայությունը պատասխանատվություն չի կրում անանուն ձևով փոխանցված միջոցների համար։",
  "p-exchange-tos-deviation-exchange-rate":
    "8.4. Եթե փոխարժեքի շեղումը գերազանցում է 3%-ը, ապա կարող է կատարվել վերանայում կամ վերադարձ։",
  "p-exchange-tos-invalid-exchanges":
    "8.5. Եթե փոխանակման սխալ է տեղի ունեցել՝ օգտատիրոջ գործողությունների կամ համակարգային խափանման պատճառով, փոխանակումն անվավեր է ճանաչվում։",
  "p-exchange-tos-suspicious-assets":
    "8.6. Կասկածելի միջոցների դեպքում գործարքը կսառեցվի 180 օրով։",
  "p-exchange-tos-unblocking-procedure":
    "Սառեցման վերացման համար անհրաժեշտ է անցկացնել պարզեցված ստուգում։",
  "p-exchange-tos-send-photo-passport": "Ուղարկել լուսանկար/անձնագրի սկան։",
  "p-exchange-tos-send-phone-number": "Ուղարկել հեռախոսի համար՝ կապի համար։",
  "p-exchange-tos-send-video-selfie":
    "Ուղարկել վիդեո սելֆի՝ նույնականացման փաստաթղթով։",
  "p-exchange-tos-record-video": "Ձայնագրել տեսանյութ՝ նշելով՝",
  "p-exchange-tos-record-full-name":
    "Անուն, ազգանուն, փաստաթղթի սերիա և համար։",
  "p-exchange-tos-service-name": "Ծառայության անվանումը և տեսանյութի ամսաթիվը։",
  "p-exchange-tos-application-number": "Դիմումի համարը և ստեղծման ամսաթիվը։",
  "p-exchange-tos-show-wallet": "Ցույց տալ դրամապանակի/հաշվի տվյալները։",
  "p-exchange-tos-write-explanation":
    "Գրել բացատրություն՝ միջոցների օրինականության մասին։",
  "p-exchange-tos-specify-address":
    "Ցույց տալ դրամապանակի հասցեն՝ որտեղ պետք է վերադարձնվեն միջոցները։",
  "p-exchange-tos-information-deadline":
    "Մենք սպասում ենք ձեր տեղեկություններին՝ 48 ժամվա ընթացքում։",
  "p-exchange-tos-no-unblocking-without-data":
    "Առանց պահանջված տեղեկությունների՝ չենք կարող սկսել միջոցների ապաշրջափակման գործընթացը։",
  "p-exchange-tos-blocking-period-expiration":
    "8.6.1. 180 օր անց՝ Օգտագործողը պետք է դիմի աջակցման ծառայությանը։",
  "p-exchange-tos-incorrect-details":
    "8.7. Ծառայությունը պատասխանատվություն չի կրում սխալ նշված մանրամասների դեպքում։",
  "p-exchange-tos-payment-delay":
    "8.8. Վճարումները կարող են ուշանալ մինչև 5 բանկային օր՝ բանկի խափանումների պատճառով։",
  "p-exchange-tos-deposit-outside-application":
    "8.9. Անվավեր դեպոզիտները չեն վերադարձվում։",
  "p-exchange-tos-non-current-details":
    "8.10. Ծառայությունը պատասխանատվություն չի կրում սխալ մանրամասների դեպքում։",
  "p-exchange-tos-fiat-exchange": "9.1. Fiat փոխանակում",
  "p-exchange-tos-fiat-exchange-amount-limit":
    "9.1.1. Փոխանակումները մինչև 150 դոլար կատարվում են առանց հապաղման։",
  "p-exchange-tos-subsequent-exchanges":
    "9.1.2. Հետագա փոխանակումները նույն հաշվի հետ՝ կատարվում են ակնթարթորեն։",
  "p-exchange-tos-additional-checks":
    "9.1.3. Եթե հաշվի ընդհանուր գումարը գերազանցի 500 դոլարը, ապա կարող է պահանջվել հավելյալ ստուգում։",
  "p-exchange-tos-suspicious-actions-fiat":
    "9.1.4. Կասկածելի գործողությունների դեպքում՝ Ծառայությունը կարող է պահանջել հաշվի հաստատման տվյալներ։",
  "p-exchange-tos-crypto-exchange": "9.2. Կրիպտոարժույթի փոխանակում",
  "p-exchange-tos-crypto-auto-manual":
    "9.2.1. Ավտոմատ հայտերը կարող են կատարվել մինչև 12 ժամ, իսկ ձեռքով հայտերը՝ մինչև 36 ժամ։",
  "p-exchange-tos-sale-cryptocurrencies":
    "9.2.2. Բոլոր կրիպտոարժույթների վաճառքի հայտերը կատարվում են՝ ստանալով անհրաժեշտ հաստատումները։",
  "p-exchange-tos-recalculate-rate":
    "9.2.3. Եթե գործարքը չի ստացել անհրաժեշտ հաստատումները, ապա հայտը կարող է վերանայվել։",
  "p-exchange-tos-btc-confirmations":
    "9.2.4. BTC վճարումները կատարվում են՝ ստանալով 3 հաստատում։",
  "p-exchange-tos-fiat-crypto-fixed-rate":
    "9.2.5. Fiat/կրիպտոարժույթի փոխարժեքը ֆիքսված է՝ վճարման ժամանակահատվածում։",
  "p-exchange-tos-crypto-sending":
    "9.2.6. Կրիպտոարժույթի ուղարկումն իրականացվում է 5 րոպեից մինչև 12 ժամվա ընթացքում։",
  "p-exchange-tos-high-load-crypto-network":
    "9.2.7. Բարձր ծանրաբեռնվածության դեպքում՝ փոխանակման ժամանակը կարող է հասնել 240 ժամի։",
  "p-exchange-tos-recalculation-notification":
    "9.2.8. Փոխանակման գումարի վերանայումը կատարվում է ավտոմատ՝ ծանուցումով։",
  "p-exchange-tos-blocked-account-responsibility":
    "9.2.9. Ծառայությունը պատասխանատվություն չի կրում՝ արգելափակված հաշիվների համար։",
  "p-exchange-tos-memo-tag-required":
    "9.2.10. Եթե փոխանակման մեջ կիրառվում է MEMO կամ TAG, ապա դրանք պետք է նշված լինեն։",
  "p-exchange-tos-smart-contract-processing":
    "9.2.11. Խելացի պայմանագրով փոխանցված մետաղադրամները մշակվում են ձեռքով՝ 2 ժամում։",
  "p-exchange-tos-refund": "9.3. Վերադարձ",
  "p-exchange-tos-refund-decision":
    "9.3.1. Վերադարձը հնարավոր է միայն Ծառայության որոշմամբ։",
  "p-exchange-tos-refund-application":
    "9.3.2. Վերադարձի հայտը ուղարկվում է՝ էլեկտրոնային հասցեով։",
  "p-exchange-tos-refund-minus-commission":
    "9.3.3. Վերադարձը կատարվում է՝ 5% ծախսի հետ միասին։",
  "p-exchange-tos-refund-review":
    "9.3.4. Վերադարձի հայտերը դիտարկվում են՝ 48 ժամվա ընթացքում։",
  "p-exchange-tos-refund-within-48-hours":
    "9.3.5. Վերադարձը կատարվում է՝ 48 ժամվա ընթացքում։",
  "p-exchange-tos-deposit-below-minimum":
    "9.3.6. Նվազագույնից ցածր դեպոզիտները չեն վերադարձվում։",
  "p-exchange-tos-assets-return-clause-419":
    "9.3.7. Եթե ակտիվները նշված են 4.19 կետում, ապա AML ստուգումից հետո կարող է կատարվել վերադարձ։",
  "p-exchange-tos-return-aml-failed-assets":
    "9.4. Վերադարձ, եթե ակտիվները չեն անցել AML ստուգումը։",
  "p-exchange-tos-return-blocked-assets":
    "9.4.1. Վերադարձը հնարավոր է միայն KYC ստուգումից հետո։",
  "p-exchange-tos-request-from-authorities":
    "9.4.2. Վերադարձն անհնար է, եթե տվյալների պահանջ կա։",
  "p-exchange-tos-refund-5-percent-commission":
    "9.4.3. Վերադարձը կատարվում է՝ 5% ծախսի հետ միասին։",
  "p-exchange-tos-no-return-criminal-wallets":
    "9.4.4. Կրիպտոարժույթները չեն վերադարձվում՝ AML ստուգումից հետո։",
  "p-exchange-tos-dark-market": "Մութ շուկա",
  "p-exchange-tos-dark-service": "Մութ ծառայություն",
  "p-exchange-tos-scam": "Խաբեություն",
  "p-exchange-tos-stolen": "Գողացված",
  "p-exchange-tos-mixing": "Խառնիչ",
  "p-exchange-tos-sextortion": "Սեքստորշիա",
  "p-exchange-tos-ransomware": "Վնասաբեր ծրագիր",
  "p-exchange-tos-hack": "Կոտրում",
  "p-exchange-tos-phishing": "Ֆիշինգ",
  "p-exchange-tos-terrorism-financing": "Ահաբեկչության ֆինանսավորում",
  "p-exchange-tos-fraud": "Խարդախություն",
  "p-exchange-tos-blacklist": "Սև ցուցակ",
  "p-exchange-tos-under-investigation": "Ներկայումս հետաքննության փուլում",
  "p-exchange-tos-cybercrime-organization":
    "Կիբերհանցագործության կազմակերպություն",
  "p-exchange-tos-narcotics": "Նարկոտիկներ",
  "p-exchange-tos-child-abuse": "Երեխաների չարաշահում",
  "p-exchange-tos-human-trafficking": "Մարդկանց թրաֆիքինգ",
  "p-exchange-tos-sanctions": "Պատժամիջոցներ",
  "p-exchange-tos-change-recipient-details":
    "9.5. Փոխանակման ստացողի մանրամասների փոփոխում",
  "p-exchange-tos-change-recipient-details-procedure":
    "9.5.1. Ստացողի մանրամասների փոփոխման հայտը ուղարկվում է՝ էլեկտրոնային հասցեով։",
  "p-exchange-tos-acceptance-terms": "10. Ծառայության պայմանների ընդունում",
  "p-exchange-tos-acceptance-terms-text":
    "10.1. Ծառայության Օգտագործողի Համաձայնագրի պայմանները ընդունելիս, հայտի ստեղծման ժամանակ՝ համաձայնվում է Ծառայության հետ բոլոր պայմանագրային փաստաթղթերի հետ։",
};
