export default {
  "c-chat-indexclient-historyLoadError": "Fehler beim Laden des Chatverlaufs",
  "c-chat-indexclient-pictureFormatError":
    "Das Bild muss im JPG/PNG-Format vorliegen!",
  "c-chat-indexclient-pictureSizeError":
    "Die Bildgröße darf 10 MB nicht überschreiten!",
  "c-chat-indexclient-uploadError": "Fehler beim Hochladen des Bildes!",
  "c-chat-indexclient-connectionError": "Fehler beim Verbinden mit dem Chat",
  "c-chat-indexclient-summaryLoadError":
    "Fehler beim Laden der Chat-Zusammenfassung",
  "c-chat-indexclient-chatWith": "Chat mit",
  "c-chat-indexclient-counterparty": "Gegenpartei",
  "c-chat-indexclient-online": "online",
  "c-chat-indexclient-wasOnline": "war online",
  "c-chat-indexclient-andMore": "und {count} mehr",
  "c-chat-indexclient-allParticipants": "Alle Chat-Teilnehmer",
  "c-chat-indexclient-moderator": "Moderator",
  "c-chat-indexclient-welcomeMessage":
    "Willkommen im Chat für Auftrag #{orderId}",
  "c-chat-indexclient-paymentSent": "Der Käufer hat die Zahlung gesendet",
  "c-chat-indexclient-paymentConfirmed":
    "Der Verkäufer hat die Zahlung bestätigt",
  "c-chat-indexclient-disputeCreated": "Streitfall erstellt",
  "c-chat-indexclient-orderCancelled": "Auftrag storniert",
  "c-chat-indexclient-moderatorJoined": "Moderator hat den Chat betreten",
  "c-chat-indexclient-sessionStarted": "Support-Sitzung gestartet",
  "c-chat-indexclient-sessionClosed": "Support-Sitzung geschlossen",
  "c-chat-indexclient-placeholder": "Bitte eingeben",
};
