export default {
  "p-privacy-policy-title": "Politique de Confidentialité | Solid Swap",
  "p-privacy-policy-meta-description":
    "Lisez la politique de confidentialité de Solid Swap pour comprendre comment nous gérons vos informations personnelles et protégeons votre vie privée",
  "p-privacy-policy-meta-keywords":
    "politique de confidentialité, protection des données, informations personnelles, confidentialité Solid Swap",
  "p-privacy-policy-header-title": "Politique de Confidentialité",
  "p-privacy-policy-download-link": "télécharger en tant que fichier",
  "p-privacy-policy-alert":
    "Cet Accord Client peut être publié dans différentes langues. En cas de divergence, veuillez vous référer à la version anglaise",
  "p-privacy-policy-last-updated": "Dernière mise à jour :",
  "p-privacy-policy-intro-1":
    "Cette Politique de Confidentialité, telle qu'amendée ou modifiée de temps à autre (la “Politique de Confidentialité”), explique la manière dont SOLIDSWAP, Ltd. (“SOLIDSWAP”, “nous”, “notre” ou “nos”) collecte, utilise, maintient et divulgue les informations des utilisateurs obtenues via le site",
  "p-privacy-policy-site": "solidswap.net",
  "p-privacy-policy-intro-2":
    "(le “Site”) et par la fourniture des services (les “Services”). SOLIDSWAP, Ltd. est le contrôleur des données de vos Données Personnelles.",
  "p-privacy-policy-commitment":
    "SOLIDSWAP et ses Affiliés s'engagent à faire tous les efforts possibles pour protéger votre vie privée. SOLIDSWAP utilise les informations collectées à votre sujet pour remplir ses obligations contractuelles et améliorer le service client.",
  "p-privacy-policy-read":
    "Veuillez lire ce document attentivement pour comprendre notre position et nos politiques concernant vos Informations Personnelles et comment nous les utiliserons.",
  "p-privacy-policy-read-cookie":
    "Cette Politique de Confidentialité doit être lue conjointement avec notre Politique de Cookies.",
  "p-privacy-policy-data-we-collect":
    "1. QUELLES DONNÉES NOUS COLLECTONS SUR VOUS",
  "p-privacy-policy-data-we-collect-intro":
    "Nous pouvons collecter et traiter les données suivantes vous concernant :",
  "p-privacy-policy-info-provided": "Informations que vous nous fournissez",
  "p-privacy-policy-info-provided-description":
    "Vous pouvez nous fournir des informations vous concernant en remplissant des formulaires sur le Site ou en nous fournissant ces informations par téléphone, email, vidéoconférence ou tout autre moyen. Ces informations incluent les données personnelles spécifiées lors de l'enregistrement sur le Site, lors de l'abonnement à nos Services, de l'ouverture d'un Compte et de la vérification de l'identité du titulaire du Compte ou des personnes autorisées à en disposer, ainsi que les instruments de paiement utilisés par eux, la recherche d'un produit, l'utilisation de l'un des Services fournis par notre Site lorsque vous passez des Commandes sur notre Site, lorsque vous participez à des discussions par Chat ou utilisez toute autre fonctionnalité de communication sur notre Site, lorsque vous participez à des compétitions, promotions ou enquêtes, et lorsque vous nous signalez des problèmes dans le fonctionnement du Site.",
  "p-privacy-policy-example":
    "Par exemple, les données que vous nous fournissez peuvent inclure votre :",
  "p-privacy-policy-first-last-name": "prénom et nom de famille;",
  "p-privacy-policy-photos-videos":
    "photographies et vidéos que vous pouvez nous envoyer à des fins de vérification conformément à notre Politique AML/CTF et KYC;",
  "p-privacy-policy-residence-citizenship":
    "adresse de résidence et citoyenneté;",
  "p-privacy-policy-sex": "sexe;",
  "p-privacy-policy-id-documents":
    "passeport, permis de conduire et tout autre document d'identification émis par le gouvernement;",
  "p-privacy-policy-tax-residency": "résidence fiscale;",
  "p-privacy-policy-phone-email": "numéro de téléphone et adresse email;",
  "p-privacy-policy-login-nickname":
    "identifiant et pseudonyme pour accéder à la Plateforme SOLIDSWAP;",
  "p-privacy-policy-bank-info":
    "informations bancaires, y compris les détails du compte et de paiement, ainsi que les informations FATCA/CRS;",
  "p-privacy-policy-dob": "date et lieu de naissance;",
  "p-privacy-policy-education": "éducation;",
  "p-privacy-policy-work-experience":
    "expérience professionnelle et lieu de travail;",
  "p-privacy-policy-official-position": "poste officiel;",
  "p-privacy-policy-income-sources":
    "sources et/ou montants des revenus/rechargement du compte;",
  "p-privacy-policy-account-status":
    "informations sur l'état de vos comptes ouverts auprès des banques et/ou des systèmes de paiement;",
  "p-privacy-policy-support-requests":
    "demandes et historique de correspondance avec le support SOLIDSWAP et d'autres Utilisateurs de la Plateforme SOLIDSWAP;",
  "p-privacy-policy-trade-balances": "soldes et activités de trading;",
  "p-privacy-policy-location-log":
    "informations de localisation et journaux de données générés lorsque vous utilisez la Plateforme SOLIDSWAP;",
  "p-privacy-policy-additional-info":
    "autres informations personnelles telles que requis par notre Politique AML/CTF et KYC pour se conformer aux obligations réglementaires.",
  "p-privacy-policy-no-sensitive-data":
    "SOLIDSWAP ne collecte ni ne traite les données personnelles sensibles des Utilisateurs, telles que la race ou l'origine ethnique, les opinions politiques, les croyances religieuses ou philosophiques, l'appartenance syndicale, les données génétiques ou biométriques, les informations sur la santé, la vie sexuelle ou l'orientation sexuelle.",
  "p-privacy-policy-info-collected":
    "Informations que nous collectons sur vous",
  "p-privacy-policy-info-collected-description":
    "Chaque fois que vous visitez notre Site et utilisez nos Services, nous pouvons collecter (y compris automatiquement) les informations suivantes vous concernant :",
  "p-privacy-policy-info-collected-a":
    "(a) informations techniques, y compris l'adresse de protocole Internet (IP) utilisée pour connecter votre ordinateur à Internet, les informations de connexion de votre Compte Personnel, le type et la version de votre navigateur, les paramètres de fuseau horaire, les types et versions de plug-ins de navigateur, le système d'exploitation et la plateforme;",
  "p-privacy-policy-info-collected-b":
    "(b) données sur votre visite sur le Site : y compris toutes les informations sur le localisateur de ressources uniforme (URL), l'itinéraire de navigation sur le Site et lorsque vous naviguez vers/quittez le Site (y compris la date et l'heure), sur les produits et Services que vous avez consultés ou recherchés, le temps de réponse des pages, les erreurs de chargement des données, la durée de séjour sur certaines pages, l'activité sur la page (informations telles que le défilement, les mouvements de la souris, les clics), les méthodes utilisées pour quitter la page et les numéros de téléphone utilisés pour contacter le centre de support client;",
  "p-privacy-policy-info-collected-c":
    "(c) données de géolocalisation : nous pouvons collecter des informations de localisation en temps réel de votre appareil à tout moment lorsque vous téléchargez ou utilisez nos services; nous pouvons utiliser ces informations pour optimiser nos services;",
  "p-privacy-policy-info-collected-d":
    "(d) informations financières et informations sur l'utilisation des Services et les opportunités fournies par notre Site, y compris des informations sur les transactions en cours et leurs participants, y compris les transactions sur marge, des informations sur l'état des Comptes et les mouvements de fonds sur ceux-ci, les commissions accumulées, des informations sur les Dépôts et Retraits de Compte ouverts sur notre Plateforme.",
  "p-privacy-policy-info-from-others":
    "Informations que nous recevons d'autres sources",
  "p-privacy-policy-info-from-others-description":
    "Nous pouvons recevoir des informations vous concernant si vous utilisez l'un de nos autres sites ou services. Nous travaillons également en étroite collaboration avec des tiers (y compris nos partenaires commerciaux, sous-traitants techniques, de paiement et de support, réseaux publicitaires, fournisseurs d'analyses, fournisseurs de diligence raisonnable, agences de crédit) et pouvons recevoir des informations vous concernant de leur part. Ces informations peuvent inclure :",
  "p-privacy-policy-info-from-others-a":
    "(a) informations sur les institutions financières que vous utilisez pour Déposer et/ou Retirer des Fonds;",
  "p-privacy-policy-info-from-others-b":
    "confirmation par des agences de crédit des informations que vous nous avez fournies;",
  "p-privacy-policy-store-pending":
    "Vos Données Personnelles, telles que l'adresse email et l'identifiant, que vous nous fournissez lors de l'enregistrement sur la Plateforme SOLIDSWAP, sont stockées par nous que vous complétiez ou non le processus d'enregistrement. Il en va de même pour vos Données Personnelles en attente de vérification conformément à la Politique AML/CTF et KYC.",
  "p-privacy-policy-cookies": "2. UTILISATION DES COOKIES",
  "p-privacy-policy-cookies-description":
    "Veuillez lire notre Politique de Cookies pour plus d'informations sur l'utilisation des cookies.",
  "p-privacy-policy-use-of-data":
    "3. À QUOI SERVIRONT VOS DONNÉES PERSONNELLES?",
  "p-privacy-policy-use-of-data-intro":
    "Nous utiliserons vos Données Personnelles aux fins suivantes :",
  "p-privacy-policy-providing-services": "Fournir nos Services",
  "p-privacy-policy-fulfill-obligations":
    "pour remplir les obligations en vertu de tout contrat conclu avec vous, ainsi que pour vous fournir les informations, produits et Services que vous demandez;",
  "p-privacy-policy-security":
    "pour assurer la sécurité de notre Site, de nos Services et de votre Compte;",
  "p-privacy-policy-admin-site":
    "pour administrer notre Site et mener des opérations internes, y compris le dépannage, l'analyse de données, les tests, la collecte de données statistiques et la réalisation d'enquêtes;",
  "p-privacy-policy-interactive-features":
    "pour vous donner accès aux fonctionnalités interactives du Site si vous souhaitez les utiliser;",
  "p-privacy-policy-support":
    "pour fournir un support, répondre et résoudre vos plaintes et questions liées à l'utilisation de nos Services et aux fonctionnalités de notre Site.",
  "p-privacy-policy-informing": "Vous informer sur nos produits et services",
  "p-privacy-policy-inform-products":
    "pour vous informer sur nos autres produits et services existants et/ou nouveaux, similaires à ceux que vous avez déjà achetés ou recherchés;",
  "p-privacy-policy-inform-changes":
    "pour vous informer des modifications apportées à nos produits et services.",
  "p-privacy-policy-improve": "Aider à améliorer nos produits et services",
  "p-privacy-policy-improve-site":
    "pour améliorer notre Site et garantir que notre contenu est aussi convivial que possible sur votre système d'exploitation informatique;",
  "p-privacy-policy-evaluate-ads":
    "pour évaluer et comprendre l'efficacité des supports publicitaires que nous vous fournissons ainsi qu'aux autres Utilisateurs, et pour vous fournir les informations publicitaires nécessaires;",
  "p-privacy-policy-effective-content":
    "pour garantir que le contenu de notre Site vous est présenté de la manière la plus efficace et peut être utilisé à partir de votre ordinateur.",
  "p-privacy-policy-protect-interests": "Protection des intérêts corporatifs",
  "p-privacy-policy-risk-management": "pour la gestion des risques;",
  "p-privacy-policy-it-security":
    "pour protéger et contrôler nos systèmes informatiques, pour prévenir le crime;",
  "p-privacy-policy-evaluate-services":
    "pour évaluer l'utilisation de nos produits et services pour leur développement futur;",
  "p-privacy-policy-internal-business":
    "à des fins internes de l'entreprise et de recherche, comme communiquer avec vous concernant nos produits et services;",
  "p-privacy-policy-analyze-data":
    "pour analyser anonymement les données en utilisant des balises web et des balises pixel pour garantir que nos produits et services sont appropriés pour le marché dans lequel nous opérons.",
  "p-privacy-policy-legal-procedures": "Fournir des procédures légales",
  "p-privacy-policy-law-enforcement":
    "pour répondre aux demandes des organismes d'application de la loi;",
  "p-privacy-policy-legal-claims":
    "pour initier et/ou répondre à des réclamations légales;",
  "p-privacy-policy-disputes":
    "pour enquêter et/ou résoudre des différends avec vous.",
  "p-privacy-policy-legal-compliance": "Conformité légale",
  "p-privacy-policy-verify-users":
    "pour vérifier et authentifier l'identité des Utilisateurs ouvrant et gérant des Comptes afin de prévenir la fraude et les activités illégales connexes;",
  "p-privacy-policy-aml-compliance":
    "pour se conformer aux exigences légales de notre Politique AML/CTF et KYC et autres lois et règlements pertinents;",
  "p-privacy-policy-notify-changes":
    "pour informer nos Utilisateurs des modifications apportées aux politiques et aux conditions d'utilisation de la Plateforme SOLIDSWAP.",
  "p-privacy-policy-marketing-third-parties": "Marketing et tiers",
  "p-privacy-policy-new-products":
    "pour vous fournir des informations sur les nouveaux produits ou services introduits par SOLIDSWAP ou nos fournisseurs tiers qui, selon nous, pourraient vous intéresser.",
  "p-privacy-policy-disclosure": "4. DIVULGATION DE VOS DONNÉES PERSONNELLES",
  "p-privacy-policy-disclosure-intro":
    "Nous pouvons partager vos Informations Personnelles au sein du Groupe SOLIDSWAP, qui comprend nos filiales et affiliés, nos sociétés mères ultimes et leurs filiales.",
  "p-privacy-policy-disclosure-third-parties-intro":
    "Nous pouvons transférer vos Données Personnelles à des tiers, y compris :",
  "p-privacy-policy-disclosure-third-parties-a":
    "(i) partenaires commerciaux, fournisseurs, sous-traitants, prestataires de services qui effectuent les contrôles légaux et financiers appropriés pour remplir les obligations contractuelles que nous avons conclues avec eux ou avec vous;",
  "p-privacy-policy-disclosure-third-parties-b":
    "(ii) réseaux de marketing qui nécessitent certaines données pour sélectionner et promouvoir la publicité pertinente.",
  "p-privacy-policy-disclosure-no-sensitive":
    "Nous ne divulguons pas d'informations sensibles sur nos clients aux entreprises de marketing, mais nous pouvons leur fournir des informations agrégées sur nos Utilisateurs (par exemple, nous pouvons leur dire que 500 femmes de moins de 30 ans ont cliqué sur leur publicité tel jour).",
  "p-privacy-policy-disclosure-aggregate":
    "Nous pouvons également utiliser ces informations agrégées pour aider les entreprises de marketing à atteindre le public qu'elles souhaitent cibler (par exemple, les femmes vivant dans une région particulière). Nous pouvons utiliser les Données Personnelles que nous collectons auprès de vous pour nous permettre de répondre aux souhaits de nos annonceurs en affichant leurs publicités à des publics cibles spécifiques, fournisseurs d'analyses et moteurs de recherche qui nous aident à améliorer et à optimiser la performance de notre Site.",
  "p-privacy-policy-disclosure-other-cases-intro":
    "Nous pouvons également divulguer vos Données Personnelles à des tiers dans les cas suivants :",
  "p-privacy-policy-disclosure-other-cases-a":
    "(a) si nous vendons ou achetons une entreprise ou des actifs, les informations personnelles des Utilisateurs peuvent être divulguées à un acheteur ou vendeur potentiel;",
  "p-privacy-policy-disclosure-other-cases-b":
    "(b) si SOLIDSWAP ou tous ses actifs sont achetés par un tiers, les Données Personnelles des Utilisateurs seront transférées comme l'un des actifs;",
  "p-privacy-policy-disclosure-other-cases-c":
    "(c) si nous sommes tenus de divulguer ou de transférer les Données Personnelles des Utilisateurs conformément à toute loi applicable ou pour nous conformer aux ordonnances judiciaires pertinentes, demandes des agences judiciaires et des forces de l'ordre, ou pour forcer les Utilisateurs à se conformer aux termes des accords conclus avec nous;",
  "p-privacy-policy-disclosure-other-cases-d":
    "(d) dans le cas où il est nécessaire de protéger nos droits et notre propriété ou de garantir la sécurité de SOLIDSWAP, de nos Utilisateurs ou d'autres parties (cela inclut le partage d'informations avec des organisations pertinentes pour prévenir la fraude et réduire la probabilité de défaut de paiement).",
  "p-privacy-policy-security-storage":
    "5. SÉCURITÉ ET STOCKAGE DES DONNÉES PERSONNELLES",
  "p-privacy-policy-security-measures":
    "Nous utilisons des mesures de sécurité pour garantir la confidentialité de vos Données Personnelles et les protéger contre la perte, l'utilisation abusive, l'altération ou la destruction. Seul le personnel autorisé de SOLIDSWAP a accès à vos Informations Personnelles, et ces employés sont tenus de traiter ces informations comme confidentielles. Les mesures de sécurité actuelles seront examinées de temps à autre conformément aux nouvelles dispositions légales et innovations techniques.",
  "p-privacy-policy-storage":
    "Les informations que nous collectons auprès de vous peuvent être transférées et stockées en dehors de l'Espace économique européen (EEE) dans le but de vous fournir nos Services. Les informations vous concernant peuvent être traitées par nos employés ou des employés de prestataires de services situés en dehors de l'EEE. Lorsque vos Données Personnelles sont stockées ou autrement traitées par ces tiers en dehors de l'EEE, nous veillerons à informer ces tiers de nos obligations en vertu de cette Politique de Confidentialité et à établir une relation avec eux qui les soumettra à des obligations en ce qui concerne vos Données Personnelles, à condition qu'ils disposent du niveau de sécurité approprié spécifié dans cette Politique de Confidentialité. Ces employés peuvent, entre autres, traiter vos demandes, informations de paiement et fournir un support technique. En nous fournissant vos Données Personnelles, vous consentez au transfert, stockage et traitement de vos Données Personnelles. À notre tour, nous prendrons toutes les mesures nécessaires pour garantir que vos Données Personnelles sont traitées conformément aux normes internationales de sécurité et aux dispositions de cette Politique de Confidentialité.",
  "p-privacy-policy-transmission-security":
    "Bien que la transmission d'informations via Internet ne soit pas totalement sécurisée, nous essaierons de protéger vos Données Personnelles. En tenant compte de cela, nous ne pouvons cependant garantir la sécurité des canaux que vous utilisez pour transmettre vos informations personnelles à notre Site. À cet égard, vous êtes seul responsable des éventuelles défaillances dans la transmission de vos Données Personnelles au Site. Une fois que nous avons reçu vos Données Personnelles, nous utiliserons des procédures strictes et toutes les mesures techniques et de sécurité nécessaires pour empêcher l'accès non autorisé à vos Données Personnelles.",
  "p-privacy-policy-grounds":
    "6. FONDEMENTS POUR LE TRAITEMENT DES DONNÉES PERSONNELLES",
  "p-privacy-policy-grounds-intro":
    "Nous collectons et traitons vos Données Personnelles uniquement lorsque nous avons des bases légales pour ce traitement. Les bases légales incluent :",
  "p-privacy-policy-consent": "votre consentement;",
  "p-privacy-policy-agreement-terms":
    "les termes de l'accord entre vous et SOLIDSWAP;",
  "p-privacy-policy-legal-obligations":
    "le respect des obligations légales lorsque SOLIDSWAP est tenu de demander/recevoir et traiter et stocker vos données personnelles afin de se conformer aux lois applicables, telles que les lois relatives à la lutte contre le blanchiment d'argent et le financement du terrorisme;",
  "p-privacy-policy-legal-interests":
    "intérêts légaux, par exemple lorsque le traitement est nécessaire pour vous protéger ou nous protéger contre certaines menaces telles que la fraude, les menaces à la sécurité, etc.;",
  "p-privacy-policy-business-compliance":
    "conformité aux lois applicables pour mener nos activités (qualité des services fournis, identification des besoins, préparation de rapports) conformément aux exigences réglementaires;",
  "p-privacy-policy-corporate-transactions":
    "réalisation de transactions d'entreprise (vente d'actions/parts des participants, fusions, acquisitions), etc.",
  "p-privacy-policy-withdraw-consent":
    "Dans les cas où la base du traitement des Données Personnelles est votre consentement, vous avez le droit de le retirer à tout moment.",
  "p-privacy-policy-rights": "7. VOS DROITS",
  "p-privacy-policy-rights-intro":
    "En nous contactant, vous pouvez exercer les droits suivants :",
  "p-privacy-policy-rights-erase":
    "le droit de supprimer tout ou partie de vos Données Personnelles (en tenant compte, cependant, de nos exigences réglementaires pour conserver vos Données Personnelles telles que définies à l'article 9 de cette Politique de Confidentialité);",
  "p-privacy-policy-rights-correct":
    "le droit de modifier ou corriger vos Données Personnelles, en particulier lorsqu'elles sont saisies de manière incorrecte;",
  "p-privacy-policy-rights-object":
    "le droit de vous opposer ou de restreindre le traitement de vos Données Personnelles;",
  "p-privacy-policy-rights-access":
    "le droit d'accéder à vos Données Personnelles et/ou une copie de vos Données Personnelles que vous avez fournies à SOLIDSWAP dans un format lisible par machine.",
  "p-privacy-policy-rights-marketing":
    "De plus, vous avez le droit d'interdire l'utilisation de vos Données à des fins de marketing. Avant de demander vos Données, nous chercherons généralement à obtenir votre consentement pour les divulguer à des tiers à des fins de marketing. Vous pouvez exercer le droit de vous opposer au traitement de vos Données à des fins de marketing en cochant la case appropriée dans le formulaire. Vous pouvez également exercer ce droit en nous contactant à tout moment.",
  "p-privacy-policy-rights-limitations":
    "Veuillez noter que l'exercice de certains de vos droits, selon la situation, peut limiter, compliquer ou éliminer complètement la possibilité de continuer à coopérer avec nous.",
  "p-privacy-policy-rights-active-links":
    "Notre Site peut contenir des liens actifs vers/depuis les sites Web de partenaires, annonceurs et Affiliés. Si vous suivez des liens actifs, veuillez noter que d'autres sites Web ont leurs propres politiques de confidentialité et nous ne sommes pas responsables des dispositions de ces politiques. Veuillez vérifier les politiques de confidentialité de ces sites Web avant de soumettre vos Informations Personnelles à ces derniers.",
  "p-privacy-policy-access": "8. ACCÈS AUX DONNÉES PERSONNELLES",
  "p-privacy-policy-access-intro":
    "Vous avez le droit d'accéder aux informations que nous détenons à votre sujet. Vous pouvez exercer le droit d'accès conformément à votre demande écrite envoyée à l'adresse email ci-dessous, nous vous fournirons les informations que nous stockons à votre sujet. Dans le cas où votre demande est manifestement infondée ou excessive, ou si vous demandez des copies supplémentaires de vos Données Personnelles telles qu'elles ont été demandées initialement, nous pouvons vous facturer des frais supplémentaires.",
  "p-privacy-policy-retention": "9. CONSERVATION DES DONNÉES PERSONNELLES",
  "p-privacy-policy-retention-intro":
    "Assurer la sécurité et la protection de vos Données Personnelles est notre plus haute priorité. Nous utilisons un stockage sécurisé pour garantir la sécurité de vos Données Personnelles que vous partagez avec nous à toutes les étapes de l'interaction avec notre Plateforme, en utilisant nos produits et Services. Notre service de sécurité prend toutes les mesures appropriées pour protéger vos Données Personnelles contre les attaques de pirates informatiques, la perte ou l'utilisation abusive.",
  "p-privacy-policy-retention-period":
    "Nous conserverons vos Données Personnelles uniquement aussi longtemps que vous utilisez nos produits et Services via votre Compte, sous réserve des finalités décrites dans cette Politique de Confidentialité et des obligations légales et réglementaires auxquelles nous nous conformons. Conformément à nos obligations en matière de tenue de registres, nous conserverons les informations sur votre Compte et vos Données Personnelles pendant une période d'au moins cinq (5) ans à compter de la date de fermeture de votre Compte ou de la fin de votre relation client avec nous, sauf si requis autrement pour des raisons légales ou réglementaires.",
  "p-privacy-policy-retention-extended":
    "Nous pouvons accéder ou conserver vos Informations Personnelles pour une période plus longue si elles font l'objet d'une demande formelle ou d'une obligation légale, d'une enquête gouvernementale ou d'une enquête pour des violations possibles de notre Accord d'Utilisateur et de ses parties intégrantes, ou pour éviter tout préjudice potentiel sur la base de nos considérations raisonnables.",
  "p-privacy-policy-unsubscribe":
    "Si vous vous désabonnez de notre newsletter par email, nous conserverons votre email sur une liste de diffusion inactive.",
  "p-privacy-policy-changes":
    "10. MODIFICATIONS DE LA POLITIQUE DE CONFIDENTIALITÉ",
  "p-privacy-policy-changes-intro":
    "Toute modification apportée à la Politique de Confidentialité sera publiée sur cette page et, si nécessaire, envoyée à votre adresse email. Nous vous encourageons à consulter régulièrement cette page pour rester informé de toute mise à jour ou modification de la Politique de Confidentialité.",
  "p-privacy-policy-contact": "11. NOUS CONTACTER",
  "p-privacy-policy-contact-description":
    "Si vous avez des questions, des plaintes ou des doutes concernant notre Politique de Confidentialité, notre Politique de Cookies ou les méthodes et finalités de traitement de vos Données Personnelles, vous pouvez nous contacter à",
};
