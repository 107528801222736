export default {
  "c-transaction-cancel-confirm":
    "Վստահ եք, որ ցանկանում եք չեղարկել այս գործարքը?",
  "c-transaction-cancel-withdrawal": "Չեղարկել ելք",
  "c-transaction-back": "Հետ",
  "c-transaction-cancel-error": "Ինչ-որ սխալ տեղի ունեցավ գործարքը չեղարկելիս!",
  "c-transaction-status": "Կարգավիճակ",
  "c-transaction-amount": "Քանակ",
  "c-transaction-fee": "Միջնորդավճար",
  "c-transaction-type": "Տեսակ",
  "c-transaction-comment": "Մեկնաբանություն",
  "c-transaction-address": "Հասցե",
};
