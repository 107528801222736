export default {
  "p-about-head-title":
    "À propos de Solid Swap | Rencontrez notre équipe d'échange de cryptomonnaies",
  "p-about-head-description":
    "En savoir plus sur Solid Swap, l'équipe derrière notre plateforme de trading de cryptomonnaies sécurisée, et notre mission de fournir des échanges d'actifs numériques efficaces",
  "p-about-head-keywords":
    "échange de cryptomonnaies, trading sécurisé, actifs numériques, trading de crypto, Solid Swap, solidswap",
  "p-about-contact-title": "Contactez-nous",
  "p-about-address": "Adresse:",
  "p-about-index": "Code Postal:",
  "p-about-tax-id": "Numéro de TVA:",
  "p-about-email": "Email:",
  "p-about-support-email": "Email du support:",
  "p-about-duns": "Système universel de numérotation des données (D-U-N-S):",
  "p-about-duns-check": "Vérifier D-U-N-S",
  "p-about-traders-count": ">200 traders sont déjà avec nous",
  "p-about-foundation-title": "La base de votre indépendance financière",
  "p-about-description":
    "Solid Swap est un nouveau service pour l'échange rapide de cryptomonnaie et de monnaie fiduciaire sur une plateforme P2P",
  "p-about-start-trading": "Commencez à trader",
  "p-about-fee-tag": "0% de frais",
  "p-about-beneficial-terms": "Conditions avantageuses",
  "p-about-low-fees":
    "Frais bas, promotions, tirages et pas de petits caractères",
  "p-about-fast-transactions": "Transactions rapides",
  "p-about-secure-optimizations":
    "Nous améliorons constamment le code sans compromettre la sécurité",
  "p-about-online": "En ligne",
  "p-about-24-support": "Support 24/7",
  "p-about-support-anytime": "Aide pour tout problème, à tout moment",
  "p-about-contact-support": "Contacter le support",
  "p-about-platform-mission":
    "Construire une plateforme fiable, guidée par des rêves et des principes",
  "p-about-join-us":
    "Il y a beaucoup de bourses, mais nous avons quelque chose de spécial à offrir — rejoignez-nous et suivez le progrès du développement",
  "p-about-freedom": "Liberté et anonymat",
  "p-about-data-privacy":
    "Nous ne voulons pas devenir une banque; nous valorisons la confidentialité des données",
  "p-about-registered-armenia": "Enregistré en Arménie",
  "p-about-legal-compliance":
    "Travailler dans la légalité tout en préservant l'indépendance",
  "p-about-access-devices": "Accessible depuis n'importe quel appareil",
  "p-about-cross-device-experience":
    "Une expérience optimisée sur tous les appareils, pour chaque situation",
  "p-about-services-question": "Quels services propose Solid Swap?",
  "p-about-buy-bitcoin-question":
    "Comment acheter des Bitcoins sur Solid Swap?",
  "p-about-crypto-question": "Qu'est-ce que la cryptomonnaie?",
  "p-about-russians-trade-question":
    "Les Russes peuvent-ils trader de la cryptomonnaie?",
  "p-about-pay-with-crypto-question": "Peut-on payer en cryptomonnaie?",
  "p-about-login-trade": "Connectez-vous pour commencer à trader",
  "p-about-login": "Connexion",
  "p-about-register": "S'inscrire",
};
