export default {
  "c-feedback-create-fastTransaction": "Արագ գործարք",
  "c-feedback-create-politeFriendly": "Զգուշավոր և բարեկամական",
  "c-feedback-create-patient": "Համբերատար",
  "c-feedback-create-safeTrustworthy": "Անվտանգ և հուսալի",
  "c-feedback-create-goodPrice": "Լավ գին",
  "c-feedback-create-successMessage": "Շնորհակալություն ձեր կարծիքի համար!",
  "c-feedback-create-errorMessage": "Սխալ է տեղի ունեցել կարծիքը ուղարկելիս",
  "c-feedback-create-feedbackTitle": "Կարծիք",
  "c-feedback-create-experienceQuestion": "Ինչպես էր ձեր փորձը?",
  "c-feedback-create-positive": "Դրական",
  "c-feedback-create-negative": "Բացասական",
  "c-feedback-create-feedbackPlaceholder": "Խնդրում ենք մուտքագրել ձեր կարծիքը",
  "c-feedback-create-anonymous": "Անանուն",
  "c-feedback-create-anonymousHint":
    "ուղարկեք ձեր կարծիքը առանց ձեր անունը ցուցադրելու",
  "c-feedback-create-cancel": "Չեղարկել",
  "c-feedback-create-submit": "Ուղարկել կարծիքը",

  "c-feedback-item-anonymously": "(անանուն)",
  "c-feedback-item-positive": "Դրական",
  "c-feedback-item-negative": "Բացասական",
};
