export default {
  "p-confirm-email-title": "E-Mail-Bestätigung",
  "p-confirm-email-wait-message":
    "Bitte warten, wir versuchen, Ihre E-Mail-Adresse zu bestätigen...",
  "p-confirm-email-success-message":
    "Ihre E-Mail-Adresse wurde erfolgreich bestätigt!",
  "p-confirm-email-success-elmessage":
    "Ihre E-Mail-Adresse wurde erfolgreich bestätigt! Sie werden in 30 Sekunden zur Anmeldeseite weitergeleitet.",
  "p-confirm-email-info-elmessage":
    "Sie können sich jetzt als: {email} in Ihr Konto einloggen",
  "p-confirm-email-error-message":
    "Etwas ist schiefgelaufen, bitte versuchen Sie es später erneut.",
  "p-confirm-email-proceed-button": "Weiter zur Anmeldung",
  "p-confirm-email-welcome-title": "Willkommen in einer neuen Ära des Handels!",
  "p-confirm-email-welcome-message":
    "Verbinden Sie sich sicher mit einer Welt digitaler Vermögenswerte. Beginnen Sie mit Vertrauen und Bequemlichkeit auf unserer vertrauenswürdigen P2P-Plattform zu handeln. Ihr Tor zu nahtlosen Krypto-Transaktionen erwartet Sie.",
};
