export default {
  "p-tos-title": "使用条款 | Solid Swap",
  "p-tos-meta-description":
    "阅读 Solid Swap 的使用条款，了解使用我们加密货币交易平台的规则和指南",
  "p-tos-meta-keywords": "使用条款，用户协议，Solid Swap 条款，交易规则",
  "p-tos-header-title": "使用条款",
  "p-tos-download-link": "下载为文件",
  "p-tos-alert": "本客户协议可以以不同语言发布。如有任何不符，请参阅英文版本",
  "p-tos-agreement-part-1":
    "本协议在您（以下简称“您”或“您的”）与 SOLIDSWAP 之间。通过访问、使用或点击“我同意” SOLIDSWAP 或其附属机构通过网站提供的任何服务",
  "p-tos-agreement-part-2":
    "、我们的移动应用程序或 SOLIDSWAP 或其附属机构提供的任何其他相关服务（如下文第 4 节进一步描述）（以下简称“服务”或统称为“服务”）您同意您已阅读、理解并接受本协议中包含的所有条款和条件（“条款”或“这些条款”），以及我们的",
  "p-tos-privacy-policy": "隐私政策",
  "p-tos-agreement-part-3":
    "。此外，在使用此服务的附加功能时，您可能还需要遵守适用于这些功能的附加条款和条件。",
  "p-tos-warning-part-1":
    "请仔细阅读这些条款，因为它们管理您对服务的使用。这些条款包含重要条款，包括要求通过具有约束力的仲裁解决所有索赔的仲裁条款。仲裁条款的条款在下文第 15 节标题为“解决争议：论坛、仲裁、集体诉讼弃权”中规定。与任何资产一样，数字货币的价值可以上涨或下跌，您在购买、出售、持有或投资数字货币时可能会损失大量资金。通过使用服务，您承认并同意：",
  "p-tos-warning-part-2": "您了解与交易数字货币相关的风险；",
  "p-tos-warning-part-3":
    "您承担使用服务和交易数字货币的所有风险； SOLIDSWAP 不对此类风险或不利后果负责或承担责任。通过以任何身份访问、使用或尝试使用服务，您承认您接受并同意受这些条款的约束。如果您不同意，请不要访问或使用服务。",
  "p-tos-risk-awareness-1": "您了解与交易数字货币相关的风险；",
  "p-tos-risk-awareness-2": "您承担使用服务和交易数字货币的所有风险；",
  "p-tos-risk-awareness-3": "SOLIDSWAP 不对此类风险或不利后果负责或承担责任。",
  "p-tos-risk-awareness-4":
    "通过以任何身份访问、使用或尝试使用服务，您承认您接受并同意受这些条款的约束。如果您不同意，请不要访问或使用服务。",
  "p-tos-agreement-conditions-title": "协议条件",
  "p-tos-agreement-conditions-text":
    "SOLIDSWAP 保留随时修改或更改条款的权利，并自行决定。 SOLIDSWAP 将通过在网页上更新修订条款并更改本页上的“[最后修订：]”日期来提供这些更改的通知。对这些条款的任何和所有修改或更改将在网站上公布后立即生效。因此，您继续使用 SOLIDSWAP 的服务即表示接受修改后的协议和规则。如果您不同意对这些条款的任何修改，您必须停止使用服务。 SOLIDSWAP 鼓励您经常查看条款，以确保您了解适用于您访问和使用服务的条款和条件。",
  "p-tos-eligibility-title": "资格",
  "p-tos-eligibility-text-1":
    "通过注册使用 SOLIDSWAP 帐户（定义见下文第 5 节），您声明并保证您：",
  "p-tos-eligibility-text-2":
    "(a) 达到法定年龄，可以根据适用法律签订具有约束力的合同；",
  "p-tos-eligibility-text-3":
    "(b) 是具有完全法律能力和权利的个人、法人或其他组织，可以签订这些条款；",
  "p-tos-eligibility-text-4": "(c) 以前未被暂停或取消使用我们的服务；",
  "p-tos-eligibility-text-5": "(d) 目前没有现有的 SOLIDSWAP 帐户；",
  "p-tos-eligibility-text-6": "(e) 不是美国或加拿大公民。",
  "p-tos-eligibility-text-7":
    "如果您代表您作为员工或代理人的法律实体签订这些条款，您声明并保证您拥有绑定该法律实体的所有必要权利和授权。",
  "p-tos-prohibition-title": "禁止使用",
  "p-tos-prohibition-text-1":
    "通过访问和使用服务，您声明并保证您不在任何贸易或经济制裁名单上，例如联合国安全理事会制裁名单、被 OFAC（美国财政部外国资产控制办公室）指定为“特别指定国民”或列入美国商务部的“拒绝人员名单”。 SOLIDSWAP 保留选择其运营市场和司法管辖区的权利，并可自行决定在某些国家/地区限制或拒绝提供服务。",
  "p-tos-prohibition-text-2":
    "SOLIDSWAP 有权根据政府机构的正式要求、通过“争议”部分收到的决定、违反规则或与对 SOLIDSWAP 的欺诈行为有关的决定冻结用户的资金。",
  "p-tos-description-title": "服务说明",
  "p-tos-description-text-1":
    "交易服务： SOLIDSWAP 提供一个在线数字资产交易平台（加密货币对加密货币），用于通常称为加密代币、数字代币或加密货币（统称为“数字货币”）的产品。 SOLIDSWAP 不提供法定交易功能作为服务的一部分。 SOLIDSWAP 作为交易平台提供商，而不是作为交易者之间交易的买家或卖家。用户必须在 SOLIDSWAP 注册并开立账户，如下文第 5 节进一步规定，并在开始交易前存入数字资产。交易者可以申请提取其数字资产，但须遵守这些条款中规定的限制。",
  "p-tos-description-text-2":
    "SOLIDSWAP 不是交易的参与者，其任务是在交易完成前冻结用户的资金（加密货币）。",
  "p-tos-description-text-3":
    "如果用户错误地发送了资金， SOLIDSWAP 对用户的行为不承担责任。资金转账是用户的责任。",
  "p-tos-community-title": "社区服务",
  "p-tos-community-text-1":
    "SOLIDSWAP 为您提供社区交流服务。您可以复制您感兴趣的用户，在社区中表达您的观点和意见，并参与点赞等互动。 SOLIDSWAP 努力保持服务上发布信息的准确性，但不能保证信息的准确性、适用性、可靠性、完整性、性能或任何目的的适用性，并且对因您使用此类内容而直接或间接造成的任何损失或损害不承担任何责任。服务上的信息可以在不通知的情况下更改，并且提供的主要目的是帮助用户做出独立决策。",
  "p-tos-community-text-2":
    "SOLIDSWAP 不提供投资或咨询建议，并且对在服务或其他交流媒介上使用或解释信息不承担任何责任。所有服务用户必须理解交易数字货币存在风险。 SOLIDSWAP 鼓励所有用户审慎行事，并在其自身能力范围内负责任地进行交易。",
  "p-tos-community-text-3":
    "如果用户之间存在冲突，且用户无法自行解决冲突，则每个用户可以联系“争议”部分并使用主持人的服务。在访问指定部分 1 小时内，主持人会要求提供有关冲突细节的信息，并在收到请求文件后 24 小时内作出有利于一方用户的决定。",
  "p-tos-account-title": "SOLIDSWAP 帐户注册及要求",
  "p-tos-registration-title": "5.1 注册",
  "p-tos-registration-text-1": "服务的所有用户（每个为“用户”）必须在",
  "p-tos-registration-text-2":
    "注册 SOLIDSWAP 帐户（“帐户”）才能使用服务。要注册帐户，您必须提供您的真实姓名、电子邮件地址和密码，并接受使用条款和隐私政策。 SOLIDSWAP 可以自行决定拒绝为您开设帐户。您同意在开立帐户时提供完整准确的信息，并同意及时更新您提供给 SOLIDSWAP 的任何信息，以确保这些信息在任何时候都是完整准确的。每次注册仅针对单个用户，且每个用户（包括任何作为企业或法律实体的用户）只能保留一个活动帐户。",
  "p-tos-verification-title": "5.2 用户身份验证",
  "p-tos-verification-text-1":
    "在 SOLIDSWAP 上注册帐户时，您同意分享为进行身份验证所要求的个人信息。此信息专门用于检测 SOLIDSWAP 平台上的洗钱、恐怖融资、欺诈和其他金融犯罪。我们将根据我们发布的隐私政策收集、使用和共享此信息。除了提供这些信息之外，为了遵守全球数据保留行业标准，您同意允许我们保留此信息的记录，保留期为您帐户的生命周期加上帐户关闭后的 5 年。您还授权我们直接或通过第三方进行查询，以验证您的身份或保护您和/或我们免受欺诈等金融犯罪。",
  "p-tos-verification-text-2":
    "我们要求的身份验证信息可能包括但不限于您的：姓名、电子邮件地址、联系方式、电话号码、用户名、政府颁发的身份证、出生日期以及注册帐户时收集的其他信息。在提供这些必要信息时，您确认其准确和真实，且文件是真实反映您主要居住国的。为此， SOLIDSWAP 保留绝对酌情权拒绝任何申请人的权利，提供的身份证明文件表明、暗示或确认他/她正在使用任何允许发行此类居住证/身份证明的国家的某种形式的数字和/或虚拟政府居住证/身份证明。 SOLIDSWAP 进一步保留对此类国家的名单保密的权利，并在其认为合适时修改或更改此类名单，无需征得任何第三方的许可或批准，也无需向任何方、申请人或用户披露此名单，除非依法由有权机构强制要求。在注册后，您必须保证信息真实、完整，并在有任何更改时及时更新。如果有任何合理怀疑认为您提供的任何信息是错误、不真实、过时或不完整的， SOLIDSWAP 有权向您发出通知要求更正、直接删除相关信息，并在适用情况下终止向您提供全部或部分服务。您应自行和完全负责在使用 SOLIDSWAP 服务期间因无法通过提供的联系信息联系到您而产生的任何损失或费用。您在此承认并同意，如果有任何更改，您有义务及时更新所有提供的信息。",
  "p-tos-verification-text-3":
    "注册帐户即表示您在此授权 SOLIDSWAP 进行查询，无论是直接还是通过第三方， SOLIDSWAP 认为有必要的查询，以验证您的身份或保护您和/或 SOLIDSWAP 免受欺诈或其他金融犯罪，并根据此类查询的结果采取 SOLIDSWAP 合理认为必要的措施。您还承认并同意，您的个人信息可能会披露给信用参考和欺诈预防或金融犯罪机构，这些机构可能会完全回应我们的查询。",
  "p-tos-guidelines-title": "使用服务的指南",
  "p-tos-guidelines-subtitle": "6.1 许可",
  "p-tos-guidelines-text-1":
    "在您继续遵守这些条款的明文条款和条件的前提下， SOLIDSWAP 向您提供一个可撤销的、有限的、免版税的、非排他性的、不可转让的和不可再许可的许可，允许您在您的计算机或其他互联网兼容设备上访问和使用服务，仅供您个人内部使用。您不得将服务用于任何转售或商业用途，包括代表他人或实体进行交易。所有此类使用均明确禁止，并构成对这些条款的重大违反。服务的内容布局、格式、功能和访问权限应由 SOLIDSWAP 全权决定。所有未在这些条款下明确授予的权利均在此保留。因此，您在此被禁止以任何未在这些条款下明确和毫无疑问地授权的方式使用服务。",
  "p-tos-guidelines-text-2":
    "这些条款仅提供有限的访问和使用服务的许可。因此，您在此同意 SOLIDSWAP 不会将服务或任何 SOLIDSWAP 知识产权的所有权或知识产权权益或标题转让给您或其他任何人，涉及您使用服务的情况。所有文本、图形、用户界面、视觉界面、照片、声音、艺术品、计算机代码（包括 html 代码）、程序、软件、产品、信息和文档以及设计、结构、选择、协调、表达、“外观和感觉”，以及任何通过服务可用的内容的安排均由 SOLIDSWAP 或其成员、母公司、许可方或附属机构独家拥有、控制和/或许可。",
  "p-tos-guidelines-text-3":
    "SOLIDSWAP 将拥有您提供的有关 SOLIDSWAP 或服务的任何反馈、建议、想法或其他信息或材料，无论是通过电子邮件、通过服务或以其他方式（“反馈”）。您在此将反馈及其所有相关知识产权的所有权、标题和权益转让给 SOLIDSWAP。您无权要求任何基于任何反馈或根据任何反馈所作的任何修改的确认或补偿，并在此放弃任何此类要求。",
  "p-tos-restrictions-title": "6.2 限制",
  "p-tos-restrictions-text-1": "在您使用服务时，您同意并承诺遵守以下规定：",
  "p-tos-restrictions-text-2":
    "(a) 您在使用服务期间进行的所有活动将符合适用法律、法规以及 SOLIDSWAP 的各种指南的要求；",
  "p-tos-restrictions-text-3":
    "(b) 您使用服务时不会违反公共利益、公共道德或他人的合法利益，包括采取任何会干扰、破坏、负面影响或妨碍其他用户使用服务的行动；",
  "p-tos-restrictions-text-4":
    "(c) 您同意不使用服务从事市场操纵（例如抬高和抛售计划、虚假交易、自我交易、抢先交易、报价填塞和伪装或分层，无论法律是否禁止）。",
  "p-tos-restrictions-text-5":
    "SOLIDSWAP 不验证用户钱包中资金的来源，对资金的来源不负责任。",
  "p-tos-restrictions-text-6":
    "SOLIDSWAP 禁止使用从非法物质交易、骗局或其他与对生命和健康有害相关的来源获得的资金。",
  "p-tos-restrictions-text-7":
    "除非获得 SOLIDSWAP 的书面同意，否则禁止以下商业用途 SOLIDSWAP 数据：",
  "p-tos-restrictions-text-8":
    "(a) 使用 SOLIDSWAP 报价或订单簿信息的交换服务；",
  "p-tos-restrictions-text-9":
    "(b) 使用 SOLIDSWAP 任何市场数据的数据馈送或数据流服务；",
  "p-tos-restrictions-text-10":
    "(c) 任何其他收费或以其他方式商业化（包括通过广告或推荐费）从 SOLIDSWAP 获取的市场数据的网站/应用程序/服务。",
  "p-tos-restrictions-text-11":
    "未经 SOLIDSWAP 事先书面同意，您不得修改、复制、复制、下载、存储、进一步传输、传播、转让、拆卸、广播、发布、删除或更改任何专有通知或标签、许可、再许可、出售、镜像、框架、租赁、租赁、私有标签、授予担保权益、创建衍生作品或以其他方式利用资产或资产的任何部分。",
  "p-tos-orders-title": "订单和服务费用",
  "p-tos-orders-subtitle": "7.1 订单",
  "p-tos-orders-text-1":
    "在通过服务发出执行交易的指令（“订单”）后，您的帐户将更新以反映未完成的订单，并且您的订单将被包括在 SOLIDSWAP 的订单簿中以与其他用户的订单进行匹配。如果您的订单或其部分订单与其他用户匹配，服务将执行交换（“交易”）。执行交易后，您的帐户将更新以反映订单已因完全执行而关闭，或更新以反映订单的任何部分完成情况。订单将保持开放状态，直到完全执行或根据下文第 7.2 节取消。为了执行交易，您授权 SOLIDSWAP 暂时控制您在交易中处置的数字货币。",
  "p-tos-orders-text-2":
    "点对点交易的最低金额为 15 欧元，最高金额为 100,000,000 欧元。",
  "p-tos-orders-text-3":
    "钱包的最低存款和取款金额为 50 欧元，如果您存入少于 50 欧元的金额；此金额将丢失且无法退还。",
  "p-tos-orders-text-4":
    "点对点交易的佣金由加密货币卖方支付，金额为交易的 0.2%。",
  "p-tos-orders-text-5":
    "钱包取款的佣金是动态的；取决于出口货币的网络负载，除了 USDT。在这种情况下，取款费用为 1 USDT。",
  "p-tos-cancellations-title": "7.2 取消",
  "p-tos-cancellations-text-1":
    "如果取消发生在您的订单与其他用户的订单匹配之前，您只能取消通过服务发起的订单。一旦您的订单与其他用户的订单匹配，您就不能更改、撤回或取消您授权 SOLIDSWAP 完成此类订单。如果任何订单已部分匹配，您可以取消未匹配的部分订单，除非并且直到未匹配的部分已被匹配。 SOLIDSWAP 保留在您提交此类订单后拒绝任何取消请求的权利。如果您的帐户中没有足够的数字货币来完成订单， SOLIDSWAP 可以取消整个订单或完成帐户中可以支付的部分订单（在每种情况下，扣除与交易有关的任何费用，如下文第 7.3 节所述）。",
  "p-tos-fees-title": "7.3 费用",
  "p-tos-fees-text-1": "您同意支付 SOLIDSWAP 在",
  "p-tos-fees-text-2":
    "上列明的费用， SOLIDSWAP 可自行决定不时更新这些费用。任何此类更新的费用将适用于更新费用生效后发生的任何交易或其他交易。您授权 SOLIDSWAP 从您的帐户中扣除您根据这些条款应支付的任何费用。如果您的帐户余额不足以扣除根据本条款应付的任何费用，您同意应立即将此金额存入您的帐户（无论如何不得迟于费用应付之日起三个工作日内），以便 SOLIDSWAP 可以扣除适当的费用。不这样做将使 SOLIDSWAP 有权自行决定暂停、关闭或取消帐户，并将未付费用视为您欠 SOLIDSWAP 的债务，从而可以通过 SOLIDSWAP 可用的任何法律手段从您那里收回。未付费用的任何利息以及任何和所有因追讨未付费用而产生的费用和费用应由您自行承担，并按赔偿基础支付给 SOLIDSWAP。",
  "p-tos-futures-title": "期货交易",
  "p-tos-futures-subtitle": "8.1 风险",
  "p-tos-futures-text-1":
    "期货交易风险极高。作为期货交易者，您承认并同意您应自行承担风险访问和使用保证金交易和借贷服务。",
  "p-tos-enter-futures-title": "8.2 进入期货交易",
  "p-tos-enter-futures-text-1":
    "您声明并保证您不是美国公民，也不在任何贸易或经济制裁名单上，例如被 OFAC（美国财政部外国资产控制办公室）指定为“特别指定国民”。",
  "p-tos-enter-futures-text-2":
    "您应充分了解期货交易和借贷的风险，并对您 SOLIDSWAP 帐户上的所有交易和非交易活动承担全部责任和义务。不要进入超出您财务能力的交易或投资资金。您对了解任何头寸的真实状况负全责，即使 SOLIDSWAP 任何时候显示不正确。",
  "p-tos-enter-futures-text-3":
    "您同意在您的 SOLIDSWAP 期货账户中保持 SOLIDSWAP 要求的足够数量的区块链资产，以便用户进行期货交易并及时全额偿还贷款。未能保持足够数量的资产或未能及时偿还未偿贷款可能会导致您期货账户中的资产被强制清算。",
  "p-tos-enter-futures-text-4":
    "即使具有强制清算您任何头寸的能力， SOLIDSWAP 也不能保证止损。如果在您的头寸被清算后，您的资产不足以支付未偿贷款，您仍需负责任何额外的资产缺口。",
  "p-tos-enter-futures-text-5":
    " SOLIDSWAP 可自行决定采取措施以减轻您可能遭受的潜在损失，包括但不限于将余额从您的合同账户转移到您的交易账户和/或从您的交易账户转移到您的期货账户。",
  "p-tos-enter-futures-text-6":
    "在 SOLIDSWAP 系统维护期间，您同意您应自行负责和承担管理您的期货账户的风险，包括但不限于保留或关闭您的头寸并偿还贷款。",
  "p-tos-enter-futures-text-7":
    "您同意您自行进行所有交易、期货交易和/或借贷，并对您的活动负全部责任。 SOLIDSWAP 对因您使用任何服务或未能了解资产使用风险的一般性或您使用我们的服务而造成的任何损失或损害不承担任何责任。",
  "p-tos-enter-futures-text-8":
    "您知道在清算时，所有未完成的订单将立即取消。您可能会失去所有头寸和合同账户中的所有资金。如果 SOLIDSWAP 无法在清算时关闭所有头寸，保险基金和/或对手清算将生效。 SOLIDSWAP 采取一切可能的措施以避免对手清算以尽量减少任何对手清算的潜在影响。不幸的是，由于加密市场的波动性以及向客户提供的高杠杆，无法完全避免这种可能性。为了提供尽可能好的客户体验， SOLIDSWAP 努力将对手清算控制在绝对最低限度。",
  "p-tos-enter-futures-text-9":
    "由于网络延迟、计算机系统故障和其他不可抗力因素，可能导致 SOLIDSWAP 服务执行延迟、中断或偏差， SOLIDSWAP 将尽商业上合理的努力确保但不保证 SOLIDSWAP 服务执行系统稳定有效。 SOLIDSWAP 不对最终执行不符合您的期望承担任何责任。",
  "p-tos-prohibited-title": "8.3 禁止的交易事项",
  "p-tos-prohibited-text-1":
    "禁止用户使用多个帐户和设备进行非法套利（包括但不限于赚取交易费用、佣金、奖金、对冲等）。 SOLIDSWAP 将对在系统中检测到非法套利的用户采取相应措施。措施包括（但不限于）限制帐户、冻结资产、限制登录、要求 KYC 等。",
  "p-tos-prohibited-text-2":
    "为了确保普通用户的正常交易体验， SOLIDSWAP 保留对被系统检测到使用超短期交易（超短期频繁开仓和平仓）用户采取以下措施的权利：",
  "p-tos-prohibited-text-3":
    "立即终止您的帐户并访问我们的服务器，并扣除非法收入。",
  "p-tos-prohibited-text-4":
    "使所有构成超短期交易活动的交易无效（即处理交易，仿佛交易从未发生）。",
  "p-tos-prohibited-text-5":
    "根据我们当前的市场价格，关闭所有构成超短期交易活动的交易。",
  "p-tos-prohibited-text-6": "增加您的持仓时间（即您只能在一段时间后平仓）。",
  "p-tos-prohibited-text-7":
    " SOLIDSWAP 严格禁止不公平的交易行为。如果您使用 SOLIDSWAP 进行以下行为， SOLIDSWAP 保留自行决定对您的帐户实施控制的权利：",
  "p-tos-prohibited-text-8": "参与价格操纵或任何其他恶意市场行为。",
  "p-tos-prohibited-text-9":
    "通过利用此服务的漏洞或其他不合理手段损害其他用户或 SOLIDSWAP。",
  "p-tos-prohibited-text-10": "参与 SOLIDSWAP 认为对市场有害的任何其他活动。",
  "p-tos-liability-title": "责任",
  "p-tos-liability-subtitle": "9.1 免责声明",
  "p-tos-liability-text-1":
    "在适用法律允许的最大范围内，服务、 SOLIDSWAP 材料以及 SOLIDSWAP 或代表 SOLIDSWAP 提供的任何产品、服务或其他项目均按“原样”和“可用”提供， SOLIDSWAP 明确免责声明，并且您放弃任何和所有其他类型的保证，无论是明示还是暗示，包括但不限于适销性、适合特定用途、所有权或不侵权的默示保证或因履行过程、交易过程或使用中的保证。除非另有明确规定， SOLIDSWAP 不表示或保证网站、服务或 SOLIDSWAP 材料是准确、完整、可靠、最新、无错误或无病毒或其他有害组件。 SOLIDSWAP 不保证任何订单将被执行、接受、记录或保持开放。除本协议中明确规定的陈述外，您在此承认并同意您未依赖于与您访问和使用服务有关的任何其他陈述或理解，无论是书面或口头。除非另有明确规定，您在此理解并同意， SOLIDSWAP 不对因以下原因而产生的任何损失或损害承担责任：(A) 任何数字货币价格数据的不准确、缺陷或遗漏，(B) 此类数据传输中的任何错误或延迟，(C) 任何此类数据的中断，以及 (D) 任何由其他用户的行为、疏忽或违反本协议而产生的损害。",
  "p-tos-liability-text-2":
    "如果并在适用法律禁止的范围内，上述隐含保证免责声明可能不适用。",
  "p-tos-liability-subtitle-2": "9.2 损害免责声明和责任限制",
  "p-tos-liability-text-3":
    "在适用法律允许的最大范围内，在任何情况下， SOLIDSWAP、其附属机构及其各自的股东、成员、董事、高级职员、员工、律师、代理、代表、供应商或承包商均不对任何偶然的、间接的、特殊的、惩罚性的、后果性的或类似的损害或责任承担任何责任（包括但不限于数据、信息、收入、利润或其他业务或财务利益的损失），无论是因服务、任何服务的履行或未履行，或由 SOLIDSWAP 及其附属机构代表提供的任何其他产品、服务或其他项目引起的或与之相关的责任，是否根据合同、法规、严格责任或其他理论，即使 SOLIDSWAP 已被告知此类损害的可能性，除非有最终的司法裁定认为此类损害是由于 SOLIDSWAP 的重大过失、欺诈、故意的不当行为或故意违反法律造成的。一些司法管辖区不允许排除或限制偶然或后果性损害，因此上述限制可能不适用于您。",
  "p-tos-liability-text-4":
    "尽管有上述规定，在任何情况下， SOLIDSWAP、其附属机构及其各自的股东、成员、董事、高级职员、员工、律师、代理、代表、供应商或承包商因服务、任何服务的履行或未履行或由 SOLIDSWAP 或其附属机构代表提供的任何其他产品、服务或其他项目引起的或与之相关的责任，无论是根据合同、法规、严格责任或其他理论，均不得超过您在本协议项下向 SOLIDSWAP 支付的费用在导致责任索赔的事件发生前的十二个月期间内支付的金额。",
  "p-tos-liability-subtitle-3": "9.3 赔偿",
  "p-tos-liability-text-5":
    "您同意赔偿和保护 SOLIDSWAP 及其附属机构、承包商、许可方及其各自的董事、高级职员、员工和代理免受任何因以下原因引起的索赔、诉讼、诉讼、调查、要求、诉讼费用、费用和损害（包括律师费用、监管机构施加的罚款或处罚）：（i）您使用或与使用服务有关的行为，（ii）您违反或我们执行这些条款，或（iii）您在使用服务期间违反任何适用法律、法规或任何第三方的权利。如果您有义务根据本条款赔偿 SOLIDSWAP、其附属机构、承包商、许可方及其各自的董事、高级职员、员工或代理， SOLIDSWAP 将有权自行决定控制任何行动或诉讼并确定 SOLIDSWAP 是否希望解决，如果是，按什么条款。",
  "p-tos-announcements-title": "公告",
  "p-tos-announcements-text-1":
    "请注意，所有官方公告、新闻、促销、竞赛和空投都将在 SOLIDSWAP 官方网站上列出，我们敦促所有用户定期查阅。 SOLIDSWAP 不对因忽视或忽视公告而导致的个人损失承担任何责任或赔偿责任。",
  "p-tos-termination-title": "协议终止",
  "p-tos-termination-text-1":
    "您同意 SOLIDSWAP 有权立即暂停您的帐户（以及任何由相关实体或附属机构实益拥有的帐户），冻结或锁定所有此类帐户中的资金，并暂停您对 SOLIDSWAP 的访问，无论出于何种原因，包括怀疑任何此类帐户违反这些条款、我们的隐私政策或任何适用法律法规。您同意 SOLIDSWAP 对因暂停、关闭或终止您的帐户或访问全部或部分服务的任何永久或临时修改不对您承担责任。 SOLIDSWAP 有权保留和使用与此类帐户相关的交易数据或其他信息。上述帐户控制也可能适用于以下情况：",
  "p-tos-termination-text-2":
    "该帐户正在进行政府程序、刑事调查或其他未决诉讼。",
  "p-tos-termination-text-3": "我们检测到帐户中的异常活动。",
  "p-tos-termination-text-4": "我们检测到帐户的未经授权访问。",
  "p-tos-termination-text-5": "我们被法院命令或监管/政府机构的命令要求这样做。",
  "p-tos-termination-text-6":
    "在以下任何情况下， SOLIDSWAP 有权通过取消您的帐户直接终止本协议，并有权永久冻结（取消）您在 SOLIDSWAP 上帐户的授权并收回相应的 SOLIDSWAP 帐户：",
  "p-tos-termination-text-7": "在 SOLIDSWAP 终止向您提供服务后；",
  "p-tos-termination-text-8":
    "您被指控直接或间接以他人名义再次注册为 SOLIDSWAP 用户；",
  "p-tos-termination-text-9": "您提供的信息不真实、不准确、过时或不完整；",
  "p-tos-termination-text-10":
    "当这些条款被修改时，您明确声明并通知 SOLIDSWAP 您不愿意接受修改后的条款；",
  "p-tos-termination-text-11":
    "您要求终止服务；以及 SOLIDSWAP 认为应终止服务的任何其他情况。",
  "p-tos-termination-text-12":
    "如果您的帐户被终止，为满足数据保留标准而需要的帐户和交易信息将被安全存储 5 年。此外，如果帐户终止过程中的交易未完成， SOLIDSWAP 有权在当时通知您的交易对手。您承认用户发起的帐户退出（根据 GDPR 或其他等效法规享有的擦除权）也将受上述终止协议的约束。",
  "p-tos-termination-text-13":
    "如果 SOLIDSWAP 收到通知称您的帐户中持有的任何资金涉嫌被盗或您未合法拥有， SOLIDSWAP 可以但没有义务对受影响的资金和您的帐户进行行政扣押。如果 SOLIDSWAP 对部分或全部资金或帐户进行行政扣押， SOLIDSWAP 可以继续扣押，直到争议得到解决并且向 SOLIDSWAP 提供的解决证据为 SOLIDSWAP 可接受的形式。 SOLIDSWAP 不会参与此类争议或争议的解决。您同意 SOLIDSWAP 对此类扣押或在任何此类扣押期间您无法提取资金或执行交易不承担任何责任。",
  "p-tos-funds-title": "帐户终止后的剩余资金",
  "p-tos-funds-text-1":
    "除以下第 (b) 款规定外，一旦帐户关闭/撤销，帐户上的所有剩余余额（包括欠 SOLIDSWAP 的费用和责任）将立即支付给 SOLIDSWAP。支付所有应付的费用给 SOLIDSWAP 后（如有），用户将有 5 个工作日提取存入帐户的原始金额（为避免疑义，这意味着在帐户终止前产生的任何交易损失是否反映在帐户余额中）。用户无权获得任何净交易利润，这些利润将相应地由 SOLIDSWAP 没收。如果帐户终止时的余额少于原始存款金额，因交易损失或早期取款等原因，用户仅有权提取较少的现有金额，并且在任何情况下均无权对 SOLIDSWAP 追索余额。",
  "p-tos-funds-title-2":
    "因欺诈、违法行为或违反这些条款导致的帐户终止后的剩余资金",
  "p-tos-funds-text-2":
    "在因欺诈调查、违法行为调查或违反这些条款而导致帐户暂停/关闭的情况下， SOLIDSWAP 保留对资金和用户数据/信息的完全控制权，这些数据/信息可能会移交给政府机构。",
  "p-tos-financial-title": "无财务建议",
  "p-tos-financial-text-1":
    " SOLIDSWAP 不是您的经纪人、中介、代理或顾问，在您使用服务所做的任何交易或其他决策或活动中没有对您的信托关系或义务。 SOLIDSWAP 提供给您的任何通信或信息均不构成或不应被视为投资建议、财务建议、交易建议或任何其他形式的建议。所有交易均根据您的订单指令参数自动执行，并按照发布的交易执行程序进行，您应自行负责确定任何投资、投资策略或相关交易是否适合您，基于您的个人投资目标、财务状况和风险承受能力。您应咨询您的法律或税务专业人士，以了解您的具体情况。 SOLIDSWAP 不建议您购买、赚取、出售或持有任何数字货币。在决定购买、出售或持有任何数字货币之前，您应自行进行尽职调查，并在做出任何投资决策之前咨询您的财务顾问。 SOLIDSWAP 不对您根据 SOLIDSWAP 提供的信息做出的购买、出售或持有数字货币的决策负责。",
  "p-tos-local-laws-title": "遵守当地法律",
  "p-tos-local-laws-text-1":
    "用户有责任遵守当地法律，合法使用其所在司法管辖区的 SOLIDSWAP。用户还必须根据当地法律的要求，处理税收、扣缴、报告和向适当的税务机关支付税款。所有 SOLIDSWAP 和其任何服务的用户承认并声明其资金来源合法，不是从非法活动中获得的。 SOLIDSWAP 保持与全球执法机构的合作态度，并且不会犹豫查封、冻结、终止被标记或受到法律命令调查的用户的帐户和资金。",
  "p-tos-privacy-title": "隐私政策",
  "p-tos-privacy-text-1":
    "访问服务将需要提交某些可识别个人身份的信息。请查看 SOLIDSWAP 的隐私政策，了解 SOLIDSWAP 与收集和使用个人身份信息相关的做法摘要。",
  "p-tos-privacy-text-2": "查看隐私政策",
  "p-tos-disputes-title": "解决争议：论坛、仲裁、集体诉讼弃权",
  "p-tos-disputes-text-1":
    "请仔细阅读本部分，因为它涉及某些权利的放弃，以进行法律诉讼。",
  "p-tos-disputes-subtitle-1": "争议通知。",
  "p-tos-disputes-text-2":
    "请首先联系 SOLIDSWAP！ SOLIDSWAP 希望在不诉诸正式法律程序的情况下解决您的担忧。在提出索赔之前，您同意首先通过 SOLIDSWAP 官方网站联系 SOLIDSWAP，尝试非正式地解决争议。",
  "p-tos-disputes-subtitle-2": "同意仲裁。",
  "p-tos-disputes-text-3":
    "您和 SOLIDSWAP 同意通过最终和具有约束力的仲裁解决与本协议相关的任何索赔（包括关于其存在、有效性、终止、任何服务或产品提供的任何问题以及我们做出的任何陈述），除非在下文例外情况中有规定。您同意首先给我们一个机会，通过上述第 (a) 节规定的联系解决任何索赔。如果我们",
  "p-tos-disputes-text-4":
    "在收到通知后的 60 天内无法解决您的索赔，您可以通过仲裁或在亚美尼亚共和国上诉法院（“RAAC”）寻求救济，如下所述。",
  "p-tos-disputes-subtitle-3": "仲裁程序",
  "p-tos-disputes-text-5":
    "您或 SOLIDSWAP 可以将争议（在根据上述第 (a) 和 (b) 节的真诚努力解决此类争议后）提交 RAAC 仲裁规则下进行最终的、具有约束力的仲裁， RAAC 仲裁规则被视为被引用的规则。仲裁庭应由 RAAC 主席指定的一名独任仲裁员组成。仲裁听证的语言应为英语，仲裁地应为亚美尼亚。任何仲裁裁决的判决可以在对有权当事人（或有权当事人资产）的任何法院登记。",
  "p-tos-disputes-subtitle-4": "例外情况",
  "p-tos-disputes-text-6":
    "如果索赔在 RAAC 的管辖范围内，任一方可以选择在 RAAC 提出索赔，任一方可以在有管辖权的法院寻求禁令救济或其他紧急衡平法救济。然而，为避免疑义，如果索赔超出了 RAAC 的管辖范围，索赔将被提交并最终由 RAAC 仲裁解决。",
  "p-tos-disputes-subtitle-5": "通知",
  "p-tos-disputes-text-7":
    "要启动仲裁程序，您必须发送一封请求仲裁并描述您的索赔的信函至",
  "p-tos-disputes-text-8":
    "如果我们对您提出仲裁请求，我们将向您提供您提供的电子邮件地址或街道地址通知。",
  "p-tos-miscellaneous-title": "杂项",
  "p-tos-miscellaneous-text-1":
    "a. 独立方。 SOLIDSWAP 是独立承包商，在履行这些条款时不是您的代理。不得将这些条款解释为双方之间的联合、合资、合伙或特许经营的证据。",
  "p-tos-miscellaneous-text-2":
    "b. 完整协议。这些条款构成双方关于使用服务的完整协议，并将取代双方之间的所有先前协议，无论是书面还是口头协议。任何贸易使用或双方之间的其他常规做法或处理方法均不得用于修改、解释、补充或更改这些条款的条款。",
  "p-tos-miscellaneous-text-3":
    "c. 不可抗力。 SOLIDSWAP 对由于超出 SOLIDSWAP 合理控制范围的任何原因或条件而导致的延迟或未履行这些条款不承担责任。",
  "p-tos-miscellaneous-text-4":
    "d. 可分割性。如果这些条款的任何部分被认定为无效或不可执行，则此类无效或不可执行性将不会影响这些条款的其他条款，其他条款将继续有效，且无效或不可执行的部分将在最大程度上有效。",
  "p-tos-miscellaneous-text-5":
    "e. 转让。未经我们事先书面同意，您不得转让或转让使用服务的任何权利或您在这些条款下的任何权利或义务，包括通过法律操作或与任何控制权变更相关。 SOLIDSWAP 可以转让或转让其在这些条款下的任何或",
  "p-tos-miscellaneous-text-6":
    "全部权利或义务，无需通知或获得您的同意或批准。",
  "p-tos-miscellaneous-text-7":
    "f. 弃权。任何一方未要求履行任何条款的行为不影响该方以后随时要求履行的权利，也不会放弃这些条款或任何条款的任何违约或默认或条款本身。",
  "p-tos-miscellaneous-text-8":
    "g. 第三方网站免责声明。任何通过服务链接到第三方网站的链接均不表示 SOLIDSWAP 对其中所呈现的任何产品、服务或信息的认可， SOLIDSWAP 也不保证其中信息的准确性。此外，由于 SOLIDSWAP 无法控制第三方网站的使用条款或隐私惯例，您应仔细阅读并理解这些政策。",
  "p-tos-miscellaneous-text-9":
    "h. 联系信息。有关 SOLIDSWAP 的更多信息，您可以参考网站上的公司和许可信息。如果您对本协议有任何疑问，请通过我们的客户支持团队联系 SOLIDSWAP 以获得澄清",
  "p-tos-language-title": "使用语言",
  "p-tos-language-text-1":
    "本客户协议可以以不同语言发布。如有任何不符，请参阅英文版本。",
  "p-tos-community-text-4":
    "如果用户之间存在冲突，且用户无法自行解决冲突，则每个用户可以联系“争议”部分并使用主持人的服务。在访问指定部分 1 小时内，主持人会要求提供有关冲突细节的信息，并在收到请求文件后 24 小时内作出有利于一方用户的决定。",
  "p-tos-verification-text-4":
    "您在此承认并同意，如果有任何更改，您有义务及时更新所有提供的信息。",
  "p-tos-verification-text-5":
    "注册帐户即表示您在此授权 SOLIDSWAP 进行查询，无论是直接还是通过第三方， SOLIDSWAP 认为有必要的查询，以验证您的身份或保护您和/或 SOLIDSWAP 免受欺诈或其他金融犯罪，并根据此类查询的结果采取 SOLIDSWAP 合理认为必要的措施。您还承认并同意，您的个人信息可能会披露给信用参考和欺诈预防或金融犯罪机构，这些机构可能会完全回应我们的查询。",
  "p-tos-guidelines-text-4":
    "这些条款仅提供有限的访问和使用服务的许可。因此，您在此同意 SOLIDSWAP 不会将服务或任何 SOLIDSWAP 知识产权的所有权或知识产权权益或标题转让给您或其他任何人，涉及您使用服务的情况。所有文本、图形、用户界面、视觉界面、照片、声音、艺术品、计算机代码（包括 html 代码）、程序、软件、产品、信息和文档以及设计、结构、选择、协调、表达、“外观和感觉”，以及任何通过服务可用的内容的安排均由 SOLIDSWAP 或其成员、母公司、许可方或附属机构独家拥有、控制和/或许可。",
  "p-tos-guidelines-text-5":
    " SOLIDSWAP 将拥有您提供的有关 SOLIDSWAP 或服务的任何反馈、建议、想法或其他信息或材料，无论是通过电子邮件、通过服务或以其他方式（“反馈”）。您在此将反馈及其所有相关知识产权的所有权、标题和权益转让给 SOLIDSWAP。您无权要求任何基于任何反馈或根据任何反馈所作的任何修改的确认或补偿，并",
  "p-tos-guidelines-text-6": "在此放弃任何此类要求。",
  "p-tos-cancellations-text-2":
    "如果您的帐户中没有足够的数字货币来完成订单， SOLIDSWAP 可以取消整个订单或完成帐户中可以支付的部分订单（在每种情况下，扣除与交易有关的任何费用，如下文第 7.3 节所述）。",
  "p-tos-futures-text-2":
    "交易市场极其波动，流动性、市场深度和交易动态迅速变化。杠杆的使用既可能对您有利也可能对您不利，并且可能导致巨大的损失和收益。",
  "p-tos-futures-text-3":
    "在某些市场条件下，您可能会发现很难或无法清算头寸。例如，市场流动性不足或 SOLIDSWAP 存在技术问题时可能会发生这种情况。",
  "p-tos-futures-text-4":
    "设置条件订单不一定会将您的损失限制在预期金额，因为市场条件可能导致无法执行此类订单。",
  "p-tos-futures-text-5":
    "期货交易没有保证的损失防护措施。作为借款人，您可能会遭受超出您存入合同账户的全部损失。",
  "p-tos-prohibited-text-11":
    "通过访问服务，您同意 SOLIDSWAP 有权调查任何违反这些条款的行为，单方面决定您是否违反了这些条款，并采取行动实施相关规则，无需获得您的同意或事先通知您。此类行为的例子包括但不限于：",
  "p-tos-termination-text-14": "我们检测到帐户中的异常活动。",
  "p-tos-termination-text-15": "我们检测到帐户的未经授权访问。",
  "p-tos-termination-text-16":
    "我们被法院命令或监管/政府机构的命令要求这样做。",
  "p-tos-termination-text-17": "在 SOLIDSWAP 终止向您提供服务后；",
  "p-tos-termination-text-18":
    "您被指控直接或间接以他人名义再次注册为 SOLIDSWAP 用户；",
  "p-tos-termination-text-19": "您提供的信息不真实、不准确、过时或不完整；",
  "p-tos-termination-text-20":
    "当这些条款被修改时，您明确声明并通知 SOLIDSWAP 您不愿意接受修改后的条款；",
  "p-tos-compliance-title": "遵守当地法律",
  "p-tos-compliance-text-1":
    "用户有责任遵守当地法律，合法使用其所在司法管辖区的 SOLIDSWAP。用户还必须根据当地法律的要求，处理税收、扣缴、报告和向适当的税务机关支付税款。所有 SOLIDSWAP 和其任何服务的用户承认并声明其资金来源合法，不是从非法活动中获得的。 SOLIDSWAP 保持与全球执法机构的合作态度，并且不会犹豫查封、冻结、终止被标记或受到法律命令调查的用户的帐户和资金。",
  "p-tos-resolving-title": "解决争议：论坛、仲裁、集体诉讼弃权",
  "p-tos-resolving-text-1":
    "请仔细阅读本部分，因为它涉及某些权利的放弃，以进行法律诉讼。",
  "p-tos-resolving-text-2":
    "请首先联系 SOLIDSWAP！ SOLIDSWAP 希望在不诉诸正式法律程序的情况下解决您的担忧。在提出索赔之前，您同意首先通过 SOLIDSWAP 官方网站联系 SOLIDSWAP，尝试非正式地解决争议。",
  "p-tos-resolving-text-3":
    "您和 SOLIDSWAP 同意通过最终和具有约束力的仲裁解决与本协议相关的任何索赔（包括关于其存在、有效性、终止、任何服务或产品提供的任何问题以及我们做出的任何陈述），除非在下文例外情况中有规定。您同意首先给我们一个机会，通过上述第 (a) 节规定的联系解决任何索赔。如果我们",
  "p-tos-resolving-text-4":
    "在收到通知后的 60 天内无法解决您的索赔，您可以通过仲裁或在亚美尼亚共和国上诉法院（“RAAC”）寻求救济，如下所述。",
  "p-tos-resolving-text-5":
    "您或 SOLIDSWAP 可以将争议（在根据上述第 (a) 和 (b) 节的真诚努力解决此类争议后）提交 RAAC 仲裁规则下进行最终的、具有约束力的仲裁， RAAC 仲裁规则被视为被引用的规则。仲裁庭应由 RAAC 主席指定的一名独任仲裁员组成。仲裁听证的语言应为英语，仲裁地应为亚美尼亚。任何仲裁裁决的判决可以在对有权当事人（或有权当事人资产）的任何法院登记。",
  "p-tos-resolving-text-6":
    "如果索赔在 RAAC 的管辖范围内，任一方可以选择在 RAAC 提出索赔，任一方可以在有管辖权的法院寻求禁令救济或其他紧急衡平法救济。然而，为避免疑义，如果索赔超出了 RAAC 的管辖范围，索赔将被提交并最终由 RAAC 仲裁解决。",
  "p-tos-resolving-text-7":
    "要启动仲裁程序，您必须发送一封请求仲裁并描述您的索赔的信函至",
};
