export default {
  "p-trade-action-buy-title":
    "Buy Cryptocurrency | Secure Crypto Buying on Solid Swap",
  "p-trade-action-sell-title":
    "Sell Cryptocurrency | Secure Crypto Selling on Solid Swap",
  "p-trade-action-buy-description":
    "Buy cryptocurrency securely on Solid Swap. Benefit from fast transactions and competitive rates for digital asset purchases",
  "p-trade-action-sell-description":
    "Sell your cryptocurrency securely on Solid Swap. Enjoy fast transactions and optimal rates for your digital assets",
  "p-trade-action-buy-keywords":
    "buy cryptocurrency, crypto buying, secure asset purchase, digital currency trading",
  "p-trade-action-sell-keywords":
    "sell cryptocurrency, crypto selling, secure asset sales, digital currency trading",
  "p-trade-action-nothing-found":
    "nothing found, try to find different options",
  "p-trade-action-clear-filters": "Clear all filters",
};
