export default {
  "p-login-head-title": "Login | Access Your Solid Swap Account",
  "p-login-head-description":
    "Log in to your Solid Swap account to start trading cryptocurrency securely and efficiently",
  "p-login-head-keywords":
    "login, Solid Swap account, cryptocurrency trading access",
  "p-login-rules-email": "Please enter your email",
  "p-login-rules-password": "Please enter your password",
  "p-login-error-default": "Oops, something went wrong during login.",
  "p-login-error-2fa-wrong": "Wrong 2FA code! Please try again.",
  "p-login-error-user-not-found": "User not found",
  "p-login-error-email-verification":
    "Your email needs to be verified first. Please check your email.",
  "p-login-error-suspended":
    "Your account has been suspended. Please check your email for further details.",
  "p-login-error-locked-title": "Temporarily locked",
  "p-login-error-locked-message":
    "You have made {failedLockCount} or more unsuccessful login attempts. For security reasons, please wait 60 minutes before trying again. If you need immediate assistance or have forgotten your password, please use the 'Forgot Password' option to reset your password.",
  "p-login-error-wrong-password-title": "Wrong password",
  "p-login-error-wrong-password-message":
    "You have made {failedAttemptsCount} unsuccessful login attempt(s). Please be careful: after {failedLockCount} failed attempts, your account will be temporarily locked for 60 minutes.",
  "p-login-password-reset-success": "Success!",
  "p-login-password-reset-error":
    "Something went wrong during password resetting, please try later",
  "p-login-title": "Log In",
  "p-login-label-email": "Email",
  "p-login-label-password": "Password",
  "p-login-email-placeholder": "Enter your email",
  "p-login-password-placeholder": "Enter your password",
  "p-login-forgot-password": "Forgot your password?",
  "p-login-submit-button": "Login",
  "p-login-2fa-prompt": "Enter 2FA code from your app:",
  "p-login-2fa-placeholder": "Enter the code...",
  "p-login-2fa-button": "Continue",
  "p-login-password-reset-success-title": "Email Sent!",
  "p-login-password-reset-success-message":
    "We have sent a password reset link to your email address. Please check your inbox and follow the instructions to reset your password.",
  "p-login-password-reset-alert":
    "If you do not see the email, please check your spam or junk folder. If you still haven't received it, try resubmitting your email address or contact our support team for assistance.",
  "p-login-back-to-login": "Back to login",
  "p-login-forgot-password-title": "Forgot Your Password?",
  "p-login-forgot-password-message":
    "Please enter the email address associated with your account and click 'Proceed.'",
  "p-login-password-reset-instructions":
    "We will send you an email with instructions on how to reset your password. If you do not receive the email within a few minutes, please check your spam folder or try again.",
  "p-login-enter-email": "Enter your email",
  "p-login-cancel": "Cancel",
  "p-login-proceed": "Proceed",
  "p-login-go-to-registration": "Don't have an account? Go to registration",
  "p-login-welcome-title": "Welcome to a new era of trading!",
  "p-login-welcome-message-1": "Securely connect to a world of digital assets.",
  "p-login-welcome-message-2":
    "Start trading with confidence and convenience on our trusted P2P platform.",
  "p-login-welcome-message-3":
    "Your gateway to seamless crypto transactions awaits.",
};
