export default {
  "c-home-banners-getToKnowUs": "Lernen Sie uns kennen",
  "c-home-banners-dirtyCryptocurrency":
    "Was tun, wenn Sie 'schmutzige' Kryptowährung erhalten",
  "c-home-banners-beginnersGuide":
    "Fangen Sie an, indem Sie unseren Anfängerleitfaden lesen",

  "c-home-historywidgets-24hours": "24 Stunden",
  "c-home-historywidgets-1week": "1 Woche",
  "c-home-historywidgets-1month": "1 Monat",
  "c-home-historywidgets-usdRatesHistory": "USD-Kursverlauf für",
  "c-home-historywidgets-change": "(Änderung?)",
};
