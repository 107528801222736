export default {
  "c-blog-editingform-pictureFormatError": "Picture must be jpg/png format!",
  "c-blog-editingform-pictureSizeError": "Picture size can not exceed 2MB!",
  "c-blog-editingform-uploadError": "Failed to upload image!",
  "c-blog-editingform-titleRequired": "Title is required",
  "c-blog-editingform-idCodeRequired": "URL code is required",
  "c-blog-editingform-descriptionRequired": "Description is required",
  "c-blog-editingform-previewImageRequired": "Preview image is required",
  "c-blog-editingform-tagsRequired": "Tags are required",
  "c-blog-editingform-bodyRequired": "Post text is required",
  "c-blog-editingform-metaDescriptionRequired": "Meta description is required",
  "c-blog-editingform-creatingNewPost": "Creating new post",
  "c-blog-editingform-title": "Title",
  "c-blog-editingform-active": "Active",
  "c-blog-editingform-idCode": "URL code",
  "c-blog-editingform-idCodeHint":
    "The code should be unique for each blog post, in lower case and with '-' instead of spaces",
  "c-blog-editingform-description": "Description",
  "c-blog-editingform-previewImage": "Preview Image",
  "c-blog-editingform-uploadHint1": "Drop file here or",
  "c-blog-editingform-uploadHint2": "click to upload",
  "c-blog-editingform-uploadHint3": "jpg/png files with a size less than 2MB",
  "c-blog-editingform-tags": "Tags",
  "c-blog-editingform-tagsPlaceholder": "Choose tags for your article",
  "c-blog-editingform-tagsHint":
    "You can choose from existing tags or create new ones by typing in the field and pressing Enter",
  "c-blog-editingform-postText": "Post text",
  "c-blog-editingform-metaDescription": "Meta description",
  "c-blog-editingform-cancel": "Cancel",
  "c-blog-editingform-save": "Save",

  "c-blog-item-readMore": "Read more",
};
