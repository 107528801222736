export default {
  "p-resetPassword-head-title": "Reset Password | Solid Swap Account Recovery",
  "p-resetPassword-head-description":
    "Reset your Solid Swap account password quickly and securely. Follow the steps to regain access to your account",
  "p-resetPassword-head-keywords":
    "reset password, account recovery, Solid Swap password reset",
  "p-resetPassword-success": "Your password has been reset successfully!",
  "p-resetPassword-loginInfo": "Now you can login to your account",
  "p-resetPassword-error":
    "Cannot reset your password, contact support please!",
  "p-resetPassword-validate-password-required": "Please enter your password",
  "p-resetPassword-validate-password-length":
    "Password length must be between 6 and 21 characters",
  "p-resetPassword-validate-password2-required": "Please confirm your password",
  "p-resetPassword-validate-password2-match": "Passwords do not match",
  "p-resetPassword-title": "Reset Your Password",
  "p-resetPassword-success-title": "Success!",
  "p-resetPassword-success-message":
    "Your password has been reset successfully! You will be redirected to the login page in 30 seconds",
  "p-resetPassword-goToLogin": "Go to login",
  "p-resetPassword-instructions":
    "Please enter your new password in the fields below. Make sure your password is at least 6 characters long and includes a mix of uppercase letters, lowercase letters, numbers, and special characters for added security.",
  "p-resetPassword-newPassword": "New password",
  "p-resetPassword-placeholder-password": "*****",
  "p-resetPassword-repeatPassword": "Repeat password",
  "p-resetPassword-placeholder-password2": "*****",
  "p-resetPassword-resetButton": "Reset Password",
};
