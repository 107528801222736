export default {
  "p-exchange-tos-agreement-intro":
    "Настоящее Пользовательское соглашение (далее – 'Соглашение') является электронным соглашением между Пользователем и сервисом SolidSwap и содержит правила работы на сайте SolidSwap по адресу",
  "p-exchange-tos-agreement-intro-continued":
    "(далее – 'Сайт') и условия доступа к услугам, предоставляемым Сайтом. Перед началом работы с Сайтом пользователь (далее – 'Пользователь') должен внимательно ознакомиться с условиями и принять это Соглашение. Если Пользователь не принимает Соглашение и не обязуется соблюдать все его условия, он должен немедленно прекратить использование Сайта.",
  "p-exchange-tos-agreement-updates":
    "Администрация Сайта оставляет за собой право по своему усмотрению обновлять и изменять текст Соглашения без уведомления Пользователей. Новая версия Соглашения вступает в силу немедленно после ее публикации на Сайте. Пользователь обязан самостоятельно следить за новыми версиями Соглашения. Продолжая использовать Сайт после вступления изменений в силу, Пользователь автоматически принимает новую версию Соглашения. Если Пользователь не согласен с условиями новой версии Соглашения, он должен деактивировать свой аккаунт, если доступен, и прекратить использование услуг сервиса.",
  "p-exchange-tos-terms-definitions": "1. Условия и определения",
  "p-exchange-tos-solid-swap-service":
    "1.1. Сервис SolidSwap — это платформа, предназначенная для проведения торговых операций с виртуальными цифровыми активами.",
  "p-exchange-tos-user-definition":
    "1.2. Пользователь – это физическое лицо, зарегистрированное на Платформе и согласившееся с условиями настоящего Соглашения. Пользователь подтверждает, что ему исполнилось 18 лет, законы его страны проживания не запрещают использование данного Сервиса, а также Пользователь не планирует осуществлять какую-либо незаконную деятельность через использование услуг SolidSwap.",
  "p-exchange-tos-title-sign":
    "1.3. Титульный знак — это условная единица платежной системы электронных и других видов валют, соответствующая расчетам электронных систем и указывающая на количество прав, соответствующих соглашению различных платежных систем и их владельца.",
  "p-exchange-tos-payment-system":
    "1.4. Платежная система — это онлайн-сервис, который осуществляет оплату товаров и услуг через электронные денежные переводы.",
  "p-exchange-tos-service-operations":
    "1.5. Услуги сервиса — это операции по вводу и выводу электронных валют, а также другие услуги, информация о которых размещена на сайте Сервиса.",
  "p-exchange-tos-payment-definition":
    "1.6. Платеж — это денежный перевод, осуществляемый с использованием платежной системы.",
  "p-exchange-tos-otc-platform":
    "1.7. OTC-платформа — это торговая платформа для поиска контрагентов и заключения внебиржевых сделок.",
  "p-exchange-tos-personal-data":
    "1.8. Персональные данные — информация, идентифицирующая личность, такая как имя, адрес, адрес электронной почты, номер мобильного телефона, информация о ставках и банковские реквизиты. Термин 'Персональные данные' не включает анонимные и/или агрегированные данные, которые не позволяют идентифицировать конкретного Пользователя.",
  "p-exchange-tos-agreement-definition":
    "1.9. Соглашение — это настоящее Пользовательское соглашение и его неотъемлемые части, с учетом всех дополнений и/или изменений, которые могут быть внесены в порядке, предусмотренном их условиями.",
  "p-exchange-tos-website-definition":
    "1.10. Сайт — это совокупность информации, текстов, графических элементов, дизайна, изображений, фотографий и видео, а также других результатов интеллектуальной деятельности, а также набор компьютерных программ, содержащихся в информационной системе, которые обеспечивают доступ к такой информации на",
  "p-exchange-tos-website-continued":
    ", включая любые поддомены, и если домен или материалы Сайта должны быть изменены по какой-либо причине, включает Сайт после любых таких изменений.",
  "p-exchange-tos-intellectual-property":
    "1.11. Интеллектуальная собственность — все материалы, опубликованные на Сайте, а также все Услуги, предоставляемые Сервисом, являются собственностью Сервиса и не подлежат использованию третьими лицами: публикации, распространению, дублированию.",
  "p-exchange-tos-application-definition":
    "1.12. Заявка — Пользователь делает предложение о покупке или продаже виртуальных цифровых активов с использованием предоставляемых Сервисом Услуг.",
  "p-exchange-tos-electronic-document":
    "1.13. Электронный документ — информация в электронном и цифровом виде.",
  "p-exchange-tos-suspicious-traffic":
    "1.14. Подозрительный трафик — совокупность действий, транзакций, активов и т. д., которые могут потенциально нарушать политики AML/CTF и KYC.",
  "p-exchange-tos-verification-procedures":
    "1.15. Процедуры верификации (KYC) — процедуры верификации в рамках стандартов борьбы с отмыванием денег — политика 'Знай своего клиента' (KYC).",
  "p-exchange-tos-persons-subject-restrictions":
    "1.16. Лица, подпадающие под ограничения, — это любое лицо в Соединенных Штатах и/или любое физическое лицо, включенное в Список граждан особых категорий или заблокированных лиц Министерства финансов США, Консолидированный список финансовых санкций ЕС или Список санкций Великобритании, а также граждане и резиденты из ограниченных юрисдикций (п. 1.17).",
  "p-exchange-tos-limited-jurisdiction":
    "1.17. Ограниченные юрисдикции включают: Соединенные Штаты Америки, Афганистан, Боснию и Герцеговину, Корейскую Народно-Демократическую Республику, Демократическую Республику Конго, Эритрею, Эфиопию, Гайану, Иран, Ирак, Лаосскую Народно-Демократическую Республику, Ливию, Сомали, Шри-Ланку, Судан, Сирию, Тринидад и Тобаго, Тунис, Уганду, Вануату, Украину, Великобританию, Китай (материковая часть), Сингапур, Гонконг, Канаду, Кубу, Узбекистан, Крым, Францию, Севастополь, Донецкую и Луганскую области.",
  "p-exchange-tos-introduction": "2. Введение",
  "p-exchange-tos-introduction-text":
    "2.1. Настоящее соглашение регулирует отношения между Пользователем и Сервисом в рамках услуг, предоставляемых Сервисом Пользователю, и отменяет все предыдущие соглашения между Сервисом и Пользователем по данному вопросу.",
  "p-exchange-tos-subject-agreement": "3. Предмет соглашения",
  "p-exchange-tos-subject-agreement-services":
    "3.1. Предметом настоящего Соглашения являются Услуги, предоставляемые Сервисом, которые описаны на сайте Сервиса.",
  "p-exchange-tos-subject-agreement-restrictions":
    "3.2. Сервис предлагает свои услуги всем заинтересованным Пользователям, за исключением лиц, подпадающих под ограничения (пункт 1.16).",
  "p-exchange-tos-payment-systems-responsibility":
    "3.3. Платежные системы и/или финансовые учреждения несут полную ответственность за средства, доверенные им Пользователем. Сервис не может быть стороной соглашения между Платежной системой и/или финансовым учреждением и Пользователем и ни в коем случае не несет ответственности за неправильное или несанкционированное использование Платежной системы Пользователем, а также за злоупотребление Пользователем функционалом Платежной системы. Взаимные права и обязанности Пользователя и Платежной системы и/или финансового учреждения регулируются соответствующими соглашениями.",
  "p-exchange-tos-operation-cannot-cancel":
    "3.4. Любая операция, инициированная для обмена средств Пользователя, а также любая другая операция, предлагаемая Сервисом Пользователю, не может быть отменена Пользователем.",
  "p-exchange-tos-rights-obligations": "4. Права и обязанности сторон",
  "p-exchange-tos-suspend-operation-authorities":
    "4.1. Сервис имеет право приостановить или отменить текущую операцию, если от уполномоченных органов поступит информация о незаконности владения Пользователем средствами и/или другая информация, делающая невозможным предоставление Сервисом услуг Пользователю.",
  "p-exchange-tos-suspend-refund-operation":
    "4.2. Сервис имеет право приостановить, отказать в возврате средств, отменить текущую операцию, если Пользователь нарушает условия настоящего Соглашения.",
  "p-exchange-tos-cancel-operation-title-return":
    "4.3. Сервис имеет право отменить текущую операцию и вернуть титульные знаки, введенные Пользователем, без объяснения причин.",
  "p-exchange-tos-prevent-illegal-activities":
    "4.4. Сервис обязуется принять все возможные и доступные меры для предотвращения попыток незаконной торговли, финансового мошенничества и отмывания денег с использованием услуг Сервиса. Эти меры включают: предоставление всевозможной помощи правоохранительным органам в поиске и задержании финансовых террористов, занимающихся незаконной деятельностью по отмыванию денег; предоставление информации уполномоченным органам в соответствии с действующим законодательством по обработке Сервиса; совершенствование Сервиса для предотвращения прямого или косвенного использования Сервиса в деятельности, противоречащей законодательству, направленному на борьбу с незаконной торговлей, финансовым мошенничеством и отмыванием денег.",
  "p-exchange-tos-suspicious-traffic-rights":
    "4.5. В случае обнаружения подозрительного трафика со стороны Пользователя Сервис оставляет за собой право передать по запросу правоохранительных органов всю информацию о личности, запросе обмена и полученной от Пользователя валюте.",
  "p-exchange-tos-accept-agreement":
    "4.6. Используя услуги, предоставляемые на сайте, Пользователь принимает и подтверждает, что он согласен с этими Правилами и политикой AML/CTF и KYC.",
  "p-exchange-tos-user-guarantee":
    "4.7. Используя услуги, предоставляемые на сайте, Пользователь гарантирует, что предоставляет полную, достоверную и актуальную информацию о себе, что использование услуг сервиса осуществляется только для личных целей, не нарушающих законы страны, в которой находится Пользователь, а также обязуется своевременно обновлять данные для поддержания их актуальности.",
  "p-exchange-tos-user-confidentiality":
    "4.8. Пользователь гарантирует конфиденциальность регистрационных данных и доступ к своему аккаунту, а также несет полную ответственность за все действия, происходящие под его аккаунтом, включая случаи взлома или несанкционированного доступа.",
  "p-exchange-tos-report-security-breach":
    "4.9. Пользователь обязуется незамедлительно сообщать о нарушении безопасности своего аккаунта.",
  "p-exchange-tos-block-account":
    "4.10. Сервис имеет право заблокировать аккаунт Пользователя в случае подозрения на незаконные действия, подозрения на взлом аккаунта или в случае нарушения правил Сервиса. Аккаунт Пользователя блокируется без права восстановления, если Пользователь создает препятствия в работе Сервиса.",
  "p-exchange-tos-rightful-owner":
    "4.11. Принимая условия настоящего Соглашения, Пользователь гарантирует, что он является законным владельцем средств и/или криптовалют.",
  "p-exchange-tos-understand-service-risks":
    "4.12. Принимая настоящее Пользовательское соглашение, Пользователь подтверждает, что понимает принципы работы Сервиса и принимает на себя полную ответственность за все риски и убытки, связанные с использованием Сервиса.",
  "p-exchange-tos-consent-data-processing":
    "4.13. Пользователь дает свое согласие на обработку Сервисом персональных данных, предоставленных во время регистрации на сайте, в процессе верификации, а также при сборе любой информации о клиенте на сайте.",
  "p-exchange-tos-personal-data-includes":
    "4.14. Персональные данные включают:",
  "p-exchange-tos-name": "Фамилия, Имя, Отчество;",
  "p-exchange-tos-photo-passport": "фото/скан паспорта;",
  "p-exchange-tos-other-id-documents":
    "другие удостоверяющие личность документы (индивидуально в каждом случае);",
  "p-exchange-tos-video-selfie": "видеоселфи с паспортом в руках;",
  "p-exchange-tos-email": "адрес электронной почты;",
  "p-exchange-tos-phone-number":
    "номер мобильного телефона (который можно использовать для связи с клиентом);",
  "p-exchange-tos-wallet-details":
    "реквизиты используемых кошельков (индивидуально в каждом случае);",
  "p-exchange-tos-card-number":
    "номер карты и/или банковского счета (индивидуально в каждом случае);",
  "p-exchange-tos-transactions-wallets": "транзакции и адреса кошельков.",
  "p-exchange-tos-processing-personal-data":
    "4.15. Обработка персональных данных включает запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), включая трансграничную, обезличивание, блокировку, удаление, уничтожение персональных данных, которые не относятся к специальным категориям, обработка которых требует письменного согласия Пользователя.",
  "p-exchange-tos-processing-purpose":
    "4.16. Обработка персональных данных осуществляется для выполнения сторонами своих обязательств по Соглашению, регистрации Пользователя на Сайте, предоставления Пользователю технической поддержки, подачи заявок, предоставления Услуг, рассмотрения обращений и претензий, отправки информационных и других сообщений на адрес электронной почты Пользователя.",
  "p-exchange-tos-fincen-rule":
    "4.17. В соответствии с финальным правилом Сети по борьбе с финансовыми преступлениями (FinCEN) согласно статье 31 Кодекса федеральных правил 1010.100(ff), которое обязывает каждую иностранную фирму, предоставляющую услуги по расчетам и наличным операциям, назначить представителя сервиса, проживающего в США, в качестве агента для проведения разбирательства в соответствии с требованиями BSA и его уставом, SolidSwap не предоставляет услуги обмена электронных валют гражданам США и пользователям, проживающим в Соединенных Штатах.",
  "p-exchange-tos-legal-prohibited":
    "4.18. Услуги, предоставляемые SolidSwap, могут быть юридически запрещены в некоторых юрисдикциях. Принимая условия использования SolidSwap, вы подтверждаете, что не являетесь резидентом такой юрисдикции.",
  "p-exchange-tos-aml-kyc-checks":
    "4.19. Сервис имеет право проводить проверки AML и KYC для соблюдения 'Пятой директивы по борьбе с отмыванием денег и финансированием терроризма (5AMLD)'.",
  "p-exchange-tos-kyt-aml-violation":
    "4.19.1. Если в результате проверки KYT/AML транзакций/активов криптовалюты будет выявлено нарушение 'политики AML/CTF и KYC', средства будут заблокированы, и потребуется дополнительная проверка пользователя (KYC) в соответствии с пунктом 8.5 настоящего соглашения, а также доказательства законности происхождения средств.",
  "p-exchange-tos-kyt-aml-non-return":
    "4.19.2. Если в результате проверки KYT/AML транзакций/активов криптовалюты будет выявлено нарушение 'политики AML/CTF и KYC' и/или ситуация подпадает под действие пункта 9.4.2 настоящего соглашения, то активы не могут быть обменены и возвращены.",
  "p-exchange-tos-services-service": "5. Услуги Сервиса",
  "p-exchange-tos-order-services":
    "5.1. Заказ услуг Сервиса и получение информации о ходе сделки Пользователем осуществляется исключительно через контакт с представителем Сервиса.",
  "p-exchange-tos-service-internal-schedule":
    "5.2. Сервис предлагает свои услуги в соответствии с установленным внутренним графиком работы сервиса.",
  "p-exchange-tos-questions-complaints":
    "5.3. Вопросы и жалобы по заявкам принимаются в течение 24 часов с момента регистрации. По истечении 24 часов заявки на обмен считаются надлежащим образом выполненными или не оплаченными, в зависимости от их статуса на момент истечения указанного срока.",
  "p-exchange-tos-suspicious-actions":
    "5.4. В случае любых подозрительных действий со стороны клиента, а также в случае использования пользователем подозрительных активов сервис может задержать оплату и потребовать дополнительной верификации личности и внутреннего анализа средств, использованных пользователем.",
  "p-exchange-tos-right-to-refuse":
    "5.5. Сервис оставляет за собой право в одностороннем порядке отказать в обмене.",
  "p-exchange-tos-restricted-citizens":
    "5.6. Услуги, предоставляемые SolidSwap, запрещены для использования гражданами, подпадающими под ограничения (пункт 1.16).",
  "p-exchange-tos-cost-services": "6. Стоимость услуг",
  "p-exchange-tos-tariffs":
    "6.1. Тарифы устанавливаются руководством Сервиса и публикуются на сайте Сервиса. Руководство Сервиса имеет право изменять тарифы без дополнительного уведомления.",
  "p-exchange-tos-expectations-tariffs":
    "6.2. Сервис не несет ответственности за ожидания клиента относительно изменения тарифных планов и стоимости услуг.",
  "p-exchange-tos-guarantees-responsibilities":
    "7. Гарантии и ответственность сторон",
  "p-exchange-tos-guarantee-fulfillment":
    "7.1. Сервис гарантирует выполнение своих обязательств перед Пользователем только в пределах сумм, доверенных Сервису Пользователем для проведения операции в рамках условий сервисной документации.",
  "p-exchange-tos-confidentiality-guarantee":
    "7.2. Сервис гарантирует и обеспечивает конфиденциальность информации о Пользователе и его операциях. Сервис может предоставить эту информацию только по запросу уполномоченных государственных органов, должностных лиц или представителей Платежных систем при наличии законных оснований.",
  "p-exchange-tos-efforts-technical-issues":
    "7.3. Сервис делает все возможное для обеспечения бесперебойной работы Сервиса, но не гарантирует отсутствие технических проблем, ведущих к полному или частичному приостановлению работы Сервиса. Сервис не несет ответственности за убытки, упущенную выгоду и другие затраты, понесенные Пользователем в результате невозможности доступа к сайту и услугам Сервиса.",
  "p-exchange-tos-damage-responsibility":
    "7.4. Сервис не несет ответственности за любой ущерб, нанесенный Пользователю, даже если его возможность была известна заранее. Сервис не несет ответственности за получение третьими лицами информации о деятельности Пользователя в случае взлома системы и получения данных третьими лицами.",
  "p-exchange-tos-no-responsibility-losses":
    "7.5. Сервис не несет никакой ответственности за убытки, упущенную выгоду и другие затраты Пользователя, возникшие вследствие ошибочных ожиданий Пользователя относительно тарифных ставок Сервиса, прибыльности сделок и других субъективных факторов.",
  "p-exchange-tos-aml-risk-score":
    "7.6. Сервис не несет ответственности за изменение риска AML Risk Score клиентских кошельков после проведения обменов.",
  "p-exchange-tos-compensation":
    "7.7. При посещении сайта Сервиса, использовании услуг Сервиса и осуществлении обмена Пользователь гарантирует возмещение убытков Сервису (управляющей компании, менеджерам и сотрудникам) в случае судебных исков или претензий, прямо или косвенно связанных с использованием Пользователем услуг Сервиса.",
  "p-exchange-tos-not-responsible-communication":
    "7.8. Пользователь соглашается с тем, что Сервис не несет ответственности в случае разрыва связи, сбоя в работе Интернета, а также в случае форс-мажорных обстоятельств.",
  "p-exchange-tos-guarantee-owner-funds":
    "7.9. Пользователь гарантирует, что он является владельцем или имеет законные основания для распоряжения средствами, использованными в его транзакциях, банковских переводах и т.д.",
  "p-exchange-tos-no-falsify-communication":
    "7.10. Пользователь обязуется не подделывать потоки связи, связанные с работой Сервиса.",
  "p-exchange-tos-force-majeure": "8. Форс-мажор",
  "p-exchange-tos-force-majeure-definition":
    "8.1. Стороны освобождаются от ответственности за полное или частичное неисполнение обязательств по настоящему Соглашению, если неисполнение обязательств стало следствием действий непреодолимой силы, а именно: пожар, наводнение, землетрясение, террористические акты, смена власти, гражданские волнения, DDOS-атаки на сервис, а также неработоспособность платежных систем, систем энергоснабжения, сетей связи и провайдеров Интернета.",
  "p-exchange-tos-error-during-payment":
    "8.2. В случае ошибки при оплате обмена Пользователь получит уведомление о статусе заявки и/или на указанный адрес электронной почты.",
  "p-exchange-tos-payment-after-deadline":
    "8.3. Если заявка была оплачена по истечении времени, отведенного на оплату заявки, сервис оставляет за собой право вернуть оплату за заявку за вычетом сетевой комиссии и затрат сервиса обратно Пользователю или произвести перерасчет по своему усмотрению.",
  "p-exchange-tos-not-responsible-assets-fiat":
    "8.3.1 Сервис не несет ответственности за активы, отправленные пользователем для обмена 'отдать' фиат, 'получить' криптовалюту после истечения срока для оплаты заявки.",
  "p-exchange-tos-not-responsible-assets-anonymous":
    "8.3.2 Сервис не несет ответственности за активы, отправленные пользователем с использованием анонимного метода, который скрывает адрес отправителя, так как они не будут зачислены на баланс кошелька обменного сервиса. В этом случае обмен и возврат будут невозможны.",
  "p-exchange-tos-deviation-exchange-rate":
    "8.4. Если курс заявки отклонился более чем на 3% от курса на биржах Bybit/Binance, включая случай технического сбоя на сайте, сервис обмена имеет право пересчитать заявку по текущему курсу на сайте или вернуть Пользователю средства за вычетом затрат сервиса и сетевой комиссии.",
  "p-exchange-tos-invalid-exchanges":
    "8.5. В случае, если Пользователь намеренно, непреднамеренно, а также в случае технического сбоя (в том числе со стороны сервиса SolidSwap) в результате действий злоумышленников или по другим причинам совершил обмен, который, в свою очередь, привел к убыткам для сервиса SolidSwap, при этом отклонение от рыночного курса в выполненной заявке составило более 3% от курса на биржах Bybit/Binance (это правило распространяется на любую сторону обмена), заявки, выполненные на указанных выше условиях, считаются недействительными. Сервис имеет право возместить понесенный ущерб за счет удержания любых будущих средств Пользователя в рамках взаимодействия с Сервисом.",
  "p-exchange-tos-suspicious-assets":
    "8.6. В случае обнаружения подозрительных активов/средств, нарушающих соглашение AML сервиса, и подозрительных/мошеннических действий со стороны Пользователя транзакция блокируется Службой безопасности на срок до 180 дней для дальнейшего расследования.",
  "p-exchange-tos-unblocking-procedure":
    "Для начала процедуры разблокировки необходимо пройти упрощенную верификацию пользователя (KYC) в соответствии с пунктом 4.19 настоящего соглашения.",
  "p-exchange-tos-send-photo-passport":
    "Фотография или скан вашего паспорта, либо других удостоверяющих личность документов (на ваш выбор);",
  "p-exchange-tos-send-phone-number":
    "Номер мобильного телефона, который может быть использован для связи с вами при необходимости (по желанию);",
  "p-exchange-tos-send-video-selfie":
    "Видео-селфи с удостоверяющим личность документом;",
  "p-exchange-tos-record-video":
    "Запишите короткое видео хорошего качества без прерываний, четко произнеся следующие данные:",
  "p-exchange-tos-record-full-name":
    "Фамилия, имя, отчество, серия и номер документа, удостоверяющего личность, удерживая его рядом с лицом;",
  "p-exchange-tos-service-name": "Название нашего сервиса и дата записи видео;",
  "p-exchange-tos-application-number": "Номер заявки и дата её создания;",
  "p-exchange-tos-show-wallet":
    "Покажите кошелек/счет/другое, откуда именно была произведена оплата/транзакция, валюту и сумму. Название и адрес (URL) сайта должны быть читаемы, если транзакция была произведена из мобильного приложения.",
  "p-exchange-tos-write-explanation":
    "Напишите в свободной форме от руки на листе бумаги краткое объяснение законности происхождения средств, а также о том, что вы отправили нам с вашего кошелька (адрес) на ваш кошелек (адрес и хэш транзакции), и что вы являетесь владельцем активов, а не третье лицо. Напишите адрес, на который вы просите вернуть активы.",
  "p-exchange-tos-specify-address":
    "Укажите адрес/счет кошелька, на который необходимо вернуть активы.",
  "p-exchange-tos-information-deadline":
    "Мы ожидаем вышеуказанную информацию от вас не позднее чем через 48 часов с момента получения данного электронного письма.",
  "p-exchange-tos-no-unblocking-without-data":
    "В отсутствие запрашиваемых данных SolidSwap не сможет начать процедуру разблокировки ваших активов.",
  "p-exchange-tos-blocking-period-expiration":
    "8.6.1. По истечении периода блокировки (180 дней) с даты прохождения верификации личности (KYC) пользователь должен самостоятельно обратиться в службу поддержки клиентов по официальной почте, не позднее чем через 30 дней.",
  "p-exchange-tos-incorrect-details":
    "8.7. Сервис не несет никакой финансовой ответственности перед Пользователем, если Пользователь указал некорректные реквизиты в заявке на получение средств от Сервиса, а Сервис произвел расчет с Пользователем, отправив платеж по реквизитам, указанным в заявке.",
  "p-exchange-tos-payment-delay":
    "8.8. В случае сбоя в банке/платежной системе третьей стороны платежи могут быть задержаны на срок до 5 банковских дней по причинам, не зависящим от сервиса.",
  "p-exchange-tos-deposit-outside-application":
    "8.9. Депозит, произведенный вне заявки, может не быть зачислен или потерян. Оплата средств, отправленных по устаревшим реквизитам, осуществляется с комиссией в размере 5%.",
  "p-exchange-tos-non-current-details":
    "8.10. Сервис не несет ответственности, если Пользователь указал реквизиты для платежа, отличающиеся от банка, указанного в направлении обмена. Оплата средств по некорректным банковским реквизитам производится с комиссией в размере 5% в течение 48 часов.",
  "p-exchange-tos-fiat-exchange": "9.1. Обмен фиатных средств",
  "p-exchange-tos-fiat-exchange-amount-limit":
    "9.1.1. Обмены на сумму до 150 долларов США или равную этой сумме осуществляются без задержек. Если вы впервые используете свой аккаунт в платежной системе для оплаты на нашем сервисе и обмениваете сумму более 150 долларов США, ваш запрос будет выполнен через 2 часа после оплаты.",
  "p-exchange-tos-subsequent-exchanges":
    "9.1.2. Последующие обмены на сумму выше 150 долларов США с тем же номером счета в платежной системе и аккаунтом пользователя будут произведены мгновенно при условии, что вы зарегистрировались перед первым обменом.",
  "p-exchange-tos-additional-checks":
    "9.1.3. Если с момента первого обмена прошло менее 24 часов, и общая сумма обменов по банковским реквизитам превышает 500 долларов США, может быть установлена дополнительная проверка с задержкой до 48 часов по решению службы безопасности.",
  "p-exchange-tos-suspicious-actions-fiat":
    "9.1.4. В случае любых подозрительных действий со стороны клиента сервис оставляет за собой право запросить данные аккаунта, которые подтвердят, что аккаунт принадлежит клиенту.",
  "p-exchange-tos-crypto-exchange": "9.2. Обмен криптовалют",
  "p-exchange-tos-crypto-auto-manual":
    "9.2.1. Заявки, выполненные сервисом в автоматическом режиме, могут быть обработаны в течение 12 часов. Заявки, выполненные сервисом в ручном режиме, могут быть обработаны в течение 36 часов.",
  "p-exchange-tos-sale-cryptocurrencies":
    "9.2.2. Заявки на продажу всех криптовалют, представленных на сайте сервиса, обрабатываются в момент получения транзакцией установленного индивидуального минимума подтверждений (минимум устанавливается сервисом SolidSwap).",
  "p-exchange-tos-recalculate-rate":
    "9.2.3. Сервис имеет право пересчитать вашу заявку в благоприятную для обменного пункта сторону (как по текущему, так и по курсу, который был на момент создания заявки), если транзакция не получила необходимое количество сетевых подтверждений в течение времени, отведенного для заявки.",
  "p-exchange-tos-btc-confirmations":
    "9.2.4. Оплата обмена криптовалюты BTC производится после 3-го подтверждения в сети Биткойн. Курс в заявке остается фиксированным, если оплата была произведена Пользователем в течение времени, отведенного для оплаты заявки в данном направлении, и транзакция появилась в сети в это время, а криптовалюта BTC получила 1 подтверждение в сети Биткойн в течение 15 минут. Если 1-е подтверждение получено через 15 минут, заявка может быть пересчитана по текущему обменному курсу.",
  "p-exchange-tos-fiat-crypto-fixed-rate":
    "9.2.5. Курс обмена в направлениях 'отдать' фиат, 'получить' криптовалюту фиксируется в течение времени, отведенного для оплаты. Заявки по этим направлениям пересчитываются по текущему курсу, если клиент не успел перевести средства на кошелек/карту, указанную в его заявке, в течение этого времени.",
  "p-exchange-tos-crypto-sending":
    "9.2.6. Когда клиент покупает любую криптовалюту, представленную на сайте сервиса, отправка криптовалюты сервисом может занять от 5 минут до 12 часов. Скорость подтверждения транзакции в сети не зависит от сервиса.",
  "p-exchange-tos-high-load-crypto-network":
    "9.2.7. В случае высокой загруженности или технических проблем в сети криптовалют, представленных на сайте сервиса, по причинам, не зависящим от Сервиса, время доставки средств на счет получателя может достигать 240 часов.",
  "p-exchange-tos-recalculation-notification":
    "9.2.8. Перерасчет суммы заявки производится сервисом в соответствии с условиями настоящего соглашения в автоматическом режиме, о чем сервис уведомляет клиента по электронной почте.",
  "p-exchange-tos-blocked-account-responsibility":
    "9.2.9. Сервис не несет ответственности за средства, переведенные на заблокированную карту клиента или заблокированный счет на криптовалютной бирже или другой централизованной платформе, а также в случае, если клиент был заблокирован после того, как сервис перевел на него активы по запросу.",
  "p-exchange-tos-memo-tag-required":
    "9.2.10. Обмен монет, для которых требуется MEMO или TAG, при отправке на наш кошелек должен обязательно включать эти значения MEMO или TAG. Средства, отправленные без использования MEMO или TAG, не подлежат зачислению и возврату.",
  "p-exchange-tos-smart-contract-processing":
    "9.2.11. Монеты, отправленные со смарт-контракта, обрабатываются вручную, время обработки составляет 2 часа. После отправки монет необходимо сообщить оператору в онлайн-чате номер вашей заявки и ХЭШ транзакции.",
  "p-exchange-tos-refund": "9.3. Возврат средств",
  "p-exchange-tos-refund-decision":
    "9.3.1. Возврат средств возможен только по решению Сервиса.",
  "p-exchange-tos-refund-application":
    "9.3.2. Для подачи заявки на возврат Пользователь должен отправить электронное письмо на адрес электронной почты Сервиса:",
  "p-exchange-tos-refund-minus-commission":
    "9.3.3. Возврат осуществляется за вычетом комиссии платежной системы за перевод и за вычетом затрат Сервиса на обмен в размере 5% от суммы обмена. Исключения составляют случаи, описанные в пункте 4.19 настоящего Соглашения.",
  "p-exchange-tos-refund-review":
    "9.3.4. Заявки на возврат средств рассматриваются Сервисом в течение 48 часов.",
  "p-exchange-tos-refund-within-48-hours":
    "9.3.5. По запросу Сервис производит возврат средств в течение 48 часов. (исключение п. 4.19)",
  "p-exchange-tos-deposit-below-minimum":
    "9.3.6. Депозиты, сделанные на сумму менее минимальной, не зачисляются и не подлежат возврату.",
  "p-exchange-tos-assets-return-clause-419":
    "9.3.7. При обмене активов, указанных в пункте 4.19 настоящего Соглашения, после прохождения процедур AML и KYC Сервис оставляет за собой право не предоставлять услугу обмена Пользователю и вернуть средства с соблюдением всех процедур внутренних правил возврата.",
  "p-exchange-tos-return-aml-failed-assets":
    "9.4. Возврат активов, не прошедших проверку AML.",
  "p-exchange-tos-return-blocked-assets":
    "9.4.1. Возврат заблокированных активов, не прошедших проверку AML, возможен только после прохождения верификации личности согласно пункту 8.6 настоящего Соглашения и выполнения пункта 8.6.1.",
  "p-exchange-tos-request-from-authorities":
    "9.4.2. Возврат активов невозможен, если по этим активам был получен запрос от компетентных органов и других ведомств, включая любые юрисдикции, в этом случае заблокированный актив может быть использован в качестве вещественного доказательства по делу.",
  "p-exchange-tos-refund-5-percent-commission":
    "9.4.3. При выполнении пунктов 8.6 и 8.6.1 возврат клиенту производится с удержанием комиссии в размере 5%.",
  "p-exchange-tos-no-return-criminal-wallets":
    "9.4.4. Криптовалютные активы не подлежат возврату и обмену, если по результатам проверки системы AML выявлены нарушения",
  "p-exchange-tos-dark-market": "DARK MARKET",
  "p-exchange-tos-dark-service": "DARK SERVICE",
  "p-exchange-tos-scam": "SCAM",
  "p-exchange-tos-stolen": "STOLEN",
  "p-exchange-tos-mixing": "MIXING(MIXER)",
  "p-exchange-tos-sextortion": "SEXTORTION",
  "p-exchange-tos-ransomware": "RANSOMWARE",
  "p-exchange-tos-hack": "HACK",
  "p-exchange-tos-phishing": "PHISHING",
  "p-exchange-tos-terrorism-financing": "TERRORISM FINANCING",
  "p-exchange-tos-fraud": "FRAUD",
  "p-exchange-tos-blacklist": "BLACKLIST",
  "p-exchange-tos-under-investigation": "STILL UNDER INVESTIGATION",
  "p-exchange-tos-cybercrime-organization": "CYBERCRIME ORGANIZATION",
  "p-exchange-tos-narcotics": "NARCOTICS",
  "p-exchange-tos-child-abuse": "CHILD ABUSE",
  "p-exchange-tos-human-trafficking": "HUMAN TRAFFICKING",
  "p-exchange-tos-sanctions": "SANCTIONS",
  "p-exchange-tos-change-recipient-details":
    "9.5. Изменение реквизитов получателя заявки",
  "p-exchange-tos-change-recipient-details-procedure":
    "9.5.1. Для изменения реквизитов получателя Пользователь подает заявку на замену реквизитов в письме, отправленном на электронную почту",
  "p-exchange-tos-acceptance-terms": "10. Принятие условий обслуживания",
  "p-exchange-tos-acceptance-terms-text":
    "10.1. Принятие условий 'Пользовательского соглашения' при создании заявки автоматически считается сервисом согласием со всей документацией, регулирующей работу сервиса.",
};
