export default {
  "c-offers-table-loginWarning":
    "Vous devez vous connecter pour créer une commande",
  "c-offers-table-toggleError":
    "Quelque chose a mal tourné lors du basculement de l'offre",
  "c-offers-table-insufficientBalance":
    "Vous n'avez pas assez de solde pour activer cette offre",
  "c-offers-table-goToWallet": "aller au portefeuille",
  "c-offers-table-seller": "Vendeur",
  "c-offers-table-buyer": "Acheteur",
  "c-offers-table-price": "Prix",
  "c-offers-table-availableLimit": "Limite disponible",
  "c-offers-table-available": "Disponible",
  "c-offers-table-offerLimits": "Limites d'offre",
  "c-offers-amount-unlimited": "le montant de l'offre est illimité",
  "c-offers-table-paymentOptions": "Options de paiement",
  "c-offers-table-more": "plus",
  "c-offers-table-active": "actif",
  "c-offers-table-disabled": "désactivé",
  "c-offers-table-edit": "modifier",
  "c-offers-table-delete": "supprimer",
  "c-offers-table-buy": "Acheter",
  "c-offers-table-sell": "Vendre",
};
