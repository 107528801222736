export default {
  "c-Withdrawal-success": "Successful withdrawal!",
  "c-Withdrawal-error": "Error happened while withdrawal, please try later",
  "c-Withdrawal-enter-valid-amount": "Enter a valid amount",
  "c-Withdrawal-enter-valid-address": "Enter a valid public address",
  "c-Withdrawal-enter-tfa-code": "Enter your 2FA code from the app",
  "c-Withdrawal-title": "Withdrawal of",
  "c-Withdrawal-message-1":
    "Please fill out the form below to proceed with your withdrawal",
  "c-Withdrawal-message-2":
    "After you proceed with the withdrawal, you will have a several-minute interval to cancel your transaction from the transaction history page",
  "c-Withdrawal-no-fee":
    "There is no withdrawal fee at this moment, you will only be charged the network fee, which depends on its load and will be deducted from the withdrawal amount.",
  "c-Withdrawal-amount-label": "Amount to Withdraw",
  "c-Withdrawal-available": "Available you have",
  "c-Withdrawal-withdraw-all": "Withdraw All",
  "c-Withdrawal-commission": "Commission:",
  "c-Withdrawal-address-label": "Wallet Public Address",
  "c-Withdrawal-security-tip-title": "Security Tip:",
  "c-Withdrawal-security-tip-desc":
    "Always double-check the address before submitting. Make sure you recognize the recipient.",
  "c-Withdrawal-tfa-label": "Two-Factor Authentication Code",
  "c-Withdrawal-scam-warning-title": "Beware of Scams:",
  "c-Withdrawal-scam-warning-desc":
    "Never share your keys or 2FA codes with anyone. Official support will never ask for this information.",
  "c-Withdrawal-cancel": "Cancel",
  "c-Withdrawal-continue": "Continue",
};
