export default {
  "p-privacy-policy-title": "Privacy Policy | Solid Swap",
  "p-privacy-policy-meta-description":
    "Read Solid Swap's privacy policy to understand how we handle your personal information and protect your privacy",
  "p-privacy-policy-meta-keywords":
    "privacy policy, data protection, personal information, Solid Swap privacy",
  "p-privacy-policy-header-title": "Privacy Policy",
  "p-privacy-policy-download-link": "download as file",
  "p-privacy-policy-alert":
    "This Customer Agreement may be published in different languages. In case of any discrepancy, please refer to the English version",
  "p-privacy-policy-last-updated": "Last updated:",
  "p-privacy-policy-intro-1":
    "This Privacy Policy, as amended or otherwise changed from time to time (the “Privacy Policy” ), explains the manner in which SOLIDSWAP, Ltd. (“SOLIDSWAP”, “we”, “us” or “our”) collects, uses, maintains and discloses user information obtained through the",
  "p-privacy-policy-site": "solidswap.net",
  "p-privacy-policy-intro-2":
    "website (the “Site” ) and through the provision of the services (the “Services” ). SOLIDSWAP, Ltd. is the data controller of your Personal Data.",
  "p-privacy-policy-commitment":
    "SOLIDSWAP and its Affiliates are committed to making every effort to protect your privacy. SOLIDSWAP uses the information collected about you to fulfill its contractual obligations and improve customer service.",
  "p-privacy-policy-read":
    "Please read this document carefully to understand our position and policies regarding your Personal Information and how we will use it.",
  "p-privacy-policy-read-cookie":
    "This Privacy Policy should be read in conjunction with our Cookie Policy.",
  "p-privacy-policy-data-we-collect": "1. WHAT DATA WE COLLECT ABOUT YOU",
  "p-privacy-policy-data-we-collect-intro":
    "We may collect and process the following data about you:",
  "p-privacy-policy-info-provided": "Information that you provide to us",
  "p-privacy-policy-info-provided-description":
    "You may provide us with information about yourself by filling out forms on the Site or by providing such information to us by telephone, email, video conference or any other means. This information includes Personal data specified when registering on the Site, when subscribing to our Services, opening an Account and verifying the identity of the Account owner or persons entitled to dispose of it, as well as the payment instruments used by them, searching for a product, using any of the Services that provides our Site when you place Orders on our Site, when you participate in Chat discussions or use any other communication features on our Site, when you participate in competitions, promotions or surveys, and when you report to us any problems in the operations of the Site.",
  "p-privacy-policy-example":
    "For example, the data you provide to us may include your:",
  "p-privacy-policy-first-last-name": "first and last name;",
  "p-privacy-policy-photos-videos":
    "photographs and videos that you can send to us for verification purposes in accordance with our AML/CTF and KYC Policy;",
  "p-privacy-policy-residence-citizenship":
    "residence address and citizenship;",
  "p-privacy-policy-sex": "sex;",
  "p-privacy-policy-id-documents":
    "passport, driver's license and any other government-issued identification document;",
  "p-privacy-policy-tax-residency": "tax residency;",
  "p-privacy-policy-phone-email": "telephone number and email address;",
  "p-privacy-policy-login-nickname":
    "login and nickname for access to the SOLIDSWAP Platform;",
  "p-privacy-policy-bank-info":
    "bank information, including account and payment details, as well as FATCA/CRS information;",
  "p-privacy-policy-dob": "date and place of birth;",
  "p-privacy-policy-education": "education;",
  "p-privacy-policy-work-experience":
    "work experience and place of employment;",
  "p-privacy-policy-official-position": "official position;",
  "p-privacy-policy-income-sources":
    "sources and/or amounts of income/account replenishment;",
  "p-privacy-policy-account-status":
    "information on the status of your accounts opened with banks and/or payment systems;",
  "p-privacy-policy-support-requests":
    "requests and history of correspondence with SOLIDSWAP support and other Users of the SOLIDSWAP Platform;",
  "p-privacy-policy-trade-balances": "trade balances and activity;",
  "p-privacy-policy-location-log":
    "location information and log data that is generated when you use the SOLIDSWAP Platform;",
  "p-privacy-policy-additional-info":
    "other personal information as may be required by our AML/CTF and KYC Policy to comply with regulatory compliance obligations.",
  "p-privacy-policy-no-sensitive-data":
    "SOLIDSWAP does not collect or process sensitive personal data of Users, such as race or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, genetic or biometric data, information about health, sex life or sexual orientation.",
  "p-privacy-policy-info-collected": "Information that we collect about you",
  "p-privacy-policy-info-collected-description":
    "Every time you visit our Site and use our Services, we may collect about you (including automatically) the following information:",
  "p-privacy-policy-info-collected-a":
    "(a) technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, your Personal Account login information, your browser type and version, time zone settings, browser plug-in types and versions, operating system and platform;",
  "p-privacy-policy-info-collected-b":
    "(b) data about your visit to the Site: including full information about the uniform resource locator (URL), the route of navigation on the Site and when you navigate to/exit from the Site (including date and time), about the products and Services that you viewed or searched for, page response time, data loading errors, duration of stay on certain pages, activity on the page (information such as scrolling, mouse movements, clicks), methods used to exit the page, and telephone numbers used to contact the customer support center;",
  "p-privacy-policy-info-collected-c":
    "(c) geolocation data: we may collect real-time location information from your device at any time when you download or use our services; we may use this information to optimize our services;",
  "p-privacy-policy-info-collected-d":
    "(d) financial information and information about the use of the Services and opportunities provided by our Site, including information about ongoing transactions and their participants, including margin transactions, information about the status of Accounts and the movement of funds on them, accrued commissions, information about Account Deposits and Withdrawals from Accounts opened on our Platform.",
  "p-privacy-policy-info-from-others":
    "Information we receive from other sources",
  "p-privacy-policy-info-from-others-description":
    "We may receive information about you if you use any of our other sites or services. We also work closely with third parties (including our business partners, technical, payment and support subcontractors, advertising networks, analytics providers, due diligence providers, credit reporting agencies) and may receive information about you from them. Such information may include:",
  "p-privacy-policy-info-from-others-a":
    "(a) information about the financial institutions you use to Deposit and/or Withdraw Funds;",
  "p-privacy-policy-info-from-others-b":
    "confirmation by credit agencies of the information you provided to us;",
  "p-privacy-policy-store-pending":
    "Your Personal Data, such as email address and login, which you provide to us during registration on the SOLIDSWAP Platform, is stored by us regardless of whether you complete the registration process or not. The same applies to your Personal Data during the pending verification process in accordance with the AML/CTF and KYC Policy.",
  "p-privacy-policy-cookies": "2. USE OF COOKIES",
  "p-privacy-policy-cookies-description":
    "Please read our Cookie Policy for information about the use of cookies.",
  "p-privacy-policy-use-of-data": "3. WHAT DO WE USE YOUR PERSONAL DATA FOR?",
  "p-privacy-policy-use-of-data-intro":
    "We will use your Personal Data for the following purposes:",
  "p-privacy-policy-providing-services": "Providing our Services",
  "p-privacy-policy-fulfill-obligations":
    "to fulfill obligations under any contracts concluded with you, as well as to provide you with the information, products and Services you request;",
  "p-privacy-policy-security":
    "to ensure the security of our Site, our Services, and your Account;",
  "p-privacy-policy-admin-site":
    "to administer our Site and conduct internal operations, including troubleshooting, data analysis, testing, collecting statistical data and conducting surveys;",
  "p-privacy-policy-interactive-features":
    "to provide you with access to interactive features of the Site if you wish to use them;",
  "p-privacy-policy-support":
    "to provide support, to respond to and resolve your complaints and questions related to the use of our Services and the capabilities of our Site.",
  "p-privacy-policy-informing": "Informing you about our products and Services",
  "p-privacy-policy-inform-products":
    "to inform you about our other existing and/or new products and Services, similar to those products and Services that you have already purchased or searched for information about;",
  "p-privacy-policy-inform-changes":
    "to inform you about changes to our products and Services.",
  "p-privacy-policy-improve": "Helping improve our products and Services",
  "p-privacy-policy-improve-site":
    "to improve our Site and to ensure that our content is as user-friendly as possible on your computer operating system;",
  "p-privacy-policy-evaluate-ads":
    "to evaluate and understand the effectiveness of advertising materials that we provide to you and other Users, as well as to provide you with the necessary advertising information;",
  "p-privacy-policy-effective-content":
    "to ensure that the content of our Site is presented to you in the most effective manner and can be used from your computer.",
  "p-privacy-policy-protect-interests": "Protection of corporate interests",
  "p-privacy-policy-risk-management": "for risk management;",
  "p-privacy-policy-it-security":
    "to protect and control our IT-systems, to prevent crime;",
  "p-privacy-policy-evaluate-services":
    "to evaluate the use of our products and Services for their further development;",
  "p-privacy-policy-internal-business":
    "for internal business and research purposes, such as communicating with you regarding our products and Services;",
  "p-privacy-policy-analyze-data":
    "to anonymously analyze data using web beacons and pixel tags to ensure that our products and Services are appropriate for the market in which we operate.",
  "p-privacy-policy-legal-procedures": "Providing legal procedures",
  "p-privacy-policy-law-enforcement":
    "to respond to requests from law enforcement agencies;",
  "p-privacy-policy-legal-claims":
    "to initiate and/or respond to legal claims;",
  "p-privacy-policy-disputes":
    "to investigate and/or resolve disputes with you.",
  "p-privacy-policy-legal-compliance": "Legal Compliance",
  "p-privacy-policy-verify-users":
    "to verify and authenticate the identity of Users opening and managing Accounts in order to prevent fraud and related illegal activities;",
  "p-privacy-policy-aml-compliance":
    "to comply with the legal requirements of our AML/CTF and KYC Policy and other relevant laws and regulations;",
  "p-privacy-policy-notify-changes":
    "to notify our Users about changes to the policies and terms of use of the SOLIDSWAP Platform.",
  "p-privacy-policy-marketing-third-parties": "Marketing and third parties",
  "p-privacy-policy-new-products":
    "to provide you with information about new products or services introduced by SOLIDSWAP or our third party providers that we think may be of interest to you.",
  "p-privacy-policy-disclosure": "4. DISCLOSURE OF YOUR PERSONAL DATA",
  "p-privacy-policy-disclosure-intro":
    "We may share your Personal Information within the SOLIDSWAP Group, which includes our subsidiaries and affiliates, our ultimate parent companies, and their subsidiaries.",
  "p-privacy-policy-disclosure-third-parties-intro":
    "We may transfer your Personal Data to third parties, including:",
  "p-privacy-policy-disclosure-third-parties-a":
    "(i) business partners, suppliers, subcontractors, service providers who carry out appropriate legal and financial checks to fulfill the contractual obligations we enter into with them or with you;",
  "p-privacy-policy-disclosure-third-parties-b":
    "(ii) marketing networks that require certain data to select and promote relevant advertising.",
  "p-privacy-policy-disclosure-no-sensitive":
    "We do not disclose sensitive information about our customers to marketing companies, but we may provide them with aggregate information about our Users (for example, we may tell them that 500 women under the age of 30 clicked on their advertisement on any given day).",
  "p-privacy-policy-disclosure-aggregate":
    "We may also use such aggregated information to help marketing companies reach the audience they want to target (for example, women living in a particular region). We may use the Personal Data we collect from you to enable us to comply with the wishes of our advertisers by displaying their advertisements to specific target audiences, analytics and search providers who help us improve and optimize the performance of our Site.",
  "p-privacy-policy-disclosure-other-cases-intro":
    "We may also disclose your Personal Data to third parties in the following cases:",
  "p-privacy-policy-disclosure-other-cases-a":
    "(a) if we sell or buy any business or assets, Users' personal information may be disclosed to a potential buyer or seller;",
  "p-privacy-policy-disclosure-other-cases-b":
    "(b) if SOLIDSWAP or all of its assets are purchased by a third party, Users' Personal Data will be transferred as one of the assets;",
  "p-privacy-policy-disclosure-other-cases-c":
    "(c) if we are required to disclose or transfer User Personal Data in accordance with any applicable law or in order to comply with relevant court orders, requests from judicial and law enforcement agencies, or to force Users to comply with the terms of agreements entered into with us;",
  "p-privacy-policy-disclosure-other-cases-d":
    "(d) in the event that it is necessary to protect our rights and property or guarantee the safety of SOLIDSWAP, our Users or other parties (this includes sharing information with relevant organizations to prevent fraud and reduce the likelihood of default).",
  "p-privacy-policy-security-storage":
    "5. SECURITY AND STORAGE OF PERSONAL DATA",
  "p-privacy-policy-security-measures":
    "We use security measures to ensure the confidentiality of your Personal Data and protect it from loss, misuse, alteration or destruction. Only authorized SOLIDSWAP personnel have access to your Personal Information, and these employees are required to treat this information as confidential. Current security measures will be reviewed from time to time in accordance with new legal provisions and technical innovations.",
  "p-privacy-policy-storage":
    "The information we collect from you may be transferred to and stored outside the European Economic Area (“EEA”) for the purpose of providing our Services to you. Information about you may be processed by our employees or employees of service providers located outside the EEA. Where your Personal Data is stored or otherwise processed by such third parties outside the EEA, we will ensure that we notify such third parties of our obligations under this Privacy Policy and enter into a relationship with them that will subject them to obligations in relation to your Personal Data, provided that: that they have the appropriate level of security specified in this Privacy Policy. Such employees may, among other things, process your requests, payment information and provide technical support. By providing us with your Personal Data, you consent to the transfer, storage and processing of your Personal Data. In turn, we will take all necessary measures to ensure that your Personal Data is processed in compliance with international security standards and in accordance with the provisions of this Privacy Policy.",
  "p-privacy-policy-transmission-security":
    "Although the transmission of information via the Internet is not completely secure, we will try to protect your Personal Data. With this in mind, we cannot, however, guarantee the security of the channels you use to transmit your personal information to our Site. In this regard, you are solely responsible for possible failures in the transmission of your Personal Data to the Site. Once we receive your Personal Data, we will use strict procedures and all necessary technical and security measures to prevent unauthorized access to your Personal Data.",
  "p-privacy-policy-grounds": "6. GROUNDS FOR PROCESSING PERSONAL DATA",
  "p-privacy-policy-grounds-intro":
    "We collect and process your Personal Data only when we have legal grounds for such processing. Legal grounds include:",
  "p-privacy-policy-consent": "your consent;",
  "p-privacy-policy-agreement-terms":
    "the terms of the agreement between you and SOLIDSWAP;",
  "p-privacy-policy-legal-obligations":
    "compliance with legal obligations where SOLIDSWAP is required to request/receive and process and store your personal data in order to comply with applicable laws, such as laws relating to anti-money laundering and anti-terrorism financing;",
  "p-privacy-policy-legal-interests":
    "legal interests, for example where processing is necessary to protect you or us from certain threats such as fraud, security threats, etc.;",
  "p-privacy-policy-business-compliance":
    "compliance with applicable laws for conducting our business (quality of services provided, identification of needs, preparation of reports) in accordance with regulatory requirements;",
  "p-privacy-policy-corporate-transactions":
    "carrying out corporate transactions (sale of shares/shares of participants, mergers, acquisitions), etc.",
  "p-privacy-policy-withdraw-consent":
    "In cases where the basis for the processing of Personal Data is your consent, you have the right to withdraw it at any time.",
  "p-privacy-policy-rights": "7. YOUR RIGHTS",
  "p-privacy-policy-rights-intro":
    "By contacting us, you can exercise the following rights:",
  "p-privacy-policy-rights-erase":
    "the right to erase all or part of your Personal Data (considering, however, our regulatory requirements for retaining your Personal Data as set out in Article 9 of this Privacy Policy);",
  "p-privacy-policy-rights-correct":
    "the right to change or correct your Personal Data, in particular when it is entered incorrectly;",
  "p-privacy-policy-rights-object":
    "the right to object to or restrict the processing of your Personal Data;",
  "p-privacy-policy-rights-access":
    "the right to access your Personal Data and/or a copy of your Personal Data that you provided to SOLIDSWAP in a machine-readable format.",
  "p-privacy-policy-rights-marketing":
    "In addition, you have the right to prohibit the use of your Data for marketing purposes. Before requesting your Data, we will generally seek your consent to disclose it to third parties for marketing purposes. You can exercise the right to object to the processing of your Data for marketing purposes by checking the appropriate box in the form. You can also exercise this right by contacting us at any time.",
  "p-privacy-policy-rights-limitations":
    "Please note that the exercise of some of your rights, depending on the situation, may limit, complicate or completely eliminate the possibility of further cooperation with us.",
  "p-privacy-policy-rights-active-links":
    "Our Site may contain active links to/from the websites of partners, advertisers and Affiliates. If you follow any active links, please note that other websites have their own privacy policies and we are not responsible for the provisions of these policies. Please check the privacy policies of these websites before submitting your Personal Information to them.",
  "p-privacy-policy-access": "8. ACCESS TO PERSONAL DATA",
  "p-privacy-policy-access-intro":
    "You have the right to access the information we hold about you. You can exercise the right of access in accordance with your written request sent to the email address below, we will provide you with the information that we store about you. In the event that your request is visibly unfounded or excessive, or you request additional copies of your Personal Data as originally requested, we may charge you an additional fee.",
  "p-privacy-policy-retention": "9. RETENTION OF PERSONAL DATA",
  "p-privacy-policy-retention-intro":
    "Ensuring the safety and security of your Personal Data is our highest priority. We use secure storage to ensure the safety of your Personal Data that you share with us at all stages of interaction with our Platform, using our products and Services. Our security service takes all appropriate measures to protect your Personal Data from hacker attacks, loss or misuse.",
  "p-privacy-policy-retention-period":
    "We will retain your Personal Data only for as long as you use our products and Services through your Account, subject to the purposes described in this Privacy Policy and the legal and regulatory obligations we comply with. In accordance with our recordkeeping obligations, we will retain information about your Account and your Personal Data for a period of at least five (5) years from the date you close it or the end of your customer relationship with us, unless otherwise required for legal or regulatory reasons.",
  "p-privacy-policy-retention-extended":
    "We may access or retain your Personal Information for a longer period if it is the subject of a formal request or legal obligation, a government investigation, or an investigation for possible violations of our User Agreement and its integral parts, or otherwise to prevent possible harm based on our reasonable considerations.",
  "p-privacy-policy-unsubscribe":
    "If you unsubscribe from our email newsletter, we will store your email on an inactive email list.",
  "p-privacy-policy-changes": "10. CHANGES TO THE PRIVACY POLICY",
  "p-privacy-policy-changes-intro":
    "Any changes made to the Privacy Policy will be published on this page and, if necessary, sent to your email address. We encourage you to regularly review this page to stay informed of any updates or changes to the Privacy Policy.",
  "p-privacy-policy-contact": "11. CONTACT US",
  "p-privacy-policy-contact-description":
    "If you have any questions, complaints or doubts regarding our Privacy Policy, Cookie Policy or the methods and purposes of processing your Personal Data, you can contact us at",
};
