export default {
  "c-orderCreate-closeOrderConfirm":
    "Êtes-vous sûr de vouloir fermer la création de la commande ?",
  "c-orderCreate-close": "Fermer",
  "c-orderCreate-closeCancel": "Annuler",
  "c-orderCreate-sendAmount-required": "Veuillez saisir le montant",
  "c-orderCreate-receiveAmount-required": "Veuillez saisir le montant",
  "c-orderCreate-orderPayment-required":
    "Veuillez sélectionner un moyen de paiement",
  "c-orderCreate-fiatLimits":
    "Le montant doit être compris entre {min} et {max}",
  "c-orderCreate-cryptoLimits": "Le montant doit être inférieur à {limit}",
  "c-orderCreate-errorTitle": "Erreur",
  "c-orderCreate-errorCreatingOrder":
    "Erreur lors de la création de la commande",
  "c-orderCreate-orderCreated": 'ID de commande : "{id}" créé',
  "c-orderCreate-ratesChangedTitle": "Les taux ont changé",
  "c-orderCreate-ratesChangedAlert":
    "Les taux d'achat de cryptomonnaie ont changé pendant que vous créiez votre commande.",
  "c-orderCreate-ratesChangedDesc":
    "Cela pourrait être dû à des fluctuations du marché ou à une mise à jour du taux personnalisé par le créateur de l'offre. Veuillez vérifier les nouveaux taux avant de procéder à votre achat pour vous assurer que vous êtes d'accord avec les termes mis à jour.",
  "c-orderCreate-oldPrice": "Ancien prix",
  "c-orderCreate-newPrice": "Nouveau prix",
  "c-orderCreate-returnToOrder": "Retourner à la commande",
  "c-orderCreate-proceedWithNewPrice": "Procéder avec le nouveau prix",
  "c-orderCreate-creatingOrderTitle":
    "Création de commande pour {type} {currency}",
  "c-orderCreate-buying": "achat",
  "c-orderCreate-selling": "vente",
  "c-orderCreate-paymentTimeLimit": "Délai de paiement",
  "c-orderCreate-30minutes": "30 minutes",
  "c-orderCreate-avgPaymentTime": "Temps de paiement moyen",
  "c-orderCreate-minutes": "minutes",
  "c-orderCreate-available": "Disponible",
  "c-orderCreate-paymentOptions": "Options de paiement :",
  "c-orderCreate-traderInfo": "Infos du commerçant",
  "c-orderCreate-noDescriptionSpecified":
    "Aucune description spécifiée par le commerçant. Vous recevrez des informations supplémentaires une fois la commande créée.",
  "c-orderCreate-priceLabel": "Prix",
  "c-orderCreate-priceChangedTooltip":
    "Le prix a changé en raison des taux de change officiels ou des modifications apportées par le créateur de l'offre",
  "c-orderCreate-priceChanged": "Prix modifié !",
  "c-orderCreate-platformFee": "Frais de plateforme",
  "c-orderCreate-paidBySeller": "payé par le vendeur",
  "c-orderCreate-iWantTo": "Je veux {type}",
  "c-orderCreate-pay": "payer",
  "c-orderCreate-sell": "vendre",
  "c-orderCreate-enterAmount": "Entrez le montant",
  "c-orderCreate-limits": "Limites",
  "c-orderCreate-fee": "Frais",
  "c-orderCreate-myBalance": "Mon solde",
  "c-orderCreate-sellAll": "Tout vendre",
  "c-orderCreate-iWillReceive": "Je recevrai",
  "c-orderCreate-paymentMethod": "Méthode de paiement",
  "c-orderCreate-noPaymentOptions":
    "Aucune option de paiement disponible pour cette offre",
  "c-orderCreate-alsoAvailable": "Également disponible pour cette offre",
  "c-orderCreate-addPaymentOption": "Ajouter une option de paiement",
  "c-orderCreate-cancel": "Annuler",
  "c-orderCreate-buy": "ACHETER",
};
