export default {
  "p-blog-codeId-failed-to-save-post": "¡Error al guardar la publicación!",
  "p-blog-codeId-post-saved-successfully": "¡Publicación guardada con éxito!",
  "p-blog-codeId-confirm-delete":
    "¿Estás seguro de que deseas eliminar esta publicación?",
  "p-blog-codeId-warning": "Advertencia",
  "p-blog-codeId-delete": "Eliminar",
  "p-blog-codeId-post-deleted-successfully":
    "¡Publicación eliminada con éxito!",
  "p-blog-codeId-failed-to-delete-post": "¡Error al eliminar la publicación!",
  "p-blog-codeId-post-not-found": "Publicación de blog no encontrada :(",
  "p-blog-codeId-back-to-all-posts": "Volver a todas las publicaciones",
  "p-blog-codeId-edit-post": "Editar publicación",
  "p-blog-codeId-delete-post": "Eliminar publicación",

  "p-blog-index-title": "Blog | Ideas, Consejos e Historias de Solid Swap",
  "p-blog-index-meta-description":
    "Explora las últimas ideas, consejos e historias en nuestro blog. Mantente actualizado con nuestras últimas publicaciones que cubren una amplia gama de temas, incluidos tecnología, estilo de vida y más",
  "p-blog-index-meta-keywords":
    "blog, ideas, consejos, historias, tecnología, estilo de vida, últimas publicaciones, Solid Swap, solidswap",
  "p-blog-index-failed-to-fetch-posts": "Error al obtener publicaciones",
  "p-blog-index-solid-swap-blog": "Blog de Solid Swap",
  "p-blog-index-search-placeholder": "Buscar publicaciones de blog...",
  "p-blog-index-new-post": "Nueva publicación",
  "p-blog-index-active": "Activo",
  "p-blog-index-archive": "Archivo",
  "p-blog-index-load-more": "Cargar más",
  "p-blog-index-no-posts-found": "No se encontraron publicaciones",
};
