export default {
  "c-home-banners-getToKnowUs": "Get to know us",
  "c-home-banners-dirtyCryptocurrency":
    "What to Do if You Receive 'Dirty' Cryptocurrency",
  "c-home-banners-beginnersGuide": "Start, by reading our beginners guide",

  "c-home-historywidgets-24hours": "24 hours",
  "c-home-historywidgets-1week": "1 week",
  "c-home-historywidgets-1month": "1 month",
  "c-home-historywidgets-usdRatesHistory": "USD rates history for",
  "c-home-historywidgets-change": "(change?)",
};
