export default {
  "p-about-head-title":
    "Acerca de Solid Swap | Conoce a nuestro equipo de intercambio de criptomonedas",
  "p-about-head-description":
    "Aprende más sobre Solid Swap, el equipo detrás de nuestra plataforma segura de comercio de criptomonedas, y nuestra misión de proporcionar intercambios eficientes de activos digitales",
  "p-about-head-keywords":
    "intercambio de criptomonedas, comercio seguro, activos digitales, comercio de cripto, Solid Swap, solidswap",
  "p-about-contact-title": "Contáctenos",
  "p-about-address": "Dirección:",
  "p-about-index": "Código Postal:",
  "p-about-tax-id": "ID Fiscal:",
  "p-about-email": "Correo electrónico:",
  "p-about-support-email": "Correo de soporte:",
  "p-about-duns": "Sistema de Numeración Universal de Datos (D-U-N-S):",
  "p-about-duns-check": "Verificar D-U-N-S",
  "p-about-traders-count": ">200 comerciantes ya están con nosotros",
  "p-about-foundation-title": "La base de tu independencia financiera",
  "p-about-description":
    "Solid Swap es un nuevo servicio para el intercambio rápido de criptomonedas y dinero fiduciario en una plataforma P2P",
  "p-about-start-trading": "Empezar a comerciar",
  "p-about-fee-tag": "0% de comisión",
  "p-about-beneficial-terms": "Términos beneficiosos",
  "p-about-low-fees":
    "Comisiones bajas, promociones, sorteos y sin letra pequeña",
  "p-about-fast-transactions": "Transacciones rápidas",
  "p-about-secure-optimizations":
    "Mejoramos el código constantemente sin sacrificar la seguridad",
  "p-about-online": "En línea",
  "p-about-24-support": "Soporte 24/7",
  "p-about-support-anytime":
    "Te ayudamos con cualquier problema, en cualquier zona horaria",
  "p-about-contact-support": "Contactar soporte",
  "p-about-platform-mission":
    "Construimos una plataforma fiable, siguiendo sueños y principios",
  "p-about-join-us":
    "Hay muchos intercambios, pero tenemos algo especial que ofrecer: únete a nosotros y sigue el progreso del desarrollo",
  "p-about-freedom": "Libertad y anonimato",
  "p-about-data-privacy":
    "No queremos convertirnos en un banco; valoramos la privacidad de los datos personales",
  "p-about-registered-armenia": "Registrado en Armenia",
  "p-about-legal-compliance":
    "Para operar dentro de la ley manteniendo la independencia",
  "p-about-access-devices": "Acceso desde cualquier dispositivo",
  "p-about-cross-device-experience":
    "Creamos una experiencia optimizada en cualquier dispositivo y para cualquier situación",
  "p-about-services-question": "¿Qué servicios ofrece Solid Swap?",
  "p-about-buy-bitcoin-question": "¿Cómo comprar Bitcoin en Solid Swap?",
  "p-about-crypto-question": "¿Qué es la criptomoneda?",
  "p-about-russians-trade-question":
    "¿Los rusos pueden comerciar con criptomonedas?",
  "p-about-pay-with-crypto-question": "¿Se puede pagar en criptomonedas?",
  "p-about-login-trade": "Inicia sesión para comenzar a comerciar",
  "p-about-login": "Iniciar sesión",
  "p-about-register": "Registrarse",
};
