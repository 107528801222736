export default {
  "p-profile-userId-error": "Erreur : utilisateur non trouvé",
  "p-profile-userId-online": "En ligne",
  "p-profile-userId-last-online": "était en ligne {date}",
  "p-profile-userId-registration-date": "Date d'inscription",
  "p-profile-userId-total-orders": "Total des commandes",
  "p-profile-userId-buy": "ACHETER",
  "p-profile-userId-sell": "VENDRE",
  "p-profile-userId-last-month-orders": "Commandes du mois dernier",
  "p-profile-userId-average-confirm-time":
    "Temps moyen de confirmation de paiement",
  "p-profile-userId-average-send-time": "Temps moyen d'envoi du paiement",
  "p-profile-userId-feedback": "Retour d'information",
  "p-profile-userId-positive": "positive",
  "p-profile-userId-negative": "négative",
  "p-profile-userId-offers-tab": "Offres",
  "p-profile-userId-feedback-tab": "Retour",
  "p-profile-userId-no-offers": "Pas d'offres",
  "p-profile-userId-buy-orders": "Commandes d'achat",
  "p-profile-userId-sell-orders": "Commandes de vente",
  "p-profile-userId-no-feedback": "Pas de retour",
};
