export default {
  "p-trade-action-buy-title":
    "Comprar Criptomoneda | Compra segura de cripto en Solid Swap",
  "p-trade-action-sell-title":
    "Vender Criptomoneda | Venta segura de cripto en Solid Swap",
  "p-trade-action-buy-description":
    "Compra criptomonedas de forma segura en Solid Swap. Benefíciate de transacciones rápidas y tarifas competitivas para compras de activos digitales",
  "p-trade-action-sell-description":
    "Vende tu criptomoneda de forma segura en Solid Swap. Disfruta de transacciones rápidas y tarifas óptimas para tus activos digitales",
  "p-trade-action-buy-keywords":
    "comprar criptomoneda, compra de cripto, compra segura de activos, comercio de divisas digitales",
  "p-trade-action-sell-keywords":
    "vender criptomoneda, venta de cripto, venta segura de activos, comercio de divisas digitales",
  "p-trade-action-nothing-found":
    "no se encontró nada, intenta encontrar diferentes opciones",
  "p-trade-action-clear-filters": "Borrar todos los filtros",
};
