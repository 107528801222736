export default {
  "c-offers-editform-closeConfirmation":
    "Êtes-vous sûr de vouloir fermer cette fenêtre ?",
  "c-offers-editform-percentError":
    "La valeur en pourcentage % doit être comprise entre -99 et 99",
  "c-offers-editform-createError": "Erreur lors de la création de l'offre",
  "c-offers-editform-createSuccess": "Offre créée avec succès !",
  "c-offers-editform-updateSuccess": "Offre mise à jour avec succès !",
  "c-offers-editform-typeRequired": "Veuillez sélectionner le type de commande",
  "c-offers-editform-currencyRequired": "Veuillez sélectionner la devise",
  "c-offers-editform-priceTypeRequired":
    "Veuillez sélectionner le type de prix",
  "c-offers-editform-priceValRequired": "Veuillez entrer la valeur du prix",
  "c-offers-editform-paymentOptionsRequired":
    "Veuillez sélectionner les options de paiement",
  "c-offers-editform-minLimitRequired": "Veuillez entrer le montant minimum",
  "c-offers-editform-minLimitError":
    "Le montant minimum doit être supérieur à {minAmount}",
  "c-offers-editform-maxLimitRequired": "Veuillez entrer le montant maximum",
  "c-offers-editform-maxLimitError":
    "Le montant maximum ne doit pas être inférieur à la limite minimale",
  "c-offers-editform-offerAmountRequired":
    "Veuillez entrer le montant de l'offre",
  "c-offers-editform-termsOfUseRequired":
    "Veuillez entrer les conditions d'utilisation",
  "c-offers-editform-editTitle": "Modifier votre offre",
  "c-offers-editform-addTitle": "Ajouter une nouvelle offre",
  "c-offers-editform-offerType": "Type d'offre",
  "c-offers-editform-sell": "vendre",
  "c-offers-editform-buy": "acheter",
  "c-offers-editform-selling": "Vente",
  "c-offers-editform-buying": "Achat",
  "c-offers-editform-mainCurrency": "Devise principale",
  "c-offers-editform-selectCurrency": "sélectionner la devise",
  "c-offers-editform-forCurrency": "...pour",
  "c-offers-editform-currentRate":
    "taux actuel : 1 {currencyIn} = {rate} {currencyOut}",
  "c-offers-editform-price": "Prix",
  "c-offers-editform-priceType": "Type de prix",
  "c-offers-editform-selectPriceType": "sélectionner le type de prix",
  "c-offers-editform-percentValue": "Valeur en pourcentage",
  "c-offers-editform-relativeValue": "Valeur relative",
  "c-offers-editform-fixedValue": "Valeur fixe",
  "c-offers-editform-priceTypesExplained": "Types de prix expliqués :",
  "c-offers-editform-percent": "Pourcentage",
  "c-offers-editform-percentExplanation":
    "Le prix est ajusté en fonction d'un pourcentage de différence par rapport aux taux actuels. Si un utilisateur entre un pourcentage, le prix final sera calculé en appliquant ce pourcentage comme augmentation ou diminution par rapport aux taux actuels.",
  "c-offers-editform-relative": "Relatif",
  "c-offers-editform-relativeExplanation":
    "Le prix est ajusté en ajoutant ou en soustrayant un nombre spécifique des taux actuels. L'utilisateur entre ce nombre, et il modifie directement le prix de base, rendant le prix final plus élevé ou plus bas de ce montant exact.",
  "c-offers-editform-fixed": "Fixe",
  "c-offers-editform-fixedExplanation":
    "C'est une fixation de prix simple où l'utilisateur définit directement le prix. Le prix saisi par l'utilisateur sera le prix final, sans ajustements basés sur les taux actuels ou les pourcentages.",
  "c-offers-editform-pricePercent": "Prix en pourcentage",
  "c-offers-editform-priceRelative": "Prix relatif",
  "c-offers-editform-priceFixed": "Prix fixe",
  "c-offers-editform-enterPriceValue": "entrer la valeur du prix",
  "c-offers-editform-rate":
    "selon le type sélectionné et les champs de prix, votre taux est : 1 {currencyIn} = {rate} {currencyOut}",
  "c-offers-editform-paymentOptions": "Options de paiement",
  "c-offers-editform-selectPaymentOptions":
    "sélectionner les options de paiement",
  "c-offers-editform-noOptions": "Pas d'options disponibles",
  "c-offers-editform-all": "Tout",
  "c-offers-editform-addOption": "Ajouter une option de paiement",
  "c-offers-editform-minLimit": "Limite minimale",
  "c-offers-editform-enterMinAmount": "montant min.",
  "c-offers-editform-maxLimit": "Limite maximale",
  "c-offers-editform-enterMaxAmount": "montant max.",
  "c-offers-editform-orderLimitsExplanation":
    "Explication des limites de commande",
  "c-offers-editform-orderLimitsExplanationTitle":
    "Explication des limites de commande",
  "c-offers-editform-orderLimitsExplanationText":
    "Les limites minimales et maximales spécifient la plage dans laquelle vous pouvez créer une commande. Vous ne pouvez pas passer une commande d'achat ou de vente de crypto en dessous de la limite minimale ou au-dessus de la limite maximale.",
  "c-offers-editform-orderLimitsExplanationUSD":
    "De plus, notre plateforme applique une limite minimale équivalente à {MIN_OFFER_AMOUNT_USD} USD pour toutes les offres, ce qui représente environ {usdLimit} {currencyOut}",
  "c-offers-editform-amountToTrade": "Montant à échanger",
  "c-offers-editform-offerAmount": "Montant de l'offre",
  "c-offers-editform-offerAmount-unlimited": "illimité",
  "c-offers-editform-enterOfferAmount": "montant",
  "c-offers-editform-currentBalance":
    "Mon solde actuel : {balance} {currencyIn}",
  "c-offers-editform-offerAmountExplanation":
    "Explication du montant de l'offre",
  "c-offers-editform-offerAmountExplanationTitle":
    "Explication du montant de l'offre",
  "c-offers-editform-offerAmountExplanationText1":
    "Le montant de l'offre est le montant total de la cryptomonnaie que vous êtes prêt à échanger.",
  "c-offers-editform-offerAmountExplanationText2":
    "Pour les offres de vente, c'est le montant de cryptomonnaie que vous souhaitez vendre. Par exemple : si vous avez 1000 USDT sur votre solde et que vous ne voulez échanger que la moitié, vous pouvez définir le montant de l'offre à 500 USDT. Cela garantit que vous ne vendrez pas plus de 500 USDT. Notez que tous vos fonds restent sur votre compte, et l'annulation de l'offre les renverra sur votre solde.",
  "c-offers-editform-offerAmountExplanationText3":
    "Pour les offres d'achat, ce montant indique combien de cryptomonnaie vous voulez acheter. Si vous définissez le montant de l'offre à 1000 USDT, vous recevrez exactement ce montant.",
  "c-offers-editform-offerAmountExplanationText4":
    "De plus, notre plateforme prélève une commission, qui est à la charge du vendeur. Par conséquent, si vous créez une offre de VENTE, la commission est à votre charge. Si vous créez une offre d'ACHAT, la commission sera à la charge de la contrepartie qui crée la commande.",
  "c-offers-editform-termsOfUse": "Conditions d'utilisation",
  "c-offers-editform-termsOfUseText":
    "Veuillez remplir vos 'Conditions d'utilisation' ci-dessous. Cette section doit décrire les conditions générales sous lesquelles vous êtes prêt à échanger. Il n'est pas nécessaire de fournir vos informations bancaires ou tout autre détail sensible ici.",
  "c-offers-editform-termsOfUsePlaceholder": "écrire une description...",
  "c-offers-editform-cancel": "Annuler",
  "c-offers-editform-confirm": "Confirmer",
};
