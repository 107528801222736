export default {
  "p-resetPassword-head-title":
    "Сброс пароля | Восстановление аккаунта Solid Swap",
  "p-resetPassword-head-description":
    "Быстро и безопасно сбросьте пароль вашего аккаунта Solid Swap. Следуйте инструкциям, чтобы вернуть доступ к вашему аккаунту",
  "p-resetPassword-head-keywords":
    "сброс пароля, восстановление аккаунта, сброс пароля Solid Swap",
  "p-resetPassword-success": "Ваш пароль успешно сброшен!",
  "p-resetPassword-loginInfo": "Теперь вы можете войти в ваш аккаунт",
  "p-resetPassword-error":
    "Не удалось сбросить ваш пароль, пожалуйста, свяжитесь с поддержкой!",
  "p-resetPassword-validate-password-required":
    "Пожалуйста, введите ваш пароль",
  "p-resetPassword-validate-password-length":
    "Длина пароля должна быть от 6 до 21 символа",
  "p-resetPassword-validate-password2-required":
    "Пожалуйста, подтвердите ваш пароль",
  "p-resetPassword-validate-password2-match": "Пароли не совпадают",
  "p-resetPassword-title": "Сбросить пароль",
  "p-resetPassword-success-title": "Успех!",
  "p-resetPassword-success-message":
    "Ваш пароль успешно сброшен! Вы будете перенаправлены на страницу входа через 30 секунд",
  "p-resetPassword-goToLogin": "Перейти к входу",
  "p-resetPassword-instructions":
    "Пожалуйста, введите ваш новый пароль в поля ниже. Убедитесь, что ваш пароль содержит не менее 6 символов и включает в себя сочетание заглавных и строчных букв, цифр и специальных символов для дополнительной безопасности.",
  "p-resetPassword-newPassword": "Новый пароль",
  "p-resetPassword-placeholder-password": "*****",
  "p-resetPassword-repeatPassword": "Повторите пароль",
  "p-resetPassword-placeholder-password2": "*****",
  "p-resetPassword-resetButton": "Сбросить пароль",
};
