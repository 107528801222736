export default {
  "p-support-chat-welcome": "Bienvenue dans notre chat de support !",
  "p-support-chat-intro":
    "Nous sommes là pour vous aider avec toutes les questions ou préoccupations que vous pourriez avoir. Notre objectif est de vous offrir la meilleure expérience de support possible. Voici comment vous pouvez commencer :",
  "p-support-chat-enter-details-prefix": "Entrez vos détails : ",
  "p-support-chat-enter-details-suffix":
    "titre de votre question. Ces informations nous aident à mieux vous assister et à répondre à vos préoccupations plus efficacement.",
  "p-support-chat-open-chat":
    'Ouvrir le chat : cliquez sur le bouton "Ouvrir le chat de support" pour commencer.',
  "p-support-chat-start-conversation":
    "Commencez la conversation : une fois que vous avez entré vos informations et soumis le formulaire, une boîte de chat apparaîtra sur la page. L'un de nos modérateurs rejoindra le chat sous peu pour vous aider.",
  "p-support-chat-appreciation":
    "Nous apprécions votre patience et sommes impatients de vous aider !",
  "p-support-chat-your-name": "Votre nom",
  "p-support-chat-your-email": "Votre email",
  "p-support-chat-your-question": "Votre question",
  "p-support-chat-open-chat-button": "Ouvrir le chat de support",
  "p-support-chat-wait-moderator": "Attendez que le modérateur rejoigne",
  "p-support-chat-contact-alternative":
    "Si vous avez des difficultés à utiliser le chat ou préférez d'autres moyens de nous contacter, n'hésitez pas à nous joindre via notre",
  "p-support-chat-contact-page": "page de contact",
  "p-support-chat-or-email": "ou envoyez-nous un email à",
  "p-support-chat-thank-you": "Merci d'avoir choisi nos services !",
  "p-support-chat-ask-question": "Posez une question",
  "p-support-chat-name-required": "Le nom est requis",
  "p-support-chat-email-required": "L'email est requis",
  "p-support-chat-valid-email": "Entrez un email valide",
  "p-support-chat-chat-started": "Chat de support démarré !",
  "p-support-chat-name-email": "nom, email,",
};
