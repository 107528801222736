export default {
  "c-orderCreate-closeOrderConfirm":
    "Համոզված եք, որ ցանկանում եք փակել պատվերի ստեղծումը։",
  "c-orderCreate-close": "Փակել",
  "c-orderCreate-closeCancel": "Չեղարկել",
  "c-orderCreate-sendAmount-required": "Խնդրում եմ, մուտքագրեք գումարը",
  "c-orderCreate-receiveAmount-required": "Խնդրում եմ, մուտքագրեք գումարը",
  "c-orderCreate-orderPayment-required": "Խնդրում եմ, ընտրեք վճարման եղանակը",
  "c-orderCreate-fiatLimits": "Գումարը պետք է լինի {min} -ից {max}-ը",
  "c-orderCreate-cryptoLimits": "Գումարը պետք է լինի {limit}-ից քիչ",
  "c-orderCreate-errorTitle": "Սխալ",
  "c-orderCreate-errorCreatingOrder": "Պատվեր ստեղծելիս սխալ է ստացվել",
  "c-orderCreate-orderCreated": 'Պատվերի ID՝ "{id}" ստեղծված է',
  "c-orderCreate-ratesChangedTitle": "Արժեքները փոխվել են",
  "c-orderCreate-ratesChangedAlert":
    "Կրիպտովալյուտա գնման արժեքները փոխվել են, երբ դուք ստեղծում էիք պատվերը։",
  "c-orderCreate-ratesChangedDesc":
    "Դա կարող է հաշվվել շուկայի բարդությունների կամ առաջարկի ստեղծողի հարմարացված արժեքի նորացմամբ։ Խնդրում եմ նորացված արժեքները ստուգել գնման նախքան առաջ գնալը։",
  "c-orderCreate-oldPrice": "Հին գին",
  "c-orderCreate-newPrice": "Նոր գին",
  "c-orderCreate-returnToOrder": "Վերադառնալ պատվերին",
  "c-orderCreate-proceedWithNewPrice": "Շարունակել նոր գնով",
  "c-orderCreate-creatingOrderTitle":
    "Պատվեր ստեղծելու համար {type} {currency}",
  "c-orderCreate-buying": "գնում",
  "c-orderCreate-selling": "վաճառք",
  "c-orderCreate-paymentTimeLimit": "Վճարման ժամկետը",
  "c-orderCreate-30minutes": "30 րոպե",
  "c-orderCreate-avgPaymentTime": "Միջին վճարման ժամանակ",
  "c-orderCreate-minutes": "րոպեներ",
  "c-orderCreate-available": "Առկա է",
  "c-orderCreate-paymentOptions": "Վճարման եղանակները՝",
  "c-orderCreate-traderInfo": "Վաճառողի տվյալները",
  "c-orderCreate-noDescriptionSpecified":
    "Վաճառողը չի նշել նկարագրություն։ Պատվերը ստեղծվելուց հետո կստանաք հավելյալ տվյալներ։",
  "c-orderCreate-priceLabel": "Գինը",
  "c-orderCreate-priceChangedTooltip":
    "գինը փոխվել է պաշտոնական առևտրի արժեքների կամ առաջարկի ստեղծողի պայմանների փոփոխման համար",
  "c-orderCreate-priceChanged": "Գինը փոխվել է։",
  "c-orderCreate-platformFee": "Հարթակի վճար",
  "c-orderCreate-paidBySeller": "վճարում է վաճառողը",
  "c-orderCreate-iWantTo": "Ես ցանկանում եմ {type}",
  "c-orderCreate-pay": "վճարել",
  "c-orderCreate-sell": "վաճառել",
  "c-orderCreate-enterAmount": "Մուտքագրեք գումարը",
  "c-orderCreate-limits": "Սահմանափակումներ",
  "c-orderCreate-fee": "Հարկ",
  "c-orderCreate-myBalance": "Իմ հաշիվը",
  "c-orderCreate-sellAll": "Վաճառել ամբողջը",
  "c-orderCreate-iWillReceive": "Ես կստանամ",
  "c-orderCreate-paymentMethod": "Վճարման մեթոդ",
  "c-orderCreate-noPaymentOptions":
    "Ձեր համար այս առաջարկում վճարման տարբերակները չեն հասանելի",
  "c-orderCreate-alsoAvailable": "Այս առաջարկում նաև հասանելի է",
  "c-orderCreate-addPaymentOption": "Ավելացնել վճարման մեթոդ",
  "c-orderCreate-cancel": "Չեղարկել",
  "c-orderCreate-buy": "ԳՆԵԼ",
};
