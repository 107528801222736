export default {
  "c-Withdrawal-success": "¡Retiro exitoso!",
  "c-Withdrawal-error":
    "Ocurrió un error durante el retiro, por favor intenta más tarde",
  "c-Withdrawal-enter-valid-amount": "Ingresa una cantidad válida",
  "c-Withdrawal-enter-valid-address": "Ingresa una dirección pública válida",
  "c-Withdrawal-enter-tfa-code": "Ingresa tu código 2FA de la aplicación",
  "c-Withdrawal-title": "Retiro de",
  "c-Withdrawal-message-1":
    "Por favor, completa el formulario a continuación para proceder con tu retiro",
  "c-Withdrawal-message-2":
    "Después de proceder con el retiro, tendrás un intervalo de varios minutos para cancelar tu transacción desde la página de historial de transacciones",
  "c-Withdrawal-no-fee":
    "No hay tarifa de retiro en este momento, solo se te cobrará la tarifa de red, que depende de su carga y se deducirá del monto del retiro.",
  "c-Withdrawal-amount-label": "Monto a retirar",
  "c-Withdrawal-available": "Disponible tienes",
  "c-Withdrawal-withdraw-all": "Retirar todo",
  "c-Withdrawal-commission": "Comisión:",
  "c-Withdrawal-address-label": "Dirección pública de la cartera",
  "c-Withdrawal-security-tip-title": "Consejo de seguridad:",
  "c-Withdrawal-security-tip-desc":
    "Siempre verifica dos veces la dirección antes de enviar. Asegúrate de reconocer al destinatario.",
  "c-Withdrawal-tfa-label": "Código de Autenticación de Dos Factores",
  "c-Withdrawal-scam-warning-title": "Cuidado con las estafas:",
  "c-Withdrawal-scam-warning-desc":
    "Nunca compartas tus claves o códigos 2FA con nadie. El soporte oficial nunca te pedirá esta información.",
  "c-Withdrawal-cancel": "Cancelar",
  "c-Withdrawal-continue": "Continuar",
};
