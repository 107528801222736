export default {
  "c-payment-option-close-dialog-confirm":
    "Êtes-vous sûr de vouloir fermer la fenêtre de l'option de paiement ?",
  "c-payment-option-save-error":
    "Une erreur s'est produite lors de la sauvegarde de l'option de paiement",
  "c-payment-option-edit": "Modifier l'option de paiement",
  "c-payment-option-new": "Nouvelle option de paiement",
  "c-payment-option-show-currency-methods":
    "Seules les méthodes de paiement avec la devise : {currency} seront affichées",
  "c-payment-option-show-specified-options":
    "Seules les options de paiement spécifiées dans l'offre seront affichées",
  "c-payment-option-editing-method":
    "Modification de la méthode de paiement pour :",
  "c-payment-option-select-method":
    "Sélectionnez la méthode que vous souhaitez ajouter :",
  "c-payment-option-select-method-placeholder":
    "sélectionnez le mode de paiement...",
  "c-payment-option-description": "Description du type de paiement :",
  "c-payment-option-description-info":
    "Veuillez fournir une description de votre type de paiement. Cela devrait inclure votre numéro de compte, numéro de carte ou toute information pertinente qui aidera les acheteurs à identifier où ils doivent transférer leurs fonds lorsqu'ils achètent de la cryptomonnaie chez vous.",
  "c-payment-option-description-placeholder": "écrire une description...",
  "c-payment-option-save": "Enregistrer",
  "c-payment-option-validate-payment-option":
    "Veuillez sélectionner une option de paiement",
  "c-payment-option-validate-description": "Veuillez ajouter la description",
};
