export default {
  "c-chat-indexclient-historyLoadError":
    "Error al cargar el historial del chat",
  "c-chat-indexclient-pictureFormatError":
    "¡La imagen debe estar en formato jpg/png!",
  "c-chat-indexclient-pictureSizeError":
    "¡El tamaño de la imagen no puede superar los 10MB!",
  "c-chat-indexclient-uploadError": "¡Error al subir la imagen!",
  "c-chat-indexclient-connectionError": "Error al conectar con el chat",
  "c-chat-indexclient-summaryLoadError": "Error al cargar el resumen del chat",
  "c-chat-indexclient-chatWith": "Chatear con",
  "c-chat-indexclient-counterparty": "contraparte",
  "c-chat-indexclient-online": "en línea",
  "c-chat-indexclient-wasOnline": "estaba en línea",
  "c-chat-indexclient-andMore": "y {count} más",
  "c-chat-indexclient-allParticipants": "Todos los participantes del chat",
  "c-chat-indexclient-moderator": "moderador",
  "c-chat-indexclient-welcomeMessage":
    "Bienvenido al chat para el pedido #{orderId}",
  "c-chat-indexclient-paymentSent": "El comprador ha enviado el pago",
  "c-chat-indexclient-paymentConfirmed": "El vendedor ha confirmado el pago",
  "c-chat-indexclient-disputeCreated": "Disputa creada",
  "c-chat-indexclient-orderCancelled": "Pedido cancelado",
  "c-chat-indexclient-moderatorJoined": "El moderador se unió al chat",
  "c-chat-indexclient-sessionStarted": "Sesión de soporte iniciada",
  "c-chat-indexclient-sessionClosed": "Sesión de soporte cerrada",
  "c-chat-indexclient-placeholder": "Por favor, escribe",
};
