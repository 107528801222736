export default {
  "p-privacy-policy-title": "Política de Privacidad | Solid Swap",
  "p-privacy-policy-meta-description":
    "Lea la política de privacidad de Solid Swap para entender cómo manejamos su información personal y protegemos su privacidad",
  "p-privacy-policy-meta-keywords":
    "política de privacidad, protección de datos, información personal, privacidad de Solid Swap",
  "p-privacy-policy-header-title": "Política de Privacidad",
  "p-privacy-policy-download-link": "descargar como archivo",
  "p-privacy-policy-alert":
    "Este Acuerdo de Cliente puede ser publicado en diferentes idiomas. En caso de cualquier discrepancia, por favor consulte la versión en inglés",
  "p-privacy-policy-last-updated": "Última actualización:",
  "p-privacy-policy-intro-1":
    "Esta Política de Privacidad, según se modifique o cambie de vez en cuando (la “Política de Privacidad”), explica la manera en la cual SOLIDSWAP, Ltd. (“SOLIDSWAP”, “nosotros” o “nuestro”) recopila, usa, mantiene y divulga la información de los usuarios obtenida a través del",
  "p-privacy-policy-site": "solidswap.net",
  "p-privacy-policy-intro-2":
    "sitio web (el “Sitio”) y a través de la prestación de los servicios (los “Servicios”). SOLIDSWAP, Ltd. es el controlador de datos de su Información Personal.",
  "p-privacy-policy-commitment":
    "SOLIDSWAP y sus Afiliados están comprometidos a hacer todo lo posible para proteger su privacidad. SOLIDSWAP utiliza la información recopilada sobre usted para cumplir con sus obligaciones contractuales y mejorar el servicio al cliente.",
  "p-privacy-policy-read":
    "Lea este documento detenidamente para comprender nuestra posición y políticas con respecto a su Información Personal y cómo la utilizaremos.",
  "p-privacy-policy-read-cookie":
    "Esta Política de Privacidad debe leerse junto con nuestra Política de Cookies.",
  "p-privacy-policy-data-we-collect": "1. QUÉ DATOS RECOPILAMOS SOBRE USTED",
  "p-privacy-policy-data-we-collect-intro":
    "Podemos recopilar y procesar los siguientes datos sobre usted:",
  "p-privacy-policy-info-provided": "Información que usted nos proporciona",
  "p-privacy-policy-info-provided-description":
    "Puede proporcionarnos información sobre usted completando formularios en el Sitio o proporcionando dicha información por teléfono, correo electrónico, videoconferencia o cualquier otro medio. Esta información incluye datos personales especificados al registrarse en el Sitio, al suscribirse a nuestros Servicios, al abrir una Cuenta y verificar la identidad del propietario de la Cuenta o de las personas autorizadas a disponer de ella, así como los instrumentos de pago utilizados por ellos, buscando un producto, utilizando cualquiera de los Servicios que proporciona nuestro Sitio al realizar Pedidos en nuestro Sitio, al participar en discusiones en el Chat o utilizar cualquier otra función de comunicación en nuestro Sitio, al participar en competiciones, promociones o encuestas, y al informarnos sobre cualquier problema en las operaciones del Sitio.",
  "p-privacy-policy-example":
    "Por ejemplo, los datos que nos proporciona pueden incluir su:",
  "p-privacy-policy-first-last-name": "nombre y apellidos;",
  "p-privacy-policy-photos-videos":
    "fotografías y videos que puede enviarnos para fines de verificación de acuerdo con nuestra Política AML/CTF y KYC;",
  "p-privacy-policy-residence-citizenship":
    "dirección de residencia y ciudadanía;",
  "p-privacy-policy-sex": "sexo;",
  "p-privacy-policy-id-documents":
    "pasaporte, licencia de conducir y cualquier otro documento de identificación emitido por el gobierno;",
  "p-privacy-policy-tax-residency": "residencia fiscal;",
  "p-privacy-policy-phone-email":
    "número de teléfono y dirección de correo electrónico;",
  "p-privacy-policy-login-nickname":
    "nombre de usuario y apodo para acceder a la Plataforma SOLIDSWAP;",
  "p-privacy-policy-bank-info":
    "información bancaria, incluidos detalles de la cuenta y del pago, así como información FATCA/CRS;",
  "p-privacy-policy-dob": "fecha y lugar de nacimiento;",
  "p-privacy-policy-education": "educación;",
  "p-privacy-policy-work-experience": "experiencia laboral y lugar de empleo;",
  "p-privacy-policy-official-position": "posición oficial;",
  "p-privacy-policy-income-sources":
    "fuentes y/o cantidades de ingresos/reposición de cuentas;",
  "p-privacy-policy-account-status":
    "información sobre el estado de sus cuentas abiertas en bancos y/o sistemas de pago;",
  "p-privacy-policy-support-requests":
    "solicitudes e historial de correspondencia con el soporte de SOLIDSWAP y otros Usuarios de la Plataforma SOLIDSWAP;",
  "p-privacy-policy-trade-balances": "saldos comerciales y actividad;",
  "p-privacy-policy-location-log":
    "información de ubicación y datos de registro que se generan cuando utiliza la Plataforma SOLIDSWAP;",
  "p-privacy-policy-additional-info":
    "otra información personal que pueda ser requerida por nuestra Política AML/CTF y KYC para cumplir con las obligaciones de cumplimiento normativo.",
  "p-privacy-policy-no-sensitive-data":
    "SOLIDSWAP no recopila ni procesa datos personales sensibles de los Usuarios, como raza u origen étnico, opiniones políticas, creencias religiosas o filosóficas, afiliación sindical, datos genéticos o biométricos, información sobre salud, vida sexual u orientación sexual.",
  "p-privacy-policy-info-collected": "Información que recopilamos sobre usted",
  "p-privacy-policy-info-collected-description":
    "Cada vez que visita nuestro Sitio y utiliza nuestros Servicios, podemos recopilar sobre usted (incluido automáticamente) la siguiente información:",
  "p-privacy-policy-info-collected-a":
    "(a) información técnica, incluida la dirección del protocolo de Internet (IP) utilizada para conectar su computadora a Internet, la información de inicio de sesión de su Cuenta Personal, el tipo y la versión del navegador, la configuración de la zona horaria, los tipos y versiones de los complementos del navegador, el sistema operativo y la plataforma;",
  "p-privacy-policy-info-collected-b":
    "(b) datos sobre su visita al Sitio: incluida información completa sobre el localizador uniforme de recursos (URL), la ruta de navegación en el Sitio y cuando navega hacia/sale del Sitio (incluida la fecha y la hora), sobre los productos y Servicios que vio o buscó, tiempo de respuesta de la página, errores de carga de datos, duración de la estancia en ciertas páginas, actividad en la página (información como desplazamientos, movimientos del ratón, clics), métodos utilizados para salir de la página, y números de teléfono utilizados para contactar al centro de soporte al cliente;",
  "p-privacy-policy-info-collected-c":
    "(c) datos de geolocalización: podemos recopilar información de ubicación en tiempo real desde su dispositivo en cualquier momento cuando descarga o utiliza nuestros servicios; podemos utilizar esta información para optimizar nuestros servicios;",
  "p-privacy-policy-info-collected-d":
    "(d) información financiera e información sobre el uso de los Servicios y las oportunidades proporcionadas por nuestro Sitio, incluida información sobre transacciones en curso y sus participantes, incluidas transacciones con margen, información sobre el estado de las Cuentas y el movimiento de fondos en ellas, comisiones acumuladas, información sobre Depósitos de Cuentas y Retiros de Cuentas abiertas en nuestra Plataforma.",
  "p-privacy-policy-info-from-others":
    "Información que recibimos de otras fuentes",
  "p-privacy-policy-info-from-others-description":
    "Podemos recibir información sobre usted si utiliza cualquiera de nuestros otros sitios o servicios. También trabajamos estrechamente con terceros (incluidos nuestros socios comerciales, subcontratistas técnicos, de pago y soporte, redes publicitarias, proveedores de análisis, proveedores de diligencia debida, agencias de informes crediticios) y podemos recibir información sobre usted de ellos. Dicha información puede incluir:",
  "p-privacy-policy-info-from-others-a":
    "(a) información sobre las instituciones financieras que utiliza para Depositar y/o Retirar Fondos;",
  "p-privacy-policy-info-from-others-b":
    "confirmación por parte de agencias de crédito de la información que nos proporcionó;",
  "p-privacy-policy-store-pending":
    "Sus Datos Personales, como la dirección de correo electrónico y el inicio de sesión, que nos proporciona durante el registro en la Plataforma SOLIDSWAP, son almacenados por nosotros independientemente de si completa el proceso de registro o no. Lo mismo se aplica a sus Datos Personales durante el proceso de verificación pendiente de acuerdo con la Política AML/CTF y KYC.",
  "p-privacy-policy-cookies": "2. USO DE COOKIES",
  "p-privacy-policy-cookies-description":
    "Lea nuestra Política de Cookies para obtener información sobre el uso de cookies.",
  "p-privacy-policy-use-of-data":
    "3. ¿PARA QUÉ UTILIZAMOS SUS DATOS PERSONALES?",
  "p-privacy-policy-use-of-data-intro":
    "Utilizaremos sus Datos Personales para los siguientes propósitos:",
  "p-privacy-policy-providing-services": "Provisión de nuestros Servicios",
  "p-privacy-policy-fulfill-obligations":
    "para cumplir con las obligaciones bajo cualquier contrato celebrado con usted, así como para proporcionarle la información, productos y Servicios que solicita;",
  "p-privacy-policy-security":
    "para garantizar la seguridad de nuestro Sitio, nuestros Servicios y su Cuenta;",
  "p-privacy-policy-admin-site":
    "para administrar nuestro Sitio y realizar operaciones internas, incluido la resolución de problemas, análisis de datos, pruebas, recopilación de datos estadísticos y realización de encuestas;",
  "p-privacy-policy-interactive-features":
    "para brindarle acceso a funciones interactivas del Sitio si desea utilizarlas;",
  "p-privacy-policy-support":
    "para proporcionar soporte, responder y resolver sus quejas y preguntas relacionadas con el uso de nuestros Servicios y las capacidades de nuestro Sitio.",
  "p-privacy-policy-informing":
    "Informarle sobre nuestros productos y Servicios",
  "p-privacy-policy-inform-products":
    "para informarle sobre nuestros otros productos y Servicios existentes y/o nuevos, similares a aquellos productos y Servicios que ya ha comprado o buscado información;",
  "p-privacy-policy-inform-changes":
    "para informarle sobre cambios en nuestros productos y Servicios.",
  "p-privacy-policy-improve": "Ayudar a mejorar nuestros productos y Servicios",
  "p-privacy-policy-improve-site":
    "para mejorar nuestro Sitio y asegurarnos de que nuestro contenido sea lo más amigable posible para su sistema operativo de computadora;",
  "p-privacy-policy-evaluate-ads":
    "para evaluar y comprender la efectividad de los materiales publicitarios que le proporcionamos a usted y a otros Usuarios, así como para proporcionarle la información publicitaria necesaria;",
  "p-privacy-policy-effective-content":
    "para asegurarnos de que el contenido de nuestro Sitio se le presente de la manera más efectiva y se pueda utilizar desde su computadora.",
  "p-privacy-policy-protect-interests": "Protección de intereses corporativos",
  "p-privacy-policy-risk-management": "para la gestión de riesgos;",
  "p-privacy-policy-it-security":
    "para proteger y controlar nuestros sistemas de TI, para prevenir delitos;",
  "p-privacy-policy-evaluate-services":
    "para evaluar el uso de nuestros productos y Servicios para su posterior desarrollo;",
  "p-privacy-policy-internal-business":
    "para fines comerciales internos y de investigación, como comunicarse con usted con respecto a nuestros productos y Servicios;",
  "p-privacy-policy-analyze-data":
    "para analizar datos de forma anónima utilizando balizas web y etiquetas de píxeles para asegurarnos de que nuestros productos y Servicios sean apropiados para el mercado en el que operamos.",
  "p-privacy-policy-legal-procedures": "Provisión de procedimientos legales",
  "p-privacy-policy-law-enforcement":
    "para responder a solicitudes de agencias de cumplimiento de la ley;",
  "p-privacy-policy-legal-claims":
    "para iniciar y/o responder a reclamaciones legales;",
  "p-privacy-policy-disputes":
    "para investigar y/o resolver disputas con usted.",
  "p-privacy-policy-legal-compliance": "Cumplimiento legal",
  "p-privacy-policy-verify-users":
    "para verificar y autenticar la identidad de los Usuarios que abren y gestionan Cuentas para prevenir fraudes y actividades ilegales relacionadas;",
  "p-privacy-policy-aml-compliance":
    "para cumplir con los requisitos legales de nuestra Política AML/CTF y otras leyes y regulaciones relevantes;",
  "p-privacy-policy-notify-changes":
    "para notificar a nuestros Usuarios sobre cambios en las políticas y términos de uso de la Plataforma SOLIDSWAP.",
  "p-privacy-policy-marketing-third-parties": "Marketing y terceros",
  "p-privacy-policy-new-products":
    "para proporcionarle información sobre nuevos productos o servicios introducidos por SOLIDSWAP o nuestros proveedores externos que creemos que pueden ser de su interés.",
  "p-privacy-policy-disclosure": "4. DIVULGACIÓN DE SUS DATOS PERSONALES",
  "p-privacy-policy-disclosure-intro":
    "Podemos compartir su Información Personal dentro del Grupo SOLIDSWAP, que incluye nuestras subsidiarias y afiliados, nuestras compañías matrices y sus subsidiarias.",
  "p-privacy-policy-disclosure-third-parties-intro":
    "Podemos transferir sus Datos Personales a terceros, incluyendo:",
  "p-privacy-policy-disclosure-third-parties-a":
    "(i) socios comerciales, proveedores, subcontratistas, proveedores de servicios que llevan a cabo verificaciones legales y financieras adecuadas para cumplir con las obligaciones contractuales que celebramos con ellos o con usted;",
  "p-privacy-policy-disclosure-third-parties-b":
    "(ii) redes de marketing que requieren ciertos datos para seleccionar y promover publicidad relevante.",
  "p-privacy-policy-disclosure-no-sensitive":
    "No divulgamos información sensible sobre nuestros clientes a empresas de marketing, pero podemos proporcionarles información agregada sobre nuestros Usuarios (por ejemplo, podemos decirles que 500 mujeres menores de 30 años hicieron clic en su anuncio en un día determinado).",
  "p-privacy-policy-disclosure-aggregate":
    "También podemos utilizar dicha información agregada para ayudar a las empresas de marketing a llegar a la audiencia que desean dirigirse (por ejemplo, mujeres que viven en una región en particular). Podemos utilizar los Datos Personales que recopilamos de usted para permitirnos cumplir con los deseos de nuestros anunciantes al mostrar sus anuncios a audiencias específicas, proveedores de análisis y búsqueda que nos ayudan a mejorar y optimizar el rendimiento de nuestro Sitio.",
  "p-privacy-policy-disclosure-other-cases-intro":
    "También podemos divulgar sus Datos Personales a terceros en los siguientes casos:",
  "p-privacy-policy-disclosure-other-cases-a":
    "(a) si vendemos o compramos algún negocio o activo, la información personal de los Usuarios puede ser divulgada a un comprador o vendedor potencial;",
  "p-privacy-policy-disclosure-other-cases-b":
    "(b) si SOLIDSWAP o todos sus activos son comprados por un tercero, los Datos Personales de los Usuarios serán transferidos como uno de los activos;",
  "p-privacy-policy-disclosure-other-cases-c":
    "(c) si estamos obligados a divulgar o transferir Datos Personales de los Usuarios de acuerdo con cualquier ley aplicable o para cumplir con órdenes judiciales relevantes, solicitudes de agencias judiciales y de cumplimiento de la ley, o para obligar a los Usuarios a cumplir con los términos de los acuerdos celebrados con nosotros;",
  "p-privacy-policy-disclosure-other-cases-d":
    "(d) en el caso de que sea necesario proteger nuestros derechos y propiedad o garantizar la seguridad de SOLIDSWAP, nuestros Usuarios u otras partes (esto incluye compartir información con organizaciones relevantes para prevenir fraudes y reducir la probabilidad de incumplimiento).",
  "p-privacy-policy-security-storage":
    "5. SEGURIDAD Y ALMACENAMIENTO DE DATOS PERSONALES",
  "p-privacy-policy-security-measures":
    "Utilizamos medidas de seguridad para garantizar la confidencialidad de sus Datos Personales y protegerlos contra pérdida, uso indebido, alteración o destrucción. Solo el personal autorizado de SOLIDSWAP tiene acceso a su Información Personal, y estos empleados están obligados a tratar esta información como confidencial. Las medidas de seguridad actuales serán revisadas de vez en cuando de acuerdo con nuevas disposiciones legales e innovaciones técnicas.",
  "p-privacy-policy-storage":
    "La información que recopilamos de usted puede ser transferida y almacenada fuera del Área Económica Europea (AEE) con el propósito de proporcionarle nuestros Servicios. La información sobre usted puede ser procesada por nuestros empleados o empleados de proveedores de servicios ubicados fuera del AEE. Cuando sus Datos Personales son almacenados o procesados por terceros fuera del AEE, nos aseguraremos de notificar a dichos terceros sobre nuestras obligaciones bajo esta Política de Privacidad y establecer una relación con ellos que los someterá a obligaciones en relación con sus Datos Personales, siempre que tengan el nivel adecuado de seguridad especificado en esta Política de Privacidad. Dichos empleados pueden, entre otras cosas, procesar sus solicitudes, información de pago y proporcionar soporte técnico. Al proporcionarnos sus Datos Personales, usted consiente la transferencia, almacenamiento y procesamiento de sus Datos Personales. A su vez, tomaremos todas las medidas necesarias para garantizar que sus Datos Personales sean procesados en cumplimiento con los estándares internacionales de seguridad y de acuerdo con las disposiciones de esta Política de Privacidad.",
  "p-privacy-policy-transmission-security":
    "Aunque la transmisión de información a través de Internet no es completamente segura, intentaremos proteger sus Datos Personales. Teniendo esto en cuenta, no podemos, sin embargo, garantizar la seguridad de los canales que utiliza para transmitir su información personal a nuestro Sitio. En este sentido, usted es el único responsable de posibles fallos en la transmisión de sus Datos Personales al Sitio. Una vez que recibamos sus Datos Personales, utilizaremos procedimientos estrictos y todas las medidas técnicas y de seguridad necesarias para evitar el acceso no autorizado a sus Datos Personales.",
  "p-privacy-policy-grounds":
    "6. BASES PARA EL PROCESAMIENTO DE DATOS PERSONALES",
  "p-privacy-policy-grounds-intro":
    "Recopilamos y procesamos sus Datos Personales solo cuando tenemos una base legal para dicho procesamiento. Las bases legales incluyen:",
  "p-privacy-policy-consent": "su consentimiento;",
  "p-privacy-policy-agreement-terms":
    "los términos del acuerdo entre usted y SOLIDSWAP;",
  "p-privacy-policy-legal-obligations":
    "cumplimiento de obligaciones legales donde SOLIDSWAP está obligado a solicitar/recibir y procesar y almacenar sus datos personales para cumplir con las leyes aplicables, como las leyes relacionadas con el lavado de dinero y la financiación del terrorismo;",
  "p-privacy-policy-legal-interests":
    "intereses legales, por ejemplo, cuando el procesamiento es necesario para protegerlo a usted o a nosotros de ciertas amenazas como fraude, amenazas de seguridad, etc.;",
  "p-privacy-policy-business-compliance":
    "cumplimiento de las leyes aplicables para llevar a cabo nuestro negocio (calidad de los servicios prestados, identificación de necesidades, preparación de informes) de acuerdo con los requisitos regulatorios;",
  "p-privacy-policy-corporate-transactions":
    "realización de transacciones corporativas (venta de acciones/participaciones de participantes, fusiones, adquisiciones), etc.",
  "p-privacy-policy-withdraw-consent":
    "En los casos en que la base para el procesamiento de Datos Personales sea su consentimiento, usted tiene el derecho de retirarlo en cualquier momento.",
  "p-privacy-policy-rights": "7. SUS DERECHOS",
  "p-privacy-policy-rights-intro":
    "Al contactarnos, puede ejercer los siguientes derechos:",
  "p-privacy-policy-rights-erase":
    "el derecho a borrar todos o parte de sus Datos Personales (considerando, sin embargo, nuestros requisitos regulatorios para retener sus Datos Personales como se establece en el Artículo 9 de esta Política de Privacidad);",
  "p-privacy-policy-rights-correct":
    "el derecho a cambiar o corregir sus Datos Personales, en particular cuando se ingresen incorrectamente;",
  "p-privacy-policy-rights-object":
    "el derecho a oponerse o restringir el procesamiento de sus Datos Personales;",
  "p-privacy-policy-rights-access":
    "el derecho a acceder a sus Datos Personales y/o una copia de sus Datos Personales que proporcionó a SOLIDSWAP en un formato legible por máquina.",
  "p-privacy-policy-rights-marketing":
    "Además, tiene el derecho de prohibir el uso de sus Datos para fines de marketing. Antes de solicitar sus Datos, generalmente solicitaremos su consentimiento para divulgarlo a terceros para fines de marketing. Puede ejercer el derecho de oponerse al procesamiento de sus Datos para fines de marketing marcando la casilla correspondiente en el formulario. También puede ejercer este derecho contactándonos en cualquier momento.",
  "p-privacy-policy-rights-limitations":
    "Tenga en cuenta que el ejercicio de algunos de sus derechos, dependiendo de la situación, puede limitar, complicar o eliminar por completo la posibilidad de continuar la cooperación con nosotros.",
  "p-privacy-policy-rights-active-links":
    "Nuestro Sitio puede contener enlaces activos hacia/desde los sitios web de socios, anunciantes y Afiliados. Si sigue algún enlace activo, tenga en cuenta que otros sitios web tienen sus propias políticas de privacidad y no somos responsables de las disposiciones de estas políticas. Verifique las políticas de privacidad de estos sitios web antes de enviar su Información Personal a ellos.",
  "p-privacy-policy-access": "8. ACCESO A LOS DATOS PERSONALES",
  "p-privacy-policy-access-intro":
    "Usted tiene el derecho de acceder a la información que tenemos sobre usted. Puede ejercer el derecho de acceso de acuerdo con su solicitud por escrito enviada a la dirección de correo electrónico a continuación, le proporcionaremos la información que tenemos sobre usted. En el caso de que su solicitud sea visiblemente infundada o excesiva, o solicite copias adicionales de sus Datos Personales como se solicitó originalmente, podemos cobrarle una tarifa adicional.",
  "p-privacy-policy-retention": "9. RETENCIÓN DE DATOS PERSONALES",
  "p-privacy-policy-retention-intro":
    "Asegurar la seguridad de sus Datos Personales es nuestra máxima prioridad. Utilizamos almacenamiento seguro para garantizar la seguridad de sus Datos Personales que comparte con nosotros en todas las etapas de interacción con nuestra Plataforma, utilizando nuestros productos y Servicios. Nuestro servicio de seguridad toma todas las medidas apropiadas para proteger sus Datos Personales de ataques de piratas informáticos, pérdida o uso indebido.",
  "p-privacy-policy-retention-period":
    "Retendremos sus Datos Personales solo mientras use nuestros productos y Servicios a través de su Cuenta, sujeto a los propósitos descritos en esta Política de Privacidad y las obligaciones legales y regulatorias que cumplimos. De acuerdo con nuestras obligaciones de mantenimiento de registros, retendremos información sobre su Cuenta y sus Datos Personales por un período de al menos cinco (5) años a partir de la fecha en que la cierre o del final de su relación con nosotros como cliente, a menos que se requiera lo contrario por razones legales o regulatorias.",
  "p-privacy-policy-retention-extended":
    "Podemos acceder o retener su Información Personal por un período más largo si es objeto de una solicitud formal u obligación legal, una investigación gubernamental, o una investigación por posibles violaciones de nuestro Acuerdo de Usuario y sus partes integrales, o de otro modo para prevenir posibles daños basados en nuestras consideraciones razonables.",
  "p-privacy-policy-unsubscribe":
    "Si se da de baja de nuestro boletín de correo electrónico, almacenaremos su correo electrónico en una lista de correos inactiva.",
  "p-privacy-policy-changes": "10. CAMBIOS A LA POLÍTICA DE PRIVACIDAD",
  "p-privacy-policy-changes-intro":
    "Cualquier cambio realizado en la Política de Privacidad se publicará en esta página y, si es necesario, se enviará a su dirección de correo electrónico. Le recomendamos que revise esta página regularmente para mantenerse informado de cualquier actualización o cambio en la Política de Privacidad.",
  "p-privacy-policy-contact": "11. CONTACTO",
  "p-privacy-policy-contact-description":
    "Si tiene alguna pregunta, queja o duda con respecto a nuestra Política de Privacidad, Política de Cookies o los métodos y propósitos del procesamiento de sus Datos Personales, puede contactarnos en",
};
