export default {
  "p-trade-head-title":
    "Comercia Criptomonedas | Comercio Seguro de Activos Digitales en Solid Swap",
  "p-trade-head-description":
    "Comienza a comerciar criptomonedas de manera segura con Solid Swap. Disfruta de intercambios de activos digitales rápidos y eficientes en nuestra plataforma fácil de usar",
  "p-trade-head-keywords":
    "comercio de criptomonedas, comercio seguro, intercambios de activos digitales, plataforma de comercio de cripto",
  "p-trade-needLogin": "Necesitas iniciar sesión para crear una oferta",
  "p-trade-menu-buy": "Comprar",
  "p-trade-menu-sell": "Vender",
  "p-trade-menu-orders": "Pedidos",
  "p-trade-menu-myOffers": "Mis ofertas",
  "p-trade-menu-paymentOptions": "Opciones de pago",
  "p-trade-buying": "Comprando",
  "p-trade-selling": "Vendiendo",
  "p-trade-placeholder-type": "seleccionar tipo...",
  "p-trade-for": "para",
  "p-trade-placeholder-allOptions": "todas las opciones de pago",
  "p-trade-amountIn": "monto en",
  "p-trade-placeholder-anyAmount": "cualquier cantidad",
  "p-trade-minimumLimit": "el límite mínimo de comercio es",
  "p-trade-equivalent": "equivalente a {MIN_OFFER_AMOUNT_USD} USD",
  "p-trade-ALM": "Mostrar solo comerciantes verificados por ALM",
  "p-trade-postNewOffer": "Publicar nueva oferta",
};
