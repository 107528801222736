export default {
  "c-notifications-indexclient-connectError":
    "Error al conectar con las notificaciones",
  "c-notifications-indexclient-hideAllMessages": "Ocultar todos los mensajes",
  "c-notifications-indexclient-goToSettings":
    "ir a configuraciones de notificaciones",
  "c-notifications-indexclient-nothingNew": "no hay nada nuevo",
  "c-notifications-indexclient-viewOlder": "Ver más antiguos",
  "c-notifications-indexclient-viewAll": "Ver todos",
};
