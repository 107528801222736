export default {
  "p-kyc-aml-policy-title":
    "Հակաֆինանսավորման քաղաքականություն (Anti-Money Laundering Policy).",
  "p-kyc-warning":
    "SolidSwap-ը զգուշացնում է օգտատերերին ծառայությունը չօգտագործել հանցագործ եղանակով ստացված միջոցների օրինականացման, ահաբեկչության ֆինանսավորման, ցանկացած ապօրինի գործողությունների համար, ինչպես նաև արգելված ապրանքների և ծառայությունների գնման նպատակով:",
  "p-kyc-illegal-funds-description":
    "Հանցավոր եղանակով ստացված միջոցների օրինականացումը՝ ապօրինի աղբյուրի ծածկում է, դրանք օրինական տեսքով դարձնելով ֆոնդեր կամ ներդրումներ:",
  "p-kyc-general-provisions-title": "Ընդհանուր դրույթներ",
  "p-kyc-prevent-illegal-transactions":
    "Ապօրինի գործարքները կանխելու համար ծառայությունը սահմանում է որոշակի պահանջներ բոլոր հայտերի համար, որոնք ստեղծում են օգտատերերը:",
  "p-kyc-sender-recipient-same":
    "Վճարման հայտի ուղարկողն ու ստացողը պետք է լինեն նույն անձը: Տեղափոխումները երրորդ անձանց օգտին խստորեն արգելվում են:",
  "p-kyc-contact-info-requirements":
    "Օգտատերերի կողմից հայտում մուտքագրված բոլոր կոնտակտային տվյալները, ինչպես նաև այլ անձնական տվյալները, պետք է լինեն արդիական և ամբողջովին ճիշտ:",
  "p-kyc-no-anonymous-connections":
    "Խստորեն արգելվում է հայտերի ստեղծումը անանուն պրոքսի սերվերների կամ ցանկացած այլ անանուն ինտերնետային կապերի միջոցով:",
  "p-kyc-policy-description":
    "Հակաֆինանսավորման քաղաքականությունը (AML Policy) նկարագրում է SolidSwap-ի կողմից կիրառվող միջոցները, որոնք ուղղված են օրինականացման դեմ պայքարին:",
  "p-kyc-no-business-with-criminals":
    "չվարվել գործարար հարաբերություններ հանցագործների և/կամ ահաբեկիչների հետ:",
  "p-kyc-no-criminal-transactions":
    "չմշակել գործարքներ, որոնք հանդիսանում են հանցագործ և/կամ ահաբեկչական գործողությունների արդյունք:",
  "p-kyc-no-facilitating-criminal-activity":
    "չաջակցել գործարքներին, որոնք կապված են հանցագործ և/կամ ահաբեկչական գործողությունների հետ:",
  "p-kyc-right-to-restrict-services":
    "ունեն իրավունք սահմանափակել կամ արգելել ծառայությունների օգտագործումը...",
  "p-kyc-verification-procedures-title": "Վավերացման գործընթացներ",
  "p-kyc-kyc-policy-description":
    'SolidSwap-ը սահմանում է սեփական վավերացման գործընթացները հակաֆինանսավորման քաղաքականության շրջանակներում՝ "Ճանաչիր քո հաճախորդին" (KYC):',
  "p-kyc-verification-steps":
    "SolidSwap-ի օգտատերերը անցնում են վավերացման գործընթացը (ներկայացնել անձը հաստատող փաստաթուղթ՝ անձնագիր կամ նույնականացման քարտ):",
  "p-kyc-request-second-document":
    "SolidSwap-ը կարող է նաև պահանջել երկրորդ փաստաթուղթ՝ օգտագործողին նույնականացնելու համար...",
  "p-kyc-verification-authenticity":
    "SolidSwap-ը ստուգում է օգտագործողի տրամադրած փաստաթղթերի իսկությունը...",
  "p-kyc-request-updated-documents":
    "Եթե օգտագործողի անձնագիրային տեղեկությունները փոխվել են կամ նրանց գործունեությունը կասկածելի է...",
  "p-kyc-aml-compliance-title":
    "AML քաղաքականության համապատասխանության պատասխանատու",
  "p-kyc-aml-compliance-description":
    "AML քաղաքականության պատասխանատուն SolidSwap-ի աշխատակից է...",
  "p-kyc-collect-user-info":
    "հավաքում է օգտագործողի նույնականացման տեղեկությունները և փոխանցում...",
  "p-kyc-create-update-policies":
    "Ստեղծում և թարմացնում է ներքին քաղաքականություններ...",
  "p-kyc-monitor-transactions": "հետևում է գործարքներին և վերլուծում...",
  "p-kyc-record-management": "գործառնական համակարգեր գրառումների համար...",
  "p-kyc-regular-risk-update": "ռիսկերի թարմացում:",
  "p-kyc-interaction-with-authorities":
    "AML քաղաքականության պատասխանատուն իրավունք ունի համագործակցել իրավապահ մարմինների հետ...",
  "p-kyc-transaction-monitoring-title": "Գործարքների մոնիտորինգ",
  "p-kyc-monitoring-description":
    "Օգտատերերի գործարքների մոնիտորինգը և տվյալների վերլուծությունը...",
  "p-kyc-report-suspicious-transactions": "զեկուցել կասկածելի գործարքները...",
  "p-kyc-request-additional-info": "պահանջել լրացուցիչ տեղեկություններ...",
  "p-kyc-suspend-terminate-account": "դադարեցնել կամ փակել հաշիվը...",
  "p-kyc-freeze-assets": "սառեցնել ակտիվները...",
  "p-kyc-return-funds": "վերադարձնել միջոցները...",
  "p-kyc-risk-assessment": "ռիսկերի գնահատում:",
  "p-kyc-additional-monitoring":
    "AML պատասխանատուն ամեն օր ստուգում է գործարքները...",
};
