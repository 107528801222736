export default {
  "c-widget-news-moreNews": "more news",

  "c-widget-Search-crypto": "Crypto:",
  "c-widget-Search-fiat": "Fiat:",
  "c-widget-Search-amount": "amount to",
  "c-widget-Search-send": "send",
  "c-widget-Search-recieve": "recieve",
  "c-widget-Search-buy": "buy",
  "c-widget-Search-sell": "sell",
  "c-widget-Search-find": "Find",
  "c-widget-Search-selling-offers": "Selling offers",
  "c-widget-Search-selling": "selling",
  "c-widget-Search-buying-offers": "Buying offers",
  "c-widget-Search-buying": "buying",
  "c-widget-Search-price": "price",
  "c-widget-Search-available": "available",
  "c-widget-Search-nothing-found":
    "nothing found, try to find different options",
  "c-widget-Search-close": "close",
  "c-widget-Search-show-more": "show more",

  "c-widget-Stats-total-users": "Total users",
  "c-widget-Stats-av-reg-per-day": "av. reg. per day",
  "c-widget-Stats-daily-active-users": "Daily active users",
  "c-widget-Stats-daily-active-users-tip":
    "Number of users who logged into the product in one day",
  "c-widget-Stats-than-yesterday": "than yesterday",
  "c-widget-Stats-total-orders": "Total orders",
  "c-widget-Stats-av-orders-per-day": "av. orders per day",
  "c-widget-Stats-total-offers": "Total offers",
  "c-widget-Stats-new-offers-24h": "new offers in 24h",

  "c-widget-TipTap-client-toggle-fullscreen": "Toggle fullscreen",
  "c-widget-TipTap-client-upload-image": "Upload image",
  "c-widget-TipTap-client-drop-file-here": "Drop file here or",
  "c-widget-TipTap-client-click-to-upload": "click to upload",
  "c-widget-TipTap-client-jpg-png-files":
    "jpg/png files with a size less than 2MB",
  "c-widget-TipTap-client-heading-1": "Heading 1",
  "c-widget-TipTap-client-heading-2": "Heading 2",
  "c-widget-TipTap-client-heading-3": "Heading 3",
  "c-widget-TipTap-client-paragraph": "Paragraph",
  "c-widget-TipTap-client-image": "Image",
  "c-widget-TipTap-client-divider": "divider",
  "c-widget-TipTap-client-break": "break",
  "c-widget-TipTap-client-clear-marks": "clear marks",
  "c-widget-TipTap-client-please-input-link": "Please input link to paste",
  "c-widget-TipTap-client-tip": "Tip",
  "c-widget-TipTap-client-ok": "OK",
  "c-widget-TipTap-client-cancel": "Cancel",
  "c-widget-TipTap-client-input-canceled": "Input canceled",
  "c-widget-TipTap-client-picture-format": "Picture must be jpg/png format!",
  "c-widget-TipTap-client-picture-size": "Picture size can not exceed 2MB!",
};
