export default {
  "p-trade-orders-title":
    "Commandes de Trading | Gérez vos commandes crypto sur Solid Swap",
  "p-trade-orders-description":
    "Gérez vos commandes de cryptomonnaies efficacement sur Solid Swap. Suivez et contrôlez vos activités de trading de manière fluide",
  "p-trade-orders-keywords":
    "gérer les commandes crypto, commandes de trading, suivi des commandes de cryptomonnaies, gestion de trading",

  "p-trade-orders-order-fetch-error":
    "Une erreur est survenue lors du chargement de la commande, veuillez recharger la page",
  "p-trade-orders-order-not-found": 'Commande "{orderId}" non trouvée',
  "p-trade-orders-payment-was-sent": "Le paiement a été envoyé",
  "p-trade-orders-payment-was-sent-confirm":
    "Confirmez-vous que le paiement a été envoyé de votre côté ? Faites-le uniquement après confirmation de la banque. Continuer ?",
  "p-trade-orders-payment-received": "Paiement reçu",
  "p-trade-orders-payment-received-confirm":
    "Confirmez-vous que le paiement a été reçu ? Faites-le uniquement après confirmation de votre banque. Si vous souhaitez continuer, saisissez le code 2FA de votre application ci-dessous",
  "p-trade-orders-open-dispute": "Ouvrir un litige",
  "p-trade-orders-open-dispute-confirm":
    "Vous souhaitez ouvrir un litige pour cette commande. Continuer ?",
  "p-trade-orders-change-status": "Changer le statut",
  "p-trade-orders-change-status-confirm":
    "Êtes-vous sûr de vouloir changer le statut ?",
  "p-trade-orders-continue": "Continuer",
  "p-trade-orders-cancel": "Annuler",
  "p-trade-orders-invalid-2fa": "2FA invalide",
  "p-trade-orders-status-changed": "Statut de la commande modifié",
  "p-trade-orders-fetch-order-error":
    "Une erreur s'est produite lors de la récupération des données de la commande",
  "p-trade-orders-go-to-all-orders": "Aller à toutes les commandes",
  "p-trade-orders-status": "Statut",
  "p-trade-orders-waiting-for-payment": "En attente du paiement de l'acheteur",
  "p-trade-orders-payment-sent": "L'acheteur a envoyé le paiement",
  "p-trade-orders-order-completed": "Commande terminée",
  "p-trade-orders-order-cancelled": "Commande annulée",
  "p-trade-orders-dispute": "LITIGE",
  "p-trade-orders-auto-cancel-after":
    "Annulation automatique de la commande après",
  "p-trade-orders-open-dispute-after": "Le litige peut être ouvert après",
  "p-trade-orders-order-created": "Commande créée",
  "p-trade-orders-summary": "Résumé",
  "p-trade-orders-sell": "VENDRE",
  "p-trade-orders-buy": "ACHETER",
  "p-trade-orders-for": "pour",
  "p-trade-orders-fee": "Frais",
  "p-trade-orders-included-in-transaction": "inclus dans la transaction",
  "p-trade-orders-selling": "Vente",
  "p-trade-orders-buying": "Achat",
  "p-trade-orders-price": "Prix",
  "p-trade-orders-offer-creator": "Créateur de l'offre",
  "p-trade-orders-trader-info": "Informations fournies par le commerçant",
  "p-trade-orders-no-description":
    "Aucune description spécifiée par le commerçant.",
  "p-trade-orders-no-description-additional-info":
    "Vous recevrez des informations supplémentaires après la création de la commande.",
  "p-trade-orders-trading-experience":
    "Comment s'est passée votre expérience de trading ?",
  "p-trade-orders-positive": "Positive",
  "p-trade-orders-negative": "Négative",
  "p-trade-orders-your-feedback": "Votre retour",

  "p-trade-ordersIndex-all": "Tout",
  "p-trade-ordersIndex-active": "Actif",
  "p-trade-ordersIndex-completed": "Terminé",
  "p-trade-ordersIndex-cancelled": "Annulé",
  "p-trade-ordersIndex-dispute": "Litige",
  "p-trade-ordersIndex-coin": "Pièce",
  "p-trade-ordersIndex-order-type": "Type de commande",
  "p-trade-ordersIndex-dates": "Dates",
  "p-trade-ordersIndex-reset-filters": "réinitialiser les filtres",
  "p-trade-ordersIndex-loading-error":
    "Quelque chose a mal tourné pendant le chargement de la commande :(",
  "p-trade-ordersIndex-orders": "commandes",
  "p-trade-ordersIndex-id-type": "ID / Type",
  "p-trade-ordersIndex-operation-info": "Informations sur l'opération",
  "p-trade-ordersIndex-status-date": "Statut / Date",
  "p-trade-ordersIndex-id": "ID",
  "p-trade-ordersIndex-sell": "VENDRE",
  "p-trade-ordersIndex-buy": "ACHETER",
  "p-trade-ordersIndex-go-to-details": "aller aux détails",
  "p-trade-ordersIndex-summary": "Résumé",
  "p-trade-ordersIndex-fee": "Frais",
  "p-trade-ordersIndex-selling": "Vente",
  "p-trade-ordersIndex-buying": "Achat",
  "p-trade-ordersIndex-for": "Pour",
  "p-trade-ordersIndex-price": "Prix",
  "p-trade-ordersIndex-offer-creator": "Créateur de l'offre",
  "p-trade-ordersIndex-order-created": "Commande créée",
  "p-trade-ordersIndex-status": "Statut",
  "p-trade-ordersIndex-waiting-for-buyer":
    "En attente du paiement de l'acheteur",
  "p-trade-ordersIndex-buyer-sent-payment": "L'acheteur a envoyé le paiement",
  "p-trade-ordersIndex-order-completed": "Commande terminée",
  "p-trade-ordersIndex-order-cancelled": "Commande annulée",
  "p-trade-ordersIndex-last7days": "Les 7 derniers jours",
  "p-trade-ordersIndex-lastMonth": "Le mois dernier",
  "p-trade-ordersIndex-last3month": "Les 3 derniers mois",
  "p-trade-ordersIndex-dispute-caps": "LITIGE",
};
