export default {
  "p-beginners-guide-head-title":
    "初学者指南 | 在 Solid Swap 上开始交易加密货币",
  "p-beginners-guide-head-description":
    "新手如何进行加密货币交易？我们的初学者指南将帮助您通过简单易懂的步骤开始交易数字资产，并提供宝贵的交易提示",
  "p-beginners-guide-head-keywords":
    "初学者指南，加密货币交易，入门，交易提示，数字资产",
  "p-beginners-guide-account-preparation-title": "P2P 交易账户准备",
  "p-beginners-guide-register-title": "在 Solid Swap 注册",
  "p-beginners-guide-two-factor-title": "启用双重身份验证",
  "p-beginners-guide-payment-method-title": "添加支付方式",
  "p-beginners-guide-finding-offers-title-1": "寻找报价并创建报价",
  "p-beginners-guide-select-currency-title-1": "选择货币对",
  "p-beginners-guide-search-offer-title-1": "寻找合适的报价",
  "p-beginners-guide-create-offer-title-1": "创建交换报价",
  "p-beginners-guide-commissions-title-1": "佣金",
  "p-beginners-guide-completing-transaction-title-1": "完成交易",
  "p-beginners-guide-intro-1":
    "您好！本指南将帮助您在 Solid Swap 上进行首次交易。它将描述所有关键步骤，遵循这些步骤，您可以轻松、安全地完成交易。我们已经尽可能地使这一过程易于理解和操作，因此即使您是加密货币新手，也不要担心——我们将陪伴您每一步。",
  "p-beginners-guide-intro-2":
    "让我们从基础开始：什么是 Solid Swap 以及为什么它是加密货币交易的理想选择。Solid Swap 是一个快速交换加密货币和法币的平台，目前提供点对点（P2P）服务。我们提供安全且有利可图的交换，具有竞争力的汇率，低费用和高安全性。",
  "p-beginners-guide-steps-title": "在 Solid Swap 上成功交易的关键步骤：",
  "p-beginners-guide-follow-steps":
    "按照我们的说明，您可以快速、安全、自信地在 Solid Swap 上进行首次交易。",
  "p-beginners-guide-step1-title": "步骤 1：注册",
  "p-beginners-guide-register-instructions": "要在 Solid Swap 上注册：",
  "p-beginners-guide-register-step1": "点击屏幕右上角的“注册”按钮。",
  "p-beginners-guide-register-step2": "填写所需信息并点击“继续”。",
  "p-beginners-guide-register-step3":
    "检查您注册的邮箱并点击邮件中的链接确认您的邮箱地址。",
  "p-beginners-guide-register-step4": "登录您的账户。",
  "p-beginners-guide-step2-title": "步骤 2：启用双重身份验证",
  "p-beginners-guide-two-factor-instructions":
    "要进行交易，您需要启用双重身份验证。双重身份验证对于保护您的资产和账户尤为重要。为此：",
  "p-beginners-guide-two-factor-step1":
    "点击屏幕右上角的个人资料图标，并点击“设置”按钮进入设置。",
  "p-beginners-guide-two-factor-step2": "点击红色的“启用双重身份验证”按钮。",
  "p-beginners-guide-two-factor-step3":
    "扫描屏幕上显示的二维码，将其与您的 Solid Swap 账户关联。",
  "p-beginners-guide-two-factor-note":
    "如果您没有双重身份验证应用程序，请不要担心。您可以在智能手机上下载以下应用程序之一：",
  "p-beginners-guide-two-factor-app1":
    "Google Authenticator（适用于 Android 和 iOS）",
  "p-beginners-guide-two-factor-app2": "Authy（适用于 Android 和 iOS）",
  "p-beginners-guide-two-factor-app3":
    "Microsoft Authenticator（适用于 Android 和 iOS）",
  "p-beginners-guide-faq":
    "有关连接双重身份验证的详细信息以及其他常见问题的答案，请访问我们的 [FAQ](link to FAQ)。",
  "p-beginners-guide-step3-title": "步骤 3：添加支付方式",
  "p-beginners-guide-payment-method-instructions":
    "要进行任何交换，您需要添加支付方式。为此：",
  "p-beginners-guide-payment-method-step1":
    "在您的账户设置中，进入“支付选项”标签。",
  "p-beginners-guide-payment-method-step2": "点击“+ 添加新支付选项”按钮。",
  "p-beginners-guide-payment-method-step3":
    "从下拉列表中选择一种方便的支付方式。",
  "p-beginners-guide-payment-method-step4":
    "在“写描述”字段中，您可以指定其他信息。这将在您作为卖方时显示。例如，接受货币的银行卡号或加密钱包。",
  "p-beginners-guide-finding-offers-title": "寻找报价并选择卖家",
  "p-beginners-guide-select-currency-title": "步骤 1：选择货币对",
  "p-beginners-guide-select-currency-step1":
    "进入页面顶部面板上的 P2P 交易标签。",
  "p-beginners-guide-select-currency-step2":
    "选择您计划进行的操作：购买或出售。",
  "p-beginners-guide-select-currency-step3": "选择交易中将使用的支付方式。",
  "p-beginners-guide-select-currency-step4":
    "输入您计划交换的货币金额，以查看所有合适的报价。",
  "p-beginners-guide-select-currency-note": "货币对也可以在网站主页上选择。",
  "p-beginners-guide-search-offer-title": "步骤 2：寻找合适的报价",
  "p-beginners-guide-search-offer-description": "选择最适合您的交换报价。",
  "p-beginners-guide-search-offer-note": "选择卖家时，请注意以下指标：",
  "p-beginners-guide-search-offer-seller-rating":
    "卖家评级：每个卖家都有一个基于以前买家反馈的评级。选择高评级的卖家以增加信心。",
  "p-beginners-guide-search-offer-transactions":
    "已完成的交易数量：注意卖家成功进行的交易数量。交易数量多的卖家通常更可靠。",
  "p-beginners-guide-search-offer-reviews":
    "评论：阅读其他用户对卖家的评论。这将帮助您评估他们的声誉和提供的服务质量。您可以在卖家的个人资料中查看这些信息。",
  "p-beginners-guide-search-offer-limits":
    "交易金额限制：检查卖家愿意交换的最小和最大金额。确保这些符合您的要求。",
  "p-beginners-guide-create-offer-title": "步骤 3：选择报价",
  "p-beginners-guide-create-offer-step1":
    "找到合适的报价后，点击旁边的绿色“购买”按钮查看详情。",
  "p-beginners-guide-create-offer-step2":
    "查看交易条款，包括汇率、最小和最大金额以及支付方式。",
  "p-beginners-guide-create-offer-step3":
    "如果您同意条款，可以继续进行交易。为此，在打开的窗口中填写空白字段并选择支付方式。点击“购买”按钮。",
  "p-beginners-guide-create-offer-step4":
    "如果没有适合的报价，您可以创建自己的报价。为此：",
  "p-beginners-guide-create-offer-note1": "点击“+ 发布新报价”按钮。",
  "p-beginners-guide-create-offer-note2": "填写所需信息的字段。",
  "p-beginners-guide-create-offer-note3":
    "在描述中，您可以指定有关交易的其他信息。",
  "p-beginners-guide-create-offer-note4": "点击“确认”按钮。",
  "p-beginners-guide-create-offer-note5":
    "之后，您将在“我的报价”部分找到您创建的所有报价。要使报价对其他用户可见，请更改其状态。您可以通过点击报价右侧的相应开关来完成此操作。",
  "p-beginners-guide-commissions-title": "请注意！",
  "p-beginners-guide-commissions-description":
    "我们在工作的各个方面都追求透明，包括佣金。交易佣金仅为交易金额的 0.2%，由卖方支付。这使您能够轻松计划交易，知道不会有意外费用。",
  "p-beginners-guide-completing-transaction-title": "完成交易",
  "p-beginners-guide-completing-transaction-description1":
    "找到合适的交换报价并点击“购买”按钮后，您将进入交易页面。此处有所有交换信息。我们强烈建议您检查以确保准确性并避免错误交易！",
  "p-beginners-guide-completing-transaction-description2":
    "在交易页面上，您还会找到与卖家的聊天。在这里，您可以澄清交换条款或其他感兴趣的信息。",
  "p-beginners-guide-completing-transaction-description3": "在活跃交易窗口中：",
  "p-beginners-guide-completing-transaction-step1":
    "检查交换的条件和详细信息。",
  "p-beginners-guide-completing-transaction-step2":
    "向卖方指定的支付方式进行转账。",
  "p-beginners-guide-completing-transaction-step3":
    "点击“付款已发送”按钮确认转账。",
  "p-beginners-guide-completing-transaction-step4":
    "等待卖方确认付款并将资金转入您的账户。",
  "p-beginners-guide-completing-transaction-step5":
    "留下交易评论：评论帮助用户了解与谁合作以及谁应该避免。我们感谢您为在我们平台上创建一个有利环境所做的努力！",
  "p-beginners-guide-active-transactions-note1":
    "您可以在 P2P 交易部分的“订单”标签下找到您的活跃交易。",
  "p-beginners-guide-active-transactions-note2":
    "如果出现争议，您可以在确认付款转账后 30 分钟内开启争议。计时器到期后，此按钮将出现在交易页面上。",
  "p-beginners-guide-active-transactions-note3":
    "如果您决定出售货币并创建了销售报价，请等待有人响应。当这种情况发生时，您会收到通知。",
  "p-beginners-guide-active-transactions-note4": "找到买家后，继续进行交换。",
  "p-beginners-guide-active-transactions-note5": "检查交换的条件和详细信息。",
  "p-beginners-guide-active-transactions-note6": "检查买家是否已付款。",
  "p-beginners-guide-active-transactions-note7":
    "点击“付款已收到”按钮确认收到款项。",
  "p-beginners-guide-active-transactions-note8":
    "将商定金额发送到买家指定的收款方式。",
  "p-beginners-guide-ending-note1":
    "完成了！您已成功完成在 Solid Swap 上的首次交易。我们希望本指南能帮助您轻松完成所有步骤。",
  "p-beginners-guide-ending-note2":
    "进行加密货币交换时，请始终牢记安全。始终检查交易条款和卖家信誉，使用双重身份验证，并在转账时保持谨慎。如果您有任何疑问或问题，我们的支持服务随时为您提供帮助。",
  "p-beginners-guide-ending-note3":
    "感谢您选择 Solid Swap。祝您交易成功、安全！",
  "p-beginners-guide-ending-note4":
    "如果您有任何疑问或问题，我们的支持服务随时为您提供帮助。",
  "p-beginners-guide-ending-note5":
    "感谢您选择 Solid Swap。祝您交易成功、安全！",
};
