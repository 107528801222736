export default {
  "c-exchange-reqItem-cancelling-request":
    "Stornierung Ihrer Austausch-Anfrage",
  "c-exchange-reqItem-cancel-request-confirm":
    "Sind Sie sicher, dass Sie die Anfrage stornieren möchten?",
  "c-exchange-reqItem-confirming-documents": "Bestätigung Ihrer Dokumente",
  "c-exchange-reqItem-upload-documents-instructions":
    "Stellen Sie sicher, dass die Bilder Ihres Dokuments eine hohe Auflösung haben und alle Ziffern deutlich sichtbar sind",
  "c-exchange-reqItem-confirm-documents-confirmation":
    "Möchten Sie mit der Dokumentenbestätigung fortfahren?",
  "c-exchange-reqItem-select-payment-option": "Zahlungsoption auswählen",
  "c-exchange-reqItem-proceed-with-payment-option":
    "Möchten Sie mit der ausgewählten Zahlungsoption fortfahren?",
  "c-exchange-reqItem-confirming-payment": "Zahlung bestätigen",
  "c-exchange-reqItem-confirm-payment":
    "Möchten Sie mit der Bestätigung Ihrer Zahlung fortfahren?",
  "c-exchange-reqItem-completing-request": "Anfrage abschließen",
  "c-exchange-reqItem-provide-txid":
    "Um die Anfrage abzuschließen, müssen Sie die Transaktions-ID Ihres Transfers angeben",
  "c-exchange-reqItem-enter-txid-error": "Bitte geben Sie hier txId ein",
  "c-exchange-reqItem-enter-txid-placeholder": "Geben Sie hier txId ein",
  "c-exchange-reqItem-verifying-card": "Überprüfung der Benutzerkarte",
  "c-exchange-reqItem-moderator-verifying": "Moderator überprüft Daten",
  "c-exchange-reqItem-waiting-alm": "Warten auf ALM-Ergebnisse",
  "c-exchange-reqItem-preparing-crypto": "Vorbereitung der Plattformadresse",
  "c-exchange-reqItem-preparing-fiat": "Vorbereitung der Fiat-Angaben",
  "c-exchange-reqItem-sending-crypto": "Senden von Krypto",
  "c-exchange-reqItem-sending-fiat": "Senden von Fiat",
  "c-exchange-reqItem-waiting-payment": "Warten auf die Zahlung des Benutzers",
  "c-exchange-reqItem-completed": "Abgeschlossen",
  "c-exchange-reqItem-failed": "Fehlgeschlagen",
  "c-exchange-reqItem-cancelled": "Abgebrochen",
  "c-exchange-reqItem-cancel-exchange-request": "Austauschanfrage stornieren",
  "c-exchange-reqItem-confirm-documents": "Dokumente bestätigen",
  "c-exchange-reqItem-documents-confirmed": "Dokumente wurden bestätigt",
  "c-exchange-reqItem-payment-sent": "Zahlung wurde gesendet",
  "c-exchange-reqItem-confirm-user-payment": "Zahlung des Benutzers bestätigen",
  "c-exchange-reqItem-complete-request": "Anfrage abschließen",
  "c-exchange-reqItem-id": "ID",
  "c-exchange-reqItem-exchanging": "Austausch",
  "c-exchange-reqItem-crypto-address": "Krypto-Adresse des Benutzers",
  "c-exchange-reqItem-fiat-card-number": "Fiat-Kartennummer des Benutzers",
  "c-exchange-reqItem-request-failed": "Anfrage fehlgeschlagen",
  "c-exchange-reqItem-alm-link": "ALM-Ergebnis-Link",
  "c-exchange-reqItem-check-here": "hier überprüfen",
  "c-exchange-reqItem-request-cancelled": "Anfrage abgebrochen",
  "c-exchange-reqItem-request-fulfilled": "Ihre Anfrage wurde erfüllt!",
  "c-exchange-reqItem-thank-you":
    "Vielen Dank, dass Sie uns als Ihren bevorzugten Austauschdienst gewählt haben. Wir schätzen Ihr Vertrauen in uns und freuen uns darauf, Ihnen wieder zu dienen!",
  "c-exchange-reqItem-address-preparation":
    "Hier wird die Adresse für Ihre Übertragung angezeigt.",
  "c-exchange-reqItem-security-check":
    "Bitte warten Sie, bis alle Vorbereitungen und Sicherheitsüberprüfungen abgeschlossen sind.",
  "c-exchange-reqItem-waiting-for-transaction":
    "Die Plattform wartet auf Ihre Transaktion.",
  "c-exchange-reqItem-transfer-here": "Überweisen Sie die Mittel hierher",
  "c-exchange-reqItem-wallet": "Wallet",
  "c-exchange-reqItem-payment-option": "Zahlungsoption",
  "c-exchange-reqItem-card-number": "Kartennummer",
  "c-exchange-reqItem-description": "Beschreibung",
  "c-exchange-reqItem-balance": "Kontostand",
  "c-exchange-reqItem-payment-instructions":
    "Nachdem Sie die Zahlung getätigt haben, teilen Sie bitte ein Bild der Rechnung (oder einen Screenshot) Ihrer Zahlung im Chat",
  "c-exchange-reqItem-verification-required": "Identitätsprüfung erforderlich",
  "c-exchange-reqItem-upload-photo-instructions":
    "Um Ihre Austausch-Anfrage fortzusetzen, müssen Sie ein Foto von sich hochladen, auf dem Sie die Kreditkarte halten, die Sie verwenden möchten. Stellen Sie bitte sicher, dass:",
  "c-exchange-reqItem-photo-instruction-cardholder":
    "Der Name des Karteninhabers und alle Ziffern der Kartennummer deutlich sichtbar sind.",
  "c-exchange-reqItem-photo-instruction-face":
    "Ihr Gesicht auf dem Foto deutlich sichtbar ist.",
  "c-exchange-reqItem-click-confirm-documents":
    "Nachdem Sie das Foto im Chat hochgeladen haben, klicken Sie oben auf die Schaltfläche 'Dokumente bestätigen' und warten Sie, bis unser Moderator Ihre Informationen überprüft.",
  "c-exchange-reqItem-verification-in-progress": "Verifizierung läuft",
  "c-exchange-reqItem-moderator-review-in-progress":
    "Vielen Dank für das Hochladen Ihres Fotos. Bitte warten Sie, während unser Moderator Ihre Informationen überprüft. Dieser Vorgang kann einige Minuten dauern. Wir danken Ihnen für Ihre Geduld.",
  "c-exchange-reqItem-select-payment-option-description":
    "Wählen Sie eine Zahlungsoption aus der untenstehenden Liste. Die ausgewählte Option wird dem Kunden als Zahlungsmethode für die Übertragung von Geldern angezeigt.",
  "c-exchange-reqItem-proceed-with-selected-option":
    "Mit der ausgewählten Option fortfahren",
  "c-exchange-reqItem-alm-check":
    "Wir führen eine ALM-Prüfung auf Ihrem Wallet durch",
  "c-exchange-reqItem-preparing-address":
    "SolidSwap bereitet die Adresse für Ihre Krypto-Überweisung vor, bitte warten.",
  "c-exchange-reqItem-transfer-funds":
    "Überweisen Sie die Mittel an die unten angegebene Wallet und klicken Sie auf die Schaltfläche „Zahlung wurde gesendet“",
  "c-exchange-reqItem-confirming-payment-text":
    "Bitte warten Sie, während SolidSwap Ihre Zahlung bestätigt.",
  "c-exchange-reqItem-transfer-confirmed":
    "Ihre Überweisung ist bestätigt, bitte warten Sie, bis SolidSwap Ihre Mittel überweist",
  "c-exchange-reqItem-identity-verification": "Identitätsprüfung erforderlich",
  "c-exchange-reqItem-upload-photo":
    "Laden Sie ein Foto der Vorderseite der Karte mit dem Geschäft im Hintergrund hoch. Sie können die Kartendaten außer den Ziffern der Kartennummer verdecken.",
  "c-exchange-reqItem-virtual-card-intro":
    "Wenn Sie eine virtuelle Karte haben:",
  "c-exchange-reqItem-first-way": "Erster Weg:",
  "c-exchange-reqItem-first-way-desc":
    "Laden Sie einen Screenshot des Bildschirms mit zwei gleichzeitig geöffneten Fenstern hoch: Das erste Fenster zeigt die Transaktionsseite mit Ihrer Bestellnummer, das zweite Fenster zeigt die Kartendetails auf Ihrer Bankseite.",
  "c-exchange-reqItem-second-way": "Zweiter Weg:",
  "c-exchange-reqItem-second-way-desc":
    "Laden Sie ein Foto oder einen Screenshot Ihrer virtuellen Karte in der mobilen App oder auf der Website Ihrer Bank vor dem Hintergrund Ihrer aktuellen Bestellung hoch. Sie können die Kartendaten außer den Ziffern der Kartennummer verdecken.",
  "c-exchange-reqItem-waiting-moderator":
    "Warten Sie, bis der Moderator die hochgeladenen Dokumente überprüft",
  "c-exchange-reqItem-transfer-to-card":
    "Überweisen Sie die Mittel auf die unten angegebene Karte. Nachdem Sie die Zahlung abgeschlossen haben, teilen Sie bitte ein Bild der Rechnung (oder einen Screenshot) im Chat und klicken Sie auf die Schaltfläche „Zahlung wurde gesendet“",
  "c-exchange-reqItem-crypto-transfer-confirmed":
    "Ihre Überweisung ist bestätigt, bitte warten Sie, bis SolidSwap Ihre Mittel sendet",
  "c-exchange-reqItem-faq-promotion": "FAQ zur Promotion",
  "c-exchange-reqItem-solid-swap-top-service":
    "Wenn Sie sich für Solid Swap entscheiden, um Ihre digitalen und Fiat-Assets zu tauschen, erhalten Sie nicht nur einen erstklassigen Service, sondern auch die Chance, jeden Monat",
  "c-exchange-reqItem-add-to-account-monthly": "zu Ihrem Konto hinzuzufügen.",
  "c-exchange-reqItem-how-can-you-do-it": "Wie können Sie das tun?",
  "c-exchange-reqItem-register-and-exchange":
    "Registrieren Sie sich und führen Sie einen Tausch auf SolidSwap durch, jeder Tausch zählt als Teilnahme zur Verlosung.",
  "c-exchange-reqItem-leave-review-on":
    "Hinterlassen Sie Ihre ehrliche Bewertung auf einer der folgenden Ressourcen",
  "c-exchange-reqItem-send-email-screenshot":
    "Senden Sie Ihre E-Mail und einen Screenshot Ihrer Bewertung an",
  "c-exchange-reqItem-manager-telegram": "unseren Manager auf Telegram",
  "c-exchange-reqItem-or-email-to": "oder senden Sie eine E-Mail an",
  "c-exchange-reqItem-enter-giveaway":
    "um an der Verlosung von 300 USDT teilzunehmen.",
  "c-exchange-reqItem-promotion-period":
    "Die Aktion läuft vom 1. Oktober bis 31. Dezember 2024.",
  "c-exchange-reqItem-winners-selected-randomly":
    "Gewinner werden monatlich zufällig unter den Teilnehmern ausgewählt, die die Anforderungen erfüllen. Die Ergebnisse werden im",
  "c-exchange-reqItem-participate-again":
    "Um im nächsten Monat erneut teilzunehmen, müssen Sie einen weiteren Tausch durchführen und eine zusätzliche Bewertung hinterlassen.",
};
