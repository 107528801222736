export default {
  "p-trade-my-offers-title":
    "Mis Ofertas | Gestiona tus ofertas de cripto en Solid Swap",
  "p-trade-my-offers-description":
    "Ve y gestiona tus ofertas de criptomonedas en Solid Swap. Asegura estrategias de comercio óptimas con nuestra interfaz fácil de usar",
  "p-trade-my-offers-keywords":
    "gestionar ofertas de cripto, ofertas de criptomonedas, ofertas de comercio, ofertas de activos digitales",
  "p-trade-my-offers-delete-confirm":
    "¿Estás seguro de que deseas eliminar esta oferta?",
  "p-trade-my-offers-warning": "Advertencia",
  "p-trade-my-offers-ok": "OK",
  "p-trade-my-offers-cancel": "Cancelar",
  "p-trade-my-offers-post-new-offer": "Publicar nueva oferta",
  "p-trade-my-offers-no-active-offers": "No se encontraron ofertas activas",
  "p-trade-my-offers-your-selling-offers": "Tus ofertas de venta",
  "p-trade-my-offers-your-buying-offers": "Tus ofertas de compra",
};
