export default {
  "p-user-index-welcome": "Добро пожаловать в вашу персональную панель!",

  "p-user-notifications-settings": "Перейти к настройкам уведомлений",
  "p-user-notifications-all": "Все уведомления",
  "p-user-notifications-error": "Ошибка при получении уведомлений",
  "p-user-notifications-none": "Нет уведомлений",

  "p-user-settings-head-title":
    "Настройки пользователя | Управляйте вашим аккаунтом Solid Swap",
  "p-user-settings-head-description":
    "Управляйте настройками вашего аккаунта Solid Swap и предпочтениями.",
  "p-user-settings-title": "Настройки пользователя",
  "p-user-settings-id": "ID",
  "p-user-settings-username": "Имя пользователя",
  "p-user-settings-last-login": "Последний вход",

  "p-user-wallet-head-title":
    "Кошелек пользователя | Управляйте вашим аккаунтом Solid Swap",
  "p-user-wallet-title": "Транзакции кошелька",
  "p-user-wallet-incoming-internal": "Входящие внутренние",
  "p-user-wallet-outgoing-internal": "Исходящие внутренние",
  "p-user-wallet-incoming": "Входящие",
  "p-user-wallet-outgoing": "Исходящие",
  "p-user-wallet-address-copied": "Ваш публичный адрес был скопирован!",
  "p-user-wallet-using-network":
    "Мы используем сеть {network} для {currentTab}",
  "p-user-wallet-balance": "Ваш баланс:",
  "p-user-wallet-frozen-tooltip":
    "Сумма средств, которые в настоящее время заморожены в системе в заказах, переводах и т.д.",
  "p-user-wallet-frozen": "заморожено",
  "p-user-wallet-show-address": "Показать ваш публичный адрес {currentTab}",
  "p-user-wallet-copy": "Скопировать",
  "p-user-wallet-min-deposit-note-1":
    "Обратите внимание, что у нас есть минимальный лимит для депозита криптовалют.",
  "p-user-wallet-min-deposit-note-2":
    "Лимит составляет около 50 {symbol} в эквиваленте криптовалюты.",
  "p-user-wallet-current-rate": "По текущему курсу обмена:",
  "p-user-wallet-usd-symbol": "50 {symbol}",
  "p-user-wallet-tfa-alert":
    "Обратите внимание: Вывод средств не разрешен, пока двухфакторная аутентификация (2FA) не включена на вашем аккаунте.",
  "p-user-wallet-restriction-alert":
    "Ваш аккаунт в настоящее время ограничен для вывода средств. Пожалуйста, свяжитесь с поддержкой для получения дополнительной помощи",
  "p-user-wallet-withdraw": "Вывод {currentTab}",
  "p-user-wallet-history-title": "История транзакций:",
  "p-user-wallet-no-transactions": "Транзакции для {currentTab} не найдены",

  "p-user-head-title":
    "Страница пользователя | Управляйте вашим аккаунтом Solid Swap",
  "p-user-head-description":
    "Получите доступ и управляйте настройками вашего аккаунта Solid Swap, кошельком и личными предпочтениями безопасно",
  "p-user-head-keywords":
    "настройки пользователя, управление аккаунтом, крипто кошелек, пользователь Solid Swap",

  "p-user-partner-my-partnership": "Моё партнёрство",
  "p-user-partner-program-details": "Детали программы",
  "p-user-partner-example-link": "пример ссылки:",
  "p-user-partner-token": "Токен",
  "p-user-partner-update-date": "Дата обновления",
  "p-user-partner-type": "Тип",
  "p-user-partner-is-active": "Активен",
  "p-user-partner-value": "Значение",
  "p-user-partner-id": "ID партнёра",
  "p-user-partner-balances": "Баланс",
  "p-user-partner-note":
    "Примечание: 'Замороженный баланс' представляет собой сумму средств, находящихся в ожидании ордеров, переводов или других резервных действий.",
  "p-user-partner-currency": "Валюта",
  "p-user-partner-balance": "Баланс",
  "p-user-partner-frozen-balance": "Замороженный баланс",
  "p-user-partner-error": "Что-то пошло не так",
  "p-user-partner-tfa-required": "Zwei-Faktor-Authentifizierung erforderlich",
  "p-user-partner-tfa-description":
    "Bitte beachten: Der Zugriff auf Ihr Partnerprogramm ist nur möglich, wenn die Zwei-Faktor-Authentifizierung (TFA) in Ihrem Konto aktiviert ist.",
  "p-user-partner-enable-tfa":
    "Aktivieren Sie Ihre TFA in den Sicherheitseinstellungen, um auf das Partnerprogramm zuzugreifen.",
  "p-user-partner-go-to-tfa-settings": "Zu den 2FA-Einstellungen gehen",
  "p-user-partner-inactive-help":
    "Если вы не уверены, почему ваше партнёрство не активно,",
  "p-user-partner-contact-support": "свяжитесь с нашей поддержкой",
  "p-user-partner-apply-withdraw": "Подать заявку на вывод",
  "p-user-partner-transaction-history": "История транзакций",
  "p-user-partner-no-transactions": "Похоже, список транзакций пуст",
  "p-user-partner-withdraw-form": "Форма вывода",
  "p-user-partner-select-currency": "Выберите валюту",
  "p-user-partner-address": "Адрес",
  "p-user-partner-enter-address": "Введите свой крипто-адрес",
  "p-user-partner-amount": "Сумма",
  "p-user-partner-enter-amount": "Введите сумму",
  "p-user-partner-2fa-code": "Код 2FA",
  "p-user-partner-enter-2fa-code": "Введите код из вашего 2FA приложения",
  "p-user-partner-submit": "Отправить",
  "p-user-partner-bonuses": "Бонусы",
  "p-user-partner-withdrawals": "Выводы",
  "p-user-partner-visitors": "Посетители",
  "p-user-partner-enter-wallet-address":
    "Введите публичный адрес вашего кошелька",
  "p-user-partner-withdraw-success": "Успешно создан запрос на вывод средств",
  "p-user-partner-copy-success": "Скопировано в буфер обмена",
};
