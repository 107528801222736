export default {
  "p-trade-action-buy-title":
    "Kryptowährung kaufen | Sicheres Krypto-Kaufen auf Solid Swap",
  "p-trade-action-sell-title":
    "Kryptowährung verkaufen | Sicheres Krypto-Verkaufen auf Solid Swap",
  "p-trade-action-buy-description":
    "Kaufen Sie sicher Kryptowährungen auf Solid Swap. Profitieren Sie von schnellen Transaktionen und wettbewerbsfähigen Kursen für digitale Vermögenswerte",
  "p-trade-action-sell-description":
    "Verkaufen Sie Ihre Kryptowährung sicher auf Solid Swap. Genießen Sie schnelle Transaktionen und optimale Kurse für Ihre digitalen Vermögenswerte",
  "p-trade-action-buy-keywords":
    "kryptowährung kaufen, krypto-kaufen, sicheres vermögenskauf, digitalwährungshandel",
  "p-trade-action-sell-keywords":
    "kryptowährung verkaufen, krypto-verkaufen, sicheres vermögensverkauf, digitalwährungshandel",
  "p-trade-action-nothing-found":
    "nichts gefunden, versuchen Sie andere Optionen zu finden",
  "p-trade-action-clear-filters": "Alle Filter löschen",
};
