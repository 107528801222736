export default {
  "c-transaction-cancel-confirm":
    "Sind Sie sicher, dass Sie diese Transaktion stornieren möchten?",
  "c-transaction-cancel-withdrawal": "Auszahlung stornieren",
  "c-transaction-back": "Zurück",
  "c-transaction-cancel-error":
    "Etwas ist beim Stornieren der Transaktion schiefgelaufen!",
  "c-transaction-status": "Status",
  "c-transaction-amount": "Betrag",
  "c-transaction-fee": "Gebühr",
  "c-transaction-type": "Typ",
  "c-transaction-comment": "Kommentar",
  "c-transaction-address": "Adresse",
};
