export default {
  "p-user-index-welcome": "Բարի գալուստ ձեր անձնական վահանակին!",

  "p-user-notifications-settings": "Գնալ ծանուցումների կարգավորումներ",
  "p-user-notifications-all": "Բոլոր ծանուցումները",
  "p-user-notifications-error": "Ծանուցումների ստացման սխալ",
  "p-user-notifications-none": "Ծանուցումներ չկան",

  "p-user-settings-head-title":
    "Օգտատիրոջ կարգավորումներ | Կառավարեք Ձեր Solid Swap Հաշիվը",
  "p-user-settings-head-description":
    "Կառավարեք ձեր Solid Swap հաշվի կարգավորումները և նախընտրությունները:",
  "p-user-settings-title": "Օգտատիրոջ կարգավորումներ",
  "p-user-settings-id": "ID",
  "p-user-settings-username": "Օգտանուն",
  "p-user-settings-last-login": "Վերջին մուտք",

  "p-user-wallet-head-title":
    "Օգտատիրոջ դրամապանակ | Կառավարեք Ձեր Solid Swap Հաշիվը",
  "p-user-wallet-title": "Դրամապանակի գործարքներ",
  "p-user-wallet-incoming-internal": "Ներսից ներսում եկած",
  "p-user-wallet-outgoing-internal": "Ներսից դուրս եկած",
  "p-user-wallet-incoming": "Ներսում եկած",
  "p-user-wallet-outgoing": "Դուրսում եկած",
  "p-user-wallet-address-copied": "Ձեր հանրային հասցեն պատճենվել է!",
  "p-user-wallet-using-network":
    "Մենք օգտագործում ենք {network} ցանցը {currentTab}-ի համար",
  "p-user-wallet-balance": "Ձեր մնացորդը:",
  "p-user-wallet-frozen-tooltip":
    "Ֆոնդերի քանակը, որոնք ներկայումս սառեցված են համակարգում պատվերներում, փոխանցումներում և այլն:",
  "p-user-wallet-frozen": "սառեցված",
  "p-user-wallet-show-address": "Ցույց տալ ձեր հանրային {currentTab} հասցեն",
  "p-user-wallet-copy": "Պատճենել",
  "p-user-wallet-min-deposit-note-1":
    "Նշեք, որ մենք ունենք կրիպտոարժույթների մուտքի նվազագույն սահման:",
  "p-user-wallet-min-deposit-note-2":
    "Սահմանը մոտ 50 {symbol} համարժեք կրիպտոյում:",
  "p-user-wallet-current-rate": "Ընթացիկ փոխարժեքով:",
  "p-user-wallet-usd-symbol": "50 {symbol}",
  "p-user-wallet-tfa-alert":
    "Խնդրում ենք նկատի ունենալ: Ելքերը չեն թույլատրվում, եթե Երկու-ֆակտորային նույնականացումը (TFA) ակտիվացված չէ ձեր հաշվի վրա:",
  "p-user-wallet-restriction-alert":
    "Ձեր հաշիվը ներկայումս սահմանափակված է ելքերի գործառույթից: Խնդրում ենք դիմել աջակցությանը՝ հետագա օգնության համար",
  "p-user-wallet-withdraw": "Ելք {currentTab}",
  "p-user-wallet-history-title": "Գործարքների պատմություն:",
  "p-user-wallet-no-transactions": "Գործարքներ չեն գտնվել {currentTab}-ի համար",

  "p-user-head-title": "Օգտատեր էջ | Կառավարեք ձեր Solid Swap հաշիվը",
  "p-user-head-description":
    "Անվտանգ հասանելիություն և կառավարում ձեր Solid Swap հաշվի կարգավորումների, դրամապանակի և անձնական նախընտրությունների",
  "p-user-head-keywords":
    "օգտատեր կարգավորումներ, հաշվի կառավարում, կրիպտո դրամապանակ, Solid Swap օգտատեր",

  "p-user-partner-my-partnership": "Իմ գործընկերությունը",
  "p-user-partner-program-details": "Ծրագրի մանրամասները",
  "p-user-partner-example-link": "օրինակ հղում:",
  "p-user-partner-token": "Տոկեն",
  "p-user-partner-update-date": "Թարմացման ամսաթիվ",
  "p-user-partner-type": "Տեսակ",
  "p-user-partner-is-active": "Ակտիվ է",
  "p-user-partner-value": "Արժեք",
  "p-user-partner-id": "Գործընկեր ID",
  "p-user-partner-balances": "Հաշիվներ",
  "p-user-partner-note":
    "'Սառեցված հաշիվը' ներկայացնում է վճարման, փոխանցումների կամ այլ պահվող գործողությունների սպասվող գումարը։",
  "p-user-partner-currency": "Արժույթ",
  "p-user-partner-balance": "Հաշիվ",
  "p-user-partner-frozen-balance": "Սառեցված հաշիվ",
  "p-user-partner-error": "Ինչ-որ բան սխալ է",
  "p-user-partner-tfa-required": "Երկրորդային վավերացում պահանջվում է",
  "p-user-partner-tfa-description":
    "Խնդրում ենք նկատի ունենալ. Ձեր գործընկեր ծրագրին մուտք գործելու համար անհրաժեշտ է ակտիվացնել Երկրորդային վավերացումը (TFA) ձեր հաշվում:",
  "p-user-partner-enable-tfa":
    "Ակտիվացրեք ձեր Երկրորդային վավերացումը անվտանգության կարգավորումներում՝ գործընկեր ծրագրին մուտք գործելու համար:",
  "p-user-partner-go-to-tfa-settings": "Գնալ 2FA կարգավորումներ",
  "p-user-partner-inactive-help":
    "Եթե դուք չգիտեք, թե ինչու ձեր գործընկերությունը ակտիվ չէ,",
  "p-user-partner-contact-support": "կապվեք մեր աջակցման կենտրոնի հետ",
  "p-user-partner-apply-withdraw": "Դիմել ելքի համար",
  "p-user-partner-transaction-history": "Գործարքների պատմություն",
  "p-user-partner-no-transactions": "Կարծես թե գործարքների ցուցակը դատարկ է",
  "p-user-partner-withdraw-form": "Ելքի ձև",
  "p-user-partner-select-currency": "Ընտրեք արժույթը",
  "p-user-partner-address": "Հասցե",
  "p-user-partner-enter-address": "Մուտքագրեք ձեր կրիպտո հասցեն",
  "p-user-partner-amount": "Քանակ",
  "p-user-partner-enter-amount": "Մուտքագրեք քանակը",
  "p-user-partner-2fa-code": "2FA կոդ",
  "p-user-partner-enter-2fa-code": "Մուտքագրեք ձեր 2FA հավելվածից կոդը",
  "p-user-partner-submit": "Հաստատել",
  "p-user-partner-bonuses": "Բոնուսներ",
  "p-user-partner-withdrawals": "Ելք",
  "p-user-partner-visitors": "Այցելուներ",
  "p-user-partner-enter-wallet-address":
    "Մուտքագրեք ձեր դրամապանակի հանրային հասցեն",
  "p-user-partner-withdraw-success": "Հաջողությամբ ստեղծվեց ելքի հայտը",
  "p-user-partner-copy-success": "Պատճենվել է clipboard-ում",
};
