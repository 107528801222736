export default {
  "p-blog-codeId-failed-to-save-post": "Հրապարակումը պահպանել չստացվեց!",
  "p-blog-codeId-post-saved-successfully":
    "Հրապարակումը հաջողությամբ պահպանվել է!",
  "p-blog-codeId-confirm-delete":
    "Վստահ եք, որ ցանկանում եք ջնջել այս հրապարակումը?",
  "p-blog-codeId-warning": "Զգուշացում",
  "p-blog-codeId-delete": "Ջնջել",
  "p-blog-codeId-post-deleted-successfully":
    "Հրապարակումը հաջողությամբ ջնջվել է!",
  "p-blog-codeId-failed-to-delete-post": "Հրապարակումը ջնջել չստացվեց!",
  "p-blog-codeId-post-not-found": "Բլոգի հրապարակումը չի գտնվել :(",
  "p-blog-codeId-back-to-all-posts": "Վերադառնալ բոլոր հրապարակումներին",
  "p-blog-codeId-edit-post": "Խմբագրել հրապարակումը",
  "p-blog-codeId-delete-post": "Ջնջել հրապարակումը",

  "p-blog-index-title":
    "Բլոգ | Հետաքրքրություններ, Խորհուրդներ և Պատմություններ Solid Swap-ից",
  "p-blog-index-meta-description":
    "Բացահայտեք մեր վերջին հետաքրքրությունները, խորհուրդները և պատմությունները մեր բլոգում: Մնացեք տեղեկացված մեր վերջին հրապարակումների մասին, որոնք ընդգրկում են լայն թեմաների շրջանակ՝ տեխնոլոգիա, կյանք և ավելին:",
  "p-blog-index-meta-keywords":
    "բլոգ, հետաքրքրություններ, խորհուրդներ, պատմություններ, տեխնոլոգիա, կյանք, վերջին հրապարակումներ, Solid Swap, solidswap",
  "p-blog-index-failed-to-fetch-posts": "Հրապարակումները ստանալ չստացվեց",
  "p-blog-index-solid-swap-blog": "Solid Swap բլոգ",
  "p-blog-index-search-placeholder": "Որոնել բլոգի հրապարակումները...",
  "p-blog-index-new-post": "Նոր հրապարակում",
  "p-blog-index-active": "Ակտիվ",
  "p-blog-index-archive": "Արխիվ",
  "p-blog-index-load-more": "Բեռնել ավելին",
  "p-blog-index-no-posts-found": "Հրապարակումներ չեն գտնվել",
};
