export default {
  "c-orderCreate-closeOrderConfirm":
    "Вы уверены, что хотите закрыть создание заказа?",
  "c-orderCreate-close": "Закрыть",
  "c-orderCreate-closeCancel": "Отмена",
  "c-orderCreate-sendAmount-required": "Пожалуйста, введите сумму",
  "c-orderCreate-receiveAmount-required": "Пожалуйста, введите сумму",
  "c-orderCreate-orderPayment-required": "Пожалуйста, выберите способ оплаты",
  "c-orderCreate-fiatLimits": "Сумма должна быть от {min} до {max}",
  "c-orderCreate-cryptoLimits": "Сумма должна быть меньше {limit}",
  "c-orderCreate-errorTitle": "Ошибка",
  "c-orderCreate-errorCreatingOrder": "Ошибка при создании заказа",
  "c-orderCreate-orderCreated": 'Заказ ID:"{id}" создан',
  "c-orderCreate-ratesChangedTitle": "Изменение курсов",
  "c-orderCreate-ratesChangedAlert":
    "Курсы на покупку криптовалюты изменились во время создания вашего заказа.",
  "c-orderCreate-ratesChangedDesc":
    "Это может быть вызвано колебаниями рынка или обновлением настраиваемого курса создателем предложения. Пожалуйста, ознакомьтесь с новыми курсами перед продолжением покупки, чтобы убедиться, что вы согласны с обновленными условиями.",
  "c-orderCreate-oldPrice": "Старая цена",
  "c-orderCreate-newPrice": "Новая цена",
  "c-orderCreate-returnToOrder": "Вернуться к заказу",
  "c-orderCreate-proceedWithNewPrice": "Продолжить с новой ценой",
  "c-orderCreate-creatingOrderTitle": "Создание заказа на {type} {currency}",
  "c-orderCreate-buying": "покупку",
  "c-orderCreate-selling": "продажу",
  "c-orderCreate-paymentTimeLimit": "Лимит времени для платежа",
  "c-orderCreate-30minutes": "30 минут",
  "c-orderCreate-avgPaymentTime": "Среднее время платежа",
  "c-orderCreate-minutes": "минут",
  "c-orderCreate-available": "Доступно",
  "c-orderCreate-paymentOptions": "Способы оплаты:",
  "c-orderCreate-traderInfo": "Информация о трейдере",
  "c-orderCreate-noDescriptionSpecified":
    "Трейдер не указал описание. Вы получите дополнительную информацию после создания заказа.",
  "c-orderCreate-priceLabel": "Цена",
  "c-orderCreate-priceChangedTooltip":
    "Цена изменилась из-за официальных торговых ставок или изменений условий создателя предложения",
  "c-orderCreate-priceChanged": "Цена изменена!",
  "c-orderCreate-platformFee": "Платформенный сбор",
  "c-orderCreate-paidBySeller": "оплачивается продавцом",
  "c-orderCreate-iWantTo": "Я хочу {type}",
  "c-orderCreate-pay": "заплатить",
  "c-orderCreate-sell": "продать",
  "c-orderCreate-enterAmount": "Введите сумму",
  "c-orderCreate-limits": "Лимиты",
  "c-orderCreate-fee": "Комиссия",
  "c-orderCreate-myBalance": "Мой баланс",
  "c-orderCreate-sellAll": "Продать все",
  "c-orderCreate-iWillReceive": "Я получу",
  "c-orderCreate-paymentMethod": "Способ оплаты",
  "c-orderCreate-noPaymentOptions":
    "Для этого предложения у вас нет разрешенных способов оплаты",
  "c-orderCreate-alsoAvailable": "Также доступно для этого предложения",
  "c-orderCreate-addPaymentOption": "Добавить способ оплаты",
  "c-orderCreate-cancel": "Отмена",
  "c-orderCreate-buy": "КУПИТЬ",
};
