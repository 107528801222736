export default {
  "c-offers-editform-closeConfirmation":
    "¿Estás seguro de cerrar este diálogo?",
  "c-offers-editform-percentError":
    "El valor del porcentaje % debe estar entre -99 y 99",
  "c-offers-editform-createError": "Error al crear la oferta",
  "c-offers-editform-createSuccess": "¡Oferta creada con éxito!",
  "c-offers-editform-updateSuccess": "¡Oferta actualizada con éxito!",
  "c-offers-editform-typeRequired": "Por favor selecciona el tipo de pedido",
  "c-offers-editform-currencyRequired": "Por favor selecciona la moneda",
  "c-offers-editform-priceTypeRequired":
    "Por favor selecciona el tipo de precio",
  "c-offers-editform-priceValRequired": "Por favor ingresa el valor del precio",
  "c-offers-editform-paymentOptionsRequired":
    "Por favor selecciona las opciones de pago",
  "c-offers-editform-minLimitRequired": "Por favor ingresa el monto mínimo",
  "c-offers-editform-minLimitError":
    "El monto mínimo debe ser mayor a {minAmount}",
  "c-offers-editform-maxLimitRequired": "Por favor ingresa el monto máximo",
  "c-offers-editform-maxLimitError":
    "El monto máximo no debe ser menor al límite mínimo",
  "c-offers-editform-offerAmountRequired":
    "Por favor ingresa el monto de la oferta",
  "c-offers-editform-termsOfUseRequired":
    "Por favor ingresa los términos de uso",
  "c-offers-editform-editTitle": "Edita tu oferta",
  "c-offers-editform-addTitle": "Añadir nueva oferta",
  "c-offers-editform-offerType": "Tipo de oferta",
  "c-offers-editform-sell": "vender",
  "c-offers-editform-buy": "comprar",
  "c-offers-editform-selling": "Venta",
  "c-offers-editform-buying": "Compra",
  "c-offers-editform-mainCurrency": "Moneda principal",
  "c-offers-editform-selectCurrency": "selecciona moneda",
  "c-offers-editform-forCurrency": "...para",
  "c-offers-editform-currentRate":
    "tasa actual: 1 {currencyIn} = {rate} {currencyOut}",
  "c-offers-editform-price": "Precio",
  "c-offers-editform-priceType": "Tipo de precio",
  "c-offers-editform-selectPriceType": "selecciona tipo de precio",
  "c-offers-editform-percentValue": "Valor porcentual",
  "c-offers-editform-relativeValue": "Valor relativo",
  "c-offers-editform-fixedValue": "Valor fijo",
  "c-offers-editform-priceTypesExplained": "Tipos de precios explicados:",
  "c-offers-editform-percent": "Porcentaje",
  "c-offers-editform-percentExplanation":
    "El precio se ajusta en función de una diferencia porcentual con respecto a las tasas actuales. Si un usuario ingresa un porcentaje, el precio final se calculará aplicando este porcentaje como un aumento o disminución de las tasas actuales.",
  "c-offers-editform-relative": "Relativo",
  "c-offers-editform-relativeExplanation":
    "El precio se ajusta sumando o restando un número específico a las tasas actuales. El usuario ingresa este número, y modifica directamente el precio base, haciendo que el precio final sea mayor o menor en esta cantidad exacta.",
  "c-offers-editform-fixed": "Fijo",
  "c-offers-editform-fixedExplanation":
    "Este es un ajuste de precio sencillo donde el usuario establece el precio directamente. El precio ingresado por el usuario será el precio final, sin ajustes basados en tasas actuales o porcentajes.",
  "c-offers-editform-pricePercent": "Precio porcentual",
  "c-offers-editform-priceRelative": "Precio relativo",
  "c-offers-editform-priceFixed": "Precio fijo",
  "c-offers-editform-enterPriceValue": "ingresa el valor del precio",
  "c-offers-editform-rate":
    "según los campos seleccionados y el tipo de precio, tu tasa es: 1 {currencyIn} = {rate} {currencyOut}",
  "c-offers-editform-paymentOptions": "Opciones de pago",
  "c-offers-editform-selectPaymentOptions": "selecciona opciones de pago",
  "c-offers-editform-noOptions": "No hay opciones disponibles",
  "c-offers-editform-all": "Todos",
  "c-offers-editform-addOption": "Añadir opción de pago",
  "c-offers-editform-minLimit": "Límite mínimo",
  "c-offers-editform-enterMinAmount": "monto mín.",
  "c-offers-editform-maxLimit": "Límite máximo",
  "c-offers-editform-enterMaxAmount": "monto máx.",
  "c-offers-editform-orderLimitsExplanation":
    "Explicación de los límites de pedido",
  "c-offers-editform-orderLimitsExplanationTitle":
    "Explicación de los límites de pedido",
  "c-offers-editform-orderLimitsExplanationText":
    "El Límite Mínimo y el Límite Máximo especifican el rango dentro del cual puedes crear un pedido. No puedes realizar un pedido de compra o venta de criptomonedas por debajo del Límite Mínimo o por encima del Límite Máximo.",
  "c-offers-editform-orderLimitsExplanationUSD":
    "Además, nuestra plataforma impone un límite mínimo equivalente a {MIN_OFFER_AMOUNT_USD} USD para todas las ofertas, esto es aproximadamente {usdLimit} {currencyOut}",
  "c-offers-editform-amountToTrade": "Monto a comerciar",
  "c-offers-editform-offerAmount": "Monto de la oferta",
  "c-offers-editform-offerAmount-unlimited": "ilimitado",
  "c-offers-editform-enterOfferAmount": "monto",
  "c-offers-editform-currentBalance": "Mi saldo actual: {balance} {currencyIn}",
  "c-offers-editform-offerAmountExplanation":
    "Explicación del monto de la oferta",
  "c-offers-editform-offerAmountExplanationTitle":
    "Explicación del monto de la oferta",
  "c-offers-editform-offerAmountExplanationText1":
    "El Monto de la Oferta es el monto total de criptomonedas que estás dispuesto a comerciar.",
  "c-offers-editform-offerAmountExplanationText2":
    "Para ofertas de venta, este es el monto de criptomonedas que deseas vender. Por ejemplo: Si tienes 1000 USDT en tu saldo y deseas comerciar solo la mitad, puedes establecer el Monto de la Oferta en 500 USDT. Esto asegura que no venderás más de 500 USDT. Ten en cuenta que todos tus fondos permanecen en tu cuenta, y cancelar la oferta los devolverá a tu saldo.",
  "c-offers-editform-offerAmountExplanationText3":
    "Para ofertas de compra, este monto indica cuánto criptomonedas deseas comprar. Si estableces el Monto de la Oferta en 1000 USDT, recibirás exactamente esa cantidad. saldo.",
  "c-offers-editform-offerAmountExplanationText4":
    "Además, nuestra plataforma cobra una comisión, que es cubierta por el vendedor. Por lo tanto, si estás creando una oferta de VENTA, la comisión está a tu cargo. Si estás creando una oferta de COMPRA, la comisión será a cargo de la contraparte que crea el pedido.",
  "c-offers-editform-termsOfUse": "Términos de uso",
  "c-offers-editform-termsOfUseText":
    "Por favor, completa tus 'Términos de Uso' a continuación. Esta sección debe detallar los términos y condiciones generales bajo los cuales estás dispuesto a comerciar. No es necesario proporcionar información bancaria u otros detalles sensibles aquí.",
  "c-offers-editform-termsOfUsePlaceholder": "escribe una descripción...",
  "c-offers-editform-cancel": "Cancelar",
  "c-offers-editform-confirm": "Confirmar",
};
