export default {
  "p-login-head-title": "Вход | Доступ к вашему аккаунту Solid Swap",
  "p-login-head-description":
    "Войдите в ваш аккаунт Solid Swap, чтобы начать безопасно и эффективно торговать криптовалютой",
  "p-login-head-keywords":
    "вход, аккаунт Solid Swap, доступ к торговле криптовалютой",
  "p-login-rules-email": "Пожалуйста, введите вашу электронную почту",
  "p-login-rules-password": "Пожалуйста, введите ваш пароль",
  "p-login-error-default": "Упс, что-то пошло не так при входе.",
  "p-login-error-2fa-wrong":
    "Неверный 2FA код! Пожалуйста, попробуйте еще раз.",
  "p-login-error-user-not-found": "Пользователь не найден",
  "p-login-error-email-verification":
    "Сначала нужно подтвердить вашу электронную почту. Пожалуйста, проверьте вашу почту.",
  "p-login-error-suspended":
    "Ваш аккаунт был приостановлен. Пожалуйста, проверьте вашу почту для получения дальнейших деталей.",
  "p-login-error-locked-title": "Временно заблокирован",
  "p-login-error-locked-message":
    "Вы сделали {failedLockCount} или более неудачных попыток входа. В целях безопасности, пожалуйста, подождите 60 минут, прежде чем пытаться снова. Если вам нужна немедленная помощь или вы забыли свой пароль, пожалуйста, используйте опцию 'Забыли пароль', чтобы сбросить ваш пароль.",
  "p-login-error-wrong-password-title": "Неверный пароль",
  "p-login-error-wrong-password-message":
    "Вы сделали {failedAttemptsCount} неудачных попыток входа. Пожалуйста, будьте осторожны: после {failedLockCount} неудачных попыток ваш аккаунт будет временно заблокирован на 60 минут.",
  "p-login-password-reset-success": "Успех!",
  "p-login-password-reset-error":
    "Что-то пошло не так при сбросе пароля, попробуйте позже",
  "p-login-title": "Войти",
  "p-login-label-email": "Электронная почта",
  "p-login-label-password": "Пароль",
  "p-login-email-placeholder": "Введите вашу электронную почту",
  "p-login-password-placeholder": "Введите ваш пароль",
  "p-login-forgot-password": "Забыли пароль?",
  "p-login-submit-button": "Войти",
  "p-login-2fa-prompt": "Введите 2FA код из вашего приложения:",
  "p-login-2fa-placeholder": "Введите код...",
  "p-login-2fa-button": "Продолжить",
  "p-login-password-reset-success-title": "Письмо отправлено!",
  "p-login-password-reset-success-message":
    "Мы отправили ссылку для сброса пароля на вашу электронную почту. Пожалуйста, проверьте вашу почту и следуйте инструкциям, чтобы сбросить ваш пароль.",
  "p-login-password-reset-alert":
    "Если вы не видите письмо, пожалуйста, проверьте вашу папку со спамом или мусором. Если вы все еще не получили письмо, попробуйте повторно отправить ваш адрес электронной почты или свяжитесь с нашей службой поддержки для получения помощи.",
  "p-login-back-to-login": "Назад к входу",
  "p-login-forgot-password-title": "Забыли ваш пароль?",
  "p-login-forgot-password-message":
    "Пожалуйста, введите адрес электронной почты, связанный с вашим аккаунтом, и нажмите 'Продолжить'.",
  "p-login-password-reset-instructions":
    "Мы отправим вам письмо с инструкциями по сбросу пароля. Если вы не получите письмо в течение нескольких минут, пожалуйста, проверьте вашу папку со спамом или попробуйте снова.",
  "p-login-enter-email": "Введите вашу электронную почту",
  "p-login-cancel": "Отмена",
  "p-login-proceed": "Продолжить",
  "p-login-go-to-registration": "Нет аккаунта? Перейти к регистрации",
  "p-login-welcome-title": "Добро пожаловать в новую эру торговли!",
  "p-login-welcome-message-1":
    "Безопасно подключайтесь к миру цифровых активов.",
  "p-login-welcome-message-2":
    "Начните торговать с уверенностью и удобством на нашей надежной P2P платформе.",
  "p-login-welcome-message-3":
    "Ваша дверь в безупречные криптовалютные транзакции ждет вас.",
};
