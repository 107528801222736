export default {
  "c-exchange-listItem-id": "ID",
  "c-exchange-listItem-fiat": "Фиат",
  "c-exchange-listItem-crypto": "Крипто",
  "c-exchange-listItem-go-to-details": "Перейти к деталям",
  "c-exchange-listItem-summary": "Сводка",
  "c-exchange-listItem-exchanging": "Обмен",
  "c-exchange-listItem-for": "за",
  "c-exchange-listItem-order-created": "Заказ создан",
  "c-exchange-listItem-status": "Статус",
  "c-exchange-listItem-verifying-card": "Проверка карты пользователя",
  "c-exchange-listItem-moderator-verifying": "Модератор проверяет данные",
  "c-exchange-listItem-waiting-alm": "Ожидание результатов ALM",
  "c-exchange-listItem-preparing-crypto": "Подготовка криптоадреса",
  "c-exchange-listItem-preparing-fiat": "Подготовка фиатных реквизитов",
  "c-exchange-listItem-sending-crypto": "Отправка криптовалюты",
  "c-exchange-listItem-sending-fiat": "Отправка фиата",
  "c-exchange-listItem-confirming-fiat": "Подтверждение платежа",
  "c-exchange-listItem-waiting-payment": "Ожидание платежа пользователя",
  "c-exchange-listItem-completed": "Завершено",
  "c-exchange-listItem-failed": "Неудачно",
  "c-exchange-listItem-cancelled": "Отменено",
};
