export default {
  "c-transaction-cancel-confirm":
    "Êtes-vous sûr de vouloir annuler cette transaction ?",
  "c-transaction-cancel-withdrawal": "Annuler le retrait",
  "c-transaction-back": "Retour",
  "c-transaction-cancel-error":
    "Quelque chose s'est mal passé lors de l'annulation de la transaction !",
  "c-transaction-status": "Statut",
  "c-transaction-amount": "Montant",
  "c-transaction-fee": "Frais",
  "c-transaction-type": "Type",
  "c-transaction-comment": "Commentaire",
  "c-transaction-address": "Adresse",
};
