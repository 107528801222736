export default {
  "p-trade-my-offers-title":
    "Meine Angebote | Verwalten Sie Ihre Krypto-Angebote auf Solid Swap",
  "p-trade-my-offers-description":
    "Ansehen und verwalten Sie Ihre Kryptowährungsangebote auf Solid Swap. Stellen Sie sicher, dass Ihre Handelsstrategien mit unserer benutzerfreundlichen Oberfläche optimal sind",
  "p-trade-my-offers-keywords":
    "krypto-angebote verwalten, kryptowährungsangebote, handelsangebote, digitalwährungsangebote",
  "p-trade-my-offers-delete-confirm":
    "Sind Sie sicher, dass Sie dieses Angebot löschen möchten?",
  "p-trade-my-offers-warning": "Warnung",
  "p-trade-my-offers-ok": "OK",
  "p-trade-my-offers-cancel": "Abbrechen",
  "p-trade-my-offers-post-new-offer": "Neues Angebot posten",
  "p-trade-my-offers-no-active-offers": "Keine aktiven Angebote gefunden",
  "p-trade-my-offers-your-selling-offers": "Ihre Verkaufsangebote",
  "p-trade-my-offers-your-buying-offers": "Ihre Kaufangebote",
};
