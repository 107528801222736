export default {
  "c-orderCreate-closeOrderConfirm":
    "¿Está seguro de que desea cerrar la creación del pedido?",
  "c-orderCreate-close": "Cerrar",
  "c-orderCreate-closeCancel": "Cancelar",
  "c-orderCreate-sendAmount-required": "Por favor, introduzca el monto",
  "c-orderCreate-receiveAmount-required": "Por favor, introduzca el monto",
  "c-orderCreate-orderPayment-required":
    "Por favor, seleccione un método de pago",
  "c-orderCreate-fiatLimits": "El monto debe estar entre {min} y {max}",
  "c-orderCreate-cryptoLimits": "El monto debe ser menor que {limit}",
  "c-orderCreate-errorTitle": "Error",
  "c-orderCreate-errorCreatingOrder": "Error al crear el pedido",
  "c-orderCreate-orderCreated": 'ID de pedido: "{id}" creado',
  "c-orderCreate-ratesChangedTitle": "Las tasas han cambiado",
  "c-orderCreate-ratesChangedAlert":
    "Las tasas de compra de criptomonedas han cambiado mientras creaba su pedido.",
  "c-orderCreate-ratesChangedDesc":
    "Esto podría deberse a fluctuaciones del mercado o a una actualización de la tasa personalizada por el creador de la oferta. Por favor, revise las nuevas tasas antes de proceder con su compra para asegurarse de que está de acuerdo con los términos actualizados.",
  "c-orderCreate-oldPrice": "Precio antiguo",
  "c-orderCreate-newPrice": "Precio nuevo",
  "c-orderCreate-returnToOrder": "Volver al pedido",
  "c-orderCreate-proceedWithNewPrice": "Proceder con el nuevo precio",
  "c-orderCreate-creatingOrderTitle": "Creando pedido para {type} {currency}",
  "c-orderCreate-buying": "compra",
  "c-orderCreate-selling": "venta",
  "c-orderCreate-paymentTimeLimit": "Límite de tiempo de pago",
  "c-orderCreate-30minutes": "30 minutos",
  "c-orderCreate-avgPaymentTime": "Tiempo promedio de pago",
  "c-orderCreate-minutes": "minutos",
  "c-orderCreate-available": "Disponible",
  "c-orderCreate-paymentOptions": "Opciones de pago:",
  "c-orderCreate-traderInfo": "Información del comerciante",
  "c-orderCreate-noDescriptionSpecified":
    "No se especificó descripción por parte del comerciante. Recibirá información adicional una vez creada la orden.",
  "c-orderCreate-priceLabel": "Precio",
  "c-orderCreate-priceChangedTooltip":
    "El precio ha cambiado debido a las tasas de cambio oficiales o cambios por parte del creador de la oferta",
  "c-orderCreate-priceChanged": "¡Precio cambiado!",
  "c-orderCreate-platformFee": "Tarifa de la plataforma",
  "c-orderCreate-paidBySeller": "pagado por el vendedor",
  "c-orderCreate-iWantTo": "Quiero {type}",
  "c-orderCreate-pay": "pagar",
  "c-orderCreate-sell": "vender",
  "c-orderCreate-enterAmount": "Introduzca el monto",
  "c-orderCreate-limits": "Límites",
  "c-orderCreate-fee": "Tarifa",
  "c-orderCreate-myBalance": "Mi saldo",
  "c-orderCreate-sellAll": "Vender todo",
  "c-orderCreate-iWillReceive": "Recibiré",
  "c-orderCreate-paymentMethod": "Método de pago",
  "c-orderCreate-noPaymentOptions":
    "No hay opciones de pago disponibles para esta oferta",
  "c-orderCreate-alsoAvailable": "También disponible para esta oferta",
  "c-orderCreate-addPaymentOption": "Agregar opción de pago",
  "c-orderCreate-cancel": "Cancelar",
  "c-orderCreate-buy": "COMPRAR",
};
