export default {
  "p-beginners-guide-head-title":
    "Guide du débutant | Commencez à trader des cryptomonnaies avec Solid Swap",
  "p-beginners-guide-head-description":
    "Nouveau dans le trading de cryptomonnaies ? Notre guide du débutant vous aidera à démarrer avec des étapes faciles à suivre et des conseils précieux sur le trading d'actifs numériques",
  "p-beginners-guide-head-keywords":
    "guide du débutant, trading de cryptomonnaies, commencer, conseils de trading de crypto, actifs numériques",
  "p-beginners-guide-account-preparation-title":
    "Préparation du compte pour le trading P2P",
  "p-beginners-guide-register-title": "Inscrivez-vous sur Solid Swap",
  "p-beginners-guide-two-factor-title":
    "Activer l'authentification à deux facteurs",
  "p-beginners-guide-payment-method-title": "Ajouter une méthode de paiement",
  "p-beginners-guide-finding-offers-title-1":
    "Trouver des offres et créer des offres",
  "p-beginners-guide-select-currency-title-1":
    "Sélectionnez une paire de devises",
  "p-beginners-guide-search-offer-title-1": "Rechercher une offre appropriée",
  "p-beginners-guide-create-offer-title-1": "Créer une offre d'échange",
  "p-beginners-guide-commissions-title-1": "Commissions",
  "p-beginners-guide-completing-transaction-title-1": "Terminer la transaction",
  "p-beginners-guide-intro-1":
    "Bonjour ! Ce guide vous aidera à faire votre premier échange sur Solid Swap. Il décrira toutes les étapes clés, suivant lesquelles vous pourrez facilement et en toute sécurité effectuer une transaction. Nous avons rendu ce processus aussi compréhensible et accessible que possible, alors ne vous inquiétez pas si vous êtes nouveau dans le monde des cryptomonnaies - nous serons avec vous à chaque étape.",
  "p-beginners-guide-intro-2":
    "Commençons par les bases : qu'est-ce que Solid Swap et pourquoi c'est le choix idéal pour l'échange de cryptomonnaies. Solid Swap est une plateforme pour l'échange rapide de cryptomonnaies et de monnaies fiduciaires, offrant actuellement des services peer-to-peer (P2P). Nous fournissons un échange sécurisé et rentable avec des taux compétitifs, des frais faibles et un haut niveau de sécurité.",
  "p-beginners-guide-steps-title":
    "Étapes clés pour un trading réussi sur Solid Swap :",
  "p-beginners-guide-follow-steps":
    "En suivant nos instructions, vous pourrez effectuer votre première transaction sur Solid Swap rapidement, en toute sécurité et avec confiance.",
  "p-beginners-guide-step1-title": "Étape 1 : Inscription",
  "p-beginners-guide-register-instructions":
    "Pour vous inscrire sur Solid Swap :",
  "p-beginners-guide-register-step1":
    "Cliquez sur le bouton “Inscription” dans le coin supérieur droit de l'écran.",
  "p-beginners-guide-register-step2":
    "Remplissez les informations requises et cliquez sur “Continuer”.",
  "p-beginners-guide-register-step3":
    "Vérifiez l'email avec lequel vous vous êtes inscrit et suivez le lien dans l'email pour confirmer votre adresse email.",
  "p-beginners-guide-register-step4": "Connectez-vous à votre compte.",
  "p-beginners-guide-step2-title":
    "Étape 2 : Activer l'authentification à deux facteurs",
  "p-beginners-guide-two-factor-instructions":
    "Pour effectuer des transactions, vous devez activer l'authentification à deux facteurs. L'authentification à deux facteurs est particulièrement importante pour protéger vos actifs et votre compte. Pour cela :",
  "p-beginners-guide-two-factor-step1":
    "Cliquez sur l'icône de votre profil dans le coin supérieur droit de l'écran et allez dans les paramètres en cliquant sur le bouton “Paramètres”.",
  "p-beginners-guide-two-factor-step2":
    "Cliquez sur le bouton rouge “Activer 2FA”.",
  "p-beginners-guide-two-factor-step3":
    "Scannez le code QR affiché à l'écran pour le lier à votre compte sur Solid Swap.",
  "p-beginners-guide-two-factor-note":
    "Si vous n'avez pas d'application d'authentification à deux facteurs, ne vous inquiétez pas. Vous pouvez télécharger l'une des applications suivantes sur votre smartphone :",
  "p-beginners-guide-two-factor-app1":
    "Google Authenticator (pour Android et iOS)",
  "p-beginners-guide-two-factor-app2": "Authy (pour Android et iOS)",
  "p-beginners-guide-two-factor-app3":
    "Microsoft Authenticator (pour Android et iOS)",
  "p-beginners-guide-faq":
    "Pour plus d'informations sur la connexion 2FA, ainsi que pour obtenir des réponses à d'autres questions fréquemment posées, visitez notre [FAQ](link to FAQ).",
  "p-beginners-guide-step3-title": "Étape 3 : Ajouter une méthode de paiement",
  "p-beginners-guide-payment-method-instructions":
    "Pour effectuer un échange, vous devez ajouter une méthode de paiement. Pour cela :",
  "p-beginners-guide-payment-method-step1":
    "Dans les paramètres de votre compte, allez dans l'onglet “Options de paiement”.",
  "p-beginners-guide-payment-method-step2":
    "Cliquez sur le bouton “+ Ajouter une nouvelle option de paiement”.",
  "p-beginners-guide-payment-method-step3":
    "Sélectionnez une méthode de paiement pratique dans la liste déroulante.",
  "p-beginners-guide-payment-method-step4":
    "Dans le champ “Écrire la description”, vous pouvez préciser des informations supplémentaires. Celles-ci seront affichées lorsque vous serez le vendeur. Par exemple, le numéro d'une carte bancaire ou d'un portefeuille crypto où vous acceptez la devise.",
  "p-beginners-guide-finding-offers-title":
    "Trouver des offres et sélectionner un vendeur",
  "p-beginners-guide-select-currency-title":
    "Étape 1 : Sélectionner une paire de devises",
  "p-beginners-guide-select-currency-step1":
    "Allez dans l'onglet de trading P2P, situé sur le panneau en haut de la page.",
  "p-beginners-guide-select-currency-step2":
    "Choisissez l'action que vous prévoyez de réaliser : acheter ou vendre.",
  "p-beginners-guide-select-currency-step3":
    "Sélectionnez la méthode de paiement qui sera utilisée dans la transaction.",
  "p-beginners-guide-select-currency-step4":
    "Entrez le montant de la devise que vous prévoyez d'échanger pour voir toutes les offres appropriées.",
  "p-beginners-guide-select-currency-note":
    "La paire de devises peut également être sélectionnée sur la page principale du site.",
  "p-beginners-guide-search-offer-title":
    "Étape 2 : Rechercher une offre appropriée",
  "p-beginners-guide-search-offer-description":
    "Choisissez l'offre d'échange qui vous convient le mieux.",
  "p-beginners-guide-search-offer-note":
    "Lors du choix d'un vendeur, faites attention aux indicateurs suivants :",
  "p-beginners-guide-search-offer-seller-rating":
    "Évaluation du vendeur : chaque vendeur a une évaluation basée sur les commentaires des acheteurs précédents. Choisissez des vendeurs avec des évaluations élevées pour plus de confiance.",
  "p-beginners-guide-search-offer-transactions":
    "Nombre de transactions effectuées : notez le nombre de transactions réussies réalisées par le vendeur. Les vendeurs avec un grand nombre de transactions sont généralement plus fiables.",
  "p-beginners-guide-search-offer-reviews":
    "Avis : lisez les avis des autres utilisateurs sur le vendeur. Cela vous aidera à évaluer leur réputation et la qualité des services fournis. Vous pouvez le faire dans le profil du vendeur.",
  "p-beginners-guide-search-offer-limits":
    "Limites de montant de transaction : vérifiez les montants minimum et maximum que le vendeur est prêt à échanger. Assurez-vous qu'ils répondent à vos exigences.",
  "p-beginners-guide-create-offer-title": "Étape 3 : Sélectionner une offre",
  "p-beginners-guide-create-offer-step1":
    "Lorsque vous trouvez une offre appropriée, cliquez sur le bouton vert “Acheter” à côté pour voir les détails.",
  "p-beginners-guide-create-offer-step2":
    "Examinez les conditions de l'accord, y compris le taux de change, les montants minimum et maximum, et les méthodes de paiement.",
  "p-beginners-guide-create-offer-step3":
    "Si vous acceptez les conditions, vous pouvez continuer à ouvrir la transaction. Pour cela, dans la fenêtre qui s'ouvre, remplissez les champs vides et sélectionnez la méthode de paiement. Cliquez sur le bouton “Acheter”.",
  "p-beginners-guide-create-offer-step4":
    "Si aucune des offres présentées ne vous convient, vous pouvez créer la vôtre. Pour cela :",
  "p-beginners-guide-create-offer-note1":
    "Cliquez sur le bouton “+ Publier une nouvelle offre”.",
  "p-beginners-guide-create-offer-note2":
    "Remplissez les champs avec les informations nécessaires.",
  "p-beginners-guide-create-offer-note3":
    "Dans la description, vous pouvez préciser des informations supplémentaires sur l'accord.",
  "p-beginners-guide-create-offer-note4": "Cliquez sur le bouton “Confirmer”.",
  "p-beginners-guide-create-offer-note5":
    "Après cela, vous serez dans la section “Mes offres”. Vous y trouverez toutes les offres que vous avez créées. Pour rendre une offre visible aux autres utilisateurs du site, changez son statut. Vous pouvez le faire en cliquant sur l'interrupteur correspondant sur le côté droit de l'offre.",
  "p-beginners-guide-commissions-title": "Veuillez noter !",
  "p-beginners-guide-commissions-description":
    "Nous nous efforçons de garantir la transparence dans tous les aspects de notre travail, y compris les commissions. La commission de trading est de seulement 0,2% du montant de la transaction, ce que le vendeur paie. Cela vous permet de planifier facilement vos transactions, sachant qu'il n'y aura pas de frais imprévus.",
  "p-beginners-guide-completing-transaction-title": "Terminer la transaction",
  "p-beginners-guide-completing-transaction-description1":
    "Après avoir trouvé une offre d'échange appropriée et cliqué sur le bouton “Acheter”, vous serez sur la page de la transaction. Ici se trouve toute l'information sur l'échange. Nous vous recommandons vivement de la vérifier pour garantir l'exactitude et éviter les transactions erronées !",
  "p-beginners-guide-completing-transaction-description2":
    "Sur la page de la transaction, vous trouverez également un chat avec le vendeur. Vous pouvez y clarifier les conditions de l'échange ou d'autres informations qui vous intéressent.",
  "p-beginners-guide-completing-transaction-description3":
    "Dans la fenêtre de transaction active :",
  "p-beginners-guide-completing-transaction-step1":
    "Vérifiez les conditions et les détails de l'échange.",
  "p-beginners-guide-completing-transaction-step2":
    "Effectuez le transfert à la méthode de paiement spécifiée par le vendeur.",
  "p-beginners-guide-completing-transaction-step3":
    "Confirmez le transfert en cliquant sur le bouton “Le paiement a été envoyé”.",
  "p-beginners-guide-completing-transaction-step4":
    "Attendez la confirmation de paiement du vendeur et le transfert des fonds sur votre compte.",
  "p-beginners-guide-completing-transaction-step5":
    "Laissez un avis sur la transaction : les avis aident les utilisateurs à comprendre avec qui coopérer et qui éviter. Nous apprécions vos efforts pour créer une atmosphère favorable sur notre plateforme !",
  "p-beginners-guide-active-transactions-note1":
    "Vous pouvez trouver vos transactions actives dans la section de trading P2P, sous l'onglet “commandes”.",
  "p-beginners-guide-active-transactions-note2":
    "En cas de litige, vous pouvez ouvrir un litige 30 minutes après avoir confirmé le transfert de paiement. Le bouton pour cela apparaîtra automatiquement sur la page de la transaction après l'expiration du chronomètre.",
  "p-beginners-guide-active-transactions-note3":
    "Si vous avez décidé de vendre de la devise et avez créé une offre de vente, attendez que quelqu'un réponde. Lorsque cela se produit, vous recevrez une notification.",
  "p-beginners-guide-active-transactions-note4":
    "Après qu'un acheteur soit trouvé, procédez à l'échange.",
  "p-beginners-guide-active-transactions-note5":
    "Vérifiez les conditions et les détails de l'échange.",
  "p-beginners-guide-active-transactions-note6":
    "Vérifiez si le paiement a été effectué par l'acheteur.",
  "p-beginners-guide-active-transactions-note7":
    "Confirmez la réception des fonds en cliquant sur le bouton “Paiement reçu”.",
  "p-beginners-guide-active-transactions-note8":
    "Envoyez le montant convenu à la méthode de réception spécifiée par l'acheteur.",
  "p-beginners-guide-ending-note1":
    "Fait ! Vous avez terminé avec succès votre première transaction sur Solid Swap. Nous espérons que ce guide vous a aidé à franchir toutes les étapes sans difficulté.",
  "p-beginners-guide-ending-note2":
    "Souvenez-vous toujours de la sécurité lors de l'échange de cryptomonnaies. Vérifiez toujours les conditions de l'accord et la réputation du vendeur, utilisez l'authentification à deux facteurs et soyez prudent lors du transfert de fonds. Si vous avez des questions ou des problèmes, notre service de support est toujours prêt à vous aider.",
  "p-beginners-guide-ending-note3":
    "Merci d'avoir choisi Solid Swap. Nous vous souhaitons des échanges réussis et sécurisés !",
  "p-beginners-guide-ending-note4":
    "Si vous avez des questions ou des problèmes, notre service de support est toujours prêt à vous aider.",
  "p-beginners-guide-ending-note5":
    "Merci d'avoir choisi Solid Swap. Nous vous souhaitons des échanges réussis et sécurisés !",
};
