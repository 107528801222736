export default {
  "p-trade-orders-title":
    "Առևտրային Պատվերներ | Կառավարեք Ձեր Կրիպտո Պատվերները Solid Swap-ում",
  "p-trade-orders-description":
    "Կառավարեք ձեր կրիպտոարժույթի պատվերները արդյունավետորեն Solid Swap-ում: Հետևեք և վերահսկեք ձեր առևտրային գործունեությունը հարթորեն",
  "p-trade-orders-keywords":
    "կառավարեք կրիպտո պատվերները, առևտրային պատվերներ, կրիպտոարժույթի պատվերի հետևում, առևտրի կառավարում",

  "p-trade-orders-order-fetch-error":
    "Պատվերը բեռնելիս սխալ է տեղի ունեցել, խնդրում ենք վերաբեռնել էջը",
  "p-trade-orders-order-not-found": 'Պատվերը "{orderId}" չի գտնվել',
  "p-trade-orders-payment-was-sent": "Վճարումը ուղարկված է",
  "p-trade-orders-payment-was-sent-confirm":
    "Հաստատո՞ւմ եք, որ վճարումը ձեր կողմից ուղարկված է: Անել դա միայն բանկի հաստատումից հետո: Շարունակել?",
  "p-trade-orders-payment-received": "Վճարումը ստացված է",
  "p-trade-orders-payment-received-confirm":
    "Հաստատո՞ւմ եք, որ վճարումը ստացված է: Անել դա միայն ձեր բանկի հաստատումից հետո: Եթե ցանկանում եք շարունակել, մուտքագրեք ձեր հավելվածից 2FA կոդը ստորև",
  "p-trade-orders-open-dispute": "Բացել վեճ",
  "p-trade-orders-open-dispute-confirm":
    "Ձեր ուզած վեճ բացելու համար: Շարունակել?",
  "p-trade-orders-change-status": "Փոխել կարգավիճակը",
  "p-trade-orders-change-status-confirm":
    "Վստահ եք, որ ցանկանում եք փոխել կարգավիճակը?",
  "p-trade-orders-continue": "Շարունակել",
  "p-trade-orders-cancel": "Չեղարկել",
  "p-trade-orders-invalid-2fa": "Անվավեր 2FA",
  "p-trade-orders-status-changed": "Պատվերի կարգավիճակը փոխվել է",
  "p-trade-orders-fetch-order-error": "Պատվերի տվյալների ստացման սխալ",
  "p-trade-orders-go-to-all-orders": "Գնալ բոլոր պատվերներին",
  "p-trade-orders-status": "Կարգավիճակ",
  "p-trade-orders-waiting-for-payment": "Սպասում ենք գնորդի վճարմանը",
  "p-trade-orders-payment-sent": "Գնորդը ուղարկել է վճարումը",
  "p-trade-orders-order-completed": "Պատվերը ավարտված է",
  "p-trade-orders-order-cancelled": "Պատվերը չեղարկված է",
  "p-trade-orders-dispute": "Վեճ",
  "p-trade-orders-auto-cancel-after": "Պատվերի ավտո չեղարկում հետո",
  "p-trade-orders-open-dispute-after": "Վեճը կարող է բացվել հետո",
  "p-trade-orders-order-created": "Պատվերը ստեղծված է",
  "p-trade-orders-summary": "Ամփոփում",
  "p-trade-orders-sell": "ՎԱՃԱՌԵԼ",
  "p-trade-orders-buy": "ԳՆԵԼ",
  "p-trade-orders-for": "համար",
  "p-trade-orders-fee": "Միջնորդավճար",
  "p-trade-orders-included-in-transaction": "ներառված է գործարքում",
  "p-trade-orders-selling": "Վաճառք",
  "p-trade-orders-buying": "Գնում",
  "p-trade-orders-price": "Գին",
  "p-trade-orders-offer-creator": "Առաջարկի ստեղծող",
  "p-trade-orders-trader-info": "Վաճառողի տեղեկություններ",
  "p-trade-orders-no-description": "Վաճառողից նշված նկարագրություն չկա:",
  "p-trade-orders-no-description-additional-info":
    "Պատվերի ստեղծումից հետո դուք կստանաք լրացուցիչ տեղեկություններ:",
  "p-trade-orders-trading-experience": "Ինչպես էր ձեր փոխանակման փորձը?",
  "p-trade-orders-positive": "Դրական",
  "p-trade-orders-negative": "Բացասական",
  "p-trade-orders-your-feedback": "Ձեր կարծիքը",

  "p-trade-ordersIndex-all": "Բոլորը",
  "p-trade-ordersIndex-active": "Ակտիվ",
  "p-trade-ordersIndex-completed": "Ավարտված",
  "p-trade-ordersIndex-cancelled": "Չեղարկված",
  "p-trade-ordersIndex-dispute": "Վեճ",
  "p-trade-ordersIndex-coin": "Մետաղադրամ",
  "p-trade-ordersIndex-order-type": "Պատվերի տեսակը",
  "p-trade-ordersIndex-dates": "Ամսաթվեր",
  "p-trade-ordersIndex-reset-filters": "Մաքրել ֆիլտրերը",
  "p-trade-ordersIndex-loading-error":
    "Ինչ-որ սխալ տեղի ունեցավ պատվերը բեռնելիս :(",
  "p-trade-ordersIndex-orders": "պատվերներ",
  "p-trade-ordersIndex-id-type": "ID / Տեսակ",
  "p-trade-ordersIndex-operation-info": "Գործարքի տեղեկություններ",
  "p-trade-ordersIndex-status-date": "Կարգավիճակ / Ամսաթիվ",
  "p-trade-ordersIndex-id": "ID",
  "p-trade-ordersIndex-sell": "ՎԱՃԱՌԵԼ",
  "p-trade-ordersIndex-buy": "ԳՆԵԼ",
  "p-trade-ordersIndex-go-to-details": "գնալ մանրամասներին",
  "p-trade-ordersIndex-summary": "Ամփոփում",
  "p-trade-ordersIndex-fee": "Միջնորդավճար",
  "p-trade-ordersIndex-selling": "Վաճառք",
  "p-trade-ordersIndex-buying": "Գնում",
  "p-trade-ordersIndex-for": "համար",
  "p-trade-ordersIndex-price": "Գին",
  "p-trade-ordersIndex-offer-creator": "Առաջարկի ստեղծող",
  "p-trade-ordersIndex-order-created": "Պատվերը ստեղծված է",
  "p-trade-ordersIndex-status": "Կարգավիճակ",
  "p-trade-ordersIndex-waiting-for-buyer": "Սպասում ենք գնորդի վճարմանը",
  "p-trade-ordersIndex-buyer-sent-payment": "Գնորդը ուղարկել է վճարումը",
  "p-trade-ordersIndex-order-completed": "Պատվերը ավարտված է",
  "p-trade-ordersIndex-order-cancelled": "Պատվերը չեղարկված է",
  "p-trade-ordersIndex-last7days": "Վերջին 7 օր",
  "p-trade-ordersIndex-lastMonth": "Վերջին ամիս",
  "p-trade-ordersIndex-last3month": "Վերջին 3 ամիս",
  "p-trade-ordersIndex-dispute-caps": "ՎԵՃ",
};
