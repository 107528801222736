export default {
  "c-orderCreate-closeOrderConfirm": "Are you sure to close order creation?",
  "c-orderCreate-close": "Close",
  "c-orderCreate-closeCancel": "Cancel",
  "c-orderCreate-sendAmount-required": "Please input the amount",
  "c-orderCreate-receiveAmount-required": "Please input the amount",
  "c-orderCreate-orderPayment-required": "Please select payment method",
  "c-orderCreate-fiatLimits": "Amount should be from {min} to {max}",
  "c-orderCreate-cryptoLimits": "Amount should be less than {limit}",
  "c-orderCreate-errorTitle": "Error",
  "c-orderCreate-errorCreatingOrder": "Error creating order",
  "c-orderCreate-orderCreated": 'Order ID:"{id}" created',
  "c-orderCreate-ratesChangedTitle": "Rates Have Changed",
  "c-orderCreate-ratesChangedAlert":
    "The rates for buying cryptocurrency have changed while you were creating your order.",
  "c-orderCreate-ratesChangedDesc":
    "This could be due to market fluctuations or the offer creator updating the custom rate. Please review the new rates before proceeding with your purchase to ensure you agree with the updated terms.",
  "c-orderCreate-oldPrice": "Old price",
  "c-orderCreate-newPrice": "New price",
  "c-orderCreate-returnToOrder": "Return to order",
  "c-orderCreate-proceedWithNewPrice": "Proceed with new price",
  "c-orderCreate-creatingOrderTitle": "Creating order for {type} {currency}",
  "c-orderCreate-buying": "buying",
  "c-orderCreate-selling": "selling",
  "c-orderCreate-paymentTimeLimit": "Payment time limit",
  "c-orderCreate-30minutes": "30 minutes",
  "c-orderCreate-avgPaymentTime": "Avg. payment time",
  "c-orderCreate-minutes": "minutes",
  "c-orderCreate-available": "Available",
  "c-orderCreate-paymentOptions": "Payment options:",
  "c-orderCreate-traderInfo": "Trader info",
  "c-orderCreate-noDescriptionSpecified":
    "No description specified from the trader. You will receive additional information after the order is created.",
  "c-orderCreate-priceLabel": "Price",
  "c-orderCreate-priceChangedTooltip":
    "Price changed because of the official trading rates, or offer creator changed his conditions",
  "c-orderCreate-priceChanged": "Price changed!",
  "c-orderCreate-platformFee": "Platform fee",
  "c-orderCreate-paidBySeller": "paid by seller",
  "c-orderCreate-iWantTo": "I want to {type}",
  "c-orderCreate-pay": "pay",
  "c-orderCreate-sell": "sell",
  "c-orderCreate-enterAmount": "Enter amount",
  "c-orderCreate-limits": "Limits",
  "c-orderCreate-fee": "Fee",
  "c-orderCreate-myBalance": "My balance",
  "c-orderCreate-sellAll": "Sell All",
  "c-orderCreate-iWillReceive": "I will receive",
  "c-orderCreate-paymentMethod": "Payment Method",
  "c-orderCreate-noPaymentOptions":
    "You don't have payment options allowed for this offer",
  "c-orderCreate-alsoAvailable": "For this offer also available",
  "c-orderCreate-addPaymentOption": "Add payment option",
  "c-orderCreate-cancel": "Cancel",
  "c-orderCreate-buy": "BUY",
};
