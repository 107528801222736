export default {
  "c-home-banners-getToKnowUs": "Ծանոթացեք մեզ հետ",
  "c-home-banners-dirtyCryptocurrency":
    "Ինչ անել, եթե ստանաք 'կեղտոտ' կրիպտոարժույթ",
  "c-home-banners-beginnersGuide": "Սկսեք, կարդալով մեր սկսնակների ուղեցույցը",

  "c-home-historywidgets-24hours": "24 ժամ",
  "c-home-historywidgets-1week": "1 շաբաթ",
  "c-home-historywidgets-1month": "1 ամիս",
  "c-home-historywidgets-usdRatesHistory": "USD փոխարժեքների պատմություն",
  "c-home-historywidgets-change": "(փոփոխություն?)",
};
