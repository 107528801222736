export default {
  "p-faq-head-title":
    "FAQ | Часто задаваемые вопросы о криптовалютной бирже Solid Swap",
  "p-faq-head-description":
    "Найдите ответы на самые часто задаваемые вопросы о Solid Swap. Узнайте о нашей платформе, мерах безопасности и как торговать криптовалютой",
  "p-faq-head-keywords":
    "FAQ, вопросы по криптовалютной бирже, Solid Swap FAQs, помощь по торговле криптовалютой",
  "p-faq-title": "FAQ о Solid Swap",
  "p-faq-q1-title": "Что такое Solid Swap?",
  "p-faq-q1-answer1":
    "Solid Swap - это платформа для быстрого обмена криптовалютами и фиатными валютами, предоставляющая услуги peer-to-peer (P2P).",
  "p-faq-q1-answer2":
    "Мы планируем в ближайшее время внедрить возможность мгновенного обмена криптовалют и продолжать расширять спектр услуг, предоставляемых нашей платформой.",
  "p-faq-q2-title": "Почему я должен доверять Solid Swap?",
  "p-faq-q2-answer1":
    "Вы можете доверять Solid Swap, потому что мы официально зарегистрированы в Армении, что позволяет нам предлагать удобные условия нашим пользователям без необходимости верификации личности и мониторинга ALM транзакций.",
  "p-faq-q2-answer2":
    "Это освобождает нас от роли традиционного банка, который мог бы заморозить ваши средства.",
  "p-faq-q2-answer3":
    "Вы всегда можете ознакомиться с отзывами наших клиентов на этой странице.",
  "p-faq-q3-title": "Какова торговая комиссия на Solid Swap?",
  "p-faq-q3-answer1":
    "Мы стремимся к прозрачности во всех аспектах нашей работы, включая сборы.",
  "p-faq-q3-answer2":
    "Торговая комиссия составляет всего 0.2% от суммы сделки, которую оплачивает продавец.",
  "p-faq-q3-answer3":
    "Это позволяет вам легко планировать свои сделки, зная, что не будет неожиданных сборов.",
  "p-faq-q4-title": "Какие валюты поддерживает биржа для торговли?",
  "p-faq-q4-answer":
    "Наша биржа поддерживает такие криптовалюты, как BTC, ETH, USDT и XMR, и работает над добавлением других валют в будущем.",
  "p-faq-q5-title":
    "Каковы минимальные суммы для депозитов и выводов на Solid Swap, и есть ли ограничения на размер заказов?",
  "p-faq-q5-answer1":
    "Минимальная сумма для депозитов и выводов на платформе Solid Swap составляет 50 долларов.",
  "p-faq-q5-answer2":
    "Если будет получена сумма меньше этой, она не будет зачислена на счет пользователя.",
  "p-faq-q5-answer3":
    "Что касается заказов, платформа не устанавливает лимитов на их размеры — их определяет покупатель или продавец самостоятельно.",
  "p-faq-q6-title": "Как определяется стоимость вывода средств?",
  "p-faq-q6-answer1":
    "Стоимость вывода денег с аккаунта изменяется динамически и зависит от текущей стоимости транзакций в сети.",
  "p-faq-q6-answer2":
    "Мы всегда стремимся предложить вам самые выгодные условия и минимизировать ваши расходы.",
  "p-faq-q7-title":
    "Какие меры безопасности принимаются после изменения пароля или настроек двухфакторной аутентификации?",
  "p-faq-q7-answer1": "Ваша безопасность - наш приоритет.",
  "p-faq-q7-answer2":
    "Поэтому после смены пароля или настроек двухфакторной аутентификации мы вводим 24-часовой блокировочный период для вывода средств.",
  "p-faq-q7-answer3":
    "Это предотвращает несанкционированный доступ к вашим средствам.",
  "p-faq-q8-title":
    "Когда я могу открыть спор, если возникают трудности с продавцом?",
  "p-faq-q8-answer1":
    "Если вы не можете достичь соглашения с продавцом, вы можете открыть спор через 30 минут после возникновения проблемы.",
  "p-faq-q8-answer2":
    "Кнопка для этого автоматически появится в вашем личном кабинете, что позволит вам быстро решить любой вопрос.",
  "p-faq-q9-title": "Сколько времени займет рассмотрение спора?",
  "p-faq-q9-answer1": "Мы стремимся рассмотреть каждый спор как можно быстрее.",
  "p-faq-q9-answer2":
    "Процесс может занять до 24 часов, как только будет предоставлена вся запрошенная информация.",
  "p-faq-q9-answer3":
    "Наша цель - предоставить вам справедливое и быстрое разрешение.",
  "p-faq-q1-answer":
    "Solid Swap — это платформа для быстрой торговли криптовалютами и фиатными валютами, мы также предлагаем услугу пирингового обмена (P2P).",
  "p-faq-q2-answer":
    "Вы можете доверять Solid Swap, потому что мы официально зарегистрированы в Армении, что позволяет нам предлагать удобные условия нашим пользователям без необходимости проверки удостоверения личности и мониторинга транзакций ALM при использовании нашей услуги P2P. Мы также предлагаем быстрый обмен, где все транзакции проверены с помощью ALM, и SolidSwap гарантирует чистоту средств, которые вы получите. Это освобождает нас от роли традиционного банка, который может заморозить ваши средства. Свободно выбирайте, какие услуги вам больше подходят.",
  "p-faq-ex-q1-title":
    "Какие ограничения по размеру заказов у услуги SolidSwap Exchange?",
  "p-faq-ex-q1-answer":
    "SolidSwap Exchange динамически изменяет минимальные и максимальные размеры заказов, пожалуйста, обратитесь к странице обмена, чтобы узнать текущие лимиты.",
  "p-faq-ex-q2-title": "Какая комиссия за торговлю на Solid Swap?",
  "p-faq-ex-q2-answer":
    "Дополнительных комиссий нет, все они включены в сумму, отображаемую при создании обмена.",
};
