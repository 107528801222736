export default {
  "c-widget-news-moreNews": "еще новости",

  "c-widget-Search-crypto": "Крипто:",
  "c-widget-Search-fiat": "Фиат:",
  "c-widget-Search-amount": "сумма для",
  "c-widget-Search-send": "отправить",
  "c-widget-Search-recieve": "получить",
  "c-widget-Search-buy": "купить",
  "c-widget-Search-sell": "продать",
  "c-widget-Search-find": "Найти",
  "c-widget-Search-selling-offers": "Предложения по продаже",
  "c-widget-Search-selling": "продажа",
  "c-widget-Search-buying-offers": "Предложения по покупке",
  "c-widget-Search-buying": "покупка",
  "c-widget-Search-price": "цена",
  "c-widget-Search-available": "доступно",
  "c-widget-Search-nothing-found":
    "ничего не найдено, попробуйте найти другие варианты",
  "c-widget-Search-close": "закрыть",
  "c-widget-Search-show-more": "показать больше",

  "c-widget-Stats-total-users": "Всего пользователей",
  "c-widget-Stats-av-reg-per-day": "сред. рег. в день",
  "c-widget-Stats-daily-active-users": "Ежедневные активные пользователи",
  "c-widget-Stats-daily-active-users-tip":
    "Количество пользователей, вошедших в продукт за один день",
  "c-widget-Stats-than-yesterday": "чем вчера",
  "c-widget-Stats-total-orders": "Всего заказов",
  "c-widget-Stats-av-orders-per-day": "сред. заказы в день",
  "c-widget-Stats-total-offers": "Всего предложений",
  "c-widget-Stats-new-offers-24h": "новые предложения за 24ч",

  "c-widget-TipTap-client-toggle-fullscreen": "Переключение на полный экран",
  "c-widget-TipTap-client-upload-image": "Загрузить изображение",
  "c-widget-TipTap-client-drop-file-here": "Перетащите файл сюда или",
  "c-widget-TipTap-client-click-to-upload": "нажмите, чтобы загрузить",
  "c-widget-TipTap-client-jpg-png-files": "jpg/png файлы размером менее 2МБ",
  "c-widget-TipTap-client-heading-1": "Заголовок 1",
  "c-widget-TipTap-client-heading-2": "Заголовок 2",
  "c-widget-TipTap-client-heading-3": "Заголовок 3",
  "c-widget-TipTap-client-paragraph": "Абзац",
  "c-widget-TipTap-client-image": "Изображение",
  "c-widget-TipTap-client-divider": "разделитель",
  "c-widget-TipTap-client-break": "разрыв",
  "c-widget-TipTap-client-clear-marks": "очистить метки",
  "c-widget-TipTap-client-please-input-link":
    "Пожалуйста, введите ссылку для вставки",
  "c-widget-TipTap-client-tip": "Подсказка",
  "c-widget-TipTap-client-ok": "ОК",
  "c-widget-TipTap-client-cancel": "Отмена",
  "c-widget-TipTap-client-input-canceled": "Ввод отменен",
  "c-widget-TipTap-client-picture-format":
    "Изображение должно быть в формате jpg/png!",
  "c-widget-TipTap-client-picture-size":
    "Размер изображения не может превышать 2МБ!",
};
