export default {
  "p-beginners-guide-head-title":
    "Guía para principiantes | Comienza a comerciar criptomonedas con Solid Swap",
  "p-beginners-guide-head-description":
    "¿Nuevo en el comercio de criptomonedas? Nuestra guía para principiantes te ayudará a comenzar con pasos fáciles de seguir y valiosos consejos sobre el comercio de activos digitales",
  "p-beginners-guide-head-keywords":
    "guía para principiantes, comercio de criptomonedas, comenzar, consejos de comercio de cripto, activos digitales",
  "p-beginners-guide-account-preparation-title":
    "Preparación de la cuenta para el comercio P2P",
  "p-beginners-guide-register-title": "Regístrate en Solid Swap",
  "p-beginners-guide-two-factor-title":
    "Habilita la autenticación de dos factores",
  "p-beginners-guide-payment-method-title": "Agrega un método de pago",
  "p-beginners-guide-finding-offers-title-1":
    "Encontrar ofertas y crear ofertas",
  "p-beginners-guide-select-currency-title-1": "Selecciona un par de divisas",
  "p-beginners-guide-search-offer-title-1": "Buscar una oferta adecuada",
  "p-beginners-guide-create-offer-title-1": "Crear una oferta de intercambio",
  "p-beginners-guide-commissions-title-1": "Comisiones",
  "p-beginners-guide-completing-transaction-title-1":
    "Completar la transacción",
  "p-beginners-guide-intro-1":
    "¡Hola! Esta guía te ayudará a hacer tu primer intercambio en Solid Swap. Describirá todos los pasos clave, siguiendo los cuales podrás completar una transacción de manera fácil y segura. Hemos hecho este proceso lo más comprensible y accesible posible, así que no te preocupes si eres nuevo en el mundo de las criptomonedas, estaremos contigo en cada paso del camino.",
  "p-beginners-guide-intro-2":
    "Comencemos con lo básico: ¿qué es Solid Swap y por qué es la elección ideal para el intercambio de criptomonedas? Solid Swap es una plataforma para el intercambio rápido de criptomonedas y dinero fiduciario, actualmente ofreciendo servicios peer-to-peer (P2P). Proporcionamos un intercambio seguro y rentable con tarifas competitivas, bajas comisiones y un alto nivel de seguridad.",
  "p-beginners-guide-steps-title":
    "Pasos clave para el comercio exitoso en Solid Swap:",
  "p-beginners-guide-follow-steps":
    "Siguiendo nuestras instrucciones, podrás hacer tu primer trato en Solid Swap de manera rápida, segura y confiada.",
  "p-beginners-guide-step1-title": "Paso 1: Registro",
  "p-beginners-guide-register-instructions": "Para registrarte en Solid Swap:",
  "p-beginners-guide-register-step1":
    "Haz clic en el botón “Registro” en la esquina superior derecha de la pantalla.",
  "p-beginners-guide-register-step2":
    "Completa la información requerida y haz clic en “Continuar”.",
  "p-beginners-guide-register-step3":
    "Revisa el correo electrónico que registraste y sigue el enlace en el correo electrónico para confirmar tu dirección de correo electrónico.",
  "p-beginners-guide-register-step4": "Inicia sesión en tu cuenta.",
  "p-beginners-guide-step2-title":
    "Paso 2: Habilitar la Autenticación de Dos Factores",
  "p-beginners-guide-two-factor-instructions":
    "Para realizar transacciones, necesitas habilitar la autenticación de dos factores. La autenticación de dos factores es especialmente importante para proteger tus activos y cuenta. Para esto:",
  "p-beginners-guide-two-factor-step1":
    "Haz clic en tu ícono de perfil en la esquina superior derecha de la pantalla y ve a la configuración haciendo clic en el botón “Configuración”.",
  "p-beginners-guide-two-factor-step2":
    "Haz clic en el botón rojo “Habilitar 2FA”.",
  "p-beginners-guide-two-factor-step3":
    "Escanea el código QR que se muestra en la pantalla para vincularlo con tu cuenta en Solid Swap.",
  "p-beginners-guide-two-factor-note":
    "Si no tienes una aplicación de autenticación de dos factores, no te preocupes. Puedes descargar una de las siguientes aplicaciones en tu smartphone:",
  "p-beginners-guide-two-factor-app1":
    "Google Authenticator (para Android y iOS)",
  "p-beginners-guide-two-factor-app2": "Authy (para Android y iOS)",
  "p-beginners-guide-two-factor-app3":
    "Microsoft Authenticator (para Android y iOS)",
  "p-beginners-guide-faq":
    "Para obtener información más detallada sobre la conexión de 2FA, así como respuestas a otras preguntas frecuentes, visita nuestro [FAQ](link to FAQ).",
  "p-beginners-guide-step3-title": "Paso 3: Agregar un Método de Pago",
  "p-beginners-guide-payment-method-instructions":
    "Para realizar cualquier intercambio, necesitas agregar un método de pago. Para esto:",
  "p-beginners-guide-payment-method-step1":
    "En la configuración de tu cuenta, ve a la pestaña “Opciones de pago”.",
  "p-beginners-guide-payment-method-step2":
    "Haz clic en el botón “+ Agregar nueva opción de pago”.",
  "p-beginners-guide-payment-method-step3":
    "Selecciona un método de pago conveniente de la lista desplegable.",
  "p-beginners-guide-payment-method-step4":
    "En el campo “Escribir la descripción”, puedes especificar información adicional. Esto se mostrará cuando seas el vendedor. Por ejemplo, el número de una tarjeta bancaria o cartera criptográfica donde aceptas la moneda.",
  "p-beginners-guide-finding-offers-title":
    "Encontrar Ofertas y Seleccionar un Vendedor",
  "p-beginners-guide-select-currency-title":
    "Paso 1: Selección de un Par de Divisas",
  "p-beginners-guide-select-currency-step1":
    "Ve a la pestaña de comercio P2P, ubicada en el panel en la parte superior de la página.",
  "p-beginners-guide-select-currency-step2":
    "Elige la acción que planeas realizar: comprar o vender.",
  "p-beginners-guide-select-currency-step3":
    "Selecciona el método de pago que se utilizará en la transacción.",
  "p-beginners-guide-select-currency-step4":
    "Ingresa la cantidad de moneda que planeas intercambiar para ver todas las ofertas adecuadas.",
  "p-beginners-guide-select-currency-note":
    "El par de divisas también se puede seleccionar en la página principal del sitio.",
  "p-beginners-guide-search-offer-title": "Paso 2: Buscar una Oferta Adecuada",
  "p-beginners-guide-search-offer-description":
    "Elige la oferta de intercambio que más te convenga.",
  "p-beginners-guide-search-offer-note":
    "Al elegir un vendedor, presta atención a los siguientes indicadores:",
  "p-beginners-guide-search-offer-seller-rating":
    "Calificación del vendedor: Cada vendedor tiene una calificación basada en los comentarios de compradores anteriores. Elige vendedores con altas calificaciones para tener más confianza.",
  "p-beginners-guide-search-offer-transactions":
    "Número de transacciones completadas: Nota el número de transacciones exitosas realizadas por el vendedor. Los vendedores con un gran número de transacciones suelen ser más confiables.",
  "p-beginners-guide-search-offer-reviews":
    "Reseñas: Lee las reseñas de otros usuarios sobre el vendedor. Esto te ayudará a evaluar su reputación y la calidad de los servicios prestados. Puedes hacerlo en el perfil del vendedor.",
  "p-beginners-guide-search-offer-limits":
    "Límites de la cantidad de transacción: Verifica los montos mínimos y máximos que el vendedor está dispuesto a intercambiar. Asegúrate de que cumplan con tus requisitos.",
  "p-beginners-guide-create-offer-title": "Paso 3: Selección de una Oferta",
  "p-beginners-guide-create-offer-step1":
    "Cuando encuentres una oferta adecuada, haz clic en el botón verde “Comprar” junto a ella para ver los detalles.",
  "p-beginners-guide-create-offer-step2":
    "Revisa los términos del trato, incluidos el tipo de cambio, los montos mínimos y máximos y los métodos de pago.",
  "p-beginners-guide-create-offer-step3":
    "Si estás de acuerdo con los términos, puedes proceder a abrir la transacción. Para hacerlo, en la ventana que se abre, completa los campos vacíos y selecciona el método de pago. Haz clic en el botón “Comprar”.",
  "p-beginners-guide-create-offer-step4":
    "Si ninguna de las ofertas presentadas te conviene, puedes crear la tuya. Para esto:",
  "p-beginners-guide-create-offer-note1":
    "Haz clic en el botón “+ Publicar nueva oferta”.",
  "p-beginners-guide-create-offer-note2":
    "Completa los campos con la información necesaria.",
  "p-beginners-guide-create-offer-note3":
    "En la descripción, puedes especificar información adicional sobre el trato.",
  "p-beginners-guide-create-offer-note4": "Haz clic en el botón “Confirmar”.",
  "p-beginners-guide-create-offer-note5":
    "Después de esto, estarás en la sección “Mis ofertas”. Aquí encontrarás todas las ofertas que has creado. Para hacer una oferta visible para otros usuarios del sitio, cambia su estado. Puedes hacerlo haciendo clic en el interruptor correspondiente en el lado derecho de la oferta.",
  "p-beginners-guide-commissions-title": "¡Ten en cuenta!",
  "p-beginners-guide-commissions-description":
    "Nos esforzamos por la transparencia en todos los aspectos de nuestro trabajo, incluidas las comisiones. La comisión de negociación es solo del 0.2% del monto de la transacción, que paga el vendedor. Esto te permite planificar fácilmente tus transacciones, sabiendo que no habrá tarifas inesperadas.",
  "p-beginners-guide-completing-transaction-title": "Completar la Transacción",
  "p-beginners-guide-completing-transaction-description1":
    "Después de haber encontrado una oferta de intercambio adecuada y hacer clic en el botón “Comprar”, estarás en la página de la transacción. Aquí está toda la información sobre el intercambio. ¡Recomendamos encarecidamente revisarla para asegurar la precisión y evitar transacciones erróneas!",
  "p-beginners-guide-completing-transaction-description2":
    "En la página de la transacción, también encontrarás un chat con el vendedor. En él, puedes aclarar los términos del intercambio u otra información que te interese.",
  "p-beginners-guide-completing-transaction-description3":
    "En la ventana de transacción activa:",
  "p-beginners-guide-completing-transaction-step1":
    "Verifica las condiciones y detalles del intercambio.",
  "p-beginners-guide-completing-transaction-step2":
    "Realiza la transferencia al método de pago especificado por el vendedor.",
  "p-beginners-guide-completing-transaction-step3":
    "Confirma la transferencia haciendo clic en el botón “El pago fue enviado”.",
  "p-beginners-guide-completing-transaction-step4":
    "Espera la confirmación del pago por parte del vendedor y la transferencia de fondos a tu cuenta.",
  "p-beginners-guide-completing-transaction-step5":
    "Deja una reseña de la transacción: las reseñas ayudan a los usuarios a entender con quién cooperar y a quién evitar. ¡Valoramos tus esfuerzos para crear una atmósfera favorable en nuestra plataforma!",
  "p-beginners-guide-active-transactions-note1":
    "Puedes encontrar tus transacciones activas en la sección de comercio P2P, en la pestaña “pedidos”.",
  "p-beginners-guide-active-transactions-note2":
    "En caso de disputa, puedes abrir una disputa 30 minutos después de confirmar la transferencia del pago. El botón para esto aparecerá automáticamente en la página de la transacción después de que expire el temporizador.",
  "p-beginners-guide-active-transactions-note3":
    "Si has decidido vender moneda y has creado una oferta de venta, espera a que alguien responda. Cuando esto ocurra, recibirás una notificación.",
  "p-beginners-guide-active-transactions-note4":
    "Después de encontrar un comprador, procede con el intercambio.",
  "p-beginners-guide-active-transactions-note5":
    "Verifica las condiciones y detalles del intercambio.",
  "p-beginners-guide-active-transactions-note6":
    "Verifica si el pago ha sido realizado por el comprador.",
  "p-beginners-guide-active-transactions-note7":
    "Confirma la recepción de fondos haciendo clic en el botón “Pago recibido”.",
  "p-beginners-guide-active-transactions-note8":
    "Envía la cantidad acordada al método de recepción especificado por el comprador.",
  "p-beginners-guide-ending-note1":
    "¡Listo! Has completado con éxito tu primera transacción en Solid Swap. Esperamos que esta guía te haya ayudado a superar todos los pasos sin dificultad.",
  "p-beginners-guide-ending-note2":
    "Recuerda siempre la seguridad al intercambiar criptomonedas. Revisa siempre los términos del trato y la reputación del vendedor, usa la autenticación de dos factores y ten cuidado al transferir fondos. Si tienes alguna pregunta o problema, nuestro servicio de soporte siempre está listo para ayudarte.",
  "p-beginners-guide-ending-note3":
    "Gracias por elegir Solid Swap. ¡Te deseamos intercambios exitosos y seguros!",
  "p-beginners-guide-ending-note4":
    "Si tienes alguna pregunta o problema, nuestro servicio de soporte siempre está listo para ayudarte.",
  "p-beginners-guide-ending-note5":
    "Gracias por elegir Solid Swap. ¡Te deseamos intercambios exitosos y seguros!",
};
