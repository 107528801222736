export default {
  "c-Withdrawal-success": "Erfolgreiche Abhebung!",
  "c-Withdrawal-error":
    "Fehler bei der Abhebung, bitte versuchen Sie es später erneut",
  "c-Withdrawal-enter-valid-amount": "Geben Sie einen gültigen Betrag ein",
  "c-Withdrawal-enter-valid-address":
    "Geben Sie eine gültige öffentliche Adresse ein",
  "c-Withdrawal-enter-tfa-code": "Geben Sie Ihren 2FA-Code aus der App ein",
  "c-Withdrawal-title": "Abhebung von",
  "c-Withdrawal-message-1":
    "Bitte füllen Sie das unten stehende Formular aus, um mit der Abhebung fortzufahren",
  "c-Withdrawal-message-2":
    "Nach dem Fortfahren mit der Abhebung haben Sie ein mehrminütiges Intervall, um Ihre Transaktion aus der Transaktionshistorie zu stornieren",
  "c-Withdrawal-no-fee":
    "Derzeit gibt es keine Abhebungsgebühr, Sie werden nur die Netzwerkgebühr zahlen, die von deren Auslastung abhängt und vom Abhebungsbetrag abgezogen wird.",
  "c-Withdrawal-amount-label": "Abhebungsbetrag",
  "c-Withdrawal-available": "Verfügbar haben Sie",
  "c-Withdrawal-withdraw-all": "Alles abheben",
  "c-Withdrawal-commission": "Kommission:",
  "c-Withdrawal-address-label": "Öffentliche Wallet-Adresse",
  "c-Withdrawal-security-tip-title": "Sicherheitstipp:",
  "c-Withdrawal-security-tip-desc":
    "Überprüfen Sie immer die Adresse vor dem Absenden doppelt. Stellen Sie sicher, dass Sie den Empfänger erkennen.",
  "c-Withdrawal-tfa-label": "Zwei-Faktor-Authentifizierungscode",
  "c-Withdrawal-scam-warning-title": "Achtung vor Betrug:",
  "c-Withdrawal-scam-warning-desc":
    "Geben Sie niemals Ihre Schlüssel oder 2FA-Codes an jemanden weiter. Offizieller Support wird niemals nach diesen Informationen fragen.",
  "c-Withdrawal-cancel": "Abbrechen",
  "c-Withdrawal-continue": "Fortfahren",
};
