export default {
  "c-widget-news-moreNews": "ավելի շատ նորություններ",

  "c-widget-Search-crypto": "Կրիպտո:",
  "c-widget-Search-fiat": "Ֆիատ:",
  "c-widget-Search-amount": "քանակ",
  "c-widget-Search-send": "ուղարկել",
  "c-widget-Search-recieve": "ստանալ",
  "c-widget-Search-buy": "գնել",
  "c-widget-Search-sell": "վաճառել",
  "c-widget-Search-find": "Գտնել",
  "c-widget-Search-selling-offers": "Վաճառքի առաջարկներ",
  "c-widget-Search-selling": "վաճառք",
  "c-widget-Search-buying-offers": "Գնման առաջարկներ",
  "c-widget-Search-buying": "գնում",
  "c-widget-Search-price": "գին",
  "c-widget-Search-available": "հասանելի",
  "c-widget-Search-nothing-found":
    "ոչինչ չի գտնվել, փորձեք գտնել այլ տարբերակներ",
  "c-widget-Search-close": "փակել",
  "c-widget-Search-show-more": "ցույց տալ ավելին",

  "c-widget-Stats-total-users": "Ընդհանուր օգտատերեր",
  "c-widget-Stats-av-reg-per-day": "միջ. գրանցումներ օրական",
  "c-widget-Stats-daily-active-users": "Օրական ակտիվ օգտատերեր",
  "c-widget-Stats-daily-active-users-tip":
    "Օրվա մեջ ապրանքի մեջ մուտք գործած օգտվողների թիվը",
  "c-widget-Stats-than-yesterday": "նախորդ օրվա համեմատ",
  "c-widget-Stats-total-orders": "Ընդհանուր պատվերներ",
  "c-widget-Stats-av-orders-per-day": "միջ. պատվերներ օրական",
  "c-widget-Stats-total-offers": "Ընդհանուր առաջարկներ",
  "c-widget-Stats-new-offers-24h": "նոր առաջարկներ 24 ժամում",

  "c-widget-TipTap-client-toggle-fullscreen": "Ամբողջ էկրանի ռեժիմ",
  "c-widget-TipTap-client-upload-image": "Վերբեռնել նկար",
  "c-widget-TipTap-client-drop-file-here": "Քաշեք ֆայլը այստեղ կամ",
  "c-widget-TipTap-client-click-to-upload": "սեղմեք վերբեռնելու համար",
  "c-widget-TipTap-client-jpg-png-files": "jpg/png ֆայլեր՝ չափը 2MB-ից քիչ",
  "c-widget-TipTap-client-heading-1": "Վերնագիր 1",
  "c-widget-TipTap-client-heading-2": "Վերնագիր 2",
  "c-widget-TipTap-client-heading-3": "Վերնագիր 3",
  "c-widget-TipTap-client-paragraph": "Պարբերություն",
  "c-widget-TipTap-client-image": "Նկար",
  "c-widget-TipTap-client-divider": "բաժանարար",
  "c-widget-TipTap-client-break": "ընդմիջում",
  "c-widget-TipTap-client-clear-marks": "հեռացնել նշումները",
  "c-widget-TipTap-client-please-input-link":
    "Խնդրում ենք մուտքագրել հղում կպցնելու համար",
  "c-widget-TipTap-client-tip": "Հուշում",
  "c-widget-TipTap-client-ok": "Լավ",
  "c-widget-TipTap-client-cancel": "Չեղարկել",
  "c-widget-TipTap-client-input-canceled": "Մուտքը չեղարկված է",
  "c-widget-TipTap-client-picture-format":
    "Նկարը պետք է լինի jpg/png ֆորմատով:",
  "c-widget-TipTap-client-picture-size": "Նկարի չափը չպետք է գերազանցի 2MB:",
};
