export default {
  "p-index-head-title":
    "Solid Swap | Secure and Efficient Cryptocurrency Exchange",
  "p-index-head-description":
    "Experience fast, secure, and efficient cryptocurrency trading with Solid Swap. Join now to start swapping digital assets with ease",
  "p-index-head-keywords":
    "cryptocurrency exchange, secure trading, digital assets, crypto trading, Solid Swap, solidswap",
  "p-index-prices-finder": "Prices finder:",
  "p-index-welcome-no-fees": "No fees for buyer, ultra low 0.2% seller fee",
  "p-index-welcome-stable": "Stable, legalized and ALM free",
  "p-index-welcome-no-id": "No ID verification required",
  "p-index-welcome-message-2": "Trade your way, anytime, anywhere",
  "p-index-get-started": "GET STARTED",
  "p-index-learn-more": "LEARN MORE",
  "p-index-world-opening-title": "We are opening up the world to you",
  "p-index-world-opening-message-1":
    "Cryptocurrency will make you free, it is freedom from the shackles of banks, the dictates of the government",
  "p-index-world-opening-message-2":
    "This is freedom of movement around the world and the ability to save your money in any country",
  "p-index-mobile-app-title": "Download the mobile app for free",
  "p-index-mobile-app-message":
    "The mobile application is available for download on the App Store and Google Play",
  "p-index-coming-soon": "Coming this summer",
  "p-index-subscribe-title": "Subscribe to Solid Swap",
  "p-index-welcome-title": "Advantages of our P2P trade",
  "p-index-our-blog": "Our blog:",
};
