export default {
  "p-trade-profile-title": "支付选项 | Solid Swap 上的安全加密支付方式",
  "p-trade-profile-description":
    "了解 Solid Swap 上可用的各种安全支付选项进行加密货币交易。选择最适合您需求的方法",
  "p-trade-profile-keywords":
    "加密支付选项，安全支付方式，数字货币支付，加密货币交易",
  "p-trade-profile-option-delete-confirm": "此操作将删除您的支付选项。继续吗？",
  "p-trade-profile-warning": "警告",
  "p-trade-profile-delete": "删除",
  "p-trade-profile-cancel": "取消",
  "p-trade-profile-option-delete-error": "删除支付选项时发生错误",
  "p-trade-profile-my-payment-options": "我的支付选项",
  "p-trade-profile-payment-options-info-1":
    "这些是您的支付选项，所有用户之间的交易都需要这些选项。",
  "p-trade-profile-payment-options-info-2":
    "如果您在出售加密货币，支付选项将告知对方转移资金的方式。",
  "p-trade-profile-payment-options-info-3":
    "如果您在购买加密货币，这些选项将在订单创建期间选择支付类型，向卖方显示您当前订单的首选支付方式。",
  "p-trade-profile-no-options": "您还没有选项",
  "p-trade-profile-edit": "编辑",
  "p-trade-profile-add-new-payment-option": "添加新的支付选项",
};
