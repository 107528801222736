export default {
  "c-Withdrawal-success": "提现成功！",
  "c-Withdrawal-error": "提现时发生错误，请稍后再试",
  "c-Withdrawal-enter-valid-amount": "请输入有效金额",
  "c-Withdrawal-enter-valid-address": "请输入有效的公共地址",
  "c-Withdrawal-enter-tfa-code": "输入应用程序中的 2FA 代码",
  "c-Withdrawal-title": "提现",
  "c-Withdrawal-message-1": "请填写以下表格以继续提现",
  "c-Withdrawal-message-2":
    "在继续提现后，您将在几分钟内可以从交易历史页面取消您的交易",
  "c-Withdrawal-no-fee":
    "目前没有提现费用，您只需支付网络费用，这取决于网络的负载，并将从提现金额中扣除。",
  "c-Withdrawal-amount-label": "提现金额",
  "c-Withdrawal-available": "您有可用金额",
  "c-Withdrawal-withdraw-all": "全部提现",
  "c-Withdrawal-commission": "佣金：",
  "c-Withdrawal-address-label": "钱包公共地址",
  "c-Withdrawal-security-tip-title": "安全提示：",
  "c-Withdrawal-security-tip-desc":
    "提交前请务必仔细检查地址。确保您认识收款人。",
  "c-Withdrawal-tfa-label": "双重身份验证代码",
  "c-Withdrawal-scam-warning-title": "警惕诈骗：",
  "c-Withdrawal-scam-warning-desc":
    "永远不要与他人分享您的密钥或双重身份验证代码。官方支持永远不会要求这些信息。",
  "c-Withdrawal-cancel": "取消",
  "c-Withdrawal-continue": "继续",
};
