export default {
  "p-trade-head-title":
    "Торговля криптовалютой | Безопасная торговля цифровыми активами на Solid Swap",
  "p-trade-head-description":
    "Начните безопасно торговать криптовалютой с Solid Swap. Наслаждайтесь быстрыми и эффективными обменами цифровыми активами на нашей удобной платформе",
  "p-trade-head-keywords":
    "торговля криптовалютой, безопасная торговля, обмен цифровыми активами, платформа для торговли криптовалютой",
  "p-trade-needLogin": "Вам нужно войти, чтобы создать предложение",
  "p-trade-menu-buy": "Купить",
  "p-trade-menu-sell": "Продать",
  "p-trade-menu-orders": "Заказы",
  "p-trade-menu-myOffers": "Мои предложения",
  "p-trade-menu-paymentOptions": "Варианты оплаты",
  "p-trade-buying": "Покупка",
  "p-trade-selling": "Продажа",
  "p-trade-placeholder-type": "выберите тип...",
  "p-trade-for": "за",
  "p-trade-placeholder-allOptions": "все варианты оплаты",
  "p-trade-amountIn": "сумма в",
  "p-trade-placeholder-anyAmount": "любая сумма",
  "p-trade-minimumLimit": "минимальный лимит сделки",
  "p-trade-equivalent": "эквивалентен {MIN_OFFER_AMOUNT_USD} USD",
  "p-trade-ALM": "Показывать только проверенных ALM трейдеров",
  "p-trade-postNewOffer": "Разместить новое предложение",
};
