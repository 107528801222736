export default {
  "p-trade-my-offers-title": "我的报价 | 在 Solid Swap 上管理您的加密报价",
  "p-trade-my-offers-description":
    "在 Solid Swap 上查看和管理您的加密货币报价。通过我们用户友好的界面确保最佳交易策略",
  "p-trade-my-offers-keywords":
    "管理加密报价，加密货币报价，交易报价，数字资产报价",
  "p-trade-my-offers-delete-confirm": "确定要删除此报价吗？",
  "p-trade-my-offers-warning": "警告",
  "p-trade-my-offers-ok": "确定",
  "p-trade-my-offers-cancel": "取消",
  "p-trade-my-offers-post-new-offer": "发布新报价",
  "p-trade-my-offers-no-active-offers": "未找到活跃报价",
  "p-trade-my-offers-your-selling-offers": "您的销售报价",
  "p-trade-my-offers-your-buying-offers": "您的购买报价",
};
