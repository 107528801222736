export default {
  "p-support-chat-welcome": "Welcome to Our Support Chat!",
  "p-support-chat-intro":
    "We are here to assist you with any questions or concerns you may have. Our goal is to provide you with the best possible support experience. Here's how you can get started:",
  "p-support-chat-enter-details-prefix": "Enter Your Details: ",
  "p-support-chat-enter-details-suffix":
    "title of your question. This information helps us to better assist you and address your concerns more efficiently.",
  "p-support-chat-open-chat":
    'Open the Chat: click on the "Open Support Chat" button to begin.',
  "p-support-chat-start-conversation":
    "Start the Conversation: once you've entered your details and submitted the form, a chat box will appear on the page. One of our moderators will join the chat shortly to assist you.",
  "p-support-chat-appreciation":
    "We appreciate your patience and look forward to helping you!",
  "p-support-chat-your-name": "Your name",
  "p-support-chat-your-email": "Your email",
  "p-support-chat-your-question": "Your question",
  "p-support-chat-open-chat-button": "Open Support Chat",
  "p-support-chat-wait-moderator": "Wait for moderator to join",
  "p-support-chat-contact-alternative":
    "If you have any trouble using the chat or prefer other ways of contacting us, please feel free to reach out via our",
  "p-support-chat-contact-page": "contact page",
  "p-support-chat-or-email": "or email us at",
  "p-support-chat-thank-you": "Thank you for choosing our services!",
  "p-support-chat-ask-question": "Ask a question",
  "p-support-chat-name-required": "Name is required",
  "p-support-chat-email-required": "Email is required",
  "p-support-chat-valid-email": "Enter valid email",
  "p-support-chat-chat-started": "Support chat started!",
  "p-support-chat-name-email": "name, email,",
};
