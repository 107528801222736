export default {
  "p-profile-userId-error": "Error: user not found",
  "p-profile-userId-online": "Online",
  "p-profile-userId-last-online": "was online {date}",
  "p-profile-userId-registration-date": "Registration date",
  "p-profile-userId-total-orders": "Total orders",
  "p-profile-userId-buy": "BUY",
  "p-profile-userId-sell": "SELL",
  "p-profile-userId-last-month-orders": "Last month orders",
  "p-profile-userId-average-confirm-time": "Average confirm payment time",
  "p-profile-userId-average-send-time": "Average send payment time",
  "p-profile-userId-feedback": "Feedback",
  "p-profile-userId-positive": "positive",
  "p-profile-userId-negative": "negative",
  "p-profile-userId-offers-tab": "Offers",
  "p-profile-userId-feedback-tab": "Feedback",
  "p-profile-userId-no-offers": "No offers",
  "p-profile-userId-buy-orders": "Buy orders",
  "p-profile-userId-sell-orders": "Sell orders",
  "p-profile-userId-no-feedback": "No feedback",
};
