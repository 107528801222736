export default {
  "p-trade-profile-title":
    "Payment Options | Secure Crypto Payment Methods on Solid Swap",
  "p-trade-profile-description":
    "Discover various secure payment options available on Solid Swap for cryptocurrency trading. Choose the best method for your needs",
  "p-trade-profile-keywords":
    "crypto payment options, secure payment methods, digital currency payments, cryptocurrency transactions",
  "p-trade-profile-option-delete-confirm":
    "This action will delete your payment option. Continue?",
  "p-trade-profile-warning": "Warning",
  "p-trade-profile-delete": "Delete",
  "p-trade-profile-cancel": "Cancel",
  "p-trade-profile-option-delete-error":
    "An error occurred while deleting the payment option",
  "p-trade-profile-my-payment-options": "My payment options",
  "p-trade-profile-payment-options-info-1":
    "These are your payment options, required for all transactions between users.",
  "p-trade-profile-payment-options-info-2":
    "If you are selling cryptocurrency, the payment options will inform the counterparty where to transfer funds for the purchase.",
  "p-trade-profile-payment-options-info-3":
    "If you are buying cryptocurrency, these options will be used to select the payment type during order creation, indicating to the seller your preferred payment method for the current order.",
  "p-trade-profile-no-options": "You have no options yet",
  "p-trade-profile-edit": "Edit",
  "p-trade-profile-add-new-payment-option": "Add new payment option",
};
