export default {
  "c-blog-editingform-pictureFormatError":
    "Das Bild muss im JPG/PNG-Format vorliegen!",
  "c-blog-editingform-pictureSizeError":
    "Die Bildgröße darf 2 MB nicht überschreiten!",
  "c-blog-editingform-uploadError": "Fehler beim Hochladen des Bildes!",
  "c-blog-editingform-titleRequired": "Titel ist erforderlich",
  "c-blog-editingform-idCodeRequired": "URL-Code ist erforderlich",
  "c-blog-editingform-descriptionRequired": "Beschreibung ist erforderlich",
  "c-blog-editingform-previewImageRequired": "Vorschaubild ist erforderlich",
  "c-blog-editingform-tagsRequired": "Tags sind erforderlich",
  "c-blog-editingform-bodyRequired": "Beitragstext ist erforderlich",
  "c-blog-editingform-metaDescriptionRequired":
    "Meta-Beschreibung ist erforderlich",
  "c-blog-editingform-creatingNewPost": "Neuen Beitrag erstellen",
  "c-blog-editingform-title": "Titel",
  "c-blog-editingform-active": "Aktiv",
  "c-blog-editingform-idCode": "URL-Code",
  "c-blog-editingform-idCodeHint":
    "Der Code sollte für jeden Blog-Beitrag eindeutig sein, in Kleinbuchstaben und mit '-' statt Leerzeichen",
  "c-blog-editingform-description": "Beschreibung",
  "c-blog-editingform-previewImage": "Vorschaubild",
  "c-blog-editingform-uploadHint1": "Datei hier ablegen oder",
  "c-blog-editingform-uploadHint2": "klicken, um hochzuladen",
  "c-blog-editingform-uploadHint3":
    "JPG/PNG-Dateien mit einer Größe von weniger als 2 MB",
  "c-blog-editingform-tags": "Tags",
  "c-blog-editingform-tagsPlaceholder": "Wählen Sie Tags für Ihren Artikel",
  "c-blog-editingform-tagsHint":
    "Sie können aus vorhandenen Tags wählen oder neue erstellen, indem Sie im Feld tippen und Enter drücken",
  "c-blog-editingform-postText": "Beitragstext",
  "c-blog-editingform-metaDescription": "Meta-Beschreibung",
  "c-blog-editingform-cancel": "Abbrechen",
  "c-blog-editingform-save": "Speichern",

  "c-blog-item-readMore": "Weiterlesen",
};
