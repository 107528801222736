export default {
  "p-confirm-email-title": "Էլեկտրոնային փոստի հաստատում",
  "p-confirm-email-wait-message":
    "Սպասեք խնդրում ենք, մենք փորձում ենք հաստատել ձեր էլեկտրոնային փոստի հասցեն...",
  "p-confirm-email-success-message":
    "Ձեր էլեկտրոնային փոստի հասցեն հաջողությամբ հաստատվել է!",
  "p-confirm-email-success-elmessage":
    "Ձեր էլեկտրոնային փոստի հասցեն հաջողությամբ հաստատվել է! Դուք կվերահասցեագրվեք մուտքի էջ 30 վայրկյանում:",
  "p-confirm-email-info-elmessage":
    "Հիմա դուք կարող եք մուտք գործել ձեր հաշիվ որպես: {email}",
  "p-confirm-email-error-message":
    "Ինչ-որ սխալ է տեղի ունեցել, խնդրում ենք փորձել ավելի ուշ:",
  "p-confirm-email-proceed-button": "Շարունակել մուտք գործել",
  "p-confirm-email-welcome-title": "Բարի գալուստ նոր փոխանակման դարաշրջան!",
  "p-confirm-email-welcome-message":
    "Ապահով միացեք թվային ակտիվների աշխարհին: Սկսեք փոխանակումը վստահությամբ և հարմարությամբ մեր վստահելի P2P հարթակում: Ձեր դարպասը հարթ կրիպտո գործարքների է սպասում.",
};
