export default {
  "c-home-banners-getToKnowUs": "Познакомьтесь с нами",
  "c-home-banners-dirtyCryptocurrency":
    "Что делать, если вы получили 'грязную' криптовалюту",
  "c-home-banners-beginnersGuide":
    "Начните с чтения нашего руководства для начинающих",

  "c-home-historywidgets-24hours": "24 часа",
  "c-home-historywidgets-1week": "1 неделя",
  "c-home-historywidgets-1month": "1 месяц",
  "c-home-historywidgets-usdRatesHistory": "История курсов USD для",
  "c-home-historywidgets-change": "(изменение?)",
};
