export default {
  "c-offers-editform-closeConfirmation":
    "Вы уверены, что хотите закрыть это диалоговое окно?",
  "c-offers-editform-percentError":
    "Значение процента % должно быть в пределах от -99 до 99",
  "c-offers-editform-createError": "Ошибка при создании предложения",
  "c-offers-editform-createSuccess": "Предложение успешно создано!",
  "c-offers-editform-updateSuccess": "Предложение успешно обновлено!",
  "c-offers-editform-typeRequired": "Пожалуйста, выберите тип заказа",
  "c-offers-editform-currencyRequired": "Пожалуйста, выберите валюту",
  "c-offers-editform-priceTypeRequired": "Пожалуйста, выберите тип цены",
  "c-offers-editform-priceValRequired": "Пожалуйста, введите значение цены",
  "c-offers-editform-paymentOptionsRequired":
    "Пожалуйста, выберите варианты оплаты",
  "c-offers-editform-minLimitRequired": "Пожалуйста, введите минимальную сумму",
  "c-offers-editform-minLimitError":
    "Минимальная сумма должна быть больше {minAmount}",
  "c-offers-editform-maxLimitRequired":
    "Пожалуйста, введите максимальную сумму",
  "c-offers-editform-maxLimitError":
    "Максимальная сумма не должна быть меньше минимального лимита",
  "c-offers-editform-offerAmountRequired":
    "Пожалуйста, введите сумму предложения",
  "c-offers-editform-termsOfUseRequired":
    "Пожалуйста, введите условия использования",
  "c-offers-editform-editTitle": "Редактировать ваше предложение",
  "c-offers-editform-addTitle": "Добавить новое предложение",
  "c-offers-editform-offerType": "Тип предложения",
  "c-offers-editform-sell": "продажа",
  "c-offers-editform-buy": "покупка",
  "c-offers-editform-selling": "Продажа",
  "c-offers-editform-buying": "Покупка",
  "c-offers-editform-mainCurrency": "Основная валюта",
  "c-offers-editform-selectCurrency": "выберите валюту",
  "c-offers-editform-forCurrency": "...за",
  "c-offers-editform-currentRate":
    "текущий курс: 1 {currencyIn} = {rate} {currencyOut}",
  "c-offers-editform-price": "Цена",
  "c-offers-editform-priceType": "Тип цены",
  "c-offers-editform-selectPriceType": "выберите тип цены",
  "c-offers-editform-percentValue": "Значение процента",
  "c-offers-editform-relativeValue": "Относительное значение",
  "c-offers-editform-fixedValue": "Фиксированное значение",
  "c-offers-editform-priceTypesExplained": "Объяснение типов цен:",
  "c-offers-editform-percent": "Процент",
  "c-offers-editform-percentExplanation":
    "Цена корректируется на основе процентного различия от текущих курсов. Если пользователь вводит процент, окончательная цена будет рассчитана путем применения этого процента как увеличения или уменьшения к текущим курсам.",
  "c-offers-editform-relative": "Относительное",
  "c-offers-editform-relativeExplanation":
    "Цена корректируется путем добавления или вычитания конкретного числа из текущих курсов. Пользователь вводит это число, и оно напрямую изменяет базовую цену, делая окончательную цену выше или ниже на эту точную сумму.",
  "c-offers-editform-fixed": "Фиксированное",
  "c-offers-editform-fixedExplanation":
    "Это простой метод установки цены, где пользователь устанавливает цену напрямую. Цена, введенная пользователем, будет окончательной, без каких-либо корректировок на основе текущих курсов или процентов.",
  "c-offers-editform-pricePercent": "Цена в процентах",
  "c-offers-editform-priceRelative": "Относительная цена",
  "c-offers-editform-priceFixed": "Фиксированная цена",
  "c-offers-editform-enterPriceValue": "введите значение цены",
  "c-offers-editform-rate":
    "согласно выбранному типу и полям цены ваш курс: 1 {currencyIn} = {rate} {currencyOut}",
  "c-offers-editform-paymentOptions": "Варианты оплаты",
  "c-offers-editform-selectPaymentOptions": "выберите варианты оплаты",
  "c-offers-editform-noOptions": "Нет доступных вариантов",
  "c-offers-editform-all": "Все",
  "c-offers-editform-addOption": "Добавить вариант оплаты",
  "c-offers-editform-minLimit": "Минимальный лимит",
  "c-offers-editform-enterMinAmount": "мин. сумма",
  "c-offers-editform-maxLimit": "Максимальный лимит",
  "c-offers-editform-enterMaxAmount": "макс. сумма",
  "c-offers-editform-orderLimitsExplanation": "Объяснение лимитов заказа",
  "c-offers-editform-orderLimitsExplanationTitle": "Объяснение лимитов заказа",
  "c-offers-editform-orderLimitsExplanationText":
    "Минимальный лимит и максимальный лимит указывают диапазон, в котором вы можете создать заказ. Вы не можете разместить заказ на покупку или продажу криптовалюты ниже минимального лимита или выше максимального лимита.",
  "c-offers-editform-orderLimitsExplanationUSD":
    "Кроме того, наша платформа вводит минимальный лимит, эквивалентный {MIN_OFFER_AMOUNT_USD} USD для всех предложений, это около {usdLimit} {currencyOut}",
  "c-offers-editform-amountToTrade": "Сумма для торговли",
  "c-offers-editform-offerAmount": "Сумма предложения",
  "c-offers-editform-offerAmount-unlimited": "неограниченно",
  "c-offers-editform-enterOfferAmount": "сумма",
  "c-offers-editform-currentBalance":
    "Мой текущий баланс: {balance} {currencyIn}",
  "c-offers-editform-offerAmountExplanation": "Объяснение суммы предложения",
  "c-offers-editform-offerAmountExplanationTitle":
    "Объяснение суммы предложения",
  "c-offers-editform-offerAmountExplanationText1":
    "Сумма предложения - это общая сумма криптовалюты, которую вы готовы обменять.",
  "c-offers-editform-offerAmountExplanationText2":
    "Для предложений на продажу это сумма криптовалюты, которую вы хотите продать. Например: Если у вас на балансе 1000 USDT и вы хотите обменять только половину, вы можете установить сумму предложения в 500 USDT. Это гарантирует, что вы не продадите больше 500 USDT. Обратите внимание, что все ваши средства остаются на вашем счету, и при отмене предложения они возвращаются на ваш баланс.",
  "c-offers-editform-offerAmountExplanationText3":
    "Для предложений на покупку эта сумма указывает, сколько криптовалюты вы хотите купить. Если вы установите сумму предложения в 1000 USDT, вы получите именно эту сумму.",
  "c-offers-editform-offerAmountExplanationText4":
    "Кроме того, наша платформа взимает комиссию, которую оплачивает продавец. Таким образом, если вы создаете предложение на продажу, комиссия оплачивается вами. Если вы создаете предложение на покупку, комиссия будет взиматься с контрагента, который создаст заказ.",
  "c-offers-editform-termsOfUse": "Условия использования",
  "c-offers-editform-termsOfUseText":
    "Пожалуйста, заполните поле 'Условия использования' ниже. В этом разделе должны быть изложены общие условия и положения, на которых вы готовы торговать. Здесь нет необходимости указывать ваши банковские данные или другую конфиденциальную информацию.",
  "c-offers-editform-termsOfUsePlaceholder": "напишите описание...",
  "c-offers-editform-cancel": "Отмена",
  "c-offers-editform-confirm": "Подтвердить",
};
