export default {
  "p-trade-head-title":
    "Trade Cryptocurrency | Secure Digital Asset Trading on Solid Swap",
  "p-trade-head-description":
    "Start trading cryptocurrency securely with Solid Swap. Enjoy fast and efficient digital asset swaps on our user-friendly platform",
  "p-trade-head-keywords":
    "cryptocurrency trading, secure trading, digital asset swaps, crypto trading platform",
  "p-trade-needLogin": "You need to login to create offer",
  "p-trade-menu-buy": "Buy",
  "p-trade-menu-sell": "Sell",
  "p-trade-menu-orders": "Orders",
  "p-trade-menu-myOffers": "My offers",
  "p-trade-menu-paymentOptions": "Payment options",
  "p-trade-buying": "Buying",
  "p-trade-selling": "Selling",
  "p-trade-placeholder-type": "select type...",
  "p-trade-for": "for",
  "p-trade-placeholder-allOptions": "all payment options",
  "p-trade-amountIn": "amount in",
  "p-trade-placeholder-anyAmount": "any amount",
  "p-trade-minimumLimit": "minimum trade limit is",
  "p-trade-equivalent": "equivalent to {MIN_OFFER_AMOUNT_USD} USD",
  "p-trade-ALM": "Only show ALM verified traders",
  "p-trade-postNewOffer": "Post new offer",
};
