export default {
  "p-exchange-tos-agreement-intro":
    "This User Agreement (hereinafter referred to as the 'Agreement') is an electronic agreement between the User and the SolidSwap service and contains the rules for working on the SolidSwap Website at",
  "p-exchange-tos-agreement-intro-continued":
    "(hereinafter referred to as the 'Site') and terms of access services provided by the Site. Before starting to work with the Site, the user of the Site (hereinafter referred to as the 'User') must carefully read the described terms and accept this Agreement. If the User does not accept the Agreement and does not commit to comply with all the terms of this Agreement, he should immediately stop using the Site.",
  "p-exchange-tos-agreement-updates":
    "The Site Administration reserves the right, at its sole discretion, to update and change the text of the Agreement without notifying Users. The new version of the Agreement comes into force immediately after its publication on the Website. The User is obliged to independently monitor new versions of the Agreement. By continuing to use the Site after the changes come into force, the User automatically accepts the new version of the Agreement. If the User does not agree with the terms of the new version of the Agreement, he should deactivate his account if available and not use the services of the service.",
  "p-exchange-tos-terms-definitions": "1. Terms and definitions",
  "p-exchange-tos-solid-swap-service":
    "1.1. SolidSwap service is a platform designed for conducting trading operations with virtual digital assets.",
  "p-exchange-tos-user-definition":
    "1.2. The User is an individual registered on the Platform and has agreed to the terms and conditions of this Agreement. The User confirms that he has reached the age of 18, the laws of his country of residence do not prohibit the use of this Service, as well as the User does not plan to carry out any illegal activity through the use of SolidSwap services.",
  "p-exchange-tos-title-sign":
    "1.3. The title sign is a conventional unit of the payment system of electronic and other types of currencies, which corresponds to the calculations of electronic systems and indicates the amount of rights corresponding to the agreement of various payment systems and its owner.",
  "p-exchange-tos-payment-system":
    "1.4. Payment system is an online service that implements payment for goods and services through electronic money transfers.",
  "p-exchange-tos-service-operations":
    "1.5. Services of the service - operations for the entry and withdrawal of electronic currencies, as well as other services, information about which is posted on the Service's website.",
  "p-exchange-tos-payment-definition":
    "1.6. Payment is a money transfer carried out using a payment system.",
  "p-exchange-tos-otc-platform":
    "1.7. OTC Platform is a trading platform for finding counterparties and making over-the-counter transactions.",
  "p-exchange-tos-personal-data":
    "1.8. Personal data — information that identifies a person, such as name, address, email address, mobile phone number, bidding information and bank details. The term 'Personal Data' does not include anonymous and/or aggregated data that does not make it possible to identify a specific User.",
  "p-exchange-tos-agreement-definition":
    "1.9. Agreement – this User Agreement and its integral parts, taking into account all additions and/or changes that may be made in the manner determined by their terms.",
  "p-exchange-tos-website-definition":
    "1.10. Website — a collection of information, texts, graphic elements, design, images, photos and videos and other results of intellectual activity, as well as a set of computer programs contained in an information system that ensure the availability of such information at",
  "p-exchange-tos-website-continued":
    ", including any subdomains, and if the domain or Site material needs to be changed for any reason, it includes the Site after any such changes.",
  "p-exchange-tos-intellectual-property":
    "1.11. Intellectual property – all materials published on the Site, as well as all Services provided by the Service, are the property of the Service and are not subject to third-party use: publication, distribution, duplication.",
  "p-exchange-tos-application-definition":
    "1.12. Application – the User makes an offer to purchase or sell virtual digital assets using the Services provided by the Service.",
  "p-exchange-tos-electronic-document":
    "1.13. Electronic document – information in electronic and digital form.",
  "p-exchange-tos-suspicious-traffic":
    "1.14. Suspicious traffic - a set of actions, transactions, assets, etc. that could potentially violate AML/CTF and KYC policies",
  "p-exchange-tos-verification-procedures":
    "1.15. Verification Procedures (KYC) - verification procedures within the framework of anti-money laundering standards - the 'Know Your Customer' (KYC) policy.",
  "p-exchange-tos-persons-subject-restrictions":
    "1.16. Persons subject to restrictions - means any person in the United States and/or any individual included in the List of Citizens of Special Categories or Blocked Persons of the U.S. Treasury Department, the Consolidated List of EU Financial Sanctions or the UK Sanctions List, as well as citizens and residents from limited jurisdictions (p 1.17).",
  "p-exchange-tos-limited-jurisdiction":
    "1.17. Limited jurisdiction - limited jurisdictions include: United States of America, Afghanistan, Bosnia and Herzegovina, Democratic People's Republic of Korea, Democratic Republic of the Congo, Eritrea, Ethiopia, Guyana, Iran, Iraq, Lao People's Democratic Republic, Libya, Somalia, Sri Lanka, Sudan, Syria, Trinidad and Tobago, Tunisia, Uganda, Vanuatu, Ukraine, United Kingdom, Mainland China, Singapore, Hong Kong, Canada, Cuba, Uzbekistan, Crimea, France, Sevastopol, Donetsk and Lugansk regions.",
  "p-exchange-tos-introduction": "2. Introduction",
  "p-exchange-tos-introduction-text":
    "2.1. This agreement regulates the relationship between the User and the Service within the framework of the services provided by the Service to the User, and cancels all previous agreements between the Service and the User on this subject.",
  "p-exchange-tos-subject-agreement": "3. Subject of the agreement",
  "p-exchange-tos-subject-agreement-services":
    "3.1. The subject of this Agreement is the Services provided by the Service, which are described on the Service's website.",
  "p-exchange-tos-subject-agreement-restrictions":
    "3.2. The Service offers its services to all interested Users, with the exception of persons subject to restrictions (paragraph 1.16).",
  "p-exchange-tos-payment-systems-responsibility":
    "3.3. Payment systems and/or financial institutions are solely responsible for the funds entrusted to them by the User. The Service cannot be a party to an agreement between the Payment System and/or a financial institution and the User and is in no way responsible for the incorrect or unauthorized use of the Payment System by the User, as well as for the User's abuse of the functionality of the Payment System. The mutual rights and obligations of the User and the Payment System and/or financial institution are regulated by the relevant agreements.",
  "p-exchange-tos-operation-cannot-cancel":
    "3.4. Any operation initiated to exchange the User's funds, as well as any other operation offered by the Service to the User, cannot be canceled by the User.",
  "p-exchange-tos-rights-obligations":
    "4. Rights and obligations of the Parties",
  "p-exchange-tos-suspend-operation-authorities":
    "4.1. The Service has the right to suspend or cancel an ongoing operation if the authorized authorities receive information about the illegality of the User's ownership of funds and/or other information that makes it impossible for the Service to provide services to the User.",
  "p-exchange-tos-suspend-refund-operation":
    "4.2. The Service has the right to suspend, refuse a refund, cancel an ongoing operation if the User violates the terms of this Agreement.",
  "p-exchange-tos-cancel-operation-title-return":
    "4.3. The Service has the right to cancel the operation in progress and return the title characters entered by the User without explaining the reasons.",
  "p-exchange-tos-prevent-illegal-activities":
    "4.4. The Service undertakes to take all possible and available actions to prevent attempts at illegal trade, financial fraud and money laundering using the services of the Service. These actions include: providing all possible assistance to law enforcement agencies in the search and capture of financial terrorists engaged in illegal money laundering activities; providing information to the competent authorities in accordance with current legislation regarding the processing of the Service; improving the Service to prevent the direct or indirect use of the Service in activities contrary to legislation aimed at combating illegal trade, financial fraud and money laundering.",
  "p-exchange-tos-suspicious-traffic-rights":
    "4.5. In case of detection of suspicious traffic on the part of the User, the Service reserves the right to transfer all information at the request of law enforcement agencies about the identity, the request for exchange and the currency received from the User.",
  "p-exchange-tos-accept-agreement":
    "4.6. By using the services provided on the site, the User accepts and confirms that he agrees with these Rules and AML/CTF and KYC policy",
  "p-exchange-tos-user-guarantee":
    "4.7. Using the services provided on the site, the User guarantees that he provides complete, truthful and up-to-date information about himself, that the use of the services of the service is carried out only for personal purposes that do not violate the laws of the country in which the User is located, and also undertakes to update the data in a timely manner to maintain their relevance.",
  "p-exchange-tos-user-confidentiality":
    "4.8. The User guarantees the confidentiality of registration data and access to his account, and also assumes full responsibility for all actions taking place under his account, including in case of hacking or unauthorized access.",
  "p-exchange-tos-report-security-breach":
    "4.9. The User undertakes to promptly report a violation of the security of his account.",
  "p-exchange-tos-block-account":
    "4.10. The Service has the right to block the User's account in case of suspicion of illegal actions, suspicion of account hacking or in case of violation of the rules of the Service. The User's Account is blocked without the right to restore if the User creates obstacles in the operation of the Service.",
  "p-exchange-tos-rightful-owner":
    "4.11. By accepting the terms of this Agreement, the User guarantees that he is the rightful owner of funds and/or cryptocurrencies.",
  "p-exchange-tos-understand-service-risks":
    "4.12. By accepting this User Agreement, the User confirms that he understands the principles of the Service and assumes full responsibility for all risks and losses associated with the use of the Service.",
  "p-exchange-tos-consent-data-processing":
    "4.13. The User gives his consent to the Service for the processing of personal data provided during registration on the site, during the verification procedure, as well as during the collection of any information about the client on the site",
  "p-exchange-tos-personal-data-includes": "4.14. Personal data includes:",
  "p-exchange-tos-name": "Last name, First Name, Patronymic;",
  "p-exchange-tos-photo-passport": "photo/passport scan;",
  "p-exchange-tos-other-id-documents":
    "other identification documents; (individually in each case)",
  "p-exchange-tos-video-selfie": "video selfie with passport in hand;",
  "p-exchange-tos-email": "email address;",
  "p-exchange-tos-phone-number":
    "mobile phone number (which can be used to contact the client);",
  "p-exchange-tos-wallet-details":
    "details of the wallets used; (individually in each case);",
  "p-exchange-tos-card-number":
    "card number and/or bank account; (individually in each case);",
  "p-exchange-tos-transactions-wallets": "transactions and wallet addresses.",
  "p-exchange-tos-processing-personal-data":
    "4.15. Processing of personal data means recording, systematization, accumulation, storage, clarification (updating, modification), extraction, use, transfer (distribution, provision, access), including cross-border, depersonalization, blocking, deletion, destruction of personal data that do not fall under special categories, the processing of which requires written consent User's consent.",
  "p-exchange-tos-processing-purpose":
    "4.16. The processing of personal data is carried out in order for the Parties to fulfill their obligations under the Agreement, register the User on the Site, provide the User with technical support, submit Applications, provide Services, consider appeals and claims, send informational and other messages to the User's e-mail address.",
  "p-exchange-tos-fincen-rule":
    "4.17. According to the final rule of the Financial Crimes Investigation Network (FinCEN) in accordance with Article 31 of the Code of Federal Rules 1010.100(ff), which obliges each foreign firm providing settlement and cash services to appoint a representative of the service residing in the United States as an agent to carry out the trial in accordance with the requirements of the BSA Association and According to its by-laws, SolidSwap does not provide e-currency exchange services to U.S. citizens and users residing in the United States.",
  "p-exchange-tos-legal-prohibited":
    "4.18. The services provided by SolidSwap may be legally prohibited in some jurisdictions. By accepting SolidSwap's terms of use, you confirm that you are not a resident of such jurisdiction.",
  "p-exchange-tos-aml-kyc-checks":
    "4.19. The Service has the right to perform AML and KYC checks to comply with the 'Fifth Directive on Combating Money Laundering and Terrorist Financing (5AMLD)'",
  "p-exchange-tos-kyt-aml-violation":
    "4.19.1. If, according to the results of the KYT/AML verification of cryptocurrency transactions/assets, a violation is detected 'AML/CTF and KYC policy', then funds will be blocked and additional user verification (KYC) will be requested in accordance with clause 8.5 of this agreement, as well as proof of the legality of the origin of funds.",
  "p-exchange-tos-kyt-aml-non-return":
    "4.19.2. If, according to the results of the KYT/AML verification of cryptocurrency transactions/assets, a violation is revealed 'AML/CTF and KYC policy' and/or the situation falls within the scope of clause 9.4.2 of this agreement, then the assets may not be subject to exchange and return.",
  "p-exchange-tos-services-service": "5. Services of the Service",
  "p-exchange-tos-order-services":
    "5.1. Ordering the services of the Service and receiving information about the progress of the transaction by the User is carried out exclusively through contacts with a representative of the Service.",
  "p-exchange-tos-service-internal-schedule":
    "5.2. The Service offers its services according to the established internal schedule of the service.",
  "p-exchange-tos-questions-complaints":
    "5.3. Questions and complaints about applications are accepted within 24 hours from the moment of registration. After 24 hours, the exchange requests are considered to be properly executed or not paid, depending on their status at the time of expiration of the specified period.",
  "p-exchange-tos-suspicious-actions":
    "5.4. In case of any suspicious actions on the part of the client, as well as in case of use of suspicious assets by the user, the service may delay payment and require additional identity verification and an internal analysis of the funds used by the user.",
  "p-exchange-tos-right-to-refuse":
    "5.5. The Service reserves the right to refuse the exchange unilaterally.",
  "p-exchange-tos-restricted-citizens":
    "5.6. The services provided by SolidSwap are prohibited for use by citizens subject to restrictions (paragraph 1.16).",
  "p-exchange-tos-cost-services": "6. Cost of services",
  "p-exchange-tos-tariffs":
    "6.1. The Tariffs are determined by the management of the Service and published on the Service's website. The management of the Service has the right to change tariffs without additional notice.",
  "p-exchange-tos-expectations-tariffs":
    "6.2. The Service is not responsible for the client's expectations about changes in tariff plans and the cost of services.",
  "p-exchange-tos-guarantees-responsibilities":
    "7. Guarantees and responsibilities of the parties",
  "p-exchange-tos-guarantee-fulfillment":
    "7.1. The Service guarantees the fulfillment of its obligations to the User only within the limits of the amounts entrusted to the Service by the User to carry out the operation within the terms of the service documentation.",
  "p-exchange-tos-confidentiality-guarantee":
    "7.2. The Service guarantees and ensures the confidentiality of information about the User and his operations. The Service may provide this information only at the request of authorized government agencies, officials or representatives of Payment systems, if there are legitimate grounds for this.",
  "p-exchange-tos-efforts-technical-issues":
    "7.3. The Service makes every effort to ensure the smooth operation of the Service, but does not guarantee the absence of technical problems leading to a complete or partial suspension of the Service. The Service is not responsible for losses, lost profits and other costs incurred by the User as a result of the inability to access the site and the services of the Service.",
  "p-exchange-tos-damage-responsibility":
    "7.4. The Service is not responsible for any damage caused to the User, even if its possibility was known in advance. The Service is not responsible for obtaining information about the User's activities by third parties in case of hacking of the system and obtaining data by third parties.",
  "p-exchange-tos-no-responsibility-losses":
    "7.5. The Service does not bear any responsibility for losses, lost profits and other User costs resulting from erroneous User expectations regarding the Service's tariff rates, profitability of transactions and other subjective factors.",
  "p-exchange-tos-aml-risk-score":
    "7.6. The Service is not responsible for changing the risk score (AML Risk Score) of client wallets after making exchanges.",
  "p-exchange-tos-compensation":
    "7.7. When visiting the Service's website, using the Service's services and making an exchange, the User guarantees compensation for losses of the Service (management company, managers and employees) in cases of lawsuits or claims directly or indirectly related to the User's use of the Service's services.",
  "p-exchange-tos-not-responsible-communication":
    "7.8. The User agrees that the Service is not responsible in the event of a break in communication, an interruption in the operation of the Internet, as well as in the event of force majeure.",
  "p-exchange-tos-guarantee-owner-funds":
    "7.9. The User guarantees that he is the owner or has legitimate grounds for disposing of funds used in his transactions, bank transfers, etc.",
  "p-exchange-tos-no-falsify-communication":
    "7.10. The User undertakes not to falsify communication flows related to the operation of the Service.",
  "p-exchange-tos-force-majeure": "8. Force majeure",
  "p-exchange-tos-force-majeure-definition":
    "8.1. The Parties are released from liability for full or partial non-fulfillment of obligations under this Agreement if the non-fulfillment of obligations was the result of acts of force majeure, namely: fire, flood, earthquake, terrorist acts, change of power, civil unrest, DDOS attacks on the service, as well as non-functioning of Payment systems, energy supply systems, communication networks and Internet service providers.",
  "p-exchange-tos-error-during-payment":
    "8.2. In case of an error during the payment of the exchange, the User will receive a notification about the status of the application and /or to the specified email address.",
  "p-exchange-tos-payment-after-deadline":
    "8.3. If the application is paid after the time allotted for payment of the application, the service reserves the right to refund the payment for the application, minus the network commission and service costs, back to the User or recalculate at its discretion.",
  "p-exchange-tos-not-responsible-assets-fiat":
    "8.3.1 The Service is not responsible for the assets sent by the user for the exchange of 'give' fiat, 'receive' cryptocurrency after the expiration of the time for payment of the application.",
  "p-exchange-tos-not-responsible-assets-anonymous":
    "8.3.2 The Service is not responsible for assets sent by the user using an anonymous method that hides the sender's address, as they will not be credited to the balance of the exchange service's wallet. In this case, the exchange and refund will not be possible.",
  "p-exchange-tos-deviation-exchange-rate":
    "8.4. If the application rate deviated by more than 3% from the exchange rate on the Bybit/Binance exchange, including in case of a technical malfunction of the site, the exchange service has the right to recalculate the application at the current rate on the site or issue a refund to the User minus the costs of the service and the network commission.",
  "p-exchange-tos-invalid-exchanges":
    "8.5. In the event that the User intentionally, unintentionally, as well as in the event of a technical failure (including on the SolidSwap Service side), as a result of the actions of intruders or for other reasons, made an exchange, which in turn caused losses for the SolidSwap Service, while the deviation from the market rate in the executed application was more than 3% of the exchange rate on the Bybit/Binance exchange (this rule applies to any side of the exchange), applications made under the above conditions are considered invalid. The Service has the right to compensate for the damage incurred by withholding any future funds from the User as part of interaction with the Service.",
  "p-exchange-tos-suspicious-assets":
    "8.6. In case of detection of suspicious assets/funds violating the AML agreement of the Service and suspicious/fraudulent actions on the part of the User, the transaction is blocked by the Security Service for up to 180 days for further investigation.",
  "p-exchange-tos-unblocking-procedure":
    "To start the unblocking procedure, it is necessary to undergo a simplified user verification (KYC) in accordance with clause 4.19 of this agreement.",
  "p-exchange-tos-send-photo-passport":
    "A photo or scan of your passport, or other identification documents (at your choice);",
  "p-exchange-tos-send-phone-number":
    "A mobile phone number that can be used to contact you if necessary (optional);",
  "p-exchange-tos-send-video-selfie": "Video selfie with an identity document;",
  "p-exchange-tos-record-video":
    "Record a short video of good quality without interruptions, clearly voicing the following data:",
  "p-exchange-tos-record-full-name":
    "Full name, series and number of the identity document, holding it next to the person;",
  "p-exchange-tos-service-name":
    "The name of our service and the date of the video recording;",
  "p-exchange-tos-application-number":
    "The application number and the date of its creation;",
  "p-exchange-tos-show-wallet":
    "Show the wallet /account/ other from where exactly the payment/transaction was made, the currency and the amount. The name and address (URL) of the site must be readable if the transaction was made from a mobile application.",
  "p-exchange-tos-write-explanation":
    "Write in free form on a piece of paper by hand a brief explanation of the legality of the origin of funds, as well as that you sent us from your wallet (address) to your wallet (address and hash of the transaction), and that you are the owner of the assets, not a third party. Write the address where you are asking for the assets to be returned.",
  "p-exchange-tos-specify-address":
    "Specify the address/account of the wallet where you should return the assets.",
  "p-exchange-tos-information-deadline":
    "We expect the above information from you no later than 48 hours after receiving this email.",
  "p-exchange-tos-no-unblocking-without-data":
    "In the absence of the requested data, SolidSwap will not be able to start the procedure for unblocking your assets.",
  "p-exchange-tos-blocking-period-expiration":
    "8.6.1. After the expiration of the blocking period (180 days), from the date of passing the identity verification (KYC), the user must independently contact the customer support service by official mail, no later than 30 days.",
  "p-exchange-tos-incorrect-details":
    "8.7. The Service does not bear any financial responsibility to the User if the User specified incorrect details in the application to receive funds from the Service, and the Service made a settlement with the User by sending a payment according to the details specified in the application.",
  "p-exchange-tos-payment-delay":
    "8.8. In case of a failure in the bank/ third-party payment system, payments may be delayed for up to 5 banking days, for reasons beyond the control of the service.",
  "p-exchange-tos-deposit-outside-application":
    "8.9. A deposit made outside the application may not be credited or lost. The payment of funds sent using outdated details is carried out with a 5% commission.",
  "p-exchange-tos-non-current-details":
    "8.10. The Service is not responsible if the User specified payment details that differ from the bank specified in the direction of the exchange. Payment of funds to non-current banking details is carried out with a 5% commission within 48 hours.",
  "p-exchange-tos-fiat-exchange": "9.1. Fiat exchange",
  "p-exchange-tos-fiat-exchange-amount-limit":
    "9.1.1. Exchanges for an amount up to or equal to $150 are carried out without delay. If you use your account in the payment system to pay on our service for the first time and exchange an amount of more than $150, your request will be completed 2 hours after payment.",
  "p-exchange-tos-subsequent-exchanges":
    "9.1.2. Subsequent exchanges for an amount above $150 with the same account number in the payment system and the user's account will be instant, provided that you register before the first exchange.",
  "p-exchange-tos-additional-checks":
    "9.1.3. If less than 24 hours have passed since the first exchange, and the total amount of exchanges by bank details exceeds $500, an additional check may be established with a delay of up to 48 hours by the decision of the security service.",
  "p-exchange-tos-suspicious-actions-fiat":
    "9.1.4. In case of any suspicious actions on the part of the client, the service reserves the right to request account data that will confirm that the account belongs to the client.",
  "p-exchange-tos-crypto-exchange": "9.2. Cryptocurrency Exchange",
  "p-exchange-tos-crypto-auto-manual":
    "9.2.1. Requests made by the service in automatic mode can be made up to 12 hours. Requests made by the service in manual mode can be made up to 36 hours.",
  "p-exchange-tos-sale-cryptocurrencies":
    "9.2.2. Applications for the sale of all cryptocurrencies listed on the service's website are processed at the time the transaction receives the established individual minimum of confirmations (the minimum is set by the SolidSwap service).",
  "p-exchange-tos-recalculate-rate":
    "9.2.3. The Service has the right to recalculate your request in a favorable direction for the exchange office (both at the current and at the exchange rate that was at the time the order was created) if the transaction did not receive the required number of network confirmations within the time allotted for the request.",
  "p-exchange-tos-btc-confirmations":
    "9.2.4. Payment for the exchange of BTC cryptocurrency is made after the 3rd confirmation on the Bitcoin network. The rate in the application remains fixed if the payment was made by the User during the time allotted for payment of the application in this direction, and the transaction appeared on the network during this time, and the BTC cryptocurrency received 1 confirmation on the Bitcoin network within 15 minutes. If the 1st confirmation is received after 15 minutes, the application can be recalculated at the current exchange rate.",
  "p-exchange-tos-fiat-crypto-fixed-rate":
    "9.2.5. The exchange rate in the directions of 'giving' Fiat, 'receiving' cryptocurrency is fixed within the time allotted for payment. Applications for these directions are recalculated at the current exchange rate if the client has not managed to transfer funds to the wallet / card issued in his application during this time.",
  "p-exchange-tos-crypto-sending":
    "9.2.6. When the client purchases any cryptocurrency listed on the service's website, sending cryptocurrency by the service may take from 5 minutes to 12 hours. The speed of transaction confirmation on the network does not depend on the service.",
  "p-exchange-tos-high-load-crypto-network":
    "9.2.7. In case of high load or technical problems in the cryptocurrency network listed on the service's website, for reasons beyond the control of the Service, the delivery time of funds to the recipient's account can reach 240 hours.",
  "p-exchange-tos-recalculation-notification":
    "9.2.8. The recalculation of the application amount is carried out by the service in accordance with the terms of this agreement in automatic mode, which the service notifies the client by email.",
  "p-exchange-tos-blocked-account-responsibility":
    "9.2.9. The Service is not responsible for funds transferred to the client's blocked card or a blocked account on a crypto exchange or other centralized platform, as well as if the client was blocked after the service transferred assets to it on request.",
  "p-exchange-tos-memo-tag-required":
    "9.2.10. The exchange of coins for which a MEMO or TAG is required, when sent to our wallet, must necessarily have these MEMO or TAG values. Funds sent without using MEMO or TAG are not subject to crediting and refund.",
  "p-exchange-tos-smart-contract-processing":
    "9.2.11. Coins sent from the Smart Contract are processed manually, the processing time is 2 hours. After sending the coins, you need to inform the operator in the online chat about your application number and the HASH of the transaction.",
  "p-exchange-tos-refund": "9.3. Refund",
  "p-exchange-tos-refund-decision":
    "9.3.1. Refunds are possible only by the decision of the Service.",
  "p-exchange-tos-refund-application":
    "9.3.2. To apply for a refund, the User must send an e-mail to the Service's e-mail address:",
  "p-exchange-tos-refund-minus-commission":
    "9.3.3. The refund is carried out minus the commission of the payment system for the transfer and minus the costs of the exchange Service in the amount of 5% of the exchange amount. The exceptions are the cases described in paragraph 4.19 of this Agreement.",
  "p-exchange-tos-refund-review":
    "9.3.4. Refund requests are reviewed by the Service within 48 hours.",
  "p-exchange-tos-refund-within-48-hours":
    "9.3.5. Upon request, the Service makes a refund within 48 hours.(exception n 4.19)",
  "p-exchange-tos-deposit-below-minimum":
    "9.3.6. Deposits made less than the minimum amount will neither be credited nor refunded.",
  "p-exchange-tos-assets-return-clause-419":
    "9.3.7. When exchanging assets specified in clause 4.19 of this Agreement, after passing the AML and KYC procedures, the Service reserves the right not to provide the exchange service to the User and return the funds, observing all procedures of the internal refund regulations.",
  "p-exchange-tos-return-aml-failed-assets":
    "9.4. Return of assets that have not passed AML verification.",
  "p-exchange-tos-return-blocked-assets":
    "9.4.1. The return of blocked assets that have not passed the AML verification is possible only after passing the identity verification of clause 8.6 of this Agreement and compliance with clause 8.6.1.",
  "p-exchange-tos-request-from-authorities":
    "9.4.2. The return of assets is impossible if a request has been received for these assets from competent authorities and other departments, including from any jurisdiction, in this case, the blocked asset may be used as material evidence in the case.",
  "p-exchange-tos-refund-5-percent-commission":
    "9.4.3. Upon fulfillment of clauses 8.6 and 8.6.1, the refund to the client will be made with a 5% commission deduction.",
  "p-exchange-tos-no-return-criminal-wallets":
    "9.4.4. Cryptocurrency assets are not subject to return and exchange if, according to the results of AML verification by the system",
  "p-exchange-tos-dark-market": "DARK MARKET",
  "p-exchange-tos-dark-service": "DARK SERVICE",
  "p-exchange-tos-scam": "SCAM",
  "p-exchange-tos-stolen": "STOLEN",
  "p-exchange-tos-mixing": "MIXING(MIXER)",
  "p-exchange-tos-sextortion": "SEXTORTION",
  "p-exchange-tos-ransomware": "RANSOMWARE",
  "p-exchange-tos-hack": "HACK",
  "p-exchange-tos-phishing": "PHISHING",
  "p-exchange-tos-terrorism-financing": "TERRORISM FINANCING",
  "p-exchange-tos-fraud": "FRAUD",
  "p-exchange-tos-blacklist": "BLACKLIST",
  "p-exchange-tos-under-investigation": "STILL UNDER INVESTIGATION",
  "p-exchange-tos-cybercrime-organization": "CYBERCRIME ORGANIZATION",
  "p-exchange-tos-narcotics": "NARCOTICS",
  "p-exchange-tos-child-abuse": "CHILD ABUSE",
  "p-exchange-tos-human-trafficking": "HUMAN TRAFFICKING",
  "p-exchange-tos-sanctions": "SANCTIONS",
  "p-exchange-tos-change-recipient-details":
    "9.5. Changing the details of the recipient of the application",
  "p-exchange-tos-change-recipient-details-procedure":
    "9.5.1. In order to change the details of the recipient, the User submits an application for the replacement of the details in an email sent to the post office",
  "p-exchange-tos-acceptance-terms": "10. Acceptance of the terms of service",
  "p-exchange-tos-acceptance-terms-text":
    "10.1. Acceptance of the terms of the 'User Agreement' when creating an application is automatically considered by the service as an agreement with all documentation regulating the operation of the service.",
};
