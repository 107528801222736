export default {
  "p-kyc-aml-policy-title":
    "Politique de Lutte Contre le Blanchiment d'Argent (Anti-Money Laundering Policy).",
  "p-kyc-warning":
    "SolidSwap avertit les utilisateurs contre toute tentative d'utiliser le service pour légaliser des fonds obtenus illégalement, financer le terrorisme, commettre des actes illégaux de toute sorte, ou utiliser le service pour acheter des biens et services interdits.",
  "p-kyc-illegal-funds-description":
    "La légalisation des fonds obtenus par des moyens criminels consiste à dissimuler une source illégale de fonds en les transférant vers des fonds ou des investissements ayant l'apparence d'être légitimes.",
  "p-kyc-general-provisions-title": "Dispositions Générales",
  "p-kyc-prevent-illegal-transactions":
    "Afin de prévenir les transactions illégales, le Service fixe certaines exigences pour toutes les demandes créées par l'Utilisateur :",
  "p-kyc-sender-recipient-same":
    "L'expéditeur et le destinataire du paiement sur la demande doivent être la même personne. Les transferts en faveur de tiers sont strictement interdits.",
  "p-kyc-contact-info-requirements":
    "Toutes les informations de contact saisies par l'Utilisateur dans la demande, ainsi que toutes les données personnelles transmises au Service, doivent être à jour et parfaitement fiables.",
  "p-kyc-no-anonymous-connections":
    "Il est strictement interdit pour un utilisateur de créer des demandes en utilisant des serveurs proxy anonymes ou toute autre connexion Internet anonyme.",
  "p-kyc-policy-description":
    "Cette Politique de Lutte Contre le Blanchiment d'Argent (ci-après dénommée « Politique AML ») décrit les procédures et mécanismes utilisés par SolidSwap pour lutter contre le blanchiment d'argent.",
  "p-kyc-no-business-with-criminals":
    "ne pas établir de relations commerciales avec des criminels et/ou terroristes connus ;",
  "p-kyc-no-criminal-transactions":
    "ne pas traiter les transactions résultant d'activités criminelles et/ou terroristes connues ;",
  "p-kyc-no-facilitating-criminal-activity":
    "ne pas faciliter de transactions liées à des activités criminelles et/ou terroristes connues ;",
  "p-kyc-right-to-restrict-services":
    "se réserve le droit de restreindre ou d'interdire l'utilisation de tout ou partie des services pour les personnes soumises à des restrictions...",
  "p-kyc-verification-procedures-title": "Procédures de Vérification",
  "p-kyc-kyc-policy-description":
    "Le service SolidSwap met en place ses propres procédures de vérification dans le cadre des normes de lutte contre le blanchiment d'argent - la politique « Connaître Son Client » (KYC).",
  "p-kyc-verification-steps":
    "Les utilisateurs du service SolidSwap suivent la procédure de vérification (fournir un document d'identité gouvernemental : passeport ou carte d'identité).",
  "p-kyc-request-second-document":
    "Le service SolidSwap peut également demander un deuxième document pour identifier l'utilisateur...",
  "p-kyc-verification-authenticity":
    "Le service SolidSwap vérifie l'authenticité des documents et des informations fournies par les utilisateurs...",
  "p-kyc-request-updated-documents":
    "Si les informations d'identification de l'utilisateur ont changé ou que ses activités semblent suspectes...",
  "p-kyc-aml-compliance-title":
    "Responsable de la Conformité à la Politique AML",
  "p-kyc-aml-compliance-description":
    "La personne responsable de la conformité à la Politique AML est un employé de SolidSwap...",
  "p-kyc-collect-user-info":
    "Collecter les informations d'identification des utilisateurs et les transmettre à l'agent responsable du traitement des données personnelles...",
  "p-kyc-create-update-policies":
    "Création et mise à jour de politiques et de procédures internes...",
  "p-kyc-monitor-transactions":
    "Surveillance des transactions et analyse des écarts significatifs...",
  "p-kyc-record-management":
    "Mise en œuvre d'un système de gestion des enregistrements pour stocker et rechercher des documents...",
  "p-kyc-regular-risk-update":
    "Mise à jour régulière de l'évaluation des risques.",
  "p-kyc-interaction-with-authorities":
    "La personne responsable de la conformité à la Politique AML a le droit de collaborer avec les autorités chargées de la lutte contre le blanchiment d'argent...",
  "p-kyc-transaction-monitoring-title": "Surveillance des Transactions",
  "p-kyc-monitoring-description":
    "La surveillance des transactions des utilisateurs et l'analyse des données reçues...",
  "p-kyc-report-suspicious-transactions":
    "Signaler des transactions suspectes aux autorités compétentes...",
  "p-kyc-request-additional-info":
    "Demander à l'utilisateur de fournir des informations et documents supplémentaires...",
  "p-kyc-suspend-terminate-account":
    "Suspendre ou résilier le compte de l'utilisateur...",
  "p-kyc-freeze-assets":
    "Suspendre la transaction et geler les actifs jusqu'à clarification des circonstances...",
  "p-kyc-return-funds":
    "Restituer les fonds à l'utilisateur en annulant la procédure d'échange conformément à l'accord utilisateur...",
  "p-kyc-risk-assessment": "Évaluation des risques.",
  "p-kyc-additional-monitoring":
    "Le Responsable de la Politique AML surveille quotidiennement les transactions des utilisateurs pour déterminer si...",
};
