export default {
  "c-payment-option-close-dialog-confirm": "确定要关闭支付选项对话框吗？",
  "c-payment-option-save-error": "保存支付选项时出错",
  "c-payment-option-edit": "编辑支付选项",
  "c-payment-option-new": "新增支付选项",
  "c-payment-option-show-currency-methods":
    "此处将显示仅含 {currency} 货币的支付方法",
  "c-payment-option-show-specified-options": "此处将显示仅报价中指定的支付选项",
  "c-payment-option-editing-method": "编辑支付方法：",
  "c-payment-option-select-method": "选择要添加的方法：",
  "c-payment-option-select-method-placeholder": "选择支付方式...",
  "c-payment-option-description": "支付类型描述：",
  "c-payment-option-description-info":
    "请提供您的支付类型描述。这应包括您的账户号码、卡号或任何相关信息，以帮助买家识别在购买加密货币时将资金转到哪里。",
  "c-payment-option-description-placeholder": "写一些描述...",
  "c-payment-option-save": "保存",
  "c-payment-option-validate-payment-option": "请选择支付方式",
  "c-payment-option-validate-description": "请添加描述",
};
