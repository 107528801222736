export default {
  "p-trade-orders-title": "交易订单 | 在 Solid Swap 上管理您的加密订单",
  "p-trade-orders-description":
    "在 Solid Swap 上高效管理您的加密货币订单。无缝跟踪和控制您的交易活动",
  "p-trade-orders-keywords":
    "管理加密订单，交易订单，加密货币订单跟踪，交易管理",

  "p-trade-orders-order-fetch-error": "加载订单时发生错误，请重新加载页面",
  "p-trade-orders-order-not-found": "未找到订单“{orderId}”",
  "p-trade-orders-payment-was-sent": "付款已发送",
  "p-trade-orders-payment-was-sent-confirm":
    "您确认已从您的端发送付款吗？只有在银行确认后才能执行此操作。继续吗？",
  "p-trade-orders-payment-received": "付款已收到",
  "p-trade-orders-payment-received-confirm":
    "您确认已收到付款吗？只有在您的银行确认后才能执行此操作。如果要继续，请在下方输入您应用中的双重身份验证代码",
  "p-trade-orders-open-dispute": "开启争议",
  "p-trade-orders-open-dispute-confirm": "您想为此订单开启争议。继续吗？",
  "p-trade-orders-change-status": "更改状态",
  "p-trade-orders-change-status-confirm": "确定要更改状态吗？",
  "p-trade-orders-continue": "继续",
  "p-trade-orders-cancel": "取消",
  "p-trade-orders-invalid-2fa": "无效的双重身份验证",
  "p-trade-orders-status-changed": "订单状态已变更",
  "p-trade-orders-fetch-order-error": "获取订单数据时发生错误",
  "p-trade-orders-go-to-all-orders": "前往所有订单",
  "p-trade-orders-status": "状态",
  "p-trade-orders-waiting-for-payment": "等待买家付款",
  "p-trade-orders-payment-sent": "买家已发送付款",
  "p-trade-orders-order-completed": "订单已完成",
  "p-trade-orders-order-cancelled": "订单已取消",
  "p-trade-orders-dispute": "争议",
  "p-trade-orders-auto-cancel-after": "自动取消订单在",
  "p-trade-orders-open-dispute-after": "争议可在之后开启",
  "p-trade-orders-order-created": "订单已创建",
  "p-trade-orders-summary": "摘要",
  "p-trade-orders-sell": "出售",
  "p-trade-orders-buy": "购买",
  "p-trade-orders-for": "用于",
  "p-trade-orders-fee": "费用",
  "p-trade-orders-included-in-transaction": "包含在交易中",
  "p-trade-orders-selling": "出售",
  "p-trade-orders-buying": "购买",
  "p-trade-orders-price": "价格",
  "p-trade-orders-offer-creator": "报价创建者",
  "p-trade-orders-trader-info": "交易者提供的信息",
  "p-trade-orders-no-description": "交易者未指定描述。",
  "p-trade-orders-no-description-additional-info":
    "订单创建后，您将收到更多信息。",
  "p-trade-orders-trading-experience": "您的交易体验如何？",
  "p-trade-orders-positive": "正面",
  "p-trade-orders-negative": "负面",
  "p-trade-orders-your-feedback": "您的反馈",

  "p-trade-ordersIndex-all": "全部",
  "p-trade-ordersIndex-active": "活跃",
  "p-trade-ordersIndex-completed": "已完成",
  "p-trade-ordersIndex-cancelled": "已取消",
  "p-trade-ordersIndex-dispute": "争议",
  "p-trade-ordersIndex-coin": "币种",
  "p-trade-ordersIndex-order-type": "订单类型",
  "p-trade-ordersIndex-dates": "日期",
  "p-trade-ordersIndex-reset-filters": "重置过滤器",
  "p-trade-ordersIndex-loading-error": "订单加载时出了点问题 :(",
  "p-trade-ordersIndex-orders": "订单",
  "p-trade-ordersIndex-id-type": "ID / 类型",
  "p-trade-ordersIndex-operation-info": "操作信息",
  "p-trade-ordersIndex-status-date": "状态 / 日期",
  "p-trade-ordersIndex-id": "ID",
  "p-trade-ordersIndex-sell": "出售",
  "p-trade-ordersIndex-buy": "购买",
  "p-trade-ordersIndex-go-to-details": "查看详情",
  "p-trade-ordersIndex-summary": "摘要",
  "p-trade-ordersIndex-fee": "费用",
  "p-trade-ordersIndex-selling": "出售",
  "p-trade-ordersIndex-buying": "购买",
  "p-trade-ordersIndex-for": "用于",
  "p-trade-ordersIndex-price": "价格",
  "p-trade-ordersIndex-offer-creator": "报价创建者",
  "p-trade-ordersIndex-order-created": "订单已创建",
  "p-trade-ordersIndex-status": "状态",
  "p-trade-ordersIndex-waiting-for-buyer": "等待买家付款",
  "p-trade-ordersIndex-buyer-sent-payment": "买家已发送付款",
  "p-trade-ordersIndex-order-completed": "订单已完成",
  "p-trade-ordersIndex-order-cancelled": "订单已取消",
  "p-trade-ordersIndex-last7days": "最近7天",
  "p-trade-ordersIndex-lastMonth": "上个月",
  "p-trade-ordersIndex-last3month": "最近3个月",
  "p-trade-ordersIndex-dispute-caps": "争议",
};
