export default {
  "p-support-chat-welcome": "Բարի գալուստ մեր աջակցման զրույցին:",
  "p-support-chat-intro":
    "Մենք այստեղ ենք, որպեսզի օգնենք ձեզ ցանկացած հարցի կամ խնդրի վերաբերյալ, որը դուք կարող եք ունենալ: Մեր նպատակն է ապահովել լավագույն աջակցման փորձը: Ահա թե ինչպես կարող եք սկսել:",
  "p-support-chat-enter-details-prefix": "Մուտքագրեք ձեր տվյալները: ",
  "p-support-chat-enter-details-suffix":
    "հարցի վերնագիրը: Այս տեղեկությունը կօգնի մեզ ավելի լավ աջակցել ձեզ և ավելի արդյունավետ լուծել ձեր խնդիրները:",
  "p-support-chat-open-chat":
    'Բացեք զրույցը՝ սեղմելով "Բացել աջակցման զրույցը" կոճակը՝ սկսելու համար:',
  "p-support-chat-start-conversation":
    "Սկսեք զրույցը: Երբ դուք մուտքագրեք ձեր տվյալները և ներկայացնեք ձևը, էջում կհայտնվի զրույցի պատուհան: Մեր մոդերատորներից մեկը կցանկանա միանալ զրույցին՝ ձեզ աջակցելու համար:",
  "p-support-chat-appreciation":
    "Մենք գնահատում ենք ձեր համբերությունը և սպասում ենք, որ օգնենք ձեզ:",
  "p-support-chat-your-name": "Ձեր անունը",
  "p-support-chat-your-email": "Ձեր էլ. փոստը",
  "p-support-chat-your-question": "Ձեր հարցը",
  "p-support-chat-open-chat-button": "Բացել աջակցման զրույցը",
  "p-support-chat-wait-moderator": "Սպասեք մոդերատորի միանալուն",
  "p-support-chat-contact-alternative":
    "Եթե ունեք խնդիր զրույցը օգտագործելու կամ նախընտրում եք այլ միջոցներ կապվելու մեզ հետ, խնդրում ենք դիմել մեր",
  "p-support-chat-contact-page": "կապի էջը",
  "p-support-chat-or-email": "կամ էլ. փոստով մեզ գրեք",
  "p-support-chat-thank-you":
    "Շնորհակալություն, որ ընտրեցիք մեր ծառայությունները:",
  "p-support-chat-ask-question": "Հարց տվեք",
  "p-support-chat-name-required": "Անունը պարտադիր է",
  "p-support-chat-email-required": "Էլ. փոստը պարտադիր է",
  "p-support-chat-valid-email": "Մուտքագրեք վավեր էլ. փոստ",
  "p-support-chat-chat-started": "Աջակցման զրույցը սկսված է:",
  "p-support-chat-name-email": "անունը, էլ. փոստը,",
};
