export default {
  "c-blog-editingform-pictureFormatError": "图片必须是 jpg/png 格式！",
  "c-blog-editingform-pictureSizeError": "图片大小不能超过 2MB！",
  "c-blog-editingform-uploadError": "上传图片失败！",
  "c-blog-editingform-titleRequired": "标题是必需的",
  "c-blog-editingform-idCodeRequired": "URL 代码是必需的",
  "c-blog-editingform-descriptionRequired": "描述是必需的",
  "c-blog-editingform-previewImageRequired": "预览图片是必需的",
  "c-blog-editingform-tagsRequired": "标签是必需的",
  "c-blog-editingform-bodyRequired": "文章内容是必需的",
  "c-blog-editingform-metaDescriptionRequired": "Meta 描述是必需的",
  "c-blog-editingform-creatingNewPost": "创建新文章",
  "c-blog-editingform-title": "标题",
  "c-blog-editingform-active": "活跃",
  "c-blog-editingform-idCode": "URL 代码",
  "c-blog-editingform-idCodeHint":
    "代码应该对每个博客文章唯一，小写，并使用 '-' 代替空格",
  "c-blog-editingform-description": "描述",
  "c-blog-editingform-previewImage": "预览图片",
  "c-blog-editingform-uploadHint1": "将文件拖到此处或",
  "c-blog-editingform-uploadHint2": "点击上传",
  "c-blog-editingform-uploadHint3": "jpg/png 文件小于 2MB",
  "c-blog-editingform-tags": "标签",
  "c-blog-editingform-tagsPlaceholder": "为您的文章选择标签",
  "c-blog-editingform-tagsHint":
    "您可以从现有标签中选择或通过在字段中输入并按 Enter 键创建新标签",
  "c-blog-editingform-postText": "文章内容",
  "c-blog-editingform-metaDescription": "Meta 描述",
  "c-blog-editingform-cancel": "取消",
  "c-blog-editingform-save": "保存",

  "c-blog-item-readMore": "阅读更多",
};
