export default {
  "p-help-head-title": "帮助 | 联系 Solid Swap 支持",
  "p-help-head-description":
    "需要帮助？通过 Solid Swap 打开支持票证，获取我们的专家团队帮助解决遇到的问题",
  "p-help-head-keywords":
    "支持票证，Solid Swap 帮助，加密货币交易支持，联系支持",
  "p-help-title": "帮助页面",
  "p-help-name-required": "请输入您的姓名",
  "p-help-email-required": "请输入您的邮箱",
  "p-help-message-required": "在这里告诉我们您的问题",
  "p-help-ticket-success": "票证已成功发送！",
  "p-help-ticket-error": "发送票证时出错，请稍后重试",
  "p-help-thank-you-message":
    "感谢您联系我们！我们已收到您的消息，并将尽快通过您提供的邮箱地址与您联系。",
  "p-help-intro-text":
    "如果您遇到任何问题或有任何疑问，请随时联系我们。填写下面的表单，我们会尽快回复您。",
  "p-help-label-name": "姓名",
  "p-help-placeholder-name": "在这里输入您的姓名",
  "p-help-label-email": "邮箱",
  "p-help-placeholder-email": "在这里输入您的邮箱",
  "p-help-label-message": "消息",
  "p-help-placeholder-message": "...",
  "p-help-submit-button": "提交",
};
