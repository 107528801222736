export default {
  "c-exchange-steps-verifying-card": "Überprüfung der Benutzerkarte",
  "c-exchange-steps-waiting-alm": "Warten auf ALM-Ergebnisse",
  "c-exchange-steps-preparing-crypto": "Plattformadresse vorbereiten",
  "c-exchange-steps-moderator-verifying": "Moderator überprüft Daten",
  "c-exchange-steps-preparing-fiat": "Fiat-Anforderungen vorbereiten",
  "c-exchange-steps-waiting-payment": "Warten auf die Zahlung des Benutzers",
  "c-exchange-steps-confirming-payment": "Zahlung bestätigen",
  "c-exchange-steps-sending-crypto": "Krypto senden",
  "c-exchange-steps-sending-fiat": "Fiat senden",
  "c-exchange-steps-request-completed": "Anfrage abgeschlossen",
};
