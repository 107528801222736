export default {
  "c-footer-menu-trade": "P2P торговля",
  "c-footer-menu-news": "новости",
  "c-footer-menu-blog": "блог",
  "c-footer-menu-faq": "FAQ",
  "c-footer-menu-beginnersGuide": "руководство для начинающих",
  "c-footer-menu-about": "о нас",
  "c-footer-menu-manageCookies": "управление куки",
  "c-footer-menu-adminPanel": "Панель администратора",

  "c-footer-mobilemenu-home": "Главная",
  "c-footer-mobilemenu-trade": "Торговля",
  "c-footer-mobilemenu-orders": "Заказы",
  "c-footer-mobilemenu-wallet": "Кошелек",
};
