export default {
  "p-trade-my-offers-title":
    "My Offers | Manage Your Crypto Offers on Solid Swap",
  "p-trade-my-offers-description":
    "View and manage your cryptocurrency offers on Solid Swap. Ensure optimal trading strategies with our user-friendly interface",
  "p-trade-my-offers-keywords":
    "manage crypto offers, cryptocurrency offers, trading offers, digital asset offers",
  "p-trade-my-offers-delete-confirm":
    "Are you sure you want to delete this offer?",
  "p-trade-my-offers-warning": "Warning",
  "p-trade-my-offers-ok": "OK",
  "p-trade-my-offers-cancel": "Cancel",
  "p-trade-my-offers-post-new-offer": "Post new offer",
  "p-trade-my-offers-no-active-offers": "No active offers found",
  "p-trade-my-offers-your-selling-offers": "Your selling offers",
  "p-trade-my-offers-your-buying-offers": "Your buying offers",
};
