export default {
  "c-exchange-listItem-id": "ID",
  "c-exchange-listItem-fiat": "Fiat",
  "c-exchange-listItem-crypto": "Crypto",
  "c-exchange-listItem-go-to-details": "Go to details",
  "c-exchange-listItem-summary": "Summary",
  "c-exchange-listItem-exchanging": "Exchanging",
  "c-exchange-listItem-for": "for",
  "c-exchange-listItem-order-created": "Order created",
  "c-exchange-listItem-status": "Status",
  "c-exchange-listItem-verifying-card": "Verifying user's card",
  "c-exchange-listItem-moderator-verifying": "Moderator is verifying data",
  "c-exchange-listItem-waiting-alm": "Waiting for ALM results",
  "c-exchange-listItem-preparing-crypto": "Preparing platform address",
  "c-exchange-listItem-preparing-fiat": "Preparing fiat requisites",
  "c-exchange-listItem-sending-crypto": "Sending crypto",
  "c-exchange-listItem-sending-fiat": "Sending fiat",
  "c-exchange-listItem-confirming-fiat": "Confirming payment",
  "c-exchange-listItem-waiting-payment": "Waiting for user's payment",
  "c-exchange-listItem-completed": "Completed",
  "c-exchange-listItem-failed": "Failed",
  "c-exchange-listItem-cancelled": "Cancelled",
};
