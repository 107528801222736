export default {
  "s-useNotifications-order": "Pedido n.º {orderId}",
  "s-useNotifications-system-message": "Mensaje del sistema",
  "s-useNotifications-new-order-created": "¡Nuevo pedido creado!",
  "s-useNotifications-order-status-changed":
    'El estado del pedido cambió a "{status}"',
  "s-useNotifications-order-created": "Pedido creado",
  "s-useNotifications-order-deleted": "Pedido eliminado",
  "s-useNotifications-moderator-joined": "Moderador unido",
  "s-useNotifications-attached-file": "[archivo adjunto]",
};
