export default {
  "c-payment-option-close-dialog-confirm":
    "Sind Sie sicher, dass Sie den Zahlungsoptionen-Dialog schließen möchten?",
  "c-payment-option-save-error":
    "Ein Fehler ist beim Speichern der Zahlungsoption aufgetreten",
  "c-payment-option-edit": "Zahlungsoption bearbeiten",
  "c-payment-option-new": "Neue Zahlungsoption",
  "c-payment-option-show-currency-methods":
    "Es werden nur Zahlungsmethoden mit Währung: {currency} angezeigt",
  "c-payment-option-show-specified-options":
    "Es werden nur die im Angebot angegebenen Zahlungsoptionen angezeigt",
  "c-payment-option-editing-method": "Bearbeitung der Zahlungsmethode für:",
  "c-payment-option-select-method":
    "Wählen Sie die Methode aus, die Sie hinzufügen möchten:",
  "c-payment-option-select-method-placeholder": "Zahlungsmethode auswählen...",
  "c-payment-option-description": "Beschreibung des Zahlungstyps:",
  "c-payment-option-description-info":
    "Bitte geben Sie eine Beschreibung Ihres Zahlungstyps an. Diese sollte Ihre Kontonummer, Kartennummer oder andere relevante Informationen enthalten, die den Käufern helfen, zu erkennen, wohin sie ihre Mittel überweisen sollen, wenn sie Kryptowährung bei Ihnen kaufen.",
  "c-payment-option-description-placeholder": "Beschreibung eingeben...",
  "c-payment-option-save": "Speichern",
  "c-payment-option-validate-payment-option":
    "Bitte wählen Sie eine Zahlungsoption",
  "c-payment-option-validate-description":
    "Bitte fügen Sie eine Beschreibung hinzu",
};
