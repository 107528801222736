export default {
  "c-header-menu-dark": "dark",
  "c-header-menu-light": "light",
  "c-header-menu-trade": "P2P trade",
  "c-header-menu-news": "News",
  "c-header-menu-support": "Support",
  "c-header-menu-chat": "Support chat",
  "c-header-menu-blog": "Blog",
  "c-header-menu-faq": "FAQ",
  "c-header-menu-beginnersGuide": "Beginners guide",
  "c-header-menu-help": "Help",
  "c-header-menu-about": "About",
  "c-header-menu-menu": "Menu",
  "c-header-menu-admin": "admin",
  "c-header-menu-moder": "moder",
  "c-header-menu-profile": "My profile",
  "c-header-menu-orders": "Orders",
  "c-header-menu-paymentOptions": "Payment options",
  "c-header-menu-wallet": "Wallet",
  "c-header-menu-settings": "Settings",
  "c-header-menu-logout": "log out",
  "c-header-menu-login": "Login",
  "c-header-menu-registration": "Registration",
  "c-header-menu-notifications": "Notifications",
};
