export default {
  "c-offers-editform-closeConfirmation": "确定要关闭此对话框吗？",
  "c-offers-editform-percentError": "百分比 % 值应在 -99 和 99 之间",
  "c-offers-editform-createError": "创建报价时出错",
  "c-offers-editform-createSuccess": "报价创建成功！",
  "c-offers-editform-updateSuccess": "报价更新成功！",
  "c-offers-editform-typeRequired": "请选择订单类型",
  "c-offers-editform-currencyRequired": "请选择货币",
  "c-offers-editform-priceTypeRequired": "请选择价格类型",
  "c-offers-editform-priceValRequired": "请输入价格值",
  "c-offers-editform-paymentOptionsRequired": "请选择支付选项",
  "c-offers-editform-minLimitRequired": "请输入最小金额",
  "c-offers-editform-minLimitError": "最小金额应大于 {minAmount}",
  "c-offers-editform-maxLimitRequired": "请输入最大金额",
  "c-offers-editform-maxLimitError": "最大金额不应低于最小限制",
  "c-offers-editform-offerAmountRequired": "请输入报价金额",
  "c-offers-editform-termsOfUseRequired": "请输入使用条款",
  "c-offers-editform-editTitle": "编辑您的报价",
  "c-offers-editform-addTitle": "添加新报价",
  "c-offers-editform-offerType": "报价类型",
  "c-offers-editform-sell": "出售",
  "c-offers-editform-buy": "购买",
  "c-offers-editform-selling": "销售",
  "c-offers-editform-buying": "购买",
  "c-offers-editform-mainCurrency": "主要货币",
  "c-offers-editform-selectCurrency": "选择货币",
  "c-offers-editform-forCurrency": "...为",
  "c-offers-editform-currentRate":
    "当前汇率：1 {currencyIn} = {rate} {currencyOut}",
  "c-offers-editform-price": "价格",
  "c-offers-editform-priceType": "价格类型",
  "c-offers-editform-selectPriceType": "选择价格类型",
  "c-offers-editform-percentValue": "百分比值",
  "c-offers-editform-relativeValue": "相对值",
  "c-offers-editform-fixedValue": "固定值",
  "c-offers-editform-priceTypesExplained": "价格类型说明：",
  "c-offers-editform-percent": "百分比",
  "c-offers-editform-percentExplanation":
    "价格基于当前汇率的百分比差异进行调整。如果用户输入百分比，最终价格将通过将此百分比应用于当前汇率进行计算。",
  "c-offers-editform-relative": "相对",
  "c-offers-editform-relativeExplanation":
    "价格通过添加或减去当前汇率的具体数字进行调整。用户输入这个数字，直接修改基价，使最终价格高于或低于这个确切金额。",
  "c-offers-editform-fixed": "固定",
  "c-offers-editform-fixedExplanation":
    "这是一个直接的价格设定，用户直接设置价格。用户输入的价格将是最终价格，不会根据当前汇率或百分比进行调整。",
  "c-offers-editform-pricePercent": "价格百分比",
  "c-offers-editform-priceRelative": "价格相对",
  "c-offers-editform-priceFixed": "价格固定",
  "c-offers-editform-enterPriceValue": "输入价格值",
  "c-offers-editform-rate":
    "根据选择的类型和价格字段，您的汇率是：1 {currencyIn} = {rate} {currencyOut}",
  "c-offers-editform-paymentOptions": "支付选项",
  "c-offers-editform-selectPaymentOptions": "选择支付选项",
  "c-offers-editform-noOptions": "没有可用选项",
  "c-offers-editform-all": "全部",
  "c-offers-editform-addOption": "添加支付选项",
  "c-offers-editform-minLimit": "最小限制",
  "c-offers-editform-enterMinAmount": "最小金额",
  "c-offers-editform-maxLimit": "最大限制",
  "c-offers-editform-enterMaxAmount": "最大金额",
  "c-offers-editform-orderLimitsExplanation": "订单限制说明",
  "c-offers-editform-orderLimitsExplanationTitle": "订单限制说明",
  "c-offers-editform-orderLimitsExplanationText":
    "最小限制和最大限制规定了您可以创建订单的范围。您不能下达低于最小限制或高于最大限制的买卖订单。",
  "c-offers-editform-orderLimitsExplanationUSD":
    "此外，我们的平台强制执行所有报价的最低限制，相当于 {MIN_OFFER_AMOUNT_USD} 美元，这大约是 {usdLimit} {currencyOut}",
  "c-offers-editform-amountToTrade": "交易金额",
  "c-offers-editform-offerAmount": "报价金额",
  "c-offers-editform-offerAmount-unlimited": "无限",
  "c-offers-editform-enterOfferAmount": "最大金额",
  "c-offers-editform-currentBalance": "我的当前余额：{balance} {currencyIn}",
  "c-offers-editform-offerAmountExplanation": "报价金额说明",
  "c-offers-editform-offerAmountExplanationTitle": "报价金额说明",
  "c-offers-editform-offerAmountExplanationText1":
    "报价金额是您愿意交易的加密货币总量。",
  "c-offers-editform-offerAmountExplanationText2":
    "对于卖单，这是您想要出售的加密货币数量。例如：如果您的余额中有 1000 USDT，并且只想交易一半，可以将报价金额设置为 500 USDT。这确保您不会出售超过 500 USDT 的金额。请注意，所有资金都留在您的账户中，取消报价会将它们返回到您的余额中。",
  "c-offers-editform-offerAmountExplanationText3":
    "对于买单，此金额表示您想要购买的加密货币数量。如果您将报价金额设置为 1000 USDT，您将正好收到这个金额。",
  "c-offers-editform-offerAmountExplanationText4":
    "此外，我们的平台收取佣金，由卖家支付。因此，如果您创建的是卖单，佣金由您承担。如果您创建的是买单，佣金将由创建订单的对方承担。",
  "c-offers-editform-termsOfUse": "使用条款",
  "c-offers-editform-termsOfUseText":
    "请在下方填写您的“使用条款”。此部分应概述您愿意交易的一般条款和条件。不需要在这里提供您的银行信息或任何其他敏感信息。",
  "c-offers-editform-termsOfUsePlaceholder": "写一些描述...",
  "c-offers-editform-cancel": "取消",
  "c-offers-editform-confirm": "确认",
};
