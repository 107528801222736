export default {
  "c-exchange-steps-verifying-card": "验证用户卡",
  "c-exchange-steps-waiting-alm": "等待ALM结果",
  "c-exchange-steps-preparing-crypto": "准备平台地址",
  "c-exchange-steps-moderator-verifying": "管理员正在验证数据",
  "c-exchange-steps-preparing-fiat": "准备法币信息",
  "c-exchange-steps-waiting-payment": "等待用户付款",
  "c-exchange-steps-confirming-payment": "确认付款",
  "c-exchange-steps-sending-crypto": "发送加密货币",
  "c-exchange-steps-sending-fiat": "发送法币",
  "c-exchange-steps-request-completed": "请求已完成",
};
