export default {
  "c-header-menu-dark": "մութ",
  "c-header-menu-light": "լուսավոր",
  "c-header-menu-trade": "P2P փոխանակում",
  "c-header-menu-news": "Նորություններ",
  "c-header-menu-support": "Աջակցություն",
  "c-header-menu-chat": "Աջակցության զրույց",
  "c-header-menu-blog": "Բլոգ",
  "c-header-menu-faq": "ՀՏՀ",
  "c-header-menu-beginnersGuide": "Սկսնակների ուղեցույց",
  "c-header-menu-help": "Օգնություն",
  "c-header-menu-about": "Մեր մասին",
  "c-header-menu-menu": "Մենյու",
  "c-header-menu-admin": "ադմին",
  "c-header-menu-moder": "մոդեր",
  "c-header-menu-profile": "Իմ պրոֆիլը",
  "c-header-menu-orders": "Պատվերներ",
  "c-header-menu-paymentOptions": "Վճարման տարբերակներ",
  "c-header-menu-wallet": "Դրամապանակ",
  "c-header-menu-settings": "Կարգավորումներ",
  "c-header-menu-logout": "ելք",
  "c-header-menu-login": "Մուտք",
  "c-header-menu-registration": "Գրանցում",
  "c-header-menu-notifications": "Ծանուցումներ",
};
