export default {
  "p-kyc-aml-policy-title":
    "Política de Antilavado de Dinero (Anti-Money Laundering Policy).",
  "p-kyc-warning":
    "SolidSwap advierte a los usuarios contra los intentos de utilizar el servicio para legalizar fondos obtenidos de manera criminal, financiar el terrorismo, realizar acciones ilegales de cualquier tipo, así como utilizar el servicio para comprar bienes y servicios prohibidos.",
  "p-kyc-illegal-funds-description":
    "La legalización de fondos obtenidos por medios criminales es el encubrimiento de una fuente ilegal de fondos al transferirlos a fondos o inversiones que parecen legítimos.",
  "p-kyc-general-provisions-title": "Disposiciones Generales",
  "p-kyc-prevent-illegal-transactions":
    "Para prevenir transacciones ilegales, el Servicio establece ciertos requisitos para todas las Solicitudes creadas por el Usuario:",
  "p-kyc-sender-recipient-same":
    "El remitente y el destinatario del Pago en la Solicitud deben ser la misma persona. Las transferencias a favor de terceros están estrictamente prohibidas.",
  "p-kyc-contact-info-requirements":
    "Toda la información de contacto ingresada por el Usuario en la Solicitud, así como otros datos personales transmitidos por el Usuario al Servicio, deben estar actualizados y ser completamente fiables.",
  "p-kyc-no-anonymous-connections":
    "Está estrictamente prohibido que un Usuario cree Solicitudes utilizando servidores proxy anónimos o cualquier otra conexión anónima a Internet.",
  "p-kyc-policy-description":
    'Esta Política de Antilavado de Dinero (en adelante, "Política AML") describe los procedimientos y mecanismos utilizados por SolidSwap para combatir el lavado de dinero.',
  "p-kyc-no-business-with-criminals":
    "no entablar relaciones comerciales con conocidos criminales y/o terroristas;",
  "p-kyc-no-criminal-transactions":
    "no procesar transacciones que sean el resultado de actividades criminales y/o terroristas conocidas;",
  "p-kyc-no-facilitating-criminal-activity":
    "no facilitar ninguna transacción relacionada con actividades criminales y/o terroristas conocidas;",
  "p-kyc-right-to-restrict-services":
    "tiene derecho a restringir o prohibir el uso de todos o parte de los servicios para personas sujetas a restricciones...",
  "p-kyc-verification-procedures-title": "Procedimientos de Verificación",
  "p-kyc-kyc-policy-description":
    'El servicio SolidSwap establece sus propios procedimientos de verificación dentro del marco de los estándares de antilavado de dinero, la política de "Conozca a su cliente" (KYC).',
  "p-kyc-verification-steps":
    "Los usuarios del Servicio SolidSwap pasan por el procedimiento de verificación (proporcionan un documento de identificación oficial: pasaporte o tarjeta de identificación).",
  "p-kyc-request-second-document":
    "El Servicio SolidSwap también puede solicitar un segundo documento para identificar al Usuario...",
  "p-kyc-verification-authenticity":
    "El Servicio SolidSwap verifica la autenticidad de los documentos e información proporcionados por los usuarios...",
  "p-kyc-request-updated-documents":
    "Si la información de identificación del Usuario ha cambiado o su actividad ha parecido sospechosa...",
  "p-kyc-aml-compliance-title":
    "Responsable del Cumplimiento de la Política AML",
  "p-kyc-aml-compliance-description":
    "La persona responsable del cumplimiento de la Política AML es un empleado de SolidSwap...",
  "p-kyc-collect-user-info":
    "Recopilar información de identificación del usuario y transferirla al agente responsable del procesamiento de datos personales...",
  "p-kyc-create-update-policies":
    "Creación y actualización de políticas y procedimientos internos...",
  "p-kyc-monitor-transactions":
    "Monitoreo de transacciones y análisis de desviaciones...",
  "p-kyc-record-management":
    "Implementación de un sistema de gestión de registros...",
  "p-kyc-regular-risk-update":
    "Actualización regular de la evaluación de riesgos.",
  "p-kyc-interaction-with-authorities":
    "La persona responsable del cumplimiento de la Política AML tiene derecho a interactuar con las agencias de cumplimiento de la ley...",
  "p-kyc-transaction-monitoring-title": "Monitoreo de Transacciones",
  "p-kyc-monitoring-description":
    "El monitoreo de las transacciones de los usuarios y el análisis de los datos recibidos...",
  "p-kyc-report-suspicious-transactions":
    "Informar de transacciones sospechosas a las agencias de cumplimiento de la ley relevantes...",
  "p-kyc-request-additional-info":
    "Solicitar al usuario información y documentos adicionales...",
  "p-kyc-suspend-terminate-account":
    "Suspender o cancelar la cuenta del usuario...",
  "p-kyc-freeze-assets":
    "Suspender el intercambio y congelar los activos hasta que se aclaren las circunstancias...",
  "p-kyc-return-funds":
    "Devolver los fondos al usuario cancelando el procedimiento de intercambio según el acuerdo de usuario...",
  "p-kyc-risk-assessment": "Evaluación de riesgos.",
  "p-kyc-additional-monitoring":
    "El Oficial de la Política AML monitorea diariamente las transacciones del usuario para determinar si...",
};
