export default {
  "p-trade-my-offers-title":
    "Իմ Առաջարկները | Կառավարեք Ձեր Կրիպտո Առաջարկները Solid Swap-ում",
  "p-trade-my-offers-description":
    "Դիտեք և կառավարեք ձեր կրիպտոարժույթի առաջարկները Solid Swap-ում: Հանգիստ պլանավորեք ձեր առևտրային ռազմավարությունները մեր օգտագործողի հարմար ինտերֆեյսով",
  "p-trade-my-offers-keywords":
    "կառավարեք կրիպտո առաջարկները, կրիպտոարժույթի առաջարկներ, առևտրի առաջարկներ, թվային ակտիվների առաջարկներ",
  "p-trade-my-offers-delete-confirm":
    "Վստահ եք, որ ցանկանում եք ջնջել այս առաջարկը?",
  "p-trade-my-offers-warning": "Զգուշացում",
  "p-trade-my-offers-ok": "Լավ",
  "p-trade-my-offers-cancel": "Չեղարկել",
  "p-trade-my-offers-post-new-offer": "Գրառել նոր առաջարկ",
  "p-trade-my-offers-no-active-offers": "Ակտիվ առաջարկներ չկան",
  "p-trade-my-offers-your-selling-offers": "Ձեր վաճառողական առաջարկները",
  "p-trade-my-offers-your-buying-offers": "Ձեր գնողական առաջարկները",
};
