export default {
  "c-orderCreate-closeOrderConfirm":
    "Sind Sie sicher, dass Sie die Bestellungserstellung schließen möchten?",
  "c-orderCreate-close": "Schließen",
  "c-orderCreate-closeCancel": "Abbrechen",
  "c-orderCreate-sendAmount-required": "Bitte geben Sie den Betrag ein",
  "c-orderCreate-receiveAmount-required": "Bitte geben Sie den Betrag ein",
  "c-orderCreate-orderPayment-required":
    "Bitte wählen Sie eine Zahlungsmethode aus",
  "c-orderCreate-fiatLimits":
    "Der Betrag sollte zwischen {min} und {max} liegen",
  "c-orderCreate-cryptoLimits": "Der Betrag sollte weniger als {limit} sein",
  "c-orderCreate-errorTitle": "Fehler",
  "c-orderCreate-errorCreatingOrder": "Fehler beim Erstellen der Bestellung",
  "c-orderCreate-orderCreated": 'Bestell-ID: "{id}" erstellt',
  "c-orderCreate-ratesChangedTitle": "Kurse haben sich geändert",
  "c-orderCreate-ratesChangedAlert":
    "Die Kurse für den Kauf von Kryptowährung haben sich während der Erstellung Ihrer Bestellung geändert.",
  "c-orderCreate-ratesChangedDesc":
    "Dies könnte auf Marktschwankungen oder eine Aktualisierung des benutzerdefinierten Kurses durch den Angebotsgeber zurückzuführen sein. Bitte überprüfen Sie die neuen Kurse, bevor Sie mit Ihrem Kauf fortfahren, um sicherzustellen, dass Sie mit den aktualisierten Bedingungen einverstanden sind.",
  "c-orderCreate-oldPrice": "Alter Preis",
  "c-orderCreate-newPrice": "Neuer Preis",
  "c-orderCreate-returnToOrder": "Zurück zur Bestellung",
  "c-orderCreate-proceedWithNewPrice": "Mit neuem Preis fortfahren",
  "c-orderCreate-creatingOrderTitle":
    "Bestellung wird erstellt für {type} {currency}",
  "c-orderCreate-buying": "Kauf",
  "c-orderCreate-selling": "Verkauf",
  "c-orderCreate-paymentTimeLimit": "Zahlungsfrist",
  "c-orderCreate-30minutes": "30 Minuten",
  "c-orderCreate-avgPaymentTime": "Durchschnittliche Zahlungszeit",
  "c-orderCreate-minutes": "Minuten",
  "c-orderCreate-available": "Verfügbar",
  "c-orderCreate-paymentOptions": "Zahlungsoptionen:",
  "c-orderCreate-traderInfo": "Händlerinformationen",
  "c-orderCreate-noDescriptionSpecified":
    "Keine Beschreibung vom Händler angegeben. Sie erhalten weitere Informationen, nachdem die Bestellung erstellt wurde.",
  "c-orderCreate-priceLabel": "Preis",
  "c-orderCreate-priceChangedTooltip":
    "Preis geändert aufgrund der offiziellen Handelsraten oder Änderungen durch den Angebotsersteller",
  "c-orderCreate-priceChanged": "Preis geändert!",
  "c-orderCreate-platformFee": "Plattformgebühr",
  "c-orderCreate-paidBySeller": "vom Verkäufer bezahlt",
  "c-orderCreate-iWantTo": "Ich möchte {type}",
  "c-orderCreate-pay": "bezahlen",
  "c-orderCreate-sell": "verkaufen",
  "c-orderCreate-enterAmount": "Betrag eingeben",
  "c-orderCreate-limits": "Limits",
  "c-orderCreate-fee": "Gebühr",
  "c-orderCreate-myBalance": "Mein Saldo",
  "c-orderCreate-sellAll": "Alles verkaufen",
  "c-orderCreate-iWillReceive": "Ich werde erhalten",
  "c-orderCreate-paymentMethod": "Zahlungsmethode",
  "c-orderCreate-noPaymentOptions":
    "Keine Zahlungsoptionen für dieses Angebot verfügbar",
  "c-orderCreate-alsoAvailable": "Ebenfalls verfügbar für dieses Angebot",
  "c-orderCreate-addPaymentOption": "Zahlungsoption hinzufügen",
  "c-orderCreate-cancel": "Abbrechen",
  "c-orderCreate-buy": "KAUFEN",
};
