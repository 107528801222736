export default {
  "p-confirm-email-title": "Confirmación de correo electrónico",
  "p-confirm-email-wait-message":
    "Espera por favor, estamos tratando de confirmar tu dirección de correo electrónico...",
  "p-confirm-email-success-message":
    "¡Tu dirección de correo electrónico ha sido confirmada con éxito!",
  "p-confirm-email-success-elmessage":
    "¡Tu dirección de correo electrónico ha sido confirmada con éxito! Serás redirigido a la página de inicio de sesión en 30 segundos.",
  "p-confirm-email-info-elmessage":
    "Ahora puedes iniciar sesión en tu cuenta como: {email}",
  "p-confirm-email-error-message":
    "Algo salió mal, por favor intenta nuevamente más tarde.",
  "p-confirm-email-proceed-button": "Proceder al inicio de sesión",
  "p-confirm-email-welcome-title": "¡Bienvenido a una nueva era de comercio!",
  "p-confirm-email-welcome-message":
    "Conéctate de manera segura a un mundo de activos digitales. Comienza a comerciar con confianza y comodidad en nuestra plataforma P2P de confianza. Tu puerta de entrada a transacciones criptográficas sin problemas te espera.",
};
