export default {
  "p-faq-head-title":
    "FAQ | Preguntas comunes sobre el intercambio de criptomonedas Solid Swap",
  "p-faq-head-description":
    "Encuentra respuestas a las preguntas más frecuentes sobre Solid Swap. Aprende sobre nuestra plataforma, medidas de seguridad y cómo comerciar criptomonedas",
  "p-faq-head-keywords":
    "FAQ, preguntas sobre intercambio de criptomonedas, FAQs de Solid Swap, ayuda para comercio de cripto",
  "p-faq-title": "FAQ sobre Solid Swap",
  "p-faq-q1-title": "¿Qué es Solid Swap?",
  "p-faq-q1-answer1":
    "Solid Swap es una plataforma para el intercambio rápido de criptomonedas y monedas fiduciarias, actualmente proporcionando servicios peer-to-peer (P2P).",
  "p-faq-q1-answer2":
    "Planeamos introducir la capacidad de intercambio instantáneo de criptomonedas en el futuro cercano y seguir ampliando la gama de servicios ofrecidos por nuestra plataforma.",
  "p-faq-q2-title": "¿Por qué debería confiar en Solid Swap?",
  "p-faq-q2-answer1":
    "Puedes confiar en Solid Swap porque estamos registrados oficialmente en Armenia, lo que nos permite ofrecer condiciones convenientes a nuestros usuarios sin necesidad de verificación de identidad y monitoreo de transacciones ALM.",
  "p-faq-q2-answer2":
    "Esto nos libera del papel de un banco tradicional, que podría congelar tus fondos.",
  "p-faq-q2-answer3":
    "Siempre puedes revisar los comentarios de nuestros clientes en esta página.",
  "p-faq-q3-title": "¿Cuál es la comisión de negociación en Solid Swap?",
  "p-faq-q3-answer1":
    "Nos esforzamos por la transparencia en todos los aspectos de nuestras operaciones, incluidas las tarifas.",
  "p-faq-q3-answer2":
    "La comisión de negociación es solo del 0.2% del monto de la transacción, que es pagada por el vendedor.",
  "p-faq-q3-answer3":
    "Esto te permite planificar fácilmente tus transacciones, sabiendo que no habrá tarifas inesperadas.",
  "p-faq-q4-title": "¿Qué monedas admite el intercambio para el comercio?",
  "p-faq-q4-answer":
    "Nuestro intercambio admite criptomonedas como BTC, ETH, USDT y XMR, y está trabajando en agregar más monedas en el futuro.",
  "p-faq-q5-title":
    "¿Cuáles son las cantidades mínimas para depósitos y retiros en Solid Swap, y hay alguna restricción en los tamaños de los pedidos?",
  "p-faq-q5-answer1":
    "El monto mínimo para depósitos y retiros en la plataforma Solid Swap es de 50 dólares.",
  "p-faq-q5-answer2":
    "Si se recibe una cantidad menor a esta, no se acreditará en la cuenta del usuario.",
  "p-faq-q5-answer3":
    "En cuanto a los pedidos, la plataforma no establece límites en sus tamaños, estos son determinados por el comprador o el vendedor.",
  "p-faq-q6-title": "¿Cómo se determina el costo de retirar fondos?",
  "p-faq-q6-answer1":
    "El costo de retirar dinero de una cuenta cambia dinámicamente y depende del costo actual de las transacciones en la red.",
  "p-faq-q6-answer2":
    "Siempre trabajamos para ofrecerte las condiciones más favorables y minimizar tus gastos.",
  "p-faq-q7-title":
    "¿Qué medidas de seguridad se toman después de cambiar la contraseña o la configuración de la autenticación de dos factores?",
  "p-faq-q7-answer1": "Tu seguridad es nuestra prioridad.",
  "p-faq-q7-answer2":
    "Por lo tanto, después de cambiar tu contraseña o la configuración de la autenticación de dos factores, implementamos un período de bloqueo de 24 horas para los retiros.",
  "p-faq-q7-answer3": "Esto evita el acceso no autorizado a tus fondos.",
  "p-faq-q8-title":
    "¿Cuándo puedo abrir una disputa si hay dificultades con el vendedor?",
  "p-faq-q8-answer1":
    "Si no puedes llegar a un acuerdo con el vendedor, puedes abrir una disputa 30 minutos después de que surja el problema.",
  "p-faq-q8-answer2":
    "Un botón para esto aparecerá automáticamente en tu cuenta personal, permitiéndote resolver rápidamente cualquier problema.",
  "p-faq-q9-title": "¿Cuánto tiempo llevará revisar una disputa?",
  "p-faq-q9-answer1":
    "Nos esforzamos por revisar cada disputa lo más rápido posible.",
  "p-faq-q9-answer2":
    "El proceso puede tardar hasta 24 horas, tan pronto como se proporcione toda la información solicitada.",
  "p-faq-q9-answer3":
    "Nuestro objetivo es proporcionarte una resolución justa y rápida.",
  "p-faq-q1-answer":
    "Solid Swap es una plataforma para el intercambio rápido de criptomonedas y monedas fiduciarias, también ofrecemos un servicio de intercambio peer-to-peer (P2P).",
  "p-faq-q2-answer":
    "Puedes confiar en Solid Swap porque estamos registrados oficialmente en Armenia, lo que nos permite ofrecer condiciones convenientes a nuestros usuarios sin la necesidad de verificación de identidad y monitoreo de transacciones ALM al usar nuestro servicio P2P. También ofrecemos un intercambio rápido, donde todas las transacciones han sido verificadas a través de ALM, y SolidSwap garantiza la limpieza de los fondos que recibirás. Esto nos libera del papel de un banco tradicional, que podría congelar tus fondos. Elige libremente el servicio que mejor te convenga.",
  "p-faq-ex-q1-title":
    "¿Cuáles son las restricciones de tamaño de pedido del servicio SolidSwap Exchange?",
  "p-faq-ex-q1-answer":
    "SolidSwap Exchange cambia dinámicamente los tamaños mínimos y máximos de pedido, consulta la página de Intercambio para ver los límites actuales.",
  "p-faq-ex-q2-title": "¿Cuál es la comisión de intercambio en Solid Swap?",
  "p-faq-ex-q2-answer":
    "No hay tarifas adicionales, todas están incluidas en el monto mostrado durante la creación del intercambio.",
};
