export default {
  "p-beginners-guide-head-title":
    "Սկսնակների ուղեցույց | Սկսեք կրիպտոարժույթի առևտուրը Solid Swap-ի հետ",
  "p-beginners-guide-head-description":
    "Նոր եք կրիպտոարժույթի առևտրի մեջ? Մեր սկսնակների ուղեցույցը կօգնի ձեզ սկսել հեշտությամբ հետևելի քայլերով և արժեքավոր խորհուրդներով թվային ակտիվների առևտրի վերաբերյալ",
  "p-beginners-guide-head-keywords":
    "սկսնակների ուղեցույց, կրիպտոարժույթի առևտուր, սկսելը, կրիպտո առևտրի խորհուրդներ, թվային ակտիվներ",
  "p-beginners-guide-account-preparation-title":
    "Հաշվի պատրաստում P2P առևտրի համար",
  "p-beginners-guide-register-title": "Գրանցվել Solid Swap-ում",
  "p-beginners-guide-two-factor-title": "Ակտիվացնել երկուսական նույնականացում",
  "p-beginners-guide-payment-method-title": "Ավելացնել վճարման մեթոդ",
  "p-beginners-guide-finding-offers-title-1": "Առաջարկների գտնելը և ստեղծելը",
  "p-beginners-guide-select-currency-title-1": "Ընտրել արժույթի զույգ",
  "p-beginners-guide-search-offer-title-1": "Գտնել համապատասխան առաջարկ",
  "p-beginners-guide-create-offer-title-1": "Ստեղծել փոխանակման առաջարկ",
  "p-beginners-guide-commissions-title-1": "Հանձնաժողովներ",
  "p-beginners-guide-completing-transaction-title-1": "Ավարտել գործարքը",
  "p-beginners-guide-intro-1":
    "Բարև! Այս ուղեցույցը կօգնի ձեզ կատարել ձեր առաջին փոխանակումը Solid Swap-ում: Այն կնկարագրի բոլոր հիմնական քայլերը, որոնց հետևելով դուք կարող եք հեշտությամբ և անվտանգ ավարտել գործարքը: Մենք այս գործընթացը դարձրել ենք հնարավորինս հասկանալի և հասանելի, այնպես որ մի անհանգստացեք, եթե նոր եք կրիպտոարժույթների աշխարհում — մենք ձեզ հետ կլինենք յուրաքանչյուր քայլի ընթացքում:",
  "p-beginners-guide-intro-2":
    "Սկսենք հիմունքներից: ինչ է Solid Swap-ը և ինչու է դա իդեալական ընտրություն կրիպտոարժույթի փոխանակման համար: Solid Swap-ը հարթակ է կրիպտոարժույթների և ֆիատ փողերի արագ փոխանակման համար, որն այժմ առաջարկում է P2P ծառայություններ: Մենք ապահովում ենք անվտանգ և շահավետ փոխանակում մրցակցային փոխարժեքներով, ցածր միջնորդավճարներով և բարձր անվտանգության մակարդակով:",
  "p-beginners-guide-steps-title":
    "Solid Swap-ում հաջող առևտրի հիմնական քայլերը:",
  "p-beginners-guide-follow-steps":
    "Հետևելով մեր հրահանգներին, դուք կարող եք արագ, անվտանգ և վստահ կատարել ձեր առաջին գործարքը Solid Swap-ում:",
  "p-beginners-guide-step1-title": "Քայլ 1: Գրանցում",
  "p-beginners-guide-register-instructions": "Գրանցվել Solid Swap-ում:",
  "p-beginners-guide-register-step1":
    "Սեղմեք “Գրանցում” կոճակը էկրանի վերևի աջ անկյունում:",
  "p-beginners-guide-register-step2":
    "Լրացրեք պահանջվող տեղեկատվությունը և սեղմեք “Շարունակել” կոճակը:",
  "p-beginners-guide-register-step3":
    "Ստուգեք ձեր գրանցած էլ. փոստը և հետևեք էլ. փոստում ստացված հղմանը՝ հաստատելու ձեր էլ. փոստի հասցեն:",
  "p-beginners-guide-register-step4": "Մուտք գործեք ձեր հաշիվ:",
  "p-beginners-guide-step2-title":
    "Քայլ 2: Ակտիվացնել երկուսական նույնականացում",
  "p-beginners-guide-two-factor-instructions":
    "Գործարքներ կատարելու համար դուք պետք է ակտիվացնեք երկուսական նույնականացում: Երկուսական նույնականացումը հատկապես կարևոր է ձեր ակտիվների և հաշվի պաշտպանության համար: Դրա համար:",
  "p-beginners-guide-two-factor-step1":
    "Սեղմեք ձեր պրոֆիլի պատկերակին էկրանի վերևի աջ անկյունում և անցեք կարգավորումներին՝ սեղմելով “Կարգավորումներ” կոճակը:",
  "p-beginners-guide-two-factor-step2":
    "Սեղմեք կարմիր “Ակտիվացնել 2FA” կոճակը:",
  "p-beginners-guide-two-factor-step3":
    "Սքանավորեք QR կոդը, որը ցուցադրվում է էկրանին՝ կապելով այն ձեր Solid Swap-ի հաշվին:",
  "p-beginners-guide-two-factor-note":
    "Եթե դուք չունեք երկուսական նույնականացման հավելված, մի անհանգստացեք: Դուք կարող եք ներբեռնել հետևյալ հավելվածներից մեկը ձեր սմարթֆոնում:",
  "p-beginners-guide-two-factor-app1":
    "Google Authenticator (Android և iOS համար)",
  "p-beginners-guide-two-factor-app2": "Authy (Android և iOS համար)",
  "p-beginners-guide-two-factor-app3":
    "Microsoft Authenticator (Android և iOS համար)",
  "p-beginners-guide-faq":
    "2FA միացնելու ավելի մանրամասն տեղեկատվության և այլ հաճախակի տրվող հարցերի պատասխանների համար այցելեք մեր [ՀՏՀ](link to FAQ):",
  "p-beginners-guide-step3-title": "Քայլ 3: Ավելացնել վճարման մեթոդ",
  "p-beginners-guide-payment-method-instructions":
    "Ցանկացած փոխանակում կատարելու համար դուք պետք է ավելացնեք վճարման մեթոդ: Դրա համար:",
  "p-beginners-guide-payment-method-step1":
    "Ձեր հաշվի կարգավորումներում անցեք “Վճարման մեթոդներ” բաժին:",
  "p-beginners-guide-payment-method-step2":
    "Սեղմեք “+ Ավելացնել նոր վճարման մեթոդ” կոճակը:",
  "p-beginners-guide-payment-method-step3":
    "Ընտրեք հարմար վճարման մեթոդը ցանկից:",
  "p-beginners-guide-payment-method-step4":
    "“Նկարագրություն” դաշտում կարող եք նշել լրացուցիչ տեղեկատվություն: Սա կցուցադրվի, երբ դուք վաճառող եք: Օրինակ՝ բանկային քարտի կամ կրիպտո դրամապանակի համարը, որտեղ ընդունում եք արժույթը:",
  "p-beginners-guide-finding-offers-title": "Գտնել առաջարկներ և ընտրել վաճառող",
  "p-beginners-guide-select-currency-title": "Քայլ 1: Ընտրել արժույթի զույգ",
  "p-beginners-guide-select-currency-step1":
    "Գնացեք P2P առևտրի բաժին, որը գտնվում է էջի վերևի վահանակում:",
  "p-beginners-guide-select-currency-step2":
    "Ընտրեք գործողությունը, որը նախատեսում եք կատարել՝ գնել կամ վաճառել:",
  "p-beginners-guide-select-currency-step3":
    "Ընտրեք գործարքում օգտագործվող վճարման մեթոդը:",
  "p-beginners-guide-select-currency-step4":
    "Մուտքագրեք այն արժույթի քանակը, որը նախատեսում եք փոխանակել, տեսնելու համար բոլոր համապատասխան առաջարկները:",
  "p-beginners-guide-select-currency-note":
    "Արժույթի զույգը նույնպես կարող է ընտրվել կայքի գլխավոր էջում:",
  "p-beginners-guide-search-offer-title": "Քայլ 2: Գտնել համապատասխան առաջարկ",
  "p-beginners-guide-search-offer-description":
    "Ընտրեք ձեզ համար ամենահարմար փոխանակման առաջարկը:",
  "p-beginners-guide-search-offer-note":
    "Վաճառող ընտրելիս ուշադրություն դարձրեք հետևյալ ցուցանիշներին.",
  "p-beginners-guide-search-offer-seller-rating":
    "Վաճառողի վարկանիշ: Յուրաքանչյուր վաճառող ունի վարկանիշ, հիմնված նախորդ գնորդների կարծիքների վրա: Ընտրեք բարձր վարկանիշ ունեցող վաճառողներ ավելի մեծ վստահության համար:",
  "p-beginners-guide-search-offer-transactions":
    "Ավարտված գործարքների քանակը: Նկատեք վաճառողի կողմից հաջողությամբ ավարտված գործարքների քանակը: Բազմաթիվ գործարքներ ունեցող վաճառողներ սովորաբար ավելի վստահելի են:",
  "p-beginners-guide-search-offer-reviews":
    "Կարծիքներ: Կարդացեք այլ օգտատերերի կարծիքները վաճառողի մասին: Սա կօգնի ձեզ գնահատել նրանց համբավը և մատուցած ծառայությունների որակը: Դուք կարող եք դա անել վաճառողի պրոֆիլում:",
  "p-beginners-guide-search-offer-limits":
    "Գործարքի քանակի սահմանափակումներ: Ստուգեք վաճառողի կողմից փոխանակվող նվազագույն և առավելագույն գումարները: Համոզվեք, որ դրանք համապատասխանում են ձեր պահանջներին:",
  "p-beginners-guide-create-offer-title": "Քայլ 3: Ընտրել առաջարկ",
  "p-beginners-guide-create-offer-step1":
    "Երբ գտնեք համապատասխան առաջարկ, սեղմեք կանաչ “Գնել” կոճակը նրա կողքին՝ դիտելու մանրամասները:",
  "p-beginners-guide-create-offer-step2":
    "Դիտեք գործարքի պայմանները, ներառյալ փոխարժեքը, նվազագույն և առավելագույն գումարները և վճարման մեթոդները:",
  "p-beginners-guide-create-offer-step3":
    "Եթե համաձայն եք պայմաններին, կարող եք շարունակել բացել գործարքը: Դրա համար բացված պատուհանում լրացրեք դատարկ դաշտերը և ընտրեք վճարման մեթոդը: Սեղմեք “Գնել” կոճակը:",
  "p-beginners-guide-create-offer-step4":
    "Եթե ներկայացված առաջարկներից ոչ մեկը ձեզ չի համապատասխանում, կարող եք ստեղծել ձեր սեփականը: Դրա համար:",
  "p-beginners-guide-create-offer-note1":
    "Սեղմեք “+ Տեղադրել նոր առաջարկ” կոճակը:",
  "p-beginners-guide-create-offer-note2":
    "Լրացրեք դաշտերը պահանջվող տեղեկատվությամբ:",
  "p-beginners-guide-create-offer-note3":
    "Նկարագրությունում կարող եք նշել լրացուցիչ տեղեկատվություն գործարքի մասին:",
  "p-beginners-guide-create-offer-note4": "Սեղմեք “Հաստատել” կոճակը:",
  "p-beginners-guide-create-offer-note5":
    "Դրանից հետո դուք կլինեք “Իմ առաջարկները” բաժնում: Այստեղ դուք կգտնեք ձեր ստեղծած բոլոր առաջարկները: Որպեսզի առաջարկը տեսանելի լինի կայքի այլ օգտատերերի համար, փոխեք դրա կարգավիճակը: Դա կարող եք անել առաջարկի աջ կողմում գտնվող համապատասխան կոճակի վրա սեղմելով:",
  "p-beginners-guide-commissions-title": "Խնդրում ենք նկատի ունենալ!",
  "p-beginners-guide-commissions-description":
    "Մենք ձգտում ենք թափանցիկության մեր աշխատանքի բոլոր ասպեկտներում, ներառյալ հանձնաժողովները: Առևտրի հանձնաժողովը կազմում է միայն գործարքի գումարի 0.2%-ը, որը վճարում է վաճառողը: Սա թույլ է տալիս հեշտությամբ պլանավորել ձեր գործարքները՝ իմանալով, որ անսպասելի ծախսեր չեն լինի:",
  "p-beginners-guide-completing-transaction-title": "Ավարտել գործարքը",
  "p-beginners-guide-completing-transaction-description1":
    "Երբ գտնեք համապատասխան փոխանակման առաջարկ և սեղմեք “Գնել” կոճակը, դուք կգտնեք գործարքի էջում: Այստեղ ներկայացված է փոխանակման մասին ամբողջ տեղեկատվությունը: Խորհուրդ ենք տալիս ստուգել այն՝ համոզվելու համար, որ ամեն ինչ ճիշտ է և խուսափելու սխալ գործարքներից:",
  "p-beginners-guide-completing-transaction-description2":
    "Գործարքի էջում դուք նաև կգտնեք զրուցարան վաճառողի հետ: Այնտեղ կարող եք ճշտել փոխանակման պայմանները կամ ձեզ հետաքրքրող այլ տեղեկատվություն:",
  "p-beginners-guide-completing-transaction-description3":
    "Ակտիվ գործարքի պատուհանում՝",
  "p-beginners-guide-completing-transaction-step1":
    "Ստուգեք փոխանակման պայմաններն ու մանրամասները:",
  "p-beginners-guide-completing-transaction-step2":
    "Կատարեք փոխանցումը վաճառողի կողմից նշված վճարման մեթոդով:",
  "p-beginners-guide-completing-transaction-step3":
    "Հաստատեք փոխանցումը՝ սեղմելով “Վճարումը կատարված է” կոճակը:",
  "p-beginners-guide-completing-transaction-step4":
    "Սպասեք վաճառողի վճարման հաստատմանը և ձեր հաշվին փոխանցման:",
  "p-beginners-guide-completing-transaction-step5":
    "Թողեք կարծիք գործարքի մասին: կարծիքները օգնում են օգտագործողներին հասկանալ, թե ում հետ համագործակցել և ումից խուսափել: Մենք գնահատում ենք ձեր ջանքերը՝ ստեղծելու բարենպաստ մթնոլորտ մեր հարթակում:",
  "p-beginners-guide-active-transactions-note1":
    "Ձեր ակտիվ գործարքները կարող եք գտնել P2P առևտրի բաժնում՝ “պատվերներ” ներդիրում:",
  "p-beginners-guide-active-transactions-note2":
    "Միջադեպի դեպքում կարող եք բացել վեճ 30 րոպե անց վճարման փոխանցումը հաստատելուց հետո: Դրա համար կոճակը կհայտնվի գործարքի էջում ժամանակաչափի ավարտից հետո:",
  "p-beginners-guide-active-transactions-note3":
    "Եթե որոշել եք վաճառել արժույթ և ստեղծել առաջարկ, սպասեք, մինչև ինչ-որ մեկը արձագանքի: Երբ դա տեղի ունենա, դուք կստանաք ծանուցում:",
  "p-beginners-guide-active-transactions-note4":
    "Գնորդ գտնելուց հետո շարունակեք փոխանակումը:",
  "p-beginners-guide-active-transactions-note5":
    "Ստուգեք փոխանակման պայմաններն ու մանրամասները:",
  "p-beginners-guide-active-transactions-note6":
    "Ստուգեք, արդյոք գնորդը կատարել է վճարումը:",
  "p-beginners-guide-active-transactions-note7":
    "Հաստատեք գումարի ստացումը՝ սեղմելով “Վճարումը ստացված է” կոճակը:",
  "p-beginners-guide-active-transactions-note8":
    "Ուղարկեք համաձայնված գումարը գնորդի նշված ստացման մեթոդին:",
  "p-beginners-guide-ending-note1":
    "Ավարտված է! Դուք հաջողությամբ ավարտել եք ձեր առաջին գործարքը Solid Swap-ում: Հուսով ենք, որ այս ուղեցույցը ձեզ օգնեց անցնել բոլոր քայլերով առանց դժվարության:",
  "p-beginners-guide-ending-note2":
    "Միշտ հիշեք անվտանգության մասին կրիպտոարժույթների փոխանակման ժամանակ: Միշտ ստուգեք գործարքի պայմաններն ու վաճառողի համբավը, օգտագործեք երկուսական նույնականացում և զգույշ եղեք գումարներ փոխանցելիս: Եթե ունեք հարցեր կամ խնդիրներ, մեր աջակցության ծառայությունը միշտ պատրաստ է օգնել ձեզ:",
  "p-beginners-guide-ending-note3":
    "Շնորհակալություն Solid Swap-ը ընտրելու համար: Ցանկանում ենք հաջող և անվտանգ փոխանակումներ:",
  "p-beginners-guide-ending-note4":
    "Եթե ունեք հարցեր կամ խնդիրներ, մեր աջակցության ծառայությունը միշտ պատրաստ է օգնել ձեզ:",
  "p-beginners-guide-ending-note5":
    "Շնորհակալություն Solid Swap-ը ընտրելու համար: Ցանկանում ենք հաջող և անվտանգ փոխանակումներ:",
};
