export default {
  "c-chat-indexclient-historyLoadError": "Ошибка при загрузке истории чата",
  "c-chat-indexclient-pictureFormatError":
    "Изображение должно быть в формате jpg/png!",
  "c-chat-indexclient-pictureSizeError":
    "Размер изображения не может превышать 10 МБ!",
  "c-chat-indexclient-uploadError": "Не удалось загрузить изображение!",
  "c-chat-indexclient-connectionError": "Ошибка при подключении к чату",
  "c-chat-indexclient-summaryLoadError": "Ошибка при загрузке резюме чата",
  "c-chat-indexclient-chatWith": "Чат с",
  "c-chat-indexclient-counterparty": "контрагентом",
  "c-chat-indexclient-online": "онлайн",
  "c-chat-indexclient-wasOnline": "был онлайн",
  "c-chat-indexclient-andMore": "и ещё {count}",
  "c-chat-indexclient-allParticipants": "Все участники чата",
  "c-chat-indexclient-moderator": "модератор",
  "c-chat-indexclient-welcomeMessage":
    "Добро пожаловать в чат для заказа #{orderId}",
  "c-chat-indexclient-paymentSent": "Покупатель отправил платеж",
  "c-chat-indexclient-paymentConfirmed": "Продавец подтвердил платеж",
  "c-chat-indexclient-disputeCreated": "Создан спор",
  "c-chat-indexclient-orderCancelled": "Заказ отменен",
  "c-chat-indexclient-moderatorJoined": "Модератор присоединился к чату",
  "c-chat-indexclient-sessionStarted": "Сеанс поддержки начат",
  "c-chat-indexclient-sessionClosed": "Сеанс поддержки закрыт",
  "c-chat-indexclient-placeholder": "Пожалуйста, введите",
};
