export default {
  "p-trade-profile-title":
    "Վճարման Տարբերակներ | Ապահով Կրիպտո Վճարման Մեթոդներ Solid Swap-ում",
  "p-trade-profile-description":
    "Բացահայտեք տարբեր ապահով վճարման տարբերակներ Solid Swap-ում կրիպտոարժույթի առևտրի համար: Ընտրեք լավագույն մեթոդը ձեր կարիքների համար",
  "p-trade-profile-keywords":
    "կրիպտո վճարման տարբերակներ, ապահով վճարման մեթոդներ, թվային արժույթի վճարումներ, կրիպտո գործարքներ",
  "p-trade-profile-option-delete-confirm":
    "Այս գործողությունը կջնջի ձեր վճարման տարբերակը: Շարունակել?",
  "p-trade-profile-warning": "Զգուշացում",
  "p-trade-profile-delete": "Ջնջել",
  "p-trade-profile-cancel": "Չեղարկել",
  "p-trade-profile-option-delete-error":
    "Վճարման տարբերակը ջնջելիս սխալ է տեղի ունեցել",
  "p-trade-profile-my-payment-options": "Իմ վճարման տարբերակները",
  "p-trade-profile-payment-options-info-1":
    "Սրանք ձեր վճարման տարբերակներն են, որոնք անհրաժեշտ են բոլոր գործարքների համար օգտատերերի միջև:",
  "p-trade-profile-payment-options-info-2":
    "Եթե դուք վաճառում եք կրիպտոարժույթ, ապա վճարման տարբերակները կտեղեկացնեն հակառակ կողմին, որտեղ փոխանցել միջոցները գնելու համար:",
  "p-trade-profile-payment-options-info-3":
    "Եթե դուք գնում եք կրիպտոարժույթ, ապա այս տարբերակները կօգտագործվեն պատվերի ստեղծման ժամանակ՝ վճարման տեսակը ընտրելու համար՝ ցուցադրելով վաճառողին ձեր նախընտրած վճարման մեթոդը տվյալ պատվերի համար:",
  "p-trade-profile-no-options": "Դուք չունեք վճարման տարբերակներ դեռևս",
  "p-trade-profile-edit": "Խմբագրել",
  "p-trade-profile-add-new-payment-option": "Ավելացնել նոր վճարման տարբերակ",
};
