export default {
  "p-resetPassword-head-title":
    "Restablecer Contraseña | Recuperación de Cuenta de Solid Swap",
  "p-resetPassword-head-description":
    "Restablece la contraseña de tu cuenta de Solid Swap rápida y de manera segura. Sigue los pasos para recuperar el acceso a tu cuenta",
  "p-resetPassword-head-keywords":
    "restablecer contraseña, recuperación de cuenta, restablecimiento de contraseña de Solid Swap",
  "p-resetPassword-success": "¡Tu contraseña ha sido restablecida con éxito!",
  "p-resetPassword-loginInfo": "Ahora puedes iniciar sesión en tu cuenta",
  "p-resetPassword-error":
    "No se puede restablecer tu contraseña, contacta al soporte por favor!",
  "p-resetPassword-validate-password-required":
    "Por favor ingresa tu contraseña",
  "p-resetPassword-validate-password-length":
    "La longitud de la contraseña debe estar entre 6 y 21 caracteres",
  "p-resetPassword-validate-password2-required":
    "Por favor confirma tu contraseña",
  "p-resetPassword-validate-password2-match": "Las contraseñas no coinciden",
  "p-resetPassword-title": "Restablecer tu Contraseña",
  "p-resetPassword-success-title": "¡Éxito!",
  "p-resetPassword-success-message":
    "¡Tu contraseña ha sido restablecida con éxito! Serás redirigido a la página de inicio de sesión en 30 segundos",
  "p-resetPassword-goToLogin": "Ir a iniciar sesión",
  "p-resetPassword-instructions":
    "Por favor ingresa tu nueva contraseña en los campos a continuación. Asegúrate de que tu contraseña tenga al menos 6 caracteres de longitud e incluya una mezcla de letras mayúsculas, letras minúsculas, números y caracteres especiales para mayor seguridad.",
  "p-resetPassword-newPassword": "Nueva contraseña",
  "p-resetPassword-placeholder-password": "*****",
  "p-resetPassword-repeatPassword": "Repetir contraseña",
  "p-resetPassword-placeholder-password2": "*****",
  "p-resetPassword-resetButton": "Restablecer Contraseña",
};
