export default {
  "p-resetPassword-head-title":
    "Passwort zurücksetzen | Solid Swap Kontowiederherstellung",
  "p-resetPassword-head-description":
    "Setzen Sie Ihr Solid Swap-Kontopasswort schnell und sicher zurück. Befolgen Sie die Schritte, um wieder Zugang zu Ihrem Konto zu erhalten",
  "p-resetPassword-head-keywords":
    "passwort zurücksetzen, kontowiederherstellung, Solid Swap passwort zurücksetzen",
  "p-resetPassword-success": "Ihr Passwort wurde erfolgreich zurückgesetzt!",
  "p-resetPassword-loginInfo": "Sie können sich jetzt in Ihr Konto einloggen",
  "p-resetPassword-error":
    "Ihr Passwort kann nicht zurückgesetzt werden, bitte kontaktieren Sie den Support!",
  "p-resetPassword-validate-password-required":
    "Bitte geben Sie Ihr Passwort ein",
  "p-resetPassword-validate-password-length":
    "Die Länge des Passworts muss zwischen 6 und 21 Zeichen liegen",
  "p-resetPassword-validate-password2-required":
    "Bitte bestätigen Sie Ihr Passwort",
  "p-resetPassword-validate-password2-match":
    "Passwörter stimmen nicht überein",
  "p-resetPassword-title": "Setzen Sie Ihr Passwort zurück",
  "p-resetPassword-success-title": "Erfolg!",
  "p-resetPassword-success-message":
    "Ihr Passwort wurde erfolgreich zurückgesetzt! Sie werden in 30 Sekunden zur Anmeldeseite weitergeleitet",
  "p-resetPassword-goToLogin": "Zur Anmeldung gehen",
  "p-resetPassword-instructions":
    "Bitte geben Sie Ihr neues Passwort in die untenstehenden Felder ein. Stellen Sie sicher, dass Ihr Passwort mindestens 6 Zeichen lang ist und eine Mischung aus Großbuchstaben, Kleinbuchstaben, Zahlen und Sonderzeichen für zusätzliche Sicherheit enthält.",
  "p-resetPassword-newPassword": "Neues Passwort",
  "p-resetPassword-placeholder-password": "*****",
  "p-resetPassword-repeatPassword": "Passwort wiederholen",
  "p-resetPassword-placeholder-password2": "*****",
  "p-resetPassword-resetButton": "Passwort zurücksetzen",
};
