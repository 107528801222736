export default {
  "c-chat-indexclient-historyLoadError": "加载聊天记录时出错",
  "c-chat-indexclient-pictureFormatError": "图片必须是 jpg/png 格式！",
  "c-chat-indexclient-pictureSizeError": "图片大小不能超过 10MB！",
  "c-chat-indexclient-uploadError": "上传图片失败！",
  "c-chat-indexclient-connectionError": "连接聊天时出错",
  "c-chat-indexclient-summaryLoadError": "加载聊天摘要时出错",
  "c-chat-indexclient-chatWith": "聊天对象",
  "c-chat-indexclient-counterparty": "对方",
  "c-chat-indexclient-online": "在线",
  "c-chat-indexclient-wasOnline": "曾在线",
  "c-chat-indexclient-andMore": "和 {count} 个更多",
  "c-chat-indexclient-allParticipants": "所有聊天参与者",
  "c-chat-indexclient-moderator": "管理员",
  "c-chat-indexclient-welcomeMessage": "欢迎进入订单 #{orderId} 的聊天",
  "c-chat-indexclient-paymentSent": "买家已发送付款",
  "c-chat-indexclient-paymentConfirmed": "卖家已确认付款",
  "c-chat-indexclient-disputeCreated": "争议已创建",
  "c-chat-indexclient-orderCancelled": "订单已取消",
  "c-chat-indexclient-moderatorJoined": "管理员加入聊天",
  "c-chat-indexclient-sessionStarted": "支持会话已开始",
  "c-chat-indexclient-sessionClosed": "支持会话已关闭",
  "c-chat-indexclient-placeholder": "请输入",
};
