export default {
  "p-privacy-policy-title": "隐私政策 | Solid Swap",
  "p-privacy-policy-meta-description":
    "阅读Solid Swap的隐私政策，了解我们如何处理您的个人信息并保护您的隐私",
  "p-privacy-policy-meta-keywords":
    "隐私政策, 数据保护, 个人信息, Solid Swap隐私",
  "p-privacy-policy-header-title": "隐私政策",
  "p-privacy-policy-download-link": "下载文件",
  "p-privacy-policy-alert":
    "此客户协议可能会以不同语言发布。如果有任何差异，请参考英文版",
  "p-privacy-policy-last-updated": "最后更新：",
  "p-privacy-policy-intro-1":
    "本隐私政策（以下简称“隐私政策”），可能会不时修订或以其他方式更改，解释了SOLIDSWAP有限公司（以下简称“SOLIDSWAP”、“我们”或“我们的”）通过以下方式收集、使用、维护和披露用户信息的方式",
  "p-privacy-policy-site": "solidswap.net",
  "p-privacy-policy-intro-2":
    "网站（以下简称“网站”）及提供服务（以下简称“服务”）。SOLIDSWAP有限公司是您的个人数据的控制者。",
  "p-privacy-policy-commitment":
    "SOLIDSWAP及其附属公司致力于尽一切努力保护您的隐私。SOLIDSWAP使用收集到的信息履行其合同义务并改善客户服务。",
  "p-privacy-policy-read":
    "请仔细阅读本文档，以了解我们关于您的个人信息的立场和政策，以及我们将如何使用这些信息。",
  "p-privacy-policy-read-cookie": "本隐私政策应与我们的Cookie政策一并阅读。",
  "p-privacy-policy-data-we-collect": "1. 我们收集您的哪些数据",
  "p-privacy-policy-data-we-collect-intro":
    "我们可能会收集和处理以下有关您的数据：",
  "p-privacy-policy-info-provided": "您提供给我们的信息",
  "p-privacy-policy-info-provided-description":
    "您可能会通过在网站上填写表格或通过电话、电子邮件、视频会议或任何其他方式向我们提供有关您的信息。这些信息包括在网站上注册时指定的个人数据，订阅我们的服务，开设账户并验证账户持有人的身份或有权处理账户的人的身份，以及他们使用的支付工具，在网站上搜索产品，使用我们网站提供的任何服务，当您在我们的网站上下订单时，参与聊天讨论或使用我们网站上的任何其他通信功能，参与竞赛、促销或调查，并向我们报告网站运营中的任何问题。",
  "p-privacy-policy-example": "例如，您提供给我们的数据可能包括您的：",
  "p-privacy-policy-first-last-name": "姓名；",
  "p-privacy-policy-photos-videos":
    "您可以根据我们的反洗钱/反恐融资政策（AML/CTF）和了解客户政策（KYC）向我们发送用于验证目的的照片和视频；",
  "p-privacy-policy-residence-citizenship": "住址和国籍；",
  "p-privacy-policy-sex": "性别；",
  "p-privacy-policy-id-documents": "护照、驾驶证和任何其他政府签发的身份证件；",
  "p-privacy-policy-tax-residency": "税务居民身份；",
  "p-privacy-policy-phone-email": "电话号码和电子邮件地址；",
  "p-privacy-policy-login-nickname": "访问SOLIDSWAP平台的登录名和昵称；",
  "p-privacy-policy-bank-info":
    "银行信息，包括账户和支付详细信息，以及FATCA/CRS信息；",
  "p-privacy-policy-dob": "出生日期和地点；",
  "p-privacy-policy-education": "教育；",
  "p-privacy-policy-work-experience": "工作经验和工作地点；",
  "p-privacy-policy-official-position": "官方职位；",
  "p-privacy-policy-income-sources": "收入来源和/或金额；",
  "p-privacy-policy-account-status":
    "在银行和/或支付系统中开设的账户状态信息；",
  "p-privacy-policy-support-requests":
    "与SOLIDSWAP支持和其他SOLIDSWAP平台用户的请求和历史通信记录；",
  "p-privacy-policy-trade-balances": "交易余额和活动；",
  "p-privacy-policy-location-log":
    "您使用SOLIDSWAP平台时生成的位置和日志数据；",
  "p-privacy-policy-additional-info":
    "根据我们的反洗钱/反恐融资政策（AML/CTF）和了解客户政策（KYC）履行合规义务可能需要的其他个人信息。",
  "p-privacy-policy-no-sensitive-data":
    "SOLIDSWAP不收集或处理用户的敏感个人数据，如种族或民族出身、政治观点、宗教或哲学信仰、工会会员、遗传或生物识别数据、健康信息、性生活或性取向。",
  "p-privacy-policy-info-collected": "我们收集的有关您的信息",
  "p-privacy-policy-info-collected-description":
    "每次您访问我们的网站并使用我们的服务时，我们可能会收集（包括自动收集）以下有关您的信息：",
  "p-privacy-policy-info-collected-a":
    "(a) 技术信息，包括用于连接您的计算机到互联网的互联网协议（IP）地址，您的个人账户登录信息，您的浏览器类型和版本，时区设置，浏览器插件类型和版本，操作系统和平台；",
  "p-privacy-policy-info-collected-b":
    "(b) 有关您访问本网站的数据：包括关于统一资源定位器（URL）的完整信息，网站上的导航路径以及您导航到/离开网站的时间（包括日期和时间），您查看或搜索的产品和服务，页面响应时间，数据加载错误，在某些页面上的停留时间，页面上的活动（如滚动、鼠标移动、点击），退出页面的方法，以及用于联系客户支持中心的电话号码；",
  "p-privacy-policy-info-collected-c":
    "(c) 地理位置数据：我们可能会在您下载或使用我们的服务时随时从您的设备收集实时位置信息；我们可能会使用这些信息来优化我们的服务；",
  "p-privacy-policy-info-collected-d":
    "(d) 财务信息和有关使用我们网站提供的服务和机会的信息，包括有关正在进行的交易及其参与者的信息，包括保证金交易，有关账户状态和资金流动的信息，累积佣金，关于账户存款和从我们平台上开设的账户中提款的信息。",
  "p-privacy-policy-info-from-others": "我们从其他来源收到的信息",
  "p-privacy-policy-info-from-others-description":
    "如果您使用我们任何其他网站或服务，我们可能会收到有关您的信息。我们还与第三方（包括我们的商业合作伙伴、技术、支付和支持分包商、广告网络、分析提供商、尽职调查提供商、信用报告机构）密切合作，可能会从他们那里收到有关您的信息。这些信息可能包括：",
  "p-privacy-policy-info-from-others-a":
    "(a) 您用于存款和/或取款的金融机构信息；",
  "p-privacy-policy-info-from-others-b": "信用机构确认您提供给我们的信息；",
  "p-privacy-policy-store-pending":
    "您在SOLIDSWAP平台注册期间提供给我们的个人数据，无论您是否完成注册过程，我们都会存储。对于根据反洗钱/反恐融资政策（AML/CTF）和了解客户政策（KYC）进行待处理验证过程中的个人数据也是如此。",
  "p-privacy-policy-cookies": "2. 使用Cookie",
  "p-privacy-policy-cookies-description":
    "有关使用Cookie的信息，请阅读我们的Cookie政策。",
  "p-privacy-policy-use-of-data": "3. 我们将如何使用您的个人数据？",
  "p-privacy-policy-use-of-data-intro": "我们将您的个人数据用于以下目的：",
  "p-privacy-policy-providing-services": "提供我们的服务",
  "p-privacy-policy-fulfill-obligations":
    "履行与您签订的任何合同项下的义务，以及向您提供所需的信息、产品和服务；",
  "p-privacy-policy-security": "确保我们网站、服务和您的账户的安全；",
  "p-privacy-policy-admin-site":
    "管理我们的网站并进行内部操作，包括故障排除、数据分析、测试、收集统计数据和进行调查；",
  "p-privacy-policy-interactive-features":
    "如果您希望使用，我们将向您提供网站的互动功能访问权限；",
  "p-privacy-policy-support":
    "提供支持，回应并解决您与我们服务和网站功能使用相关的投诉和问题。",
  "p-privacy-policy-informing": "通知您我们的产品和服务",
  "p-privacy-policy-inform-products":
    "通知您我们的其他现有和/或新产品和服务，类似于您已购买或查询信息的产品和服务；",
  "p-privacy-policy-inform-changes": "通知您我们的产品和服务的变更。",
  "p-privacy-policy-improve": "帮助改进我们的产品和服务",
  "p-privacy-policy-improve-site":
    "改进我们的网站，确保我们的内容在您的计算机操作系统上尽可能用户友好；",
  "p-privacy-policy-evaluate-ads":
    "评估和理解我们向您和其他用户提供的广告材料的效果，并向您提供必要的广告信息；",
  "p-privacy-policy-effective-content":
    "确保我们网站的内容以最有效的方式呈现给您，并可从您的计算机上使用。",
  "p-privacy-policy-protect-interests": "保护公司利益",
  "p-privacy-policy-risk-management": "风险管理；",
  "p-privacy-policy-it-security": "保护和控制我们的IT系统，预防犯罪；",
  "p-privacy-policy-evaluate-services":
    "评估我们的产品和服务的使用情况，以便进一步开发；",
  "p-privacy-policy-internal-business":
    "用于内部业务和研究目的，例如与您沟通我们的产品和服务；",
  "p-privacy-policy-analyze-data":
    "使用网络信标和像素标签匿名分析数据，以确保我们的产品和服务适合我们运营的市场。",
  "p-privacy-policy-legal-procedures": "提供法律程序",
  "p-privacy-policy-law-enforcement": "回应执法机构的请求；",
  "p-privacy-policy-legal-claims": "提出和/或回应法律诉讼；",
  "p-privacy-policy-disputes": "调查和/或解决与您的争议。",
  "p-privacy-policy-legal-compliance": "法律合规",
  "p-privacy-policy-verify-users":
    "验证和认证开设和管理账户的用户的身份，以防止欺诈和相关的非法活动；",
  "p-privacy-policy-aml-compliance":
    "遵守我们的反洗钱/反恐融资政策（AML/CTF）和了解客户政策（KYC）以及其他相关法律法规的要求；",
  "p-privacy-policy-notify-changes":
    "通知我们的用户SOLIDSWAP平台的政策和使用条款的变更。",
  "p-privacy-policy-marketing-third-parties": "营销和第三方",
  "p-privacy-policy-new-products":
    "向您提供有关SOLIDSWAP或我们的第三方提供商推出的新产品或服务的信息，我们认为这些信息可能对您有兴趣。",
  "p-privacy-policy-disclosure": "4. 披露您的个人数据",
  "p-privacy-policy-disclosure-intro":
    "我们可能会在SOLIDSWAP集团内分享您的个人信息，包括我们的子公司和附属公司，我们的最终母公司及其子公司。",
  "p-privacy-policy-disclosure-third-parties-intro":
    "我们可能会将您的个人数据转移给第三方，包括：",
  "p-privacy-policy-disclosure-third-parties-a":
    "(i) 商业伙伴、供应商、分包商、服务提供商，他们进行适当的法律和财务检查，以履行我们与他们或与您签订的合同义务；",
  "p-privacy-policy-disclosure-third-parties-b":
    "(ii) 需要某些数据以选择和推广相关广告的营销网络。",
  "p-privacy-policy-disclosure-no-sensitive":
    "我们不会向营销公司披露有关客户的敏感信息，但我们可能会向他们提供有关用户的汇总信息（例如，我们可能会告诉他们，某一天有500名30岁以下的女性点击了他们的广告）。",
  "p-privacy-policy-disclosure-aggregate":
    "我们还可能使用这些汇总信息来帮助营销公司接触他们希望的目标受众（例如，居住在某个特定地区的女性）。我们可能会使用我们从您那里收集的个人数据，使我们能够按照广告客户的愿望展示广告给特定的目标受众，帮助我们改进和优化我们网站的表现。",
  "p-privacy-policy-disclosure-other-cases-intro":
    "在以下情况下，我们也可能向第三方披露您的个人数据：",
  "p-privacy-policy-disclosure-other-cases-a":
    "(a) 如果我们出售或购买任何业务或资产，用户的个人信息可能会被披露给潜在买家或卖家；",
  "p-privacy-policy-disclosure-other-cases-b":
    "(b) 如果SOLIDSWAP或其所有资产被第三方购买，用户的个人数据将作为资产之一转移；",
  "p-privacy-policy-disclosure-other-cases-c":
    "(c) 如果我们被要求根据任何适用法律披露或转移用户的个人数据，或为了遵守相关法院命令、司法和执法机构的请求，或强迫用户遵守与我们签订的协议条款；",
  "p-privacy-policy-disclosure-other-cases-d":
    "(d) 在必要时保护我们的权利和财产或确保SOLIDSWAP、我们的用户或其他方的安全（这包括与相关组织共享信息，以防止欺诈和减少违约可能性）。",
  "p-privacy-policy-security-storage": "5. 个人数据的安全和存储",
  "p-privacy-policy-security-measures":
    "我们使用安全措施确保您的个人数据的机密性，并保护其免受丢失、滥用、篡改或销毁。只有授权的SOLIDSWAP人员可以访问您的个人信息，这些员工必须将此信息视为机密。当前的安全措施将根据新的法律规定和技术创新不时进行审查。",
  "p-privacy-policy-storage":
    "我们从您那里收集的信息可能会转移到欧洲经济区（EEA）以外，并存储在EEA以外的地方，以便向您提供我们的服务。有关您的信息可能由我们或位于EEA以外的服务提供商的员工处理。无论您的个人数据是由EEA以外的第三方存储还是以其他方式处理，我们都会确保我们通知这些第三方我们在本隐私政策下的义务，并与他们建立关系，使其对您的个人数据承担义务，前提是他们拥有本隐私政策规定的适当安全水平。这些员工可能会处理您的请求、支付信息并提供技术支持。通过向我们提供您的个人数据，您同意您的个人数据的转移、存储和处理。反过来，我们将采取一切必要措施，确保您的个人数据按照国际安全标准和本隐私政策的规定进行处理。",
  "p-privacy-policy-transmission-security":
    "尽管通过互联网传输信息并非完全安全，但我们将努力保护您的个人数据。鉴于此，我们不能保证您用于向我们网站传输个人信息的渠道的安全性。在这方面，您对向网站传输个人数据可能出现的任何故障负全部责任。一旦我们收到您的个人数据，我们将使用严格的程序和所有必要的技术和安全措施，以防止未经授权访问您的个人数据。",
  "p-privacy-policy-grounds": "6. 处理个人数据的依据",
  "p-privacy-policy-grounds-intro":
    "我们只有在有法律依据的情况下才会收集和处理您的个人数据。法律依据包括：",
  "p-privacy-policy-consent": "您的同意；",
  "p-privacy-policy-agreement-terms": "您与SOLIDSWAP之间协议的条款；",
  "p-privacy-policy-legal-obligations":
    "法律义务的遵守，例如SOLIDSWAP被要求请求/接收和处理及存储您的个人数据以遵守适用法律，例如反洗钱和反恐融资的相关法律；",
  "p-privacy-policy-legal-interests":
    "合法利益，例如当处理对于保护您或我们免受某些威胁（如欺诈、安全威胁等）是必要时；",
  "p-privacy-policy-business-compliance":
    "遵守适用法律，以便根据监管要求开展我们的业务（提供服务的质量、识别需求、准备报告）；",
  "p-privacy-policy-corporate-transactions":
    "进行公司交易（出售股份/参与者股份、合并、收购）等。",
  "p-privacy-policy-withdraw-consent":
    "在处理个人数据的依据是您的同意的情况下，您有权随时撤回您的同意。",
  "p-privacy-policy-rights": "7. 您的权利",
  "p-privacy-policy-rights-intro": "通过联系我们，您可以行使以下权利：",
  "p-privacy-policy-rights-erase":
    "删除全部或部分个人数据的权利（但须考虑到第9条中规定的保留您个人数据的监管要求）；",
  "p-privacy-policy-rights-correct":
    "更改或更正您的个人数据的权利，特别是当它输入错误时；",
  "p-privacy-policy-rights-object": "反对或限制处理您的个人数据的权利；",
  "p-privacy-policy-rights-access":
    "访问您的个人数据和/或以机器可读格式访问您提供给SOLIDSWAP的个人数据的副本的权利。",
  "p-privacy-policy-rights-marketing":
    "此外，您有权禁止将您的数据用于营销目的。在要求您的数据之前，我们通常会征求您的同意，将其披露给第三方用于营销目的。您可以通过在表格中勾选相应的框来行使反对处理您的数据用于营销目的的权利。您也可以随时通过联系我们行使此权利。",
  "p-privacy-policy-rights-limitations":
    "请注意，根据情况行使您的某些权利可能会限制、复杂化或完全消除与我们进一步合作的可能性。",
  "p-privacy-policy-rights-active-links":
    "我们的网站可能包含合作伙伴、广告商和附属机构网站的活动链接。如果您点击任何活动链接，请注意，其他网站有其自己的隐私政策，我们对这些政策的规定不负责。在向这些网站提交您的个人信息之前，请查看其隐私政策。",
  "p-privacy-policy-access": "8. 访问个人数据",
  "p-privacy-policy-access-intro":
    "您有权访问我们持有的有关您的信息。您可以根据以下电子邮件地址发送的书面请求行使访问权，我们将向您提供我们存储的有关您的信息。如果您的请求显然毫无根据或过多，或者您要求额外的个人数据副本，我们可能会向您收取额外费用。",
  "p-privacy-policy-retention": "9. 个人数据的保留",
  "p-privacy-policy-retention-intro":
    "确保您的个人数据的安全和保护是我们的最高优先事项。我们使用安全存储，以确保您在与我们平台互动的所有阶段、使用我们的产品和服务时共享的个人数据的安全。我们的安全服务采取一切适当措施，保护您的个人数据免受黑客攻击、丢失或滥用。",
  "p-privacy-policy-retention-period":
    "我们只会在您通过您的账户使用我们的产品和服务的期间保留您的个人数据，并在遵守本隐私政策中描述的目的和我们遵守的法律和监管义务的前提下进行保留。根据我们的记录保存义务，我们将在您关闭账户或结束与我们的客户关系之日起至少五（5）年内保留有关您的账户和个人数据的信息，除非出于法律或监管原因另有要求。",
  "p-privacy-policy-retention-extended":
    "如果您的个人信息是正式请求或法律义务、政府调查或我们用户协议及其组成部分的可能违规行为调查的对象，或者基于我们的合理考虑，可能会造成损害，我们可能会访问或保留您的个人信息更长时间。",
  "p-privacy-policy-unsubscribe":
    "如果您取消订阅我们的电子邮件通讯，我们将在非活动电子邮件列表中存储您的电子邮件。",
  "p-privacy-policy-changes": "10. 隐私政策的变更",
  "p-privacy-policy-changes-intro":
    "对隐私政策所做的任何更改将发布在此页面上，并在必要时发送到您的电子邮件地址。我们鼓励您定期查看此页面，以了解隐私政策的任何更新或更改。",
  "p-privacy-policy-contact": "11. 联系我们",
  "p-privacy-policy-contact-description":
    "如果您对我们的隐私政策、Cookie政策或处理您的个人数据的方法和目的有任何问题、投诉或疑问，可以通过以下电子邮件地址联系我们",
};
