export default {
  "p-blog-codeId-failed-to-save-post": "Не удалось сохранить пост!",
  "p-blog-codeId-post-saved-successfully": "Пост успешно сохранен!",
  "p-blog-codeId-confirm-delete": "Вы уверены, что хотите удалить этот пост?",
  "p-blog-codeId-warning": "Предупреждение",
  "p-blog-codeId-delete": "Удалить",
  "p-blog-codeId-post-deleted-successfully": "Пост успешно удален!",
  "p-blog-codeId-failed-to-delete-post": "Не удалось удалить пост!",
  "p-blog-codeId-post-not-found": "Пост в блоге не найден :(",
  "p-blog-codeId-back-to-all-posts": "Назад ко всем постам",
  "p-blog-codeId-edit-post": "Редактировать пост",
  "p-blog-codeId-delete-post": "Удалить пост",

  "p-blog-index-title": "Блог | Инсайты, советы и истории от Solid Swap",
  "p-blog-index-meta-description":
    "Ознакомьтесь с последними инсайтами, советами и историями в нашем блоге. Оставайтесь в курсе наших последних публикаций, охватывающих широкий спектр тем, включая технологии, образ жизни и многое другое",
  "p-blog-index-meta-keywords":
    "блог, инсайты, советы, истории, технологии, образ жизни, последние посты, Solid Swap, solidswap",
  "p-blog-index-failed-to-fetch-posts": "Не удалось получить посты",
  "p-blog-index-solid-swap-blog": "Блог Solid Swap",
  "p-blog-index-search-placeholder": "Поиск постов в блоге...",
  "p-blog-index-new-post": "Новый пост",
  "p-blog-index-active": "Активный",
  "p-blog-index-archive": "Архив",
  "p-blog-index-load-more": "Загрузить еще",
  "p-blog-index-no-posts-found": "Посты не найдены",
};
