export default {
  "p-kyc-aml-policy-title":
    "Политика борьбы с отмыванием денег (Anti-Money Laundering Policy).",
  "p-kyc-warning":
    "SolidSwap предупреждает пользователей о недопустимости использования сервиса для легализации средств, полученных преступным путем, финансирования терроризма, незаконной деятельности любого рода, а также для покупки запрещенных товаров и услуг.",
  "p-kyc-illegal-funds-description":
    "Легализация средств, полученных преступным путем, — это сокрытие их незаконного происхождения путем перевода в средства или инвестиции, которые выглядят легитимными.",
  "p-kyc-general-provisions-title": "Общие положения",
  "p-kyc-prevent-illegal-transactions":
    "Для предотвращения незаконных транзакций сервис устанавливает определенные требования ко всем заявкам, создаваемым пользователем:",
  "p-kyc-sender-recipient-same":
    "Отправитель и получатель платежа по заявке должны быть одним и тем же лицом. Переводы в пользу третьих лиц с использованием услуг сервиса строго запрещены.",
  "p-kyc-contact-info-requirements":
    "Вся контактная информация, введенная пользователем в заявке, а также другие личные данные, переданные пользователем в сервис, должны быть актуальными и полностью достоверными.",
  "p-kyc-no-anonymous-connections":
    "Строго запрещено создание заявок с использованием анонимных прокси-серверов или любых других анонимных интернет-соединений.",
  "p-kyc-policy-description":
    'Данная Политика по борьбе с отмыванием денег (далее — "AML Policy") описывает процедуры и механизмы, используемые SolidSwap для борьбы с отмыванием денег. SolidSwap придерживается следующих правил:',
  "p-kyc-no-business-with-criminals":
    "не вступать в деловые отношения с известными преступниками и/или террористами;",
  "p-kyc-no-criminal-transactions":
    "не проводить транзакции, которые являются результатом известной преступной и/или террористической деятельности;",
  "p-kyc-no-facilitating-criminal-activity":
    "не способствовать осуществлению транзакций, связанных с известной преступной и/или террористической деятельностью;",
  "p-kyc-right-to-restrict-services":
    "имеет право ограничивать или запрещать использование всех или части услуг для лиц, подпадающих под ограничения...",
  "p-kyc-verification-procedures-title": "Процедуры проверки",
  "p-kyc-kyc-policy-description":
    'Сервис SolidSwap устанавливает собственные процедуры проверки в рамках стандартов по борьбе с отмыванием денег — политики "Знай своего клиента" (KYC).',
  "p-kyc-verification-steps":
    "Пользователи сервиса SolidSwap проходят процедуру проверки (предоставляют удостоверение личности: паспорт или идентификационную карту). SolidSwap оставляет за собой право собирать идентификационную информацию пользователей для целей реализации AML Policy.",
  "p-kyc-request-second-document":
    "Сервис SolidSwap также может запросить второй документ для идентификации пользователя: выписку из банка или счет за коммунальные услуги, выданные не более чем 3 месяца назад...",
  "p-kyc-verification-authenticity":
    "Сервис SolidSwap проверяет подлинность документов и информации, предоставленных пользователями, и оставляет за собой право получать дополнительную информацию...",
  "p-kyc-request-updated-documents":
    "Если идентификационная информация пользователя была изменена или его деятельность показалась подозрительной, сервис SolidSwap имеет право запросить обновленные документы у пользователя...",
  "p-kyc-aml-compliance-title": "Ответственный за соблюдение AML Policy",
  "p-kyc-aml-compliance-description":
    "Ответственным за соблюдение AML Policy является сотрудник SolidSwap, в чьи обязанности входит обеспечение соблюдения AML Policy, а именно:",
  "p-kyc-collect-user-info":
    "сбор идентификационной информации пользователей и передача ее ответственному агенту по обработке персональных данных;",
  "p-kyc-create-update-policies":
    "Создание и обновление внутренних политик и процедур по составлению, проверке, представлению и хранению всех отчетов, требуемых в соответствии с действующими законами и правилами...",
  "p-kyc-monitor-transactions":
    "мониторинг транзакций и анализ любых значительных отклонений от обычной активности пользователя;",
  "p-kyc-record-management":
    "внедрение системы управления записями для хранения и поиска документов, файлов, форм и журналов;",
  "p-kyc-regular-risk-update": "регулярное обновление оценки рисков.",
  "p-kyc-interaction-with-authorities":
    "Ответственный за соблюдение AML Policy имеет право взаимодействовать с правоохранительными органами, занимающимися предотвращением отмывания денег...",
  "p-kyc-transaction-monitoring-title": "Мониторинг транзакций",
  "p-kyc-monitoring-description":
    "Мониторинг транзакций пользователей и анализ полученных данных также является инструментом для оценки рисков и выявления подозрительных транзакций...",
  "p-kyc-report-suspicious-transactions":
    "сообщать о подозрительных транзакциях в соответствующие правоохранительные органы;",
  "p-kyc-request-additional-info":
    "запрашивать у пользователя любую дополнительную информацию и документы;",
  "p-kyc-suspend-terminate-account":
    "приостанавливать или прекращать учетную запись пользователя;",
  "p-kyc-freeze-assets":
    "приостанавливать обмен и замораживать активы до выяснения обстоятельств;",
  "p-kyc-return-funds":
    "возвращать средства пользователю путем отмены процедуры обмена, согласно пользовательскому соглашению;",
  "p-kyc-risk-assessment": "оценка рисков.",
  "p-kyc-additional-monitoring":
    "Указанный выше список не является исчерпывающим. Сотрудник по AML Policy ежедневно контролирует транзакции пользователей для определения...",
};
