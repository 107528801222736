export default {
  "p-registration-label-email": "电子邮件",
  "p-registration-label-username": "用户名",
  "p-registration-label-password": "密码",
  "p-registration-label-repeat-password": "重复密码",
  "p-registration-head-title": "注册 | 创建您的 Solid Swap 账户",
  "p-registration-head-description":
    "注册 Solid Swap 账户，开始安全高效地进行加密货币交易",
  "p-registration-head-keywords":
    "注册，创建账户，Solid Swap 注册，加密交易注册",
  "p-registration-rules-username": "请输入您的用户名",
  "p-registration-rules-username-length": "用户名长度必须在 5 到 15 个字符之间",
  "p-registration-rules-email": "请输入您的邮箱",
  "p-registration-rules-password": "请输入您的密码",
  "p-registration-rules-password-length": "密码长度必须在 6 到 21 个字符之间",
  "p-registration-rules-password-confirm": "请确认您的密码",
  "p-registration-rules-password-match": "密码不匹配",
  "p-registration-rules-terms": "请接受使用条款",
  "p-registration-success-message": "注册成功。请检查您的邮箱以获取确认链接。",
  "p-registration-error-default": "哎呀，注册时出了点问题。",
  "p-registration-error-data": "您输入的数据有误",
  "p-registration-error-account-exists": "看起来您已经有一个账户。",
  "p-registration-login-link": "请登录",
  "p-registration-title": "注册",
  "p-registration-email-placeholder": "输入您的邮箱",
  "p-registration-username-placeholder": "输入您的用户名",
  "p-registration-password-placeholder": "输入您的密码",
  "p-registration-password-confirm-placeholder": "确认您的密码",
  "p-registration-terms-message": "我同意",
  "p-registration-terms": "使用条款",
  "p-registration-and": "和",
  "p-registration-privacy": "隐私政策",
  "p-registration-continue": "继续",
  "p-registration-success-title": "成功！",
  "p-registration-welcome-title": "加入我们的加密爱好者社区！",
  "p-registration-welcome-message-1": "立即注册，解锁点对点交易的全部潜力。",
  "p-registration-welcome-message-2": "体验自由、公平和金融的未来。",
  "p-registration-welcome-message-3": "深入到创新和机遇相遇的数字货币市场。",
};
