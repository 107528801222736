export default {
  "c-header-menu-dark": "dunkel",
  "c-header-menu-light": "hell",
  "c-header-menu-trade": "P2P-Handel",
  "c-header-menu-news": "Nachrichten",
  "c-header-menu-support": "Support",
  "c-header-menu-chat": "Support-Chat",
  "c-header-menu-blog": "Blog",
  "c-header-menu-faq": "FAQ",
  "c-header-menu-beginnersGuide": "Anfängerleitfaden",
  "c-header-menu-help": "Hilfe",
  "c-header-menu-about": "Über",
  "c-header-menu-menu": "Menü",
  "c-header-menu-admin": "Admin",
  "c-header-menu-moder": "Moderator",
  "c-header-menu-profile": "Mein Profil",
  "c-header-menu-orders": "Aufträge",
  "c-header-menu-paymentOptions": "Zahlungsmethoden",
  "c-header-menu-wallet": "Brieftasche",
  "c-header-menu-settings": "Einstellungen",
  "c-header-menu-logout": "abmelden",
  "c-header-menu-login": "Anmelden",
  "c-header-menu-registration": "Registrierung",
  "c-header-menu-notifications": "Benachrichtigungen",
};
