export default {
  "p-help-head-title": "Aide | Contactez le support Solid Swap",
  "p-help-head-description":
    "Besoin d'aide ? Ouvrez un ticket de support avec Solid Swap et obtenez de l'aide de notre équipe d'experts pour résoudre les problèmes que vous rencontrez",
  "p-help-head-keywords":
    "ticket de support, aide Solid Swap, support de trading de cryptomonnaies, contacter le support",
  "p-help-title": "Page d'aide",
  "p-help-name-required": "Veuillez entrer votre nom",
  "p-help-email-required": "Veuillez entrer votre email",
  "p-help-message-required": "Dites-nous votre problème ici",
  "p-help-ticket-success": "Ticket envoyé avec succès !",
  "p-help-ticket-error":
    "Quelque chose a mal tourné lors de l'envoi du ticket, veuillez réessayer plus tard",
  "p-help-thank-you-message":
    "Merci de nous avoir contactés ! Nous avons reçu votre message et nous vous répondrons par l'adresse email que vous avez fournie dès que possible.",
  "p-help-intro-text":
    "Si vous rencontrez des problèmes ou avez des questions, n'hésitez pas à nous contacter. Remplissez le formulaire ci-dessous, et nous vous répondrons dès que possible.",
  "p-help-label-name": "Nom",
  "p-help-placeholder-name": "Entrez votre nom ici",
  "p-help-label-email": "Email",
  "p-help-placeholder-email": "Entrez votre email ici",
  "p-help-label-message": "Message",
  "p-help-placeholder-message": "...",
  "p-help-submit-button": "Soumettre",
};
