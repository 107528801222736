export default {
  "c-payment-option-close-dialog-confirm":
    "Վստահ եք, որ ցանկանում եք փակել վճարման տարբերակի պատուհանը?",
  "c-payment-option-save-error":
    "Սխալ է տեղի ունեցել վճարման տարբերակը պահպանելիս",
  "c-payment-option-edit": "Խմբագրել վճարման տարբերակը",
  "c-payment-option-new": "Նոր վճարման տարբերակ",
  "c-payment-option-show-currency-methods":
    "Կցուցադրվեն միայն {currency} արժույթի վճարման մեթոդները",
  "c-payment-option-show-specified-options":
    "Կցուցադրվեն միայն առաջարկում նշված վճարման տարբերակները",
  "c-payment-option-editing-method": "Խմբագրում է վճարման մեթոդը:",
  "c-payment-option-select-method":
    "Ընտրեք մեթոդը, որը ցանկանում եք ավելացնել:",
  "c-payment-option-select-method-placeholder": "ընտրեք վճարման եղանակը...",
  "c-payment-option-description": "Վճարման տեսակի նկարագրություն:",
  "c-payment-option-description-info":
    "Խնդրում ենք տրամադրել ձեր վճարման տեսակի նկարագրությունը: Սա պետք է ներառի ձեր հաշվեհամարը, քարտի համարը կամ որևէ համապատասխան տեղեկություն, որը կօգնի գնորդներին հասկանալ, թե որտեղ նրանք պետք է փոխանցեն իրենց գումարը, երբ նրանք ձեռք են բերում կրիպտոարժույթ ձեզանից:",
  "c-payment-option-description-placeholder": "գրեք մի քանի նկարագրություն...",
  "c-payment-option-save": "Պահպանել",
  "c-payment-option-validate-payment-option":
    "Խնդրում ենք ընտրել վճարման տարբերակ",
  "c-payment-option-validate-description":
    "Խնդրում ենք ավելացնել նկարագրություն",
};
