export default {
  "c-blog-editingform-pictureFormatError":
    "L'image doit être au format jpg/png !",
  "c-blog-editingform-pictureSizeError":
    "La taille de l'image ne doit pas dépasser 2MB !",
  "c-blog-editingform-uploadError": "Échec du téléchargement de l'image !",
  "c-blog-editingform-titleRequired": "Le titre est requis",
  "c-blog-editingform-idCodeRequired": "Le code URL est requis",
  "c-blog-editingform-descriptionRequired": "La description est requise",
  "c-blog-editingform-previewImageRequired":
    "L'image de prévisualisation est requise",
  "c-blog-editingform-tagsRequired": "Les tags sont requis",
  "c-blog-editingform-bodyRequired": "Le texte de l'article est requis",
  "c-blog-editingform-metaDescriptionRequired":
    "La méta description est requise",
  "c-blog-editingform-creatingNewPost": "Création d'un nouvel article",
  "c-blog-editingform-title": "Titre",
  "c-blog-editingform-active": "Actif",
  "c-blog-editingform-idCode": "Code URL",
  "c-blog-editingform-idCodeHint":
    "Le code doit être unique pour chaque article de blog, en minuscules et avec '-' au lieu d'espaces",
  "c-blog-editingform-description": "Description",
  "c-blog-editingform-previewImage": "Image de prévisualisation",
  "c-blog-editingform-uploadHint1": "Déposez le fichier ici ou",
  "c-blog-editingform-uploadHint2": "cliquez pour télécharger",
  "c-blog-editingform-uploadHint3": "fichiers jpg/png de moins de 2MB",
  "c-blog-editingform-tags": "Tags",
  "c-blog-editingform-tagsPlaceholder":
    "Choisissez des tags pour votre article",
  "c-blog-editingform-tagsHint":
    "Vous pouvez choisir parmi les tags existants ou en créer de nouveaux en tapant dans le champ et en appuyant sur Entrée",
  "c-blog-editingform-postText": "Texte de l'article",
  "c-blog-editingform-metaDescription": "Méta description",
  "c-blog-editingform-cancel": "Annuler",
  "c-blog-editingform-save": "Enregistrer",

  "c-blog-item-readMore": "Lire la suite",
};
