export default {
  "c-feedback-create-fastTransaction": "Быстрая транзакция",
  "c-feedback-create-politeFriendly": "Вежливый и дружелюбный",
  "c-feedback-create-patient": "Терпеливый",
  "c-feedback-create-safeTrustworthy": "Надежный и заслуживающий доверия",
  "c-feedback-create-goodPrice": "Хорошая цена",
  "c-feedback-create-successMessage": "Спасибо за ваш отзыв!",
  "c-feedback-create-errorMessage": "Произошла ошибка при отправке отзыва",
  "c-feedback-create-feedbackTitle": "Отзыв",
  "c-feedback-create-experienceQuestion": "Каков ваш опыт?",
  "c-feedback-create-positive": "Положительный",
  "c-feedback-create-negative": "Отрицательный",
  "c-feedback-create-feedbackPlaceholder": "Пожалуйста, введите ваш отзыв",
  "c-feedback-create-anonymous": "Анонимно",
  "c-feedback-create-anonymousHint": "отправить отзыв без указания имени",
  "c-feedback-create-cancel": "Отмена",
  "c-feedback-create-submit": "Отправить отзыв",

  "c-feedback-item-anonymously": "(анонимно)",
  "c-feedback-item-positive": "Положительный",
  "c-feedback-item-negative": "Отрицательный",
};
