export default {
  "s-useNotifications-order": "Order #{orderId}",
  "s-useNotifications-system-message": "System message",
  "s-useNotifications-new-order-created": "New order created!",
  "s-useNotifications-order-status-changed":
    'Order status changed to "{status}"',
  "s-useNotifications-order-created": "Order created",
  "s-useNotifications-order-deleted": "Order deleted",
  "s-useNotifications-moderator-joined": "Moderator joined",
  "s-useNotifications-attached-file": "[attached file]",
};
