export default {
  "c-offers-table-loginWarning":
    "Вам необходимо войти в систему, чтобы создать заказ",
  "c-offers-table-toggleError":
    "Что-то пошло не так при переключении предложения",
  "c-offers-table-insufficientBalance":
    "У вас недостаточно средств для активации этого предложения",
  "c-offers-table-goToWallet": "перейти к кошельку",
  "c-offers-table-seller": "Продавец",
  "c-offers-table-buyer": "Покупатель",
  "c-offers-table-price": "Цена",
  "c-offers-table-availableLimit": "Доступный лимит",
  "c-offers-table-available": "Доступно",
  "c-offers-table-offerLimits": "Лимиты предложения",
  "c-offers-amount-unlimited": "предложение не ограничено",
  "c-offers-table-paymentOptions": "Варианты оплаты",
  "c-offers-table-more": "ещё",
  "c-offers-table-active": "активный",
  "c-offers-table-disabled": "отключен",
  "c-offers-table-edit": "редактировать",
  "c-offers-table-delete": "удалить",
  "c-offers-table-buy": "Купить",
  "c-offers-table-sell": "Продать",
};
