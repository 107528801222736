export default {
  "p-trade-profile-title":
    "Opciones de Pago | Métodos de pago de cripto seguros en Solid Swap",
  "p-trade-profile-description":
    "Descubre varias opciones de pago seguras disponibles en Solid Swap para el comercio de criptomonedas. Elige el mejor método para tus necesidades",
  "p-trade-profile-keywords":
    "opciones de pago de cripto, métodos de pago seguros, pagos de divisas digitales, transacciones de criptomonedas",
  "p-trade-profile-option-delete-confirm":
    "Esta acción eliminará tu opción de pago. ¿Continuar?",
  "p-trade-profile-warning": "Advertencia",
  "p-trade-profile-delete": "Eliminar",
  "p-trade-profile-cancel": "Cancelar",
  "p-trade-profile-option-delete-error":
    "Ocurrió un error al eliminar la opción de pago",
  "p-trade-profile-my-payment-options": "Mis opciones de pago",
  "p-trade-profile-payment-options-info-1":
    "Estas son tus opciones de pago, necesarias para todas las transacciones entre usuarios.",
  "p-trade-profile-payment-options-info-2":
    "Si estás vendiendo criptomonedas, las opciones de pago informarán a la contraparte dónde transferir los fondos para la compra.",
  "p-trade-profile-payment-options-info-3":
    "Si estás comprando criptomonedas, estas opciones se utilizarán para seleccionar el tipo de pago durante la creación del pedido, indicando al vendedor tu método de pago preferido para el pedido actual.",
  "p-trade-profile-no-options": "Aún no tienes opciones",
  "p-trade-profile-edit": "Editar",
  "p-trade-profile-add-new-payment-option": "Agregar nueva opción de pago",
};
