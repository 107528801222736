export default {
  "p-beginners-guide-head-title":
    "Anfängerleitfaden | Beginnen Sie mit dem Handel von Kryptowährungen mit Solid Swap",
  "p-beginners-guide-head-description":
    "Neu im Kryptowährungshandel? Unser Anfängerleitfaden hilft Ihnen beim Einstieg mit leicht verständlichen Schritten und wertvollen Tipps zum Handel mit digitalen Vermögenswerten",
  "p-beginners-guide-head-keywords":
    "anfängerleitfaden, kryptowährungshandel, einstieg, krypto-handelstipps, digitale vermögenswerte",
  "p-beginners-guide-account-preparation-title":
    "Kontovorbereitung für den P2P-Handel",
  "p-beginners-guide-register-title": "Registrieren Sie sich bei Solid Swap",
  "p-beginners-guide-two-factor-title":
    "Zwei-Faktor-Authentifizierung aktivieren",
  "p-beginners-guide-payment-method-title": "Zahlungsmethode hinzufügen",
  "p-beginners-guide-finding-offers-title-1": "Angebote finden und erstellen",
  "p-beginners-guide-select-currency-title-1": "Währungspaar auswählen",
  "p-beginners-guide-search-offer-title-1":
    "Nach einem passenden Angebot suchen",
  "p-beginners-guide-create-offer-title-1": "Ein Umtauschangebot erstellen",
  "p-beginners-guide-commissions-title-1": "Kommissionen",
  "p-beginners-guide-completing-transaction-title-1":
    "Abschluss der Transaktion",
  "p-beginners-guide-intro-1":
    "Hallo! Dieser Leitfaden hilft Ihnen dabei, Ihren ersten Austausch auf Solid Swap durchzuführen. Es wird alle wichtigen Schritte beschrieben, nach denen Sie eine Transaktion einfach und sicher abschließen können. Wir haben diesen Prozess so verständlich und zugänglich wie möglich gemacht, sodass Sie sich keine Sorgen machen müssen, wenn Sie neu in der Welt der Kryptowährungen sind – wir werden Sie bei jedem Schritt begleiten.",
  "p-beginners-guide-intro-2":
    "Lassen Sie uns mit den Grundlagen beginnen: Was ist Solid Swap und warum ist es die ideale Wahl für den Kryptowährungsaustausch. Solid Swap ist eine Plattform für den schnellen Austausch von Kryptowährungen und Fiat-Geld, die derzeit Peer-to-Peer (P2P)-Dienste anbietet. Wir bieten einen sicheren und profitablen Austausch mit wettbewerbsfähigen Kursen, niedrigen Gebühren und einem hohen Sicherheitsniveau.",
  "p-beginners-guide-steps-title":
    "Wichtige Schritte für erfolgreiches Handeln auf Solid Swap:",
  "p-beginners-guide-follow-steps":
    "Befolgen Sie unsere Anweisungen, um schnell, sicher und zuversichtlich Ihren ersten Handel auf Solid Swap durchzuführen.",
  "p-beginners-guide-step1-title": "Schritt 1: Registrierung",
  "p-beginners-guide-register-instructions":
    "So registrieren Sie sich bei Solid Swap:",
  "p-beginners-guide-register-step1":
    "Klicken Sie auf die Schaltfläche “Registrierung” in der oberen rechten Ecke des Bildschirms.",
  "p-beginners-guide-register-step2":
    "Füllen Sie die erforderlichen Informationen aus und klicken Sie auf “Weiter”.",
  "p-beginners-guide-register-step3":
    "Überprüfen Sie die E-Mail, die Sie registriert haben, und folgen Sie dem Link in der E-Mail, um Ihre E-Mail-Adresse zu bestätigen.",
  "p-beginners-guide-register-step4": "Melden Sie sich in Ihrem Konto an.",
  "p-beginners-guide-step2-title":
    "Schritt 2: Zwei-Faktor-Authentifizierung aktivieren",
  "p-beginners-guide-two-factor-instructions":
    "Um Transaktionen durchzuführen, müssen Sie die Zwei-Faktor-Authentifizierung aktivieren. Die Zwei-Faktor-Authentifizierung ist besonders wichtig für den Schutz Ihrer Vermögenswerte und Ihres Kontos. Dafür:",
  "p-beginners-guide-two-factor-step1":
    "Klicken Sie auf Ihr Profilbild in der oberen rechten Ecke des Bildschirms und gehen Sie zu den Einstellungen, indem Sie auf die Schaltfläche “Einstellungen” klicken.",
  "p-beginners-guide-two-factor-step2":
    "Klicken Sie auf die rote Schaltfläche “2FA aktivieren”.",
  "p-beginners-guide-two-factor-step3":
    "Scannen Sie den auf dem Bildschirm angezeigten QR-Code, um ihn mit Ihrem Konto bei Solid Swap zu verknüpfen.",
  "p-beginners-guide-two-factor-note":
    "Wenn Sie keine Zwei-Faktor-Authentifizierungs-App haben, machen Sie sich keine Sorgen. Sie können eine der folgenden Apps auf Ihrem Smartphone herunterladen:",
  "p-beginners-guide-two-factor-app1":
    "Google Authenticator (für Android und iOS)",
  "p-beginners-guide-two-factor-app2": "Authy (für Android und iOS)",
  "p-beginners-guide-two-factor-app3":
    "Microsoft Authenticator (für Android und iOS)",
  "p-beginners-guide-faq":
    "Für detailliertere Informationen zum Anschließen von 2FA sowie Antworten auf andere häufig gestellte Fragen besuchen Sie unser [FAQ](link to FAQ).",
  "p-beginners-guide-step3-title": "Schritt 3: Zahlungsmethode hinzufügen",
  "p-beginners-guide-payment-method-instructions":
    "Um einen Austausch durchzuführen, müssen Sie eine Zahlungsmethode hinzufügen. Dafür:",
  "p-beginners-guide-payment-method-step1":
    "Gehen Sie in den Kontoeinstellungen zum Tab “Zahlungsoptionen”.",
  "p-beginners-guide-payment-method-step2":
    "Klicken Sie auf die Schaltfläche “+ Neue Zahlungsmethode hinzufügen”.",
  "p-beginners-guide-payment-method-step3":
    "Wählen Sie eine bequeme Zahlungsmethode aus der Dropdown-Liste.",
  "p-beginners-guide-payment-method-step4":
    "Im Feld “Beschreibung eingeben” können Sie zusätzliche Informationen angeben. Dies wird angezeigt, wenn Sie der Verkäufer sind. Zum Beispiel die Nummer einer Bankkarte oder eines Kryptogeldbeutels, auf dem Sie die Währung akzeptieren.",
  "p-beginners-guide-finding-offers-title":
    "Angebote finden und einen Verkäufer auswählen",
  "p-beginners-guide-select-currency-title":
    "Schritt 1: Währungspaar auswählen",
  "p-beginners-guide-select-currency-step1":
    "Gehen Sie zum Tab P2P-Handel, der sich auf der oberen Panelseite befindet.",
  "p-beginners-guide-select-currency-step2":
    "Wählen Sie die Aktion aus, die Sie durchführen möchten: kaufen oder verkaufen.",
  "p-beginners-guide-select-currency-step3":
    "Wählen Sie die Zahlungsmethode aus, die bei der Transaktion verwendet wird.",
  "p-beginners-guide-select-currency-step4":
    "Geben Sie den Betrag ein, den Sie tauschen möchten, um alle geeigneten Angebote anzuzeigen.",
  "p-beginners-guide-select-currency-note":
    "Das Währungspaar kann auch auf der Hauptseite der Website ausgewählt werden.",
  "p-beginners-guide-search-offer-title":
    "Schritt 2: Nach einem passenden Angebot suchen",
  "p-beginners-guide-search-offer-description":
    "Wählen Sie das für Sie am besten geeignete Umtauschangebot aus.",
  "p-beginners-guide-search-offer-note":
    "Bei der Auswahl eines Verkäufers achten Sie auf folgende Indikatoren:",
  "p-beginners-guide-search-offer-seller-rating":
    "Verkäuferbewertung: Jeder Verkäufer hat eine Bewertung, die auf dem Feedback früherer Käufer basiert. Wählen Sie Verkäufer mit hohen Bewertungen für mehr Vertrauen.",
  "p-beginners-guide-search-offer-transactions":
    "Anzahl der abgeschlossenen Transaktionen: Beachten Sie die Anzahl der erfolgreichen Transaktionen des Verkäufers. Verkäufer mit vielen Transaktionen sind in der Regel zuverlässiger.",
  "p-beginners-guide-search-offer-reviews":
    "Bewertungen: Lesen Sie Bewertungen anderer Benutzer über den Verkäufer. Dies hilft Ihnen, deren Ruf und die Qualität der angebotenen Dienstleistungen zu bewerten. Sie können dies im Profil des Verkäufers tun.",
  "p-beginners-guide-search-offer-limits":
    "Transaktionsmengengrenzen: Überprüfen Sie die Mindest- und Höchstbeträge, die der Verkäufer bereit ist zu tauschen. Stellen Sie sicher, dass sie Ihren Anforderungen entsprechen.",
  "p-beginners-guide-create-offer-title": "Schritt 3: Ein Angebot auswählen",
  "p-beginners-guide-create-offer-step1":
    "Wenn Sie ein passendes Angebot gefunden haben, klicken Sie auf die grüne Schaltfläche “Kaufen” daneben, um die Details anzuzeigen.",
  "p-beginners-guide-create-offer-step2":
    "Überprüfen Sie die Bedingungen des Deals, einschließlich des Wechselkurses, der Mindest- und Höchstbeträge und der Zahlungsmethoden.",
  "p-beginners-guide-create-offer-step3":
    "Wenn Sie mit den Bedingungen einverstanden sind, können Sie mit der Eröffnung der Transaktion fortfahren. Füllen Sie dazu im sich öffnenden Fenster die leeren Felder aus und wählen Sie die Zahlungsmethode. Klicken Sie auf die Schaltfläche “Kaufen”.",
  "p-beginners-guide-create-offer-step4":
    "Wenn keines der vorgestellten Angebote Ihnen zusagt, können Sie Ihr eigenes erstellen. Dafür:",
  "p-beginners-guide-create-offer-note1":
    "Klicken Sie auf die Schaltfläche “+ Neues Angebot posten”.",
  "p-beginners-guide-create-offer-note2":
    "Füllen Sie die Felder mit den notwendigen Informationen aus.",
  "p-beginners-guide-create-offer-note3":
    "In der Beschreibung können Sie zusätzliche Informationen zum Deal angeben.",
  "p-beginners-guide-create-offer-note4":
    "Klicken Sie auf die Schaltfläche “Bestätigen”.",
  "p-beginners-guide-create-offer-note5":
    "Danach befinden Sie sich im Abschnitt “Meine Angebote”. Hier finden Sie alle von Ihnen erstellten Angebote. Um ein Angebot für andere Benutzer der Website sichtbar zu machen, ändern Sie dessen Status. Sie können dies tun, indem Sie den entsprechenden Schalter auf der rechten Seite des Angebots anklicken.",
  "p-beginners-guide-commissions-title": "Bitte beachten Sie!",
  "p-beginners-guide-commissions-description":
    "Wir streben nach Transparenz in allen Aspekten unserer Arbeit, einschließlich der Kommissionen. Die Handelskommission beträgt nur 0,2% des Transaktionsbetrags, die der Verkäufer zahlt. Dadurch können Sie Ihre Transaktionen einfach planen, da keine unerwarteten Gebühren anfallen.",
  "p-beginners-guide-completing-transaction-title": "Abschluss der Transaktion",
  "p-beginners-guide-completing-transaction-description1":
    "Nachdem Sie ein passendes Umtauschangebot gefunden und auf die Schaltfläche “Kaufen” geklickt haben, befinden Sie sich auf der Transaktionsseite. Hier finden Sie alle Informationen zum Austausch. Wir empfehlen dringend, diese zu überprüfen, um die Genauigkeit zu gewährleisten und fehlerhafte Transaktionen zu vermeiden!",
  "p-beginners-guide-completing-transaction-description2":
    "Auf der Transaktionsseite finden Sie auch einen Chat mit dem Verkäufer. Darin können Sie die Bedingungen des Austauschs oder andere für Sie interessante Informationen klären.",
  "p-beginners-guide-completing-transaction-description3":
    "Im aktiven Transaktionsfenster:",
  "p-beginners-guide-completing-transaction-step1":
    "Überprüfen Sie die Bedingungen und Details des Austauschs.",
  "p-beginners-guide-completing-transaction-step2":
    "Nehmen Sie die Überweisung auf die vom Verkäufer angegebene Zahlungsmethode vor.",
  "p-beginners-guide-completing-transaction-step3":
    "Bestätigen Sie die Überweisung, indem Sie auf die Schaltfläche “Zahlung wurde gesendet” klicken.",
  "p-beginners-guide-completing-transaction-step4":
    "Warten Sie auf die Zahlungsbestätigung des Verkäufers und die Überweisung der Mittel auf Ihr Konto.",
  "p-beginners-guide-completing-transaction-step5":
    "Hinterlassen Sie eine Bewertung der Transaktion: Bewertungen helfen Benutzern zu verstehen, mit wem sie zusammenarbeiten sollen und wen sie vermeiden sollten. Wir schätzen Ihre Bemühungen, eine günstige Atmosphäre auf unserer Plattform zu schaffen!",
  "p-beginners-guide-active-transactions-note1":
    "Sie finden Ihre aktiven Transaktionen im P2P-Handelsbereich unter dem Tab “Aufträge”.",
  "p-beginners-guide-active-transactions-note2":
    "Im Streitfall können Sie 30 Minuten nach Bestätigung der Zahlung einen Streitfall eröffnen. Die Schaltfläche dafür erscheint nach Ablauf des Timers auf der Transaktionsseite.",
  "p-beginners-guide-active-transactions-note3":
    "Wenn Sie sich entschieden haben, Währung zu verkaufen und ein Verkaufsangebot erstellt haben, warten Sie, bis jemand darauf reagiert. Wenn dies der Fall ist, erhalten Sie eine Benachrichtigung.",
  "p-beginners-guide-active-transactions-note4":
    "Nachdem ein Käufer gefunden wurde, fahren Sie mit dem Austausch fort.",
  "p-beginners-guide-active-transactions-note5":
    "Überprüfen Sie die Bedingungen und Details des Austauschs.",
  "p-beginners-guide-active-transactions-note6":
    "Überprüfen Sie, ob die Zahlung vom Käufer vorgenommen wurde.",
  "p-beginners-guide-active-transactions-note7":
    "Bestätigen Sie den Zahlungseingang, indem Sie auf die Schaltfläche “Zahlung erhalten” klicken.",
  "p-beginners-guide-active-transactions-note8":
    "Senden Sie den vereinbarten Betrag an die vom Käufer angegebene Empfangsmethode.",
  "p-beginners-guide-ending-note1":
    "Fertig! Sie haben erfolgreich Ihre erste Transaktion auf Solid Swap abgeschlossen. Wir hoffen, dass Ihnen dieser Leitfaden geholfen hat, alle Schritte ohne Schwierigkeiten zu durchlaufen.",
  "p-beginners-guide-ending-note2":
    "Denken Sie immer an die Sicherheit beim Austausch von Kryptowährungen. Überprüfen Sie immer die Bedingungen des Deals und den Ruf des Verkäufers, verwenden Sie die Zwei-Faktor-Authentifizierung und seien Sie vorsichtig beim Überweisen von Geldern. Wenn Sie Fragen oder Probleme haben, steht Ihnen unser Support-Team jederzeit zur Verfügung.",
  "p-beginners-guide-ending-note3":
    "Danke, dass Sie sich für Solid Swap entschieden haben. Wir wünschen Ihnen erfolgreiche und sichere Transaktionen!",
  "p-beginners-guide-ending-note4":
    "Wenn Sie Fragen oder Probleme haben, steht Ihnen unser Support-Team jederzeit zur Verfügung.",
  "p-beginners-guide-ending-note5":
    "Danke, dass Sie sich für Solid Swap entschieden haben. Wir wünschen Ihnen erfolgreiche und sichere Transaktionen!",
};
