export default {
  "c-offers-table-loginWarning":
    "Sie müssen sich anmelden, um einen Auftrag zu erstellen",
  "c-offers-table-toggleError":
    "Etwas ist beim Umschalten des Angebots schiefgelaufen",
  "c-offers-table-insufficientBalance":
    "Sie haben nicht genügend Guthaben, um dieses Angebot zu aktivieren",
  "c-offers-table-goToWallet": "zur Brieftasche gehen",
  "c-offers-table-seller": "Verkäufer",
  "c-offers-table-buyer": "Käufer",
  "c-offers-table-price": "Preis",
  "c-offers-table-availableLimit": "Verfügbares Limit",
  "c-offers-table-available": "Verfügbar",
  "c-offers-table-offerLimits": "Angebotslimits",
  "c-offers-amount-unlimited": "Angebotsmenge ist unbegrenzt",
  "c-offers-table-paymentOptions": "Zahlungsmethoden",
  "c-offers-table-more": "mehr",
  "c-offers-table-active": "aktiv",
  "c-offers-table-disabled": "deaktiviert",
  "c-offers-table-edit": "bearbeiten",
  "c-offers-table-delete": "löschen",
  "c-offers-table-buy": "Kaufen",
  "c-offers-table-sell": "Verkaufen",
};
