export default {
  "c-widget-news-moreNews": "weitere Nachrichten",

  "c-widget-Search-crypto": "Krypto:",
  "c-widget-Search-fiat": "Fiat:",
  "c-widget-Search-amount": "Betrag zu",
  "c-widget-Search-send": "senden",
  "c-widget-Search-recieve": "empfangen",
  "c-widget-Search-buy": "kaufen",
  "c-widget-Search-sell": "verkaufen",
  "c-widget-Search-find": "Finden",
  "c-widget-Search-selling-offers": "Verkaufsangebote",
  "c-widget-Search-selling": "verkaufen",
  "c-widget-Search-buying-offers": "Kaufangebote",
  "c-widget-Search-buying": "kaufen",
  "c-widget-Search-price": "Preis",
  "c-widget-Search-available": "verfügbar",
  "c-widget-Search-nothing-found":
    "nichts gefunden, versuchen Sie andere Optionen zu finden",
  "c-widget-Search-close": "schließen",
  "c-widget-Search-show-more": "mehr anzeigen",

  "c-widget-Stats-total-users": "Gesamtbenutzer",
  "c-widget-Stats-av-reg-per-day": "durchschn. Reg. pro Tag",
  "c-widget-Stats-daily-active-users": "Täglich aktive Benutzer",
  "c-widget-Stats-daily-active-users-tip":
    "Anzahl der Benutzer, die sich an einem Tag in das Produkt eingeloggt haben",
  "c-widget-Stats-than-yesterday": "als gestern",
  "c-widget-Stats-total-orders": "Gesamtaufträge",
  "c-widget-Stats-av-orders-per-day": "durchschn. Aufträge pro Tag",
  "c-widget-Stats-total-offers": "Gesamtangebote",
  "c-widget-Stats-new-offers-24h": "neue Angebote in 24h",

  "c-widget-TipTap-client-toggle-fullscreen": "Vollbild umschalten",
  "c-widget-TipTap-client-upload-image": "Bild hochladen",
  "c-widget-TipTap-client-drop-file-here": "Datei hier ablegen oder",
  "c-widget-TipTap-client-click-to-upload": "klicken, um hochzuladen",
  "c-widget-TipTap-client-jpg-png-files":
    "JPG/PNG-Dateien mit einer Größe von weniger als 2 MB",
  "c-widget-TipTap-client-heading-1": "Überschrift 1",
  "c-widget-TipTap-client-heading-2": "Überschrift 2",
  "c-widget-TipTap-client-heading-3": "Überschrift 3",
  "c-widget-TipTap-client-paragraph": "Absatz",
  "c-widget-TipTap-client-image": "Bild",
  "c-widget-TipTap-client-divider": "Trennlinie",
  "c-widget-TipTap-client-break": "Umbruch",
  "c-widget-TipTap-client-clear-marks": "Markierungen löschen",
  "c-widget-TipTap-client-please-input-link": "Bitte Link eingeben",
  "c-widget-TipTap-client-tip": "Tipp",
  "c-widget-TipTap-client-ok": "OK",
  "c-widget-TipTap-client-cancel": "Abbrechen",
  "c-widget-TipTap-client-input-canceled": "Eingabe abgebrochen",
  "c-widget-TipTap-client-picture-format":
    "Das Bild muss im JPG/PNG-Format vorliegen!",
  "c-widget-TipTap-client-picture-size":
    "Die Bildgröße darf 2 MB nicht überschreiten!",
};
