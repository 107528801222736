export default {
  "c-offers-editform-closeConfirmation": "Are you sure to close this dialog?",
  "c-offers-editform-percentError":
    "Percent % value should be between -99 and 99",
  "c-offers-editform-createError": "Error while creating offer",
  "c-offers-editform-createSuccess": "Offer created successfully!",
  "c-offers-editform-updateSuccess": "Offer updated successfully!",
  "c-offers-editform-typeRequired": "Please select type of the order",
  "c-offers-editform-currencyRequired": "Please select currency",
  "c-offers-editform-priceTypeRequired": "Please select price type",
  "c-offers-editform-priceValRequired": "Please enter price value",
  "c-offers-editform-paymentOptionsRequired": "Please select payment options",
  "c-offers-editform-minLimitRequired": "Please enter min. amount",
  "c-offers-editform-minLimitError":
    "Min. amount should be greater than {minAmount}",
  "c-offers-editform-maxLimitRequired": "Please enter max. amount",
  "c-offers-editform-maxLimitError":
    "Max. amount should not be lower than min. limit",
  "c-offers-editform-offerAmountRequired": "Please enter offer amount",
  "c-offers-editform-termsOfUseRequired": "Please enter terms of use",
  "c-offers-editform-editTitle": "Edit your offer",
  "c-offers-editform-addTitle": "Add new offer",
  "c-offers-editform-offerType": "Offer type",
  "c-offers-editform-sell": "sell",
  "c-offers-editform-buy": "buy",
  "c-offers-editform-selling": "Selling",
  "c-offers-editform-buying": "Buying",
  "c-offers-editform-mainCurrency": "Main currency",
  "c-offers-editform-selectCurrency": "select currency",
  "c-offers-editform-forCurrency": "...for",
  "c-offers-editform-currentRate":
    "current rate: 1 {currencyIn} = {rate} {currencyOut}",
  "c-offers-editform-price": "Price",
  "c-offers-editform-priceType": "Price type",
  "c-offers-editform-selectPriceType": "select price type",
  "c-offers-editform-percentValue": "Percent value",
  "c-offers-editform-relativeValue": "Relative value",
  "c-offers-editform-fixedValue": "Fixed value",
  "c-offers-editform-priceTypesExplained": "Price types explained:",
  "c-offers-editform-percent": "Percent",
  "c-offers-editform-percentExplanation":
    "The price is adjusted based on a percentage difference from the current rates. If a user enters a percentage, the final price will be calculated by applying this percentage as an increase or decrease to the current rates.",
  "c-offers-editform-relative": "Relative",
  "c-offers-editform-relativeExplanation":
    "The price is adjusted by adding or subtracting a specific number from the current rates. The user enters this number, and it directly modifies the base price, making the final price either higher or lower by this exact amount.",
  "c-offers-editform-fixed": "Fixed",
  "c-offers-editform-fixedExplanation":
    "This is a straightforward price setting where the user sets the price directly. The price entered by the user will be the final price, without any adjustments based on current rates or percentages.",
  "c-offers-editform-pricePercent": "Price percent",
  "c-offers-editform-priceRelative": "Price relative",
  "c-offers-editform-priceFixed": "Price fixed",
  "c-offers-editform-enterPriceValue": "enter price value",
  "c-offers-editform-rate":
    "according to selected type and price fields your rate is: 1 {currencyIn} = {rate} {currencyOut}",
  "c-offers-editform-paymentOptions": "Payment options",
  "c-offers-editform-selectPaymentOptions": "select payment options",
  "c-offers-editform-noOptions": "No options available",
  "c-offers-editform-all": "All",
  "c-offers-editform-addOption": "Add payment option",
  "c-offers-editform-minLimit": "Minimum limit",
  "c-offers-editform-enterMinAmount": "min. amount",
  "c-offers-editform-maxLimit": "Maximum limit",
  "c-offers-editform-enterMaxAmount": "max. amount",
  "c-offers-editform-orderLimitsExplanation": "Order Limits Explanation",
  "c-offers-editform-orderLimitsExplanationTitle": "Order Limits Explanation",
  "c-offers-editform-orderLimitsExplanationText":
    "The Minimum Limit and Maximum Limit specify the range within which you can create an order. You cannot place a buy or sell order for crypto below the Minimum Limit or above the Maximum Limit.",
  "c-offers-editform-orderLimitsExplanationUSD":
    "Additionally, our platform enforces a minimum limit equivalent to {MIN_OFFER_AMOUNT_USD} USD for all offers, this is about {usdLimit} {currencyOut}",
  "c-offers-editform-amountToTrade": "Amount to trade",
  "c-offers-editform-offerAmount": "Offer amount",
  "c-offers-editform-offerAmount-unlimited": "unlimited",
  "c-offers-editform-enterOfferAmount": "amount",
  "c-offers-editform-currentBalance":
    "My current balance: {balance} {currencyIn}",
  "c-offers-editform-offerAmountExplanation": "Offer Amount Explanation",
  "c-offers-editform-offerAmountExplanationTitle": "Offer Amount Explanation",
  "c-offers-editform-offerAmountExplanationText1":
    "The Offer Amount is the total amount of cryptocurrency you are willing to trade.",
  "c-offers-editform-offerAmountExplanationText2":
    "For selling offers, this is the amount of cryptocurrency you want to sell. For example: If you have 1000 USDT in your balance and want to trade only half, you can set the Offer Amount to 500 USDT. This ensures you will not sell more than 500 USDT. Note that all your funds remain in your account, and cancelling the offer will return them to your balance.",
  "c-offers-editform-offerAmountExplanationText3":
    "For buying offers, this amount indicates how much cryptocurrency you want to buy. If you set the Offer Amount to 1000 USDT, you will receive exactly that amount. balance.",
  "c-offers-editform-offerAmountExplanationText4":
    "Additionally, our platform charges a commission, which is covered by the seller. Therefore, if you are creating a SELL offer, the commission is on you. If you are creating a BUY offer, the commission will be on the counterparty who creates the order.",
  "c-offers-editform-termsOfUse": "Terms of use",
  "c-offers-editform-termsOfUseText":
    "Please fill in your 'Terms of Use' below. This section should outline the general terms and conditions you are willing to trade under. There is no need to provide your banking information or any other sensitive details here.",
  "c-offers-editform-termsOfUsePlaceholder": "write some description...",
  "c-offers-editform-cancel": "Cancel",
  "c-offers-editform-confirm": "Confirm",
};
