export default {
  "c-offers-table-loginWarning": "You need to login to create order",
  "c-offers-table-toggleError": "Something went wrong while toggling offer",
  "c-offers-table-insufficientBalance":
    "You don't have enough balance to activate this offer",
  "c-offers-table-goToWallet": "go to wallet",
  "c-offers-table-seller": "Seller",
  "c-offers-table-buyer": "Buyer",
  "c-offers-table-price": "Price",
  "c-offers-table-availableLimit": "Available Limit",
  "c-offers-table-available": "Available",
  "c-offers-table-offerLimits": "Offer limits",
  "c-offers-amount-unlimited": "offer amount is unlimited",
  "c-offers-table-paymentOptions": "Payment options",
  "c-offers-table-more": "more",
  "c-offers-table-active": "active",
  "c-offers-table-disabled": "disabled",
  "c-offers-table-edit": "edit",
  "c-offers-table-delete": "delete",
  "c-offers-table-buy": "Buy",
  "c-offers-table-sell": "Sell",
};
