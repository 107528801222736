export default {
  "p-registration-label-email": "Email",
  "p-registration-label-username": "Username",
  "p-registration-label-password": "Password",
  "p-registration-label-repeat-password": "Repeat password",
  "p-registration-head-title": "Registration | Create Your Solid Swap Account",
  "p-registration-head-description":
    "Sign up for a Solid Swap account to begin trading cryptocurrency securely and efficiently",
  "p-registration-head-keywords":
    "registration, create account, Solid Swap sign up, crypto trading registration",
  "p-registration-rules-username": "Please enter your username",
  "p-registration-rules-username-length":
    "Username length must be between 5 and 15 characters",
  "p-registration-rules-email": "Please enter your email",
  "p-registration-rules-password": "Please enter your password",
  "p-registration-rules-password-length":
    "Password length must be between 6 and 21 characters",
  "p-registration-rules-password-confirm": "Please confirm your password",
  "p-registration-rules-password-match": "Passwords do not match",
  "p-registration-rules-terms": "Please accept the terms of use",
  "p-registration-success-message":
    "Registration successful. Please check your email for a confirmation link.",
  "p-registration-error-default":
    "Oops, something went wrong during registration.",
  "p-registration-error-data": "Something wrong with the data you entered",
  "p-registration-error-account-exists":
    "Looks like you already have an account.",
  "p-registration-login-link": "Please log in",
  "p-registration-title": "Registration",
  "p-registration-email-placeholder": "Enter your email",
  "p-registration-username-placeholder": "Enter your username",
  "p-registration-password-placeholder": "Enter your password",
  "p-registration-password-confirm-placeholder": "Confirm your password",
  "p-registration-terms-message": "I agree to the",
  "p-registration-terms": "Terms of Use",
  "p-registration-and": "and",
  "p-registration-privacy": "Privacy Policy",
  "p-registration-continue": "Continue",
  "p-registration-success-title": "Successful!",
  "p-registration-welcome-title":
    "Join our vibrant community of crypto enthusiasts!",
  "p-registration-welcome-message-1":
    "Register now to unlock the full potential of peer-to-peer trading.",
  "p-registration-welcome-message-2":
    "Experience freedom, fairness, and the future of finance.",
  "p-registration-welcome-message-3":
    "Dive into the diverse marketplace of digital currencies where opportunity and innovation meet.",
};
