export default {
  "p-trade-action-buy-title":
    "Acheter de la cryptomonnaie | Achat de crypto sécurisé sur Solid Swap",
  "p-trade-action-sell-title":
    "Vendre de la cryptomonnaie | Vente de crypto sécurisée sur Solid Swap",
  "p-trade-action-buy-description":
    "Achetez de la cryptomonnaie en toute sécurité sur Solid Swap. Profitez de transactions rapides et de taux compétitifs pour les achats d'actifs numériques",
  "p-trade-action-sell-description":
    "Vendez votre cryptomonnaie en toute sécurité sur Solid Swap. Profitez de transactions rapides et de taux optimaux pour vos actifs numériques",
  "p-trade-action-buy-keywords":
    "acheter de la cryptomonnaie, achat de crypto, achat d'actifs sécurisé, trading de devises numériques",
  "p-trade-action-sell-keywords":
    "vendre de la cryptomonnaie, vente de crypto, vente d'actifs sécurisée, trading de devises numériques",
  "p-trade-action-nothing-found":
    "rien trouvé, essayez de trouver d'autres options",
  "p-trade-action-clear-filters": "Effacer tous les filtres",
};
