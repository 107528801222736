export default {
  "c-footer-menu-trade": "P2P-Handel",
  "c-footer-menu-news": "Nachrichten",
  "c-footer-menu-blog": "Blog",
  "c-footer-menu-faq": "FAQ",
  "c-footer-menu-beginnersGuide": "Anfängerleitfaden",
  "c-footer-menu-about": "Über",
  "c-footer-menu-manageCookies": "Cookies verwalten",
  "c-footer-menu-adminPanel": "Admin-Panel",

  "c-footer-mobilemenu-home": "Startseite",
  "c-footer-mobilemenu-trade": "Handel",
  "c-footer-mobilemenu-orders": "Aufträge",
  "c-footer-mobilemenu-wallet": "Brieftasche",
};
