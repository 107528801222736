export default {
  "p-exchange-tos-agreement-intro":
    "Cet Accord Utilisateur (ci-après dénommé 'l'Accord') est un accord électronique entre l'Utilisateur et le service SolidSwap et contient les règles pour travailler sur le site Web de SolidSwap à",
  "p-exchange-tos-agreement-intro-continued":
    "(ci-après dénommé 'le Site') et les termes d'accès aux services fournis par le Site. Avant de commencer à utiliser le Site, l'utilisateur du Site (ci-après dénommé 'l'Utilisateur') doit lire attentivement les conditions décrites et accepter cet Accord. Si l'Utilisateur n'accepte pas l'Accord et ne s'engage pas à respecter toutes les conditions de cet Accord, il doit immédiatement cesser d'utiliser le Site.",
  "p-exchange-tos-agreement-updates":
    "L'Administration du Site se réserve le droit, à sa seule discrétion, de mettre à jour et de modifier le texte de l'Accord sans en informer les Utilisateurs. La nouvelle version de l'Accord entre en vigueur immédiatement après sa publication sur le site. L'Utilisateur est tenu de surveiller lui-même les nouvelles versions de l'Accord. En continuant à utiliser le Site après l'entrée en vigueur des modifications, l'Utilisateur accepte automatiquement la nouvelle version de l'Accord. Si l'Utilisateur n'est pas d'accord avec les termes de la nouvelle version de l'Accord, il doit désactiver son compte, le cas échéant, et ne plus utiliser les services du service.",
  "p-exchange-tos-terms-definitions": "1. Termes et définitions",
  "p-exchange-tos-solid-swap-service":
    "1.1. Le service SolidSwap est une plateforme conçue pour effectuer des opérations de trading avec des actifs numériques virtuels.",
  "p-exchange-tos-user-definition":
    "1.2. L'Utilisateur est une personne physique enregistrée sur la Plateforme et ayant accepté les termes et conditions de cet Accord. L'Utilisateur confirme qu'il a atteint l'âge de 18 ans, que les lois de son pays de résidence n'interdisent pas l'utilisation de ce Service, et que l'Utilisateur n'envisage pas de mener des activités illégales par l'intermédiaire des services SolidSwap.",
  "p-exchange-tos-title-sign":
    "1.3. Le signe de titre est une unité conventionnelle du système de paiement des devises électroniques et autres, correspondant aux calculs des systèmes électroniques et indiquant le montant des droits correspondant à l'accord des différents systèmes de paiement et de son propriétaire.",
  "p-exchange-tos-payment-system":
    "1.4. Un système de paiement est un service en ligne qui permet de payer des biens et services par transfert d'argent électronique.",
  "p-exchange-tos-service-operations":
    "1.5. Services du service - opérations d'entrée et de retrait des devises électroniques, ainsi que d'autres services, dont les informations sont publiées sur le site Web du Service.",
  "p-exchange-tos-payment-definition":
    "1.6. Le paiement est un transfert d'argent effectué à l'aide d'un système de paiement.",
  "p-exchange-tos-otc-platform":
    "1.7. La Plateforme OTC est une plateforme de trading pour trouver des contreparties et effectuer des transactions de gré à gré.",
  "p-exchange-tos-personal-data":
    "1.8. Données personnelles - informations identifiant une personne, telles que le nom, l'adresse, l'adresse e-mail, le numéro de téléphone portable, les informations d'enchères et les coordonnées bancaires. Le terme 'Données personnelles' n'inclut pas les données anonymes et/ou agrégées qui ne permettent pas d'identifier un Utilisateur spécifique.",
  "p-exchange-tos-agreement-definition":
    "1.9. Accord - cet Accord Utilisateur et ses parties intégrantes, en tenant compte de tous les ajouts et/ou modifications qui peuvent être effectués de la manière déterminée par leurs termes.",
  "p-exchange-tos-website-definition":
    "1.10. Site Web - un ensemble d'informations, de textes, d'éléments graphiques, de design, d'images, de photos et de vidéos, et d'autres résultats d'activités intellectuelles, ainsi qu'un ensemble de programmes informatiques contenus dans un système d'information qui garantissent la disponibilité de ces informations à",
  "p-exchange-tos-website-continued":
    ", y compris tout sous-domaine, et si le domaine ou le matériel du Site doit être modifié pour quelque raison que ce soit, il inclut le Site après ces modifications.",
  "p-exchange-tos-intellectual-property":
    "1.11. Propriété intellectuelle - tous les matériaux publiés sur le Site, ainsi que tous les Services fournis par le Service, sont la propriété du Service et ne peuvent pas être utilisés par des tiers : publication, distribution, duplication.",
  "p-exchange-tos-application-definition":
    "1.12. Demande - l'Utilisateur fait une offre d'achat ou de vente d'actifs numériques virtuels en utilisant les Services fournis par le Service.",
  "p-exchange-tos-electronic-document":
    "1.13. Document électronique - informations sous forme électronique et numérique.",
  "p-exchange-tos-suspicious-traffic":
    "1.14. Trafic suspect - un ensemble d'actions, de transactions, d'actifs, etc., susceptibles de violer les politiques de LBC/CTF et de LAC.",
  "p-exchange-tos-verification-procedures":
    "1.15. Procédures de vérification (KYC) - procédures de vérification dans le cadre des normes de lutte contre le blanchiment d'argent - la politique 'Know Your Customer' (KYC).",
  "p-exchange-tos-persons-subject-restrictions":
    "1.16. Personnes soumises à des restrictions - désigne toute personne aux États-Unis et/ou toute personne incluse dans la Liste des Citoyens de Catégories Spéciales ou des Personnes Bloquées du Département du Trésor des États-Unis, la Liste Consolidée des Sanctions Financières de l'UE ou la Liste des Sanctions du Royaume-Uni, ainsi que les citoyens et résidents des juridictions limitées (point 1.17).",
  "p-exchange-tos-limited-jurisdiction":
    "1.17. Juridiction limitée - les juridictions limitées incluent : États-Unis d'Amérique, Afghanistan, Bosnie-Herzégovine, République Populaire Démocratique de Corée, République Démocratique du Congo, Érythrée, Éthiopie, Guyana, Iran, Irak, République Démocratique Populaire Lao, Libye, Somalie, Sri Lanka, Soudan, Syrie, Trinité-et-Tobago, Tunisie, Ouganda, Vanuatu, Ukraine, Royaume-Uni, Chine Continentale, Singapour, Hong Kong, Canada, Cuba, Ouzbékistan, Crimée, France, Sébastopol, régions de Donetsk et de Lougansk.",
  "p-exchange-tos-introduction": "2. Introduction",
  "p-exchange-tos-introduction-text":
    "2.1. Cet accord régit la relation entre l'Utilisateur et le Service dans le cadre des services fournis par le Service à l'Utilisateur, et annule tous les accords précédents entre le Service et l'Utilisateur à ce sujet.",
  "p-exchange-tos-subject-agreement": "3. Objet de l'accord",
  "p-exchange-tos-subject-agreement-services":
    "3.1. L'objet de cet Accord est les Services fournis par le Service, qui sont décrits sur le site Web du Service.",
  "p-exchange-tos-subject-agreement-restrictions":
    "3.2. Le Service propose ses services à tous les Utilisateurs intéressés, à l'exception des personnes soumises à des restrictions (paragraphe 1.16).",
  "p-exchange-tos-payment-systems-responsibility":
    "3.3. Les systèmes de paiement et/ou les institutions financières sont seuls responsables des fonds qui leur sont confiés par l'Utilisateur. Le Service ne peut pas être partie à un accord entre le Système de Paiement et/ou une institution financière et l'Utilisateur et n'est en aucun cas responsable de l'utilisation incorrecte ou non autorisée du Système de Paiement par l'Utilisateur, ainsi que de l'abus par l'Utilisateur des fonctionnalités du Système de Paiement. Les droits et obligations mutuels de l'Utilisateur et du Système de Paiement et/ou de l'institution financière sont régis par les accords pertinents.",
  "p-exchange-tos-operation-cannot-cancel":
    "3.4. Toute opération initiée pour échanger les fonds de l'Utilisateur, ainsi que toute autre opération proposée par le Service à l'Utilisateur, ne peut pas être annulée par l'Utilisateur.",
  "p-exchange-tos-rights-obligations": "4. Droits et obligations des Parties",
  "p-exchange-tos-suspend-operation-authorities":
    "4.1. Le Service a le droit de suspendre ou d'annuler une opération en cours si les autorités autorisées reçoivent des informations sur l'illégalité de la propriété des fonds par l'Utilisateur et/ou d'autres informations rendant impossible la fourniture de services à l'Utilisateur par le Service.",
  "p-exchange-tos-suspend-refund-operation":
    "4.2. Le Service a le droit de suspendre, de refuser un remboursement, d'annuler une opération en cours si l'Utilisateur enfreint les termes de cet Accord.",
  "p-exchange-tos-cancel-operation-title-return":
    "4.3. Le Service a le droit d'annuler l'opération en cours et de retourner les caractères de titre saisis par l'Utilisateur sans en expliquer les raisons.",
  "p-exchange-tos-prevent-illegal-activities":
    "4.4. Le Service s'engage à prendre toutes les mesures possibles et disponibles pour empêcher les tentatives de commerce illégal, de fraude financière et de blanchiment d'argent en utilisant les services du Service. Ces actions incluent : fournir toute l'assistance possible aux agences de la force publique pour la recherche et la capture des terroristes financiers engagés dans des activités de blanchiment d'argent illégales ; fournir des informations aux autorités compétentes conformément à la législation en vigueur concernant le traitement du Service ; améliorer le Service pour empêcher l'utilisation directe ou indirecte du Service dans des activités contraires à la législation visant à lutter contre le commerce illégal, la fraude financière et le blanchiment d'argent.",
  "p-exchange-tos-suspicious-traffic-rights":
    "4.5. En cas de détection de trafic suspect de la part de l'Utilisateur, le Service se réserve le droit de transmettre toutes les informations à la demande des agences de la force publique concernant l'identité, la demande d'échange et la devise reçue de l'Utilisateur.",
  "p-exchange-tos-accept-agreement":
    "4.6. En utilisant les services fournis sur le site, l'Utilisateur accepte et confirme qu'il est d'accord avec ces Règles et la politique AML/CTF et KYC.",
  "p-exchange-tos-user-guarantee":
    "4.7. En utilisant les services fournis sur le site, l'Utilisateur garantit qu'il fournit des informations complètes, véridiques et à jour à son sujet, que l'utilisation des services du service est effectuée uniquement à des fins personnelles qui ne violent pas les lois du pays dans lequel se trouve l'Utilisateur, et s'engage également à mettre à jour les données en temps opportun pour maintenir leur pertinence.",
  "p-exchange-tos-user-confidentiality":
    "4.8. L'Utilisateur garantit la confidentialité des données d'enregistrement et l'accès à son compte, et assume également l'entière responsabilité de toutes les actions effectuées sous son compte, y compris en cas de piratage ou d'accès non autorisé.",
  "p-exchange-tos-report-security-breach":
    "4.9. L'Utilisateur s'engage à signaler rapidement toute violation de la sécurité de son compte.",
  "p-exchange-tos-block-account":
    "4.10. Le Service a le droit de bloquer le compte de l'Utilisateur en cas de suspicion d'actions illégales, de suspicion de piratage de compte ou en cas de violation des règles du Service. Le compte de l'Utilisateur est bloqué sans droit de restauration si l'Utilisateur crée des obstacles au fonctionnement du Service.",
  "p-exchange-tos-rightful-owner":
    "4.11. En acceptant les termes de cet Accord, l'Utilisateur garantit qu'il est le propriétaire légitime des fonds et/ou des crypto-monnaies.",
  "p-exchange-tos-understand-service-risks":
    "4.12. En acceptant cet Accord Utilisateur, l'Utilisateur confirme qu'il comprend les principes du Service et assume l'entière responsabilité de tous les risques et pertes liés à l'utilisation du Service.",
  "p-exchange-tos-consent-data-processing":
    "4.13. L'Utilisateur donne son consentement au Service pour le traitement des données personnelles fournies lors de l'inscription sur le site, lors de la procédure de vérification, ainsi que lors de la collecte de toute information sur le client sur le site.",
  "p-exchange-tos-personal-data-includes":
    "4.14. Les données personnelles incluent :",
  "p-exchange-tos-name": "Nom de famille, Prénom, Patronyme ;",
  "p-exchange-tos-photo-passport": "photo/scan du passeport ;",
  "p-exchange-tos-other-id-documents":
    "autres documents d'identification ; (individuellement dans chaque cas)",
  "p-exchange-tos-video-selfie": "selfie vidéo avec passeport en main ;",
  "p-exchange-tos-email": "adresse e-mail ;",
  "p-exchange-tos-phone-number":
    "numéro de téléphone portable (qui peut être utilisé pour contacter le client) ;",
  "p-exchange-tos-wallet-details":
    "détails des portefeuilles utilisés ; (individuellement dans chaque cas) ;",
  "p-exchange-tos-card-number":
    "numéro de carte et/ou compte bancaire ; (individuellement dans chaque cas) ;",
  "p-exchange-tos-transactions-wallets":
    "transactions et adresses de portefeuille.",
  "p-exchange-tos-processing-personal-data":
    "4.15. Le traitement des données personnelles signifie l'enregistrement, la systématisation, l'accumulation, le stockage, la clarification (mise à jour, modification), l'extraction, l'utilisation, le transfert (distribution, fourniture, accès), y compris transfrontalier, la dépersonnalisation, le blocage, la suppression, la destruction des données personnelles qui ne relèvent pas des catégories spéciales, dont le traitement nécessite le consentement écrit de l'Utilisateur.",
  "p-exchange-tos-processing-purpose":
    "4.16. Le traitement des données personnelles est effectué afin que les Parties remplissent leurs obligations en vertu de l'Accord, enregistrent l'Utilisateur sur le Site, fournissent à l'Utilisateur une assistance technique, soumettent des demandes, fournissent des Services, examinent des réclamations et des plaintes, envoient des messages d'information et autres à l'adresse e-mail de l'Utilisateur.",
  "p-exchange-tos-fincen-rule":
    "4.17. Conformément à la règle finale du Financial Crimes Investigation Network (FinCEN) en vertu de l'Article 31 du Code des Règles Fédérales 1010.100(ff), qui oblige chaque entreprise étrangère fournissant des services de règlement et de liquidité à désigner un représentant du service résidant aux États-Unis en tant qu'agent pour mener à bien le procès conformément aux exigences de l'Association BSA et à ses règlements, SolidSwap ne fournit pas de services d'échange de devises électroniques aux citoyens américains et aux utilisateurs résidant aux États-Unis.",
  "p-exchange-tos-legal-prohibited":
    "4.18. Les services fournis par SolidSwap peuvent être légalement interdits dans certaines juridictions. En acceptant les conditions d'utilisation de SolidSwap, vous confirmez que vous n'êtes pas résident de ces juridictions.",
  "p-exchange-tos-aml-kyc-checks":
    "4.19. Le Service a le droit d'effectuer des contrôles AML et KYC pour se conformer à la 'Cinquième Directive sur la Lutte contre le Blanchiment d'Argent et le Financement du Terrorisme (5AMLD)'",
  "p-exchange-tos-kyt-aml-violation":
    "4.19.1. Si, selon les résultats de la vérification KYT/AML des transactions/actifs en crypto-monnaies, une violation est détectée de la 'politique LBC/CTF et KYC', alors les fonds seront bloqués et une vérification utilisateur supplémentaire (KYC) sera demandée conformément à la clause 8.5 de cet accord, ainsi qu'une preuve de la légalité de l'origine des fonds.",
  "p-exchange-tos-kyt-aml-non-return":
    "4.19.2. Si, selon les résultats de la vérification KYT/AML des transactions/actifs en crypto-monnaies, une violation est révélée de la 'politique LBC/CTF et KYC' et/ou la situation relève de la clause 9.4.2 de cet accord, alors les actifs peuvent ne pas être soumis à un échange et à un retour.",
  "p-exchange-tos-services-service": "5. Services du Service",
  "p-exchange-tos-order-services":
    "5.1. La commande des services du Service et la réception d'informations sur l'état d'avancement de la transaction par l'Utilisateur se font exclusivement par contact avec un représentant du Service.",
  "p-exchange-tos-service-internal-schedule":
    "5.2. Le Service propose ses services selon le calendrier interne établi du service.",
  "p-exchange-tos-questions-complaints":
    "5.3. Les questions et plaintes concernant les demandes sont acceptées dans un délai de 24 heures à compter de l'enregistrement. Après 24 heures, les demandes d'échange sont considérées comme correctement exécutées ou non payées, selon leur statut au moment de l'expiration de la période indiquée.",
  "p-exchange-tos-suspicious-actions":
    "5.4. En cas d'actions suspectes de la part du client, ainsi qu'en cas d'utilisation d'actifs suspects par l'utilisateur, le service peut retarder le paiement et exiger une vérification d'identité supplémentaire et une analyse interne des fonds utilisés par l'utilisateur.",
  "p-exchange-tos-right-to-refuse":
    "5.5. Le Service se réserve le droit de refuser l'échange unilatéralement.",
  "p-exchange-tos-restricted-citizens":
    "5.6. Les services fournis par SolidSwap sont interdits aux citoyens soumis à des restrictions (paragraphe 1.16).",
  "p-exchange-tos-cost-services": "6. Coût des services",
  "p-exchange-tos-tariffs":
    "6.1. Les tarifs sont déterminés par la direction du Service et publiés sur le site Web du Service. La direction du Service a le droit de modifier les tarifs sans préavis supplémentaire.",
  "p-exchange-tos-expectations-tariffs":
    "6.2. Le Service n'est pas responsable des attentes du client concernant les changements dans les plans tarifaires et le coût des services.",
  "p-exchange-tos-guarantees-responsibilities":
    "7. Garanties et responsabilités des parties",
  "p-exchange-tos-guarantee-fulfillment":
    "7.1. Le Service garantit l'exécution de ses obligations envers l'Utilisateur uniquement dans les limites des montants confiés au Service par l'Utilisateur pour effectuer l'opération dans les termes de la documentation du service.",
  "p-exchange-tos-confidentiality-guarantee":
    "7.2. Le Service garantit et assure la confidentialité des informations sur l'Utilisateur et ses opérations. Le Service peut fournir ces informations uniquement à la demande des autorités gouvernementales autorisées, des fonctionnaires ou des représentants des systèmes de paiement, s'il existe des motifs légitimes pour cela.",
  "p-exchange-tos-efforts-technical-issues":
    "7.3. Le Service fait tout son possible pour assurer le bon fonctionnement du Service, mais ne garantit pas l'absence de problèmes techniques entraînant une suspension totale ou partielle du Service. Le Service n'est pas responsable des pertes, des profits perdus et d'autres coûts encourus par l'Utilisateur en raison de l'impossibilité d'accéder au site et aux services du Service.",
  "p-exchange-tos-damage-responsibility":
    "7.4. Le Service n'est pas responsable de tout dommage causé à l'Utilisateur, même si sa possibilité était connue à l'avance. Le Service n'est pas responsable de l'obtention d'informations sur les activités de l'Utilisateur par des tiers en cas de piratage du système et de récupération de données par des tiers.",
  "p-exchange-tos-no-responsibility-losses":
    "7.5. Le Service n'assume aucune responsabilité pour les pertes, les profits perdus et d'autres coûts de l'Utilisateur résultant des attentes erronées de l'Utilisateur concernant les tarifs du Service, la rentabilité des transactions et d'autres facteurs subjectifs.",
  "p-exchange-tos-aml-risk-score":
    "7.6. Le Service n'est pas responsable de la modification du score de risque (AML Risk Score) des portefeuilles clients après avoir effectué des échanges.",
  "p-exchange-tos-compensation":
    "7.7. En visitant le site Web du Service, en utilisant les services du Service et en effectuant un échange, l'Utilisateur garantit la compensation des pertes du Service (société de gestion, dirigeants et employés) en cas de poursuites ou de réclamations directement ou indirectement liées à l'utilisation des services du Service par l'Utilisateur.",
  "p-exchange-tos-not-responsible-communication":
    "7.8. L'Utilisateur accepte que le Service ne soit pas responsable en cas d'interruption de la communication, d'interruption de l'Internet, ainsi qu'en cas de force majeure.",
  "p-exchange-tos-guarantee-owner-funds":
    "7.9. L'Utilisateur garantit qu'il est le propriétaire ou qu'il dispose de motifs légitimes pour disposer des fonds utilisés dans ses transactions, virements bancaires, etc.",
  "p-exchange-tos-no-falsify-communication":
    "7.10. L'Utilisateur s'engage à ne pas falsifier les flux de communication liés au fonctionnement du Service.",
  "p-exchange-tos-force-majeure": "8. Force majeure",
  "p-exchange-tos-force-majeure-definition":
    "8.1. Les Parties sont dégagées de toute responsabilité en cas de non-exécution totale ou partielle des obligations prévues par le présent Accord si la non-exécution des obligations est le résultat d'actes de force majeure, à savoir : incendie, inondation, tremblement de terre, actes terroristes, changement de pouvoir, troubles civils, attaques DDOS contre le service, ainsi que la non-fonctionnement des systèmes de paiement, des systèmes d'approvisionnement en énergie, des réseaux de communication et des fournisseurs de services Internet.",
  "p-exchange-tos-error-during-payment":
    "8.2. En cas d'erreur lors du paiement de l'échange, l'Utilisateur recevra une notification concernant le statut de la demande et/ou à l'adresse e-mail indiquée.",
  "p-exchange-tos-payment-after-deadline":
    "8.3. Si la demande est payée après le délai imparti pour le paiement de la demande, le service se réserve le droit de rembourser le paiement de la demande, moins la commission réseau et les coûts de service, à l'Utilisateur ou de recalculer à sa discrétion.",
  "p-exchange-tos-not-responsible-assets-fiat":
    "8.3.1 Le Service n'est pas responsable des actifs envoyés par l'utilisateur pour l'échange de fiat 'donné', de cryptomonnaie 'reçue' après l'expiration du délai de paiement de la demande.",
  "p-exchange-tos-not-responsible-assets-anonymous":
    "8.3.2 Le Service n'est pas responsable des actifs envoyés par l'utilisateur en utilisant une méthode anonyme qui cache l'adresse de l'expéditeur, car ils ne seront pas crédités sur le solde du portefeuille du service d'échange. Dans ce cas, l'échange et le remboursement ne seront pas possibles.",
  "p-exchange-tos-deviation-exchange-rate":
    "8.4. Si le taux de la demande s'écarte de plus de 3% du taux de change sur la bourse Bybit/Binance, y compris en cas de dysfonctionnement technique du site, le service d'échange a le droit de recalculer la demande au taux actuel sur le site ou d'émettre un remboursement à l'Utilisateur, moins les coûts du service et la commission réseau.",
  "p-exchange-tos-invalid-exchanges":
    "8.5. Dans le cas où l'Utilisateur a effectué un échange intentionnellement, involontairement, ou en raison d'une défaillance technique (y compris du côté du service SolidSwap), à la suite des actions d'intrus ou pour d'autres raisons, et que cet échange a causé des pertes au service SolidSwap, alors que l'écart par rapport au taux du marché dans la demande exécutée dépassait 3% du taux de change sur la bourse Bybit/Binance (cette règle s'applique à tout côté de l'échange), les demandes effectuées dans les conditions susmentionnées sont considérées comme invalides. Le Service a le droit de compenser les dommages encourus en retenant tout fonds futur de l'Utilisateur dans le cadre de l'interaction avec le Service.",
  "p-exchange-tos-suspicious-assets":
    "8.6. En cas de détection d'actifs/fonds suspects violant l'accord LBC du Service et d'actions suspectes/frauduleuses de la part de l'Utilisateur, la transaction est bloquée par le Service de Sécurité pour une durée maximale de 180 jours pour enquête ultérieure.",
  "p-exchange-tos-unblocking-procedure":
    "Pour commencer la procédure de déblocage, il est nécessaire de passer par une vérification simplifiée de l'utilisateur (KYC) conformément à la clause 4.19 de cet accord.",
  "p-exchange-tos-send-photo-passport":
    "Une photo ou un scan de votre passeport, ou d'autres documents d'identification (au choix) ;",
  "p-exchange-tos-send-phone-number":
    "Un numéro de téléphone portable pouvant être utilisé pour vous contacter si nécessaire (facultatif) ;",
  "p-exchange-tos-send-video-selfie":
    "Selfie vidéo avec un document d'identité ;",
  "p-exchange-tos-record-video":
    "Enregistrez une courte vidéo de bonne qualité sans interruptions, en énonçant clairement les données suivantes :",
  "p-exchange-tos-record-full-name":
    "Nom complet, série et numéro du document d'identité, en le tenant à côté de la personne ;",
  "p-exchange-tos-service-name":
    "Le nom de notre service et la date de l'enregistrement vidéo ;",
  "p-exchange-tos-application-number":
    "Le numéro de la demande et la date de sa création ;",
  "p-exchange-tos-show-wallet":
    "Montrez le portefeuille /compte/ autre depuis lequel exactement le paiement/transaction a été effectué, la devise et le montant. Le nom et l'adresse (URL) du site doivent être lisibles si la transaction a été effectuée à partir d'une application mobile.",
  "p-exchange-tos-write-explanation":
    "Rédigez librement à la main sur un morceau de papier une brève explication de la légalité de l'origine des fonds, ainsi que du fait que vous nous avez envoyé depuis votre portefeuille (adresse) à votre portefeuille (adresse et hash de la transaction), et que vous êtes le propriétaire des actifs, non une tierce partie. Écrivez l'adresse à laquelle vous demandez le retour des actifs.",
  "p-exchange-tos-specify-address":
    "Indiquez l'adresse/compte du portefeuille où vous devez retourner les actifs.",
  "p-exchange-tos-information-deadline":
    "Nous attendons les informations ci-dessus de votre part au plus tard 48 heures après réception de cet e-mail.",
  "p-exchange-tos-no-unblocking-without-data":
    "En l'absence des données demandées, SolidSwap ne pourra pas commencer la procédure de déblocage de vos actifs.",
  "p-exchange-tos-blocking-period-expiration":
    "8.6.1. Après l'expiration de la période de blocage (180 jours), à compter de la date de passage de la vérification d'identité (KYC), l'utilisateur doit contacter lui-même le service client par e-mail officiel, au plus tard dans les 30 jours.",
  "p-exchange-tos-incorrect-details":
    "8.7. Le Service n'assume aucune responsabilité financière envers l'Utilisateur si celui-ci a fourni des informations incorrectes dans la demande pour recevoir des fonds du Service, et le Service a réglé avec l'Utilisateur en envoyant un paiement conformément aux informations fournies dans la demande.",
  "p-exchange-tos-payment-delay":
    "8.8. En cas de panne dans la banque / système de paiement tiers, les paiements peuvent être retardés jusqu'à 5 jours ouvrables, pour des raisons indépendantes de la volonté du service.",
  "p-exchange-tos-deposit-outside-application":
    "8.9. Un dépôt effectué en dehors de la demande peut ne pas être crédité ou perdu. Le paiement des fonds envoyés en utilisant des informations obsolètes est effectué avec une commission de 5%.",
  "p-exchange-tos-non-current-details":
    "8.10. Le Service n'est pas responsable si l'Utilisateur a fourni des informations de paiement différentes de celles spécifiées dans la direction de l'échange. Le paiement des fonds sur des informations bancaires obsolètes est effectué avec une commission de 5% dans un délai de 48 heures.",
  "p-exchange-tos-fiat-exchange": "9.1. Échange Fiat",
  "p-exchange-tos-fiat-exchange-amount-limit":
    "9.1.1. Les échanges pour un montant inférieur ou égal à 150 $ sont effectués sans délai. Si vous utilisez votre compte dans le système de paiement pour payer sur notre service pour la première fois et que vous échangez un montant supérieur à 150 $, votre demande sera complétée 2 heures après le paiement.",
  "p-exchange-tos-subsequent-exchanges":
    "9.1.2. Les échanges ultérieurs pour un montant supérieur à 150 $ avec le même numéro de compte dans le système de paiement et le compte de l'utilisateur seront instantanés, à condition que vous vous soyez enregistré avant le premier échange.",
  "p-exchange-tos-additional-checks":
    "9.1.3. Si moins de 24 heures se sont écoulées depuis le premier échange, et que le montant total des échanges par informations bancaires dépasse 500 $, un contrôle supplémentaire peut être établi avec un délai pouvant aller jusqu'à 48 heures par décision du service de sécurité.",
  "p-exchange-tos-suspicious-actions-fiat":
    "9.1.4. En cas d'actions suspectes de la part du client, le service se réserve le droit de demander des données de compte qui confirmeront que le compte appartient au client.",
  "p-exchange-tos-crypto-exchange": "9.2. Échange de crypto-monnaies",
  "p-exchange-tos-crypto-auto-manual":
    "9.2.1. Les demandes effectuées par le service en mode automatique peuvent être effectuées jusqu'à 12 heures. Les demandes effectuées par le service en mode manuel peuvent être effectuées jusqu'à 36 heures.",
  "p-exchange-tos-sale-cryptocurrencies":
    "9.2.2. Les demandes de vente de toutes les crypto-monnaies listées sur le site Web du service sont traitées au moment où la transaction reçoit le minimum établi de confirmations individuelles (le minimum est fixé par le service SolidSwap).",
  "p-exchange-tos-recalculate-rate":
    "9.2.3. Le Service a le droit de recalculer votre demande dans une direction favorable pour le bureau de change (à la fois au taux actuel et au taux de change qui était en vigueur au moment de la création de la demande) si la transaction n'a pas reçu le nombre requis de confirmations réseau dans le délai imparti pour la demande.",
  "p-exchange-tos-btc-confirmations":
    "9.2.4. Le paiement de l'échange de la crypto-monnaie BTC est effectué après la 3e confirmation sur le réseau Bitcoin. Le taux dans la demande reste fixe si le paiement a été effectué par l'Utilisateur pendant le délai imparti pour le paiement de la demande dans cette direction, et que la transaction est apparue sur le réseau pendant ce délai, et que la crypto-monnaie BTC a reçu 1 confirmation sur le réseau Bitcoin dans les 15 minutes. Si la 1ère confirmation est reçue après 15 minutes, la demande peut être recalculée au taux de change actuel.",
  "p-exchange-tos-fiat-crypto-fixed-rate":
    "9.2.5. Le taux de change dans les directions 'donner' Fiat, 'recevoir' crypto-monnaie est fixe pendant le délai imparti pour le paiement. Les demandes pour ces directions sont recalculées au taux de change actuel si le client n'a pas réussi à transférer des fonds sur le portefeuille / carte émis dans sa demande pendant ce délai.",
  "p-exchange-tos-crypto-sending":
    "9.2.6. Lorsque le client achète une crypto-monnaie répertoriée sur le site Web du service, l'envoi de la crypto-monnaie par le service peut prendre de 5 minutes à 12 heures. La vitesse de confirmation de la transaction sur le réseau ne dépend pas du service.",
  "p-exchange-tos-high-load-crypto-network":
    "9.2.7. En cas de forte charge ou de problèmes techniques sur le réseau de crypto-monnaies répertoriées sur le site Web du service, pour des raisons indépendantes de la volonté du Service, le délai de livraison des fonds au compte du destinataire peut atteindre 240 heures.",
  "p-exchange-tos-recalculation-notification":
    "9.2.8. Le recalcul du montant de la demande est effectué par le service conformément aux termes de cet accord en mode automatique, ce que le service notifie au client par e-mail.",
  "p-exchange-tos-blocked-account-responsibility":
    "9.2.9. Le Service n'est pas responsable des fonds transférés sur la carte bloquée du client ou sur un compte bloqué sur une plateforme d'échange de crypto-monnaies ou une autre plateforme centralisée, ainsi que si le client a été bloqué après que le service lui a transféré des actifs sur demande.",
  "p-exchange-tos-memo-tag-required":
    "9.2.10. L'échange de pièces nécessitant un MEMO ou un TAG, lorsqu'il est envoyé à notre portefeuille, doit nécessairement contenir ces valeurs MEMO ou TAG. Les fonds envoyés sans utiliser MEMO ou TAG ne sont pas soumis à un crédit et à un remboursement.",
  "p-exchange-tos-smart-contract-processing":
    "9.2.11. Les pièces envoyées à partir du Smart Contract sont traitées manuellement, le délai de traitement est de 2 heures. Après avoir envoyé les pièces, vous devez informer l'opérateur dans le chat en ligne de votre numéro de demande et du HASH de la transaction.",
  "p-exchange-tos-refund": "9.3. Remboursement",
  "p-exchange-tos-refund-decision":
    "9.3.1. Les remboursements ne sont possibles que par décision du Service.",
  "p-exchange-tos-refund-application":
    "9.3.2. Pour demander un remboursement, l'Utilisateur doit envoyer un e-mail à l'adresse e-mail du Service :",
  "p-exchange-tos-refund-minus-commission":
    "9.3.3. Le remboursement est effectué moins la commission du système de paiement pour le transfert et moins les frais de service de l'échange à hauteur de 5% du montant de l'échange. Les exceptions sont les cas décrits au paragraphe 4.19 de cet Accord.",
  "p-exchange-tos-refund-review":
    "9.3.4. Les demandes de remboursement sont examinées par le Service dans un délai de 48 heures.",
  "p-exchange-tos-refund-within-48-hours":
    "9.3.5. Sur demande, le Service effectue un remboursement dans un délai de 48 heures. (exception n 4.19)",
  "p-exchange-tos-deposit-below-minimum":
    "9.3.6. Les dépôts inférieurs au montant minimum ne seront ni crédités ni remboursés.",
  "p-exchange-tos-assets-return-clause-419":
    "9.3.7. Lors de l'échange d'actifs spécifiés au paragraphe 4.19 de cet Accord, après avoir passé les procédures AML et KYC, le Service se réserve le droit de ne pas fournir le service d'échange à l'Utilisateur et de rembourser les fonds, en respectant toutes les procédures des règlements de remboursement internes.",
  "p-exchange-tos-return-aml-failed-assets":
    "9.4. Retour des actifs n'ayant pas passé la vérification AML.",
  "p-exchange-tos-return-blocked-assets":
    "9.4.1. Le retour des actifs bloqués n'ayant pas passé la vérification AML n'est possible qu'après avoir passé la vérification d'identité conformément à la clause 8.6 de cet Accord et à la conformité à la clause 8.6.1.",
  "p-exchange-tos-request-from-authorities":
    "9.4.2. Le retour des actifs est impossible si une demande a été reçue pour ces actifs de la part des autorités compétentes et d'autres départements, y compris de toute juridiction ; dans ce cas, l'actif bloqué peut être utilisé comme preuve matérielle dans l'affaire.",
  "p-exchange-tos-refund-5-percent-commission":
    "9.4.3. Lors de l'exécution des clauses 8.6 et 8.6.1, le remboursement au client sera effectué avec une déduction de 5% de commission.",
  "p-exchange-tos-no-return-criminal-wallets":
    "9.4.4. Les actifs en crypto-monnaies ne sont pas soumis à un retour et à un échange si, selon les résultats de la vérification AML par le système",
  "p-exchange-tos-dark-market": "DARK MARKET",
  "p-exchange-tos-dark-service": "DARK SERVICE",
  "p-exchange-tos-scam": "SCAM",
  "p-exchange-tos-stolen": "VOLÉ",
  "p-exchange-tos-mixing": "MIXAGE (MIXER)",
  "p-exchange-tos-sextortion": "SEXTORSION",
  "p-exchange-tos-ransomware": "RANSOMWARE",
  "p-exchange-tos-hack": "PIRATAGE",
  "p-exchange-tos-phishing": "PHISHING",
  "p-exchange-tos-terrorism-financing": "FINANCEMENT DU TERRORISME",
  "p-exchange-tos-fraud": "FRAUDE",
  "p-exchange-tos-blacklist": "LISTE NOIRE",
  "p-exchange-tos-under-investigation": "ENCORE EN COURS D'ENQUÊTE",
  "p-exchange-tos-cybercrime-organization": "ORGANISATION DE CYBERCRIMINALITÉ",
  "p-exchange-tos-narcotics": "NARCOTIQUES",
  "p-exchange-tos-child-abuse": "MALTRAITANCE DES ENFANTS",
  "p-exchange-tos-human-trafficking": "TRAITE DES ÊTRES HUMAINS",
  "p-exchange-tos-sanctions": "SANCTIONS",
  "p-exchange-tos-change-recipient-details":
    "9.5. Modification des coordonnées du destinataire de la demande",
  "p-exchange-tos-change-recipient-details-procedure":
    "9.5.1. Afin de modifier les coordonnées du destinataire, l'Utilisateur soumet une demande de remplacement des coordonnées dans un e-mail envoyé à la poste",
  "p-exchange-tos-acceptance-terms":
    "10. Acceptation des conditions de service",
  "p-exchange-tos-acceptance-terms-text":
    "10.1. L'acceptation des termes de l'Accord Utilisateur lors de la création d'une demande est automatiquement considérée par le service comme une acceptation de toute la documentation régissant le fonctionnement du service.",
};
