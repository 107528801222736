export default {
  "p-help-head-title": "Ayuda | Contacta al soporte de Solid Swap",
  "p-help-head-description":
    "¿Necesitas asistencia? Abre un ticket de soporte con Solid Swap y obtén ayuda de nuestro equipo experto para resolver cualquier problema que encuentres",
  "p-help-head-keywords":
    "ticket de soporte, ayuda de Solid Swap, soporte para comercio de criptomonedas, contacto de soporte",
  "p-help-title": "Página de ayuda",
  "p-help-name-required": "Por favor, ingresa tu nombre",
  "p-help-email-required": "Por favor, ingresa tu correo electrónico",
  "p-help-message-required": "Cuéntanos tu problema aquí",
  "p-help-ticket-success": "¡Ticket enviado con éxito!",
  "p-help-ticket-error":
    "Algo salió mal al enviar el ticket, por favor intenta más tarde",
  "p-help-thank-you-message":
    "¡Gracias por comunicarte con nosotros! Hemos recibido tu mensaje y nos pondremos en contacto contigo a través de la dirección de correo electrónico que proporcionaste lo antes posible.",
  "p-help-intro-text":
    "Si encuentras algún problema o tienes alguna pregunta, no dudes en comunicarte con nosotros. Completa el formulario a continuación y te responderemos lo antes posible.",
  "p-help-label-name": "Nombre",
  "p-help-placeholder-name": "Ingresa tu nombre aquí",
  "p-help-label-email": "Correo electrónico",
  "p-help-placeholder-email": "Ingresa tu correo electrónico aquí",
  "p-help-label-message": "Mensaje",
  "p-help-placeholder-message": "...",
  "p-help-submit-button": "Enviar",
};
