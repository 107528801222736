export default {
  "p-index-head-title": "Solid Swap | 安全高效的加密货币交易",
  "p-index-head-description":
    "体验快速、安全、高效的加密货币交易。现在加入，开始轻松交换数字资产",
  "p-index-head-keywords":
    "加密货币交易，安全交易，数字资产，加密交易，Solid Swap，solidswap",
  "p-index-prices-finder": "价格查找器：",
  "p-index-welcome-message-2": "随时随地按您的方式交易",
  "p-index-welcome-no-fees": "买家无费用，卖家超低0.2%费用",
  "p-index-welcome-stable": "稳定，合法并且无反洗钱",
  "p-index-welcome-no-id": "无需身份验证",
  "p-index-get-started": "开始",
  "p-index-learn-more": "了解更多",
  "p-index-world-opening-title": "我们为您打开世界",
  "p-index-world-opening-message-1":
    "加密货币将使您自由，它让您摆脱银行的束缚，政府的统治",
  "p-index-world-opening-message-2":
    "这意味着在世界各地自由移动，并能够在任何国家保存您的资金",
  "p-index-mobile-app-title": "免费下载移动应用",
  "p-index-mobile-app-message":
    "移动应用程序可在 App Store 和 Google Play 下载",
  "p-index-coming-soon": "即将推出",
  "p-index-subscribe-title": "订阅 Solid Swap",
  "p-index-welcome-title": "我们P2P交易的优势",
  "p-index-our-blog": "我们的博客:",
};
