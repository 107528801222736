export default {
  "c-offers-table-loginWarning": "您需要登录才能创建订单",
  "c-offers-table-toggleError": "切换报价时出现问题",
  "c-offers-table-insufficientBalance": "您的余额不足，无法激活此报价",
  "c-offers-table-goToWallet": "前往钱包",
  "c-offers-table-seller": "卖家",
  "c-offers-table-buyer": "买家",
  "c-offers-table-price": "价格",
  "c-offers-table-availableLimit": "可用限制",
  "c-offers-table-available": "可用",
  "c-offers-table-offerLimits": "报价限制",
  "c-offers-amount-unlimited": "报价金额是无限的",
  "c-offers-table-paymentOptions": "支付选项",
  "c-offers-table-more": "更多",
  "c-offers-table-active": "活跃",
  "c-offers-table-disabled": "禁用",
  "c-offers-table-edit": "编辑",
  "c-offers-table-delete": "删除",
  "c-offers-table-buy": "购买",
  "c-offers-table-sell": "出售",
};
