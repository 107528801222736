export default {
  "p-login-head-title": "Մուտք գործել | Մուտք գործել ձեր Solid Swap հաշիվ",
  "p-login-head-description":
    "Մուտք գործեք ձեր Solid Swap հաշիվ՝ սկսելու համար կրիպտոարժույթի առևտուր անվտանգ և արդյունավետ",
  "p-login-head-keywords":
    "մուտք, Solid Swap հաշիվ, կրիպտոարժույթի առևտրի մուտք",
  "p-login-rules-email": "Խնդրում ենք մուտքագրել ձեր էլ. փոստը",
  "p-login-rules-password": "Խնդրում ենք մուտքագրել ձեր գաղտնաբառը",
  "p-login-error-default": "Ուփս, ինչ-որ բան սխալ գնաց մուտքի ժամանակ:",
  "p-login-error-2fa-wrong": "Սխալ 2FA կոդ: Խնդրում ենք փորձել նորից:",
  "p-login-error-user-not-found": "Օգտատեր չի գտնվել",
  "p-login-error-email-verification":
    "Ձեր էլ. փոստը պետք է նախապես հաստատված լինի: Խնդրում ենք ստուգել ձեր էլ. փոստը:",
  "p-login-error-suspended":
    "Ձեր հաշիվը կասեցվել է: Խնդրում ենք ստուգել ձեր էլ. փոստը հետագա մանրամասների համար:",
  "p-login-error-locked-title": "Ժամանակավորապես արգելափակված",
  "p-login-error-locked-message":
    "Դուք կատարել եք {failedLockCount} կամ ավելի անհաջող մուտքի փորձ: Անվտանգության պատճառներով, խնդրում ենք սպասել 60 րոպե նախքան նորից փորձելը: Եթե անհրաժեշտ է անհապաղ օգնություն կամ մոռացել եք ձեր գաղտնաբառը, խնդրում ենք օգտագործել 'Մոռացել եք գաղտնաբառը' տարբերակը՝ գաղտնաբառը վերականգնելու համար:",
  "p-login-error-wrong-password-title": "Սխալ գաղտնաբառ",
  "p-login-error-wrong-password-message":
    "Դուք կատարել եք {failedAttemptsCount} անհաջող մուտքի փորձ: Խնդրում ենք զգույշ լինել: {failedLockCount} անհաջող փորձերից հետո ձեր հաշիվը ժամանակավորապես արգելափակված կլինի 60 րոպե:",
  "p-login-password-reset-success": "Հաջողություն!",
  "p-login-password-reset-error":
    "Ինչ-որ բան սխալ գնաց գաղտնաբառի վերականգնելու ժամանակ, խնդրում ենք փորձել ավելի ուշ",
  "p-login-title": "Մուտք գործել",
  "p-login-label-email": "Էլփոստ",
  "p-login-label-password": "Գաղտնաբառ",
  "p-login-email-placeholder": "Մուտքագրեք ձեր էլ. փոստը",
  "p-login-password-placeholder": "Մուտքագրեք ձեր գաղտնաբառը",
  "p-login-forgot-password": "Մոռացե՞լ եք գաղտնաբառը?",
  "p-login-submit-button": "Մուտք գործել",
  "p-login-2fa-prompt": "Մուտքագրեք 2FA կոդը ձեր հավելվածից:",
  "p-login-2fa-placeholder": "Մուտքագրեք կոդը...",
  "p-login-2fa-button": "Շարունակել",
  "p-login-password-reset-success-title": "Էլ. փոստ ուղարկված!",
  "p-login-password-reset-success-message":
    "Մենք ուղարկել ենք գաղտնաբառի վերականգնման հղումը ձեր էլ. փոստի հասցեին: Խնդրում ենք ստուգել ձեր մուտքագրած հասցեն և հետևել հրահանգներին՝ գաղտնաբառը վերականգնելու համար:",
  "p-login-password-reset-alert":
    "Եթե էլ. փոստը չեք ստանում, խնդրում ենք ստուգել ձեր սպամ կամ գովազդային բաժինները: Եթե դեռ չեք ստացել, փորձեք նորից մուտքագրել ձեր էլ. փոստի հասցեն կամ կապվել մեր աջակցության թիմի հետ:",
  "p-login-back-to-login": "Վերադառնալ մուտք",
  "p-login-forgot-password-title": "Մոռացե՞լ եք ձեր գաղտնաբառը?",
  "p-login-forgot-password-message":
    "Խնդրում ենք մուտքագրել ձեր հաշվի հետ կապված էլ. փոստի հասցեն և սեղմեք 'Շարունակել':",
  "p-login-password-reset-instructions":
    "Մենք կուղարկենք ձեզ էլ. փոստ՝ գաղտնաբառի վերականգնման հրահանգներով: Եթե մի քանի րոպեների ընթացքում էլ. փոստը չեք ստանում, խնդրում ենք ստուգել ձեր սպամ բաժինը կամ փորձեք նորից:",
  "p-login-enter-email": "Մուտքագրեք ձեր էլ. փոստը",
  "p-login-cancel": "Չեղարկել",
  "p-login-proceed": "Շարունակել",
  "p-login-go-to-registration": "Չունե՞ք հաշիվ: Գնացեք գրանցում",
  "p-login-welcome-title": "Բարի գալուստ առևտրի նոր դարաշրջան!",
  "p-login-welcome-message-1":
    "Ապահով կերպով կապվեք թվային ակտիվների աշխարհին:",
  "p-login-welcome-message-2":
    "Սկսեք առևտուրը վստահությամբ և հարմարավետությամբ մեր վստահելի P2P հարթակում:",
  "p-login-welcome-message-3":
    "Ձեր ճանապարհորդությունը դեպի անխափան կրիպտո գործարքներ սպասում է:",
};
