export default {
  "p-confirm-email-title": "Email Confirmation",
  "p-confirm-email-wait-message":
    "Wait please, we're trying to confirm your email address...",
  "p-confirm-email-success-message":
    "Your email address has been confirmed successfully!",
  "p-confirm-email-success-elmessage":
    "Your email address has been confirmed successfully! You will be redirected to the login page in 30 seconds.",
  "p-confirm-email-info-elmessage":
    "Now you can login to your account as: {email}",
  "p-confirm-email-error-message":
    "Something went wrong, please try again later.",
  "p-confirm-email-proceed-button": "Proceed to Login",
  "p-confirm-email-welcome-title": "Welcome to a new era of trading!",
  "p-confirm-email-welcome-message":
    "Securely connect to a world of digital assets. Start trading with confidence and convenience on our trusted P2P platform. Your gateway to seamless crypto transactions awaits.",
};
