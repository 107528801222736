export default {
  "p-beginners-guide-head-title":
    "Beginners Guide | Start Trading Cryptocurrency with Solid Swap",
  "p-beginners-guide-head-description":
    "New to cryptocurrency trading? Our beginners guide will help you get started with easy-to-follow steps and valuable tips on trading digital assets",
  "p-beginners-guide-head-keywords":
    "beginners guide, cryptocurrency trading, getting started, crypto trading tips, digital assets",
  "p-beginners-guide-account-preparation-title":
    "Account Preparation for P2P Trading",
  "p-beginners-guide-register-title": "Register on Solid Swap",
  "p-beginners-guide-two-factor-title": "Enable two-factor authentication",
  "p-beginners-guide-payment-method-title": "Add a payment method",
  "p-beginners-guide-finding-offers-title-1":
    "Finding Offers and Creating Offers",
  "p-beginners-guide-select-currency-title-1": "Select a currency pair",
  "p-beginners-guide-search-offer-title-1": "Search for a suitable offer",
  "p-beginners-guide-create-offer-title-1": "Create an exchange offer",
  "p-beginners-guide-commissions-title-1": "Commissions",
  "p-beginners-guide-completing-transaction-title-1":
    "Completing the transaction",
  "p-beginners-guide-intro-1":
    "Hello! This guide will help you make your first exchange on Solid Swap. It will describe all the key steps, following which you can easily and securely complete a transaction. We have made this process as understandable and accessible as possible, so don't worry if you are new to the world of cryptocurrencies — we will be with you every step of the way.",
  "p-beginners-guide-intro-2":
    "Let's start with the basics: what is Solid Swap and why it is the ideal choice for cryptocurrency exchange. Solid Swap is a platform for quick exchange of cryptocurrencies and fiat money, currently offering peer-to-peer (P2P) services. We provide a secure and profitable exchange with competitive rates, low fees, and a high level of security.",
  "p-beginners-guide-steps-title":
    "Key steps for successful trading on Solid Swap:",
  "p-beginners-guide-follow-steps":
    "By following our instructions, you can make your first deal on Solid Swap quickly, safely, and confidently.",
  "p-beginners-guide-step1-title": "Step 1: Registration",
  "p-beginners-guide-register-instructions": "To register on Solid Swap:",
  "p-beginners-guide-register-step1":
    "Click the “Registration” button in the upper right corner of the screen.",
  "p-beginners-guide-register-step2":
    "Fill in the required information and click “Continue”.",
  "p-beginners-guide-register-step3":
    "Check the email you registered with and follow the link in the email to confirm your email address.",
  "p-beginners-guide-register-step4": "Log into your account.",
  "p-beginners-guide-step2-title": "Step 2: Enabling Two-Factor Authentication",
  "p-beginners-guide-two-factor-instructions":
    "To make transactions, you need to enable two-factor authentication. Two-factor authentication is especially important for protecting your assets and account. For this:",
  "p-beginners-guide-two-factor-step1":
    "Click on your profile icon in the upper right corner of the screen and go to settings by clicking the “Settings” button.",
  "p-beginners-guide-two-factor-step2": "Click the red “Enable 2FA” button.",
  "p-beginners-guide-two-factor-step3":
    "Scan the QR code displayed on the screen to link it with your account on Solid Swap.",
  "p-beginners-guide-two-factor-note":
    "If you do not have a two-factor authentication app, do not worry. You can download one of the following apps on your smartphone:",
  "p-beginners-guide-two-factor-app1":
    "Google Authenticator (for Android and iOS)",
  "p-beginners-guide-two-factor-app2": "Authy (for Android and iOS)",
  "p-beginners-guide-two-factor-app3":
    "Microsoft Authenticator (for Android and iOS)",
  "p-beginners-guide-faq":
    "For more detailed information on connecting 2FA, as well as answers to other frequently asked questions, visit our [FAQ](link to FAQ).",
  "p-beginners-guide-step3-title": "Step 3: Adding a Payment Method",
  "p-beginners-guide-payment-method-instructions":
    "To conduct any exchange, you need to add a payment method. For this:",
  "p-beginners-guide-payment-method-step1":
    "In your account settings, go to the “Payment options” tab.",
  "p-beginners-guide-payment-method-step2":
    "Click the “+ Add new payment option” button.",
  "p-beginners-guide-payment-method-step3":
    "Select a convenient payment method from the dropdown list.",
  "p-beginners-guide-payment-method-step4":
    "In the “Write the description” field, you can specify additional information. This will be displayed when you are the seller. For example, the number of a bank card or crypto wallet where you accept the currency.",
  "p-beginners-guide-finding-offers-title":
    "Finding Offers and Selecting a Seller",
  "p-beginners-guide-select-currency-title":
    "Step 1: Selecting a Currency Pair",
  "p-beginners-guide-select-currency-step1":
    "Go to the P2P trade tab, located on the panel at the top of the page.",
  "p-beginners-guide-select-currency-step2":
    "Choose the action you plan to take: buying or selling.",
  "p-beginners-guide-select-currency-step3":
    "Select the payment method that will be used in the transaction.",
  "p-beginners-guide-select-currency-step4":
    "Enter the amount of currency you plan to exchange to see all suitable offers.",
  "p-beginners-guide-select-currency-note":
    "The currency pair can also be selected on the main page of the site.",
  "p-beginners-guide-search-offer-title":
    "Step 2: Searching for a Suitable Offer",
  "p-beginners-guide-search-offer-description":
    "Choose the most suitable exchange offer for you.",
  "p-beginners-guide-search-offer-note":
    "When choosing a seller, pay attention to the following indicators:",
  "p-beginners-guide-search-offer-seller-rating":
    "Seller rating: Each seller has a rating based on feedback from previous buyers. Choose sellers with high ratings for more confidence.",
  "p-beginners-guide-search-offer-transactions":
    "Number of completed transactions: Note the number of successful transactions conducted by the seller. Sellers with a large number of transactions are generally more reliable.",
  "p-beginners-guide-search-offer-reviews":
    "Reviews: Read reviews from other users about the seller. This will help you assess their reputation and the quality of services provided. You can do this in the seller's profile.",
  "p-beginners-guide-search-offer-limits":
    "Transaction amount limits: Check the minimum and maximum amounts the seller is willing to exchange. Make sure they meet your requirements.",
  "p-beginners-guide-create-offer-title": "Step 3: Selecting an Offer",
  "p-beginners-guide-create-offer-step1":
    "When you find a suitable offer, click the green “Buy” button next to it to view the details.",
  "p-beginners-guide-create-offer-step2":
    "Review the terms of the deal, including the exchange rate, minimum and maximum amounts, and payment methods.",
  "p-beginners-guide-create-offer-step3":
    "If you agree with the terms, you can proceed to open the transaction. To do this, in the window that opens, fill in the empty fields and select the payment method. Click the “Buy” button.",
  "p-beginners-guide-create-offer-step4":
    "If none of the presented offers suit you, you can create your own. For this:",
  "p-beginners-guide-create-offer-note1":
    "Click the “+ Post new offer” button.",
  "p-beginners-guide-create-offer-note2":
    "Fill in the fields with the necessary information.",
  "p-beginners-guide-create-offer-note3":
    "In the description, you can specify additional information about the deal.",
  "p-beginners-guide-create-offer-note4": "Click the “Confirm” button.",
  "p-beginners-guide-create-offer-note5":
    "After this, you will be in the “My offers” section. Here you will find all the offers you have created. To make an offer visible to other users of the site, change its status. You can do this by clicking the corresponding switch on the right side of the offer.",
  "p-beginners-guide-commissions-title": "Please note!",
  "p-beginners-guide-commissions-description":
    "We strive for transparency in all aspects of our work, including commissions. The trading commission is only 0.2% of the transaction amount, which the seller pays. This allows you to easily plan your transactions, knowing that there will be no unexpected fees.",
  "p-beginners-guide-completing-transaction-title":
    "Completing the Transaction",
  "p-beginners-guide-completing-transaction-description1":
    "After you have found a suitable exchange offer and clicked the “Buy” button, you will be on the transaction page. Here is all the information about the exchange. We strongly recommend checking it to ensure accuracy and avoid erroneous transactions!",
  "p-beginners-guide-completing-transaction-description2":
    "On the transaction page, you will also find a chat with the seller. In it, you can clarify the terms of the exchange or other information of interest to you.",
  "p-beginners-guide-completing-transaction-description3":
    "In the active transaction window:",
  "p-beginners-guide-completing-transaction-step1":
    "Check the conditions and details of the exchange.",
  "p-beginners-guide-completing-transaction-step2":
    "Make the transfer to the seller's specified payment method.",
  "p-beginners-guide-completing-transaction-step3":
    "Confirm the transfer by clicking the “Payment was sent” button.",
  "p-beginners-guide-completing-transaction-step4":
    "Wait for the seller's payment confirmation and the transfer of funds to your account.",
  "p-beginners-guide-completing-transaction-step5":
    "Leave a review of the transaction: reviews help users understand who to cooperate with and who to avoid. We value your efforts to create a favorable atmosphere on our platform!",
  "p-beginners-guide-active-transactions-note1":
    "You can find your active transactions in the P2P trade section, under the “orders” tab.",
  "p-beginners-guide-active-transactions-note2":
    "In the event of a dispute, you can open a dispute 30 minutes after confirming the payment transfer. The button for this will appear on the transaction page after the timer expires.",
  "p-beginners-guide-active-transactions-note3":
    "If you have decided to sell currency and have created an offer for sale, wait for someone to respond. When this happens, you will receive a notification.",
  "p-beginners-guide-active-transactions-note4":
    "After a buyer is found, proceed with the exchange.",
  "p-beginners-guide-active-transactions-note5":
    "Check the conditions and details of the exchange.",
  "p-beginners-guide-active-transactions-note6":
    "Check if the payment has been made by the buyer.",
  "p-beginners-guide-active-transactions-note7":
    "Confirm receipt of funds by clicking the “Payment received” button.",
  "p-beginners-guide-active-transactions-note8":
    "Send the agreed amount to the buyer's specified receipt method.",
  "p-beginners-guide-ending-note1":
    "Done! You have successfully completed your first transaction on Solid Swap. We hope this guide has helped you go through all the steps without difficulty.",
  "p-beginners-guide-ending-note2":
    "Always remember safety when exchanging cryptocurrencies. Always check the terms of the deal and the seller's reputation, use two-factor authentication, and be careful when transferring funds. If you have any questions or problems, our support service is always ready to help you.",
  "p-beginners-guide-ending-note3":
    "Thank you for choosing Solid Swap. Wishing you successful and safe exchanges!",
  "p-beginners-guide-ending-note4":
    "If you have any questions or problems, our support service is always ready to help you.",
  "p-beginners-guide-ending-note5":
    "Thank you for choosing Solid Swap. Wishing you successful and safe exchanges!",
};
