export default {
  "p-about-head-title":
    "О Solid Swap | Познакомьтесь с нашей командой криптовалютной биржи",
  "p-about-head-description":
    "Узнайте больше о Solid Swap, команде, стоящей за нашей безопасной платформой для торговли криптовалютами, и нашей миссии по обеспечению эффективных обменов цифровыми активами",
  "p-about-head-keywords":
    "криптовалютная биржа, безопасная торговля, цифровые активы, торговля криптовалютой, Solid Swap, solidswap",
  "p-about-contact-title": "Свяжитесь с нами",
  "p-about-address": "Адрес:",
  "p-about-index": "Индекс:",
  "p-about-tax-id": "ИНН:",
  "p-about-email": "Электронная почта:",
  "p-about-support-email": "Электронная почта поддержки:",
  "p-about-duns": "Data Universal Numbering System:",
  "p-about-duns-check": "Проверить D-U-N-S",
  "p-about-traders-count": ">200 трейдеров уже с нами",
  "p-about-foundation-title": "Фундамент твоей финансовой независимости",
  "p-about-description":
    "Solid Swap — новый сервис для быстрого обмена криптовалюты и фиата на P2P платформе",
  "p-about-start-trading": "Начать торговать",
  "p-about-fee-tag": "0% fee",
  "p-about-beneficial-terms": "Выгодные условия",
  "p-about-low-fees":
    "Низкие комиссии, акции, розыгрыши и никаких сносок мелким шрифтом",
  "p-about-fast-transactions": "Быстрые транзакции",
  "p-about-secure-optimizations":
    "Постоянно улучшаем код, но не жертвуем безопасностью",
  "p-about-online": "Online",
  "p-about-24-support": "Круглосуточная поддержка",
  "p-about-support-anytime": "Поможем с любой проблемой, в любом часовом поясе",
  "p-about-contact-support": "Обратиться",
  "p-about-platform-mission":
    "Строим надежную платформу, следуя мечтам и принципам",
  "p-about-join-us":
    "Бирж и обменников много, но у нас есть чем выделиться — присоединяйся к нам и следи за ходом разработки",
  "p-about-freedom": "Свобода и анонимность",
  "p-about-data-privacy":
    "Не хотим превратиться в банк, ценим неприкосновенность личных данных",
  "p-about-registered-armenia": "Регистрация в Армении",
  "p-about-legal-compliance":
    "Чтобы работать в рамках закона, сохраняя независимость",
  "p-about-access-devices": "Доступ с любого устройства",
  "p-about-cross-device-experience":
    "Создаем проработанный опыт на любых девайсах, для любых ситуаций",
  "p-about-services-question": "Какие услуги предоставляет Solid Swap?",
  "p-about-buy-bitcoin-question": "Как купить Bitcoin на Solid Swap?",
  "p-about-crypto-question": "Что такое криптовалюта?",
  "p-about-russians-trade-question":
    "Можно ли россиянам торговать криптовалютой?",
  "p-about-pay-with-crypto-question": "Можно ли платить за работу в крипте?",
  "p-about-login-trade": "Зайди в аккаунт, чтобы начать торговать",
  "p-about-login": "Войти",
  "p-about-register": "Зарегистрироваться",
};
