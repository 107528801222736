export default {
  "p-kyc-aml-policy-title":
    "Anti-Geldwäsche-Politik (Anti-Money Laundering Policy).",
  "p-kyc-warning":
    "SolidSwap warnt Benutzer davor, den Dienst zu nutzen, um kriminell erworbene Gelder zu legalisieren, den Terrorismus zu finanzieren, illegale Handlungen jeglicher Art zu begehen oder den Dienst zu nutzen, um verbotene Waren und Dienstleistungen zu erwerben.",
  "p-kyc-illegal-funds-description":
    "Die Legalisierung kriminell erworbener Gelder ist die Verschleierung einer illegalen Quelle von Geldern, indem sie in rechtmäßig erscheinende Gelder oder Investitionen umgewandelt werden.",
  "p-kyc-general-provisions-title": "Allgemeine Bestimmungen",
  "p-kyc-prevent-illegal-transactions":
    "Um illegale Transaktionen zu verhindern, stellt der Dienst bestimmte Anforderungen an alle Anträge, die vom Benutzer erstellt werden:",
  "p-kyc-sender-recipient-same":
    "Der Absender und der Empfänger der Zahlung im Antrag müssen dieselbe Person sein. Überweisungen zugunsten Dritter sind strengstens untersagt.",
  "p-kyc-contact-info-requirements":
    "Alle im Antrag eingegebenen Kontaktdaten sowie andere personenbezogene Daten, die vom Benutzer an den Dienst übermittelt werden, müssen aktuell und vollständig zuverlässig sein.",
  "p-kyc-no-anonymous-connections":
    "Es ist strengstens verboten, Anträge unter Verwendung anonymer Proxy-Server oder anderer anonymer Internetverbindungen zu erstellen.",
  "p-kyc-policy-description":
    'Diese Anti-Geldwäsche-Politik (im Folgenden "AML-Politik") beschreibt die Verfahren und Mechanismen, die SolidSwap zur Bekämpfung der Geldwäsche verwendet.',
  "p-kyc-no-business-with-criminals":
    "keine Geschäftsbeziehungen mit bekannten Kriminellen und/oder Terroristen einzugehen;",
  "p-kyc-no-criminal-transactions":
    "keine Transaktionen zu bearbeiten, die das Ergebnis bekannter krimineller und/oder terroristischer Aktivitäten sind;",
  "p-kyc-no-facilitating-criminal-activity":
    "keine Transaktionen zu erleichtern, die mit bekannten kriminellen und/oder terroristischen Aktivitäten in Zusammenhang stehen;",
  "p-kyc-right-to-restrict-services":
    "hat das Recht, die Nutzung aller oder eines Teils der Dienste für Personen mit Einschränkungen zu beschränken oder zu verbieten...",
  "p-kyc-verification-procedures-title": "Verifizierungsverfahren",
  "p-kyc-kyc-policy-description":
    'Der Dienst SolidSwap hat eigene Verifizierungsverfahren im Rahmen der Geldwäschebekämpfungsstandards etabliert - die "Know Your Customer"-Politik (KYC).',
  "p-kyc-verification-steps":
    "Benutzer des SolidSwap-Dienstes durchlaufen das Verifizierungsverfahren (Vorlage eines amtlichen Identifikationsdokuments: Reisepass oder Personalausweis).",
  "p-kyc-request-second-document":
    "SolidSwap-Dienst kann auch ein zweites Dokument anfordern, um den Benutzer zu identifizieren...",
  "p-kyc-verification-authenticity":
    "SolidSwap-Dienst überprüft die Authentizität der von den Benutzern bereitgestellten Dokumente und Informationen...",
  "p-kyc-request-updated-documents":
    "Wenn sich die Identifikationsinformationen des Benutzers geändert haben oder dessen Aktivitäten verdächtig erscheinen...",
  "p-kyc-aml-compliance-title":
    "Verantwortlicher für die Einhaltung der AML-Politik",
  "p-kyc-aml-compliance-description":
    "Der Verantwortliche für die Einhaltung der AML-Politik ist ein Mitarbeiter von SolidSwap...",
  "p-kyc-collect-user-info":
    "Sammelt Benutzeridentifikationsinformationen und überträgt diese an den verantwortlichen Agenten für die Verarbeitung personenbezogener Daten...",
  "p-kyc-create-update-policies":
    "Erstellung und Aktualisierung interner Richtlinien und Verfahren...",
  "p-kyc-monitor-transactions":
    "Überwachung von Transaktionen und Analyse von Abweichungen...",
  "p-kyc-record-management":
    "Umsetzung eines Aufzeichnungssystems zur Aufbewahrung und Suche von Dokumenten...",
  "p-kyc-regular-risk-update":
    "Regelmäßige Aktualisierung der Risikobewertung.",
  "p-kyc-interaction-with-authorities":
    "Der Verantwortliche für die Einhaltung der AML-Politik hat das Recht, mit den Strafverfolgungsbehörden zusammenzuarbeiten...",
  "p-kyc-transaction-monitoring-title": "Transaktionsüberwachung",
  "p-kyc-monitoring-description":
    "Die Überwachung von Benutzertransaktionen und die Analyse der empfangenen Daten...",
  "p-kyc-report-suspicious-transactions":
    "Melden verdächtiger Transaktionen an die zuständigen Strafverfolgungsbehörden...",
  "p-kyc-request-additional-info":
    "Anforderung zusätzlicher Informationen und Dokumente...",
  "p-kyc-suspend-terminate-account":
    "Sperrung oder Beendigung des Benutzerkontos...",
  "p-kyc-freeze-assets":
    "Aussetzung der Transaktion und Einfrieren von Vermögenswerten bis zur Klärung der Umstände...",
  "p-kyc-return-funds":
    "Rückgabe der Gelder durch Stornierung des Umtauschverfahrens gemäß Benutzervereinbarung...",
  "p-kyc-risk-assessment": "Risikobewertung.",
  "p-kyc-additional-monitoring":
    "Der AML-Politikbeauftragte überwacht täglich die Transaktionen der Benutzer...",
};
