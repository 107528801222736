export default {
  "p-support-chat-welcome": "Добро пожаловать в наш чат поддержки!",
  "p-support-chat-intro":
    "Мы здесь, чтобы помочь вам с любыми вопросами или проблемами, которые могут у вас возникнуть. Наша цель - предоставить вам лучший опыт поддержки. Вот как вы можете начать:",
  "p-support-chat-enter-details-prefix": "Введите ваши данные: ",
  "p-support-chat-enter-details-suffix":
    "тема вашего вопроса. Эта информация поможет нам лучше помочь вам и более эффективно решить ваши проблемы.",
  "p-support-chat-open-chat":
    'Откройте чат: нажмите кнопку "Открыть чат поддержки", чтобы начать.',
  "p-support-chat-start-conversation":
    "Начните разговор: как только вы введете свои данные и отправите форму, на странице появится окно чата. Один из наших модераторов вскоре присоединится к чату, чтобы помочь вам.",
  "p-support-chat-appreciation":
    "Мы ценим ваше терпение и с нетерпением ждем возможности помочь вам!",
  "p-support-chat-your-name": "Ваше имя",
  "p-support-chat-your-email": "Ваш email",
  "p-support-chat-your-question": "Ваш вопрос",
  "p-support-chat-open-chat-button": "Открыть чат поддержки",
  "p-support-chat-wait-moderator": "Ожидайте присоединения модератора",
  "p-support-chat-contact-alternative":
    "Если у вас возникли проблемы с использованием чата или вы предпочитаете другие способы связи с нами, пожалуйста, свяжитесь с нами через нашу",
  "p-support-chat-contact-page": "страницу контактов",
  "p-support-chat-or-email": "или напишите нам по электронной почте",
  "p-support-chat-thank-you": "Спасибо, что выбрали наши услуги!",
  "p-support-chat-ask-question": "Задайте вопрос",
  "p-support-chat-name-required": "Имя обязательно",
  "p-support-chat-email-required": "Email обязателен",
  "p-support-chat-valid-email": "Введите действительный email",
  "p-support-chat-chat-started": "Чат поддержки начат!",
  "p-support-chat-name-email": "имя, email,",
};
