export default {
  "p-help-head-title": "Help | Contact Solid Swap Support",
  "p-help-head-description":
    "Need assistance? Open a support ticket with Solid Swap and get help from our expert team to resolve any issues you encounter",
  "p-help-head-keywords":
    "support ticket, Solid Swap help, cryptocurrency trading support, contact support",
  "p-help-title": "Help page",
  "p-help-name-required": "Enter your name please",
  "p-help-email-required": "Enter your email please",
  "p-help-message-required": "Tell us your problem here",
  "p-help-ticket-success": "Successfully sent ticket!",
  "p-help-ticket-error":
    "Something went wrong while sending ticket, please try later",
  "p-help-thank-you-message":
    "Thank you for reaching out to us! We've received your message and will be in touch via the email address you provided as soon as possible.",
  "p-help-intro-text":
    "If you encounter any issues or have any questions, please don't hesitate to reach out to us. Fill out the form below, and we will get back to you as soon as possible.",
  "p-help-label-name": "Name",
  "p-help-placeholder-name": "Enter your name here",
  "p-help-label-email": "Email",
  "p-help-placeholder-email": "Enter your email here",
  "p-help-label-message": "Message",
  "p-help-placeholder-message": "...",
  "p-help-submit-button": "Submit",
};
