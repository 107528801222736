export default {
  "c-notifications-indexclient-connectError":
    "Erreur lors de la connexion aux notifications",
  "c-notifications-indexclient-hideAllMessages": "Cacher tous les messages",
  "c-notifications-indexclient-goToSettings":
    "aller aux paramètres de notification",
  "c-notifications-indexclient-nothingNew": "rien de nouveau",
  "c-notifications-indexclient-viewOlder": "Voir plus anciens",
  "c-notifications-indexclient-viewAll": "Voir tout",
};
