export default {
  "p-profile-userId-error": "错误：未找到用户",
  "p-profile-userId-online": "在线",
  "p-profile-userId-last-online": "最近在线时间：{date}",
  "p-profile-userId-registration-date": "注册日期",
  "p-profile-userId-total-orders": "总订单数",
  "p-profile-userId-buy": "购买",
  "p-profile-userId-sell": "出售",
  "p-profile-userId-last-month-orders": "上月订单",
  "p-profile-userId-average-confirm-time": "平均付款确认时间",
  "p-profile-userId-average-send-time": "平均付款发送时间",
  "p-profile-userId-feedback": "反馈",
  "p-profile-userId-positive": "正面",
  "p-profile-userId-negative": "负面",
  "p-profile-userId-offers-tab": "报价",
  "p-profile-userId-feedback-tab": "反馈",
  "p-profile-userId-no-offers": "无报价",
  "p-profile-userId-buy-orders": "购买订单",
  "p-profile-userId-sell-orders": "出售订单",
  "p-profile-userId-no-feedback": "无反馈",
};
