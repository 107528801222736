export default {
  "c-user-avatarChange-avatar-must-be-jpg":
    "¡La imagen del avatar debe estar en formato JPG!",
  "c-user-avatarChange-avatar-size-exceed":
    "¡El tamaño de la imagen del avatar no puede superar los 2MB!",
  "c-user-avatarChange-avatar-upload-error": "¡Error al subir la imagen!",
  "c-user-avatarChange-change-avatar": "Cambiar avatar",
  "c-user-avatarChange-avatar-file-restriction":
    "archivos jpg/png con un tamaño menor a 5 MB.",

  "c-user-cookiealert-cookie-preference": "Preferencia de cookies",
  "c-user-cookiealert-essential-cookies":
    "Usamos cookies y tecnologías similares que son necesarias para operar nuestros Sitios Web (cookies esenciales).",
  "c-user-cookiealert-additional-cookies":
    "También utilizamos cookies de Análisis, Funcionalidad y Segmentación para analizar el tráfico de nuestros Sitios Web, optimizar tu experiencia, personalizar el contenido y servir anuncios dirigidos.",
  "c-user-cookiealert-manage-cookies":
    "Puedes desactivar las cookies en cualquier momento visitando la opción Administrar Cookies en el pie de página.",
  "c-user-cookiealert-reject-cookies": "Rechazar Cookies",
  "c-user-cookiealert-accept-continue": "Aceptar y Continuar",

  "c-Enable2FA-enabled":
    "¡Autenticación de Dos Factores (2FA) está habilitada!",
  "c-Enable2FA-disable": "Desactivar 2FA",
  "c-Enable2FA-not-recommended": "(no recomendado)",
  "c-Enable2FA-enable": "Habilitar 2FA",
  "c-Enable2FA-highly-recommended": "(altamente recomendado)",
  "c-Enable2FA-enable-2fa": "Habilitar 2FA",
  "c-Enable2FA-scan-image":
    "Escanea la imagen a continuación con tu aplicación 2FA para habilitar dos factores",
  "c-Enable2FA-enter-address": "O ingresa esta dirección en tu aplicación",
  "c-Enable2FA-enter-code": "Ingresa el código 2FA",
  "c-Enable2FA-cancel": "Cancelar",
  "c-Enable2FA-error-getting-image": "¡Error al obtener la imagen 2FA!",
  "c-Enable2FA-success-enable": "¡2FA habilitado con éxito!",
  "c-Enable2FA-error-enable": "¡Error al habilitar 2FA!",
  "c-Enable2FA-disable-prompt":
    "Estás a punto de desactivar la Autenticación de Dos Factores (2FA) en tu cuenta. Ten en cuenta que esto reducirá la seguridad de tu cuenta. Al desactivar 2FA, no podrás comerciar ni retirar fondos durante 24 horas. Si deseas continuar, ingresa tu código 2FA a continuación y haz clic en 'Desactivar 2FA'.",
  "c-Enable2FA-warning": "Advertencia",
  "c-Enable2FA-input-error": "Ingresa tu código 2FA",
  "c-Enable2FA-success-disable": "2FA cancelado",
  "c-Enable2FA-error-disable": "¡Error al desactivar 2FA!",

  "c-UserMenu-orders": "Pedidos",
  "c-UserMenu-payment-options": "Opciones de pago",
  "c-UserMenu-wallet": "Cartera",
  "c-UserMenu-notifications": "Notificaciones",
  "c-UserMenu-settings": "Configuraciones",
  "c-UserMenu-partner-dashboard": "Socio",

  "c-NotificationSettings-title": "Configuraciones de notificaciones",
  "c-NotificationSettings-alert":
    "Ten en cuenta que las configuraciones de notificaciones son específicas para cada dispositivo. Tienes la capacidad de configurar diferentes configuraciones para cada uno de tus dispositivos.",
  "c-NotificationSettings-check-all": "Marcar todo",
  "c-NotificationSettings-website-notifications":
    "Notificaciones del sitio web:",
  "c-NotificationSettings-order-notifications": "Notificaciones de pedidos",
  "c-NotificationSettings-chat-messages": "Mensajes de chat",
  "c-NotificationSettings-system-notifications": "Notificaciones del sistema:",
  "c-NotificationSettings-system-alert":
    "Las notificaciones del sistema son alertas nativas específicas de tu dispositivo. Estas notificaciones solo funcionarán si has otorgado permiso a tu navegador para mostrar notificaciones.",

  "c-PasswordChange-change-password": "Cambiar contraseña",
  "c-PasswordChange-dialog-title": "Cambiar tu contraseña",
  "c-PasswordChange-description":
    "Por favor, ingresa tu contraseña actual y elige una nueva. Tu nueva contraseña debe cumplir con los siguientes criterios:",
  "c-PasswordChange-current-password": "Contraseña actual",
  "c-PasswordChange-new-password": "Nueva contraseña",
  "c-PasswordChange-confirm-new-password": "Confirmar nueva contraseña",
  "c-PasswordChange-min-characters": "Mínimo 6 caracteres",
  "c-PasswordChange-max-characters": "Máximo 20 caracteres",
  "c-PasswordChange-2fa-code": "Código 2FA",
  "c-PasswordChange-case-sensitive":
    "Las contraseñas distinguen entre mayúsculas y minúsculas. Elige una contraseña fuerte y única para mantener la seguridad de tu cuenta.",
  "c-PasswordChange-cancel": "Cancelar",
  "c-PasswordChange-wait-loading": "Por favor, espera cargando...",
  "c-PasswordChange-enter-current-password":
    "Por favor, ingresa tu contraseña actual",
  "c-PasswordChange-enter-new-password":
    "Por favor, ingresa tu nueva contraseña",
  "c-PasswordChange-please-confirm-new-password":
    "Por favor, confirma tu nueva contraseña",
  "c-PasswordChange-passwords-not-match": "Las dos contraseñas no coinciden",
  "c-PasswordChange-enter-2fa-code": "Por favor, ingresa tu código 2FA",
  "c-PasswordChange-password-changed-success":
    "¡Contraseña cambiada con éxito!",
  "c-PasswordChange-error-changing-password":
    "¡Error al cambiar la contraseña!",

  "c-TFAAlert-2fa-disabled-title":
    "Tu autenticación de dos factores está desactivada",
  "c-TFAAlert-attention": "¡Atención!",
  "c-TFAAlert-message-1":
    "Parece que aún no has habilitado la Autenticación de Dos Factores (2FA). Para tu seguridad, ciertas acciones como crear pedidos, ofertas y retiros están restringidas hasta que se active 2FA.",
  "c-TFAAlert-message-2":
    "Puedes seguir usando el sitio web, pero considera habilitar 2FA para desbloquear la funcionalidad completa y mejorar la seguridad de tu cuenta.",
  "c-TFAAlert-remind-me-later": "Recuérdame más tarde",
  "c-TFAAlert-go-to-setup": "Ir a configuración de 2FA",

  "c-partner-bonuses-list": "Lista de bonificaciones",
  "c-partner-bonuses-description":
    "Aquí puedes ver la lista de bonificaciones que has recibido de los clientes que usaron tu enlace de referencia.",
  "c-partner-bonus-id": "ID",
  "c-partner-bonus-amount": "Monto del bono",
  "c-partner-bonus-date": "Fecha",

  "c-partner-withdrawals-list": "Lista de retiros",
};
