export default {
  "p-blog-codeId-failed-to-save-post": "保存文章失败！",
  "p-blog-codeId-post-saved-successfully": "文章保存成功！",
  "p-blog-codeId-confirm-delete": "确定要删除这篇文章吗？",
  "p-blog-codeId-warning": "警告",
  "p-blog-codeId-delete": "删除",
  "p-blog-codeId-post-deleted-successfully": "文章删除成功！",
  "p-blog-codeId-failed-to-delete-post": "删除文章失败！",
  "p-blog-codeId-post-not-found": "未找到博客文章 :(",
  "p-blog-codeId-back-to-all-posts": "返回所有文章",
  "p-blog-codeId-edit-post": "编辑文章",
  "p-blog-codeId-delete-post": "删除文章",

  "p-blog-index-title": "博客 | Solid Swap 的见解、提示和故事",
  "p-blog-index-meta-description":
    "在我们的博客上探索最新的见解、提示和故事。保持更新，了解我们最新的文章，涵盖广泛的主题，包括技术、生活方式等",
  "p-blog-index-meta-keywords":
    "博客，见解，提示，故事，技术，生活方式，最新文章，Solid Swap，solidswap",
  "p-blog-index-failed-to-fetch-posts": "获取文章失败",
  "p-blog-index-solid-swap-blog": "Solid Swap 博客",
  "p-blog-index-search-placeholder": "搜索博客文章...",
  "p-blog-index-new-post": "新文章",
  "p-blog-index-active": "活跃",
  "p-blog-index-archive": "档案",
  "p-blog-index-load-more": "加载更多",
  "p-blog-index-no-posts-found": "未找到文章",
};
