export default {
  "c-header-menu-dark": "暗黑",
  "c-header-menu-light": "明亮",
  "c-header-menu-trade": "P2P 交易",
  "c-header-menu-news": "新闻",
  "c-header-menu-support": "支持",
  "c-header-menu-chat": "支持聊天",
  "c-header-menu-blog": "博客",
  "c-header-menu-faq": "常见问题",
  "c-header-menu-beginnersGuide": "新手指南",
  "c-header-menu-help": "帮助",
  "c-header-menu-about": "关于",
  "c-header-menu-menu": "菜单",
  "c-header-menu-admin": "管理员",
  "c-header-menu-moder": "版主",
  "c-header-menu-profile": "我的资料",
  "c-header-menu-orders": "订单",
  "c-header-menu-paymentOptions": "支付选项",
  "c-header-menu-wallet": "钱包",
  "c-header-menu-settings": "设置",
  "c-header-menu-logout": "登出",
  "c-header-menu-login": "登录",
  "c-header-menu-registration": "注册",
  "c-header-menu-notifications": "通知",
};
