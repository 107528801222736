export default {
  "c-exchange-listItem-id": "ID",
  "c-exchange-listItem-fiat": "Fiat",
  "c-exchange-listItem-crypto": "Cripto",
  "c-exchange-listItem-go-to-details": "Ir a los detalles",
  "c-exchange-listItem-summary": "Resumen",
  "c-exchange-listItem-exchanging": "Intercambiando",
  "c-exchange-listItem-for": "por",
  "c-exchange-listItem-order-created": "Pedido creado",
  "c-exchange-listItem-status": "Estado",
  "c-exchange-listItem-verifying-card": "Verificando la tarjeta del usuario",
  "c-exchange-listItem-moderator-verifying":
    "El moderador está verificando los datos",
  "c-exchange-listItem-waiting-alm": "Esperando resultados de ALM",
  "c-exchange-listItem-preparing-crypto":
    "Preparando la dirección de la plataforma",
  "c-exchange-listItem-preparing-fiat": "Preparando los requisitos de fiat",
  "c-exchange-listItem-sending-crypto": "Enviando cripto",
  "c-exchange-listItem-sending-fiat": "Enviando fiat",
  "c-exchange-listItem-confirming-fiat": "Confirmando el pago",
  "c-exchange-listItem-waiting-payment": "Esperando el pago del usuario",
  "c-exchange-listItem-completed": "Completado",
  "c-exchange-listItem-failed": "Fallido",
  "c-exchange-listItem-cancelled": "Cancelado",
};
