export default {
  "p-exchange-exchange": "Обмен",
  "p-exchange-for": "на",
  "p-exchange-fixed-rate":
    "Курс обмена будет зафиксирован после создания заявки",
  "p-exchange-current-rate": "Текущий курс обмена для этой пары:",
  "p-exchange-send": "Отправить",
  "p-exchange-amount-to-send": "Сумма для отправки",
  "p-exchange-crypto": "Криптовалюта",
  "p-exchange-fiat": "Фиат",
  "p-exchange-card-number-verification":
    "Номер вашей карты: (требуется для проверки)",
  "p-exchange-card-tooltip":
    "Прежде чем мы сможем принять ваши деньги, нам нужно подтвердить вашу личность и проверить, не находится ли карта в черных списках",
  "p-exchange-learn-more": "(узнать больше?)",
  "p-exchange-wallet-verification":
    "Ваш {currency} кошелек: (требуется для проверки ALM)",
  "p-exchange-wallet-tooltip":
    "Проверка ALM требует проверки криптовалюты, SolidSwap гарантирует, что все криптовалютные транзакции являются 'чистыми'",
  "p-exchange-enter-wallet": "Введите кошелек здесь",
  "p-exchange-receive": "Получить",
  "p-exchange-on": "на {name}",
  "p-exchange-amount-to-receive": "Сумма для получения",
  "p-exchange-card-number": "Номер карты",
  "p-exchange-wallet-receive": "Кошелек для получения",
  "p-exchange-email-required": "Эл. почта (обязательно)",
  "p-exchange-terms-message": "Я прочитал и принимаю",
  "p-exchange-terms": "Условия использования",
  "p-exchange-and": "и",
  "p-exchange-privacy": "Политику конфиденциальности",
  "p-exchange-alm-message": "Я прочитал и принимаю",
  "p-exchange-captcha": "Капча",
  "p-exchange-not-allowed": "Не разрешено",
  "p-exchange-not-allowed-message":
    "Администраторам и модераторам SolidSwap не разрешено создавать запросы на обмен",
  "p-exchange-proceed": "Перейти к обмену",
  "p-exchange-menu-create-request": "Создать запрос на обмен",
  "p-exchange-menu-requests": "Запросы",
  "p-exchange-accept-terms": "Пожалуйста, примите условия использования",
  "p-exchange-accept-alm": "Пожалуйста, прочитайте и примите AML/CTF и KYC",
  "p-exchange-enter-captcha": "Пожалуйста, введите капчу",
  "p-exchange-send-amount-min":
    "Сумма для отправки не должна быть меньше {amount} {currency}",
  "p-exchange-recieve-amount-min":
    "Сумма для получения не должна быть меньше {amount} {currency}",
  "p-exchange-send-amount-max":
    "Сумма для отправки не должна превышать {amount} {currency}",
  "p-exchange-recieve-amount-max":
    "Сумма для получения не должна превышать {amount} {currency}",
  "p-exchange-send-amount-required": "Требуется сумма для отправки",
  "p-exchange-recieve-amount-required": "Требуется сумма для получения",
  "p-exchange-public-address-required": "Требуется ваш адрес кошелька",
  "p-exchange-card-number-required": "Требуется номер вашей карты",
  "p-exchange-rates-updated-now": "Курсы будут обновлены сейчас!",
  "p-exchange-rates-updated-in":
    "Курсы будут обновлены через {minutes}:{seconds}",
  "p-exchange-procees-to-search": "Перейти к созданию обмена",
};
