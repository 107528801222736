export default {
  "s-useNotifications-order": "Commande n° {orderId}",
  "s-useNotifications-system-message": "Message système",
  "s-useNotifications-new-order-created": "Nouvelle commande créée !",
  "s-useNotifications-order-status-changed":
    'Le statut de la commande a changé en "{status}"',
  "s-useNotifications-order-created": "Commande créée",
  "s-useNotifications-order-deleted": "Commande supprimée",
  "s-useNotifications-moderator-joined": "Modérateur rejoint",
  "s-useNotifications-attached-file": "[fichier joint]",
};
