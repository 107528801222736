export default {
  "c-home-banners-getToKnowUs": "Conócenos",
  "c-home-banners-dirtyCryptocurrency":
    "Qué hacer si recibes criptomoneda 'sucia'",
  "c-home-banners-beginnersGuide":
    "Comienza, leyendo nuestra guía para principiantes",

  "c-home-historywidgets-24hours": "24 horas",
  "c-home-historywidgets-1week": "1 semana",
  "c-home-historywidgets-1month": "1 mes",
  "c-home-historywidgets-usdRatesHistory": "Historial de tasas USD para",
  "c-home-historywidgets-change": "(cambiar?)",
};
