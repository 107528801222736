export default {
  "c-transaction-cancel-confirm": "确定要取消此交易吗？",
  "c-transaction-cancel-withdrawal": "取消取款",
  "c-transaction-back": "返回",
  "c-transaction-cancel-error": "取消交易时出错！",
  "c-transaction-status": "状态",
  "c-transaction-amount": "金额",
  "c-transaction-fee": "费用",
  "c-transaction-type": "类型",
  "c-transaction-comment": "评论",
  "c-transaction-address": "地址",
};
