export default {
  "c-footer-menu-trade": "commerce p2p",
  "c-footer-menu-news": "nouvelles",
  "c-footer-menu-blog": "blog",
  "c-footer-menu-faq": "FAQ",
  "c-footer-menu-beginnersGuide": "guide du débutant",
  "c-footer-menu-about": "à propos",
  "c-footer-menu-manageCookies": "gérer les cookies",
  "c-footer-menu-adminPanel": "Panneau d'administration",

  "c-footer-mobilemenu-home": "Accueil",
  "c-footer-mobilemenu-trade": "Commerce",
  "c-footer-mobilemenu-orders": "Commandes",
  "c-footer-mobilemenu-wallet": "Portefeuille",
};
