export default {
  "c-chat-indexclient-historyLoadError": "Error while loading chat history",
  "c-chat-indexclient-pictureFormatError": "Picture must be jpg/png format!",
  "c-chat-indexclient-pictureSizeError": "Picture size can not exceed 10MB!",
  "c-chat-indexclient-uploadError": "Failed to upload image!",
  "c-chat-indexclient-connectionError": "Error while connecting to chat",
  "c-chat-indexclient-summaryLoadError": "Error while loading chat summary",
  "c-chat-indexclient-chatWith": "Chat with",
  "c-chat-indexclient-counterparty": "counterparty",
  "c-chat-indexclient-online": "online",
  "c-chat-indexclient-wasOnline": "was online",
  "c-chat-indexclient-andMore": "and {count} more",
  "c-chat-indexclient-allParticipants": "All chat participants",
  "c-chat-indexclient-moderator": "moderator",
  "c-chat-indexclient-welcomeMessage":
    "Welcome to the chat for order #{orderId}",
  "c-chat-indexclient-paymentSent": "Buyer has sent the payment",
  "c-chat-indexclient-paymentConfirmed": "Seller has confirmed the payment",
  "c-chat-indexclient-disputeCreated": "Dispute created",
  "c-chat-indexclient-orderCancelled": "Order cancelled",
  "c-chat-indexclient-moderatorJoined": "Moderator joined the chat",
  "c-chat-indexclient-sessionStarted": "Support session started",
  "c-chat-indexclient-sessionClosed": "Support session closed",
  "c-chat-indexclient-placeholder": "Please input",
};
