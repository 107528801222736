export default {
  "s-useNotifications-order": "订单编号 #{orderId}",
  "s-useNotifications-system-message": "系统消息",
  "s-useNotifications-new-order-created": "新订单已创建！",
  "s-useNotifications-order-status-changed": '订单状态已变更为 "{status}"',
  "s-useNotifications-order-created": "订单已创建",
  "s-useNotifications-order-deleted": "订单已删除",
  "s-useNotifications-moderator-joined": "管理员已加入",
  "s-useNotifications-attached-file": "[附件文件]",
};
