export default {
  "c-payment-option-close-dialog-confirm":
    "Вы уверены, что хотите закрыть диалоговое окно опции оплаты?",
  "c-payment-option-save-error": "Произошла ошибка при сохранении опции оплаты",
  "c-payment-option-edit": "Редактировать опцию оплаты",
  "c-payment-option-new": "Новая опция оплаты",
  "c-payment-option-show-currency-methods":
    "Будут показаны только методы оплаты с валютой: {currency}",
  "c-payment-option-show-specified-options":
    "Будут показаны только опции оплаты, указанные в предложении",
  "c-payment-option-editing-method": "Редактирование метода оплаты для:",
  "c-payment-option-select-method": "Выберите метод, который хотите добавить:",
  "c-payment-option-select-method-placeholder": "выберите способ оплаты...",
  "c-payment-option-description": "Описание типа оплаты:",
  "c-payment-option-description-info":
    "Пожалуйста, укажите описание вашего типа оплаты. Это должно включать номер вашего счета, номер карты или любую другую релевантную информацию, которая поможет покупателям определить, куда им следует перевести средства при покупке криптовалюты у вас.",
  "c-payment-option-description-placeholder": "напишите описание...",
  "c-payment-option-save": "Сохранить",
  "c-payment-option-validate-payment-option":
    "Пожалуйста, выберите способ оплаты",
  "c-payment-option-validate-description": "Пожалуйста, добавьте описание",
};
