export default {
  "c-transaction-cancel-confirm":
    "Are you sure you want to cancel this transaction?",
  "c-transaction-cancel-withdrawal": "Cancel Withdrawal",
  "c-transaction-back": "Back",
  "c-transaction-cancel-error":
    "Something went wrong while cancelling transaction!",
  "c-transaction-status": "Status",
  "c-transaction-amount": "Amount",
  "c-transaction-fee": "Fee",
  "c-transaction-type": "Type",
  "c-transaction-comment": "Comment",
  "c-transaction-address": "Address",
};
