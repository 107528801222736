export default {
  "c-notifications-indexclient-connectError":
    "Fehler beim Verbinden mit Benachrichtigungen",
  "c-notifications-indexclient-hideAllMessages": "Alle Nachrichten ausblenden",
  "c-notifications-indexclient-goToSettings":
    "zu den Benachrichtigungseinstellungen gehen",
  "c-notifications-indexclient-nothingNew": "Es gibt nichts Neues",
  "c-notifications-indexclient-viewOlder": "Ältere anzeigen",
  "c-notifications-indexclient-viewAll": "Alle anzeigen",
};
