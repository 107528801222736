export default {
  "p-exchange-exchange": "兑换",
  "p-exchange-for": "兑换为",
  "p-exchange-fixed-rate": "申请提交后汇率将被固定",
  "p-exchange-current-rate": "当前汇率为：",
  "p-exchange-send": "发送",
  "p-exchange-amount-to-send": "发送金额",
  "p-exchange-crypto": "加密货币",
  "p-exchange-fiat": "法定货币",
  "p-exchange-card-number-verification": "您的卡号：（需要验证）",
  "p-exchange-card-tooltip":
    "在我们接受您的资金之前，我们需要验证您的身份并检查卡片是否在黑名单中",
  "p-exchange-learn-more": "(了解更多?)",
  "p-exchange-wallet-verification":
    "您的 {currency} 钱包：（需要进行 ALM 检查）",
  "p-exchange-wallet-tooltip":
    "ALM 检查需要验证加密货币，SolidSwap 保证所有加密货币的接收和发送都是“干净的”",
  "p-exchange-enter-wallet": "在此输入钱包地址",
  "p-exchange-receive": "接收",
  "p-exchange-on": "在 {name}",
  "p-exchange-amount-to-receive": "接收金额",
  "p-exchange-card-number": "卡号",
  "p-exchange-wallet-receive": "接收钱包",
  "p-exchange-email-required": "电子邮箱（必填）",
  "p-exchange-terms-message": "我已阅读并接受",
  "p-exchange-terms": "使用条款",
  "p-exchange-and": "和",
  "p-exchange-privacy": "隐私政策",
  "p-exchange-alm-message": "我已阅读并接受",
  "p-exchange-captcha": "验证码",
  "p-exchange-not-allowed": "不允许",
  "p-exchange-not-allowed-message":
    "SolidSwap 的管理员和版主不允许创建兑换请求",
  "p-exchange-proceed": "继续兑换",
  "p-exchange-menu-create-request": "创建交换请求",
  "p-exchange-menu-requests": "请求",
  "p-exchange-accept-terms": "请接受使用条款",
  "p-exchange-accept-alm": "请阅读并接受AML/CTF和KYC",
  "p-exchange-enter-captcha": "请输入验证码",
  "p-exchange-send-amount-min": "发送金额不得少于 {amount} {currency}",
  "p-exchange-recieve-amount-min": "接收金额不得少于 {amount} {currency}",
  "p-exchange-send-amount-max": "发送金额不得超过 {amount} {currency}",
  "p-exchange-recieve-amount-max": "接收金额不得超过 {amount} {currency}",
  "p-exchange-send-amount-required": "发送金额为必填项",
  "p-exchange-recieve-amount-required": "接收金额为必填项",
  "p-exchange-public-address-required": "您的钱包地址是必填项",
  "p-exchange-card-number-required": "您的卡号是必填项",
  "p-exchange-rates-updated-now": "汇率现在将更新！",
  "p-exchange-rates-updated-in": "汇率将在 {minutes}:{seconds} 后更新",
  "p-exchange-procees-to-search": "继续创建交换",
};
