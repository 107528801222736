export default {
  "p-resetPassword-head-title":
    "Վերականգնել գաղտնաբառը | Solid Swap հաշվի վերականգնում",
  "p-resetPassword-head-description":
    "Վերականգնեք ձեր Solid Swap հաշվի գաղտնաբառը արագ և ապահով: Հետևեք քայլերին՝ ձեր հաշվի հասանելիությունը վերականգնելու համար",
  "p-resetPassword-head-keywords":
    "վերականգնել գաղտնաբառը, հաշվի վերականգնում, Solid Swap գաղտնաբառի վերականգնում",
  "p-resetPassword-success": "Ձեր գաղտնաբառը հաջողությամբ վերականգնվել է!",
  "p-resetPassword-loginInfo": "Այժմ կարող եք մուտք գործել ձեր հաշիվ",
  "p-resetPassword-error":
    "Չհաջողվեց վերականգնել ձեր գաղտնաբառը, խնդրում ենք կապ հաստատել աջակցության հետ:",
  "p-resetPassword-validate-password-required":
    "Խնդրում ենք մուտքագրել ձեր գաղտնաբառը",
  "p-resetPassword-validate-password-length":
    "Գաղտնաբառի երկարությունը պետք է լինի 6-21 նիշերի սահմաններում",
  "p-resetPassword-validate-password2-required":
    "Խնդրում ենք հաստատել ձեր գաղտնաբառը",
  "p-resetPassword-validate-password2-match": "Գաղտնաբառերը չեն համընկնում",
  "p-resetPassword-title": "Վերականգնել ձեր գաղտնաբառը",
  "p-resetPassword-success-title": "Հաջողություն!",
  "p-resetPassword-success-message":
    "Ձեր գաղտնաբառը հաջողությամբ վերականգնվել է: Դուք կուղղորդվեք դեպի մուտքի էջ 30 վայրկյանում",
  "p-resetPassword-goToLogin": "Գնալ մուտք",
  "p-resetPassword-instructions":
    "Խնդրում ենք մուտքագրել ձեր նոր գաղտնաբառը ներքևի դաշտերում: Համոզվեք, որ ձեր գաղտնաբառը առնվազն 6 նիշ է և պարունակում է մեծատառեր, փոքրատառեր, թվեր և հատուկ նիշեր՝ ավելի մեծ անվտանգության համար:",
  "p-resetPassword-newPassword": "Նոր գաղտնաբառ",
  "p-resetPassword-placeholder-password": "*****",
  "p-resetPassword-repeatPassword": "Կրկնել գաղտնաբառը",
  "p-resetPassword-placeholder-password2": "*****",
  "p-resetPassword-resetButton": "Վերականգնել գաղտնաբառը",
};
