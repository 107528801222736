export default {
  "c-exchange-steps-verifying-card": "Проверка карты пользователя",
  "c-exchange-steps-waiting-alm": "Ожидание результатов ALM",
  "c-exchange-steps-preparing-crypto": "Подготовка криптоадреса",
  "c-exchange-steps-moderator-verifying": "Модератор проверяет данные",
  "c-exchange-steps-preparing-fiat": "Подготовка фиатных реквизитов",
  "c-exchange-steps-waiting-payment": "Ожидание платежа пользователя",
  "c-exchange-steps-confirming-payment": "Подтверждение платежа",
  "c-exchange-steps-sending-crypto": "Отправка криптовалюты",
  "c-exchange-steps-sending-fiat": "Отправка фиата",
  "c-exchange-steps-request-completed": "Запрос завершен",
};
