export default {
  "p-user-index-welcome": "Bienvenue sur votre tableau de bord personnel !",

  "p-user-notifications-settings": "Aller aux paramètres de notification",
  "p-user-notifications-all": "Toutes les notifications",
  "p-user-notifications-error":
    "Erreur lors de la récupération des notifications",
  "p-user-notifications-none": "Aucune notification",

  "p-user-settings-head-title":
    "Paramètres utilisateur | Gérez votre compte Solid Swap",
  "p-user-settings-head-description":
    "Gérez les paramètres de votre compte Solid Swap et vos préférences.",
  "p-user-settings-title": "Paramètres utilisateur",
  "p-user-settings-id": "ID",
  "p-user-settings-username": "Nom d'utilisateur",
  "p-user-settings-last-login": "Dernière connexion",

  "p-user-wallet-head-title":
    "Portefeuille utilisateur | Gérez votre compte Solid Swap",
  "p-user-wallet-title": "Transactions de portefeuille",
  "p-user-wallet-incoming-internal": "Entrées internes",
  "p-user-wallet-outgoing-internal": "Sorties internes",
  "p-user-wallet-incoming": "Entrées",
  "p-user-wallet-outgoing": "Sorties",
  "p-user-wallet-address-copied": "Votre adresse publique a été copiée !",
  "p-user-wallet-using-network":
    "Nous utilisons le réseau {network} pour {currentTab}",
  "p-user-wallet-balance": "Votre solde est de :",
  "p-user-wallet-frozen-tooltip":
    "Le montant des fonds actuellement gelés dans le système, dans les commandes, les transferts, etc.",
  "p-user-wallet-frozen": "gelé",
  "p-user-wallet-show-address": "Afficher votre adresse publique {currentTab}",
  "p-user-wallet-copy": "Copier",
  "p-user-wallet-min-deposit-note-1":
    "Notez que nous avons une limite minimale pour déposer des cryptomonnaies.",
  "p-user-wallet-min-deposit-note-2":
    "La limite est d'environ 50 {symbol} équivalent en crypto.",
  "p-user-wallet-current-rate": "Au taux de change actuel :",
  "p-user-wallet-usd-symbol": "50 {symbol}",
  "p-user-wallet-tfa-alert":
    "Veuillez noter : Les retraits ne sont pas autorisés à moins que l'authentification à deux facteurs (TFA) ne soit activée sur votre compte.",
  "p-user-wallet-restriction-alert":
    "Votre compte est actuellement restreint pour les retraits. Veuillez contacter le support pour plus d'assistance",
  "p-user-wallet-withdraw": "Retirer {currentTab}",
  "p-user-wallet-history-title": "Historique des transactions :",
  "p-user-wallet-no-transactions":
    "Aucune transaction trouvée pour {currentTab}",

  "p-user-head-title": "Page utilisateur | Gérez votre compte Solid Swap",
  "p-user-head-description":
    "Accédez et gérez les paramètres de votre compte Solid Swap, votre portefeuille et vos préférences personnelles en toute sécurité",
  "p-user-head-keywords":
    "paramètres utilisateur, gestion de compte, portefeuille crypto, utilisateur Solid Swap",

  "p-user-partner-my-partnership": "Mon partenariat",
  "p-user-partner-program-details": "Détails du programme",
  "p-user-partner-example-link": "lien d'exemple :",
  "p-user-partner-token": "Jeton",
  "p-user-partner-update-date": "Date de mise à jour",
  "p-user-partner-type": "Type",
  "p-user-partner-is-active": "Est actif",
  "p-user-partner-value": "Valeur",
  "p-user-partner-id": "ID partenaire",
  "p-user-partner-balances": "Soldes",
  "p-user-partner-note":
    "Remarque : 'Solde bloqué' représente le montant des fonds en attente de commandes, de transferts ou d'autres actions réservées.",
  "p-user-partner-currency": "Devise",
  "p-user-partner-balance": "Solde",
  "p-user-partner-frozen-balance": "Solde bloqué",
  "p-user-partner-error": "Quelque chose a mal tourné",
  "p-user-partner-tfa-required": "Authentification à deux facteurs requise",
  "p-user-partner-tfa-description":
    "Veuillez noter : l'accès à votre programme de partenariat n'est autorisé que si l'authentification à deux facteurs (TFA) est activée sur votre compte.",
  "p-user-partner-enable-tfa":
    "Activez votre TFA dans les paramètres de sécurité pour accéder au programme de partenariat.",
  "p-user-partner-go-to-tfa-settings": "Aller aux paramètres 2FA",
  "p-user-partner-inactive-help":
    "Si vous n'êtes pas sûr de la raison pour laquelle votre partenariat n'est pas actif,",
  "p-user-partner-contact-support": "contactez notre support",
  "p-user-partner-apply-withdraw": "Demander un retrait",
  "p-user-partner-transaction-history": "Historique des transactions",
  "p-user-partner-no-transactions":
    "Il semble que la liste des transactions soit vide",
  "p-user-partner-withdraw-form": "Formulaire de retrait",
  "p-user-partner-select-currency": "Sélectionnez la devise",
  "p-user-partner-address": "Adresse",
  "p-user-partner-enter-address": "Entrez votre adresse crypto",
  "p-user-partner-amount": "Montant",
  "p-user-partner-enter-amount": "Entrez le montant",
  "p-user-partner-2fa-code": "Code 2FA",
  "p-user-partner-enter-2fa-code": "Entrez le code de votre application 2FA",
  "p-user-partner-submit": "Soumettre",
  "p-user-partner-bonuses": "Bonus",
  "p-user-partner-withdrawals": "Retraits",
  "p-user-partner-visitors": "Visiteurs",
  "p-user-partner-enter-wallet-address":
    "Entrez l'adresse publique de votre portefeuille",
  "p-user-partner-withdraw-success": "Demande de retrait créée avec succès",
  "p-user-partner-copy-success": "Copié dans le presse-papiers",
};
