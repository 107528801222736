export default {
  "c-feedback-create-fastTransaction": "Transaction rapide",
  "c-feedback-create-politeFriendly": "Poli et amical",
  "c-feedback-create-patient": "Patient",
  "c-feedback-create-safeTrustworthy": "Sûr et digne de confiance",
  "c-feedback-create-goodPrice": "Bon prix",
  "c-feedback-create-successMessage": "Merci pour votre retour !",
  "c-feedback-create-errorMessage":
    "Une erreur s'est produite lors de l'envoi du retour",
  "c-feedback-create-feedbackTitle": "Retour d'information",
  "c-feedback-create-experienceQuestion":
    "Comment s'est passée votre expérience ?",
  "c-feedback-create-positive": "Positive",
  "c-feedback-create-negative": "Négative",
  "c-feedback-create-feedbackPlaceholder": "Veuillez saisir votre retour",
  "c-feedback-create-anonymous": "Anonyme",
  "c-feedback-create-anonymousHint":
    "envoyez votre retour sans afficher votre nom",
  "c-feedback-create-cancel": "Annuler",
  "c-feedback-create-submit": "Soumettre le retour",

  "c-feedback-item-anonymously": "(anonymement)",
  "c-feedback-item-positive": "Positive",
  "c-feedback-item-negative": "Négative",
};
