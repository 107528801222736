export default {
  "c-offers-editform-closeConfirmation":
    "Sind Sie sicher, dass Sie diesen Dialog schließen möchten?",
  "c-offers-editform-percentError":
    "Prozentwert % sollte zwischen -99 und 99 liegen",
  "c-offers-editform-createError": "Fehler beim Erstellen des Angebots",
  "c-offers-editform-createSuccess": "Angebot erfolgreich erstellt!",
  "c-offers-editform-updateSuccess": "Angebot erfolgreich aktualisiert!",
  "c-offers-editform-typeRequired": "Bitte wählen Sie den Typ des Auftrags",
  "c-offers-editform-currencyRequired": "Bitte wählen Sie die Währung",
  "c-offers-editform-priceTypeRequired": "Bitte wählen Sie den Preis-Typ",
  "c-offers-editform-priceValRequired": "Bitte geben Sie den Preiswert ein",
  "c-offers-editform-paymentOptionsRequired":
    "Bitte wählen Sie die Zahlungsmethoden",
  "c-offers-editform-minLimitRequired": "Bitte geben Sie den Mindestbetrag ein",
  "c-offers-editform-minLimitError":
    "Mindestbetrag sollte größer als {minAmount} sein",
  "c-offers-editform-maxLimitRequired": "Bitte geben Sie den Höchstbetrag ein",
  "c-offers-editform-maxLimitError":
    "Höchstbetrag sollte nicht niedriger als das Mindestlimit sein",
  "c-offers-editform-offerAmountRequired":
    "Bitte geben Sie den Angebotsbetrag ein",
  "c-offers-editform-termsOfUseRequired":
    "Bitte geben Sie die Nutzungsbedingungen ein",
  "c-offers-editform-editTitle": "Bearbeiten Sie Ihr Angebot",
  "c-offers-editform-addTitle": "Neues Angebot hinzufügen",
  "c-offers-editform-offerType": "Angebotstyp",
  "c-offers-editform-sell": "verkaufen",
  "c-offers-editform-buy": "kaufen",
  "c-offers-editform-selling": "Verkauf",
  "c-offers-editform-buying": "Kauf",
  "c-offers-editform-mainCurrency": "Hauptwährung",
  "c-offers-editform-selectCurrency": "Währung auswählen",
  "c-offers-editform-forCurrency": "...für",
  "c-offers-editform-currentRate":
    "aktueller Kurs: 1 {currencyIn} = {rate} {currencyOut}",
  "c-offers-editform-price": "Preis",
  "c-offers-editform-priceType": "Preis-Typ",
  "c-offers-editform-selectPriceType": "Preis-Typ auswählen",
  "c-offers-editform-percentValue": "Prozentwert",
  "c-offers-editform-relativeValue": "Relativer Wert",
  "c-offers-editform-fixedValue": "Festwert",
  "c-offers-editform-priceTypesExplained": "Erklärung der Preis-Typen:",
  "c-offers-editform-percent": "Prozent",
  "c-offers-editform-percentExplanation":
    "Der Preis wird basierend auf einem prozentualen Unterschied zu den aktuellen Kursen angepasst. Wenn ein Benutzer einen Prozentsatz eingibt, wird der Endpreis berechnet, indem dieser Prozentsatz als Erhöhung oder Verringerung auf die aktuellen Kurse angewendet wird.",
  "c-offers-editform-relative": "Relativ",
  "c-offers-editform-relativeExplanation":
    "Der Preis wird durch Hinzufügen oder Subtrahieren einer bestimmten Zahl zu den aktuellen Kursen angepasst. Der Benutzer gibt diese Zahl ein, und sie modifiziert direkt den Basispreis, wodurch der Endpreis entweder höher oder niedriger um diesen genauen Betrag wird.",
  "c-offers-editform-fixed": "Fest",
  "c-offers-editform-fixedExplanation":
    "Dies ist eine einfache Preisfestlegung, bei der der Benutzer den Preis direkt festlegt. Der eingegebene Preis wird der Endpreis sein, ohne Anpassungen basierend auf aktuellen Kursen oder Prozentsätzen.",
  "c-offers-editform-pricePercent": "Prozentpreis",
  "c-offers-editform-priceRelative": "Relativer Preis",
  "c-offers-editform-priceFixed": "Fester Preis",
  "c-offers-editform-enterPriceValue": "Preiswert eingeben",
  "c-offers-editform-rate":
    "entsprechend dem ausgewählten Typ und den Preisfeldern beträgt Ihr Kurs: 1 {currencyIn} = {rate} {currencyOut}",
  "c-offers-editform-paymentOptions": "Zahlungsmethoden",
  "c-offers-editform-selectPaymentOptions": "Zahlungsmethoden auswählen",
  "c-offers-editform-noOptions": "Keine Optionen verfügbar",
  "c-offers-editform-all": "Alle",
  "c-offers-editform-addOption": "Zahlungsoption hinzufügen",
  "c-offers-editform-minLimit": "Mindestlimit",
  "c-offers-editform-enterMinAmount": "min. Betrag",
  "c-offers-editform-maxLimit": "Höchstlimit",
  "c-offers-editform-enterMaxAmount": "max. Betrag",
  "c-offers-editform-orderLimitsExplanation": "Erklärung der Auftragslimits",
  "c-offers-editform-orderLimitsExplanationTitle":
    "Erklärung der Auftragslimits",
  "c-offers-editform-orderLimitsExplanationText":
    "Die Mindest- und Höchstgrenzen geben den Bereich an, in dem Sie einen Auftrag erstellen können. Sie können keinen Kauf- oder Verkaufsauftrag für Kryptowährungen unterhalb der Mindestgrenze oder oberhalb der Höchstgrenze platzieren.",
  "c-offers-editform-orderLimitsExplanationUSD":
    "Zusätzlich erzwingt unsere Plattform ein Mindestlimit von {MIN_OFFER_AMOUNT_USD} USD für alle Angebote, dies entspricht etwa {usdLimit} {currencyOut}",
  "c-offers-editform-amountToTrade": "Zu handelnder Betrag",
  "c-offers-editform-offerAmount": "Angebotsbetrag",
  "c-offers-editform-offerAmount-unlimited": "unbegrenzt",
  "c-offers-editform-enterOfferAmount": "betrag",
  "c-offers-editform-currentBalance":
    "Mein aktuelles Guthaben: {balance} {currencyIn}",
  "c-offers-editform-offerAmountExplanation": "Erklärung des Angebotsbetrags",
  "c-offers-editform-offerAmountExplanationTitle":
    "Erklärung des Angebotsbetrags",
  "c-offers-editform-offerAmountExplanationText1":
    "Der Angebotsbetrag ist die Gesamtmenge an Kryptowährung, die Sie handeln möchten.",
  "c-offers-editform-offerAmountExplanationText2":
    "Bei Verkaufsangeboten ist dies die Menge an Kryptowährung, die Sie verkaufen möchten. Zum Beispiel: Wenn Sie 1000 USDT in Ihrem Guthaben haben und nur die Hälfte handeln möchten, können Sie den Angebotsbetrag auf 500 USDT festlegen. Dies stellt sicher, dass Sie nicht mehr als 500 USDT verkaufen. Beachten Sie, dass alle Ihre Gelder in Ihrem Konto bleiben und durch das Stornieren des Angebots auf Ihr Guthaben zurückgeführt werden.",
  "c-offers-editform-offerAmountExplanationText3":
    "Bei Kaufangeboten gibt dieser Betrag an, wie viel Kryptowährung Sie kaufen möchten. Wenn Sie den Angebotsbetrag auf 1000 USDT festlegen, erhalten Sie genau diesen Betrag.",
  "c-offers-editform-offerAmountExplanationText4":
    "Zusätzlich erhebt unsere Plattform eine Provision, die vom Verkäufer getragen wird. Daher, wenn Sie ein VERKAUFSangebot erstellen, tragen Sie die Provision. Wenn Sie ein KAUFangebot erstellen, trägt die Gegenpartei, die den Auftrag erstellt, die Provision.",
  "c-offers-editform-termsOfUse": "Nutzungsbedingungen",
  "c-offers-editform-termsOfUseText":
    "Bitte füllen Sie unten Ihre 'Nutzungsbedingungen' aus. Dieser Abschnitt sollte die allgemeinen Bedingungen und Konditionen umfassen, unter denen Sie bereit sind, zu handeln. Es ist nicht notwendig, hier Ihre Bankdaten oder andere sensible Informationen anzugeben.",
  "c-offers-editform-termsOfUsePlaceholder":
    "schreiben Sie eine Beschreibung...",
  "c-offers-editform-cancel": "Abbrechen",
  "c-offers-editform-confirm": "Bestätigen",
};
