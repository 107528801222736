export default {
  "c-exchange-reqItem-cancelling-request": "Cancelling your exchange request",
  "c-exchange-reqItem-cancel-request-confirm":
    "Are you sure you want to cancel the request?",
  "c-exchange-reqItem-confirming-documents": "Confirming your documents",
  "c-exchange-reqItem-upload-documents-instructions":
    "Make sure that images of your document have high resolution and all digits are clearly visible",
  "c-exchange-reqItem-confirm-documents-confirmation":
    "Do you want to proceed with documents confirmation?",
  "c-exchange-reqItem-select-payment-option": "Select payment option",
  "c-exchange-reqItem-proceed-with-payment-option":
    "Do you want to proceed with the selected payment option?",
  "c-exchange-reqItem-confirming-payment": "Confirming payment",
  "c-exchange-reqItem-confirm-payment":
    "Do you want to proceed with confirming your payment?",
  "c-exchange-reqItem-completing-request": "Completing request",
  "c-exchange-reqItem-provide-txid":
    "To complete the request, you need to provide the transaction ID of your transfer",
  "c-exchange-reqItem-enter-txid-error": "Please enter txId here",
  "c-exchange-reqItem-enter-txid-placeholder": "Enter txId here",
  "c-exchange-reqItem-verifying-card": "Verifying user's card",
  "c-exchange-reqItem-moderator-verifying": "Moderator is verifying data",
  "c-exchange-reqItem-waiting-alm": "Waiting for ALM results",
  "c-exchange-reqItem-preparing-crypto": "Preparing platform address",
  "c-exchange-reqItem-preparing-fiat": "Preparing fiat requisites",
  "c-exchange-reqItem-sending-crypto": "Sending crypto",
  "c-exchange-reqItem-sending-fiat": "Sending fiat",
  "c-exchange-reqItem-waiting-payment": "Waiting for user's payment",
  "c-exchange-reqItem-completed": "Completed",
  "c-exchange-reqItem-failed": "Failed",
  "c-exchange-reqItem-cancelled": "Cancelled",
  "c-exchange-reqItem-cancel-exchange-request": "Cancel exchange request",
  "c-exchange-reqItem-confirm-documents": "Confirm documents",
  "c-exchange-reqItem-documents-confirmed": "Documents are confirmed",
  "c-exchange-reqItem-payment-sent": "Payment was sent",
  "c-exchange-reqItem-confirm-user-payment": "Confirm user payment",
  "c-exchange-reqItem-complete-request": "Complete request",
  "c-exchange-reqItem-id": "ID",
  "c-exchange-reqItem-exchanging": "Exchanging",
  "c-exchange-reqItem-crypto-address": "User's crypto address",
  "c-exchange-reqItem-fiat-card-number": "User's fiat card number",
  "c-exchange-reqItem-request-failed": "Request failed",
  "c-exchange-reqItem-alm-link": "ALM result link",
  "c-exchange-reqItem-check-here": "check here",
  "c-exchange-reqItem-request-cancelled": "Request cancelled",
  "c-exchange-reqItem-request-fulfilled": "Your Request Has Been Fulfilled!",
  "c-exchange-reqItem-thank-you":
    "Thank you for choosing us as your preferred exchange service. We truly appreciate your trust in us and look forward to serving you again!",
  "c-exchange-reqItem-address-preparation":
    "Here will be the address for your transfer.",
  "c-exchange-reqItem-security-check":
    "Please wait until all preparations and security checks are done.",
  "c-exchange-reqItem-waiting-for-transaction":
    "The platform is waiting for your transaction.",
  "c-exchange-reqItem-transfer-here": "Transfer the funds here",
  "c-exchange-reqItem-wallet": "wallet",
  "c-exchange-reqItem-payment-option": "Payment option",
  "c-exchange-reqItem-card-number": "Card number",
  "c-exchange-reqItem-description": "Description",
  "c-exchange-reqItem-balance": "balance",
  "c-exchange-reqItem-payment-instructions":
    "After you have made the payment, please share an image of the invoice (or screenshot) of your payment in the chat",
  "c-exchange-reqItem-verification-required": "Identity Verification Required",
  "c-exchange-reqItem-upload-photo-instructions":
    "To proceed with your exchange request, we need you to upload a photo of yourself holding the credit card you plan to use. Please ensure the following:",
  "c-exchange-reqItem-photo-instruction-cardholder":
    "The cardholder’s name and all of the digits of the card number are visible.",
  "c-exchange-reqItem-photo-instruction-face":
    "Your face is clearly visible in the photo.",
  "c-exchange-reqItem-click-confirm-documents":
    "Once you've uploaded the photo in the chat, click the 'Confirm documents' button above and wait for our moderator to verify your information.",
  "c-exchange-reqItem-verification-in-progress": "Verification in Progress",
  "c-exchange-reqItem-moderator-review-in-progress":
    "Thank you for uploading your photo. Please wait while our moderator reviews and verifies your information. This process may take a few minutes. We appreciate your patience.",
  "c-exchange-reqItem-select-payment-option-description":
    "Choose a payment option from the list below. The selected option will be displayed to the customer as the payment method for transferring funds.",
  "c-exchange-reqItem-proceed-with-selected-option":
    "Proceed with selected option",
  "c-exchange-reqItem-alm-check": "We are conducting ALM check on your wallet",
  "c-exchange-reqItem-preparing-address":
    "SolidSwap is preparing address for your crypto transfer, please wait.",
  "c-exchange-reqItem-transfer-funds":
    "Transfer funds to the wallet provided below and click 'Payment was sent' button",
  "c-exchange-reqItem-confirming-payment-text":
    "Please wait while SolidSwap confirms your payment.",
  "c-exchange-reqItem-transfer-confirmed":
    "Your transfer is confirmed, please wait for SolidSwap to send your funds to",
  "c-exchange-reqItem-identity-verification": "Identity Verification Required",
  "c-exchange-reqItem-upload-photo":
    "Use chat to upload a photo of the front of the card with the deal in the background. You can close the card data except for the digits of the card number.",
  "c-exchange-reqItem-virtual-card-intro": "If you have virtual card:",
  "c-exchange-reqItem-first-way": "First way:",
  "c-exchange-reqItem-first-way-desc":
    "Upload a screenshot of the screen with two windows open at the same time: the first is the transaction page with your order number, the second is the window with card details on your bank page.",
  "c-exchange-reqItem-second-way": "Second way:",
  "c-exchange-reqItem-second-way-desc":
    "Upload a photo or screenshot of your virtual card in the mobile app or in your bank's website against the background of your current order. You can close the card data, except the digits of the card number.",
  "c-exchange-reqItem-waiting-moderator":
    "Wait for the moderator to check the uploaded documents",
  "c-exchange-reqItem-transfer-to-card":
    "Transfer funds to the card provided below. After you have made the payment, please share an image of the invoice (or screenshot) of your payment in the chat and click 'Payment was sent' button",
  "c-exchange-reqItem-crypto-transfer-confirmed":
    "Your transfer is confirmed, please wait for SolidSwap to send your funds",
  "c-exchange-reqItem-faq-promotion": "FAQ for promotion",
  "c-exchange-reqItem-solid-swap-top-service":
    "When you choose Solid Swap to exchange your digital and fiat assets, you not only get top-notch service but also a chance to add",
  "c-exchange-reqItem-add-to-account-monthly": "to your account every month.",
  "c-exchange-reqItem-how-can-you-do-it": "How can you do it?",
  "c-exchange-reqItem-register-and-exchange":
    "Register and conduct an exchange on SolidSwap, each exchange counts as an entry to win.",
  "c-exchange-reqItem-leave-review-on":
    "Leave your honest review on one of the following resources",
  "c-exchange-reqItem-send-email-screenshot":
    "Send your email and a screenshot of your review to",
  "c-exchange-reqItem-manager-telegram": "our manager on Telegram",
  "c-exchange-reqItem-or-email-to": "or email to",
  "c-exchange-reqItem-enter-giveaway": "to enter the 300 USDT giveaway.",
  "c-exchange-reqItem-promotion-period":
    "The promotion runs from October 1 to December 31, 2024.",
  "c-exchange-reqItem-winners-selected-randomly":
    "Winners are selected randomly among participants who meet the requirements each month during the promotion period. Results will be announced in the",
  "c-exchange-reqItem-participate-again":
    "To participate again in the following month, you need to make another exchange and leave an additional review.",
};
