export default {
  "c-transaction-cancel-confirm":
    "¿Estás seguro de que deseas cancelar esta transacción?",
  "c-transaction-cancel-withdrawal": "Cancelar retiro",
  "c-transaction-back": "Atrás",
  "c-transaction-cancel-error": "¡Algo salió mal al cancelar la transacción!",
  "c-transaction-status": "Estado",
  "c-transaction-amount": "Monto",
  "c-transaction-fee": "Tarifa",
  "c-transaction-type": "Tipo",
  "c-transaction-comment": "Comentario",
  "c-transaction-address": "Dirección",
};
