export default {
  "p-help-head-title": "Hilfe | Solid Swap Support kontaktieren",
  "p-help-head-description":
    "Benötigen Sie Hilfe? Öffnen Sie ein Support-Ticket bei Solid Swap und erhalten Sie Unterstützung von unserem Expertenteam, um alle auftretenden Probleme zu lösen",
  "p-help-head-keywords":
    "support-ticket, Solid Swap hilfe, kryptowährungshandel unterstützung, kontaktieren sie den support",
  "p-help-title": "Hilfe-Seite",
  "p-help-name-required": "Bitte geben Sie Ihren Namen ein",
  "p-help-email-required": "Bitte geben Sie Ihre E-Mail ein",
  "p-help-message-required": "Teilen Sie uns hier Ihr Problem mit",
  "p-help-ticket-success": "Ticket erfolgreich gesendet!",
  "p-help-ticket-error":
    "Etwas ist schiefgelaufen beim Senden des Tickets, bitte versuchen Sie es später erneut",
  "p-help-thank-you-message":
    "Vielen Dank, dass Sie uns kontaktiert haben! Wir haben Ihre Nachricht erhalten und werden uns so schnell wie möglich per E-Mail bei Ihnen melden.",
  "p-help-intro-text":
    "Wenn Sie auf Probleme stoßen oder Fragen haben, zögern Sie nicht, uns zu kontaktieren. Füllen Sie das untenstehende Formular aus, und wir werden uns so schnell wie möglich bei Ihnen melden.",
  "p-help-label-name": "Name",
  "p-help-placeholder-name": "Geben Sie hier Ihren Namen ein",
  "p-help-label-email": "E-Mail",
  "p-help-placeholder-email": "Geben Sie hier Ihre E-Mail ein",
  "p-help-label-message": "Nachricht",
  "p-help-placeholder-message": "...",
  "p-help-submit-button": "Senden",
};
