export default {
  "c-feedback-create-fastTransaction": "Transacción rápida",
  "c-feedback-create-politeFriendly": "Cortés y amigable",
  "c-feedback-create-patient": "Paciente",
  "c-feedback-create-safeTrustworthy": "Seguro y confiable",
  "c-feedback-create-goodPrice": "Buen precio",
  "c-feedback-create-successMessage": "¡Gracias por tus comentarios!",
  "c-feedback-create-errorMessage":
    "Ocurrió un error al enviar los comentarios",
  "c-feedback-create-feedbackTitle": "Comentarios",
  "c-feedback-create-experienceQuestion": "¿Cómo fue tu experiencia?",
  "c-feedback-create-positive": "Positiva",
  "c-feedback-create-negative": "Negativa",
  "c-feedback-create-feedbackPlaceholder": "Por favor, escribe tus comentarios",
  "c-feedback-create-anonymous": "Anónimo",
  "c-feedback-create-anonymousHint":
    "envía tus comentarios sin mostrar tu nombre",
  "c-feedback-create-cancel": "Cancelar",
  "c-feedback-create-submit": "Enviar comentarios",

  "c-feedback-item-anonymously": "(anónimo)",
  "c-feedback-item-positive": "Positiva",
  "c-feedback-item-negative": "Negativa",
};
