export default {
  "c-offers-table-loginWarning":
    "Ձեզ անհրաժեշտ է մուտք գործել՝ պատվեր ստեղծելու համար",
  "c-offers-table-toggleError":
    "Ինչ-որ սխալ տեղի ունեցավ առաջարկը ակտիվացնելիս",
  "c-offers-table-insufficientBalance":
    "Դուք չունեք բավարար մնացորդ՝ այս առաջարկը ակտիվացնելու համար",
  "c-offers-table-goToWallet": "գնալ դրամապանակ",
  "c-offers-table-seller": "Վաճառող",
  "c-offers-table-buyer": "Գնորդ",
  "c-offers-table-price": "Գին",
  "c-offers-table-availableLimit": "Հասանելի սահման",
  "c-offers-table-available": "Հասանելի",
  "c-offers-table-offerLimits": "Առաջարկի սահմաններ",
  "c-offers-amount-unlimited": "առաջարկի գումարը անսահման է",
  "c-offers-table-paymentOptions": "Վճարման տարբերակներ",
  "c-offers-table-more": "ավելին",
  "c-offers-table-active": "ակտիվ",
  "c-offers-table-disabled": "դադարեցված",
  "c-offers-table-edit": "խմբագրել",
  "c-offers-table-delete": "ջնջել",
  "c-offers-table-buy": "Գնել",
  "c-offers-table-sell": "Վաճառել",
};
