export default {
  "p-exchange-tos-agreement-intro":
    "Diese Benutzervereinbarung (im Folgenden als 'Vereinbarung' bezeichnet) ist eine elektronische Vereinbarung zwischen dem Benutzer und dem SolidSwap-Dienst und enthält die Regeln für die Arbeit auf der SolidSwap-Website unter",
  "p-exchange-tos-agreement-intro-continued":
    "(im Folgenden als 'Website' bezeichnet) sowie die Bedingungen für den Zugriff auf die von der Website bereitgestellten Dienste. Bevor der Benutzer mit der Arbeit auf der Website beginnt (im Folgenden als 'Benutzer' bezeichnet), muss er die beschriebenen Bedingungen sorgfältig lesen und diese Vereinbarung akzeptieren. Wenn der Benutzer die Vereinbarung nicht akzeptiert und sich nicht verpflichtet, alle Bedingungen dieser Vereinbarung einzuhalten, sollte er die Nutzung der Website sofort einstellen.",
  "p-exchange-tos-agreement-updates":
    "Die Website-Verwaltung behält sich das Recht vor, nach eigenem Ermessen den Text der Vereinbarung zu aktualisieren und zu ändern, ohne die Benutzer zu benachrichtigen. Die neue Version der Vereinbarung tritt unmittelbar nach ihrer Veröffentlichung auf der Website in Kraft. Der Benutzer ist verpflichtet, eigenständig neue Versionen der Vereinbarung zu überwachen. Durch die fortgesetzte Nutzung der Website nach Inkrafttreten der Änderungen akzeptiert der Benutzer automatisch die neue Version der Vereinbarung. Wenn der Benutzer mit den Bedingungen der neuen Version der Vereinbarung nicht einverstanden ist, sollte er sein Konto deaktivieren, falls vorhanden, und die Dienste des Dienstes nicht nutzen.",
  "p-exchange-tos-terms-definitions": "1. Begriffe und Definitionen",
  "p-exchange-tos-solid-swap-service":
    "1.1. Der SolidSwap-Dienst ist eine Plattform, die für Handelsoperationen mit virtuellen digitalen Vermögenswerten entwickelt wurde.",
  "p-exchange-tos-user-definition":
    "1.2. Der Benutzer ist eine auf der Plattform registrierte natürliche Person, die den Bedingungen dieser Vereinbarung zugestimmt hat. Der Benutzer bestätigt, dass er das 18. Lebensjahr erreicht hat, dass die Gesetze seines Wohnsitzlandes die Nutzung dieses Dienstes nicht verbieten und dass der Benutzer keine illegalen Aktivitäten durch die Nutzung der SolidSwap-Dienste plant.",
  "p-exchange-tos-title-sign":
    "1.3. Das Titelzeichen ist eine übliche Einheit des Zahlungssystems für elektronische und andere Währungen, die den Berechnungen elektronischer Systeme entspricht und die Menge der Rechte anzeigt, die der Vereinbarung verschiedener Zahlungssysteme und deren Eigentümer entsprechen.",
  "p-exchange-tos-payment-system":
    "1.4. Zahlungssystem ist ein Online-Dienst, der die Bezahlung von Waren und Dienstleistungen durch elektronische Geldtransfers ermöglicht.",
  "p-exchange-tos-service-operations":
    "1.5. Dienste des Dienstes – Operationen zur Ein- und Auszahlung elektronischer Währungen sowie andere Dienste, deren Informationen auf der Website des Dienstes veröffentlicht sind.",
  "p-exchange-tos-payment-definition":
    "1.6. Zahlung ist eine Geldüberweisung, die mit einem Zahlungssystem durchgeführt wird.",
  "p-exchange-tos-otc-platform":
    "1.7. OTC-Plattform ist eine Handelsplattform zur Suche nach Gegenparteien und Durchführung von außerbörslichen Transaktionen.",
  "p-exchange-tos-personal-data":
    "1.8. Persönliche Daten – Informationen, die eine Person identifizieren, wie Name, Adresse, E-Mail-Adresse, Mobiltelefonnummer, Angebotsinformationen und Bankdaten. Der Begriff 'Persönliche Daten' umfasst keine anonymen und/oder aggregierten Daten, die keine Identifizierung eines bestimmten Benutzers ermöglichen.",
  "p-exchange-tos-agreement-definition":
    "1.9. Vereinbarung – diese Benutzervereinbarung und ihre wesentlichen Bestandteile, unter Berücksichtigung aller Ergänzungen und/oder Änderungen, die in der durch deren Bedingungen festgelegten Weise vorgenommen werden können.",
  "p-exchange-tos-website-definition":
    "1.10. Website – eine Sammlung von Informationen, Texten, Grafikelementen, Design, Bildern, Fotos und Videos sowie anderen Ergebnissen geistiger Tätigkeiten, ebenso wie eine Sammlung von Computerprogrammen, die in einem Informationssystem enthalten sind und die Verfügbarkeit solcher Informationen gewährleisten unter",
  "p-exchange-tos-website-continued":
    ", einschließlich aller Subdomains, und falls die Domain oder das Material der Website aus irgendeinem Grund geändert werden muss, umfasst dies die Website nach solchen Änderungen.",
  "p-exchange-tos-intellectual-property":
    "1.11. Geistiges Eigentum – alle auf der Website veröffentlichten Materialien sowie alle vom Dienst bereitgestellten Dienste sind Eigentum des Dienstes und dürfen nicht von Dritten verwendet, veröffentlicht, verteilt oder vervielfältigt werden.",
  "p-exchange-tos-application-definition":
    "1.12. Antrag – der Benutzer gibt ein Angebot zum Kauf oder Verkauf von virtuellen digitalen Vermögenswerten unter Nutzung der vom Dienst bereitgestellten Dienste ab.",
  "p-exchange-tos-electronic-document":
    "1.13. Elektronisches Dokument – Informationen in elektronischer und digitaler Form.",
  "p-exchange-tos-suspicious-traffic":
    "1.14. Verdächtiger Verkehr – eine Reihe von Handlungen, Transaktionen, Vermögenswerten usw., die möglicherweise gegen AML/CTF- und KYC-Richtlinien verstoßen.",
  "p-exchange-tos-verification-procedures":
    "1.15. Verifizierungsverfahren (KYC) – Verifizierungsverfahren im Rahmen der Geldwäschebekämpfungsstandards – der 'Know Your Customer'-Richtlinie (KYC).",
  "p-exchange-tos-persons-subject-restrictions":
    "1.16. Personen mit Einschränkungen – jede Person in den Vereinigten Staaten und/oder jede Person, die auf der Liste der Sonderkategorie-Bürger oder blockierten Personen des US-Finanzministeriums, der konsolidierten Liste der EU-Finanzsanktionen oder der UK-Sanktionsliste aufgeführt ist, ebenso wie Bürger und Einwohner von eingeschränkten Gerichtsbarkeiten (Abschnitt 1.17).",
  "p-exchange-tos-limited-jurisdiction":
    "1.17. Eingeschränkte Gerichtsbarkeit – eingeschränkte Gerichtsbarkeiten umfassen: Vereinigte Staaten von Amerika, Afghanistan, Bosnien und Herzegowina, Demokratische Volksrepublik Korea, Demokratische Republik Kongo, Eritrea, Äthiopien, Guyana, Iran, Irak, Demokratische Volksrepublik Laos, Libyen, Somalia, Sri Lanka, Sudan, Syrien, Trinidad und Tobago, Tunesien, Uganda, Vanuatu, Ukraine, Vereinigtes Königreich, Festlandchina, Singapur, Hongkong, Kanada, Kuba, Usbekistan, Krim, Frankreich, Sewastopol, Gebiete Donezk und Lugansk.",
  "p-exchange-tos-introduction": "2. Einführung",
  "p-exchange-tos-introduction-text":
    "2.1. Diese Vereinbarung regelt das Verhältnis zwischen dem Benutzer und dem Dienst im Rahmen der vom Dienst dem Benutzer bereitgestellten Dienste und hebt alle vorherigen Vereinbarungen zwischen dem Dienst und dem Benutzer zu diesem Thema auf.",
  "p-exchange-tos-subject-agreement": "3. Gegenstand der Vereinbarung",
  "p-exchange-tos-subject-agreement-services":
    "3.1. Der Gegenstand dieser Vereinbarung sind die vom Dienst bereitgestellten Dienste, die auf der Website des Dienstes beschrieben sind.",
  "p-exchange-tos-subject-agreement-restrictions":
    "3.2. Der Dienst bietet seine Dienste allen interessierten Benutzern an, mit Ausnahme von Personen, die Einschränkungen unterliegen (Abschnitt 1.16).",
  "p-exchange-tos-payment-systems-responsibility":
    "3.3. Zahlungssysteme und/oder Finanzinstitute sind allein für die Gelder verantwortlich, die der Benutzer ihnen anvertraut hat. Der Dienst kann keine Vertragspartei einer Vereinbarung zwischen dem Zahlungssystem und/oder einem Finanzinstitut und dem Benutzer sein und ist in keiner Weise für die falsche oder unbefugte Nutzung des Zahlungssystems durch den Benutzer sowie für den Missbrauch der Funktionen des Zahlungssystems durch den Benutzer verantwortlich. Die gegenseitigen Rechte und Pflichten des Benutzers und des Zahlungssystems und/oder Finanzinstituts werden durch die entsprechenden Vereinbarungen geregelt.",
  "p-exchange-tos-operation-cannot-cancel":
    "3.4. Jede vom Benutzer eingeleitete Operation zum Austausch von Geldern sowie jede andere vom Dienst dem Benutzer angebotene Operation kann vom Benutzer nicht storniert werden.",
  "p-exchange-tos-rights-obligations": "4. Rechte und Pflichten der Parteien",
  "p-exchange-tos-suspend-operation-authorities":
    "4.1. Der Dienst hat das Recht, eine laufende Operation auszusetzen oder zu stornieren, wenn die zuständigen Behörden Informationen über die Rechtswidrigkeit des Eigentums des Benutzers an den Geldern erhalten und/oder andere Informationen vorliegen, die es dem Dienst unmöglich machen, dem Benutzer Dienste anzubieten.",
  "p-exchange-tos-suspend-refund-operation":
    "4.2. Der Dienst hat das Recht, eine laufende Operation auszusetzen, eine Rückerstattung zu verweigern oder eine laufende Operation zu stornieren, wenn der Benutzer gegen die Bedingungen dieser Vereinbarung verstößt.",
  "p-exchange-tos-cancel-operation-title-return":
    "4.3. Der Dienst hat das Recht, die laufende Operation zu stornieren und die vom Benutzer eingegebenen Titelzeichen ohne Angabe von Gründen zurückzugeben.",
  "p-exchange-tos-prevent-illegal-activities":
    "4.4. Der Dienst verpflichtet sich, alle möglichen und verfügbaren Maßnahmen zu ergreifen, um Versuche des illegalen Handels, Finanzbetrugs und der Geldwäsche mithilfe der Dienste des Dienstes zu verhindern. Diese Maßnahmen umfassen: Bereitstellung aller möglichen Unterstützung für Strafverfolgungsbehörden bei der Suche und Festnahme von finanziellen Terroristen, die illegale Geldwäscheaktivitäten betreiben; Bereitstellung von Informationen an die zuständigen Behörden gemäß den geltenden Gesetzen bezüglich der Verarbeitung des Dienstes; Verbesserung des Dienstes, um die direkte oder indirekte Nutzung des Dienstes in Aktivitäten zu verhindern, die gegen Gesetze zur Bekämpfung des illegalen Handels, Finanzbetrugs und der Geldwäsche verstoßen.",
  "p-exchange-tos-suspicious-traffic-rights":
    "4.5. Im Falle des Auffindens verdächtigen Verkehrs auf Seiten des Benutzers behält sich der Dienst das Recht vor, auf Anfrage der Strafverfolgungsbehörden alle Informationen über die Identität, den Antrag auf Austausch und die vom Benutzer erhaltene Währung zu übermitteln.",
  "p-exchange-tos-accept-agreement":
    "4.6. Durch die Nutzung der auf der Website bereitgestellten Dienste akzeptiert und bestätigt der Benutzer, dass er diesen Regeln und den AML/CTF- und KYC-Richtlinien zustimmt.",
  "p-exchange-tos-user-guarantee":
    "4.7. Durch die Nutzung der auf der Website bereitgestellten Dienste garantiert der Benutzer, dass er vollständige, wahrheitsgemäße und aktuelle Informationen über sich selbst bereitstellt, dass die Nutzung der Dienste des Dienstes ausschließlich für persönliche Zwecke erfolgt, die nicht gegen die Gesetze des Landes verstoßen, in dem sich der Benutzer befindet, und verpflichtet sich außerdem, die Daten rechtzeitig zu aktualisieren, um ihre Aktualität zu gewährleisten.",
  "p-exchange-tos-user-confidentiality":
    "4.8. Der Benutzer garantiert die Vertraulichkeit der Registrierungsdaten und den Zugriff auf sein Konto und übernimmt die volle Verantwortung für alle Handlungen, die unter seinem Konto stattfinden, einschließlich im Falle von Hacking oder unbefugtem Zugriff.",
  "p-exchange-tos-report-security-breach":
    "4.9. Der Benutzer verpflichtet sich, Verstöße gegen die Sicherheit seines Kontos unverzüglich zu melden.",
  "p-exchange-tos-block-account":
    "4.10. Der Dienst hat das Recht, das Konto des Benutzers zu sperren, wenn Verdacht auf illegale Handlungen besteht, Verdacht auf Hacking des Kontos oder im Falle eines Verstoßes gegen die Regeln des Dienstes. Das Konto des Benutzers wird ohne das Recht auf Wiederherstellung gesperrt, wenn der Benutzer den Betrieb des Dienstes behindert.",
  "p-exchange-tos-rightful-owner":
    "4.11. Durch die Annahme der Bedingungen dieser Vereinbarung garantiert der Benutzer, dass er der rechtmäßige Eigentümer von Geldern und/oder Kryptowährungen ist.",
  "p-exchange-tos-understand-service-risks":
    "4.12. Durch die Annahme dieser Benutzervereinbarung bestätigt der Benutzer, dass er die Funktionsweise des Dienstes versteht und die volle Verantwortung für alle Risiken und Verluste übernimmt, die mit der Nutzung des Dienstes verbunden sind.",
  "p-exchange-tos-consent-data-processing":
    "4.13. Der Benutzer gibt seine Zustimmung zur Verarbeitung der im Rahmen der Registrierung auf der Website, im Rahmen des Verifizierungsverfahrens sowie während der Erfassung von Informationen über den Kunden auf der Website angegebenen persönlichen Daten.",
  "p-exchange-tos-personal-data-includes": "4.14. Persönliche Daten umfassen:",
  "p-exchange-tos-name": "Nachname, Vorname, Patronymikon;",
  "p-exchange-tos-photo-passport": "Foto/Pass-Scan;",
  "p-exchange-tos-other-id-documents":
    "andere Identifikationsdokumente; (je nach Einzelfall)",
  "p-exchange-tos-video-selfie": "Video-Selfie mit Pass in der Hand;",
  "p-exchange-tos-email": "E-Mail-Adresse;",
  "p-exchange-tos-phone-number":
    "Mobiltelefonnummer (die zur Kontaktaufnahme mit dem Kunden verwendet werden kann);",
  "p-exchange-tos-wallet-details":
    "Details der verwendeten Wallets; (je nach Einzelfall);",
  "p-exchange-tos-card-number":
    "Kartennummer und/oder Bankkonto; (je nach Einzelfall);",
  "p-exchange-tos-transactions-wallets": "Transaktionen und Wallet-Adressen.",
  "p-exchange-tos-processing-personal-data":
    "4.15. Die Verarbeitung personenbezogener Daten bedeutet die Aufzeichnung, Systematisierung, Ansammlung, Speicherung, Klärung (Aktualisierung, Änderung), Extraktion, Nutzung, Übertragung (Verbreitung, Bereitstellung, Zugriff), einschließlich grenzüberschreitend, Anonymisierung, Sperrung, Löschung, Zerstörung personenbezogener Daten, die nicht in besondere Kategorien fallen, deren Verarbeitung eine schriftliche Zustimmung des Benutzers erfordert.",
  "p-exchange-tos-processing-purpose":
    "4.16. Die Verarbeitung personenbezogener Daten erfolgt, um den Parteien die Erfüllung ihrer Verpflichtungen im Rahmen der Vereinbarung zu ermöglichen, den Benutzer auf der Website zu registrieren, dem Benutzer technischen Support zu leisten, Anträge zu stellen, Dienstleistungen zu erbringen, Beschwerden und Ansprüche zu bearbeiten, und um dem Benutzer Informations- und andere Nachrichten an die E-Mail-Adresse des Benutzers zu senden.",
  "p-exchange-tos-fincen-rule":
    "4.17. Gemäß der endgültigen Regel des Financial Crimes Investigation Network (FinCEN) gemäß Artikel 31 des Code of Federal Rules 1010.100(ff), der jedes ausländische Unternehmen, das Abrechnungs- und Bargelddienste anbietet, verpflichtet, einen in den USA ansässigen Vertreter des Dienstes als Vertreter zu benennen, um das Verfahren gemäß den Anforderungen der BSA Association und deren Satzung durchzuführen, bietet SolidSwap keine E-Währungsdienste für US-Bürger und Benutzer mit Wohnsitz in den Vereinigten Staaten an.",
  "p-exchange-tos-legal-prohibited":
    "4.18. Die von SolidSwap angebotenen Dienstleistungen können in einigen Gerichtsbarkeiten gesetzlich verboten sein. Durch die Annahme der Nutzungsbedingungen von SolidSwap bestätigen Sie, dass Sie kein Einwohner einer solchen Gerichtsbarkeit sind.",
  "p-exchange-tos-aml-kyc-checks":
    "4.19. Der Dienst hat das Recht, AML- und KYC-Prüfungen durchzuführen, um die 'Fünfte Richtlinie zur Bekämpfung der Geldwäsche und Terrorismusfinanzierung (5AMLD)' einzuhalten.",
  "p-exchange-tos-kyt-aml-violation":
    "4.19.1. Wenn im Rahmen der KYT/AML-Überprüfung von Kryptowährungstransaktionen/-vermögenswerten ein Verstoß gegen die 'AML/CTF- und KYC-Richtlinie' festgestellt wird, werden Gelder blockiert und eine zusätzliche Benutzerverifizierung (KYC) gemäß Abschnitt 8.5 dieser Vereinbarung angefordert, sowie ein Nachweis über die Rechtmäßigkeit der Herkunft der Gelder.",
  "p-exchange-tos-kyt-aml-non-return":
    "4.19.2. Wenn im Rahmen der KYT/AML-Überprüfung von Kryptowährungstransaktionen/-vermögenswerten ein Verstoß gegen die 'AML/CTF- und KYC-Richtlinie' festgestellt wird und/oder die Situation unter Abschnitt 9.4.2 dieser Vereinbarung fällt, dürfen die Vermögenswerte weder ausgetauscht noch zurückgegeben werden.",
  "p-exchange-tos-services-service": "5. Dienstleistungen des Dienstes",
  "p-exchange-tos-order-services":
    "5.1. Die Bestellung der Dienstleistungen des Dienstes und die Informationsabfrage über den Fortschritt der Transaktion durch den Benutzer erfolgen ausschließlich über Kontakte mit einem Vertreter des Dienstes.",
  "p-exchange-tos-service-internal-schedule":
    "5.2. Der Dienst bietet seine Dienstleistungen gemäß dem festgelegten internen Zeitplan des Dienstes an.",
  "p-exchange-tos-questions-complaints":
    "5.3. Fragen und Beschwerden zu Anträgen werden innerhalb von 24 Stunden nach der Registrierung angenommen. Nach 24 Stunden gelten die Austauschvorgänge je nach ihrem Status zum Ablauf des angegebenen Zeitraums als ordnungsgemäß ausgeführt oder nicht bezahlt.",
  "p-exchange-tos-suspicious-actions":
    "5.4. Bei verdächtigen Handlungen des Kunden sowie bei der Verwendung verdächtiger Vermögenswerte durch den Benutzer kann der Dienst die Zahlung verzögern und eine zusätzliche Identitätsüberprüfung sowie eine interne Analyse der vom Benutzer verwendeten Gelder verlangen.",
  "p-exchange-tos-right-to-refuse":
    "5.5. Der Dienst behält sich das Recht vor, den Austausch einseitig abzulehnen.",
  "p-exchange-tos-restricted-citizens":
    "5.6. Die von SolidSwap angebotenen Dienstleistungen sind für Personen, die Einschränkungen unterliegen (Abschnitt 1.16), verboten.",
  "p-exchange-tos-cost-services": "6. Kosten der Dienstleistungen",
  "p-exchange-tos-tariffs":
    "6.1. Die Tarife werden von der Geschäftsführung des Dienstes festgelegt und auf der Website des Dienstes veröffentlicht. Die Geschäftsführung des Dienstes hat das Recht, die Tarife ohne zusätzliche Benachrichtigung zu ändern.",
  "p-exchange-tos-expectations-tariffs":
    "6.2. Der Dienst ist nicht verantwortlich für die Erwartungen des Kunden bezüglich Änderungen der Tarifpläne und der Kosten für Dienstleistungen.",
  "p-exchange-tos-guarantees-responsibilities":
    "7. Garantien und Verantwortlichkeiten der Parteien",
  "p-exchange-tos-guarantee-fulfillment":
    "7.1. Der Dienst garantiert die Erfüllung seiner Verpflichtungen gegenüber dem Benutzer nur im Rahmen der Beträge, die der Benutzer dem Dienst anvertraut hat, um die Operationen innerhalb der Dokumentation des Dienstes durchzuführen.",
  "p-exchange-tos-confidentiality-guarantee":
    "7.2. Der Dienst garantiert die Vertraulichkeit der Informationen über den Benutzer und seine Operationen. Der Dienst kann diese Informationen nur auf Anfrage der zuständigen Regierungsbehörden, Beamten oder Vertreter der Zahlungssysteme weitergeben, wenn es berechtigte Gründe dafür gibt.",
  "p-exchange-tos-efforts-technical-issues":
    "7.3. Der Dienst unternimmt alle Anstrengungen, um den reibungslosen Betrieb des Dienstes zu gewährleisten, garantiert jedoch nicht das Fehlen technischer Probleme, die zu einer vollständigen oder teilweisen Aussetzung des Dienstes führen. Der Dienst ist nicht verantwortlich für Verluste, entgangenen Gewinn und andere Kosten, die dem Benutzer aufgrund der Unmöglichkeit des Zugriffs auf die Website und die Dienste des Dienstes entstehen.",
  "p-exchange-tos-damage-responsibility":
    "7.4. Der Dienst ist nicht verantwortlich für Schäden, die dem Benutzer entstehen, selbst wenn deren Möglichkeit im Voraus bekannt war. Der Dienst ist nicht verantwortlich für das Erlangen von Informationen über die Aktivitäten des Benutzers durch Dritte im Falle eines Systemhacks und der Erlangung von Daten durch Dritte.",
  "p-exchange-tos-no-responsibility-losses":
    "7.5. Der Dienst übernimmt keine Verantwortung für Verluste, entgangenen Gewinn und andere Kosten des Benutzers, die sich aus den falschen Erwartungen des Benutzers bezüglich der Tarife des Dienstes, der Rentabilität von Transaktionen und anderen subjektiven Faktoren ergeben.",
  "p-exchange-tos-aml-risk-score":
    "7.6. Der Dienst ist nicht verantwortlich für die Änderung des Risikowerts (AML-Risikowert) von Kunden-Wallets nach dem Austausch.",
  "p-exchange-tos-compensation":
    "7.7. Beim Besuch der Website des Dienstes, der Nutzung der Dienste des Dienstes und des Austauschs garantiert der Benutzer die Entschädigung für Verluste des Dienstes (Verwaltungsgesellschaft, Manager und Mitarbeiter) im Falle von Klagen oder Ansprüchen, die direkt oder indirekt mit der Nutzung der Dienste des Dienstes durch den Benutzer zusammenhängen.",
  "p-exchange-tos-not-responsible-communication":
    "7.8. Der Benutzer erklärt sich damit einverstanden, dass der Dienst nicht verantwortlich ist im Falle einer Unterbrechung der Kommunikation, einer Unterbrechung des Betriebs des Internets sowie im Falle höherer Gewalt.",
  "p-exchange-tos-guarantee-owner-funds":
    "7.9. Der Benutzer garantiert, dass er der Eigentümer ist oder berechtigte Gründe hat, über die in seinen Transaktionen verwendeten Gelder, Banküberweisungen usw. zu verfügen.",
  "p-exchange-tos-no-falsify-communication":
    "7.10. Der Benutzer verpflichtet sich, keine Kommunikationsflüsse im Zusammenhang mit dem Betrieb des Dienstes zu fälschen.",
  "p-exchange-tos-force-majeure": "8. Höhere Gewalt",
  "p-exchange-tos-force-majeure-definition":
    "8.1. Die Parteien sind von der Haftung für die vollständige oder teilweise Nichterfüllung von Verpflichtungen aus dieser Vereinbarung befreit, wenn die Nichterfüllung der Verpflichtungen das Ergebnis von höherer Gewalt war, nämlich: Feuer, Überschwemmung, Erdbeben, Terroranschläge, Machtwechsel, Bürgerunruhen, DDOS-Angriffe auf den Dienst sowie Nichtfunktionieren von Zahlungssystemen, Stromversorgungssystemen, Kommunikationsnetzen und Internetdienstanbietern.",
  "p-exchange-tos-error-during-payment":
    "8.2. Im Falle eines Fehlers bei der Bezahlung des Austauschs erhält der Benutzer eine Benachrichtigung über den Status des Antrags und/oder an die angegebene E-Mail-Adresse.",
  "p-exchange-tos-payment-after-deadline":
    "8.3. Wenn der Antrag nach Ablauf der für die Bezahlung des Antrags vorgesehenen Zeit bezahlt wird, behält sich der Dienst das Recht vor, die Zahlung für den Antrag abzüglich der Netzwerkkommission und der Dienstkosten an den Benutzer zurückzuerstatten oder nach eigenem Ermessen neu zu berechnen.",
  "p-exchange-tos-not-responsible-assets-fiat":
    "8.3.1 Der Dienst ist nicht verantwortlich für die vom Benutzer zum Austausch von 'geben' Fiat, 'erhalten' Kryptowährung gesendeten Vermögenswerte nach Ablauf der für die Bezahlung des Antrags vorgesehenen Zeit.",
  "p-exchange-tos-not-responsible-assets-anonymous":
    "8.3.2 Der Dienst ist nicht verantwortlich für Vermögenswerte, die vom Benutzer mit einer anonymen Methode gesendet wurden, die die Absenderadresse verbirgt, da sie dem Wallet-Saldo des Austauschdienstes nicht gutgeschrieben werden. In diesem Fall ist weder ein Austausch noch eine Rückerstattung möglich.",
  "p-exchange-tos-deviation-exchange-rate":
    "8.4. Wenn der Kurs des Antrags um mehr als 3 % vom Wechselkurs an der Bybit/Binance-Börse abweicht, einschließlich bei einem technischen Fehler der Website, hat der Austauschdienst das Recht, den Antrag zum aktuellen Kurs auf der Website neu zu berechnen oder eine Rückerstattung an den Benutzer abzüglich der Dienstkosten und der Netzwerkkommission vorzunehmen.",
  "p-exchange-tos-invalid-exchanges":
    "8.5. Falls der Benutzer absichtlich oder unabsichtlich, ebenso wie bei einem technischen Fehler (einschließlich auf Seiten des SolidSwap-Dienstes), durch Handlungen von Eindringlingen oder aus anderen Gründen einen Austausch vorgenommen hat, der dem SolidSwap-Dienst Verluste verursacht hat, wobei die Abweichung vom Marktkurs im ausgeführten Antrag mehr als 3 % des Wechselkurses an der Bybit/Binance-Börse betrug (diese Regel gilt für jede Seite des Austauschs), werden Anträge, die unter den oben genannten Bedingungen gestellt wurden, als ungültig angesehen. Der Dienst hat das Recht, den erlittenen Schaden durch Zurückhaltung zukünftiger Gelder des Benutzers im Rahmen der Interaktion mit dem Dienst zu kompensieren.",
  "p-exchange-tos-suspicious-assets":
    "8.6. Bei Auffinden verdächtiger Vermögenswerte/Fonds, die gegen die AML-Vereinbarung des Dienstes verstoßen, und bei verdächtigen/betrügerischen Handlungen des Benutzers wird die Transaktion von der Sicherheitsabteilung für bis zu 180 Tage zur weiteren Untersuchung blockiert.",
  "p-exchange-tos-unblocking-procedure":
    "Um das Entsperrungsverfahren zu starten, muss eine vereinfachte Benutzerverifizierung (KYC) gemäß Abschnitt 4.19 dieser Vereinbarung durchgeführt werden.",
  "p-exchange-tos-send-photo-passport":
    "Ein Foto oder Scan Ihres Reisepasses oder eines anderen Identifikationsdokuments (nach Ihrer Wahl);",
  "p-exchange-tos-send-phone-number":
    "Eine Mobiltelefonnummer, die zur Kontaktaufnahme mit Ihnen verwendet werden kann (optional);",
  "p-exchange-tos-send-video-selfie": "Video-Selfie mit einem Ausweisdokument;",
  "p-exchange-tos-record-video":
    "Nehmen Sie ein kurzes Video in guter Qualität ohne Unterbrechungen auf und sprechen Sie die folgenden Daten deutlich aus:",
  "p-exchange-tos-record-full-name":
    "Vollständiger Name, Seriennummer und Nummer des Ausweisdokuments, halten Sie es neben Ihr Gesicht;",
  "p-exchange-tos-service-name":
    "Der Name unseres Dienstes und das Datum der Videoaufnahme;",
  "p-exchange-tos-application-number":
    "Die Antragsnummer und das Datum ihrer Erstellung;",
  "p-exchange-tos-show-wallet":
    "Zeigen Sie das Wallet/Konto/sonstiges, von dem genau die Zahlung/Transaktion durchgeführt wurde, die Währung und den Betrag. Der Name und die Adresse (URL) der Website müssen lesbar sein, wenn die Transaktion über eine mobile Anwendung durchgeführt wurde.",
  "p-exchange-tos-write-explanation":
    "Schreiben Sie in freier Form auf ein Blatt Papier handschriftlich eine kurze Erklärung zur Rechtmäßigkeit der Herkunft der Gelder sowie, dass Sie uns von Ihrem Wallet (Adresse) an Ihr Wallet (Adresse und Hash der Transaktion) gesendet haben und dass Sie der Eigentümer der Vermögenswerte sind, nicht ein Dritter. Schreiben Sie die Adresse, an die Sie die Rückgabe der Vermögenswerte verlangen.",
  "p-exchange-tos-specify-address":
    "Geben Sie die Adresse/das Konto des Wallets an, an das die Vermögenswerte zurückgegeben werden sollen.",
  "p-exchange-tos-information-deadline":
    "Wir erwarten die oben genannten Informationen von Ihnen spätestens 48 Stunden nach Erhalt dieser E-Mail.",
  "p-exchange-tos-no-unblocking-without-data":
    "Ohne die angeforderten Daten kann SolidSwap das Verfahren zur Entsperrung Ihrer Vermögenswerte nicht starten.",
  "p-exchange-tos-blocking-period-expiration":
    "8.6.1. Nach Ablauf der Sperrfrist (180 Tage) ab dem Datum der Identitätsverifizierung (KYC) muss der Benutzer den Kundendienst des Dienstes eigenständig per offizieller E-Mail kontaktieren, spätestens 30 Tage später.",
  "p-exchange-tos-incorrect-details":
    "8.7. Der Dienst trägt keine finanzielle Verantwortung gegenüber dem Benutzer, wenn der Benutzer in dem Antrag falsche Angaben gemacht hat, um Gelder vom Dienst zu erhalten, und der Dienst eine Zahlung an den Benutzer gemäß den im Antrag gemachten Angaben gesendet hat.",
  "p-exchange-tos-payment-delay":
    "8.8. Im Falle eines Fehlers im Bank-/Drittanbieter-Zahlungssystem können Zahlungen aus Gründen, die außerhalb des Einflussbereichs des Dienstes liegen, bis zu 5 Bankarbeitstage verzögert werden.",
  "p-exchange-tos-deposit-outside-application":
    "8.9. Eine Einzahlung, die außerhalb des Antrags erfolgt, wird möglicherweise nicht gutgeschrieben oder geht verloren. Die Zahlung von Geldern, die unter Verwendung veralteter Daten gesendet wurden, erfolgt mit einer 5 % Gebühr.",
  "p-exchange-tos-non-current-details":
    "8.10. Der Dienst ist nicht verantwortlich, wenn der Benutzer Zahlungsdaten angegeben hat, die sich von der im Austausch angegebenen Bank unterscheiden. Die Zahlung von Geldern an nicht aktuelle Bankdaten erfolgt innerhalb von 48 Stunden mit einer Gebühr von 5 %.",
  "p-exchange-tos-fiat-exchange": "9.1. Fiat-Austausch",
  "p-exchange-tos-fiat-exchange-amount-limit":
    "9.1.1. Austausche für Beträge bis zu oder gleich 150 $ erfolgen ohne Verzögerung. Wenn Sie Ihr Konto im Zahlungssystem zum ersten Mal verwenden, um unseren Dienst zu bezahlen, und einen Betrag von mehr als 150 $ austauschen, wird Ihr Antrag 2 Stunden nach Zahlungseingang bearbeitet.",
  "p-exchange-tos-subsequent-exchanges":
    "9.1.2. Nachfolgende Austausche für Beträge über 150 $ mit derselben Kontonummer im Zahlungssystem und dem Konto des Benutzers erfolgen sofort, sofern Sie sich vor dem ersten Austausch registriert haben.",
  "p-exchange-tos-additional-checks":
    "9.1.3. Wenn seit dem ersten Austausch weniger als 24 Stunden vergangen sind und der Gesamtbetrag der Austausche durch Bankdaten 500 $ übersteigt, kann eine zusätzliche Überprüfung mit einer Verzögerung von bis zu 48 Stunden durch die Sicherheitsabteilung vorgenommen werden.",
  "p-exchange-tos-suspicious-actions-fiat":
    "9.1.4. Im Falle verdächtiger Handlungen des Kunden behält sich der Dienst das Recht vor, Kontodaten anzufordern, die bestätigen, dass das Konto dem Kunden gehört.",
  "p-exchange-tos-crypto-exchange": "9.2. Kryptowährungs-Austausch",
  "p-exchange-tos-crypto-auto-manual":
    "9.2.1. Anträge, die vom Dienst im automatischen Modus gestellt werden, können bis zu 12 Stunden dauern. Anträge, die im manuellen Modus gestellt werden, können bis zu 36 Stunden dauern.",
  "p-exchange-tos-sale-cryptocurrencies":
    "9.2.2. Anträge auf den Verkauf aller auf der Website des Dienstes aufgeführten Kryptowährungen werden bearbeitet, wenn die Transaktion die festgelegte Mindestanzahl an Bestätigungen erhalten hat (das Minimum wird vom SolidSwap-Dienst festgelegt).",
  "p-exchange-tos-recalculate-rate":
    "9.2.3. Der Dienst hat das Recht, Ihren Antrag zugunsten der Wechselstube neu zu berechnen (sowohl zum aktuellen Kurs als auch zum Kurs, der zum Zeitpunkt der Erstellung des Antrags galt), wenn die Transaktion innerhalb der für den Antrag vorgesehenen Zeit nicht die erforderliche Anzahl an Netzwerkbestätigungen erhalten hat.",
  "p-exchange-tos-btc-confirmations":
    "9.2.4. Die Zahlung für den Austausch von BTC-Kryptowährung erfolgt nach der dritten Bestätigung im Bitcoin-Netzwerk. Der Kurs im Antrag bleibt fest, wenn die Zahlung vom Benutzer innerhalb der für die Bezahlung des Antrags vorgesehenen Zeit in dieser Richtung vorgenommen wurde und die Transaktion in dieser Zeit im Netzwerk erschien und die BTC-Kryptowährung innerhalb von 15 Minuten eine Bestätigung im Bitcoin-Netzwerk erhalten hat. Wenn die erste Bestätigung nach 15 Minuten erfolgt, kann der Antrag zum aktuellen Kurs neu berechnet werden.",
  "p-exchange-tos-fiat-crypto-fixed-rate":
    "9.2.5. Der Wechselkurs in den Richtungen 'geben' Fiat, 'erhalten' Kryptowährung bleibt während der für die Zahlung vorgesehenen Zeit fest. Anträge in diesen Richtungen werden zum aktuellen Wechselkurs neu berechnet, wenn es dem Kunden nicht gelungen ist, während dieser Zeit Gelder auf das in seinem Antrag angegebene Wallet/Karte zu überweisen.",
  "p-exchange-tos-crypto-sending":
    "9.2.6. Wenn der Kunde eine auf der Website des Dienstes aufgeführte Kryptowährung kauft, kann der Versand der Kryptowährung durch den Dienst zwischen 5 Minuten und 12 Stunden dauern. Die Geschwindigkeit der Bestätigung der Transaktion im Netzwerk hängt nicht vom Dienst ab.",
  "p-exchange-tos-high-load-crypto-network":
    "9.2.7. Im Falle einer hohen Auslastung oder technischer Probleme im Kryptowährungsnetzwerk, die auf der Website des Dienstes aufgeführt sind, aus Gründen, die außerhalb des Einflussbereichs des Dienstes liegen, kann die Lieferzeit der Gelder auf das Konto des Empfängers bis zu 240 Stunden betragen.",
  "p-exchange-tos-recalculation-notification":
    "9.2.8. Die Neuberechnung des Antragsbetrags erfolgt durch den Dienst gemäß den Bedingungen dieser Vereinbarung im automatischen Modus, über den der Dienst den Kunden per E-Mail informiert.",
  "p-exchange-tos-blocked-account-responsibility":
    "9.2.9. Der Dienst ist nicht verantwortlich für Gelder, die auf die gesperrte Karte des Kunden oder ein gesperrtes Konto auf einer Kryptowährungsbörse oder einer anderen zentralen Plattform überwiesen wurden, ebenso wie, wenn der Kunde nach Überweisung der Vermögenswerte durch den Dienst auf Anforderung gesperrt wurde.",
  "p-exchange-tos-memo-tag-required":
    "9.2.10. Der Austausch von Münzen, bei denen ein MEMO oder TAG erforderlich ist, muss beim Senden an unser Wallet diese MEMO- oder TAG-Werte unbedingt enthalten. Gelder, die ohne Verwendung von MEMO oder TAG gesendet wurden, werden nicht gutgeschrieben und nicht zurückerstattet.",
  "p-exchange-tos-smart-contract-processing":
    "9.2.11. Münzen, die von einem Smart Contract gesendet wurden, werden manuell verarbeitet, die Bearbeitungszeit beträgt 2 Stunden. Nach dem Versenden der Münzen müssen Sie den Operator im Online-Chat über Ihre Antragsnummer und den HASH der Transaktion informieren.",
  "p-exchange-tos-refund": "9.3. Rückerstattung",
  "p-exchange-tos-refund-decision":
    "9.3.1. Rückerstattungen sind nur auf Entscheidung des Dienstes möglich.",
  "p-exchange-tos-refund-application":
    "9.3.2. Um eine Rückerstattung zu beantragen, muss der Benutzer eine E-Mail an die E-Mail-Adresse des Dienstes senden:",
  "p-exchange-tos-refund-minus-commission":
    "9.3.3. Die Rückerstattung erfolgt abzüglich der Überweisungsgebühr des Zahlungssystems und abzüglich der Kosten des Austauschdienstes in Höhe von 5 % des Austauschbetrags. Ausnahmen sind die in Abschnitt 4.19 dieser Vereinbarung beschriebenen Fälle.",
  "p-exchange-tos-refund-review":
    "9.3.4. Rückerstattungsanträge werden vom Dienst innerhalb von 48 Stunden bearbeitet.",
  "p-exchange-tos-refund-within-48-hours":
    "9.3.5. Auf Antrag erfolgt die Rückerstattung durch den Dienst innerhalb von 48 Stunden (Ausnahme n 4.19).",
  "p-exchange-tos-deposit-below-minimum":
    "9.3.6. Einzahlungen unter dem Mindestbetrag werden weder gutgeschrieben noch zurückerstattet.",
  "p-exchange-tos-assets-return-clause-419":
    "9.3.7. Beim Austausch von Vermögenswerten, die in Abschnitt 4.19 dieser Vereinbarung genannt werden, behält sich der Dienst nach Abschluss der AML- und KYC-Verfahren das Recht vor, dem Benutzer den Austauschdienst nicht anzubieten und die Gelder unter Einhaltung aller Verfahren der internen Rückerstattungsrichtlinien zurückzugeben.",
  "p-exchange-tos-return-aml-failed-assets":
    "9.4. Rückgabe von Vermögenswerten, die die AML-Prüfung nicht bestanden haben.",
  "p-exchange-tos-return-blocked-assets":
    "9.4.1. Die Rückgabe blockierter Vermögenswerte, die die AML-Prüfung nicht bestanden haben, ist nur nach erfolgreicher Identitätsverifizierung gemäß Abschnitt 8.6 dieser Vereinbarung und Einhaltung von Abschnitt 8.6.1 möglich.",
  "p-exchange-tos-request-from-authorities":
    "9.4.2. Die Rückgabe von Vermögenswerten ist nicht möglich, wenn eine Anfrage zu diesen Vermögenswerten von zuständigen Behörden und anderen Abteilungen, einschließlich aus jeder Gerichtsbarkeit, eingegangen ist. In diesem Fall kann das blockierte Vermögen als Beweismittel in dem Fall verwendet werden.",
  "p-exchange-tos-refund-5-percent-commission":
    "9.4.3. Nach Erfüllung der Abschnitte 8.6 und 8.6.1 erfolgt die Rückerstattung an den Kunden unter Abzug einer 5 % Gebühr.",
  "p-exchange-tos-no-return-criminal-wallets":
    "9.4.4. Kryptowährungsvermögenswerte unterliegen keiner Rückgabe oder einem Austausch, wenn im Rahmen der AML-Prüfung durch das System ein Verstoß festgestellt wird.",
  "p-exchange-tos-dark-market": "DARK MARKET",
  "p-exchange-tos-dark-service": "DARK SERVICE",
  "p-exchange-tos-scam": "BETRUG",
  "p-exchange-tos-stolen": "GESTOHLEN",
  "p-exchange-tos-mixing": "MIXING(MIXER)",
  "p-exchange-tos-sextortion": "SEXTAUSBEUTUNG",
  "p-exchange-tos-ransomware": "ERPRESSUNGS-TROJANER",
  "p-exchange-tos-hack": "HACK",
  "p-exchange-tos-phishing": "PHISHING",
  "p-exchange-tos-terrorism-financing": "TERRORISMUSFINANZIERUNG",
  "p-exchange-tos-fraud": "BETRUG",
  "p-exchange-tos-blacklist": "SCHWARZE LISTE",
  "p-exchange-tos-under-investigation": "NOCH IN UNTERSUCHUNG",
  "p-exchange-tos-cybercrime-organization": "CYBERCRIME-ORGANISATION",
  "p-exchange-tos-narcotics": "BETÄUBUNGSMITTEL",
  "p-exchange-tos-child-abuse": "KINDESMISSBRAUCH",
  "p-exchange-tos-human-trafficking": "MENSCHENHANDEL",
  "p-exchange-tos-sanctions": "SANKTIONEN",
  "p-exchange-tos-change-recipient-details":
    "9.5. Änderung der Empfängerdaten des Antrags",
  "p-exchange-tos-change-recipient-details-procedure":
    "9.5.1. Um die Empfängerdaten zu ändern, reicht der Benutzer einen Antrag auf Änderung der Daten per E-Mail an die Poststelle ein.",
  "p-exchange-tos-acceptance-terms": "10. Annahme der Nutzungsbedingungen",
  "p-exchange-tos-acceptance-terms-text":
    "10.1. Die Annahme der Bedingungen der 'Benutzervereinbarung' bei der Erstellung eines Antrags wird vom Dienst automatisch als Zustimmung zu allen Dokumentationen betrachtet, die den Betrieb des Dienstes regeln.",
};
