export default {
  "p-registration-label-email": "Էլփոստ",
  "p-registration-label-username": "Օգտանուն",
  "p-registration-label-password": "Գաղտնաբառ",
  "p-registration-label-repeat-password": "Կրկնեք գաղտնաբառը",
  "p-registration-head-title": "Գրանցում | Ստեղծեք ձեր Solid Swap հաշիվ",
  "p-registration-head-description":
    "Գրանցվեք Solid Swap հաշիվ՝ սկսելու համար կրիպտոարժույթի առևտուր անվտանգ և արդյունավետ",
  "p-registration-head-keywords":
    "գրանցում, հաշվի ստեղծում, Solid Swap գրանցում, կրիպտո առևտրի գրանցում",
  "p-registration-rules-username": "Խնդրում ենք մուտքագրել ձեր օգտանունը",
  "p-registration-rules-username-length":
    "Օգտանունի երկարությունը պետք է լինի 5-15 նիշերի սահմաններում",
  "p-registration-rules-email": "Խնդրում ենք մուտքագրել ձեր էլ. փոստը",
  "p-registration-rules-password": "Խնդրում ենք մուտքագրել ձեր գաղտնաբառը",
  "p-registration-rules-password-length":
    "Գաղտնաբառի երկարությունը պետք է լինի 6-21 նիշերի սահմաններում",
  "p-registration-rules-password-confirm":
    "Խնդրում ենք հաստատել ձեր գաղտնաբառը",
  "p-registration-rules-password-match": "Գաղտնաբառերը չեն համընկնում",
  "p-registration-rules-terms": "Խնդրում ենք ընդունել օգտագործման պայմանները",
  "p-registration-success-message":
    "Գրանցումը հաջող է: Խնդրում ենք ստուգել ձեր էլ. փոստը հաստատման հղման համար:",
  "p-registration-error-default":
    "Ուփս, ինչ-որ բան սխալ գնաց գրանցման ժամանակ:",
  "p-registration-error-data": "Մուտքագրած տվյալների հետ ինչ-որ բան սխալ է",
  "p-registration-error-account-exists": "Թվում է, որ դուք արդեն ունեք հաշիվ:",
  "p-registration-login-link": "Խնդրում ենք մուտք գործել",
  "p-registration-title": "Գրանցում",
  "p-registration-email-placeholder": "Մուտքագրեք ձեր էլ. փոստը",
  "p-registration-username-placeholder": "Մուտքագրեք ձեր օգտանունը",
  "p-registration-password-placeholder": "Մուտքագրեք ձեր գաղտնաբառը",
  "p-registration-password-confirm-placeholder": "Հաստատեք ձեր գաղտնաբառը",
  "p-registration-terms-message": "Ես համաձայն եմ",
  "p-registration-terms": "Օգտագործման պայմաններ",
  "p-registration-and": "և",
  "p-registration-privacy": "Գաղտնիության քաղաքականություն",
  "p-registration-continue": "Շարունակել",
  "p-registration-success-title": "Հաջողություն!",
  "p-registration-welcome-title": "Միացեք մեր կենսունակ կրիպտո համայնքին!",
  "p-registration-welcome-message-1":
    "Գրանցվեք հիմա՝ բացահայտելու P2P առևտրի ամբողջ ներուժը:",
  "p-registration-welcome-message-2":
    "Փորձեք ազատությունը, արդարությունը և ֆինանսական ապագան:",
  "p-registration-welcome-message-3":
    "Ներխուժեք թվային արժույթների բազմազան շուկա, որտեղ հնարավորություններն ու նորարարությունները հանդիպում են:",
};
