export default {
  "p-index-head-title":
    "Solid Swap | Ապահով և արդյունավետ կրիպտոարժույթի փոխանակում",
  "p-index-head-description":
    "Փորձեք արագ, ապահով և արդյունավետ կրիպտոարժույթի առևտուր Solid Swap-ի հետ: Միացեք հիմա, որպեսզի սկսեք փոխանակել թվային ակտիվներ հեշտությամբ",
  "p-index-head-keywords":
    "կրիպտոարժույթի փոխանակում, անվտանգ առևտուր, թվային ակտիվներ, կրիպտո առևտուր, Solid Swap, solidswap",
  "p-index-prices-finder": "Գնացուցակների որոնիչ:",
  "p-index-welcome-message-2":
    "Առևտուր կատարեք ձեր ձևով, ցանկացած ժամանակ, ցանկացած վայրում",
  "p-index-welcome-no-fees":
    "Գնումն առանց վճարումների, չափազանց ցածր 0.2% վաճառողի վճարում",
  "p-index-welcome-stable": "Կայուն, օրինականացված և ALM ազատ",
  "p-index-welcome-no-id": "ID հաստատում չի պահանջվում",
  "p-index-get-started": "ՍԿՍԵԼ",
  "p-index-learn-more": "ԻՄԱՆԱԼ ԱՎԵԼԻՆ",
  "p-index-world-opening-title": "Մենք բացում ենք աշխարհը ձեզ համար",
  "p-index-world-opening-message-1":
    "Կրիպտոարժույթը ձեզ կդարձնի ազատ, դա ազատություն է բանկերի շղթաներից, կառավարության հրահանգներից",
  "p-index-world-opening-message-2":
    "Սա աշխարհի շուրջ շարժման ազատություն է և ձեր գումարը ցանկացած երկրում պահելու հնարավորություն",
  "p-index-mobile-app-title": "Ներբեռնեք բջջային հավելվածը անվճար",
  "p-index-mobile-app-message":
    "Բջջային հավելվածը հասանելի է ներբեռնման համար App Store-ում և Google Play-ում",
  "p-index-coming-soon": "Գալիս այս ամռանը",
  "p-index-subscribe-title": "Բաժանորդագրվել Solid Swap-ին",
  "p-index-welcome-title": "Մեր P2P առևտրի առավելությունները",
  "p-index-our-blog": "Մեր բլոգը:",
};
