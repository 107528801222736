export default {
  "p-profile-userId-error": "Error: usuario no encontrado",
  "p-profile-userId-online": "En línea",
  "p-profile-userId-last-online": "estuvo en línea {date}",
  "p-profile-userId-registration-date": "Fecha de registro",
  "p-profile-userId-total-orders": "Pedidos totales",
  "p-profile-userId-buy": "COMPRAR",
  "p-profile-userId-sell": "VENDER",
  "p-profile-userId-last-month-orders": "Pedidos del último mes",
  "p-profile-userId-average-confirm-time":
    "Tiempo promedio de confirmación de pago",
  "p-profile-userId-average-send-time": "Tiempo promedio de envío de pago",
  "p-profile-userId-feedback": "Comentarios",
  "p-profile-userId-positive": "positivo",
  "p-profile-userId-negative": "negativo",
  "p-profile-userId-offers-tab": "Ofertas",
  "p-profile-userId-feedback-tab": "Comentarios",
  "p-profile-userId-no-offers": "No hay ofertas",
  "p-profile-userId-buy-orders": "Órdenes de compra",
  "p-profile-userId-sell-orders": "Órdenes de venta",
  "p-profile-userId-no-feedback": "Sin comentarios",
};
