export default {
  "p-resetPassword-head-title": "重置密码 | Solid Swap 账户恢复",
  "p-resetPassword-head-description":
    "快速安全地重置您的 Solid Swap 账户密码。按照步骤重新获得账户访问权限",
  "p-resetPassword-head-keywords": "重置密码，账户恢复，Solid Swap 密码重置",
  "p-resetPassword-success": "您的密码已成功重置！",
  "p-resetPassword-loginInfo": "现在您可以登录到您的账户",
  "p-resetPassword-error": "无法重置密码，请联系支持！",
  "p-resetPassword-validate-password-required": "请输入您的密码",
  "p-resetPassword-validate-password-length":
    "密码长度必须在 6 到 21 个字符之间",
  "p-resetPassword-validate-password2-required": "请确认您的密码",
  "p-resetPassword-validate-password2-match": "密码不匹配",
  "p-resetPassword-title": "重置您的密码",
  "p-resetPassword-success-title": "成功！",
  "p-resetPassword-success-message":
    "您的密码已成功重置！您将在 30 秒内被重定向到登录页面",
  "p-resetPassword-goToLogin": "前往登录",
  "p-resetPassword-instructions":
    "请在下面的字段中输入您的新密码。确保您的密码至少包含 6 个字符，并包括大写字母、小写字母、数字和特殊字符的组合，以增强安全性。",
  "p-resetPassword-newPassword": "新密码",
  "p-resetPassword-placeholder-password": "*****",
  "p-resetPassword-repeatPassword": "重复密码",
  "p-resetPassword-placeholder-password2": "*****",
  "p-resetPassword-resetButton": "重置密码",
};
