export default {
  "p-registration-label-email": "Электронная почта",
  "p-registration-label-username": "Имя пользователя",
  "p-registration-label-password": "Пароль",
  "p-registration-label-repeat-password": "Повторите пароль",
  "p-registration-head-title": "Регистрация | Создайте ваш аккаунт Solid Swap",
  "p-registration-head-description":
    "Зарегистрируйтесь в Solid Swap, чтобы начать безопасно и эффективно торговать криптовалютой",
  "p-registration-head-keywords":
    "регистрация, создание аккаунта, регистрация Solid Swap, регистрация для торговли крипто",
  "p-registration-rules-username": "Пожалуйста, введите ваше имя пользователя",
  "p-registration-rules-username-length":
    "Длина имени пользователя должна быть от 5 до 15 символов",
  "p-registration-rules-email": "Пожалуйста, введите вашу электронную почту",
  "p-registration-rules-password": "Пожалуйста, введите ваш пароль",
  "p-registration-rules-password-length":
    "Длина пароля должна быть от 6 до 21 символа",
  "p-registration-rules-password-confirm": "Пожалуйста, подтвердите ваш пароль",
  "p-registration-rules-password-match": "Пароли не совпадают",
  "p-registration-rules-terms": "Пожалуйста, примите условия использования",
  "p-registration-success-message":
    "Регистрация успешна. Пожалуйста, проверьте вашу электронную почту для подтверждения.",
  "p-registration-error-default": "Упс, что-то пошло не так при регистрации.",
  "p-registration-error-data": "Что-то не так с введенными данными",
  "p-registration-error-account-exists": "Похоже, у вас уже есть аккаунт.",
  "p-registration-login-link": "Пожалуйста, войдите",
  "p-registration-title": "Регистрация",
  "p-registration-email-placeholder": "Введите вашу электронную почту",
  "p-registration-username-placeholder": "Введите ваше имя пользователя",
  "p-registration-password-placeholder": "Введите ваш пароль",
  "p-registration-password-confirm-placeholder": "Подтвердите ваш пароль",
  "p-registration-terms-message": "Я согласен с",
  "p-registration-terms": "Условиями использования",
  "p-registration-and": "и",
  "p-registration-privacy": "Политикой конфиденциальности",
  "p-registration-continue": "Продолжить",
  "p-registration-success-title": "Успех!",
  "p-registration-welcome-title":
    "Присоединяйтесь к нашему яркому сообществу криптоэнтузиастов!",
  "p-registration-welcome-message-1":
    "Зарегистрируйтесь сейчас, чтобы раскрыть полный потенциал торговли peer-to-peer.",
  "p-registration-welcome-message-2":
    "Ощутите свободу, справедливость и будущее финансов.",
  "p-registration-welcome-message-3":
    "Погрузитесь в разнообразный рынок цифровых валют, где встречаются возможности и инновации.",
};
