export default {
  "p-trade-head-title": "交易加密货币 | 在 Solid Swap 上进行安全的数字资产交易",
  "p-trade-head-description":
    "通过 Solid Swap 开始安全的加密货币交易。享受我们用户友好平台上的快速高效的数字资产交换",
  "p-trade-head-keywords": "加密货币交易，安全交易，数字资产交换，加密交易平台",
  "p-trade-needLogin": "您需要登录才能创建报价",
  "p-trade-menu-buy": "购买",
  "p-trade-menu-sell": "出售",
  "p-trade-menu-orders": "订单",
  "p-trade-menu-myOffers": "我的报价",
  "p-trade-menu-paymentOptions": "支付选项",
  "p-trade-buying": "购买",
  "p-trade-selling": "出售",
  "p-trade-placeholder-type": "选择类型...",
  "p-trade-for": "为",
  "p-trade-placeholder-allOptions": "所有支付选项",
  "p-trade-amountIn": "金额",
  "p-trade-placeholder-anyAmount": "任意金额",
  "p-trade-minimumLimit": "最低交易限额为",
  "p-trade-equivalent": "等值于 {MIN_OFFER_AMOUNT_USD} 美元",
  "p-trade-ALM": "仅显示 ALM 认证交易员",
  "p-trade-postNewOffer": "发布新报价",
};
