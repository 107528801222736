export default {
  "c-feedback-create-fastTransaction": "Fast transaction",
  "c-feedback-create-politeFriendly": "Polite and friendly",
  "c-feedback-create-patient": "Patient",
  "c-feedback-create-safeTrustworthy": "Safe and trustworthy",
  "c-feedback-create-goodPrice": "Good price",
  "c-feedback-create-successMessage": "Thank you for your feedback!",
  "c-feedback-create-errorMessage": "An error occurred while sending feedback",
  "c-feedback-create-feedbackTitle": "Feedback",
  "c-feedback-create-experienceQuestion": "How was your experience?",
  "c-feedback-create-positive": "Positive",
  "c-feedback-create-negative": "Negative",
  "c-feedback-create-feedbackPlaceholder": "Please input your feedback",
  "c-feedback-create-anonymous": "Anonymous",
  "c-feedback-create-anonymousHint":
    "send your feedback without showing your name",
  "c-feedback-create-cancel": "Cancel",
  "c-feedback-create-submit": "Submit feedback",

  "c-feedback-item-anonymously": "(anonymously)",
  "c-feedback-item-positive": "Positive",
  "c-feedback-item-negative": "Negative",
};
