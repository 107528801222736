export default {
  "p-trade-my-offers-title":
    "Mes Offres | Gérez vos offres crypto sur Solid Swap",
  "p-trade-my-offers-description":
    "Consultez et gérez vos offres de cryptomonnaies sur Solid Swap. Assurez des stratégies de trading optimales avec notre interface conviviale",
  "p-trade-my-offers-keywords":
    "gérer les offres crypto, offres de cryptomonnaies, offres de trading, offres d'actifs numériques",
  "p-trade-my-offers-delete-confirm":
    "Êtes-vous sûr de vouloir supprimer cette offre ?",
  "p-trade-my-offers-warning": "Avertissement",
  "p-trade-my-offers-ok": "OK",
  "p-trade-my-offers-cancel": "Annuler",
  "p-trade-my-offers-post-new-offer": "Publier une nouvelle offre",
  "p-trade-my-offers-no-active-offers": "Aucune offre active trouvée",
  "p-trade-my-offers-your-selling-offers": "Vos offres de vente",
  "p-trade-my-offers-your-buying-offers": "Vos offres d'achat",
};
