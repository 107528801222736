export default {
  "c-Withdrawal-success": "Retrait réussi !",
  "c-Withdrawal-error":
    "Une erreur est survenue lors du retrait, veuillez réessayer plus tard",
  "c-Withdrawal-enter-valid-amount": "Entrez un montant valide",
  "c-Withdrawal-enter-valid-address": "Entrez une adresse publique valide",
  "c-Withdrawal-enter-tfa-code": "Entrez votre code 2FA depuis l'application",
  "c-Withdrawal-title": "Retrait de",
  "c-Withdrawal-message-1":
    "Veuillez remplir le formulaire ci-dessous pour procéder à votre retrait",
  "c-Withdrawal-message-2":
    "Après avoir procédé au retrait, vous disposerez d'un intervalle de quelques minutes pour annuler votre transaction depuis la page d'historique des transactions",
  "c-Withdrawal-no-fee":
    "Il n'y a actuellement pas de frais de retrait, vous ne serez facturé que les frais de réseau, qui dépendent de sa charge et seront déduits du montant du retrait.",
  "c-Withdrawal-amount-label": "Montant à retirer",
  "c-Withdrawal-available": "Disponible vous avez",
  "c-Withdrawal-withdraw-all": "Tout retirer",
  "c-Withdrawal-commission": "Commission :",
  "c-Withdrawal-address-label": "Adresse publique du portefeuille",
  "c-Withdrawal-security-tip-title": "Conseil de sécurité :",
  "c-Withdrawal-security-tip-desc":
    "Vérifiez toujours l'adresse avant de soumettre. Assurez-vous de reconnaître le destinataire.",
  "c-Withdrawal-tfa-label": "Code d'authentification à deux facteurs",
  "c-Withdrawal-scam-warning-title": "Attention aux arnaques :",
  "c-Withdrawal-scam-warning-desc":
    "Ne partagez jamais vos clés ou vos codes 2FA avec qui que ce soit. Le support officiel ne vous demandera jamais ces informations.",
  "c-Withdrawal-cancel": "Annuler",
  "c-Withdrawal-continue": "Continuer",
};
