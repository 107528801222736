export default {
  "p-user-index-welcome": "欢迎来到您的个人仪表板！",

  "p-user-notifications-settings": "前往通知设置",
  "p-user-notifications-all": "所有通知",
  "p-user-notifications-error": "获取通知时出错",
  "p-user-notifications-none": "无通知",

  "p-user-settings-head-title": "用户设置 | 管理您的 Solid Swap 账户",
  "p-user-settings-head-description": "管理您的 Solid Swap 账户设置和偏好。",
  "p-user-settings-title": "用户设置",
  "p-user-settings-id": "ID",
  "p-user-settings-username": "用户名",
  "p-user-settings-last-login": "上次登录",

  "p-user-wallet-head-title": "用户钱包 | 管理您的 Solid Swap 账户",
  "p-user-wallet-title": "钱包交易",
  "p-user-wallet-incoming-internal": "内部收入",
  "p-user-wallet-outgoing-internal": "内部支出",
  "p-user-wallet-incoming": "收入",
  "p-user-wallet-outgoing": "支出",
  "p-user-wallet-address-copied": "您的公共地址已复制！",
  "p-user-wallet-using-network": "我们正在使用 {network} 网络进行 {currentTab}",
  "p-user-wallet-balance": "您的余额为：",
  "p-user-wallet-frozen-tooltip": "当前被冻结的资金金额，涉及订单、转账等。",
  "p-user-wallet-frozen": "冻结",
  "p-user-wallet-show-address": "显示您的公共 {currentTab} 地址",
  "p-user-wallet-copy": "复制",
  "p-user-wallet-min-deposit-note-1": "请注意，我们对加密货币存款有最低限额。",
  "p-user-wallet-min-deposit-note-2": "限额约为 50 {symbol} 等值的加密货币。",
  "p-user-wallet-current-rate": "当前汇率：",
  "p-user-wallet-usd-symbol": "50 {symbol}",
  "p-user-wallet-tfa-alert":
    "请注意：除非在您的账户上启用了双重身份验证（TFA），否则不允许提现。",
  "p-user-wallet-restriction-alert":
    "您的账户当前被限制提现。请联系支持以获取进一步帮助",
  "p-user-wallet-withdraw": "提现 {currentTab}",
  "p-user-wallet-history-title": "交易历史：",
  "p-user-wallet-no-transactions": "{currentTab} 无交易记录",

  "p-user-head-title": "用户页面 | 管理您的 Solid Swap 账户",
  "p-user-head-description":
    "安全地访问和管理您的 Solid Swap 账户设置、钱包和个人偏好",
  "p-user-head-keywords": "用户设置，管理账户，加密钱包，Solid Swap 用户",

  "p-user-partner-my-partnership": "我的合作",
  "p-user-partner-program-details": "计划详情",
  "p-user-partner-example-link": "示例链接：",
  "p-user-partner-token": "代币",
  "p-user-partner-update-date": "更新日期",
  "p-user-partner-type": "类型",
  "p-user-partner-is-active": "是否活跃",
  "p-user-partner-value": "数值",
  "p-user-partner-id": "合作伙伴ID",
  "p-user-partner-balances": "余额",
  "p-user-partner-note":
    "注意：'冻结余额'表示处于待处理订单、转账或其他保留操作中的资金数额。",
  "p-user-partner-currency": "货币",
  "p-user-partner-balance": "余额",
  "p-user-partner-frozen-balance": "冻结余额",
  "p-user-partner-error": "出现错误",
  "p-user-partner-tfa-required": "需要双重认证",
  "p-user-partner-tfa-description":
    "请注意：只有在您的帐户启用了双重认证 (TFA) 后，才能访问您的合作伙伴计划。",
  "p-user-partner-enable-tfa": "在安全设置中启用双重认证以访问合作伙伴计划。",
  "p-user-partner-go-to-tfa-settings": "前往 2FA 设置",
  "p-user-partner-inactive-help": "如果您不确定为什么您的合作伙伴关系未激活，",
  "p-user-partner-contact-support": "联系我们的支持",
  "p-user-partner-apply-withdraw": "申请提现",
  "p-user-partner-transaction-history": "交易历史",
  "p-user-partner-no-transactions": "看起来交易列表为空",
  "p-user-partner-withdraw-form": "提现表单",
  "p-user-partner-select-currency": "选择货币",
  "p-user-partner-address": "地址",
  "p-user-partner-enter-address": "输入您的加密货币地址",
  "p-user-partner-amount": "金额",
  "p-user-partner-enter-amount": "输入金额",
  "p-user-partner-2fa-code": "2FA 代码",
  "p-user-partner-enter-2fa-code": "从您的2FA应用中输入代码",
  "p-user-partner-submit": "提交",
  "p-user-partner-bonuses": "奖金",
  "p-user-partner-withdrawals": "取款",
  "p-user-partner-visitors": "访客",
  "p-user-partner-enter-wallet-address": "输入您的钱包公共地址",
  "p-user-partner-withdraw-success": "成功创建了提款请求",
  "p-user-partner-copy-success": "已复制到剪贴板",
};
