export default {
  "c-exchange-listItem-id": "ID",
  "c-exchange-listItem-fiat": "Fiat",
  "c-exchange-listItem-crypto": "Crypto",
  "c-exchange-listItem-go-to-details": "Voir les détails",
  "c-exchange-listItem-summary": "Résumé",
  "c-exchange-listItem-exchanging": "Échange en cours",
  "c-exchange-listItem-for": "pour",
  "c-exchange-listItem-order-created": "Commande créée",
  "c-exchange-listItem-status": "Statut",
  "c-exchange-listItem-verifying-card": "Vérification de la carte utilisateur",
  "c-exchange-listItem-moderator-verifying":
    "Le modérateur vérifie les données",
  "c-exchange-listItem-waiting-alm": "En attente des résultats ALM",
  "c-exchange-listItem-preparing-crypto":
    "Préparation de l'adresse de la plateforme",
  "c-exchange-listItem-preparing-fiat": "Préparation des coordonnées bancaires",
  "c-exchange-listItem-sending-crypto": "Envoi de crypto",
  "c-exchange-listItem-sending-fiat": "Envoi de fiat",
  "c-exchange-listItem-confirming-fiat": "Confirmation du paiement",
  "c-exchange-listItem-waiting-payment":
    "En attente du paiement de l'utilisateur",
  "c-exchange-listItem-completed": "Terminé",
  "c-exchange-listItem-failed": "Échec",
  "c-exchange-listItem-cancelled": "Annulé",
};
