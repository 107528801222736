export default {
  "p-trade-orders-title":
    "Órdenes de Comercio | Gestiona tus órdenes de cripto en Solid Swap",
  "p-trade-orders-description":
    "Gestiona tus órdenes de criptomonedas eficientemente en Solid Swap. Rastrear y controlar tus actividades comerciales sin problemas",
  "p-trade-orders-keywords":
    "gestionar órdenes de cripto, órdenes de comercio, seguimiento de órdenes de criptomonedas, gestión de comercio",

  "p-trade-orders-order-fetch-error":
    "Ocurrió un error al cargar el pedido, por favor recarga la página",
  "p-trade-orders-order-not-found": 'Pedido "{orderId}" no encontrado',
  "p-trade-orders-payment-was-sent": "El pago fue enviado",
  "p-trade-orders-payment-was-sent-confirm":
    "¿Confirmas que el pago fue enviado desde tu lado? Hazlo solo después de la confirmación del banco. ¿Continuar?",
  "p-trade-orders-payment-received": "Pago recibido",
  "p-trade-orders-payment-received-confirm":
    "¿Confirmas que el pago fue recibido? Hazlo solo después de la confirmación de tu banco. Si deseas continuar, ingresa el código 2FA de tu aplicación a continuación",
  "p-trade-orders-open-dispute": "Abrir disputa",
  "p-trade-orders-open-dispute-confirm":
    "Deseas abrir una disputa para este pedido. ¿Continuar?",
  "p-trade-orders-change-status": "Cambiar estado",
  "p-trade-orders-change-status-confirm":
    "¿Estás seguro de que deseas cambiar el estado?",
  "p-trade-orders-continue": "Continuar",
  "p-trade-orders-cancel": "Cancelar",
  "p-trade-orders-invalid-2fa": "2FA inválido",
  "p-trade-orders-status-changed": "Estado del pedido cambiado",
  "p-trade-orders-fetch-order-error":
    "Ocurrió un error al obtener los datos del pedido",
  "p-trade-orders-go-to-all-orders": "Ir a todos los pedidos",
  "p-trade-orders-status": "Estado",
  "p-trade-orders-waiting-for-payment": "Esperando el pago del comprador",
  "p-trade-orders-payment-sent": "El comprador ha enviado el pago",
  "p-trade-orders-order-completed": "Pedido completado",
  "p-trade-orders-order-cancelled": "Pedido cancelado",
  "p-trade-orders-dispute": "DISPUTA",
  "p-trade-orders-auto-cancel-after":
    "Cancelar automáticamente el pedido después",
  "p-trade-orders-open-dispute-after": "La disputa se puede abrir después",
  "p-trade-orders-order-created": "Pedido creado",
  "p-trade-orders-summary": "Resumen",
  "p-trade-orders-sell": "VENDER",
  "p-trade-orders-buy": "COMPRAR",
  "p-trade-orders-for": "por",
  "p-trade-orders-fee": "Tarifa",
  "p-trade-orders-included-in-transaction": "incluida en la transacción",
  "p-trade-orders-selling": "Vendiendo",
  "p-trade-orders-buying": "Comprando",
  "p-trade-orders-price": "Precio",
  "p-trade-orders-offer-creator": "Creador de la oferta",
  "p-trade-orders-trader-info": "Información proporcionada por el comerciante",
  "p-trade-orders-no-description":
    "No se especificó ninguna descripción por parte del comerciante.",
  "p-trade-orders-no-description-additional-info":
    "Recibirás información adicional después de crear el pedido.",
  "p-trade-orders-trading-experience": "¿Cómo fue tu experiencia de comercio?",
  "p-trade-orders-positive": "Positiva",
  "p-trade-orders-negative": "Negativa",
  "p-trade-orders-your-feedback": "Tu opinión",

  "p-trade-ordersIndex-all": "Todos",
  "p-trade-ordersIndex-active": "Activo",
  "p-trade-ordersIndex-completed": "Completado",
  "p-trade-ordersIndex-cancelled": "Cancelado",
  "p-trade-ordersIndex-dispute": "Disputa",
  "p-trade-ordersIndex-coin": "Moneda",
  "p-trade-ordersIndex-order-type": "Tipo de orden",
  "p-trade-ordersIndex-dates": "Fechas",
  "p-trade-ordersIndex-reset-filters": "restablecer filtros",
  "p-trade-ordersIndex-loading-error":
    "Algo salió mal durante la carga de pedidos :(",
  "p-trade-ordersIndex-orders": "pedidos",
  "p-trade-ordersIndex-id-type": "ID / Tipo",
  "p-trade-ordersIndex-operation-info": "Información de la operación",
  "p-trade-ordersIndex-status-date": "Estado / Fecha",
  "p-trade-ordersIndex-id": "ID",
  "p-trade-ordersIndex-sell": "VENDER",
  "p-trade-ordersIndex-buy": "COMPRAR",
  "p-trade-ordersIndex-go-to-details": "ir a detalles",
  "p-trade-ordersIndex-summary": "Resumen",
  "p-trade-ordersIndex-fee": "Tarifa",
  "p-trade-ordersIndex-selling": "Vendiendo",
  "p-trade-ordersIndex-buying": "Comprando",
  "p-trade-ordersIndex-for": "Por",
  "p-trade-ordersIndex-price": "Precio",
  "p-trade-ordersIndex-offer-creator": "Creador de la oferta",
  "p-trade-ordersIndex-order-created": "Pedido creado",
  "p-trade-ordersIndex-status": "Estado",
  "p-trade-ordersIndex-waiting-for-buyer": "Esperando el pago del comprador",
  "p-trade-ordersIndex-buyer-sent-payment": "El comprador ha enviado el pago",
  "p-trade-ordersIndex-order-completed": "Pedido completado",
  "p-trade-ordersIndex-order-cancelled": "Pedido cancelado",
  "p-trade-ordersIndex-last7days": "Últimos 7 días",
  "p-trade-ordersIndex-lastMonth": "Último mes",
  "p-trade-ordersIndex-last3month": "Últimos 3 meses",
  "p-trade-ordersIndex-dispute-caps": "DISPUTA",
};
