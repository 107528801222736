export default {
  "c-header-menu-dark": "sombre",
  "c-header-menu-light": "clair",
  "c-header-menu-trade": "Commerce P2P",
  "c-header-menu-news": "Nouvelles",
  "c-header-menu-support": "Support",
  "c-header-menu-chat": "Chat de support",
  "c-header-menu-blog": "Blog",
  "c-header-menu-faq": "FAQ",
  "c-header-menu-beginnersGuide": "Guide du débutant",
  "c-header-menu-help": "Aide",
  "c-header-menu-about": "À propos",
  "c-header-menu-menu": "Menu",
  "c-header-menu-admin": "admin",
  "c-header-menu-moder": "modérateur",
  "c-header-menu-profile": "Mon profil",
  "c-header-menu-orders": "Commandes",
  "c-header-menu-paymentOptions": "Options de paiement",
  "c-header-menu-wallet": "Portefeuille",
  "c-header-menu-settings": "Paramètres",
  "c-header-menu-logout": "déconnexion",
  "c-header-menu-login": "Connexion",
  "c-header-menu-registration": "Inscription",
  "c-header-menu-notifications": "Notifications",
};
