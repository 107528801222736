import en from "@/lang/en";
import de from "@/lang/de";
import fr from "@/lang/fr";
import ru from "@/lang/ru";
import es from "@/lang/es";
import cn from "@/lang/cn";
import am from "@/lang/am";

const getLocaleFromRoute = () => {
  const route = useRoute();
  let foundLocale: string = "en";
  ["en", "de", "fr", "ru", "es", "cn", "am"].forEach((lang) => {
    if (route.path.indexOf(`/${lang}/`) !== -1) {
      foundLocale = lang;
    }
  });
  return foundLocale;
};
export default defineI18nConfig(() => {
  const lang = useCookie("lang");
  let locale: string;
  if (!lang.value || lang.value === "") {
    lang.value = getLocaleFromRoute();
  }
  locale = lang.value;
  return {
    legacy: false,
    locale,
    fallbackLocale: "en",
    messages: {
      en,
      de,
      fr,
      ru,
      es,
      cn,
      am,
    },
  };
});
