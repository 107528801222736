export default {
  "p-user-index-welcome": "¡Bienvenido a tu panel personal!",

  "p-user-notifications-settings": "Ir a la configuración de notificaciones",
  "p-user-notifications-all": "Todas las notificaciones",
  "p-user-notifications-error": "Error al obtener notificaciones",
  "p-user-notifications-none": "No hay notificaciones",

  "p-user-settings-head-title":
    "Configuración de Usuario | Gestiona tu cuenta de Solid Swap",
  "p-user-settings-head-description":
    "Gestiona la configuración y preferencias de tu cuenta de Solid Swap.",
  "p-user-settings-title": "Configuración de usuario",
  "p-user-settings-id": "ID",
  "p-user-settings-username": "Nombre de usuario",
  "p-user-settings-last-login": "Último inicio de sesión",

  "p-user-wallet-head-title":
    "Cartera de Usuario | Gestiona tu cuenta de Solid Swap",
  "p-user-wallet-title": "Transacciones de cartera",
  "p-user-wallet-incoming-internal": "Entrante interno",
  "p-user-wallet-outgoing-internal": "Saliente interno",
  "p-user-wallet-incoming": "Entrante",
  "p-user-wallet-outgoing": "Saliente",
  "p-user-wallet-address-copied": "¡Tu dirección pública ha sido copiada!",
  "p-user-wallet-using-network":
    "Estamos utilizando la red {network} para {currentTab}",
  "p-user-wallet-balance": "Tu saldo es:",
  "p-user-wallet-frozen-tooltip":
    "El monto de los fondos que actualmente están congelados en el sistema en pedidos, transferencias, etc.",
  "p-user-wallet-frozen": "congelado",
  "p-user-wallet-show-address": "Mostrar tu dirección pública {currentTab}",
  "p-user-wallet-copy": "Copiar",
  "p-user-wallet-min-deposit-note-1":
    "Ten en cuenta que tenemos un límite mínimo para depositar criptomonedas.",
  "p-user-wallet-min-deposit-note-2":
    "El límite es de aproximadamente 50 {symbol} equivalente en cripto.",
  "p-user-wallet-current-rate": "Con el tipo de cambio actual:",
  "p-user-wallet-usd-symbol": "50 {symbol}",
  "p-user-wallet-tfa-alert":
    "Ten en cuenta: No se permiten retiros a menos que la Autenticación de Dos Factores (TFA) esté habilitada en tu cuenta.",
  "p-user-wallet-restriction-alert":
    "Tu cuenta está actualmente restringida para realizar retiros. Por favor, contacta al soporte para obtener más ayuda",
  "p-user-wallet-withdraw": "Retirar {currentTab}",
  "p-user-wallet-history-title": "Historial de transacciones:",
  "p-user-wallet-no-transactions":
    "No se encontraron transacciones para {currentTab}",

  "p-user-head-title": "Página de Usuario | Gestiona tu cuenta de Solid Swap",
  "p-user-head-description":
    "Accede y gestiona la configuración de tu cuenta de Solid Swap, cartera y preferencias personales de manera segura",
  "p-user-head-keywords":
    "configuración de usuario, gestionar cuenta, cartera de cripto, usuario de Solid Swap",

  "p-user-partner-my-partnership": "Mi asociación",
  "p-user-partner-program-details": "Detalles del programa",
  "p-user-partner-example-link": "enlace de ejemplo:",
  "p-user-partner-token": "Token",
  "p-user-partner-update-date": "Fecha de actualización",
  "p-user-partner-type": "Tipo",
  "p-user-partner-is-active": "Está activo",
  "p-user-partner-value": "Valor",
  "p-user-partner-id": "ID de Socio",
  "p-user-partner-balances": "Saldos",
  "p-user-partner-note":
    "Nota: 'Saldo congelado' representa la cantidad de fondos en órdenes pendientes, transferencias u otras acciones reservadas.",
  "p-user-partner-currency": "Moneda",
  "p-user-partner-balance": "Saldo",
  "p-user-partner-frozen-balance": "Saldo congelado",
  "p-user-partner-error": "Algo salió mal",
  "p-user-partner-tfa-required": "Se requiere autenticación de dos factores",
  "p-user-partner-tfa-description":
    "Tenga en cuenta: no se permite el acceso a su programa de socios a menos que la autenticación de dos factores (TFA) esté habilitada en su cuenta.",
  "p-user-partner-enable-tfa":
    "Habilite su TFA en la configuración de seguridad para acceder al programa de socios.",
  "p-user-partner-go-to-tfa-settings": "Ir a la configuración de 2FA",
  "p-user-partner-inactive-help":
    "Si no está seguro de por qué su asociación no está activa,",
  "p-user-partner-contact-support": "contacte a nuestro soporte",
  "p-user-partner-apply-withdraw": "Solicitar retiro",
  "p-user-partner-transaction-history": "Historial de transacciones",
  "p-user-partner-no-transactions":
    "Parece que la lista de transacciones está vacía",
  "p-user-partner-withdraw-form": "Formulario de retiro",
  "p-user-partner-select-currency": "Seleccione la moneda",
  "p-user-partner-address": "Dirección",
  "p-user-partner-enter-address": "Ingrese su dirección de criptomoneda",
  "p-user-partner-amount": "Cantidad",
  "p-user-partner-enter-amount": "Ingrese la cantidad",
  "p-user-partner-2fa-code": "Código 2FA",
  "p-user-partner-enter-2fa-code": "Ingrese el código de su aplicación 2FA",
  "p-user-partner-submit": "Enviar",
  "p-user-partner-bonuses": "Bonos",
  "p-user-partner-withdrawals": "Retiros",
  "p-user-partner-visitors": "Visitantes",
  "p-user-partner-enter-wallet-address":
    "Ingrese la dirección pública de su billetera",
  "p-user-partner-withdraw-success": "Solicitud de retiro creada con éxito",
  "p-user-partner-copy-success": "Copiado al portapapeles",
};
