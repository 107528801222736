export default {
  "p-resetPassword-head-title":
    "Réinitialiser le mot de passe | Récupération de compte Solid Swap",
  "p-resetPassword-head-description":
    "Réinitialisez rapidement et en toute sécurité le mot de passe de votre compte Solid Swap. Suivez les étapes pour retrouver l'accès à votre compte",
  "p-resetPassword-head-keywords":
    "réinitialiser le mot de passe, récupération de compte, réinitialisation du mot de passe Solid Swap",
  "p-resetPassword-success":
    "Votre mot de passe a été réinitialisé avec succès !",
  "p-resetPassword-loginInfo":
    "Vous pouvez maintenant vous connecter à votre compte",
  "p-resetPassword-error":
    "Impossible de réinitialiser votre mot de passe, contactez le support s'il vous plaît !",
  "p-resetPassword-validate-password-required":
    "Veuillez entrer votre mot de passe",
  "p-resetPassword-validate-password-length":
    "La longueur du mot de passe doit être comprise entre 6 et 21 caractères",
  "p-resetPassword-validate-password2-required":
    "Veuillez confirmer votre mot de passe",
  "p-resetPassword-validate-password2-match":
    "Les mots de passe ne correspondent pas",
  "p-resetPassword-title": "Réinitialisez votre mot de passe",
  "p-resetPassword-success-title": "Succès !",
  "p-resetPassword-success-message":
    "Votre mot de passe a été réinitialisé avec succès ! Vous serez redirigé vers la page de connexion dans 30 secondes",
  "p-resetPassword-goToLogin": "Aller à la connexion",
  "p-resetPassword-instructions":
    "Veuillez entrer votre nouveau mot de passe dans les champs ci-dessous. Assurez-vous que votre mot de passe comporte au moins 6 caractères et inclut un mélange de lettres majuscules, de lettres minuscules, de chiffres et de caractères spéciaux pour une sécurité accrue.",
  "p-resetPassword-newPassword": "Nouveau mot de passe",
  "p-resetPassword-placeholder-password": "*****",
  "p-resetPassword-repeatPassword": "Répétez le mot de passe",
  "p-resetPassword-placeholder-password2": "*****",
  "p-resetPassword-resetButton": "Réinitialiser le mot de passe",
};
