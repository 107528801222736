export default {
  "c-payment-option-close-dialog-confirm":
    "¿Estás seguro de cerrar el diálogo de opciones de pago?",
  "c-payment-option-save-error":
    "Ocurrió un error al guardar la opción de pago",
  "c-payment-option-edit": "Editar opción de pago",
  "c-payment-option-new": "Nueva opción de pago",
  "c-payment-option-show-currency-methods":
    "Solo se mostrarán métodos de pago con moneda: {currency}",
  "c-payment-option-show-specified-options":
    "Solo se mostrarán opciones de pago especificadas en la oferta",
  "c-payment-option-editing-method": "Editando método de pago para:",
  "c-payment-option-select-method": "Selecciona el método que deseas añadir:",
  "c-payment-option-select-method-placeholder": "seleccionar método de pago...",
  "c-payment-option-description": "Descripción del tipo de pago:",
  "c-payment-option-description-info":
    "Por favor, proporciona una descripción de tu tipo de pago. Esto debe incluir tu número de cuenta, número de tarjeta o cualquier información relevante que ayude a los compradores a identificar dónde deben transferir sus fondos cuando compren criptomonedas de ti.",
  "c-payment-option-description-placeholder": "escribe una descripción...",
  "c-payment-option-save": "Guardar",
  "c-payment-option-validate-payment-option":
    "Por favor, seleccione una opción de pago",
  "c-payment-option-validate-description": "Por favor agregue la descripción",
};
