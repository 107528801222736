export default {
  "p-about-head-title":
    "Über Solid Swap | Lernen Sie unser Kryptowährungsbörse-Team kennen",
  "p-about-head-description":
    "Erfahren Sie mehr über Solid Swap, das Team hinter unserer sicheren Kryptowährungshandelsplattform, und unsere Mission, effiziente digitale Asset-Swaps anzubieten",
  "p-about-head-keywords":
    "kryptowährungsbörse, sicherer handel, digitale vermögenswerte, krypto-handel, Solid Swap, solidswap",
  "p-about-contact-title": "Kontaktieren Sie uns",
  "p-about-address": "Adresse:",
  "p-about-index": "Postleitzahl:",
  "p-about-tax-id": "Steuer-ID:",
  "p-about-email": "E-Mail:",
  "p-about-support-email": "Support E-Mail:",
  "p-about-duns": "Daten-Universal-Nummernsystem (D-U-N-S):",
  "p-about-duns-check": "D-U-N-S prüfen",
  "p-about-traders-count": ">200 Händler sind bereits bei uns",
  "p-about-foundation-title":
    "Das Fundament deiner finanziellen Unabhängigkeit",
  "p-about-description":
    "Solid Swap ist ein neuer Service für den schnellen Austausch von Kryptowährungen und Fiat auf einer P2P-Plattform",
  "p-about-start-trading": "Handel starten",
  "p-about-fee-tag": "0% Gebühr",
  "p-about-beneficial-terms": "Vorteilhafte Konditionen",
  "p-about-low-fees":
    "Niedrige Gebühren, Aktionen, Verlosungen und keine Kleingedruckten",
  "p-about-fast-transactions": "Schnelle Transaktionen",
  "p-about-secure-optimizations":
    "Wir verbessern ständig den Code, ohne die Sicherheit zu opfern",
  "p-about-online": "Online",
  "p-about-24-support": "24/7 Unterstützung",
  "p-about-support-anytime": "Hilfe bei jedem Problem, zu jeder Zeit",
  "p-about-contact-support": "Support kontaktieren",
  "p-about-platform-mission":
    "Aufbau einer zuverlässigen Plattform, geleitet von Träumen und Prinzipien",
  "p-about-join-us":
    "Es gibt viele Börsen, aber wir bieten etwas Besonderes — schließe dich uns an und verfolge die Entwicklung",
  "p-about-freedom": "Freiheit und Anonymität",
  "p-about-data-privacy":
    "Wir wollen kein Bank werden und schätzen die Datensicherheit",
  "p-about-registered-armenia": "Registriert in Armenien",
  "p-about-legal-compliance":
    "Arbeiten im rechtlichen Rahmen und Unabhängigkeit bewahren",
  "p-about-access-devices": "Zugriff von jedem Gerät",
  "p-about-cross-device-experience":
    "Ein optimiertes Erlebnis auf allen Geräten und für jede Situation",
  "p-about-services-question": "Welche Dienstleistungen bietet Solid Swap an?",
  "p-about-buy-bitcoin-question": "Wie kauft man Bitcoin auf Solid Swap?",
  "p-about-crypto-question": "Was ist Kryptowährung?",
  "p-about-russians-trade-question":
    "Können Russen mit Kryptowährungen handeln?",
  "p-about-pay-with-crypto-question": "Kann man mit Kryptowährungen bezahlen?",
  "p-about-login-trade": "Loggen Sie sich ein, um mit dem Handel zu beginnen",
  "p-about-login": "Einloggen",
  "p-about-register": "Registrieren",
};
