export default {
  "p-trade-head-title":
    "Tradez des cryptomonnaies | Trading sécurisé d'actifs numériques sur Solid Swap",
  "p-trade-head-description":
    "Commencez à trader des cryptomonnaies en toute sécurité avec Solid Swap. Profitez d'échanges d'actifs numériques rapides et efficaces sur notre plateforme conviviale",
  "p-trade-head-keywords":
    "trading de cryptomonnaies, trading sécurisé, échanges d'actifs numériques, plateforme de trading de crypto",
  "p-trade-needLogin": "Vous devez vous connecter pour créer une offre",
  "p-trade-menu-buy": "Acheter",
  "p-trade-menu-sell": "Vendre",
  "p-trade-menu-orders": "Commandes",
  "p-trade-menu-myOffers": "Mes offres",
  "p-trade-menu-paymentOptions": "Options de paiement",
  "p-trade-buying": "Achat",
  "p-trade-selling": "Vente",
  "p-trade-placeholder-type": "sélectionnez le type...",
  "p-trade-for": "pour",
  "p-trade-placeholder-allOptions": "toutes les options de paiement",
  "p-trade-amountIn": "montant en",
  "p-trade-placeholder-anyAmount": "n'importe quel montant",
  "p-trade-minimumLimit": "la limite de trading minimum est de",
  "p-trade-equivalent": "équivalent à {MIN_OFFER_AMOUNT_USD} USD",
  "p-trade-ALM": "Afficher uniquement les commerçants vérifiés ALM",
  "p-trade-postNewOffer": "Publier une nouvelle offre",
};
