export default {
  "c-user-avatarChange-avatar-must-be-jpg":
    "Das Avatarbild muss im JPG-Format vorliegen!",
  "c-user-avatarChange-avatar-size-exceed":
    "Die Bildgröße des Avatars darf 2 MB nicht überschreiten!",
  "c-user-avatarChange-avatar-upload-error":
    "Fehler beim Hochladen des Bildes!",
  "c-user-avatarChange-change-avatar": "Avatar ändern",
  "c-user-avatarChange-avatar-file-restriction":
    "JPG/PNG-Dateien mit einer Größe von weniger als 5 MB.",

  "c-user-cookiealert-cookie-preference": "Cookie-Einstellungen",
  "c-user-cookiealert-essential-cookies":
    "Wir verwenden Cookies und ähnliche Technologien, die notwendig sind, um unsere Websites zu betreiben (essentielle Cookies).",
  "c-user-cookiealert-additional-cookies":
    "Wir verwenden auch Analytik-, Funktions- und Zielgerichtete Cookies, um den Traffic unserer Websites zu analysieren, Ihre Erfahrung zu optimieren, Inhalte zu personalisieren und gezielte Werbung zu schalten.",
  "c-user-cookiealert-manage-cookies":
    "Sie können Cookies jederzeit deaktivieren, indem Sie die Option Cookies verwalten im Footer der Seite aufrufen.",
  "c-user-cookiealert-reject-cookies": "Cookies ablehnen",
  "c-user-cookiealert-accept-continue": "Akzeptieren & Fortfahren",

  "c-Enable2FA-enabled": "Zwei-Faktor-Authentifizierung (2FA) ist aktiviert!",
  "c-Enable2FA-disable": "2FA deaktivieren",
  "c-Enable2FA-not-recommended": "(nicht empfohlen)",
  "c-Enable2FA-enable": "2FA aktivieren",
  "c-Enable2FA-highly-recommended": "(dringend empfohlen)",
  "c-Enable2FA-enable-2fa": "2FA aktivieren",
  "c-Enable2FA-scan-image":
    "Scannen Sie das unten stehende Bild mit Ihrer 2FA-App, um die Zwei-Faktor-Authentifizierung zu aktivieren",
  "c-Enable2FA-enter-address": "Oder geben Sie diese Adresse in Ihre App ein",
  "c-Enable2FA-enter-code": "2FA-Code eingeben",
  "c-Enable2FA-cancel": "Abbrechen",
  "c-Enable2FA-error-getting-image": "Fehler beim Abrufen des 2FA-Bildes!",
  "c-Enable2FA-success-enable": "2FA erfolgreich aktiviert!",
  "c-Enable2FA-error-enable": "Fehler beim Aktivieren von 2FA!",
  "c-Enable2FA-disable-prompt":
    "Sie sind dabei, die Zwei-Faktor-Authentifizierung (2FA) auf Ihrem Konto zu deaktivieren. Bitte beachten Sie, dass dies die Sicherheit Ihres Kontos verringert. Nach der Deaktivierung von 2FA können Sie 24 Stunden lang keine Handels- oder Abhebungsaktionen durchführen. Wenn Sie fortfahren möchten, geben Sie unten Ihren 2FA-Code ein und klicken Sie auf '2FA deaktivieren'.",
  "c-Enable2FA-warning": "Warnung",
  "c-Enable2FA-input-error": "Geben Sie Ihren 2FA-Code ein",
  "c-Enable2FA-success-disable": "2FA erfolgreich deaktiviert",
  "c-Enable2FA-error-disable": "Fehler beim Deaktivieren von 2FA!",

  "c-UserMenu-orders": "Aufträge",
  "c-UserMenu-payment-options": "Zahlungsmethoden",
  "c-UserMenu-wallet": "Brieftasche",
  "c-UserMenu-notifications": "Benachrichtigungen",
  "c-UserMenu-settings": "Einstellungen",
  "c-UserMenu-partner-dashboard": "Partner",

  "c-NotificationSettings-title": "Benachrichtigungseinstellungen",
  "c-NotificationSettings-alert":
    "Bitte beachten Sie, dass die Benachrichtigungseinstellungen gerätespezifisch sind. Sie haben die Möglichkeit, für jedes Ihrer Geräte unterschiedliche Einstellungen zu konfigurieren.",
  "c-NotificationSettings-check-all": "Alle auswählen",
  "c-NotificationSettings-website-notifications": "Website-Benachrichtigungen:",
  "c-NotificationSettings-order-notifications": "Auftragsbenachrichtigungen",
  "c-NotificationSettings-chat-messages": "Chat-Nachrichten",
  "c-NotificationSettings-system-notifications": "Systembenachrichtigungen:",
  "c-NotificationSettings-system-alert":
    "Systembenachrichtigungen sind native Benachrichtigungen, die spezifisch für Ihr Gerät sind. Diese Benachrichtigungen funktionieren nur, wenn Sie Ihrem Browser die Erlaubnis erteilt haben, Benachrichtigungen anzuzeigen.",

  "c-PasswordChange-change-password": "Passwort ändern",
  "c-PasswordChange-dialog-title": "Ändern Sie Ihr Passwort",
  "c-PasswordChange-description":
    "Bitte geben Sie Ihr aktuelles Passwort ein und wählen Sie ein neues. Ihr neues Passwort muss die folgenden Kriterien erfüllen:",
  "c-PasswordChange-current-password": "Aktuelles Passwort",
  "c-PasswordChange-new-password": "Neues Passwort",
  "c-PasswordChange-confirm-new-password": "Neues Passwort bestätigen",
  "c-PasswordChange-min-characters": "Mindestens 6 Zeichen",
  "c-PasswordChange-max-characters": "Maximal 20 Zeichen",
  "c-PasswordChange-2fa-code": "2FA-Code",
  "c-PasswordChange-case-sensitive":
    "Passwörter sind case-sensitive. Wählen Sie ein starkes, einzigartiges Passwort, um Ihr Konto zu sichern.",
  "c-PasswordChange-cancel": "Abbrechen",
  "c-PasswordChange-wait-loading": "Bitte warten, laden...",
  "c-PasswordChange-enter-current-password":
    "Bitte geben Sie Ihr aktuelles Passwort ein",
  "c-PasswordChange-enter-new-password":
    "Bitte geben Sie Ihr neues Passwort ein",
  "c-PasswordChange-please-confirm-new-password":
    "Bitte bestätigen Sie Ihr neues Passwort",
  "c-PasswordChange-passwords-not-match":
    "Die beiden Passwörter stimmen nicht überein",
  "c-PasswordChange-enter-2fa-code": "Bitte geben Sie Ihren 2FA-Code ein",
  "c-PasswordChange-password-changed-success": "Passwort erfolgreich geändert!",
  "c-PasswordChange-error-changing-password":
    "Fehler beim Ändern des Passworts!",

  "c-TFAAlert-2fa-disabled-title":
    "Ihre Zwei-Faktor-Authentifizierung ist deaktiviert",
  "c-TFAAlert-attention": "Achtung!",
  "c-TFAAlert-message-1":
    "Es sieht so aus, als hätten Sie die Zwei-Faktor-Authentifizierung (2FA) noch nicht aktiviert. Zu Ihrer Sicherheit sind bestimmte Aktionen wie das Erstellen von Aufträgen, Angeboten und Abhebungen eingeschränkt, bis 2FA aktiviert ist.",
  "c-TFAAlert-message-2":
    "Sie können die Website weiterhin nutzen, aber bitte erwägen Sie, 2FA zu aktivieren, um die volle Funktionalität freizuschalten und die Sicherheit Ihres Kontos zu erhöhen.",
  "c-TFAAlert-remind-me-later": "Später erinnern",
  "c-TFAAlert-go-to-setup": "Zu den 2FA-Einstellungen gehen",

  "c-partner-bonuses-list": "Bonusliste",
  "c-partner-bonuses-description":
    "Hier sehen Sie die Liste der Boni, die Sie von Kunden erhalten haben, die Ihren Empfehlungslink genutzt haben.",
  "c-partner-bonus-id": "ID",
  "c-partner-bonus-amount": "Bonusbetrag",
  "c-partner-bonus-date": "Datum",

  "c-partner-withdrawals-list": "Auszahlungsliste",
};
