export default {
  "c-widget-news-moreNews": "更多新闻",

  "c-widget-Search-crypto": "加密货币：",
  "c-widget-Search-fiat": "法币：",
  "c-widget-Search-amount": "金额",
  "c-widget-Search-send": "发送",
  "c-widget-Search-recieve": "接收",
  "c-widget-Search-buy": "购买",
  "c-widget-Search-sell": "出售",
  "c-widget-Search-find": "查找",
  "c-widget-Search-selling-offers": "出售报价",
  "c-widget-Search-selling": "出售",
  "c-widget-Search-buying-offers": "购买报价",
  "c-widget-Search-buying": "购买",
  "c-widget-Search-price": "价格",
  "c-widget-Search-available": "可用",
  "c-widget-Search-nothing-found": "未找到，请尝试其他选项",
  "c-widget-Search-close": "关闭",
  "c-widget-Search-show-more": "显示更多",

  "c-widget-Stats-total-users": "总用户数",
  "c-widget-Stats-av-reg-per-day": "每日平均注册",
  "c-widget-Stats-daily-active-users": "日活跃用户",
  "c-widget-Stats-daily-active-users-tip": "一天内登录产品的用户数量",
  "c-widget-Stats-than-yesterday": "比昨天",
  "c-widget-Stats-total-orders": "总订单数",
  "c-widget-Stats-av-orders-per-day": "每日平均订单",
  "c-widget-Stats-total-offers": "总报价",
  "c-widget-Stats-new-offers-24h": "24小时内新报价",

  "c-widget-TipTap-client-toggle-fullscreen": "切换全屏",
  "c-widget-TipTap-client-upload-image": "上传图片",
  "c-widget-TipTap-client-drop-file-here": "将文件拖到此处或",
  "c-widget-TipTap-client-click-to-upload": "点击上传",
  "c-widget-TipTap-client-jpg-png-files": "jpg/png 文件小于 2MB",
  "c-widget-TipTap-client-heading-1": "标题 1",
  "c-widget-TipTap-client-heading-2": "标题 2",
  "c-widget-TipTap-client-heading-3": "标题 3",
  "c-widget-TipTap-client-paragraph": "段落",
  "c-widget-TipTap-client-image": "图片",
  "c-widget-TipTap-client-divider": "分隔线",
  "c-widget-TipTap-client-break": "换行",
  "c-widget-TipTap-client-clear-marks": "清除标记",
  "c-widget-TipTap-client-please-input-link": "请输入链接",
  "c-widget-TipTap-client-tip": "提示",
  "c-widget-TipTap-client-ok": "确定",
  "c-widget-TipTap-client-cancel": "取消",
  "c-widget-TipTap-client-input-canceled": "输入已取消",
  "c-widget-TipTap-client-picture-format": "图片必须是 jpg/png 格式！",
  "c-widget-TipTap-client-picture-size": "图片大小不能超过 2MB！",
};
