export default {
  "p-exchange-tos-agreement-intro":
    "Este Acuerdo de Usuario (en adelante, el 'Acuerdo') es un acuerdo electrónico entre el Usuario y el servicio SolidSwap y contiene las reglas para trabajar en el sitio web de SolidSwap en",
  "p-exchange-tos-agreement-intro-continued":
    "(en adelante, el 'Sitio') y los términos de acceso a los servicios proporcionados por el Sitio. Antes de comenzar a trabajar con el Sitio, el usuario del Sitio (en adelante, el 'Usuario') debe leer cuidadosamente los términos descritos y aceptar este Acuerdo. Si el Usuario no acepta el Acuerdo y no se compromete a cumplir con todos los términos de este Acuerdo, debe dejar de usar el Sitio de inmediato.",
  "p-exchange-tos-agreement-updates":
    "La Administración del Sitio se reserva el derecho, a su entera discreción, de actualizar y cambiar el texto del Acuerdo sin notificar a los Usuarios. La nueva versión del Acuerdo entra en vigor inmediatamente después de su publicación en el Sitio web. El Usuario está obligado a monitorear de manera independiente las nuevas versiones del Acuerdo. Al continuar usando el Sitio después de que entren en vigor los cambios, el Usuario acepta automáticamente la nueva versión del Acuerdo. Si el Usuario no está de acuerdo con los términos de la nueva versión del Acuerdo, debe desactivar su cuenta si está disponible y no utilizar los servicios del servicio.",
  "p-exchange-tos-terms-definitions": "1. Términos y definiciones",
  "p-exchange-tos-solid-swap-service":
    "1.1. El servicio SolidSwap es una plataforma diseñada para realizar operaciones de trading con activos digitales virtuales.",
  "p-exchange-tos-user-definition":
    "1.2. El Usuario es una persona física registrada en la Plataforma que ha aceptado los términos y condiciones de este Acuerdo. El Usuario confirma que ha alcanzado la edad de 18 años, que las leyes de su país de residencia no prohíben el uso de este Servicio, y que el Usuario no tiene la intención de realizar ninguna actividad ilegal a través del uso de los servicios de SolidSwap.",
  "p-exchange-tos-title-sign":
    "1.3. El signo de título es una unidad convencional del sistema de pago de monedas electrónicas y otros tipos de monedas, que corresponde a los cálculos de los sistemas electrónicos y muestra la cantidad de derechos correspondiente al acuerdo de varios sistemas de pago y su propietario.",
  "p-exchange-tos-payment-system":
    "1.4. El sistema de pago es un servicio en línea que implementa el pago de bienes y servicios a través de transferencias de dinero electrónico.",
  "p-exchange-tos-service-operations":
    "1.5. Servicios del servicio: operaciones de entrada y retiro de monedas electrónicas, así como otros servicios, cuya información está publicada en el sitio web del Servicio.",
  "p-exchange-tos-payment-definition":
    "1.6. Pago es una transferencia de dinero realizada utilizando un sistema de pago.",
  "p-exchange-tos-otc-platform":
    "1.7. Plataforma OTC es una plataforma de trading para encontrar contrapartes y realizar transacciones fuera del mercado.",
  "p-exchange-tos-personal-data":
    "1.8. Datos personales: información que identifica a una persona, como el nombre, dirección, correo electrónico, número de teléfono móvil, información de ofertas y detalles bancarios. El término 'Datos Personales' no incluye datos anónimos y/o agregados que no permitan identificar a un Usuario específico.",
  "p-exchange-tos-agreement-definition":
    "1.9. Acuerdo: este Acuerdo de Usuario y sus partes integrales, teniendo en cuenta todas las adiciones y/o modificaciones que puedan realizarse de acuerdo con sus términos.",
  "p-exchange-tos-website-definition":
    "1.10. Sitio web: un conjunto de información, textos, elementos gráficos, diseño, imágenes, fotos y videos y otros resultados de la actividad intelectual, así como un conjunto de programas informáticos contenidos en un sistema de información que aseguran la disponibilidad de dicha información en",
  "p-exchange-tos-website-continued":
    ", incluidos los subdominios, y si el dominio o el material del Sitio necesitan ser cambiados por alguna razón, incluye el Sitio después de tales cambios.",
  "p-exchange-tos-intellectual-property":
    "1.11. Propiedad intelectual: todos los materiales publicados en el Sitio, así como todos los Servicios proporcionados por el Servicio, son propiedad del Servicio y no pueden ser utilizados por terceros: publicación, distribución, duplicación.",
  "p-exchange-tos-application-definition":
    "1.12. Solicitud: el Usuario hace una oferta para comprar o vender activos digitales virtuales utilizando los Servicios proporcionados por el Servicio.",
  "p-exchange-tos-electronic-document":
    "1.13. Documento electrónico: información en forma electrónica y digital.",
  "p-exchange-tos-suspicious-traffic":
    "1.14. Tráfico sospechoso: un conjunto de acciones, transacciones, activos, etc., que podrían potencialmente violar las políticas de AML/CTF y KYC.",
  "p-exchange-tos-verification-procedures":
    "1.15. Procedimientos de verificación (KYC): procedimientos de verificación en el marco de las normas contra el lavado de dinero, la política de 'Conozca a su cliente' (KYC).",
  "p-exchange-tos-persons-subject-restrictions":
    "1.16. Personas sujetas a restricciones: significa cualquier persona en los Estados Unidos y/o cualquier individuo incluido en la Lista de Ciudadanos de Categorías Especiales o Personas Bloqueadas del Departamento del Tesoro de los Estados Unidos, la Lista Consolidada de Sanciones Financieras de la UE o la Lista de Sanciones del Reino Unido, así como ciudadanos y residentes de jurisdicciones limitadas (p 1.17).",
  "p-exchange-tos-limited-jurisdiction":
    "1.17. Jurisdicción limitada: las jurisdicciones limitadas incluyen: Estados Unidos de América, Afganistán, Bosnia y Herzegovina, República Popular Democrática de Corea, República Democrática del Congo, Eritrea, Etiopía, Guyana, Irán, Irak, República Democrática Popular Lao, Libia, Somalia, Sri Lanka, Sudán, Siria, Trinidad y Tobago, Túnez, Uganda, Vanuatu, Ucrania, Reino Unido, China continental, Singapur, Hong Kong, Canadá, Cuba, Uzbekistán, Crimea, Francia, Sebastopol, las regiones de Donetsk y Lugansk.",
  "p-exchange-tos-introduction": "2. Introducción",
  "p-exchange-tos-introduction-text":
    "2.1. Este acuerdo regula la relación entre el Usuario y el Servicio en el marco de los servicios proporcionados por el Servicio al Usuario, y cancela todos los acuerdos anteriores entre el Servicio y el Usuario sobre este tema.",
  "p-exchange-tos-subject-agreement": "3. Objeto del acuerdo",
  "p-exchange-tos-subject-agreement-services":
    "3.1. El objeto de este Acuerdo son los Servicios proporcionados por el Servicio, que están descritos en el sitio web del Servicio.",
  "p-exchange-tos-subject-agreement-restrictions":
    "3.2. El Servicio ofrece sus servicios a todos los Usuarios interesados, con la excepción de personas sujetas a restricciones (párrafo 1.16).",
  "p-exchange-tos-payment-systems-responsibility":
    "3.3. Los sistemas de pago y/o las instituciones financieras son los únicos responsables de los fondos que el Usuario les confía. El Servicio no puede ser parte de un acuerdo entre el Sistema de Pago y/o una institución financiera y el Usuario y no es responsable de ningún uso incorrecto o no autorizado del Sistema de Pago por parte del Usuario, así como del abuso del Usuario de las funcionalidades del Sistema de Pago. Los derechos y obligaciones mutuos del Usuario y del Sistema de Pago y/o de la institución financiera están regulados por los acuerdos correspondientes.",
  "p-exchange-tos-operation-cannot-cancel":
    "3.4. Cualquier operación iniciada para intercambiar los fondos del Usuario, así como cualquier otra operación ofrecida por el Servicio al Usuario, no puede ser cancelada por el Usuario.",
  "p-exchange-tos-rights-obligations":
    "4. Derechos y obligaciones de las Partes",
  "p-exchange-tos-suspend-operation-authorities":
    "4.1. El Servicio tiene derecho a suspender o cancelar una operación en curso si las autoridades competentes reciben información sobre la ilegalidad de la propiedad de los fondos del Usuario y/o cualquier otra información que haga imposible que el Servicio proporcione servicios al Usuario.",
  "p-exchange-tos-suspend-refund-operation":
    "4.2. El Servicio tiene derecho a suspender, rechazar un reembolso o cancelar una operación en curso si el Usuario viola los términos de este Acuerdo.",
  "p-exchange-tos-cancel-operation-title-return":
    "4.3. El Servicio tiene derecho a cancelar la operación en curso y devolver los caracteres de título ingresados por el Usuario sin dar explicaciones.",
  "p-exchange-tos-prevent-illegal-activities":
    "4.4. El Servicio se compromete a tomar todas las acciones posibles y disponibles para prevenir intentos de comercio ilegal, fraude financiero y lavado de dinero utilizando los servicios del Servicio. Estas acciones incluyen: proporcionar toda la asistencia posible a las agencias de cumplimiento de la ley en la búsqueda y captura de terroristas financieros involucrados en actividades ilegales de lavado de dinero; proporcionar información a las autoridades competentes de acuerdo con la legislación vigente sobre el procesamiento del Servicio; mejorar el Servicio para prevenir el uso directo o indirecto del Servicio en actividades contrarias a la legislación destinada a combatir el comercio ilegal, el fraude financiero y el lavado de dinero.",
  "p-exchange-tos-suspicious-traffic-rights":
    "4.5. En caso de detectar tráfico sospechoso por parte del Usuario, el Servicio se reserva el derecho de transferir toda la información solicitada por las agencias de cumplimiento de la ley sobre la identidad, la solicitud de intercambio y la moneda recibida del Usuario.",
  "p-exchange-tos-accept-agreement":
    "4.6. Al utilizar los servicios proporcionados en el sitio, el Usuario acepta y confirma que está de acuerdo con estas Reglas y la política AML/CTF y KYC.",
  "p-exchange-tos-user-guarantee":
    "4.7. Al utilizar los servicios proporcionados en el sitio, el Usuario garantiza que proporciona información completa, veraz y actualizada sobre sí mismo, que el uso de los servicios del servicio se realiza solo con fines personales que no violen las leyes del país en el que se encuentra el Usuario, y se compromete a actualizar los datos de manera oportuna para mantener su relevancia.",
  "p-exchange-tos-user-confidentiality":
    "4.8. El Usuario garantiza la confidencialidad de los datos de registro y el acceso a su cuenta, y asume toda la responsabilidad por todas las acciones que ocurran en su cuenta, incluso en caso de hackeo o acceso no autorizado.",
  "p-exchange-tos-report-security-breach":
    "4.9. El Usuario se compromete a informar de inmediato sobre una violación de la seguridad de su cuenta.",
  "p-exchange-tos-block-account":
    "4.10. El Servicio tiene derecho a bloquear la cuenta del Usuario en caso de sospecha de acciones ilegales, sospecha de hackeo de la cuenta o en caso de violación de las reglas del Servicio. La cuenta del Usuario se bloquea sin derecho a restauración si el Usuario crea obstáculos en el funcionamiento del Servicio.",
  "p-exchange-tos-rightful-owner":
    "4.11. Al aceptar los términos de este Acuerdo, el Usuario garantiza que es el legítimo propietario de los fondos y/o criptomonedas.",
  "p-exchange-tos-understand-service-risks":
    "4.12. Al aceptar este Acuerdo de Usuario, el Usuario confirma que comprende los principios del Servicio y asume toda la responsabilidad por todos los riesgos y pérdidas asociados con el uso del Servicio.",
  "p-exchange-tos-consent-data-processing":
    "4.13. El Usuario da su consentimiento al Servicio para el procesamiento de los datos personales proporcionados durante el registro en el sitio, durante el procedimiento de verificación, así como durante la recopilación de cualquier información sobre el cliente en el sitio.",
  "p-exchange-tos-personal-data-includes":
    "4.14. Los datos personales incluyen:",
  "p-exchange-tos-name": "Apellido, Nombre, Apellido paterno;",
  "p-exchange-tos-photo-passport": "foto/escaneo del pasaporte;",
  "p-exchange-tos-other-id-documents":
    "otros documentos de identificación; (individualmente en cada caso)",
  "p-exchange-tos-video-selfie": "selfie en video con el pasaporte en mano;",
  "p-exchange-tos-email": "dirección de correo electrónico;",
  "p-exchange-tos-phone-number":
    "número de teléfono móvil (que se puede utilizar para contactar al cliente);",
  "p-exchange-tos-wallet-details":
    "detalles de las billeteras utilizadas; (individualmente en cada caso);",
  "p-exchange-tos-card-number":
    "número de tarjeta y/o cuenta bancaria; (individualmente en cada caso);",
  "p-exchange-tos-transactions-wallets":
    "transacciones y direcciones de billetera.",
  "p-exchange-tos-processing-personal-data":
    "4.15. El procesamiento de datos personales incluye el registro, la sistematización, la acumulación, el almacenamiento, la aclaración (actualización, modificación), la extracción, el uso, la transferencia (distribución, provisión, acceso), incluida la transferencia transfronteriza, la despersonalización, el bloqueo, la eliminación, la destrucción de datos personales que no pertenecen a categorías especiales, cuyo procesamiento requiere el consentimiento por escrito del Usuario.",
  "p-exchange-tos-processing-purpose":
    "4.16. El procesamiento de datos personales se lleva a cabo para que las Partes cumplan con sus obligaciones bajo el Acuerdo, registren al Usuario en el Sitio, proporcionen al Usuario soporte técnico, presenten Solicitudes, proporcionen Servicios, consideren apelaciones y reclamaciones, envíen mensajes informativos y otros al correo electrónico del Usuario.",
  "p-exchange-tos-fincen-rule":
    "4.17. Según la regla final de la Red de Investigación de Delitos Financieros (FinCEN) de acuerdo con el Artículo 31 del Código de Normas Federales 1010.100(ff), que obliga a cada firma extranjera que brinda servicios de liquidación y efectivo a nombrar a un representante del servicio que resida en los Estados Unidos como agente para llevar a cabo el juicio de acuerdo con los requisitos de la Asociación BSA y sus estatutos, SolidSwap no proporciona servicios de intercambio de moneda electrónica a ciudadanos estadounidenses y usuarios que residen en los Estados Unidos.",
  "p-exchange-tos-legal-prohibited":
    "4.18. Los servicios proporcionados por SolidSwap pueden estar legalmente prohibidos en algunas jurisdicciones. Al aceptar los términos de uso de SolidSwap, confirma que no es residente de dicha jurisdicción.",
  "p-exchange-tos-aml-kyc-checks":
    "4.19. El Servicio tiene derecho a realizar verificaciones AML y KYC para cumplir con la 'Quinta Directiva de la Lucha contra el Lavado de Dinero y la Financiación del Terrorismo (5AMLD)'",
  "p-exchange-tos-kyt-aml-violation":
    "4.19.1. Si, según los resultados de la verificación KYT/AML de las transacciones/activos de criptomonedas, se detecta una violación de la 'política AML/CTF y KYC', entonces los fondos serán bloqueados y se solicitará una verificación adicional del usuario (KYC) de acuerdo con la cláusula 8.5 de este acuerdo, así como la prueba de la legalidad del origen de los fondos.",
  "p-exchange-tos-kyt-aml-non-return":
    "4.19.2. Si, según los resultados de la verificación KYT/AML de las transacciones/activos de criptomonedas, se revela una violación de la 'política AML/CTF y KYC' y/o la situación cae dentro del alcance de la cláusula 9.4.2 de este acuerdo, entonces los activos no podrán ser objeto de intercambio y devolución.",
  "p-exchange-tos-services-service": "5. Servicios del Servicio",
  "p-exchange-tos-order-services":
    "5.1. La ordenación de los servicios del Servicio y la recepción de información sobre el progreso de la transacción por parte del Usuario se lleva a cabo exclusivamente a través de contactos con un representante del Servicio.",
  "p-exchange-tos-service-internal-schedule":
    "5.2. El Servicio ofrece sus servicios de acuerdo con el horario interno establecido por el servicio.",
  "p-exchange-tos-questions-complaints":
    "5.3. Las preguntas y quejas sobre las solicitudes se aceptan dentro de las 24 horas desde el momento del registro. Después de 24 horas, las solicitudes de intercambio se consideran debidamente ejecutadas o no pagadas, dependiendo de su estado en el momento del vencimiento del período especificado.",
  "p-exchange-tos-suspicious-actions":
    "5.4. En caso de cualquier acción sospechosa por parte del cliente, así como en caso de uso de activos sospechosos por parte del usuario, el servicio puede retrasar el pago y requerir una verificación adicional de la identidad y un análisis interno de los fondos utilizados por el usuario.",
  "p-exchange-tos-right-to-refuse":
    "5.5. El Servicio se reserva el derecho de rechazar el intercambio unilateralmente.",
  "p-exchange-tos-restricted-citizens":
    "5.6. Los servicios proporcionados por SolidSwap están prohibidos para su uso por ciudadanos sujetos a restricciones (párrafo 1.16).",
  "p-exchange-tos-cost-services": "6. Costo de los servicios",
  "p-exchange-tos-tariffs":
    "6.1. Las Tarifas son determinadas por la administración del Servicio y publicadas en el sitio web del Servicio. La administración del Servicio tiene derecho a cambiar las tarifas sin previo aviso.",
  "p-exchange-tos-expectations-tariffs":
    "6.2. El Servicio no es responsable de las expectativas del cliente con respecto a los cambios en los planes de tarifas y el costo de los servicios.",
  "p-exchange-tos-guarantees-responsibilities":
    "7. Garantías y responsabilidades de las partes",
  "p-exchange-tos-guarantee-fulfillment":
    "7.1. El Servicio garantiza el cumplimiento de sus obligaciones con el Usuario solo dentro de los límites de los montos confiados al Servicio por el Usuario para realizar la operación dentro de los términos de la documentación del servicio.",
  "p-exchange-tos-confidentiality-guarantee":
    "7.2. El Servicio garantiza y asegura la confidencialidad de la información sobre el Usuario y sus operaciones. El Servicio puede proporcionar esta información solo a solicitud de agencias gubernamentales autorizadas, funcionarios o representantes de los sistemas de pago, si existen motivos legítimos para ello.",
  "p-exchange-tos-efforts-technical-issues":
    "7.3. El Servicio hace todo lo posible para garantizar el funcionamiento continuo del Servicio, pero no garantiza la ausencia de problemas técnicos que conduzcan a una suspensión total o parcial del Servicio. El Servicio no es responsable de las pérdidas, las ganancias no obtenidas y otros costos incurridos por el Usuario como resultado de la imposibilidad de acceder al sitio y a los servicios del Servicio.",
  "p-exchange-tos-damage-responsibility":
    "7.4. El Servicio no es responsable de ningún daño causado al Usuario, incluso si su posibilidad se conocía de antemano. El Servicio no es responsable de la obtención de información sobre las actividades del Usuario por parte de terceros en caso de hackeo del sistema y obtención de datos por parte de terceros.",
  "p-exchange-tos-no-responsibility-losses":
    "7.5. El Servicio no asume ninguna responsabilidad por las pérdidas, las ganancias no obtenidas y otros costos del Usuario resultantes de expectativas erróneas del Usuario con respecto a las tarifas del Servicio, la rentabilidad de las transacciones y otros factores subjetivos.",
  "p-exchange-tos-aml-risk-score":
    "7.6. El Servicio no es responsable del cambio en la puntuación de riesgo (AML Risk Score) de las billeteras de los clientes después de realizar intercambios.",
  "p-exchange-tos-compensation":
    "7.7. Al visitar el sitio web del Servicio, utilizar los servicios del Servicio y realizar un intercambio, el Usuario garantiza compensar las pérdidas del Servicio (empresa gestora, gerentes y empleados) en caso de demandas o reclamaciones directa o indirectamente relacionadas con el uso de los servicios del Servicio por parte del Usuario.",
  "p-exchange-tos-not-responsible-communication":
    "7.8. El Usuario acepta que el Servicio no es responsable en caso de interrupción de la comunicación, interrupción en el funcionamiento de Internet, así como en caso de fuerza mayor.",
  "p-exchange-tos-guarantee-owner-funds":
    "7.9. El Usuario garantiza que es el propietario o tiene bases legítimas para disponer de los fondos utilizados en sus transacciones, transferencias bancarias, etc.",
  "p-exchange-tos-no-falsify-communication":
    "7.10. El Usuario se compromete a no falsificar los flujos de comunicación relacionados con el funcionamiento del Servicio.",
  "p-exchange-tos-force-majeure": "8. Fuerza mayor",
  "p-exchange-tos-force-majeure-definition":
    "8.1. Las Partes están exentas de responsabilidad por el incumplimiento total o parcial de las obligaciones bajo este Acuerdo si el incumplimiento de las obligaciones fue el resultado de actos de fuerza mayor, a saber: incendio, inundación, terremoto, actos terroristas, cambio de poder, disturbios civiles, ataques DDOS al servicio, así como el mal funcionamiento de los sistemas de pago, sistemas de suministro de energía, redes de comunicación y proveedores de servicios de Internet.",
  "p-exchange-tos-error-during-payment":
    "8.2. En caso de un error durante el pago del intercambio, el Usuario recibirá una notificación sobre el estado de la solicitud y/o a la dirección de correo electrónico especificada.",
  "p-exchange-tos-payment-after-deadline":
    "8.3. Si la solicitud se paga después del tiempo asignado para el pago de la solicitud, el servicio se reserva el derecho de reembolsar el pago de la solicitud, menos la comisión de la red y los costos del servicio, al Usuario o recalcularlo a su discreción.",
  "p-exchange-tos-not-responsible-assets-fiat":
    "8.3.1 El Servicio no es responsable de los activos enviados por el usuario para el intercambio de 'dar' fiat, 'recibir' criptomonedas después de la expiración del tiempo para el pago de la solicitud.",
  "p-exchange-tos-not-responsible-assets-anonymous":
    "8.3.2 El Servicio no es responsable de los activos enviados por el usuario utilizando un método anónimo que oculta la dirección del remitente, ya que no se acreditarán al saldo de la billetera del servicio de intercambio. En este caso, el intercambio y el reembolso no serán posibles.",
  "p-exchange-tos-deviation-exchange-rate":
    "8.4. Si la tasa de la solicitud se desvía más del 3% de la tasa de cambio en el intercambio de Bybit/Binance, incluidas las fallas técnicas del sitio, el servicio de intercambio tiene el derecho de recalcular la solicitud a la tasa actual en el sitio o emitir un reembolso al Usuario menos los costos del servicio y la comisión de la red.",
  "p-exchange-tos-invalid-exchanges":
    "8.5. En caso de que el Usuario, intencionadamente, de manera no intencionada, así como en caso de un fallo técnico (incluido en el lado del Servicio SolidSwap), como resultado de las acciones de los intrusos o por otras razones, haya realizado un intercambio, lo que a su vez haya causado pérdidas para el Servicio SolidSwap, mientras que la desviación de la tasa de mercado en la solicitud ejecutada fue superior al 3% de la tasa de cambio en el intercambio de Bybit/Binance (esta regla se aplica a cualquier lado del intercambio), las solicitudes realizadas en las condiciones anteriores se consideran inválidas. El Servicio tiene derecho a compensar los daños reteniendo cualquier fondo futuro del Usuario como parte de la interacción con el Servicio.",
  "p-exchange-tos-suspicious-assets":
    "8.6. En caso de detectar activos/fondos sospechosos que violen el acuerdo AML del Servicio y acciones sospechosas/fraudulentas por parte del Usuario, la transacción será bloqueada por el Servicio de Seguridad durante un máximo de 180 días para su posterior investigación.",
  "p-exchange-tos-unblocking-procedure":
    "Para iniciar el procedimiento de desbloqueo, es necesario someterse a una verificación simplificada del usuario (KYC) de acuerdo con la cláusula 4.19 de este acuerdo.",
  "p-exchange-tos-send-photo-passport":
    "Una foto o escaneo de su pasaporte, u otros documentos de identificación (a su elección);",
  "p-exchange-tos-send-phone-number":
    "Un número de teléfono móvil que se pueda utilizar para contactarlo si es necesario (opcional);",
  "p-exchange-tos-send-video-selfie":
    "Selfie en video con un documento de identidad;",
  "p-exchange-tos-record-video":
    "Grabe un video corto de buena calidad sin interrupciones, pronunciando claramente los siguientes datos:",
  "p-exchange-tos-record-full-name":
    "Nombre completo, serie y número del documento de identidad, sosteniéndolo junto a la persona;",
  "p-exchange-tos-service-name":
    "El nombre de nuestro servicio y la fecha de la grabación del video;",
  "p-exchange-tos-application-number":
    "El número de la solicitud y la fecha de su creación;",
  "p-exchange-tos-show-wallet":
    "Muestre la billetera/cuenta/otro desde donde exactamente se realizó el pago/la transacción, la moneda y el monto. El nombre y la dirección (URL) del sitio deben ser legibles si la transacción se realizó desde una aplicación móvil.",
  "p-exchange-tos-write-explanation":
    "Escriba en forma libre en una hoja de papel a mano una breve explicación de la legalidad del origen de los fondos, así como que nos envió desde su billetera (dirección) a su billetera (dirección y hash de la transacción), y que usted es el propietario de los activos, no un tercero. Escriba la dirección donde solicita que se devuelvan los activos.",
  "p-exchange-tos-specify-address":
    "Especifique la dirección/cuenta de la billetera donde debe devolver los activos.",
  "p-exchange-tos-information-deadline":
    "Esperamos la información mencionada anteriormente de usted dentro de las 48 horas posteriores a la recepción de este correo electrónico.",
  "p-exchange-tos-no-unblocking-without-data":
    "En ausencia de los datos solicitados, SolidSwap no podrá iniciar el procedimiento de desbloqueo de sus activos.",
  "p-exchange-tos-blocking-period-expiration":
    "8.6.1. Después de la expiración del período de bloqueo (180 días), desde la fecha de pasar la verificación de identidad (KYC), el usuario debe contactar de manera independiente al servicio de atención al cliente por correo oficial, a más tardar 30 días.",
  "p-exchange-tos-incorrect-details":
    "8.7. El Servicio no asume ninguna responsabilidad financiera con el Usuario si el Usuario especificó detalles incorrectos en la solicitud para recibir fondos del Servicio, y el Servicio realizó una liquidación con el Usuario enviando un pago de acuerdo con los detalles especificados en la solicitud.",
  "p-exchange-tos-payment-delay":
    "8.8. En caso de una falla en el banco/sistema de pago de terceros, los pagos pueden retrasarse hasta 5 días hábiles, por razones fuera del control del servicio.",
  "p-exchange-tos-deposit-outside-application":
    "8.9. Un depósito realizado fuera de la solicitud puede no ser acreditado o perdido. El pago de fondos enviados utilizando detalles obsoletos se realiza con una comisión del 5%.",
  "p-exchange-tos-non-current-details":
    "8.10. El Servicio no es responsable si el Usuario especificó detalles de pago que difieren del banco especificado en la dirección del intercambio. El pago de fondos a detalles bancarios no actuales se realiza con una comisión del 5% dentro de las 48 horas.",
  "p-exchange-tos-fiat-exchange": "9.1. Intercambio de Fiat",
  "p-exchange-tos-fiat-exchange-amount-limit":
    "9.1.1. Los intercambios por un monto igual o inferior a $150 se realizan sin demora. Si usa su cuenta en el sistema de pago para pagar en nuestro servicio por primera vez y cambia un monto superior a $150, su solicitud se completará 2 horas después del pago.",
  "p-exchange-tos-subsequent-exchanges":
    "9.1.2. Los intercambios posteriores por un monto superior a $150 con el mismo número de cuenta en el sistema de pago y la cuenta del usuario serán instantáneos, siempre que se registre antes del primer intercambio.",
  "p-exchange-tos-additional-checks":
    "9.1.3. Si han pasado menos de 24 horas desde el primer intercambio, y el monto total de los intercambios por detalles bancarios excede los $500, se puede establecer una verificación adicional con un retraso de hasta 48 horas por decisión del servicio de seguridad.",
  "p-exchange-tos-suspicious-actions-fiat":
    "9.1.4. En caso de cualquier acción sospechosa por parte del cliente, el servicio se reserva el derecho de solicitar datos de cuenta que confirmen que la cuenta pertenece al cliente.",
  "p-exchange-tos-crypto-exchange": "9.2. Intercambio de Criptomonedas",
  "p-exchange-tos-crypto-auto-manual":
    "9.2.1. Las solicitudes realizadas por el servicio en modo automático se pueden realizar hasta 12 horas. Las solicitudes realizadas por el servicio en modo manual se pueden realizar hasta 36 horas.",
  "p-exchange-tos-sale-cryptocurrencies":
    "9.2.2. Las solicitudes de venta de todas las criptomonedas listadas en el sitio web del servicio se procesan en el momento en que la transacción recibe el mínimo de confirmaciones individuales establecidas (el mínimo lo establece el servicio SolidSwap).",
  "p-exchange-tos-recalculate-rate":
    "9.2.3. El Servicio tiene derecho a recalcular su solicitud en una dirección favorable para la oficina de cambio (tanto en la tasa actual como en la tasa de cambio que existía en el momento en que se creó el pedido) si la transacción no recibió el número requerido de confirmaciones de la red dentro del tiempo asignado para la solicitud.",
  "p-exchange-tos-btc-confirmations":
    "9.2.4. El pago por el intercambio de la criptomoneda BTC se realiza después de la tercera confirmación en la red Bitcoin. La tasa en la solicitud permanece fija si el pago fue realizado por el Usuario durante el tiempo asignado para el pago de la solicitud en esta dirección, y la transacción apareció en la red durante este tiempo, y la criptomoneda BTC recibió 1 confirmación en la red Bitcoin dentro de los 15 minutos. Si la primera confirmación se recibe después de los 15 minutos, la solicitud puede recalcularse a la tasa de cambio actual.",
  "p-exchange-tos-fiat-crypto-fixed-rate":
    "9.2.5. La tasa de cambio en las direcciones de 'dar' Fiat, 'recibir' criptomonedas se fija dentro del tiempo asignado para el pago. Las solicitudes para estas direcciones se recalculan a la tasa de cambio actual si el cliente no ha logrado transferir fondos a la billetera/tarjeta emitida en su solicitud durante este tiempo.",
  "p-exchange-tos-crypto-sending":
    "9.2.6. Cuando el cliente compra cualquier criptomoneda listada en el sitio web del servicio, el envío de la criptomoneda por parte del servicio puede tardar entre 5 minutos y 12 horas. La velocidad de confirmación de la transacción en la red no depende del servicio.",
  "p-exchange-tos-high-load-crypto-network":
    "9.2.7. En caso de alta carga o problemas técnicos en la red de criptomonedas listada en el sitio web del servicio, por razones fuera del control del Servicio, el tiempo de entrega de fondos a la cuenta del destinatario puede alcanzar las 240 horas.",
  "p-exchange-tos-recalculation-notification":
    "9.2.8. El recalculo de la cantidad de la solicitud se realiza por el servicio de acuerdo con los términos de este acuerdo en modo automático, lo que el servicio notifica al cliente por correo electrónico.",
  "p-exchange-tos-blocked-account-responsibility":
    "9.2.9. El Servicio no es responsable de los fondos transferidos a la tarjeta bloqueada del cliente o a una cuenta bloqueada en un intercambio de criptomonedas u otra plataforma centralizada, así como si el cliente fue bloqueado después de que el servicio transfirió activos a él a pedido.",
  "p-exchange-tos-memo-tag-required":
    "9.2.10. El intercambio de monedas para las que se requiere un MEMO o TAG, cuando se envían a nuestra billetera, debe necesariamente tener estos valores MEMO o TAG. Los fondos enviados sin usar MEMO o TAG no están sujetos a crédito y reembolso.",
  "p-exchange-tos-smart-contract-processing":
    "9.2.11. Las monedas enviadas desde el Smart Contract se procesan manualmente, el tiempo de procesamiento es de 2 horas. Después de enviar las monedas, debe informar al operador en el chat en línea sobre su número de solicitud y el HASH de la transacción.",
  "p-exchange-tos-refund": "9.3. Reembolso",
  "p-exchange-tos-refund-decision":
    "9.3.1. Los reembolsos son posibles solo por decisión del Servicio.",
  "p-exchange-tos-refund-application":
    "9.3.2. Para solicitar un reembolso, el Usuario debe enviar un correo electrónico a la dirección de correo electrónico del Servicio:",
  "p-exchange-tos-refund-minus-commission":
    "9.3.3. El reembolso se realiza menos la comisión del sistema de pago por la transferencia y menos los costos del Servicio de intercambio en un 5% del monto del intercambio. Las excepciones son los casos descritos en el párrafo 4.19 de este Acuerdo.",
  "p-exchange-tos-refund-review":
    "9.3.4. Las solicitudes de reembolso son revisadas por el Servicio dentro de las 48 horas.",
  "p-exchange-tos-refund-within-48-hours":
    "9.3.5. A solicitud, el Servicio realiza un reembolso dentro de las 48 horas. (excepción n 4.19)",
  "p-exchange-tos-deposit-below-minimum":
    "9.3.6. Los depósitos realizados por debajo de la cantidad mínima no serán acreditados ni reembolsados.",
  "p-exchange-tos-assets-return-clause-419":
    "9.3.7. Al intercambiar activos especificados en la cláusula 4.19 de este Acuerdo, después de pasar los procedimientos AML y KYC, el Servicio se reserva el derecho de no proporcionar el servicio de intercambio al Usuario y devolver los fondos, observando todos los procedimientos de las regulaciones internas de reembolso.",
  "p-exchange-tos-return-aml-failed-assets":
    "9.4. Devolución de activos que no han pasado la verificación AML.",
  "p-exchange-tos-return-blocked-assets":
    "9.4.1. La devolución de activos bloqueados que no han pasado la verificación AML es posible solo después de pasar la verificación de identidad de la cláusula 8.6 de este Acuerdo y el cumplimiento de la cláusula 8.6.1.",
  "p-exchange-tos-request-from-authorities":
    "9.4.2. La devolución de activos es imposible si se ha recibido una solicitud para estos activos de las autoridades competentes y otros departamentos, incluidas las de cualquier jurisdicción; en este caso, el activo bloqueado puede ser utilizado como prueba material en el caso.",
  "p-exchange-tos-refund-5-percent-commission":
    "9.4.3. Al cumplir con las cláusulas 8.6 y 8.6.1, el reembolso al cliente se realizará con una deducción del 5% de la comisión.",
  "p-exchange-tos-no-return-criminal-wallets":
    "9.4.4. Los activos de criptomonedas no están sujetos a devolución ni intercambio si, según los resultados de la verificación AML del sistema",
  "p-exchange-tos-dark-market": "DARK MARKET",
  "p-exchange-tos-dark-service": "DARK SERVICE",
  "p-exchange-tos-scam": "SCAM",
  "p-exchange-tos-stolen": "STOLEN",
  "p-exchange-tos-mixing": "MIXING(MIXER)",
  "p-exchange-tos-sextortion": "SEXTORTION",
  "p-exchange-tos-ransomware": "RANSOMWARE",
  "p-exchange-tos-hack": "HACK",
  "p-exchange-tos-phishing": "PHISHING",
  "p-exchange-tos-terrorism-financing": "FINANCIACIÓN DEL TERRORISMO",
  "p-exchange-tos-fraud": "FRAUDE",
  "p-exchange-tos-blacklist": "LISTA NEGRA",
  "p-exchange-tos-under-investigation": "TODAVÍA BAJO INVESTIGACIÓN",
  "p-exchange-tos-cybercrime-organization": "ORGANIZACIÓN DE CIBERCRIMEN",
  "p-exchange-tos-narcotics": "NARCÓTICOS",
  "p-exchange-tos-child-abuse": "ABUSO INFANTIL",
  "p-exchange-tos-human-trafficking": "TRATA DE PERSONAS",
  "p-exchange-tos-sanctions": "SANCIONES",
  "p-exchange-tos-change-recipient-details":
    "9.5. Cambio de los detalles del destinatario de la solicitud",
  "p-exchange-tos-change-recipient-details-procedure":
    "9.5.1. Para cambiar los detalles del destinatario, el Usuario presenta una solicitud para reemplazar los detalles en un correo electrónico enviado a la oficina de correos.",
  "p-exchange-tos-acceptance-terms":
    "10. Aceptación de los términos del servicio",
  "p-exchange-tos-acceptance-terms-text":
    "10.1. La aceptación de los términos del 'Acuerdo de Usuario' al crear una solicitud se considera automáticamente por el servicio como un acuerdo con toda la documentación que regula el funcionamiento del servicio.",
};
