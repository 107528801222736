export default {
  "p-confirm-email-title": "Подтверждение электронной почты",
  "p-confirm-email-wait-message":
    "Подождите, пожалуйста, мы пытаемся подтвердить ваш электронный адрес...",
  "p-confirm-email-success-message":
    "Ваш электронный адрес успешно подтвержден!",
  "p-confirm-email-success-elmessage":
    "Ваш электронный адрес успешно подтвержден! Вы будете перенаправлены на страницу входа через 30 секунд.",
  "p-confirm-email-info-elmessage":
    "Теперь вы можете войти в ваш аккаунт как: {email}",
  "p-confirm-email-error-message":
    "Что-то пошло не так, пожалуйста, попробуйте позже.",
  "p-confirm-email-proceed-button": "Перейти к входу",
  "p-confirm-email-welcome-title": "Добро пожаловать в новую эру торговли!",
  "p-confirm-email-welcome-message":
    "Безопасно подключайтесь к миру цифровых активов. Начните торговать с уверенностью и удобством на нашей надежной P2P платформе. Ваша дверь в безупречные криптовалютные транзакции ждет вас.",
};
