export default {
  "p-help-head-title":
    "Օգնություն | Կապվել Solid Swap-ի աջակցման ծառայության հետ",
  "p-help-head-description":
    "Օգնության կարիք ունե՞ք: Բացեք աջակցության տոմս Solid Swap-ում և ստացեք օգնություն մեր փորձագետ թիմից՝ լուծելու ցանկացած խնդիր",
  "p-help-head-keywords":
    "աջակցության տոմս, Solid Swap օգնություն, կրիպտո առևտրի աջակցություն, կապվել աջակցության հետ",
  "p-help-title": "Օգնության էջ",
  "p-help-name-required": "Խնդրում ենք մուտքագրել ձեր անունը",
  "p-help-email-required": "Խնդրում ենք մուտքագրել ձեր էլ. փոստը",
  "p-help-message-required": "Ասեք մեզ ձեր խնդիրը այստեղ",
  "p-help-ticket-success": "Հաջողությամբ ուղարկված տոմս!",
  "p-help-ticket-error":
    "Ինչ-որ բան սխալ գնաց տոմսը ուղարկելիս, խնդրում ենք փորձել ավելի ուշ",
  "p-help-thank-you-message":
    "Շնորհակալություն կապ հաստատելու համար: Մենք ստացել ենք ձեր հաղորդագրությունը և կկապնվենք ձեզ հետ մուտքագրած էլ. փոստի միջոցով հնարավորինս շուտ:",
  "p-help-intro-text":
    "Եթե դուք հանդիպել եք որևէ խնդրի կամ ունեք հարցեր, խնդրում ենք դիմել մեզ: Լրացրեք ներքևի ձևը, և մենք կպատասխանենք հնարավորինս շուտ:",
  "p-help-label-name": "Անուն",
  "p-help-placeholder-name": "Մուտքագրեք ձեր անունը այստեղ",
  "p-help-label-email": "Էլ. փոստ",
  "p-help-placeholder-email": "Մուտքագրեք ձեր էլ. փոստը այստեղ",
  "p-help-label-message": "Հաղորդագրություն",
  "p-help-placeholder-message": "...",
  "p-help-submit-button": "Ուղարկել",
};
