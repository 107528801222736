export default {
  "p-blog-codeId-failed-to-save-post":
    "Échec de l'enregistrement de l'article !",
  "p-blog-codeId-post-saved-successfully": "Article enregistré avec succès !",
  "p-blog-codeId-confirm-delete":
    "Êtes-vous sûr de vouloir supprimer cet article ?",
  "p-blog-codeId-warning": "Avertissement",
  "p-blog-codeId-delete": "Supprimer",
  "p-blog-codeId-post-deleted-successfully": "Article supprimé avec succès !",
  "p-blog-codeId-failed-to-delete-post":
    "Échec de la suppression de l'article !",
  "p-blog-codeId-post-not-found": "Article de blog non trouvé :(",
  "p-blog-codeId-back-to-all-posts": "Retour à tous les articles",
  "p-blog-codeId-edit-post": "Modifier l'article",
  "p-blog-codeId-delete-post": "Supprimer l'article",

  "p-blog-index-title": "Blog | Idées, Conseils et Histoires de Solid Swap",
  "p-blog-index-meta-description":
    "Découvrez les dernières idées, conseils et histoires sur notre blog. Restez à jour avec nos derniers articles couvrant une large gamme de sujets, y compris la technologie, le mode de vie et plus encore",
  "p-blog-index-meta-keywords":
    "blog, idées, conseils, histoires, technologie, mode de vie, derniers articles, Solid Swap, solidswap",
  "p-blog-index-failed-to-fetch-posts": "Échec de la récupération des articles",
  "p-blog-index-solid-swap-blog": "Blog de Solid Swap",
  "p-blog-index-search-placeholder": "Rechercher des articles de blog...",
  "p-blog-index-new-post": "Nouvel article",
  "p-blog-index-active": "Actif",
  "p-blog-index-archive": "Archive",
  "p-blog-index-load-more": "Charger plus",
  "p-blog-index-no-posts-found": "Aucun article trouvé",
};
