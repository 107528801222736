export default {
  "c-footer-menu-trade": "p2p փոխանակում",
  "c-footer-menu-news": "նորություններ",
  "c-footer-menu-blog": "բլոգ",
  "c-footer-menu-faq": "ՀՏՀ",
  "c-footer-menu-beginnersGuide": "սկսնակների ուղեցույց",
  "c-footer-menu-about": "մեր մասին",
  "c-footer-menu-manageCookies": "կառավարել թխվածքները",
  "c-footer-menu-adminPanel": "Ադմին վահանակ",

  "c-footer-mobilemenu-home": "Գլխավոր էջ",
  "c-footer-mobilemenu-trade": "Փոխանակում",
  "c-footer-mobilemenu-orders": "Պատվերներ",
  "c-footer-mobilemenu-wallet": "Դրամապանակ",
};
