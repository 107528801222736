export default {
  "p-profile-userId-error": "Սխալ: օգտատերը չի գտնվել",
  "p-profile-userId-online": "Առցանց",
  "p-profile-userId-last-online": "էր առցանց {date}",
  "p-profile-userId-registration-date": "Գրանցման ամսաթիվ",
  "p-profile-userId-total-orders": "Ընդհանուր պատվերներ",
  "p-profile-userId-buy": "ԳՆԵԼ",
  "p-profile-userId-sell": "ՎԱՃԱՌԵԼ",
  "p-profile-userId-last-month-orders": "Նախորդ ամսվա պատվերներ",
  "p-profile-userId-average-confirm-time": "Միջին հաստատման ժամանակ",
  "p-profile-userId-average-send-time": "Միջին վճարման ժամանակ",
  "p-profile-userId-feedback": "Կարծիքներ",
  "p-profile-userId-positive": "դրական",
  "p-profile-userId-negative": "բացասական",
  "p-profile-userId-offers-tab": "Առաջարկներ",
  "p-profile-userId-feedback-tab": "Կարծիքներ",
  "p-profile-userId-no-offers": "Առաջարկներ չկան",
  "p-profile-userId-buy-orders": "Գնման պատվերներ",
  "p-profile-userId-sell-orders": "Վաճառքի պատվերներ",
  "p-profile-userId-no-feedback": "Կարծիքներ չկան",
};
