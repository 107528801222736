export default {
  "p-trade-head-title":
    "Kryptowährung handeln | Sicherer Handel mit digitalen Vermögenswerten auf Solid Swap",
  "p-trade-head-description":
    "Beginnen Sie mit dem sicheren Handel von Kryptowährungen mit Solid Swap. Genießen Sie schnelle und effiziente digitale Vermögenswerte auf unserer benutzerfreundlichen Plattform",
  "p-trade-head-keywords":
    "kryptowährungshandel, sicherer handel, digitale vermögenswerte tauschen, krypto-handelsplattform",
  "p-trade-needLogin": "Sie müssen sich anmelden, um ein Angebot zu erstellen",
  "p-trade-menu-buy": "Kaufen",
  "p-trade-menu-sell": "Verkaufen",
  "p-trade-menu-orders": "Aufträge",
  "p-trade-menu-myOffers": "Meine Angebote",
  "p-trade-menu-paymentOptions": "Zahlungsmethoden",
  "p-trade-buying": "Kaufen",
  "p-trade-selling": "Verkaufen",
  "p-trade-placeholder-type": "Typ auswählen...",
  "p-trade-for": "für",
  "p-trade-placeholder-allOptions": "alle Zahlungsmethoden",
  "p-trade-amountIn": "Betrag in",
  "p-trade-placeholder-anyAmount": "jeder Betrag",
  "p-trade-minimumLimit": "Mindesthandelslimit ist",
  "p-trade-equivalent": "entspricht {MIN_OFFER_AMOUNT_USD} USD",
  "p-trade-ALM": "Nur ALM verifizierte Händler anzeigen",
  "p-trade-postNewOffer": "Neues Angebot posten",
};
