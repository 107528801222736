export default {
  "p-kyc-aml-policy-title": "反洗钱政策 (Anti-Money Laundering Policy).",
  "p-kyc-warning":
    "SolidSwap提醒用户不得利用该服务来清洗犯罪所得资金、资助恐怖主义、进行任何形式的非法活动，以及使用该服务购买违禁商品和服务。",
  "p-kyc-illegal-funds-description":
    "通过犯罪手段获得资金的合法化是通过将非法来源的资金转化为看似合法的资金或投资来隐藏其非法来源。",
  "p-kyc-general-provisions-title": "一般规定",
  "p-kyc-prevent-illegal-transactions":
    "为了防止非法交易，服务对所有用户创建的申请设定了一定的要求：",
  "p-kyc-sender-recipient-same":
    "申请中的付款发送人和接收人必须是同一人。使用服务进行第三方转账是严格禁止的。",
  "p-kyc-contact-info-requirements":
    "用户在申请中输入的所有联系方式，以及传递给服务的其他个人数据，必须是最新的且完全真实的。",
  "p-kyc-no-anonymous-connections":
    "用户严禁通过匿名代理服务器或任何其他匿名互联网连接创建申请。",
  "p-kyc-policy-description":
    '本反洗钱政策 (以下简称 "AML政策") 说明了SolidSwap为打击洗钱所使用的程序和机制。',
  "p-kyc-no-business-with-criminals":
    "不与已知的罪犯和/或恐怖分子建立业务关系；",
  "p-kyc-no-criminal-transactions": "不处理已知的犯罪和/或恐怖活动的交易；",
  "p-kyc-no-facilitating-criminal-activity":
    "不协助任何已知的犯罪和/或恐怖活动相关的交易；",
  "p-kyc-right-to-restrict-services":
    "有权限制或禁止对受限制的人员使用全部或部分服务...",
  "p-kyc-verification-procedures-title": "验证程序",
  "p-kyc-kyc-policy-description":
    'SolidSwap服务在反洗钱标准的框架下建立了自己的验证程序—"了解你的客户" (KYC) 政策。',
  "p-kyc-verification-steps":
    "SolidSwap服务用户通过验证程序（提供政府身份证明文件：护照或身份证）。SolidSwap保留为执行AML政策而收集用户身份信息的权利。",
  "p-kyc-request-second-document":
    "SolidSwap服务还可以要求提供第二份证明文件...",
  "p-kyc-verification-authenticity":
    "SolidSwap服务验证用户提供的文件和信息的真实性...",
  "p-kyc-request-updated-documents":
    "如果用户的身份信息发生变更或其活动可疑，SolidSwap服务有权要求更新的文件...",
  "p-kyc-aml-compliance-title": "负责遵守AML政策的人员",
  "p-kyc-aml-compliance-description":
    "负责遵守AML政策的是SolidSwap的一名员工，其职责包括确保遵守AML政策，即：",
  "p-kyc-collect-user-info":
    "收集用户的身份信息并将其转交给负责的数据处理代理；",
  "p-kyc-create-update-policies": "创建和更新内部政策和程序...",
  "p-kyc-monitor-transactions": "监控交易并分析任何显著的异常活动...",
  "p-kyc-record-management": "记录管理系统的实施...",
  "p-kyc-regular-risk-update": "定期更新风险评估。",
  "p-kyc-interaction-with-authorities":
    "负责遵守AML政策的人员有权与防止洗钱的执法机构合作...",
  "p-kyc-transaction-monitoring-title": "交易监控",
  "p-kyc-monitoring-description": "监控用户交易并分析接收到的数据...",
  "p-kyc-report-suspicious-transactions": "向相关执法机构报告可疑交易；",
  "p-kyc-request-additional-info": "要求用户提供额外的信息和文件；",
  "p-kyc-suspend-terminate-account": "暂停或终止用户账户；",
  "p-kyc-freeze-assets": "暂停交易并冻结资产，直至情况澄清；",
  "p-kyc-return-funds": "根据用户协议取消交易程序并退还用户资金；",
  "p-kyc-risk-assessment": "风险评估。",
  "p-kyc-additional-monitoring": "AML政策官员每日监控用户交易以确定是否...",
};
