export default {
  "p-blog-codeId-failed-to-save-post": "Fehler beim Speichern des Beitrags!",
  "p-blog-codeId-post-saved-successfully": "Beitrag erfolgreich gespeichert!",
  "p-blog-codeId-confirm-delete":
    "Sind Sie sicher, dass Sie diesen Beitrag löschen möchten?",
  "p-blog-codeId-warning": "Warnung",
  "p-blog-codeId-delete": "Löschen",
  "p-blog-codeId-post-deleted-successfully": "Beitrag erfolgreich gelöscht!",
  "p-blog-codeId-failed-to-delete-post": "Fehler beim Löschen des Beitrags!",
  "p-blog-codeId-post-not-found": "Blog-Beitrag nicht gefunden :(",
  "p-blog-codeId-back-to-all-posts": "Zurück zu allen Beiträgen",
  "p-blog-codeId-edit-post": "Beitrag bearbeiten",
  "p-blog-codeId-delete-post": "Beitrag löschen",

  "p-blog-index-title":
    "Blog | Einblicke, Tipps und Geschichten von Solid Swap",
  "p-blog-index-meta-description":
    "Entdecken Sie die neuesten Einblicke, Tipps und Geschichten in unserem Blog. Bleiben Sie auf dem Laufenden mit unseren neuesten Beiträgen zu einer Vielzahl von Themen, darunter Technologie, Lifestyle und mehr",
  "p-blog-index-meta-keywords":
    "blog, einblicke, tipps, geschichten, technologie, lifestyle, neueste beiträge, Solid Swap, solidswap",
  "p-blog-index-failed-to-fetch-posts": "Fehler beim Abrufen der Beiträge",
  "p-blog-index-solid-swap-blog": "Solid Swap Blog",
  "p-blog-index-search-placeholder": "Blog-Beiträge durchsuchen...",
  "p-blog-index-new-post": "Neuer Beitrag",
  "p-blog-index-active": "Aktiv",
  "p-blog-index-archive": "Archiv",
  "p-blog-index-load-more": "Mehr laden",
  "p-blog-index-no-posts-found": "Keine Beiträge gefunden",
};
