export default {
  "p-trade-profile-title":
    "Options de Paiement | Méthodes de paiement crypto sécurisées sur Solid Swap",
  "p-trade-profile-description":
    "Découvrez les différentes options de paiement sécurisées disponibles sur Solid Swap pour le trading de cryptomonnaies. Choisissez la méthode qui vous convient le mieux",
  "p-trade-profile-keywords":
    "options de paiement crypto, méthodes de paiement sécurisées, paiements de devises numériques, transactions de cryptomonnaies",
  "p-trade-profile-option-delete-confirm":
    "Cette action supprimera votre option de paiement. Continuer ?",
  "p-trade-profile-warning": "Avertissement",
  "p-trade-profile-delete": "Supprimer",
  "p-trade-profile-cancel": "Annuler",
  "p-trade-profile-option-delete-error":
    "Une erreur s'est produite lors de la suppression de l'option de paiement",
  "p-trade-profile-my-payment-options": "Mes options de paiement",
  "p-trade-profile-payment-options-info-1":
    "Ce sont vos options de paiement, nécessaires pour toutes les transactions entre utilisateurs.",
  "p-trade-profile-payment-options-info-2":
    "Si vous vendez de la cryptomonnaie, les options de paiement informeront la contrepartie où transférer les fonds pour l'achat.",
  "p-trade-profile-payment-options-info-3":
    "Si vous achetez de la cryptomonnaie, ces options seront utilisées pour sélectionner le type de paiement lors de la création de la commande, indiquant au vendeur votre méthode de paiement préférée pour la commande en cours.",
  "p-trade-profile-no-options": "Vous n'avez encore aucune option",
  "p-trade-profile-edit": "Modifier",
  "p-trade-profile-add-new-payment-option":
    "Ajouter une nouvelle option de paiement",
};
