export default {
  "c-chat-indexclient-historyLoadError": "Չատ պատմության բեռնումի սխալ",
  "c-chat-indexclient-pictureFormatError":
    "Նկարը պետք է լինի jpg/png ֆորմատով:",
  "c-chat-indexclient-pictureSizeError": "Նկարի չափը չպետք է գերազանցի 10MB:",
  "c-chat-indexclient-uploadError": "Նկարի վերբեռնման սխալ:",
  "c-chat-indexclient-connectionError": "Չատին միանալու սխալ",
  "c-chat-indexclient-summaryLoadError": "Չատի ամփոփման բեռնումի սխալ",
  "c-chat-indexclient-chatWith": "Չատվել",
  "c-chat-indexclient-counterparty": "հակառակ կողմ",
  "c-chat-indexclient-online": "առցանց",
  "c-chat-indexclient-wasOnline": "էր առցանց",
  "c-chat-indexclient-andMore": "և {count} ավելին",
  "c-chat-indexclient-allParticipants": "Բոլոր չատի մասնակիցները",
  "c-chat-indexclient-moderator": "մոդերատոր",
  "c-chat-indexclient-welcomeMessage":
    "Բարի գալուստ չատ՝ պատվերի համար #{orderId}",
  "c-chat-indexclient-paymentSent": "Գնորդը ուղարկել է վճարումը",
  "c-chat-indexclient-paymentConfirmed": "Վաճառողը հաստատել է վճարումը",
  "c-chat-indexclient-disputeCreated": "Ստեղծվել է վեճ",
  "c-chat-indexclient-orderCancelled": "Պատվերը չեղարկվել է",
  "c-chat-indexclient-moderatorJoined": "Մոդերատորը միացել է չատին",
  "c-chat-indexclient-sessionStarted": "Աջակցության նստաշրջանը սկսված է",
  "c-chat-indexclient-sessionClosed": "Աջակցության նստաշրջանը փակված է",
  "c-chat-indexclient-placeholder": "Մուտքագրեք խնդրում եմ",
};
