export default {
  "c-payment-option-close-dialog-confirm":
    "Are you sure to close payment option dialog?",
  "c-payment-option-save-error":
    "An error occurred while saving the payment option",
  "c-payment-option-edit": "Edit payment option",
  "c-payment-option-new": "New payment option",
  "c-payment-option-show-currency-methods":
    "There will be shown only payment methods with currency: {currency}",
  "c-payment-option-show-specified-options":
    "There will be shown only payment options specified in the offer",
  "c-payment-option-editing-method": "Editing payment method for:",
  "c-payment-option-select-method": "Select the method you want to add:",
  "c-payment-option-select-method-placeholder": "select payment method...",
  "c-payment-option-description": "Payment Type Description:",
  "c-payment-option-description-info":
    "Please provide a description of your payment type. This should include your account number, card number, or any relevant information that will help buyers identify where they should transfer their funds when they purchase cryptocurrency from you.",
  "c-payment-option-description-placeholder": "write some description...",
  "c-payment-option-save": "Save",
  "c-payment-option-validate-payment-option": "Please, select a payment option",
  "c-payment-option-validate-description": "Please add the description",
};
