export default {
  "p-help-head-title": "Помощь | Свяжитесь с поддержкой Solid Swap",
  "p-help-head-description":
    "Нужна помощь? Откройте тикет поддержки на Solid Swap и получите помощь от нашей экспертной команды для решения любых возникающих у вас проблем",
  "p-help-head-keywords":
    "тикет поддержки, помощь Solid Swap, поддержка по торговле криптовалютой, связаться с поддержкой",
  "p-help-title": "Страница помощи",
  "p-help-name-required": "Пожалуйста, введите ваше имя",
  "p-help-email-required": "Пожалуйста, введите вашу электронную почту",
  "p-help-message-required": "Сообщите нам вашу проблему здесь",
  "p-help-ticket-success": "Тикет успешно отправлен!",
  "p-help-ticket-error":
    "Что-то пошло не так при отправке тикета, попробуйте позже",
  "p-help-thank-you-message":
    "Спасибо, что обратились к нам! Мы получили ваше сообщение и свяжемся с вами по указанному вами адресу электронной почты в ближайшее время.",
  "p-help-intro-text":
    "Если у вас возникли проблемы или вопросы, пожалуйста, не стесняйтесь обращаться к нам. Заполните форму ниже, и мы свяжемся с вами как можно скорее.",
  "p-help-label-name": "Имя",
  "p-help-placeholder-name": "Введите ваше имя здесь",
  "p-help-label-email": "Электронная почта",
  "p-help-placeholder-email": "Введите вашу электронную почту здесь",
  "p-help-label-message": "Сообщение",
  "p-help-placeholder-message": "...",
  "p-help-submit-button": "Отправить",
};
