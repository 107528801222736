export default {
  "p-login-head-title": "登录 | 访问您的 Solid Swap 账户",
  "p-login-head-description":
    "登录您的 Solid Swap 账户，开始安全高效地进行加密货币交易",
  "p-login-head-keywords": "登录，Solid Swap 账户，加密货币交易访问",
  "p-login-rules-email": "请输入您的邮箱",
  "p-login-rules-password": "请输入您的密码",
  "p-login-error-default": "哎呀，登录时出了点问题。",
  "p-login-error-2fa-wrong": "错误的双重身份验证代码！请重试。",
  "p-login-error-user-not-found": "用户未找到",
  "p-login-error-email-verification": "您的邮箱需要先验证。请检查您的邮箱。",
  "p-login-error-suspended": "您的账户已被暂停。请检查您的邮箱以获取更多详情。",
  "p-login-error-locked-title": "暂时锁定",
  "p-login-error-locked-message":
    "您已进行 {failedLockCount} 次或更多不成功的登录尝试。出于安全原因，请在 60 分钟后再试。如果您需要立即帮助或忘记了密码，请使用“忘记密码”选项重置密码。",
  "p-login-error-wrong-password-title": "错误的密码",
  "p-login-error-wrong-password-message":
    "您已进行 {failedAttemptsCount} 次不成功的登录尝试。请小心：在 {failedLockCount} 次失败后，您的账户将被暂时锁定 60 分钟。",
  "p-login-password-reset-success": "成功！",
  "p-login-password-reset-error": "重置密码时出了点问题，请稍后重试",
  "p-login-title": "登录",
  "p-login-label-email": "电子邮件",
  "p-login-label-password": "密码",
  "p-login-email-placeholder": "输入您的邮箱",
  "p-login-password-placeholder": "输入您的密码",
  "p-login-forgot-password": "忘记密码？",
  "p-login-submit-button": "登录",
  "p-login-2fa-prompt": "从您的应用中输入双重身份验证代码：",
  "p-login-2fa-placeholder": "输入代码...",
  "p-login-2fa-button": "继续",
  "p-login-password-reset-success-title": "邮件已发送！",
  "p-login-password-reset-success-message":
    "我们已向您的邮箱地址发送了密码重置链接。请检查您的收件箱并按照说明重置密码。",
  "p-login-password-reset-alert":
    "如果没有看到邮件，请检查您的垃圾邮件或垃圾文件夹。如果仍然没有收到邮件，请重试提交邮箱地址或联系支持团队寻求帮助。",
  "p-login-back-to-login": "返回登录",
  "p-login-forgot-password-title": "忘记密码？",
  "p-login-forgot-password-message":
    "请输入与您账户关联的邮箱地址，然后点击“继续”。",
  "p-login-password-reset-instructions":
    "我们将向您发送一封包含重置密码说明的邮件。如果几分钟内没有收到邮件，请检查垃圾邮件文件夹或重试。",
  "p-login-enter-email": "输入您的邮箱",
  "p-login-cancel": "取消",
  "p-login-proceed": "继续",
  "p-login-go-to-registration": "没有账户？前往注册",
  "p-login-welcome-title": "欢迎来到交易新时代！",
  "p-login-welcome-message-1": "安全连接到数字资产世界。",
  "p-login-welcome-message-2":
    "在我们值得信赖的 P2P 平台上自信便捷地开始交易。",
  "p-login-welcome-message-3": "您的无缝加密交易之门即将开启。",
};
