export default {
  "c-offers-editform-closeConfirmation":
    "Վստահ եք, որ ցանկանում եք փակել այս պատուհանը?",
  "c-offers-editform-percentError":
    "Տոկոսային արժեքը պետք է լինի -99-ից մինչև 99",
  "c-offers-editform-createError": "Առաջարկ ստեղծելիս սխալ է տեղի ունեցել",
  "c-offers-editform-createSuccess": "Առաջարկը հաջողությամբ ստեղծվել է!",
  "c-offers-editform-updateSuccess": "Առաջարկը հաջողությամբ թարմացվել է!",
  "c-offers-editform-typeRequired": "Խնդրում ենք ընտրել պատվերի տեսակը",
  "c-offers-editform-currencyRequired": "Խնդրում ենք ընտրել արժույթը",
  "c-offers-editform-priceTypeRequired": "Խնդրում ենք ընտրել գնի տեսակը",
  "c-offers-editform-priceValRequired": "Խնդրում ենք մուտքագրել գնի արժեքը",
  "c-offers-editform-paymentOptionsRequired":
    "Խնդրում ենք ընտրել վճարման տարբերակները",
  "c-offers-editform-minLimitRequired":
    "Խնդրում ենք մուտքագրել նվազագույն քանակը",
  "c-offers-editform-minLimitError":
    "Նվազագույն քանակը պետք է մեծ լինի {minAmount}-ից",
  "c-offers-editform-maxLimitRequired":
    "Խնդրում ենք մուտքագրել առավելագույն քանակը",
  "c-offers-editform-maxLimitError":
    "Արավելագույն քանակը չպետք է լինի փոքր քան նվազագույն քանակը",
  "c-offers-editform-offerAmountRequired":
    "Խնդրում ենք մուտքագրել առաջարկի քանակը",
  "c-offers-editform-termsOfUseRequired":
    "Խնդրում ենք մուտքագրել օգտագործման պայմանները",
  "c-offers-editform-editTitle": "Խմբագրել ձեր առաջարկը",
  "c-offers-editform-addTitle": "Ավելացնել նոր առաջարկ",
  "c-offers-editform-offerType": "Առաջարկի տեսակ",
  "c-offers-editform-sell": "վաճառել",
  "c-offers-editform-buy": "գնել",
  "c-offers-editform-selling": "Վաճառք",
  "c-offers-editform-buying": "Գնում",
  "c-offers-editform-mainCurrency": "Հիմնական արժույթ",
  "c-offers-editform-selectCurrency": "ընտրեք արժույթ",
  "c-offers-editform-forCurrency": "...համար",
  "c-offers-editform-currentRate":
    "ընթացիկ փոխարժեքը: 1 {currencyIn} = {rate} {currencyOut}",
  "c-offers-editform-price": "Գին",
  "c-offers-editform-priceType": "Գնի տեսակ",
  "c-offers-editform-selectPriceType": "ընտրեք գնի տեսակ",
  "c-offers-editform-percentValue": "Տոկոսային արժեք",
  "c-offers-editform-relativeValue": "Հարաբերական արժեք",
  "c-offers-editform-fixedValue": "Ֆիքսված արժեք",
  "c-offers-editform-priceTypesExplained": "Գնի տեսակները բացատրված են:",
  "c-offers-editform-percent": "Տոկոս",
  "c-offers-editform-percentExplanation":
    "Գինը կարգավորվում է ընթացիկ փոխարժեքներից տոկոսային տարբերության հիման վրա: Եթե օգտատերը մուտքագրում է տոկոս, վերջնական գինը կհաշվարկվի՝ կիրառելով այդ տոկոսը ընթացիկ փոխարժեքներին ավելացնելու կամ հանելու միջոցով:",
  "c-offers-editform-relative": "Հարաբերական",
  "c-offers-editform-relativeExplanation":
    "Գինը կարգավորվում է ընթացիկ փոխարժեքներից կոնկրետ թվային արժեք ավելացնելով կամ հանելով: Օգտատերը մուտքագրում է այս թիվը, և դա ուղղակիորեն փոխում է հիմնական գինը՝ դարձնելով վերջնական գինը ավելի բարձր կամ ցածր այդ ճշգրիտ գումարով:",
  "c-offers-editform-fixed": "Ֆիքսված",
  "c-offers-editform-fixedExplanation":
    "Սա պարզ գնի կարգավորում է, որտեղ օգտատերը ուղղակիորեն կարգավորում է գինը: Օգտատիրոջ կողմից մուտքագրված գինը կլինի վերջնական գինը՝ առանց ընթացիկ փոխարժեքներից կամ տոկոսներից որևէ փոփոխությունների:",
  "c-offers-editform-pricePercent": "Գնի տոկոս",
  "c-offers-editform-priceRelative": "Հարաբերական գին",
  "c-offers-editform-priceFixed": "Ֆիքսված գին",
  "c-offers-editform-enterPriceValue": "մուտքագրեք գնի արժեքը",
  "c-offers-editform-rate":
    "ըստ ընտրված տեսակի և գնի դաշտերի՝ ձեր փոխարժեքը: 1 {currencyIn} = {rate} {currencyOut}",
  "c-offers-editform-paymentOptions": "Վճարման տարբերակներ",
  "c-offers-editform-selectPaymentOptions": "ընտրեք վճարման տարբերակները",
  "c-offers-editform-noOptions": "Ոչ մի տարբերակ հասանելի չէ",
  "c-offers-editform-all": "Բոլորը",
  "c-offers-editform-addOption": "Ավելացնել վճարման տարբերակ",
  "c-offers-editform-minLimit": "Նվազագույն քանակ",
  "c-offers-editform-enterMinAmount": "նվազ. քանակ",
  "c-offers-editform-maxLimit": "Արավելագույն քանակ",
  "c-offers-editform-enterMaxAmount": "արավել. քանակ",
  "c-offers-editform-orderLimitsExplanation":
    "Պատվերի սահմանների բացատրություն",
  "c-offers-editform-orderLimitsExplanationTitle":
    "Պատվերի սահմանների բացատրություն",
  "c-offers-editform-orderLimitsExplanationText":
    "Նվազագույն սահմանը և առավելագույն սահմանը սահմանում են այն միջակայքը, որի շրջանակներում դուք կարող եք ստեղծել պատվեր: Դուք չեք կարող պատվեր կատարել կրիպտոարժույթի գնելու կամ վաճառելու համար՝ նվազագույն սահմանից ցածր կամ առավելագույն սահմանից բարձր:",
  "c-offers-editform-orderLimitsExplanationUSD":
    "Բացի այդ, մեր հարթակը պահանջում է նվազագույն սահման, որը համարժեք է {MIN_OFFER_AMOUNT_USD} ԱՄՆ դոլարին բոլոր առաջարկների համար, որը մոտ {usdLimit} {currencyOut}",
  "c-offers-editform-amountToTrade": "Փոխանակվող քանակ",
  "c-offers-editform-offerAmount": "Առաջարկի քանակ",
  "c-offers-editform-offerAmount-unlimited": "անսահմանափակ",
  "c-offers-editform-enterOfferAmount": "առավել. քանակ",
  "c-offers-editform-currentBalance":
    "Իմ ընթացիկ մնացորդը: {balance} {currencyIn}",
  "c-offers-editform-offerAmountExplanation": "Առաջարկի քանակի բացատրություն",
  "c-offers-editform-offerAmountExplanationTitle":
    "Առաջարկի քանակի բացատրություն",
  "c-offers-editform-offerAmountExplanationText1":
    "Առաջարկի քանակը այն ընդհանուր քանակն է, որը դուք պատրաստ եք փոխանակել:",
  "c-offers-editform-offerAmountExplanationText2":
    "Վաճառքի առաջարկների համար սա այն քանակն է, որը դուք ցանկանում եք վաճառել: Օրինակ՝ եթե ձեր հաշվեկշռում ունեք 1000 USDT և ցանկանում եք փոխանակել միայն կեսը, կարող եք սահմանել առաջարկի քանակը 500 USDT: Սա ապահովում է, որ դուք չեք վաճառի ավելի քան 500 USDT: Նշենք, որ ձեր բոլոր միջոցները մնում են ձեր հաշվին, և առաջարկի չեղարկումը դրանք կվերադարձնի ձեր հաշվեկշռին:",
  "c-offers-editform-offerAmountExplanationText3":
    "Գնելու առաջարկների համար այս քանակը ցույց է տալիս, թե որքան կրիպտոարժույթ եք ցանկանում գնել: Եթե դուք սահմանեք առաջարկի քանակը 1000 USDT, ապա կստանաք հենց այդ քանակը:",
  "c-offers-editform-offerAmountExplanationText4":
    "Բացի այդ, մեր հարթակը գանձում է միջնորդավճար, որը վճարում է վաճառողը: Այսպիսով, եթե դուք ստեղծում եք ՎԱՃԱՌՔ առաջարկ, ապա միջնորդավճարը ձեզ վրա է: Եթե դուք ստեղծում եք ԳՆՄԱՆ առաջարկ, միջնորդավճարը կլինի հակառակ կողմի վրա, ով ստեղծում է պատվերը:",
  "c-offers-editform-termsOfUse": "Օգտագործման պայմաններ",
  "c-offers-editform-termsOfUseText":
    "Խնդրում ենք լրացնել ձեր 'Օգտագործման պայմանները' ներքևում: Այս բաժինը պետք է նկարագրի այն ընդհանուր պայմաններն ու պայմանները, որոնց շրջանակներում դուք պատրաստ եք փոխանակել: Այստեղ անհրաժեշտ չէ նշել ձեր բանկային տվյալները կամ այլ զգայուն տվյալներ:",
  "c-offers-editform-termsOfUsePlaceholder": "գրեք մի քանի նկարագրություն...",
  "c-offers-editform-cancel": "Չեղարկել",
  "c-offers-editform-confirm": "Հաստատել",
};
