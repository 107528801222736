export default {
  "p-exchange-tos-agreement-intro":
    "本用户协议（以下简称“协议”）是用户与SolidSwap服务之间的电子协议，包含了在SolidSwap网站上的操作规则，网址为",
  "p-exchange-tos-agreement-intro-continued":
    "（以下简称“网站”），以及网站提供的访问服务条款。在使用网站之前，用户（以下简称“用户”）必须仔细阅读并接受本协议。如果用户不接受协议或不同意遵守协议的所有条款，应立即停止使用网站。",
  "p-exchange-tos-agreement-updates":
    "网站管理保留单方面更新和更改协议文本的权利，且无需通知用户。协议的新版本在网站发布后立即生效。用户有责任自行监控协议的新版本。继续使用网站即表示用户自动接受协议的新版本。如果用户不同意协议的新版本条款，用户应停用其账户（如适用）并停止使用服务。",
  "p-exchange-tos-terms-definitions": "1. 术语和定义",
  "p-exchange-tos-solid-swap-service":
    "1.1. SolidSwap服务是一个为虚拟数字资产交易提供平台。",
  "p-exchange-tos-user-definition":
    "1.2. 用户是指在平台上注册并同意本协议条款的个人。用户确认已年满18岁，其所在国家的法律不禁止使用本服务，且用户不打算通过使用SolidSwap服务从事任何非法活动。",
  "p-exchange-tos-title-sign":
    "1.3. 标题符号是电子和其他货币类型支付系统的常规单位，表示根据不同支付系统及其所有者协议计算的金额。",
  "p-exchange-tos-payment-system":
    "1.4. 支付系统是通过电子货币转账实现商品和服务支付的在线服务。",
  "p-exchange-tos-service-operations":
    "1.5. 服务操作——电子货币的存取操作，以及网站上发布的其他服务信息。",
  "p-exchange-tos-payment-definition":
    "1.6. 支付是通过支付系统进行的资金转移。",
  "p-exchange-tos-otc-platform":
    "1.7. OTC平台是用于寻找交易对手并进行场外交易的平台。",
  "p-exchange-tos-personal-data":
    "1.8. 个人数据——可以识别个人身份的信息，如姓名、地址、电子邮件地址、手机号码、投标信息和银行详细信息。“个人数据”不包括匿名和/或聚合数据，这些数据无法识别特定用户。",
  "p-exchange-tos-agreement-definition":
    "1.9. 协议——本用户协议及其组成部分，考虑到所有根据其条款进行的增补和/或更改。",
  "p-exchange-tos-website-definition":
    "1.10. 网站——信息、文本、图形元素、设计、图片、照片和视频及其他知识产权成果的集合，以及一个包含在信息系统中的计算机程序集合，确保这些信息可在",
  "p-exchange-tos-website-continued":
    "提供，包括任何子域名，如果由于任何原因需要更改域名或网站材料，这包括更改后的网站。",
  "p-exchange-tos-intellectual-property":
    "1.11. 知识产权——网站上发布的所有材料以及服务提供的所有服务均属于服务所有，不得第三方使用：发布、分发、复制。",
  "p-exchange-tos-application-definition":
    "1.12. 申请——用户使用服务提供的服务进行购买或出售虚拟数字资产的要约。",
  "p-exchange-tos-electronic-document":
    "1.13. 电子文件——以电子和数字形式的信息。",
  "p-exchange-tos-suspicious-traffic":
    "1.14. 可疑流量——可能违反AML/CTF和KYC政策的一系列操作、交易、资产等。",
  "p-exchange-tos-verification-procedures":
    "1.15. 验证程序（KYC）——根据反洗钱标准实施的“了解你的客户”（KYC）政策的验证程序。",
  "p-exchange-tos-persons-subject-restrictions":
    "1.16. 受限制人员——指任何在美国的个人和/或任何被列入美国财政部特别类别或被封锁人员名单、欧盟金融制裁综合名单或英国制裁名单的个人，以及来自受限司法管辖区的公民和居民（见第1.17条）。",
  "p-exchange-tos-limited-jurisdiction":
    "1.17. 受限司法管辖区包括：美国、阿富汗、波斯尼亚和黑塞哥维那、朝鲜民主主义人民共和国、刚果民主共和国、厄立特里亚、埃塞俄比亚、圭亚那、伊朗、伊拉克、老挝人民民主共和国、利比亚、索马里、斯里兰卡、苏丹、叙利亚、特立尼达和多巴哥、突尼斯、乌干达、瓦努阿图、乌克兰、英国、中国大陆、新加坡、香港、加拿大、古巴、乌兹别克斯坦、克里米亚、法国、塞瓦斯托波尔、顿涅茨克和卢甘斯克地区。",
  "p-exchange-tos-introduction": "2. 引言",
  "p-exchange-tos-introduction-text":
    "2.1. 本协议规定了用户与服务之间的关系，并取消了服务和用户之前的所有关于此主题的协议。",
  "p-exchange-tos-subject-agreement": "3. 协议的主题",
  "p-exchange-tos-subject-agreement-services":
    "3.1. 本协议的主题是网站上描述的服务提供的服务。",
  "p-exchange-tos-subject-agreement-restrictions":
    "3.2. 服务向所有感兴趣的用户提供服务，但第1.16条所列的受限制人员除外。",
  "p-exchange-tos-payment-systems-responsibility":
    "3.3. 支付系统和/或金融机构对用户托付给他们的资金独立负责。服务不能成为支付系统和/或金融机构与用户之间的协议的一方，且对用户对支付系统的错误或未经授权的使用不负责任，也不负责用户滥用支付系统的功能。用户与支付系统和/或金融机构的相互权利和义务由相关协议规定。",
  "p-exchange-tos-operation-cannot-cancel":
    "3.4. 用户发起的任何资金交换操作以及服务提供给用户的任何其他操作不能被用户取消。",
  "p-exchange-tos-rights-obligations": "4. 双方的权利和义务",
  "p-exchange-tos-suspend-operation-authorities":
    "4.1. 如果授权机构收到有关用户资金所有权非法的信息和/或其他信息，导致服务无法为用户提供服务，服务有权暂停或取消正在进行的操作。",
  "p-exchange-tos-suspend-refund-operation":
    "4.2. 如果用户违反本协议条款，服务有权暂停、拒绝退款、取消正在进行的操作。",
  "p-exchange-tos-cancel-operation-title-return":
    "4.3. 服务有权取消正在进行的操作并返还用户输入的标题符号，而无需解释原因。",
  "p-exchange-tos-prevent-illegal-activities":
    "4.4. 服务承诺采取所有可能和可用的行动，以防止使用服务进行非法贸易、金融欺诈和洗钱。这些行动包括：在搜寻和逮捕从事非法洗钱活动的金融恐怖分子方面提供所有可能的协助；根据现行法律向有关当局提供有关服务处理的信息；改进服务，以防止服务在违背打击非法贸易、金融欺诈和洗钱法律的活动中被直接或间接使用。",
  "p-exchange-tos-suspicious-traffic-rights":
    "4.5. 如果发现用户有可疑流量，服务保留根据执法机构的要求，转交所有有关用户身份、交易请求和收到货币的信息的权利。",
  "p-exchange-tos-accept-agreement":
    "4.6. 使用网站提供的服务即表示用户接受并确认其同意这些规则以及AML/CTF和KYC政策。",
  "p-exchange-tos-user-guarantee":
    "4.7. 使用网站提供的服务，用户保证其提供的个人信息完整、真实且最新，服务的使用仅用于个人目的，不违反用户所在国的法律，且用户承诺及时更新数据以保持其相关性。",
  "p-exchange-tos-user-confidentiality":
    "4.8. 用户保证其注册数据和账户访问的保密性，并对其账户下发生的所有操作负全责，包括账户被黑或未经授权访问的情况。",
  "p-exchange-tos-report-security-breach":
    "4.9. 用户承诺及时报告其账户安全的任何违规行为。",
  "p-exchange-tos-block-account":
    "4.10. 如果怀疑有非法行为、账户被黑或违反服务规则，服务有权封锁用户的账户。如果用户妨碍服务的正常运行，其账户将被封锁，且无恢复权。",
  "p-exchange-tos-rightful-owner":
    "4.11. 接受本协议条款，用户保证其为资金和/或加密货币的合法所有者。",
  "p-exchange-tos-understand-service-risks":
    "4.12. 接受本用户协议，用户确认其理解服务的原则并对使用服务相关的所有风险和损失承担全部责任。",
  "p-exchange-tos-consent-data-processing":
    "4.13. 用户同意服务在网站上收集的个人数据处理，包括注册时、验证程序期间以及其他关于客户的信息收集期间。",
  "p-exchange-tos-personal-data-includes": "4.14. 个人数据包括：",
  "p-exchange-tos-name": "姓名、名、父名；",
  "p-exchange-tos-photo-passport": "照片/护照扫描件；",
  "p-exchange-tos-other-id-documents": "其他身份证明文件；（根据具体情况）",
  "p-exchange-tos-video-selfie": "持护照的自拍视频；",
  "p-exchange-tos-email": "电子邮件地址；",
  "p-exchange-tos-phone-number": "可用于联系客户的手机号码；",
  "p-exchange-tos-wallet-details": "所使用钱包的详细信息；（根据具体情况）",
  "p-exchange-tos-card-number": "卡号和/或银行账户；（根据具体情况）",
  "p-exchange-tos-transactions-wallets": "交易和钱包地址。",
  "p-exchange-tos-processing-personal-data":
    "4.15. 个人数据处理是指个人数据的记录、系统化、积累、存储、澄清（更新、修改）、提取、使用、转移（分发、提供、访问）、包括跨境、去身份化、阻止、删除、销毁不属于特殊类别的个人数据的处理，这些处理需要书面同意用户。",
  "p-exchange-tos-processing-purpose":
    "4.16. 个人数据处理的目的是履行协议双方的义务，在网站上注册用户，为用户提供技术支持，提交申请，提供服务，处理申诉和投诉，向用户的电子邮件地址发送信息和其他消息。",
  "p-exchange-tos-fincen-rule":
    "4.17. 根据《联邦规则法典》第1010.100（ff）条的最终规定，金融犯罪调查网络（FinCEN）要求每个提供结算和现金服务的外国公司任命一个常驻美国的服务代表，以根据《银行保密法协会》的要求进行审判，并根据其章程，SolidSwap不向美国公民和居住在美国的用户提供电子货币兑换服务。",
  "p-exchange-tos-legal-prohibited":
    "4.18. SolidSwap提供的服务在某些司法管辖区可能被法律禁止。接受SolidSwap的使用条款即表示您确认自己不是这些司法管辖区的居民。",
  "p-exchange-tos-aml-kyc-checks":
    "4.19. 为了遵守《第五反洗钱和打击恐怖融资指令》（5AMLD），服务有权进行AML和KYC检查。",
  "p-exchange-tos-kyt-aml-violation":
    "4.19.1. 如果根据KYT/AML验证结果发现加密货币交易/资产存在违反“AML/CTF和KYC政策”的行为，资金将被冻结，用户将被要求进行额外的用户验证（KYC），并提供资金合法来源的证明。",
  "p-exchange-tos-kyt-aml-non-return":
    "4.19.2. 如果根据KYT/AML验证结果发现加密货币交易/资产存在违反“AML/CTF和KYC政策”的行为，且/或情况符合本协议第9.4.2条的规定，则资产可能无法进行交换和退还。",
  "p-exchange-tos-services-service": "5. 服务的服务",
  "p-exchange-tos-order-services":
    "5.1. 用户通过与服务代表联系，订购服务并获取交易进度信息。",
  "p-exchange-tos-service-internal-schedule":
    "5.2. 服务根据服务的内部安排提供服务。",
  "p-exchange-tos-questions-complaints":
    "5.3. 申请相关问题和投诉在注册后的24小时内接受。24小时后，交换请求将被视为已正确执行或未支付，具体取决于在该时间到期时的状态。",
  "p-exchange-tos-suspicious-actions":
    "5.4. 如果客户有任何可疑行为，或用户使用可疑资产，服务可能会延迟付款，并要求额外的身份验证和对用户所用资金的内部分析。",
  "p-exchange-tos-right-to-refuse": "5.5. 服务保留单方面拒绝进行交换的权利。",
  "p-exchange-tos-restricted-citizens":
    "5.6. SolidSwap提供的服务禁止第1.16条所列的受限制公民使用。",
  "p-exchange-tos-cost-services": "6. 服务费用",
  "p-exchange-tos-tariffs":
    "6.1. 费率由服务管理层确定并发布在服务的网站上。服务管理层有权在不另行通知的情况下更改费率。",
  "p-exchange-tos-expectations-tariffs":
    "6.2. 服务不对客户关于费率计划变更和服务成本的预期负责。",
  "p-exchange-tos-guarantees-responsibilities": "7. 双方的保证和责任",
  "p-exchange-tos-guarantee-fulfillment":
    "7.1. 服务仅在用户委托给服务的资金限度内，按照服务文档规定的期限，保证履行其对用户的义务。",
  "p-exchange-tos-confidentiality-guarantee":
    "7.2. 服务保证并确保用户及其交易信息的保密性。服务仅在有合法依据的情况下，按照授权政府机构、官员或支付系统代表的要求提供此类信息。",
  "p-exchange-tos-efforts-technical-issues":
    "7.3. 服务尽最大努力确保服务的正常运行，但不保证不存在导致服务完全或部分中断的技术问题。服务不对用户因无法访问网站和服务导致的损失、利润损失及其他费用承担责任。",
  "p-exchange-tos-damage-responsibility":
    "7.4. 服务对用户造成的任何损害不承担责任，即使事先已知其可能性。服务不对系统被黑或第三方获取用户活动信息负责。",
  "p-exchange-tos-no-responsibility-losses":
    "7.5. 服务对用户因对服务费率、交易收益等的错误预期以及其他主观因素导致的损失、利润损失及其他费用不承担任何责任。",
  "p-exchange-tos-aml-risk-score":
    "7.6. 服务不对客户钱包在交易后的AML风险评分变化负责。",
  "p-exchange-tos-compensation":
    "7.7. 用户访问服务网站、使用服务并进行交换时，保证在因与用户使用服务相关的诉讼或索赔情况下，赔偿服务（管理公司、管理人员和员工）的损失。",
  "p-exchange-tos-not-responsible-communication":
    "7.8. 用户同意在通信中断、互联网运行中断以及不可抗力情况下，服务不承担任何责任。",
  "p-exchange-tos-guarantee-owner-funds":
    "7.9. 用户保证其为交易中所使用的资金、银行转账等的所有者或有合法处置依据。",
  "p-exchange-tos-no-falsify-communication":
    "7.10. 用户承诺不伪造与服务操作相关的通信流。",
  "p-exchange-tos-force-majeure": "8. 不可抗力",
  "p-exchange-tos-force-majeure-definition":
    "8.1. 如果因不可抗力因素导致本协议义务的全部或部分未履行，包括：火灾、洪水、地震、恐怖袭击、政权更迭、内乱、对服务的DDOS攻击、支付系统、能源供应系统、通信网络和互联网服务提供商的故障，双方将免除责任。",
  "p-exchange-tos-error-during-payment":
    "8.2. 在支付交换时发生错误，用户将收到关于申请状态的通知，和/或发送至指定的电子邮件地址。",
  "p-exchange-tos-payment-after-deadline":
    "8.3. 如果申请在规定的支付时间之后完成支付，服务保留根据自己的决定，将申请支付退还给用户，扣除网络手续费和服务费用，或重新计算申请的权利。",
  "p-exchange-tos-not-responsible-assets-fiat":
    "8.3.1 服务对用户在支付申请时间过期后，提交的用于兑换的“给出”法币、“接收”加密货币资产不承担责任。",
  "p-exchange-tos-not-responsible-assets-anonymous":
    "8.3.2 服务对用户使用匿名方法发送的资产不承担责任，这些方法隐藏了发送方的地址，因此不会被记入兑换服务的钱包余额。在这种情况下，兑换和退款将不可行。",
  "p-exchange-tos-deviation-exchange-rate":
    "8.4. 如果申请汇率与Bybit/Binance交易所的汇率偏差超过3%，包括由于网站的技术故障，兑换服务有权根据网站上的当前汇率重新计算申请或扣除服务成本和网络手续费后向用户退款。",
  "p-exchange-tos-invalid-exchanges":
    "8.5. 如果用户故意或无意，以及由于技术故障（包括SolidSwap服务方面）或恶意行为人的行为或其他原因进行的交换，导致SolidSwap服务遭受损失，而已执行的申请汇率与Bybit/Binance交易所的市场汇率偏差超过3%（此规则适用于兑换双方），则上述条件下进行的申请将被视为无效。服务有权在用户未来与服务的交互中扣除任何未来资金以补偿所遭受的损失。",
  "p-exchange-tos-suspicious-assets":
    "8.6. 如果发现违反服务AML协议的可疑资产/资金以及用户的可疑/欺诈行为，交易将由安全部门冻结长达180天，以便进一步调查。",
  "p-exchange-tos-unblocking-procedure":
    "要启动解锁程序，用户需要根据本协议第4.19条的规定，进行简化的用户验证（KYC）。",
  "p-exchange-tos-send-photo-passport":
    "请提供您的护照照片或扫描件，或其他身份证明文件（由您选择）；",
  "p-exchange-tos-send-phone-number": "可以用来联系您的手机号码（可选）；",
  "p-exchange-tos-send-video-selfie": "持有身份证件的自拍视频；",
  "p-exchange-tos-record-video":
    "录制一段质量良好且无中断的短视频，清晰地朗读以下数据：",
  "p-exchange-tos-record-full-name":
    "全名，身份证件的系列号和号码，并将其拿在身旁；",
  "p-exchange-tos-service-name": "我们的服务名称和视频录制日期；",
  "p-exchange-tos-application-number": "申请编号及其创建日期；",
  "p-exchange-tos-show-wallet":
    "展示进行支付/交易的确切钱包/账户、货币及金额。如果通过移动应用程序进行交易，请确保网站名称和地址（URL）可读。",
  "p-exchange-tos-write-explanation":
    "请用手写方式在纸上简要说明资金来源的合法性，并声明您从您的钱包（地址）向您的钱包（地址和交易哈希）发送了资金，且您为资产的所有者，而非第三方。请写下要求退还资产的地址。",
  "p-exchange-tos-specify-address": "请注明要求退还资产的地址/账户。",
  "p-exchange-tos-information-deadline":
    "我们希望您在收到此电子邮件后的48小时内提供上述信息。",
  "p-exchange-tos-no-unblocking-without-data":
    "如果未提供所请求的数据，SolidSwap将无法启动解锁程序。",
  "p-exchange-tos-blocking-period-expiration":
    "8.6.1. 在封锁期（180天）结束后，自通过身份验证（KYC）之日起，用户必须在30天内通过官方邮件自行联系客户支持服务。",
  "p-exchange-tos-incorrect-details":
    "8.7. 如果用户在申请中提供了错误的详细信息以接收服务的资金，且服务根据申请中指定的详细信息进行了结算，服务对此不承担任何财务责任。",
  "p-exchange-tos-payment-delay":
    "8.8. 如果银行/第三方支付系统出现故障，付款可能会延迟至多5个银行工作日，这超出了服务的控制范围。",
  "p-exchange-tos-deposit-outside-application":
    "8.9. 超出申请范围的存款可能不会被记入或会丢失。使用过期详细信息发送的资金支付将按5%的手续费处理。",
  "p-exchange-tos-non-current-details":
    "8.10. 如果用户指定的付款详细信息与兑换方向中指定的银行不同，服务对此不承担责任。向非当前银行详细信息支付资金将在48小时内按5%的手续费处理。",
  "p-exchange-tos-fiat-exchange": "9.1. 法币兑换",
  "p-exchange-tos-fiat-exchange-amount-limit":
    "9.1.1. 金额等于或少于150美元的兑换将即时处理。如果您首次使用支付系统账户在我们的服务上支付并兑换金额超过150美元，您的申请将在支付后2小时内完成。",
  "p-exchange-tos-subsequent-exchanges":
    "9.1.2. 随后的150美元以上兑换，使用相同的支付系统账户号码及用户账户，将会立即处理，前提是您在首次兑换之前注册。",
  "p-exchange-tos-additional-checks":
    "9.1.3. 如果自首次兑换起不到24小时，且通过银行详细信息的兑换总金额超过500美元，安全部门可能会决定进行额外检查，并导致最多48小时的延迟。",
  "p-exchange-tos-suspicious-actions-fiat":
    "9.1.4. 如果客户有任何可疑行为，服务保留请求账户数据以确认账户归属于客户的权利。",
  "p-exchange-tos-crypto-exchange": "9.2. 加密货币兑换",
  "p-exchange-tos-crypto-auto-manual":
    "9.2.1. 服务自动模式下的请求最多可处理12小时。服务手动模式下的请求最多可处理36小时。",
  "p-exchange-tos-sale-cryptocurrencies":
    "9.2.2. 服务网站上列出的所有加密货币的出售申请在交易收到规定的最少确认后处理（该最少确认由SolidSwap服务设定）。",
  "p-exchange-tos-recalculate-rate":
    "9.2.3. 如果交易在规定的时间内未收到所需的网络确认，服务有权重新计算您的请求，重新计算可以按照当前汇率或订单创建时的汇率进行，取决于对兑换所更有利的计算方式。",
  "p-exchange-tos-btc-confirmations":
    "9.2.4. BTC加密货币的支付在收到第3次确认后进行。如果用户在规定的申请支付时间内完成支付，且交易在此期间出现在网络上，并在比特币网络上收到1次确认，申请汇率将保持不变。如果在15分钟内收到第1次确认，申请将按当前汇率重新计算。",
  "p-exchange-tos-fiat-crypto-fixed-rate":
    "9.2.5. 在“给出”法币、“接收”加密货币的兑换方向中，汇率在支付时间内是固定的。如果客户在此期间未能将资金转入其申请中提供的钱包/卡片，则申请将按当前汇率重新计算。",
  "p-exchange-tos-crypto-sending":
    "9.2.6. 当客户购买服务网站上列出的任何加密货币时，服务发送加密货币的时间可能为5分钟至12小时。交易确认速度与服务无关。",
  "p-exchange-tos-high-load-crypto-network":
    "9.2.7. 如果服务网站上列出的加密货币网络负载高或出现技术问题，由于服务不可控的原因，资金到达收款人账户的时间可能长达240小时。",
  "p-exchange-tos-recalculation-notification":
    "9.2.8. 服务将按照本协议的条款在自动模式下重新计算申请金额，并通过电子邮件通知客户。",
  "p-exchange-tos-blocked-account-responsibility":
    "9.2.9. 服务对转入客户已被封锁的卡片或加密货币交易所或其他集中式平台的封锁账户的资金不承担责任，且如果在服务根据请求向其转移资产后，客户被封锁，服务亦不承担责任。",
  "p-exchange-tos-memo-tag-required":
    "9.2.10. 在向我们的钱包发送需要MEMO或TAG值的代币时，必须包含这些MEMO或TAG值。未使用MEMO或TAG发送的资金不予记入和退款。",
  "p-exchange-tos-smart-contract-processing":
    "9.2.11. 通过智能合约发送的代币将手动处理，处理时间为2小时。发送代币后，您需要通过在线聊天告知操作员您的申请编号和交易哈希。",
  "p-exchange-tos-refund": "9.3. 退款",
  "p-exchange-tos-refund-decision": "9.3.1. 退款仅可由服务决定。",
  "p-exchange-tos-refund-application":
    "9.3.2. 要申请退款，用户必须向服务的电子邮件地址发送邮件：",
  "p-exchange-tos-refund-minus-commission":
    "9.3.3. 退款将在扣除支付系统的转账手续费和兑换服务成本（兑换金额的5%）后进行。第4.19条所述情况除外。",
  "p-exchange-tos-refund-review": "9.3.4. 服务将在48小时内审查退款请求。",
  "p-exchange-tos-refund-within-48-hours":
    "9.3.5. 经请求，服务将在48小时内退款。（第4.19条除外）",
  "p-exchange-tos-deposit-below-minimum":
    "9.3.6. 少于最低金额的存款将不会被记入或退款。",
  "p-exchange-tos-assets-return-clause-419":
    "9.3.7. 在兑换本协议第4.19条所述的资产后，经过AML和KYC程序，服务保留不向用户提供兑换服务并返还资金的权利，并遵循内部退款规定的所有程序。",
  "p-exchange-tos-return-aml-failed-assets": "9.4. 未通过AML验证的资产的返还。",
  "p-exchange-tos-return-blocked-assets":
    "9.4.1. 仅在通过本协议第8.6条规定的身份验证和遵守第8.6.1条后，才可能返还未通过AML验证的已冻结资产。",
  "p-exchange-tos-request-from-authorities":
    "9.4.2. 如果已收到有关这些资产的授权机构和其他部门的请求，包括任何司法管辖区的请求，则这些冻结资产可能作为案件的物证使用，不可返还。",
  "p-exchange-tos-refund-5-percent-commission":
    "9.4.3. 在履行第8.6条和第8.6.1条后，客户的退款将扣除5%的手续费。",
  "p-exchange-tos-no-return-criminal-wallets":
    "9.4.4. 如果根据AML验证系统的结果，发现资产与以下类别的犯罪钱包有联系，数字资产将不予退还和兑换：",
  "p-exchange-tos-dark-market": "暗网市场",
  "p-exchange-tos-dark-service": "暗网服务",
  "p-exchange-tos-scam": "骗局",
  "p-exchange-tos-stolen": "被盗",
  "p-exchange-tos-mixing": "混合器",
  "p-exchange-tos-sextortion": "色情勒索",
  "p-exchange-tos-ransomware": "勒索软件",
  "p-exchange-tos-hack": "黑客",
  "p-exchange-tos-phishing": "网络钓鱼",
  "p-exchange-tos-terrorism-financing": "恐怖主义融资",
  "p-exchange-tos-fraud": "欺诈",
  "p-exchange-tos-blacklist": "黑名单",
  "p-exchange-tos-under-investigation": "仍在调查中",
  "p-exchange-tos-cybercrime-organization": "网络犯罪组织",
  "p-exchange-tos-narcotics": "毒品",
  "p-exchange-tos-child-abuse": "儿童虐待",
  "p-exchange-tos-human-trafficking": "人口贩运",
  "p-exchange-tos-sanctions": "制裁",
  "p-exchange-tos-change-recipient-details": "9.5. 更改申请接收人的详细信息",
  "p-exchange-tos-change-recipient-details-procedure":
    "9.5.1. 要更改接收人的详细信息，用户需通过电子邮件向邮局提交申请，发送到",
  "p-exchange-tos-acceptance-terms": "10. 接受服务条款",
  "p-exchange-tos-acceptance-terms-text":
    "10.1. 在创建申请时接受“用户协议”条款将被服务自动视为同意服务运作的所有文档。",
};
