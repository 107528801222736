export default {
  "p-trade-orders-title":
    "Trade Orders | Manage Your Crypto Orders on Solid Swap",
  "p-trade-orders-description":
    "Manage your cryptocurrency orders efficiently on Solid Swap. Track and control your trading activities seamlessly",
  "p-trade-orders-keywords":
    "manage crypto orders, trading orders, cryptocurrency order tracking, trade management",

  "p-trade-orders-order-fetch-error":
    "Error happened while loading order, please reload the page",
  "p-trade-orders-order-not-found": 'Order "{orderId}" not found',
  "p-trade-orders-payment-was-sent": "Payment was sent",
  "p-trade-orders-payment-was-sent-confirm":
    "Are you confirm that payment was sent from your side? Do that only after confirmation from bank. Continue?",
  "p-trade-orders-payment-received": "Payment received",
  "p-trade-orders-payment-received-confirm":
    "Are you confirm that payment was received? Do that only after confirmation from your bank. If you want to continue fill 2FA code from your app below",
  "p-trade-orders-open-dispute": "Open dispute",
  "p-trade-orders-open-dispute-confirm":
    "You want to open Dispute for this order. Continue?",
  "p-trade-orders-change-status": "Change status",
  "p-trade-orders-change-status-confirm":
    "Are you sure you want to change status?",
  "p-trade-orders-continue": "Continue",
  "p-trade-orders-cancel": "Cancel",
  "p-trade-orders-invalid-2fa": "Invalid 2FA",
  "p-trade-orders-status-changed": "Order status changed",
  "p-trade-orders-fetch-order-error":
    "Error occurred while fetching order data",
  "p-trade-orders-go-to-all-orders": "Go to all orders",
  "p-trade-orders-status": "Status",
  "p-trade-orders-waiting-for-payment": "Waiting for buyer's payment",
  "p-trade-orders-payment-sent": "Buyer has sent the payment",
  "p-trade-orders-order-completed": "Order completed",
  "p-trade-orders-order-cancelled": "Order cancelled",
  "p-trade-orders-dispute": "DISPUTE",
  "p-trade-orders-auto-cancel-after": "Autocancel order after",
  "p-trade-orders-open-dispute-after": "Dispute can be opened after",
  "p-trade-orders-order-created": "Order created",
  "p-trade-orders-summary": "Summary",
  "p-trade-orders-sell": "SELL",
  "p-trade-orders-buy": "BUY",
  "p-trade-orders-for": "for",
  "p-trade-orders-fee": "Fee",
  "p-trade-orders-included-in-transaction": "included in transaction",
  "p-trade-orders-selling": "Selling",
  "p-trade-orders-buying": "Buying",
  "p-trade-orders-price": "Price",
  "p-trade-orders-offer-creator": "Offer creator",
  "p-trade-orders-trader-info": "Trader provided info",
  "p-trade-orders-no-description": "No description specified from the trader.",
  "p-trade-orders-no-description-additional-info":
    "You will receive additional information after the order is created.",
  "p-trade-orders-trading-experience": "How was your trading experience?",
  "p-trade-orders-positive": "Positive",
  "p-trade-orders-negative": "Negative",
  "p-trade-orders-your-feedback": "Your feedback",

  "p-trade-ordersIndex-all": "All",
  "p-trade-ordersIndex-active": "Active",
  "p-trade-ordersIndex-completed": "Completed",
  "p-trade-ordersIndex-cancelled": "Canceled",
  "p-trade-ordersIndex-dispute": "Dispute",
  "p-trade-ordersIndex-coin": "Coin",
  "p-trade-ordersIndex-order-type": "Order type",
  "p-trade-ordersIndex-dates": "Dates",
  "p-trade-ordersIndex-reset-filters": "reset filters",
  "p-trade-ordersIndex-loading-error":
    "Something went wrong during order loading :(",
  "p-trade-ordersIndex-orders": "orders",
  "p-trade-ordersIndex-id-type": "ID / Type",
  "p-trade-ordersIndex-operation-info": "Operation Info",
  "p-trade-ordersIndex-status-date": "Status / Date",
  "p-trade-ordersIndex-id": "ID",
  "p-trade-ordersIndex-sell": "SELL",
  "p-trade-ordersIndex-buy": "BUY",
  "p-trade-ordersIndex-go-to-details": "go to details",
  "p-trade-ordersIndex-summary": "Summary",
  "p-trade-ordersIndex-fee": "Fee",
  "p-trade-ordersIndex-selling": "Selling",
  "p-trade-ordersIndex-buying": "Buying",
  "p-trade-ordersIndex-for": "For",
  "p-trade-ordersIndex-price": "Price",
  "p-trade-ordersIndex-offer-creator": "Offer creator",
  "p-trade-ordersIndex-order-created": "Order created",
  "p-trade-ordersIndex-status": "Status",
  "p-trade-ordersIndex-waiting-for-buyer": "Waiting for buyer's payment",
  "p-trade-ordersIndex-buyer-sent-payment": "Buyer has sent the payment",
  "p-trade-ordersIndex-order-completed": "Order completed",
  "p-trade-ordersIndex-order-cancelled": "Order cancelled",
  "p-trade-ordersIndex-last7days": "Last 7 days",
  "p-trade-ordersIndex-lastMonth": "Last month",
  "p-trade-ordersIndex-last3month": "Last 3 months",
  "p-trade-ordersIndex-dispute-caps": "DISPUTE",
};
