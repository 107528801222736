export default {
  "t-email-logoAltText": "SolidSwap Logo",
  "t-email-greeting": "Hallo,",
  "t-email-failedLoginAttemptMessage":
    "Es gab einen fehlgeschlagenen Anmeldeversuch auf Ihrem SolidSwap-Konto! Ihr Konto wurde für 1 Stunde gesperrt.",
  "t-email-lastFailedLoginAttemptMessage":
    "Der letzte fehlgeschlagene Anmeldeversuch war von diesem Gerät und Standort:",
  "t-email-serverLabel": "Server:",
  "t-email-ipAddressLabel": "IP-Adresse:",
  "t-email-countryDistrictLabel": "Land/Region:",
  "t-email-timestampLabel": "Zeitstempel:",
  "t-email-compromisedAccountMessage":
    "Wenn Sie glauben, dass Ihr Konto kompromittiert wurde, kontaktieren Sie uns bitte sofort per E-Mail",
  "t-email-registeredEmailMessage":
    "unter Verwendung Ihrer registrierten E-Mail-Adresse oder klicken Sie auf die Schaltfläche unten, um Abhebungen zu sperren.",
  "t-email-blockWithdrawalsButtonText": "Abhebungen sperren",
  "t-email-blockWithdrawalsButtonLabel": "Abhebungen sperren",
  "t-email-thankYouMessage": "Danke,",
  "t-email-teamMessage": "Das SolidSwap-Team",
  "t-email-loginFromNewDeviceMessage":
    "Sie haben sich von einem neuen Gerät oder Standort in Ihr SolidSwap-Konto eingeloggt:",
  "t-email-newExchangeRequestSubject": "SolidSwap: Neue Umtauschanfrage",
  "t-email-fromCurrencyLabel": "Von Währung:",
  "t-email-fromAmountLabel": "Betrag:",
  "t-email-toCurrencyLabel": "In Währung:",
  "t-email-toAmountLabel": "Betrag:",
  "t-email-statusLabel": "Status:",
  "t-email-dateTimeLabel": "Datum/Zeit:",
  "t-email-requestIdLabel": "Anfrage-ID:",
  "t-email-goToRequestPageButtonLabel": "Zur Umtauschanfrage-Seite",
  "t-email-noReplyMessage": "Bitte antworten Sie nicht auf diese E-Mail.",
  "t-email-supportRequestNotification":
    "SolidSwap Neue Supportanfrage-Benachrichtigung",
  "t-email-userNameLabel": "Benutzername:",
  "t-email-userEmailLabel": "Benutzer-E-Mail:",
  "t-email-helpMessageLabel": "Hilfenachricht:",
  "t-email-checkSupportChatsMessage":
    "Bitte überprüfen Sie den Support-Chat-Tab:",
  "t-email-orderCreationNotification": "SolidSwap Auftragsbenachrichtigung",
  "t-email-currencyLabel": "Währung:",
  "t-email-quantityLabel": "Menge:",
  "t-email-goToOrderPageButtonLabel": "Zur Auftragsseite",
  "t-email-passwordResetRequestMessage":
    "Sie haben ein Zurücksetzen des Passworts angefordert!",
  "t-email-passwordResetInstructionMessage":
    "Bitte setzen Sie Ihr SolidSwap-Kontopasswort zurück, indem Sie auf die Schaltfläche unten klicken:",
  "t-email-resetPasswordButtonLabel": "Passwort zurücksetzen",
  "t-email-linkValidityMessage": "Der Link ist 15 Minuten gültig.",
  "t-email-errorNotificationMessage":
    "Wenn Sie glauben, dass diese E-Mail fälschlicherweise gesendet wurde, benachrichtigen Sie bitte sofort das SolidSwap-Registrierungsteam",
  "t-email-alternateLinkInstruction":
    "Wenn der obige Aktivierungslink nicht funktioniert, kopieren Sie bitte den untenstehenden Link und fügen Sie ihn in die Adresszeile Ihres Webbrowsers ein und drücken Sie Enter:",
  "t-email-confirmAccountMessage":
    "Bitte bestätigen Sie Ihr SolidSwap-Konto, indem Sie auf die Schaltfläche unten klicken:",
  "t-email-confirmAccountButtonLabel": "Konto bestätigen",
  "t-email-memberNameMessage": "Ihr SolidSwap-Mitgliedsname lautet:",
  "t-email-accessSiteMessage":
    "Sie können auf die Website zugreifen, indem Sie auf",
  "t-email-activate2FAInstructions":
    "Um mit dem Handel zu beginnen oder Ihre erste Einzahlung zu tätigen, müssen Sie die Zwei-Faktor-Authentifizierung aktivieren. Sie können dies in den Einstellungen Ihres Profils tun oder klicken Sie",
  "t-email-hereLabel": "hier",
  "t-email-beginnersGuideMessage":
    "Wir empfehlen Ihnen, unseren Anfängerleitfaden zu lesen",
  "t-email-communityWelcomeMessage":
    "Wir freuen uns darauf, Sie in der SolidSwap-Community zu sehen.",
  "t-email-exchangeStatusChangeNotification":
    "SolidSwap Umtauschanfrage: Statusänderungsbenachrichtigung",
  "t-email-withdrawalNotification": "SolidSwap Abhebungsbenachrichtigung",
  "t-email-withdrawToLabel": "Abheben an:",
  "t-email-ipLabel": "IP:",
  "t-email-locationLabel": "Standort:",
  "t-email-unauthorizedActivityMessage":
    "Wenn Sie diese Aktivität nicht erkennen, klicken Sie bitte auf die Schaltfläche unten, um die Transaktion zu stornieren und Abhebungen auf Ihrem Konto zu sperren. Der Link ist nur 10 Minuten gültig.",
  "t-email-cancelTransactionButtonLabel": "Transaktion stornieren",
};
