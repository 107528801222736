export default {
  "p-confirm-email-title": "邮箱确认",
  "p-confirm-email-wait-message": "请稍候，我们正在确认您的邮箱地址...",
  "p-confirm-email-success-message": "您的邮箱地址已成功确认！",
  "p-confirm-email-success-elmessage":
    "您的邮箱地址已成功确认！您将在 30 秒内重定向到登录页面。",
  "p-confirm-email-info-elmessage": "现在您可以登录您的账户了：{email}",
  "p-confirm-email-error-message": "出了点问题，请稍后再试。",
  "p-confirm-email-proceed-button": "前往登录",
  "p-confirm-email-welcome-title": "欢迎来到交易新纪元！",
  "p-confirm-email-welcome-message":
    "安全连接到数字资产世界。在我们值得信赖的 P2P 平台上，自信且便利地开始交易。您的无缝加密交易之门正等待着您。",
};
