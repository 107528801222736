export default {
  "c-exchange-reqItem-cancelling-request":
    "Annulation de votre demande d'échange",
  "c-exchange-reqItem-cancel-request-confirm":
    "Êtes-vous sûr de vouloir annuler la demande ?",
  "c-exchange-reqItem-confirming-documents": "Confirmation de vos documents",
  "c-exchange-reqItem-upload-documents-instructions":
    "Assurez-vous que les images de votre document sont de haute résolution et que tous les chiffres sont clairement visibles",
  "c-exchange-reqItem-confirm-documents-confirmation":
    "Voulez-vous procéder à la confirmation des documents ?",
  "c-exchange-reqItem-select-payment-option":
    "Sélectionnez une option de paiement",
  "c-exchange-reqItem-proceed-with-payment-option":
    "Voulez-vous continuer avec l'option de paiement sélectionnée ?",
  "c-exchange-reqItem-confirming-payment": "Confirmation du paiement",
  "c-exchange-reqItem-confirm-payment":
    "Voulez-vous confirmer votre paiement ?",
  "c-exchange-reqItem-completing-request": "Finalisation de la demande",
  "c-exchange-reqItem-provide-txid":
    "Pour compléter la demande, vous devez fournir l'identifiant de transaction de votre transfert",
  "c-exchange-reqItem-enter-txid-error": "Veuillez entrer txId ici",
  "c-exchange-reqItem-enter-txid-placeholder": "Entrez txId ici",
  "c-exchange-reqItem-verifying-card":
    "Vérification de la carte de l'utilisateur",
  "c-exchange-reqItem-moderator-verifying": "Le modérateur vérifie les données",
  "c-exchange-reqItem-waiting-alm": "En attente des résultats ALM",
  "c-exchange-reqItem-preparing-crypto":
    "Préparation de l'adresse de la plateforme",
  "c-exchange-reqItem-preparing-fiat": "Préparation des coordonnées bancaires",
  "c-exchange-reqItem-sending-crypto": "Envoi de crypto",
  "c-exchange-reqItem-sending-fiat": "Envoi de fiat",
  "c-exchange-reqItem-waiting-payment":
    "En attente du paiement de l'utilisateur",
  "c-exchange-reqItem-completed": "Complété",
  "c-exchange-reqItem-failed": "Échoué",
  "c-exchange-reqItem-cancelled": "Annulé",
  "c-exchange-reqItem-cancel-exchange-request": "Annuler la demande d'échange",
  "c-exchange-reqItem-confirm-documents": "Confirmer les documents",
  "c-exchange-reqItem-documents-confirmed": "Documents confirmés",
  "c-exchange-reqItem-payment-sent": "Paiement envoyé",
  "c-exchange-reqItem-confirm-user-payment":
    "Confirmer le paiement de l'utilisateur",
  "c-exchange-reqItem-complete-request": "Compléter la demande",
  "c-exchange-reqItem-id": "ID",
  "c-exchange-reqItem-exchanging": "Échange",
  "c-exchange-reqItem-crypto-address": "Adresse crypto de l'utilisateur",
  "c-exchange-reqItem-fiat-card-number":
    "Numéro de carte fiat de l'utilisateur",
  "c-exchange-reqItem-request-failed": "Demande échouée",
  "c-exchange-reqItem-alm-link": "Lien vers les résultats ALM",
  "c-exchange-reqItem-check-here": "vérifiez ici",
  "c-exchange-reqItem-request-cancelled": "Demande annulée",
  "c-exchange-reqItem-request-fulfilled": "Votre demande a été accomplie !",
  "c-exchange-reqItem-thank-you":
    "Merci de nous avoir choisis comme service d'échange préféré. Nous apprécions votre confiance en nous et nous réjouissons de vous servir à nouveau !",
  "c-exchange-reqItem-address-preparation":
    "Voici l'adresse pour votre transfert.",
  "c-exchange-reqItem-security-check":
    "Veuillez attendre que toutes les préparations et vérifications de sécurité soient terminées.",
  "c-exchange-reqItem-waiting-for-transaction":
    "La plateforme attend votre transaction.",
  "c-exchange-reqItem-transfer-here": "Transférez les fonds ici",
  "c-exchange-reqItem-wallet": "portefeuille",
  "c-exchange-reqItem-payment-option": "Option de paiement",
  "c-exchange-reqItem-card-number": "Numéro de carte",
  "c-exchange-reqItem-description": "Description",
  "c-exchange-reqItem-balance": "solde",
  "c-exchange-reqItem-payment-instructions":
    "Après avoir effectué le paiement, veuillez partager une image de la facture (ou une capture d'écran) de votre paiement dans le chat",
  "c-exchange-reqItem-verification-required": "Vérification d'identité requise",
  "c-exchange-reqItem-upload-photo-instructions":
    "Pour poursuivre votre demande d'échange, nous avons besoin d'une photo de vous tenant la carte de crédit que vous comptez utiliser. Veuillez vous assurer que :",
  "c-exchange-reqItem-photo-instruction-cardholder":
    "Le nom du titulaire de la carte et tous les chiffres du numéro de carte sont visibles.",
  "c-exchange-reqItem-photo-instruction-face":
    "Votre visage est clairement visible sur la photo.",
  "c-exchange-reqItem-click-confirm-documents":
    "Une fois la photo téléchargée dans le chat, cliquez sur le bouton 'Confirmer les documents' ci-dessus et attendez que notre modérateur vérifie vos informations.",
  "c-exchange-reqItem-verification-in-progress": "Vérification en cours",
  "c-exchange-reqItem-moderator-review-in-progress":
    "Merci d'avoir téléchargé votre photo. Veuillez patienter pendant que notre modérateur examine et vérifie vos informations. Ce processus peut prendre quelques minutes. Nous vous remercions pour votre patience.",
  "c-exchange-reqItem-select-payment-option-description":
    "Choisissez une option de paiement dans la liste ci-dessous. L'option sélectionnée sera affichée au client comme méthode de paiement pour transférer des fonds.",
  "c-exchange-reqItem-proceed-with-selected-option":
    "Procéder avec l'option sélectionnée",
  "c-exchange-reqItem-alm-check":
    "Nous effectuons une vérification ALM sur votre portefeuille",
  "c-exchange-reqItem-preparing-address":
    "SolidSwap prépare une adresse pour votre transfert de crypto, veuillez patienter.",
  "c-exchange-reqItem-transfer-funds":
    "Transférez les fonds sur le portefeuille fourni ci-dessous et cliquez sur le bouton « Le paiement a été envoyé »",
  "c-exchange-reqItem-confirming-payment-text":
    "Veuillez patienter pendant que SolidSwap confirme votre paiement.",
  "c-exchange-reqItem-transfer-confirmed":
    "Votre transfert est confirmé, veuillez attendre que SolidSwap envoie vos fonds à",
  "c-exchange-reqItem-identity-verification": "Vérification d'identité requise",
  "c-exchange-reqItem-upload-photo":
    "Téléchargez une photo de l'avant de la carte avec la transaction en arrière-plan. Vous pouvez masquer les données de la carte à l'exception des chiffres du numéro de carte.",
  "c-exchange-reqItem-virtual-card-intro": "Si vous avez une carte virtuelle :",
  "c-exchange-reqItem-first-way": "Première méthode :",
  "c-exchange-reqItem-first-way-desc":
    "Téléchargez une capture d'écran de l'écran avec deux fenêtres ouvertes en même temps : la première est la page de la transaction avec votre numéro de commande, la seconde est la fenêtre avec les détails de la carte sur la page de votre banque.",
  "c-exchange-reqItem-second-way": "Deuxième méthode :",
  "c-exchange-reqItem-second-way-desc":
    "Téléchargez une photo ou une capture d'écran de votre carte virtuelle dans l'application mobile ou sur le site Web de votre banque avec votre commande actuelle en arrière-plan. Vous pouvez masquer les données de la carte, à l'exception des chiffres du numéro de carte.",
  "c-exchange-reqItem-waiting-moderator":
    "Veuillez attendre que le modérateur vérifie les documents téléchargés",
  "c-exchange-reqItem-transfer-to-card":
    "Transférez les fonds sur la carte fournie ci-dessous. Une fois le paiement effectué, partagez une image de la facture (ou une capture d'écran) de votre paiement dans le chat et cliquez sur le bouton « Le paiement a été envoyé »",
  "c-exchange-reqItem-crypto-transfer-confirmed":
    "Votre transfert est confirmé, veuillez attendre que SolidSwap envoie vos fonds",
  "c-exchange-reqItem-faq-promotion": "FAQ pour la promotion",
  "c-exchange-reqItem-solid-swap-top-service":
    "Lorsque vous choisissez Solid Swap pour échanger vos actifs numériques et fiduciaires, vous bénéficiez non seulement d'un service de premier ordre, mais aussi de la chance d'ajouter",
  "c-exchange-reqItem-add-to-account-monthly": "à votre compte chaque mois.",
  "c-exchange-reqItem-how-can-you-do-it": "Comment pouvez-vous le faire ?",
  "c-exchange-reqItem-register-and-exchange":
    "Inscrivez-vous et effectuez un échange sur SolidSwap, chaque échange compte comme une participation pour gagner.",
  "c-exchange-reqItem-leave-review-on":
    "Laissez votre avis honnête sur l'une des ressources suivantes",
  "c-exchange-reqItem-send-email-screenshot":
    "Envoyez votre e-mail et une capture d'écran de votre avis à",
  "c-exchange-reqItem-manager-telegram": "notre gestionnaire sur Telegram",
  "c-exchange-reqItem-or-email-to": "ou envoyez un e-mail à",
  "c-exchange-reqItem-enter-giveaway":
    "pour participer au tirage au sort de 300 USDT.",
  "c-exchange-reqItem-promotion-period":
    "La promotion se déroule du 1er octobre au 31 décembre 2024.",
  "c-exchange-reqItem-winners-selected-randomly":
    "Les gagnants sont sélectionnés au hasard parmi les participants qui remplissent les conditions chaque mois pendant la période de promotion. Les résultats seront annoncés dans le",
  "c-exchange-reqItem-participate-again":
    "Pour participer de nouveau le mois suivant, vous devez effectuer un autre échange et laisser un avis supplémentaire.",
};
