export default {
  "p-privacy-policy-title": "Datenschutzerklärung | Solid Swap",
  "p-privacy-policy-meta-description":
    "Lesen Sie die Datenschutzerklärung von Solid Swap, um zu verstehen, wie wir Ihre persönlichen Daten verarbeiten und Ihre Privatsphäre schützen",
  "p-privacy-policy-meta-keywords":
    "Datenschutzerklärung, Datenschutz, persönliche Informationen, Solid Swap Datenschutz",
  "p-privacy-policy-header-title": "Datenschutzerklärung",
  "p-privacy-policy-download-link": "als Datei herunterladen",
  "p-privacy-policy-alert":
    "Diese Kundenvereinbarung kann in verschiedenen Sprachen veröffentlicht werden. Bei Abweichungen beachten Sie bitte die englische Version",
  "p-privacy-policy-last-updated": "Zuletzt aktualisiert:",
  "p-privacy-policy-intro-1":
    "Diese Datenschutzerklärung, die von Zeit zu Zeit geändert oder anderweitig geändert werden kann (die „Datenschutzerklärung“), erklärt, wie SOLIDSWAP, Ltd. („SOLIDSWAP“, „wir“, „uns“ oder „unser“) Benutzerinformationen sammelt, verwendet, pflegt und offenlegt, die durch die",
  "p-privacy-policy-site": "solidswap.net",
  "p-privacy-policy-intro-2":
    "Website (die „Site“) und durch die Bereitstellung der Dienste (die „Dienste“) erhalten wurden. SOLIDSWAP, Ltd. ist der Datenverantwortliche Ihrer persönlichen Daten.",
  "p-privacy-policy-commitment":
    "SOLIDSWAP und seine verbundenen Unternehmen sind bestrebt, Ihre Privatsphäre bestmöglich zu schützen. SOLIDSWAP verwendet die über Sie gesammelten Informationen, um seine vertraglichen Verpflichtungen zu erfüllen und den Kundenservice zu verbessern.",
  "p-privacy-policy-read":
    "Bitte lesen Sie dieses Dokument sorgfältig durch, um unsere Haltung und Richtlinien bezüglich Ihrer persönlichen Informationen zu verstehen und wie wir diese verwenden werden.",
  "p-privacy-policy-read-cookie":
    "Diese Datenschutzerklärung sollte in Verbindung mit unserer Cookie-Richtlinie gelesen werden.",
  "p-privacy-policy-data-we-collect": "1. WELCHE DATEN WIR ÜBER SIE SAMMELN",
  "p-privacy-policy-data-we-collect-intro":
    "Wir können die folgenden Daten über Sie sammeln und verarbeiten:",
  "p-privacy-policy-info-provided":
    "Informationen, die Sie uns zur Verfügung stellen",
  "p-privacy-policy-info-provided-description":
    "Sie können uns Informationen über sich selbst zur Verfügung stellen, indem Sie Formulare auf der Website ausfüllen oder uns diese Informationen per Telefon, E-Mail, Videokonferenz oder auf andere Weise zur Verfügung stellen. Diese Informationen umfassen personenbezogene Daten, die bei der Registrierung auf der Website, beim Abonnieren unserer Dienste, beim Eröffnen eines Kontos und bei der Überprüfung der Identität des Kontoinhabers oder der zur Verfügung stehenden Personen angegeben werden, sowie die von ihnen verwendeten Zahlungsmittel, die Suche nach einem Produkt, die Nutzung von Diensten unserer Website, wenn Sie Bestellungen auf unserer Website aufgeben, wenn Sie an Chat-Diskussionen teilnehmen oder andere Kommunikationsfunktionen auf unserer Website nutzen, wenn Sie an Wettbewerben, Werbeaktionen oder Umfragen teilnehmen und wenn Sie uns Probleme bei der Nutzung der Website melden.",
  "p-privacy-policy-example":
    "Zum Beispiel können die von Ihnen bereitgestellten Daten umfassen:",
  "p-privacy-policy-first-last-name": "Vor- und Nachname;",
  "p-privacy-policy-photos-videos":
    "Fotos und Videos, die Sie uns zu Verifizierungszwecken gemäß unserer AML/CTF- und KYC-Richtlinie senden können;",
  "p-privacy-policy-residence-citizenship":
    "Wohnadresse und Staatsangehörigkeit;",
  "p-privacy-policy-sex": "Geschlecht;",
  "p-privacy-policy-id-documents":
    "Reisepass, Führerschein und andere von der Regierung ausgestellte Ausweisdokumente;",
  "p-privacy-policy-tax-residency": "Steuerlicher Wohnsitz;",
  "p-privacy-policy-phone-email": "Telefonnummer und E-Mail-Adresse;",
  "p-privacy-policy-login-nickname":
    "Login und Spitzname für den Zugriff auf die SOLIDSWAP-Plattform;",
  "p-privacy-policy-bank-info":
    "Bankinformationen, einschließlich Konten- und Zahlungsdetails sowie FATCA/CRS-Informationen;",
  "p-privacy-policy-dob": "Geburtsdatum und -ort;",
  "p-privacy-policy-education": "Ausbildung;",
  "p-privacy-policy-work-experience": "Berufserfahrung und Arbeitsplatz;",
  "p-privacy-policy-official-position": "Offizielle Position;",
  "p-privacy-policy-income-sources":
    "Quellen und/oder Beträge von Einkommens-/Kontenaufstockungen;",
  "p-privacy-policy-account-status":
    "Informationen zum Status Ihrer bei Banken und/oder Zahlungssystemen eröffneten Konten;",
  "p-privacy-policy-support-requests":
    "Anfragen und Korrespondenzverlauf mit SOLIDSWAP-Support und anderen Benutzern der SOLIDSWAP-Plattform;",
  "p-privacy-policy-trade-balances": "Handelsbilanzen und Aktivitäten;",
  "p-privacy-policy-location-log":
    "Standortinformationen und Protokolldaten, die bei der Nutzung der SOLIDSWAP-Plattform generiert werden;",
  "p-privacy-policy-additional-info":
    "Weitere persönliche Informationen, die gemäß unserer AML/CTF- und KYC-Richtlinie erforderlich sein können, um regulatorischen Compliance-Verpflichtungen nachzukommen.",
  "p-privacy-policy-no-sensitive-data":
    "SOLIDSWAP sammelt oder verarbeitet keine sensiblen personenbezogenen Daten von Benutzern, wie z. B. Rasse oder ethnische Herkunft, politische Meinungen, religiöse oder philosophische Überzeugungen, Gewerkschaftszugehörigkeit, genetische oder biometrische Daten, Gesundheitsinformationen, Informationen über das Sexualleben oder die sexuelle Orientierung.",
  "p-privacy-policy-info-collected": "Informationen, die wir über Sie sammeln",
  "p-privacy-policy-info-collected-description":
    "Jedes Mal, wenn Sie unsere Website besuchen und unsere Dienste nutzen, können wir die folgenden Informationen über Sie sammeln (einschließlich automatisch):",
  "p-privacy-policy-info-collected-a":
    "(a) technische Informationen, einschließlich der Internetprotokoll (IP)-Adresse, die verwendet wird, um Ihren Computer mit dem Internet zu verbinden, Ihrer persönlichen Kontoanmeldeinformationen, Ihres Browsertyps und Ihrer Browserversion, der Zeitzoneneinstellungen, der Arten und Versionen von Browser-Plugins, des Betriebssystems und der Plattform;",
  "p-privacy-policy-info-collected-b":
    "(b) Daten über Ihren Besuch auf der Website: einschließlich vollständiger Informationen über die einheitliche Ressourcen-Locator (URL), die Navigationsroute auf der Website und wenn Sie zur/zur Website navigieren (einschließlich Datum und Uhrzeit), über die Produkte und Dienstleistungen, die Sie angesehen oder nach denen Sie gesucht haben, Seitenantwortzeiten, Datenladefehler, Aufenthaltsdauer auf bestimmten Seiten, Aktivität auf der Seite (Informationen wie Scrollen, Mausbewegungen, Klicks), Methoden zum Verlassen der Seite und Telefonnummern, die zur Kontaktaufnahme mit dem Kundensupport verwendet werden;",
  "p-privacy-policy-info-collected-c":
    "(c) Geolokalisierungsdaten: Wir können jederzeit, wenn Sie unsere Dienste herunterladen oder nutzen, Echtzeit-Standortinformationen von Ihrem Gerät erfassen; wir können diese Informationen verwenden, um unsere Dienste zu optimieren;",
  "p-privacy-policy-info-collected-d":
    "(d) Finanzinformationen und Informationen über die Nutzung der Dienste und Möglichkeiten, die unsere Website bietet, einschließlich Informationen über laufende Transaktionen und deren Teilnehmer, einschließlich Margin-Transaktionen, Informationen über den Status von Konten und den Geldfluss auf ihnen, aufgelaufene Provisionen, Informationen über Konto-Einzahlungen und Abhebungen von auf unserer Plattform eröffneten Konten.",
  "p-privacy-policy-info-from-others":
    "Informationen, die wir aus anderen Quellen erhalten",
  "p-privacy-policy-info-from-others-description":
    "Wir können Informationen über Sie erhalten, wenn Sie eine unserer anderen Websites oder Dienste nutzen. Wir arbeiten auch eng mit Drittparteien zusammen (einschließlich unserer Geschäftspartner, technischen, Zahlungs- und Support-Subunternehmer, Werbenetzwerke, Analytikanbieter, Due-Diligence-Anbieter, Kreditauskunfteien) und können Informationen über Sie von ihnen erhalten. Solche Informationen können umfassen:",
  "p-privacy-policy-info-from-others-a":
    "(a) Informationen über die Finanzinstitute, die Sie zur Einzahlung und/oder Abhebung von Geldern verwenden;",
  "p-privacy-policy-info-from-others-b":
    "Bestätigung durch Kreditauskunfteien der von Ihnen bereitgestellten Informationen;",
  "p-privacy-policy-store-pending":
    "Ihre persönlichen Daten, wie E-Mail-Adresse und Login, die Sie uns bei der Registrierung auf der SOLIDSWAP-Plattform zur Verfügung stellen, werden von uns gespeichert, unabhängig davon, ob Sie den Registrierungsprozess abschließen oder nicht. Das Gleiche gilt für Ihre persönlichen Daten während des ausstehenden Verifizierungsprozesses gemäß der AML/CTF- und KYC-Richtlinie.",
  "p-privacy-policy-cookies": "2. VERWENDUNG VON COOKIES",
  "p-privacy-policy-cookies-description":
    "Bitte lesen Sie unsere Cookie-Richtlinie für Informationen zur Verwendung von Cookies.",
  "p-privacy-policy-use-of-data":
    "3. WOFÜR VERWENDEN WIR IHRE PERSÖNLICHEN DATEN?",
  "p-privacy-policy-use-of-data-intro":
    "Wir werden Ihre persönlichen Daten für folgende Zwecke verwenden:",
  "p-privacy-policy-providing-services": "Erbringung unserer Dienstleistungen",
  "p-privacy-policy-fulfill-obligations":
    "um Verpflichtungen aus Verträgen mit Ihnen zu erfüllen sowie Ihnen die angeforderten Informationen, Produkte und Dienstleistungen zur Verfügung zu stellen;",
  "p-privacy-policy-security":
    "um die Sicherheit unserer Website, unserer Dienste und Ihres Kontos zu gewährleisten;",
  "p-privacy-policy-admin-site":
    "um unsere Website zu verwalten und interne Abläufe durchzuführen, einschließlich Fehlerbehebung, Datenanalyse, Tests, Sammlung statistischer Daten und Durchführung von Umfragen;",
  "p-privacy-policy-interactive-features":
    "um Ihnen den Zugang zu interaktiven Funktionen der Website zu ermöglichen, wenn Sie diese nutzen möchten;",
  "p-privacy-policy-support":
    "um Unterstützung zu leisten, auf Ihre Beschwerden und Fragen im Zusammenhang mit der Nutzung unserer Dienste und der Möglichkeiten unserer Website zu antworten und diese zu lösen.",
  "p-privacy-policy-informing":
    "Information über unsere Produkte und Dienstleistungen",
  "p-privacy-policy-inform-products":
    "um Sie über unsere anderen bestehenden und/oder neuen Produkte und Dienstleistungen zu informieren, die denen ähneln, die Sie bereits gekauft oder nach denen Sie Informationen gesucht haben;",
  "p-privacy-policy-inform-changes":
    "um Sie über Änderungen unserer Produkte und Dienstleistungen zu informieren.",
  "p-privacy-policy-improve":
    "Hilfe bei der Verbesserung unserer Produkte und Dienstleistungen",
  "p-privacy-policy-improve-site":
    "um unsere Website zu verbessern und sicherzustellen, dass unser Inhalt auf Ihrem Computersystem so benutzerfreundlich wie möglich ist;",
  "p-privacy-policy-evaluate-ads":
    "um die Wirksamkeit von Werbematerialien, die wir Ihnen und anderen Benutzern zur Verfügung stellen, zu bewerten und zu verstehen sowie Ihnen die erforderlichen Werbeinformationen bereitzustellen;",
  "p-privacy-policy-effective-content":
    "um sicherzustellen, dass der Inhalt unserer Website Ihnen auf die effektivste Weise präsentiert wird und von Ihrem Computer aus verwendet werden kann.",
  "p-privacy-policy-protect-interests": "Schutz der Unternehmensinteressen",
  "p-privacy-policy-risk-management": "für das Risikomanagement;",
  "p-privacy-policy-it-security":
    "um unsere IT-Systeme zu schützen und zu kontrollieren, um Kriminalität zu verhindern;",
  "p-privacy-policy-evaluate-services":
    "um die Nutzung unserer Produkte und Dienstleistungen zu bewerten und deren Weiterentwicklung zu fördern;",
  "p-privacy-policy-internal-business":
    "für interne Geschäfts- und Forschungszwecke, wie z.B. die Kommunikation mit Ihnen über unsere Produkte und Dienstleistungen;",
  "p-privacy-policy-analyze-data":
    "um Daten anonym mithilfe von Web-Beacons und Pixel-Tags zu analysieren, um sicherzustellen, dass unsere Produkte und Dienstleistungen für den Markt geeignet sind, in dem wir tätig sind.",
  "p-privacy-policy-legal-procedures": "Erbringung rechtlicher Verfahren",
  "p-privacy-policy-law-enforcement":
    "um auf Anfragen von Strafverfolgungsbehörden zu reagieren;",
  "p-privacy-policy-legal-claims":
    "um rechtliche Ansprüche zu initiieren und/oder darauf zu reagieren;",
  "p-privacy-policy-disputes":
    "um Streitigkeiten mit Ihnen zu untersuchen und/oder zu lösen.",
  "p-privacy-policy-legal-compliance": "Einhaltung gesetzlicher Vorschriften",
  "p-privacy-policy-verify-users":
    "um die Identität von Benutzern zu überprüfen und zu authentifizieren, die Konten eröffnen und verwalten, um Betrug und verwandte illegale Aktivitäten zu verhindern;",
  "p-privacy-policy-aml-compliance":
    "um den gesetzlichen Anforderungen unserer AML/CTF- und KYC-Richtlinie sowie anderen einschlägigen Gesetzen und Vorschriften zu entsprechen;",
  "p-privacy-policy-notify-changes":
    "um unsere Benutzer über Änderungen der Richtlinien und Nutzungsbedingungen der SOLIDSWAP-Plattform zu informieren.",
  "p-privacy-policy-marketing-third-parties": "Marketing und Drittparteien",
  "p-privacy-policy-new-products":
    "um Ihnen Informationen über neue Produkte oder Dienstleistungen von SOLIDSWAP oder unseren Drittanbietern bereitzustellen, von denen wir glauben, dass sie für Sie von Interesse sein könnten.",
  "p-privacy-policy-disclosure": "4. OFFENLEGUNG IHRER PERSÖNLICHEN DATEN",
  "p-privacy-policy-disclosure-intro":
    "Wir können Ihre persönlichen Informationen innerhalb der SOLIDSWAP-Gruppe weitergeben, zu der unsere Tochtergesellschaften und verbundenen Unternehmen, unsere letztendlichen Muttergesellschaften und deren Tochtergesellschaften gehören.",
  "p-privacy-policy-disclosure-third-parties-intro":
    "Wir können Ihre persönlichen Daten an Drittparteien weitergeben, einschließlich:",
  "p-privacy-policy-disclosure-third-parties-a":
    "(i) Geschäftspartner, Lieferanten, Subunternehmer, Dienstleister, die angemessene rechtliche und finanzielle Prüfungen durchführen, um die vertraglichen Verpflichtungen zu erfüllen, die wir mit ihnen oder mit Ihnen eingehen;",
  "p-privacy-policy-disclosure-third-parties-b":
    "(ii) Marketing-Netzwerke, die bestimmte Daten benötigen, um relevante Werbung auszuwählen und zu fördern.",
  "p-privacy-policy-disclosure-no-sensitive":
    "Wir geben keine sensiblen Informationen über unsere Kunden an Marketingunternehmen weiter, aber wir können ihnen aggregierte Informationen über unsere Benutzer zur Verfügung stellen (zum Beispiel können wir ihnen mitteilen, dass 500 Frauen unter 30 Jahren an einem bestimmten Tag auf ihre Anzeige geklickt haben).",
  "p-privacy-policy-disclosure-aggregate":
    "Wir können solche aggregierten Informationen auch verwenden, um Marketingunternehmen dabei zu helfen, die Zielgruppe zu erreichen, die sie ansprechen möchten (zum Beispiel Frauen, die in einer bestimmten Region leben). Wir können die von Ihnen gesammelten persönlichen Daten verwenden, um uns in die Lage zu versetzen, die Wünsche unserer Werbekunden zu erfüllen, indem wir deren Anzeigen bestimmten Zielgruppen anzeigen, Analyse- und Suchanbieter, die uns bei der Verbesserung und Optimierung der Leistung unserer Website helfen.",
  "p-privacy-policy-disclosure-other-cases-intro":
    "Wir können Ihre persönlichen Daten auch in den folgenden Fällen an Drittparteien weitergeben:",
  "p-privacy-policy-disclosure-other-cases-a":
    "(a) wenn wir ein Geschäft oder Vermögenswerte verkaufen oder kaufen, können die persönlichen Informationen der Benutzer einem potenziellen Käufer oder Verkäufer offengelegt werden;",
  "p-privacy-policy-disclosure-other-cases-b":
    "(b) wenn SOLIDSWAP oder alle seine Vermögenswerte von einer Drittpartei gekauft werden, werden die persönlichen Daten der Benutzer als eines der Vermögenswerte übertragen;",
  "p-privacy-policy-disclosure-other-cases-c":
    "(c) wenn wir gesetzlich verpflichtet sind, Benutzer persönliche Daten offen zu legen oder zu übertragen, um geltenden Gesetzen oder relevanten Gerichtsbeschlüssen, Anfragen von Gerichts- und Strafverfolgungsbehörden nachzukommen oder um Benutzer zur Einhaltung der mit uns geschlossenen Vereinbarungen zu zwingen;",
  "p-privacy-policy-disclosure-other-cases-d":
    "(d) im Falle, dass es notwendig ist, unsere Rechte und unser Eigentum zu schützen oder die Sicherheit von SOLIDSWAP, unserer Benutzer oder anderer Parteien zu gewährleisten (dies schließt die Weitergabe von Informationen an relevante Organisationen ein, um Betrug zu verhindern und die Wahrscheinlichkeit eines Zahlungsausfalls zu verringern).",
  "p-privacy-policy-security-storage":
    "5. SICHERHEIT UND SPEICHERUNG PERSONENBEZOGENER DATEN",
  "p-privacy-policy-security-measures":
    "Wir verwenden Sicherheitsmaßnahmen, um die Vertraulichkeit Ihrer persönlichen Daten zu gewährleisten und sie vor Verlust, Missbrauch, Veränderung oder Zerstörung zu schützen. Nur autorisiertes SOLIDSWAP-Personal hat Zugriff auf Ihre persönlichen Informationen, und diese Mitarbeiter sind verpflichtet, diese Informationen vertraulich zu behandeln. Aktuelle Sicherheitsmaßnahmen werden von Zeit zu Zeit in Übereinstimmung mit neuen gesetzlichen Bestimmungen und technischen Innovationen überprüft.",
  "p-privacy-policy-storage":
    "Die Informationen, die wir von Ihnen sammeln, können außerhalb des Europäischen Wirtschaftsraums („EWR“) übertragen und gespeichert werden, um Ihnen unsere Dienstleistungen zur Verfügung zu stellen. Informationen über Sie können von unseren Mitarbeitern oder Mitarbeitern von Dienstleistern außerhalb des EWR verarbeitet werden. Wenn Ihre persönlichen Daten von solchen Drittparteien außerhalb des EWR gespeichert oder anderweitig verarbeitet werden, werden wir sicherstellen, dass wir diese Drittparteien über unsere Verpflichtungen gemäß dieser Datenschutzerklärung informieren und eine Beziehung mit ihnen eingehen, die sie zu Verpflichtungen in Bezug auf Ihre persönlichen Daten verpflichtet, vorausgesetzt, dass sie das in dieser Datenschutzerklärung angegebene angemessene Sicherheitsniveau haben. Solche Mitarbeiter können unter anderem Ihre Anfragen bearbeiten, Zahlungsinformationen verarbeiten und technischen Support leisten. Indem Sie uns Ihre persönlichen Daten zur Verfügung stellen, stimmen Sie der Übertragung, Speicherung und Verarbeitung Ihrer persönlichen Daten zu. Im Gegenzug werden wir alle notwendigen Maßnahmen ergreifen, um sicherzustellen, dass Ihre persönlichen Daten in Übereinstimmung mit internationalen Sicherheitsstandards und den Bestimmungen dieser Datenschutzerklärung verarbeitet werden.",
  "p-privacy-policy-transmission-security":
    "Obwohl die Übertragung von Informationen über das Internet nicht vollständig sicher ist, werden wir versuchen, Ihre persönlichen Daten zu schützen. In diesem Zusammenhang können wir jedoch nicht die Sicherheit der Kanäle garantieren, die Sie verwenden, um Ihre persönlichen Informationen an unsere Website zu übermitteln. In diesem Zusammenhang sind Sie allein verantwortlich für mögliche Ausfälle bei der Übermittlung Ihrer persönlichen Daten an die Website. Sobald wir Ihre persönlichen Daten erhalten, werden wir strenge Verfahren und alle notwendigen technischen und Sicherheitsmaßnahmen anwenden, um einen unbefugten Zugriff auf Ihre persönlichen Daten zu verhindern.",
  "p-privacy-policy-grounds":
    "6. GRUNDLAGEN DER VERARBEITUNG PERSONENBEZOGENER DATEN",
  "p-privacy-policy-grounds-intro":
    "Wir sammeln und verarbeiten Ihre persönlichen Daten nur, wenn wir eine rechtliche Grundlage für eine solche Verarbeitung haben. Rechtliche Grundlagen umfassen:",
  "p-privacy-policy-consent": "Ihre Einwilligung;",
  "p-privacy-policy-agreement-terms":
    "die Bedingungen der Vereinbarung zwischen Ihnen und SOLIDSWAP;",
  "p-privacy-policy-legal-obligations":
    "die Einhaltung gesetzlicher Verpflichtungen, bei denen SOLIDSWAP verpflichtet ist, Ihre persönlichen Daten anzufordern/zu erhalten und zu verarbeiten und zu speichern, um geltende Gesetze einzuhalten, wie z.B. Gesetze zur Bekämpfung von Geldwäsche und Terrorismusfinanzierung;",
  "p-privacy-policy-legal-interests":
    "rechtliche Interessen, zum Beispiel, wenn die Verarbeitung notwendig ist, um Sie oder uns vor bestimmten Bedrohungen wie Betrug, Sicherheitsbedrohungen usw. zu schützen;",
  "p-privacy-policy-business-compliance":
    "die Einhaltung geltender Gesetze für die Durchführung unseres Geschäfts (Qualität der erbrachten Dienstleistungen, Identifizierung von Bedürfnissen, Erstellung von Berichten) in Übereinstimmung mit regulatorischen Anforderungen;",
  "p-privacy-policy-corporate-transactions":
    "die Durchführung von Unternehmensgeschäften (Verkauf von Anteilen/Anteilen der Teilnehmer, Fusionen, Übernahmen) usw.",
  "p-privacy-policy-withdraw-consent":
    "In Fällen, in denen die Grundlage für die Verarbeitung personenbezogener Daten Ihre Einwilligung ist, haben Sie das Recht, diese jederzeit zu widerrufen.",
  "p-privacy-policy-rights": "7. IHRE RECHTE",
  "p-privacy-policy-rights-intro":
    "Indem Sie uns kontaktieren, können Sie die folgenden Rechte ausüben:",
  "p-privacy-policy-rights-erase":
    "das Recht, alle oder einen Teil Ihrer persönlichen Daten zu löschen (unter Berücksichtigung jedoch unserer gesetzlichen Anforderungen zur Aufbewahrung Ihrer persönlichen Daten gemäß Artikel 9 dieser Datenschutzerklärung);",
  "p-privacy-policy-rights-correct":
    "das Recht, Ihre persönlichen Daten zu ändern oder zu korrigieren, insbesondere wenn sie falsch eingegeben wurden;",
  "p-privacy-policy-rights-object":
    "das Recht, der Verarbeitung Ihrer persönlichen Daten zu widersprechen oder diese einzuschränken;",
  "p-privacy-policy-rights-access":
    "das Recht auf Zugriff auf Ihre persönlichen Daten und/oder eine Kopie Ihrer persönlichen Daten, die Sie SOLIDSWAP in einem maschinenlesbaren Format zur Verfügung gestellt haben.",
  "p-privacy-policy-rights-marketing":
    "Darüber hinaus haben Sie das Recht, die Verwendung Ihrer Daten für Marketingzwecke zu untersagen. Bevor wir Ihre Daten anfordern, werden wir im Allgemeinen Ihre Einwilligung einholen, um diese an Drittparteien für Marketingzwecke weiterzugeben. Sie können das Recht ausüben, der Verarbeitung Ihrer Daten für Marketingzwecke zu widersprechen, indem Sie das entsprechende Kästchen im Formular ankreuzen. Sie können dieses Recht auch jederzeit ausüben, indem Sie uns kontaktieren.",
  "p-privacy-policy-rights-limitations":
    "Bitte beachten Sie, dass die Ausübung einiger Ihrer Rechte je nach Situation die Möglichkeit einer weiteren Zusammenarbeit mit uns einschränken, erschweren oder vollständig ausschließen kann.",
  "p-privacy-policy-rights-active-links":
    "Unsere Website kann aktive Links zu/von den Websites von Partnern, Werbetreibenden und verbundenen Unternehmen enthalten. Wenn Sie einem dieser aktiven Links folgen, beachten Sie bitte, dass andere Websites ihre eigenen Datenschutzrichtlinien haben und wir nicht für deren Bestimmungen verantwortlich sind. Bitte überprüfen Sie die Datenschutzrichtlinien dieser Websites, bevor Sie Ihre persönlichen Informationen an diese übermitteln.",
  "p-privacy-policy-access": "8. ZUGRIFF AUF PERSONENBEZOGENE DATEN",
  "p-privacy-policy-access-intro":
    "Sie haben das Recht, auf die Informationen zuzugreifen, die wir über Sie speichern. Sie können das Zugriffsrecht gemäß Ihrer schriftlichen Anfrage an die unten angegebene E-Mail-Adresse ausüben, wir werden Ihnen die Informationen zur Verfügung stellen, die wir über Sie speichern. Wenn Ihre Anfrage offensichtlich unbegründet oder übermäßig ist oder Sie zusätzliche Kopien Ihrer ursprünglich angeforderten persönlichen Daten anfordern, können wir Ihnen eine zusätzliche Gebühr berechnen.",
  "p-privacy-policy-retention": "9. AUFBEWAHRUNG PERSONENBEZOGENER DATEN",
  "p-privacy-policy-retention-intro":
    "Die Sicherheit und der Schutz Ihrer persönlichen Daten hat für uns oberste Priorität. Wir verwenden sichere Speicher, um die Sicherheit Ihrer persönlichen Daten zu gewährleisten, die Sie uns in allen Phasen der Interaktion mit unserer Plattform bei der Nutzung unserer Produkte und Dienstleistungen mitteilen. Unser Sicherheitsdienst ergreift alle geeigneten Maßnahmen, um Ihre persönlichen Daten vor Hackerangriffen, Verlust oder Missbrauch zu schützen.",
  "p-privacy-policy-retention-period":
    "Wir werden Ihre persönlichen Daten nur so lange aufbewahren, wie Sie unsere Produkte und Dienstleistungen über Ihr Konto nutzen, vorbehaltlich der in dieser Datenschutzerklärung beschriebenen Zwecke und der gesetzlichen und regulatorischen Verpflichtungen, denen wir nachkommen. In Übereinstimmung mit unseren Aufbewahrungspflichten werden wir Informationen über Ihr Konto und Ihre persönlichen Daten für einen Zeitraum von mindestens fünf (5) Jahren ab dem Datum, an dem Sie es schließen, oder dem Ende Ihrer Kundenbeziehung mit uns aufbewahren, es sei denn, dies ist aus rechtlichen oder regulatorischen Gründen anderweitig erforderlich.",
  "p-privacy-policy-retention-extended":
    "Wir können auf Ihre persönlichen Informationen für einen längeren Zeitraum zugreifen oder sie aufbewahren, wenn diese Gegenstand einer formellen Anfrage oder gesetzlichen Verpflichtung, einer behördlichen Untersuchung oder einer Untersuchung möglicher Verstöße gegen unsere Benutzervereinbarung und deren integrale Bestandteile sind oder anderweitig, um möglichen Schaden aufgrund unserer angemessenen Überlegungen zu verhindern.",
  "p-privacy-policy-unsubscribe":
    "Wenn Sie sich von unserem E-Mail-Newsletter abmelden, werden wir Ihre E-Mail auf einer inaktiven E-Mail-Liste speichern.",
  "p-privacy-policy-changes": "10. ÄNDERUNGEN DER DATENSCHUTZERKLÄRUNG",
  "p-privacy-policy-changes-intro":
    "Alle Änderungen der Datenschutzerklärung werden auf dieser Seite veröffentlicht und gegebenenfalls an Ihre E-Mail-Adresse gesendet. Wir empfehlen Ihnen, diese Seite regelmäßig zu überprüfen, um über Aktualisierungen oder Änderungen der Datenschutzerklärung informiert zu bleiben.",
  "p-privacy-policy-contact": "11. KONTAKTIEREN SIE UNS",
  "p-privacy-policy-contact-description":
    "Wenn Sie Fragen, Beschwerden oder Zweifel bezüglich unserer Datenschutzerklärung, Cookie-Richtlinie oder der Methoden und Zwecke der Verarbeitung Ihrer persönlichen Daten haben, können Sie uns unter folgender Adresse kontaktieren:",
};
