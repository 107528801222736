export default {
  "c-notifications-indexclient-connectError":
    "Ошибка при подключении к уведомлениям",
  "c-notifications-indexclient-hideAllMessages": "Скрыть все сообщения",
  "c-notifications-indexclient-goToSettings":
    "перейти к настройкам уведомлений",
  "c-notifications-indexclient-nothingNew": "ничего нового",
  "c-notifications-indexclient-viewOlder": "Просмотреть старые",
  "c-notifications-indexclient-viewAll": "Просмотреть все",
};
