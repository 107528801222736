export default {
  "c-exchange-listItem-id": "ID",
  "c-exchange-listItem-fiat": "Ֆիատ",
  "c-exchange-listItem-crypto": "Կրիպտո",
  "c-exchange-listItem-go-to-details": "Դիտել մանրամասները",
  "c-exchange-listItem-summary": "Ամփոփում",
  "c-exchange-listItem-exchanging": "Փոխանակում",
  "c-exchange-listItem-for": "ի համար",
  "c-exchange-listItem-order-created": "Պատվերը ստեղծված է",
  "c-exchange-listItem-status": "Կարգավիճակ",
  "c-exchange-listItem-verifying-card": "Ստուգում է օգտատիրոջ քարտը",
  "c-exchange-listItem-moderator-verifying": "Մոդերատորը ստուգում է տվյալները",
  "c-exchange-listItem-waiting-alm": "Սպասում է ALM արդյունքների",
  "c-exchange-listItem-preparing-crypto": "Պլատֆորմի հասցեն պատրաստվում է",
  "c-exchange-listItem-preparing-fiat": "Ֆիատի տվյալների պատրաստում",
  "c-exchange-listItem-sending-crypto": "Կրիպտոյի ուղարկում",
  "c-exchange-listItem-sending-fiat": "Ֆիատի ուղարկում",
  "c-exchange-listItem-confirming-fiat": "Վճարման հաստատում",
  "c-exchange-listItem-waiting-payment": "Սպասում է օգտատիրոջ վճարմանը",
  "c-exchange-listItem-completed": "Ավարտված է",
  "c-exchange-listItem-failed": "Անհաջող է",
  "c-exchange-listItem-cancelled": "Չեղարկված է",
};
