export default {
  "p-blog-codeId-failed-to-save-post": "Failed to save post!",
  "p-blog-codeId-post-saved-successfully": "Post saved successfully!",
  "p-blog-codeId-confirm-delete": "Are you sure you want to delete this post?",
  "p-blog-codeId-warning": "Warning",
  "p-blog-codeId-delete": "Delete",
  "p-blog-codeId-post-deleted-successfully": "Post deleted successfully!",
  "p-blog-codeId-failed-to-delete-post": "Failed to delete post!",
  "p-blog-codeId-post-not-found": "Blog post not found :(",
  "p-blog-codeId-back-to-all-posts": "Back to all posts",
  "p-blog-codeId-edit-post": "Edit post",
  "p-blog-codeId-delete-post": "Delete post",

  "p-blog-index-title": "Blog | Insights, Tips, and Stories from Solid Swap",
  "p-blog-index-meta-description":
    "Explore the latest insights, tips, and stories on our blog. Stay updated with our latest posts covering a wide range of topics including technology, lifestyle, and more",
  "p-blog-index-meta-keywords":
    "blog, insights, tips, stories, technology, lifestyle, latest posts, Solid Swap, solidswap",
  "p-blog-index-failed-to-fetch-posts": "Failed to fetch posts",
  "p-blog-index-solid-swap-blog": "Solid Swap blog",
  "p-blog-index-search-placeholder": "Search blog posts...",
  "p-blog-index-new-post": "New post",
  "p-blog-index-active": "Active",
  "p-blog-index-archive": "Archive",
  "p-blog-index-load-more": "Load more",
  "p-blog-index-no-posts-found": "No posts found",
};
