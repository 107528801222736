export default {
  "c-chat-indexclient-historyLoadError":
    "Erreur lors du chargement de l'historique du chat",
  "c-chat-indexclient-pictureFormatError":
    "L'image doit être au format jpg/png !",
  "c-chat-indexclient-pictureSizeError":
    "La taille de l'image ne doit pas dépasser 10MB !",
  "c-chat-indexclient-uploadError": "Échec du téléchargement de l'image !",
  "c-chat-indexclient-connectionError": "Erreur lors de la connexion au chat",
  "c-chat-indexclient-summaryLoadError":
    "Erreur lors du chargement du résumé du chat",
  "c-chat-indexclient-chatWith": "Chat avec",
  "c-chat-indexclient-counterparty": "contrepartie",
  "c-chat-indexclient-online": "en ligne",
  "c-chat-indexclient-wasOnline": "était en ligne",
  "c-chat-indexclient-andMore": "et {count} autres",
  "c-chat-indexclient-allParticipants": "Tous les participants du chat",
  "c-chat-indexclient-moderator": "modérateur",
  "c-chat-indexclient-welcomeMessage":
    "Bienvenue dans le chat pour la commande #{orderId}",
  "c-chat-indexclient-paymentSent": "L'acheteur a envoyé le paiement",
  "c-chat-indexclient-paymentConfirmed": "Le vendeur a confirmé le paiement",
  "c-chat-indexclient-disputeCreated": "Litige créé",
  "c-chat-indexclient-orderCancelled": "Commande annulée",
  "c-chat-indexclient-moderatorJoined": "Le modérateur a rejoint le chat",
  "c-chat-indexclient-sessionStarted": "Session de support commencée",
  "c-chat-indexclient-sessionClosed": "Session de support terminée",
  "c-chat-indexclient-placeholder": "Veuillez saisir",
};
