export default {
  "p-user-index-welcome": "Welcome to your personal dashboard!",

  "p-user-notifications-settings": "Go to notification's settings",
  "p-user-notifications-all": "All notifications",
  "p-user-notifications-error": "Error while getting notifications",
  "p-user-notifications-none": "No notifications",

  "p-user-settings-head-title":
    "User Settings | Manage Your Solid Swap Account",
  "p-user-settings-head-description":
    "Manage your Solid Swap account settings and preferences.",
  "p-user-settings-title": "User settings",
  "p-user-settings-id": "ID",
  "p-user-settings-username": "Username",
  "p-user-settings-last-login": "Last login",

  "p-user-wallet-head-title": "User Wallet | Manage Your Solid Swap Account",
  "p-user-wallet-title": "Wallet transactions",
  "p-user-wallet-incoming-internal": "Incoming internal",
  "p-user-wallet-outgoing-internal": "Outgoing internal",
  "p-user-wallet-incoming": "Incoming",
  "p-user-wallet-outgoing": "Outgoing",
  "p-user-wallet-address-copied": "Your public address has been copied!",
  "p-user-wallet-using-network":
    "We are using {network} network for {currentTab}",
  "p-user-wallet-balance": "Your balance is:",
  "p-user-wallet-frozen-tooltip":
    "The amount of funds that are currently frozen in the systemm in orders, transfers, etc.",
  "p-user-wallet-frozen": "frozen",
  "p-user-wallet-show-address": "Show your public {currentTab} address",
  "p-user-wallet-copy": "Copy",
  "p-user-wallet-min-deposit-note-1":
    "Note that we have minimum limit for deposit crypto currencies.",
  "p-user-wallet-min-deposit-note-2":
    "The limit is about 50 {symbol} equivalent in crypto.",
  "p-user-wallet-current-rate": "With current exchange rate:",
  "p-user-wallet-usd-symbol": "50 {symbol}",
  "p-user-wallet-tfa-alert":
    "Please note: Withdrawals are not permitted unless Two-Factor Authentication (TFA) is enabled on your account.",
  "p-user-wallet-restriction-alert":
    "Your account is currently restricted from making withdrawals. Please contact support for further assistance",
  "p-user-wallet-withdraw": "Withdraw {currentTab}",
  "p-user-wallet-history-title": "Transactions history:",
  "p-user-wallet-no-transactions": "No transactions found for {currentTab}",

  "p-user-head-title": "User Page | Manage Your Solid Swap Account",
  "p-user-head-description":
    "Access and manage your Solid Swap account settings, wallet, and personal preferences securely",
  "p-user-head-keywords":
    "user settings, manage account, crypto wallet, Solid Swap user",

  "p-user-partner-my-partnership": "My partnership",
  "p-user-partner-program-details": "Program Details",
  "p-user-partner-example-link": "example link:",
  "p-user-partner-token": "Token",
  "p-user-partner-update-date": "Update Date",
  "p-user-partner-type": "Type",
  "p-user-partner-is-active": "Is Active",
  "p-user-partner-value": "Value",
  "p-user-partner-id": "Partner ID",
  "p-user-partner-balances": "Balances",
  "p-user-partner-note":
    "Note: 'Frozen balance' represents the amount of funds held in pending orders, transfers, or other reserved actions.",
  "p-user-partner-currency": "Currency",
  "p-user-partner-balance": "Balance",
  "p-user-partner-frozen-balance": "Frozen Balance",
  "p-user-partner-error": "Something went wrong",
  "p-user-partner-tfa-required": "Two-Factor Authentication Required",
  "p-user-partner-tfa-description":
    "Please note: Access to your partner program is not permitted unless Two-Factor Authentication (TFA) is enabled on your account.",
  "p-user-partner-enable-tfa":
    "Enable your TFA in the security settings to access the partner program.",
  "p-user-partner-go-to-tfa-settings": "Go to 2FA Settings",
  "p-user-partner-inactive-help":
    "If you are unsure why your partnership is not active,",
  "p-user-partner-contact-support": "contact our support",
  "p-user-partner-apply-withdraw": "Apply for Withdraw",
  "p-user-partner-transaction-history": "History of transactions",
  "p-user-partner-no-transactions": "Looks like the transaction list is empty",
  "p-user-partner-withdraw-form": "Withdraw form",
  "p-user-partner-select-currency": "Select currency",
  "p-user-partner-address": "Address",
  "p-user-partner-enter-address": "Enter your crypto address",
  "p-user-partner-amount": "Amount",
  "p-user-partner-enter-amount": "Enter the amount",
  "p-user-partner-2fa-code": "2FA code",
  "p-user-partner-enter-2fa-code": "Enter code from your 2FA app",
  "p-user-partner-submit": "Submit",
  "p-user-partner-bonuses": "Bonuses",
  "p-user-partner-withdrawals": "Withdrawals",
  "p-user-partner-visitors": "Visitors",
  "p-user-partner-enter-wallet-address": "Enter public address of your wallet",
  "p-user-partner-withdraw-success": "Successfully created withdrawal request",
  "p-user-partner-copy-success": "Copied to clipboard",
};
