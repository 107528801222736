export default {
  "s-useNotifications-order": "Заказ №{orderId}",
  "s-useNotifications-system-message": "Системное сообщение",
  "s-useNotifications-new-order-created": "Создан новый заказ!",
  "s-useNotifications-order-status-changed":
    'Статус заказа изменен на "{status}"',
  "s-useNotifications-order-created": "Заказ создан",
  "s-useNotifications-order-deleted": "Заказ удален",
  "s-useNotifications-moderator-joined": "Модератор присоединился",
  "s-useNotifications-attached-file": "[вложенный файл]",
};
