export default {
  "c-Withdrawal-success": "Вывод средств успешно выполнен!",
  "c-Withdrawal-error": "Произошла ошибка при выводе средств, попробуйте позже",
  "c-Withdrawal-enter-valid-amount": "Введите действительную сумму",
  "c-Withdrawal-enter-valid-address": "Введите действительный публичный адрес",
  "c-Withdrawal-enter-tfa-code": "Введите ваш 2FA код из приложения",
  "c-Withdrawal-title": "Вывод",
  "c-Withdrawal-message-1":
    "Пожалуйста, заполните форму ниже, чтобы продолжить вывод средств",
  "c-Withdrawal-message-2":
    "После того, как вы продолжите с выводом средств, у вас будет несколько минут, чтобы отменить вашу транзакцию на странице истории транзакций",
  "c-Withdrawal-no-fee":
    "В данный момент нет комиссии за вывод средств, вы будете оплачивать только сетевую комиссию, которая зависит от нагрузки сети и будет вычтена из суммы вывода.",
  "c-Withdrawal-amount-label": "Сумма вывода",
  "c-Withdrawal-available": "Доступно у вас",
  "c-Withdrawal-withdraw-all": "Вывести все",
  "c-Withdrawal-commission": "Комиссия:",
  "c-Withdrawal-address-label": "Публичный адрес кошелька",
  "c-Withdrawal-security-tip-title": "Совет по безопасности:",
  "c-Withdrawal-security-tip-desc":
    "Всегда дважды проверяйте адрес перед отправкой. Убедитесь, что вы узнаете получателя.",
  "c-Withdrawal-tfa-label": "Код двухфакторной аутентификации",
  "c-Withdrawal-scam-warning-title": "Остерегайтесь мошенников:",
  "c-Withdrawal-scam-warning-desc":
    "Никогда не делитесь своими ключами или 2FA кодами с кем-либо. Официальная поддержка никогда не спросит эту информацию.",
  "c-Withdrawal-cancel": "Отмена",
  "c-Withdrawal-continue": "Продолжить",
};
