export default {
  "c-exchange-listItem-id": "ID",
  "c-exchange-listItem-fiat": "Fiat",
  "c-exchange-listItem-crypto": "Krypto",
  "c-exchange-listItem-go-to-details": "Details anzeigen",
  "c-exchange-listItem-summary": "Zusammenfassung",
  "c-exchange-listItem-exchanging": "Umtauschen",
  "c-exchange-listItem-for": "für",
  "c-exchange-listItem-order-created": "Bestellung erstellt",
  "c-exchange-listItem-status": "Status",
  "c-exchange-listItem-verifying-card": "Überprüfung der Benutzerkarte",
  "c-exchange-listItem-moderator-verifying": "Moderator überprüft Daten",
  "c-exchange-listItem-waiting-alm": "Warten auf ALM-Ergebnisse",
  "c-exchange-listItem-preparing-crypto": "Plattformadresse vorbereiten",
  "c-exchange-listItem-preparing-fiat": "Fiat-Anforderungen vorbereiten",
  "c-exchange-listItem-sending-crypto": "Krypto senden",
  "c-exchange-listItem-sending-fiat": "Fiat senden",
  "c-exchange-listItem-confirming-fiat": "Zahlung bestätigen",
  "c-exchange-listItem-waiting-payment": "Warten auf die Zahlung des Benutzers",
  "c-exchange-listItem-completed": "Abgeschlossen",
  "c-exchange-listItem-failed": "Fehlgeschlagen",
  "c-exchange-listItem-cancelled": "Storniert",
};
