export default {
  "c-orderCreate-closeOrderConfirm": "确定要关闭订单创建吗？",
  "c-orderCreate-close": "关闭",
  "c-orderCreate-closeCancel": "取消",
  "c-orderCreate-sendAmount-required": "请输入金额",
  "c-orderCreate-receiveAmount-required": "请输入金额",
  "c-orderCreate-orderPayment-required": "请选择支付方式",
  "c-orderCreate-fiatLimits": "金额应在 {min} 至 {max} 之间",
  "c-orderCreate-cryptoLimits": "金额应小于 {limit}",
  "c-orderCreate-errorTitle": "错误",
  "c-orderCreate-errorCreatingOrder": "创建订单时出错",
  "c-orderCreate-orderCreated": '订单 ID："{id}" 已创建',
  "c-orderCreate-ratesChangedTitle": "汇率已变更",
  "c-orderCreate-ratesChangedAlert": "创建订单时加密货币的购买汇率已变更。",
  "c-orderCreate-ratesChangedDesc":
    "这可能是由于市场波动或报价创建者更新自定义汇率所致。在继续购买前，请审查新的汇率以确保您同意更新的条款。",
  "c-orderCreate-oldPrice": "旧价格",
  "c-orderCreate-newPrice": "新价格",
  "c-orderCreate-returnToOrder": "返回订单",
  "c-orderCreate-proceedWithNewPrice": "以新价格继续",
  "c-orderCreate-creatingOrderTitle": "为{type} {currency}创建订单",
  "c-orderCreate-buying": "购买",
  "c-orderCreate-selling": "出售",
  "c-orderCreate-paymentTimeLimit": "支付时间限制",
  "c-orderCreate-30minutes": "30分钟",
  "c-orderCreate-avgPaymentTime": "平均支付时间",
  "c-orderCreate-minutes": "分钟",
  "c-orderCreate-available": "可用",
  "c-orderCreate-paymentOptions": "支付选项：",
  "c-orderCreate-traderInfo": "交易员信息",
  "c-orderCreate-noDescriptionSpecified":
    "交易员未指定描述。订单创建后您将收到额外信息。",
  "c-orderCreate-priceLabel": "价格",
  "c-orderCreate-priceChangedTooltip":
    "价格变动是由于官方交易率变动或报价创建者更改其条件",
  "c-orderCreate-priceChanged": "价格已变更！",
  "c-orderCreate-platformFee": "平台费",
  "c-orderCreate-paidBySeller": "由卖家支付",
  "c-orderCreate-iWantTo": "我想要{type}",
  "c-orderCreate-pay": "支付",
  "c-orderCreate-sell": "出售",
  "c-orderCreate-enterAmount": "输入金额",
  "c-orderCreate-limits": "限制",
  "c-orderCreate-fee": "费用",
  "c-orderCreate-myBalance": "我的余额",
  "c-orderCreate-sellAll": "全部出售",
  "c-orderCreate-iWillReceive": "我将收到",
  "c-orderCreate-paymentMethod": "支付方式",
  "c-orderCreate-noPaymentOptions": "此报价不允许您使用的支付选项",
  "c-orderCreate-alsoAvailable": "此报价还可用",
  "c-orderCreate-addPaymentOption": "添加支付选项",
  "c-orderCreate-cancel": "取消",
  "c-orderCreate-buy": "买入",
};
