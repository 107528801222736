export default {
  "p-login-head-title": "Anmeldung | Greifen Sie auf Ihr Solid Swap-Konto zu",
  "p-login-head-description":
    "Melden Sie sich in Ihrem Solid Swap-Konto an, um sicher und effizient mit Kryptowährungen zu handeln",
  "p-login-head-keywords":
    "anmeldung, Solid Swap-konto, zugriff auf kryptowährungshandel",
  "p-login-rules-email": "Bitte geben Sie Ihre E-Mail ein",
  "p-login-rules-password": "Bitte geben Sie Ihr Passwort ein",
  "p-login-error-default":
    "Hoppla, etwas ist bei der Anmeldung schiefgelaufen.",
  "p-login-error-2fa-wrong":
    "Falscher 2FA-Code! Bitte versuchen Sie es erneut.",
  "p-login-error-user-not-found": "Benutzer nicht gefunden",
  "p-login-error-email-verification":
    "Ihre E-Mail muss zuerst verifiziert werden. Bitte überprüfen Sie Ihre E-Mail.",
  "p-login-error-suspended":
    "Ihr Konto wurde gesperrt. Bitte überprüfen Sie Ihre E-Mail für weitere Details.",
  "p-login-error-locked-title": "Vorübergehend gesperrt",
  "p-login-error-locked-message":
    "Sie haben {failedLockCount} oder mehr fehlgeschlagene Anmeldeversuche gemacht. Aus Sicherheitsgründen warten Sie bitte 60 Minuten, bevor Sie es erneut versuchen. Wenn Sie sofortige Hilfe benötigen oder Ihr Passwort vergessen haben, verwenden Sie die Option 'Passwort vergessen', um Ihr Passwort zurückzusetzen.",
  "p-login-error-wrong-password-title": "Falsches Passwort",
  "p-login-error-wrong-password-message":
    "Sie haben {failedAttemptsCount} fehlgeschlagene Anmeldeversuche gemacht. Bitte seien Sie vorsichtig: Nach {failedLockCount} fehlgeschlagenen Versuchen wird Ihr Konto vorübergehend für 60 Minuten gesperrt.",
  "p-login-password-reset-success": "Erfolg!",
  "p-login-password-reset-error":
    "Etwas ist beim Zurücksetzen des Passworts schiefgelaufen, bitte versuchen Sie es später erneut",
  "p-login-title": "Anmelden",
  "p-login-label-email": "E-Mail",
  "p-login-label-password": "Passwort",
  "p-login-email-placeholder": "Geben Sie Ihre E-Mail ein",
  "p-login-password-placeholder": "Geben Sie Ihr Passwort ein",
  "p-login-forgot-password": "Passwort vergessen?",
  "p-login-submit-button": "Anmelden",
  "p-login-2fa-prompt": "Geben Sie den 2FA-Code aus Ihrer App ein:",
  "p-login-2fa-placeholder": "Geben Sie den Code ein...",
  "p-login-2fa-button": "Fortfahren",
  "p-login-password-reset-success-title": "E-Mail gesendet!",
  "p-login-password-reset-success-message":
    "Wir haben einen Link zum Zurücksetzen des Passworts an Ihre E-Mail-Adresse gesendet. Bitte überprüfen Sie Ihr Postfach und folgen Sie den Anweisungen, um Ihr Passwort zurückzusetzen.",
  "p-login-password-reset-alert":
    "Wenn Sie die E-Mail nicht sehen, überprüfen Sie bitte Ihren Spam- oder Junk-Ordner. Wenn Sie sie immer noch nicht erhalten haben, versuchen Sie, Ihre E-Mail-Adresse erneut einzureichen oder wenden Sie sich an unser Support-Team, um Unterstützung zu erhalten.",
  "p-login-back-to-login": "Zurück zur Anmeldung",
  "p-login-forgot-password-title": "Passwort vergessen?",
  "p-login-forgot-password-message":
    "Bitte geben Sie die E-Mail-Adresse ein, die mit Ihrem Konto verknüpft ist, und klicken Sie auf 'Weiter'.",
  "p-login-password-reset-instructions":
    "Wir senden Ihnen eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts. Wenn Sie die E-Mail innerhalb weniger Minuten nicht erhalten, überprüfen Sie bitte Ihren Spam-Ordner oder versuchen Sie es erneut.",
  "p-login-enter-email": "Geben Sie Ihre E-Mail ein",
  "p-login-cancel": "Abbrechen",
  "p-login-proceed": "Fortfahren",
  "p-login-go-to-registration":
    "Haben Sie noch kein Konto? Zur Registrierung gehen",
  "p-login-welcome-title": "Willkommen in einer neuen Ära des Handels!",
  "p-login-welcome-message-1":
    "Verbinden Sie sich sicher mit einer Welt digitaler Vermögenswerte.",
  "p-login-welcome-message-2":
    "Beginnen Sie mit Vertrauen und Bequemlichkeit auf unserer vertrauenswürdigen P2P-Plattform zu handeln.",
  "p-login-welcome-message-3":
    "Ihr Tor zu nahtlosen Krypto-Transaktionen erwartet Sie.",
};
