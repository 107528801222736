export default {
  "c-exchange-steps-verifying-card": "Verificando la tarjeta del usuario",
  "c-exchange-steps-waiting-alm": "Esperando resultados de ALM",
  "c-exchange-steps-preparing-crypto":
    "Preparando la dirección de la plataforma",
  "c-exchange-steps-moderator-verifying":
    "El moderador está verificando los datos",
  "c-exchange-steps-preparing-fiat": "Preparando los requisitos de fiat",
  "c-exchange-steps-waiting-payment": "Esperando el pago del usuario",
  "c-exchange-steps-confirming-payment": "Confirmando el pago",
  "c-exchange-steps-sending-crypto": "Enviando cripto",
  "c-exchange-steps-sending-fiat": "Enviando fiat",
  "c-exchange-steps-request-completed": "Solicitud completada",
};
