export default {
  "p-registration-label-email": "Correo electrónico",
  "p-registration-label-username": "Nombre de usuario",
  "p-registration-label-password": "Contraseña",
  "p-registration-label-repeat-password": "Repetir contraseña",
  "p-registration-head-title": "Registro | Crea tu cuenta de Solid Swap",
  "p-registration-head-description":
    "Regístrate para obtener una cuenta de Solid Swap y comenzar a comerciar criptomonedas de manera segura y eficiente",
  "p-registration-head-keywords":
    "registro, crear cuenta, registro de Solid Swap, comercio de cripto",
  "p-registration-rules-username": "Por favor, ingresa tu nombre de usuario",
  "p-registration-rules-username-length":
    "La longitud del nombre de usuario debe estar entre 5 y 15 caracteres",
  "p-registration-rules-email": "Por favor, ingresa tu correo electrónico",
  "p-registration-rules-password": "Por favor, ingresa tu contraseña",
  "p-registration-rules-password-length":
    "La longitud de la contraseña debe estar entre 6 y 21 caracteres",
  "p-registration-rules-password-confirm": "Por favor, confirma tu contraseña",
  "p-registration-rules-password-match": "Las contraseñas no coinciden",
  "p-registration-rules-terms": "Por favor acepta los términos de uso",
  "p-registration-success-message":
    "Registro exitoso. Por favor revisa tu correo electrónico para un enlace de confirmación.",
  "p-registration-error-default": "Ups, algo salió mal durante el registro.",
  "p-registration-error-data": "Algo está mal con los datos que ingresaste",
  "p-registration-error-account-exists": "Parece que ya tienes una cuenta.",
  "p-registration-login-link": "Por favor, inicia sesión",
  "p-registration-title": "Registro",
  "p-registration-email-placeholder": "Ingresa tu correo electrónico",
  "p-registration-username-placeholder": "Ingresa tu nombre de usuario",
  "p-registration-password-placeholder": "Ingresa tu contraseña",
  "p-registration-password-confirm-placeholder": "Confirma tu contraseña",
  "p-registration-terms-message": "Acepto los",
  "p-registration-terms": "Términos de uso",
  "p-registration-and": "y",
  "p-registration-privacy": "Política de privacidad",
  "p-registration-continue": "Continuar",
  "p-registration-success-title": "¡Exitoso!",
  "p-registration-welcome-title":
    "¡Únete a nuestra vibrante comunidad de entusiastas de las criptomonedas!",
  "p-registration-welcome-message-1":
    "Regístrate ahora para desbloquear el potencial completo del comercio peer-to-peer.",
  "p-registration-welcome-message-2":
    "Experimenta libertad, justicia y el futuro de las finanzas.",
  "p-registration-welcome-message-3":
    "Sumérgete en el diverso mercado de monedas digitales donde la oportunidad y la innovación se encuentran.",
};
