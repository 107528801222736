export default {
  "c-exchange-listItem-id": "ID",
  "c-exchange-listItem-fiat": "法币",
  "c-exchange-listItem-crypto": "加密货币",
  "c-exchange-listItem-go-to-details": "查看详情",
  "c-exchange-listItem-summary": "摘要",
  "c-exchange-listItem-exchanging": "正在交换",
  "c-exchange-listItem-for": "用于",
  "c-exchange-listItem-order-created": "订单已创建",
  "c-exchange-listItem-status": "状态",
  "c-exchange-listItem-verifying-card": "验证用户卡",
  "c-exchange-listItem-moderator-verifying": "管理员正在验证数据",
  "c-exchange-listItem-waiting-alm": "等待ALM结果",
  "c-exchange-listItem-preparing-crypto": "准备平台地址",
  "c-exchange-listItem-preparing-fiat": "准备法币信息",
  "c-exchange-listItem-sending-crypto": "发送加密货币",
  "c-exchange-listItem-sending-fiat": "发送法币",
  "c-exchange-listItem-confirming-fiat": "确认付款",
  "c-exchange-listItem-waiting-payment": "等待用户付款",
  "c-exchange-listItem-completed": "已完成",
  "c-exchange-listItem-failed": "失败",
  "c-exchange-listItem-cancelled": "已取消",
};
