export default {
  "p-trade-profile-title":
    "Zahlungsmethoden | Sichere Krypto-Zahlungsmethoden auf Solid Swap",
  "p-trade-profile-description":
    "Entdecken Sie verschiedene sichere Zahlungsmethoden, die auf Solid Swap für den Kryptowährungshandel verfügbar sind. Wählen Sie die beste Methode für Ihre Bedürfnisse",
  "p-trade-profile-keywords":
    "krypto-zahlungsmethoden, sichere zahlungsmethoden, digitalwährungszahlungen, kryptowährungstransaktionen",
  "p-trade-profile-option-delete-confirm":
    "Diese Aktion wird Ihre Zahlungsmethode löschen. Fortfahren?",
  "p-trade-profile-warning": "Warnung",
  "p-trade-profile-delete": "Löschen",
  "p-trade-profile-cancel": "Abbrechen",
  "p-trade-profile-option-delete-error":
    "Ein Fehler ist beim Löschen der Zahlungsmethode aufgetreten",
  "p-trade-profile-my-payment-options": "Meine Zahlungsmethoden",
  "p-trade-profile-payment-options-info-1":
    "Dies sind Ihre Zahlungsmethoden, die für alle Transaktionen zwischen Benutzern erforderlich sind.",
  "p-trade-profile-payment-options-info-2":
    "Wenn Sie Kryptowährung verkaufen, informieren die Zahlungsmethoden die Gegenpartei, wohin sie Mittel für den Kauf überweisen soll.",
  "p-trade-profile-payment-options-info-3":
    "Wenn Sie Kryptowährung kaufen, werden diese Optionen verwendet, um den Zahlungstyp während der Auftragserstellung auszuwählen, der dem Verkäufer Ihre bevorzugte Zahlungsmethode für den aktuellen Auftrag anzeigt.",
  "p-trade-profile-no-options": "Sie haben noch keine Optionen",
  "p-trade-profile-edit": "Bearbeiten",
  "p-trade-profile-add-new-payment-option": "Neue Zahlungsmethode hinzufügen",
};
