export default {
  "c-home-banners-getToKnowUs": "Faites notre connaissance",
  "c-home-banners-dirtyCryptocurrency":
    "Que faire si vous recevez des cryptomonnaies 'sales'",
  "c-home-banners-beginnersGuide": "Commencez par lire notre guide du débutant",

  "c-home-historywidgets-24hours": "24 heures",
  "c-home-historywidgets-1week": "1 semaine",
  "c-home-historywidgets-1month": "1 mois",
  "c-home-historywidgets-usdRatesHistory": "Historique des taux USD pour",
  "c-home-historywidgets-change": "(changement?)",
};
