export default {
  "c-user-avatarChange-avatar-must-be-jpg":
    "Avatar picture must be JPG format!",
  "c-user-avatarChange-avatar-size-exceed":
    "Avatar picture size can not exceed 2MB!",
  "c-user-avatarChange-avatar-upload-error": "Error uploading image!",
  "c-user-avatarChange-change-avatar": "Change avatar",
  "c-user-avatarChange-avatar-file-restriction":
    "jpg/png files with a size less than 5 MB.",

  "c-user-cookiealert-cookie-preference": "Cookie Preference",
  "c-user-cookiealert-essential-cookies":
    "We use cookies and similar technologies that are necessary to run our Websites (essential cookies).",
  "c-user-cookiealert-additional-cookies":
    "We also use Analytics, Functionality and Targeting cookies to analyse our Websites’ traffic, optimize your experience, personalize content and serve targeted advertisements.",
  "c-user-cookiealert-manage-cookies":
    "You can switch off cookies at any time by visiting the Manage Cookies option at the footer of the page.",
  "c-user-cookiealert-reject-cookies": "Reject Cookies",
  "c-user-cookiealert-accept-continue": "Accept & Continue",

  "c-Enable2FA-enabled": "Two-Factor Authentication (2FA) is enabled!",
  "c-Enable2FA-disable": "Disable 2FA",
  "c-Enable2FA-not-recommended": "(not recommended)",
  "c-Enable2FA-enable": "Enable 2FA",
  "c-Enable2FA-highly-recommended": "(highly recommended)",
  "c-Enable2FA-enable-2fa": "Enable 2FA",
  "c-Enable2FA-scan-image":
    "Scan the image below with your 2FA app to enable two-factor",
  "c-Enable2FA-enter-address": "Or enter this address into your app",
  "c-Enable2FA-enter-code": "Enter 2FA code",
  "c-Enable2FA-cancel": "Cancel",
  "c-Enable2FA-error-getting-image": "Error getting 2FA image!",
  "c-Enable2FA-success-enable": "2FA enabled successfully!",
  "c-Enable2FA-error-enable": "Error enabling 2FA!",
  "c-Enable2FA-disable-prompt":
    "You are about to disable Two-Factor Authentication (2FA) on your account. Please be aware that this will reduce your account's security. Upon disabling 2FA, you will not be able to trade or withdraw funds for 24 hours. If you wish to proceed, please enter your 2FA code below and click on 'Disable 2FA'.",
  "c-Enable2FA-warning": "Warning",
  "c-Enable2FA-input-error": "Enter your 2FA code",
  "c-Enable2FA-success-disable": "2FA cancelled",
  "c-Enable2FA-error-disable": "Error disabling 2FA!",

  "c-UserMenu-orders": "Orders",
  "c-UserMenu-payment-options": "Payment options",
  "c-UserMenu-wallet": "Wallet",
  "c-UserMenu-notifications": "Notifications",
  "c-UserMenu-settings": "Settings",
  "c-UserMenu-partner-dashboard": "Partner",

  "c-NotificationSettings-title": "Notifications settings",
  "c-NotificationSettings-alert":
    "Please note that notification settings are device-specific. You have the capability to configure different settings for each of your devices.",
  "c-NotificationSettings-check-all": "Check all",
  "c-NotificationSettings-website-notifications": "Website Notifications:",
  "c-NotificationSettings-order-notifications": "Order Notifications",
  "c-NotificationSettings-chat-messages": "Chat Messages",
  "c-NotificationSettings-system-notifications": "System Notifications:",
  "c-NotificationSettings-system-alert":
    "System notifications are native alerts specific to your device. These notifications will only function if you have granted permission for your browser to display notifications.",

  "c-PasswordChange-change-password": "Change password",
  "c-PasswordChange-dialog-title": "Change Your Password",
  "c-PasswordChange-description":
    "Please enter your current password and choose a new one. Your new password must meet the following criteria:",
  "c-PasswordChange-current-password": "Current Password",
  "c-PasswordChange-new-password": "New Password",
  "c-PasswordChange-confirm-new-password": "Confirm New Password",
  "c-PasswordChange-min-characters": "Minimum 6 characters",
  "c-PasswordChange-max-characters": "Maximum 20 characters",
  "c-PasswordChange-2fa-code": "2FA code",
  "c-PasswordChange-case-sensitive":
    "Passwords are case sensitive. Choose a strong, unique password to keep your account secure.",
  "c-PasswordChange-cancel": "Cancel",
  "c-PasswordChange-wait-loading": "Please wait loading...",
  "c-PasswordChange-enter-current-password":
    "Please enter your current password",
  "c-PasswordChange-enter-new-password": "Please enter your new password",
  "c-PasswordChange-please-confirm-new-password":
    "Please confirm your new password",
  "c-PasswordChange-passwords-not-match": "The two passwords do not match",
  "c-PasswordChange-enter-2fa-code": "Please enter your 2FA code",
  "c-PasswordChange-password-changed-success": "Password changed successfully!",
  "c-PasswordChange-error-changing-password": "Error changing password!",

  "c-TFAAlert-2fa-disabled-title": "Your two-factor authentication is disabled",
  "c-TFAAlert-attention": "Attention!",
  "c-TFAAlert-message-1":
    "It looks like you haven't enabled Two-Factor Authentication (2FA) yet. For your security, certain actions like creating orders, offers, and withdrawals are restricted until 2FA is activated.",
  "c-TFAAlert-message-2":
    "You can continue using the website, but please consider enabling 2FA to unlock full functionality and enhance your account security.",
  "c-TFAAlert-remind-me-later": "Remind me later",
  "c-TFAAlert-go-to-setup": "Go to 2FA setup",

  "c-partner-bonuses-list": "Bonuses list",
  "c-partner-bonuses-description":
    "Here you can see the list of bonuses you have received from customers who used your referral link.",
  "c-partner-bonus-id": "ID",
  "c-partner-bonus-amount": "Bonus amount",
  "c-partner-bonus-date": "Date",

  "c-partner-withdrawals-list": "Withdrawals list",
};
