export default {
  "c-user-avatarChange-avatar-must-be-jpg":
    "L'image de l'avatar doit être au format JPG !",
  "c-user-avatarChange-avatar-size-exceed":
    "La taille de l'image de l'avatar ne doit pas dépasser 2MB !",
  "c-user-avatarChange-avatar-upload-error":
    "Erreur lors du téléchargement de l'image !",
  "c-user-avatarChange-change-avatar": "Changer d'avatar",
  "c-user-avatarChange-avatar-file-restriction":
    "fichiers jpg/png de moins de 5 MB.",

  "c-user-cookiealert-cookie-preference": "Préférence de cookies",
  "c-user-cookiealert-essential-cookies":
    "Nous utilisons des cookies et des technologies similaires nécessaires pour faire fonctionner nos sites Web (cookies essentiels).",
  "c-user-cookiealert-additional-cookies":
    "Nous utilisons également des cookies d'analyse, de fonctionnalité et de ciblage pour analyser le trafic de nos sites Web, optimiser votre expérience, personnaliser le contenu et diffuser des publicités ciblées.",
  "c-user-cookiealert-manage-cookies":
    "Vous pouvez désactiver les cookies à tout moment en visitant l'option Gérer les cookies en bas de page.",
  "c-user-cookiealert-reject-cookies": "Rejeter les cookies",
  "c-user-cookiealert-accept-continue": "Accepter et continuer",

  "c-Enable2FA-enabled":
    "L'authentification à deux facteurs (2FA) est activée !",
  "c-Enable2FA-disable": "Désactiver 2FA",
  "c-Enable2FA-not-recommended": "(non recommandé)",
  "c-Enable2FA-enable": "Activer 2FA",
  "c-Enable2FA-highly-recommended": "(fortement recommandé)",
  "c-Enable2FA-enable-2fa": "Activer 2FA",
  "c-Enable2FA-scan-image":
    "Scannez l'image ci-dessous avec votre application 2FA pour activer la double authentification",
  "c-Enable2FA-enter-address": "Ou entrez cette adresse dans votre application",
  "c-Enable2FA-enter-code": "Entrez le code 2FA",
  "c-Enable2FA-cancel": "Annuler",
  "c-Enable2FA-error-getting-image":
    "Erreur lors de l'obtention de l'image 2FA !",
  "c-Enable2FA-success-enable": "2FA activée avec succès !",
  "c-Enable2FA-error-enable": "Erreur lors de l'activation de 2FA !",
  "c-Enable2FA-disable-prompt":
    "Vous êtes sur le point de désactiver l'authentification à deux facteurs (2FA) sur votre compte. Veuillez noter que cela réduira la sécurité de votre compte. En désactivant 2FA, vous ne pourrez pas trader ou retirer des fonds pendant 24 heures. Si vous souhaitez continuer, veuillez entrer votre code 2FA ci-dessous et cliquer sur 'Désactiver 2FA'.",
  "c-Enable2FA-warning": "Avertissement",
  "c-Enable2FA-input-error": "Entrez votre code 2FA",
  "c-Enable2FA-success-disable": "2FA désactivée",
  "c-Enable2FA-error-disable": "Erreur lors de la désactivation de 2FA !",

  "c-UserMenu-orders": "Commandes",
  "c-UserMenu-payment-options": "Options de paiement",
  "c-UserMenu-wallet": "Portefeuille",
  "c-UserMenu-notifications": "Notifications",
  "c-UserMenu-settings": "Paramètres",
  "c-UserMenu-partner-dashboard": "Partenaire",

  "c-NotificationSettings-title": "Paramètres de notifications",
  "c-NotificationSettings-alert":
    "Veuillez noter que les paramètres de notification sont spécifiques à l'appareil. Vous avez la possibilité de configurer différents paramètres pour chacun de vos appareils.",
  "c-NotificationSettings-check-all": "Tout cocher",
  "c-NotificationSettings-website-notifications": "Notifications du site Web :",
  "c-NotificationSettings-order-notifications": "Notifications de commande",
  "c-NotificationSettings-chat-messages": "Messages de chat",
  "c-NotificationSettings-system-notifications": "Notifications système :",
  "c-NotificationSettings-system-alert":
    "Les notifications système sont des alertes natives spécifiques à votre appareil. Ces notifications ne fonctionneront que si vous avez accordé la permission à votre navigateur d'afficher des notifications.",

  "c-PasswordChange-change-password": "Changer le mot de passe",
  "c-PasswordChange-dialog-title": "Changez votre mot de passe",
  "c-PasswordChange-description":
    "Veuillez entrer votre mot de passe actuel et choisir un nouveau. Votre nouveau mot de passe doit répondre aux critères suivants :",
  "c-PasswordChange-current-password": "Mot de passe actuel",
  "c-PasswordChange-new-password": "Nouveau mot de passe",
  "c-PasswordChange-confirm-new-password": "Confirmer le nouveau mot de passe",
  "c-PasswordChange-min-characters": "Minimum 6 caractères",
  "c-PasswordChange-max-characters": "Maximum 20 caractères",
  "c-PasswordChange-2fa-code": "Code 2FA",
  "c-PasswordChange-case-sensitive":
    "Les mots de passe sont sensibles à la casse. Choisissez un mot de passe fort et unique pour sécuriser votre compte.",
  "c-PasswordChange-cancel": "Annuler",
  "c-PasswordChange-wait-loading": "Veuillez patienter, chargement...",
  "c-PasswordChange-enter-current-password":
    "Veuillez entrer votre mot de passe actuel",
  "c-PasswordChange-enter-new-password":
    "Veuillez entrer votre nouveau mot de passe",
  "c-PasswordChange-please-confirm-new-password":
    "Veuillez confirmer votre nouveau mot de passe",
  "c-PasswordChange-passwords-not-match":
    "Les deux mots de passe ne correspondent pas",
  "c-PasswordChange-enter-2fa-code": "Veuillez entrer votre code 2FA",
  "c-PasswordChange-password-changed-success":
    "Mot de passe changé avec succès !",
  "c-PasswordChange-error-changing-password":
    "Erreur lors du changement de mot de passe !",

  "c-TFAAlert-2fa-disabled-title":
    "Votre authentification à deux facteurs est désactivée",
  "c-TFAAlert-attention": "Attention !",
  "c-TFAAlert-message-1":
    "Il semble que vous n'ayez pas encore activé l'authentification à deux facteurs (2FA). Pour votre sécurité, certaines actions comme la création de commandes, d'offres et les retraits sont restreintes tant que la 2FA n'est pas activée.",
  "c-TFAAlert-message-2":
    "Vous pouvez continuer à utiliser le site, mais veuillez envisager d'activer la 2FA pour débloquer toutes les fonctionnalités et améliorer la sécurité de votre compte.",
  "c-TFAAlert-remind-me-later": "Me le rappeler plus tard",
  "c-TFAAlert-go-to-setup": "Aller à la configuration de la 2FA",

  "c-partner-bonuses-list": "Liste des bonus",
  "c-partner-bonuses-description":
    "Ici, vous pouvez voir la liste des bonus que vous avez reçus de clients ayant utilisé votre lien de parrainage.",
  "c-partner-bonus-id": "ID",
  "c-partner-bonus-amount": "Montant du bonus",
  "c-partner-bonus-date": "Date",

  "c-partner-withdrawals-list": "Liste des retraits",
};
