export default {
  "c-feedback-create-fastTransaction": "交易快速",
  "c-feedback-create-politeFriendly": "礼貌友好",
  "c-feedback-create-patient": "耐心",
  "c-feedback-create-safeTrustworthy": "安全可靠",
  "c-feedback-create-goodPrice": "价格合理",
  "c-feedback-create-successMessage": "感谢您的反馈！",
  "c-feedback-create-errorMessage": "发送反馈时出错",
  "c-feedback-create-feedbackTitle": "反馈",
  "c-feedback-create-experienceQuestion": "您的体验如何？",
  "c-feedback-create-positive": "正面",
  "c-feedback-create-negative": "负面",
  "c-feedback-create-feedbackPlaceholder": "请输入您的反馈",
  "c-feedback-create-anonymous": "匿名",
  "c-feedback-create-anonymousHint": "匿名发送您的反馈",
  "c-feedback-create-cancel": "取消",
  "c-feedback-create-submit": "提交反馈",

  "c-feedback-item-anonymously": "(匿名)",
  "c-feedback-item-positive": "正面",
  "c-feedback-item-negative": "负面",
};
