export default {
  "c-Withdrawal-success": "Ելքը հաջողությամբ իրականացվել է!",
  "c-Withdrawal-error":
    "Ելքի ժամանակ սխալ է տեղի ունեցել, խնդրում ենք փորձել ավելի ուշ",
  "c-Withdrawal-enter-valid-amount": "Մուտքագրեք վավեր քանակ",
  "c-Withdrawal-enter-valid-address": "Մուտքագրեք վավեր հանրային հասցե",
  "c-Withdrawal-enter-tfa-code": "Մուտքագրեք ձեր 2FA կոդը հավելվածից",
  "c-Withdrawal-title": "Ելք",
  "c-Withdrawal-message-1":
    "Խնդրում ենք լրացնել ներքևի ձևաթուղթը՝ ձեր ելքը շարունակելու համար",
  "c-Withdrawal-message-2":
    "Ելքի շարունակությունից հետո դուք կունենաք մի քանի րոպե ժամանակ՝ ձեր գործարքը չեղարկելու համար գործարքների պատմության էջից",
  "c-Withdrawal-no-fee":
    "Այս պահին չկա ելքի միջնորդավճար, դուք միայն կվճարեք ցանցային միջնորդավճարը, որը կախված է դրա ծանրաբեռնվածությունից և կհանվի ելքի քանակից:",
  "c-Withdrawal-amount-label": "Ելքի քանակ",
  "c-Withdrawal-available": "Հասանելի դուք ունեք",
  "c-Withdrawal-withdraw-all": "Ելք բոլոր",
  "c-Withdrawal-commission": "Հանձնաժողով:",
  "c-Withdrawal-address-label": "Դրամապանակի հանրային հասցե",
  "c-Withdrawal-security-tip-title": "Անվտանգության հուշում:",
  "c-Withdrawal-security-tip-desc":
    "Միշտ կրկնակի ստուգեք հասցեն նախքան ուղարկելը: Համոզվեք, որ ճանաչում եք ստացողին:",
  "c-Withdrawal-tfa-label": "Երկու-ֆակտորային նույնականացման կոդ",
  "c-Withdrawal-scam-warning-title": "Խուսափեք խարդախություններից:",
  "c-Withdrawal-scam-warning-desc":
    "Երբեք մի կիսեք ձեր բանալիները կամ 2FA կոդերը որևէ մեկի հետ: Պաշտոնական աջակցությունը երբեք չի հարցնի այս տեղեկությունները:",
  "c-Withdrawal-cancel": "Չեղարկել",
  "c-Withdrawal-continue": "Շարունակել",
};
